import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from 'contexts/AuthContext';
import {getFreeEmailPreview, getFreeNewsletterPreview} from 'utils/api/alertsAPI';
import {useParams, useLocation} from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import ScrollToTopButton from 'components/ScrollToTopButton';

const PublicEmailPreview = (props) => {
    let {id} = useParams();
    let {search} = useLocation();
    const {handleError} = useContext(AuthContext);
    const [fetchingData, setFetchingData] = useState(true);
    const [contentData, setContentData] = useState(null);
    const [scrollLink, setScrollLink] = useState(null);

    const fetchEmailContent = async() => {
        try {
            const hash = search.slice(
                3,
                search.length,
            );
            const hashLink = window.location.hash.substr(1);
            hashLink && setScrollLink(hashLink);
            let data;
            props.newsletter ?
                data = await getFreeNewsletterPreview(id, hash)
                :
                data = await getFreeEmailPreview(id, hash);
            setContentData(data.html);
            setFetchingData(false);
        } catch(err) {
            setFetchingData(false);
            handleError(err);
        }
    };

    useEffect(() => {
        if(contentData && scrollLink) {
            setTimeout(() => {
                let elementToScrollTo = document.getElementById(scrollLink);
                elementToScrollTo && elementToScrollTo.scrollIntoView({behavior: 'smooth'});
            }, 700);
        }
    }, [contentData]);

    useEffect(() => {
        fetchEmailContent();
    }, []);

    useEffect(() => {
        if(contentData) {
            setTimeout(() => {
                let elList = document.querySelectorAll('.scroll-to-link-button');
                elList.forEach((el) => {
                    el.addEventListener('click', () => {
                        let name = el.getAttribute('tabindex');
                        let elementToScrollTo = document.getElementById(name);
                        // elementToScrollTo.scrollIntoView();
                        elementToScrollTo.scrollIntoView({behavior: 'smooth'});
                    });
                });
            }, 700);
        }
    }, [contentData]);

    // const scrollToTop = () => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: "smooth"
    //     });
    // };
    //
    // const [showScroll, setShowScroll] = useState(false)
    // const checkScrollTop = () => {
    //     if (!showScroll && window.pageYOffset > 400){
    //         setShowScroll(true)
    //     } else if (showScroll && window.pageYOffset <= 400){
    //         setShowScroll(false)
    //     }
    // };
    // window.addEventListener('scroll', checkScrollTop)

    return (
        <div className="public-email-preview position-relative">
            {!fetchingData ? (
                contentData ? (
                    <div>
                        <div
                            dangerouslySetInnerHTML={{__html: contentData}}
                        />
                    </div>
                ) : (
                    <div className="p-1">Invalid Link</div>
                )
            ) : (
                <div style={{padding: '2rem 3rem'}}>
                    <LoadingSpinner text={props.newsletter ? 'Fetching newsletter content' : 'Fetching email content'}/>
                </div>
            )}
            {/*<div className="public-back-to-top-btn" onClick={scrollToTop}>*/}
            {/*    <i className="fa fa-chevron-up"/>*/}
            {/*</div>*/}
            <ScrollToTopButton/>
        </div>
    );
};

export default PublicEmailPreview;
