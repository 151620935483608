import React, {useEffect, useState} from 'react';
import SpinnerButton from 'components/SpinnerButton';
import CharacterCount from 'components/CharacterCount';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const CommentForm = ({value, onSubmit, disabled, handleValueChange}) => {
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async() => {
        try {
            setSubmitting(true);
            await onSubmit();
            setSubmitting(false);
        } catch(err) {
            console.log(err);
            setSubmitting(false);
        }
    }

    const commentOptions = {
        options: ['link'],
        link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: 'comment-link-dropdown',
            // dropdownClassName: 'comment-link-dropdown',
            showOpenOptionOnHover: true,
            defaultTargetOption: '_blank',
            options: ['link'],
            linkCallback: undefined,
            link: { className: 'comment-link-icon' },
        },
    }

    return (
        <div className="newsletter-comment">
            <div className="newsletter-comment__textarea-container">
                <div className="newsletter-comment__textarea-label">
                    <strong>Comment:</strong>
                </div>
                <Editor
                    editorState={value}
                    toolbarClassName="comment-link-toolbar"
                    wrapperClassName="newsletter-comment__textarea-container"
                    editorClassName="newsletter-comment__textarea"
                    onEditorStateChange={handleValueChange}
                    toolbar={commentOptions}
                />
                {/*<Input*/}
                {/*    className="newsletter-comment__textarea"*/}
                {/*    type="textarea"*/}
                {/*    value={value || ''}*/}
                {/*    onChange={(e) => handleValueChange(e.target.value)}*/}
                {/*/>*/}
            </div>
            <div className="d-flex justify-content-end">
                <div className="newsletter-comment__submit-btn-container">
                    {/*<CharacterCount asHtml value={value ? value : ''} limit={500}/>*/}
                    <SpinnerButton
                        color="primary"
                        onClick={handleSubmit}
                        title="Save"
                        submitting={submitting}
                    />
                </div>
            </div>
        </div>
    );
};

export default CommentForm;
