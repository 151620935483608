import React from 'react';

import {Line} from 'react-chartjs-2';
import moment from 'moment-timezone';

const SentimentChart = (props) => {
    const mobileView = window.innerWidth < 650;

    let data = {
        labels: props.data[0].days.map(item => moment(item.day).format('MMM DD')),
        datasets: [
            {
                data: props.data[0].days.map(item => item.value),
                label: 'Positive',
                borderColor: 'green',
                backgroundColor: 'green',
                fill: false
            },
            {
                data: props.data[1].days.map(item => item.value),
                label: 'Neutral',
                borderColor: '#a7a7a7',
                backgroundColor: '#a7a7a7',
                fill: false
            },
            {
                data: props.data[2].days.map(item => item.value),
                label: 'Negative',
                borderColor: '#e85354',
                backgroundColor: '#e85354',
                fill: false
            },
        ]
    }
    return (
        <div style={{maxHeight: mobileView ? 500 : 300, width: '100%', flex: 1}}>
            <Line
                data={data}
                height={mobileView ? 500 : 300}
                responsive={false}
                options={{
                    legend: {
                        display: true,
                        position: 'top',
                        paddingLeft: 10,
                        labels: {
                            boxWidth: mobileView ? 20 : 40,
                            fontSize: mobileView ? 15 : 12,
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                ticks: {
                                    maxTicksLimit: 15,
                                },
                            },
                        ],
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                    },
                }}
            />
        </div>
    );
};

export default SentimentChart;