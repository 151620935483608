import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalFooter, Row } from 'reactstrap';
import '../AlertRulesModal/Info.scss';
import ModalBodyWithClose from 'components/ModalBodyWithClose';
import { getCategoriesBySearchTyoeId, addOzmosysCategorizedAlert, deleteOzmosysCategorizedAlert } from 'utils/api/alertsAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import TableColCategories from './TableColCategories';
import { useHistory } from 'react-router-dom';
import SpinnerButton from 'components/SpinnerButton';

const CategoriesBySearchType = (props) => {
    const history = useHistory();
    const { isOpen, setIsOpen, searchType, searchTypeId } = props;
    const [categories, setCategories] = useState({ 0: [], 1: [], 2: [] });
    const [originalCategories, setOriginalCategories] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const [savedCategories, setSavedCategories] = useState([]);
    const [categoriesForCreate, setCategoriesForCreate] = useState([]);
    const [categoriesForDelete, setCategoriesForDelete] = useState([]);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const getCategories = async () => {
        setLoadingCategories(true);
        const response = await getCategoriesBySearchTyoeId(searchTypeId);
        setOriginalCategories(response);
        const convertedList = { 0: [], 1: [], 2: [] };
        const saved = [];
        response.forEach((element, index) => {
            convertedList[(index + 1) % 3].push(element);
            if (element.alerts > 0) {
                saved.push(element.id);
            }
        });
        setCategories(convertedList);
        setSavedCategories(saved);
        setLoadingCategories(false);
    };

    useEffect(() => {
        getCategories();
    }, [searchTypeId]);

    const handleChangeCategories = (value, checked) => {
        let newSelectedCategories = [...selectedCategories];
        if (true === checked) {
            newSelectedCategories.push({
                id: value,
                name: originalCategories.find((item) => +item.id === +value)?.name,
            });
            addForCreate(value);
        } else {
            addForDelete(value);
            newSelectedCategories = newSelectedCategories.filter((item) => item.id !== value);
        }

        // if (newSelectedCategories.length > 5) {
        //     addFlashMessage('warning', 'You have chosen more than 5 categories. We suggest choosing less.')
        // }

        setSelectedCategories(newSelectedCategories);
    };

    const toggleAssignModal = () => {
        setAssignModalOpen(!assignModalOpen);
    };

    const handleAssign = async (selectedCategories) => {
        try {
            setSubmitting(true);
            await Promise.all(
                selectedCategories.map(async (category) => {
                    if (categoriesForCreate.indexOf(+category.id) !== -1) {
                        await createAndAssignAlert(category);
                    }
                })
            );

            if (categoriesForDelete.length) {
                await deleteOzmosysCategorizedAlert({
                    ids: categoriesForDelete.join(),
                });
            }

            setSubmitting(false);
            if (history.location.pathname === '/account/manage-category-based/' + searchType) {
                window.location.reload();
                return;
            }
            history.push('/account/manage-category-based/' + searchType);
        } catch (err) {
            setSubmitting(false);
            addFlashMessage('danger', 'Unable to create/assign alerts at this time');
        }
    };

    const addForCreate = (id) => {
        if (savedCategories.indexOf(+id) === -1 && categoriesForCreate.indexOf(+id) === -1) {
            setCategoriesForCreate([...categoriesForCreate, +id]);
        }
    };

    const addForDelete = (id) => {
        if (savedCategories.indexOf(+id) !== -1 && categoriesForDelete.indexOf(+id) === -1) {
            setCategoriesForDelete([...categoriesForDelete, +id]);
        }
    };

    const createAndAssignAlert = async (selectedCategory) => {
        const response = await addOzmosysCategorizedAlert({
            name: selectedCategory.name,
            newsCategory: selectedCategory.id,
        });
    };

    return (
        <div>
            <Modal isOpen={isOpen} fade={false} className="search-info-modal">
                <ModalBodyWithClose toggle={toggle}>
                    <h2>Select {searchType}</h2>
                    {loadingCategories ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="table-container">
                            <Row>
                                <TableColCategories categories={categories[1]} handleChangeCategories={handleChangeCategories} />
                                <TableColCategories categories={categories[2]} handleChangeCategories={handleChangeCategories} />
                                <TableColCategories categories={categories[0]} handleChangeCategories={handleChangeCategories} />
                            </Row>
                        </div>
                    )}
                </ModalBodyWithClose>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>{' '}
                    <SpinnerButton
                        type="modal"
                        color="primary"
                        onClick={() => handleAssign(selectedCategories)}
                        submitting={submitting}
                        title="Confirm"
                    />
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default CategoriesBySearchType;
