import React, {useState} from 'react';

import {Link} from 'react-router-dom';
import {Button} from 'reactstrap';
import CreateLabelModal from './Modals/CreateLabel';

const EmptyList = (props) => {
    const {createLabel, submitting, refreshLabels, handleError, addFlashMessage} = props;
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const toggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen);
    }

    return (
        <div>
            <p>Create your first alert label!</p>
            <Button
                color="primary"
                onClick={toggleCreateModal}
            >
                Create Label
            </Button>
            <CreateLabelModal
                isOpen={createModalOpen}
                onSubmit={createLabel}
                toggle={toggleCreateModal}
                submitting={submitting}
                handleError={handleError}
                addFlashMessage={addFlashMessage}
                refreshLabels={refreshLabels}
            />
        </div>
    );
};

export default EmptyList;
