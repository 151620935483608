import React, {useState, useEffect} from 'react';

import Alert from './Alert';
import SearchInput from 'components/SearchInput';
import {filterByValue} from 'Helpers/filterByValue';

const AlertList = (props) => {
    const {alerts, selectedAlert, handleAlertSelect} = props;

    const [searchValue, setSearchValue] = useState('');
    const [visibleAlerts, setVisibleAlerts] = useState([]);

    useEffect(() => {
        setVisibleAlerts(alerts);
    }, [alerts]);

    useEffect(() => {
        let newList = filterByValue(alerts, 'name', searchValue);
        setVisibleAlerts(newList);
    }, [searchValue]);

    return (
        <div className="mobile-reader-list mobile-reader-alert-list">
            <div className="mobile-reader-list__header mobile-reader-alert-list__header">
                Alerts ({visibleAlerts.length} {visibleAlerts.length === 1 ? 'Result' : 'Results'})
            </div>
            <div className="mobile-reader-alert-list__searchbar">
                <SearchInput
                    placeholder="Search by alert name"
                    value={searchValue}
                    classNames={['reader-column__input reader-alert-list__input']}
                    iconClassNames={['reader-alert-list__searchbar-icon']}
                    closeClassNames={['reader-alert-list__searchbar-icon']}
                    onChange={setSearchValue}
                    onClear={() => setSearchValue('')}
                />
            </div>
            <div className="mobile-reader-list__body mobile-reader-alert-list__body">
                {visibleAlerts.length < 1 ?
                    <div className="mobile-reader-alert">
                        No alerts found
                    </div>
                    :
                    visibleAlerts.map(alert => (
                        <Alert
                            alert={alert}
                            key={alert.id}
                            isSelected={alert.id === selectedAlert.id}
                            handleAlertSelect={handleAlertSelect}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default AlertList;
