import axios from 'axios';

const FileDownload = require('js-file-download');

export const getDecorator = (url) => {
    const rawToken = localStorage.getItem('token');
    const parsedToken = rawToken && JSON.parse(rawToken);
    if(!parsedToken) {
        throw {code: 'AUTHENTICATION_FAILED'};
    }
    if(url.length > 20000) { // For 414 Error
        return;
    }
    return axios(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-AUTH-TOKEN': parsedToken.accessToken,
        },
        credentials: 'same-origin',
    })
        .then(res => res.data.content)
        .catch(err => {
            if(err.response) {
                throw err.response.data.content;
            } else {
                throw {
                    code: 'GATEWAY_ERROR',
                    errors: {},
                    message: 'Gateway error',
                };
            }
        });
};

export const patchDecorator = (url, params) => {
    const rawToken = localStorage.getItem('token');
    const parsedToken = rawToken && JSON.parse(rawToken);
    if(!parsedToken) {
        throw {code: 'AUTHENTICATION_FAILED'};
    }
    return axios(url, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-AUTH-TOKEN': parsedToken.accessToken,
        },
        credentials: 'same-origin',
        data: params,
    })
        .then(res => res.data)
        .catch(err => {
            if(err.response) {
                throw err.response.data.content;
            } else {
                throw {
                    code: 'GATEWAY_ERROR',
                    errors: {},
                    message: 'Gateway error',
                };
            }
        });
};

export const deleteDecorator = (url) => {
    const rawToken = localStorage.getItem('token');
    const parsedToken = rawToken && JSON.parse(rawToken);
    if(!parsedToken) {
        throw {code: 'AUTHENTICATION_FAILED'};
    }
    return axios(url, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-AUTH-TOKEN': parsedToken.accessToken,
        },
        credentials: 'same-origin',
    })
        .then(res => res.data)
        .catch(err => {
            if(err.response) {
                throw err.response.data.content;
            } else {
                throw {
                    code: 'GATEWAY_ERROR',
                    errors: {},
                    message: 'Gateway error',
                };
            }
        });
};

export const postDecorator = (url, params) => {
    const rawToken = localStorage.getItem('token');
    const parsedToken = rawToken && JSON.parse(rawToken);

    return axios(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-AUTH-TOKEN': parsedToken.accessToken,
        },
        credentials: 'same-origin',
        data: params,
    })
        .then(res => res.data)
        .catch(err => {
            if(err.response) {
                throw err.response.data.content;
            } else {
                throw {
                    code: 'GATEWAY_ERROR',
                    errors: {},
                    message: 'Gateway error',
                };
            }
        });
};

export const publicDecorator = (url) => {
    return axios(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'same-origin',
    })
        .then(res => res.data.content)
        .catch(err => {
            if(err.response) {
                throw err.response.data.content;
            } else {
                throw {
                    code: 'GATEWAY_ERROR',
                    errors: {},
                    message: 'Gateway error',
                };
            }
        });
};

export const publicPostDecorator = (url, params) => {
    return axios(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'same-origin',
        data: params,
    })
        .then(res => res.data.content)
        .catch(err => {
            if(err.response) {
                throw err.response.data.content;
            } else {
                throw {
                    code: 'GATEWAY_ERROR',
                    errors: {},
                    message: 'Gateway error',
                };
            }
        });
};

export const csvDecorator = async(url, backupTitle, pdf) => {
    const rawToken = localStorage.getItem('token');
    const parsedToken = rawToken && JSON.parse(rawToken);

    return axios(url, {
        method: 'GET',
        responseType: 'blob',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-AUTH-TOKEN': parsedToken.accessToken,
        },
        credentials: 'same-origin',
    })
        .then(res => {
            let headerLine = res.headers['content-disposition'].split('=');
            if(headerLine.length < 2) {
                FileDownload(res.data, `${backupTitle}.${pdf ? 'pdf' : 'csv'}`);
            } else {
                FileDownload(res.data, `${headerLine[1]}`);
            }
        })
        .catch(err => {
            if(err.response) {
                throw err.response;
            } else {
                throw {
                    code: 'GATEWAY_ERROR',
                    errors: {},
                    message: 'Gateway error',
                };
            }
        });
};
