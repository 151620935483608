import React, {useEffect, useState, useContext} from 'react';

import {useHistory} from 'react-router-dom';
import {cloneDeep} from 'lodash';

import SectionsList from './SectionsList';
import SectionsView from './SectionView';
import AddArticles from './AddArticles';
import AddAlerts from './AddAlerts';
import {AuthContext} from 'contexts/AuthContext';
import './style.scss';
import {moveAlertBetweenNewsletterSections, moveArticleBetweenNewsletterSections} from 'utils/api/newslettersAPI';
import {FlashMessageContext} from 'contexts/FlashMessageContext';

const ManageContentMobile = (props) => {
    let history = useHistory();
    const {state, handleError} = useContext(AuthContext);
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {newsletterData, refreshNewsletter} = props;

    const [activeView, setActiveView] = useState('sectionsList');
    const [activeSection, setActiveSection] = useState(null);
    const [newsletterForDom, setNewsletterForDom] = useState(null);
    const [newsletterType, setNewsletterType] = useState('');

    useEffect(() => {
        setNewsletterForDom(newsletterData);
        let foundSection;
        if(activeSection) {
            foundSection = newsletterData.sections.find(section => section.id === activeSection.id);
            setActiveSection(foundSection);
        }
        if(!newsletterData.schedule.length && !newsletterData.asAvailable) {
            setNewsletterType('curated');
        } else {
            setNewsletterType('automatic');
        }
    }, [newsletterData]);

    useEffect(() => {
        if(activeSection) {
            let newActiveSection = newsletterForDom.sections.find(section => section.id === activeSection.id);
            setActiveSection(newActiveSection);
        }
    }, [newsletterForDom]);

    const handleBackButtonPress = () => {
        switch(activeView) {
            case 'sectionView':
                setActiveSection(null);
                setActiveView('sectionsList');
                break;
            case 'sectionsList':
                history.push('/account/newsletters');
                break;
            case 'addAlerts':
                setActiveView('sectionView');
                break;
            case 'addArticles':
                setActiveView('sectionView');
                break;
        }
    };

    const handleSectionSelect = (section) => {
        setActiveSection(section);
    };

    useEffect(() => {
        activeSection && activeView === 'sectionsList' && setActiveView('sectionView');
    }, [activeSection]);

    const handleViewDisplay = () => {
        switch(activeView) {
            case 'sectionsList':
                return (
                    <SectionsList
                        newsletterData={newsletterData}
                        refreshNewsletter={refreshNewsletter}
                        handleSectionSelect={handleSectionSelect}
                    />
                );
            case 'sectionView':
                if(activeSection) {
                    return (
                        <SectionsView
                            newsletter={newsletterData}
                            refreshNewsletter={refreshNewsletter}
                            setActiveView={setActiveView}
                            section={activeSection}
                            newsletterType={newsletterType}
                            handleError={handleError}
                        />
                    );
                }
                break;
            case 'addAlerts':
                return (
                    <AddAlerts
                        section={activeSection}
                        newsletterData={newsletterForDom}
                        handleAddAlert={handleAddAlert}
                        handleRemoveAlert={handleRemoveAlert}
                        unassignedAlerts={newsletterForDom.unassignedAlerts}
                    />
                );
            case 'addArticles':
                return (
                    <AddArticles
                        section={activeSection}
                        newsletterData={newsletterForDom}
                        setNewsletterForDom={setNewsletterForDom}
                        addFlashMessage={addFlashMessage}
                        handleError={handleError}
                    />
                );
            default:
                return (
                    <SectionsList
                        newsletterData={newsletterData}
                        refreshNewsletter={refreshNewsletter}
                        handleSectionSelect={handleSectionSelect}
                    />
                );
        }
    };

    const handleAddAlert = async(sectionId, alertId) => {
        const {sections, unassignedAlerts} = newsletterForDom;
        let alertLimit = state.profile.organization.limitNewsletterAlerts;
        let assignedAlertsCnt = 0;
        sections.map(section => {
            assignedAlertsCnt += section.alerts.length;
        });
        if(assignedAlertsCnt >= alertLimit) {
            props.addFlashMessage(
                'danger',
                `Maximum alert limit for this newsletter (${alertLimit}) has been reached.`,
            );
            return;
        }

        const nextColumn = sections.find(obj => obj.id === sectionId);
        const nextColumnIndex = sections.indexOf(nextColumn);
        const newStateUnassignedAlerts = cloneDeep(unassignedAlerts);
        const selectedAlert = newStateUnassignedAlerts.find(
            alert => alert.id === alertId,
        );
        const nextColumnAlerts = Array.from(nextColumn.alerts);
        nextColumnAlerts.push(selectedAlert);
        const newNextColumn = {
            ...nextColumn,
            alerts: nextColumnAlerts,
        };

        newStateUnassignedAlerts.splice(
            newStateUnassignedAlerts.indexOf(selectedAlert),
            1,
        );
        const newStateSections = cloneDeep(sections);
        newStateSections[nextColumnIndex] = newNextColumn;
        const newNewsletter = {
            ...newsletterForDom,
            sections: newStateSections,
            unassignedAlerts: newStateUnassignedAlerts,
        };
        setNewsletterForDom(newNewsletter);
        await moveAlertBetweenNewsletterSections(newsletterData.id, alertId, 0, sectionId);
    };

    const handleRemoveAlert = async(sectionId, alertId) => {
        const {sections, unassignedAlerts} = newsletterForDom;
        const selectedSection = sections.find(obj => obj.id === sectionId);
        const selectedSectionIndex = sections.indexOf(selectedSection);
        const newStateUnassignedAlerts = cloneDeep(unassignedAlerts);
        const newStateSections = cloneDeep(sections);

        const selectedAlert = selectedSection.alerts.find(
            alert => alert.id === alertId,
        );
        const selectedSectionAlerts = Array.from(selectedSection.alerts);
        selectedSectionAlerts.splice(
            selectedSectionAlerts.indexOf(selectedAlert),
            1,
        );
        const newSelectedSection = {
            ...selectedSection,
            alerts: selectedSectionAlerts,
        };

        newStateUnassignedAlerts.push(selectedAlert);
        newStateSections[selectedSectionIndex] = newSelectedSection;
        const newNewsletter = {
            ...newsletterForDom,
            sections: newStateSections,
            unassignedAlerts: newStateUnassignedAlerts,
        };
        setNewsletterForDom(newNewsletter);
        await moveAlertBetweenNewsletterSections(newsletterData.id, alertId, sectionId, 0);
    };


    return (
        <div className="mobile-manage-content">
            <div className="mobile-manage-content__back-btn-container">
                <div
                    className="mobile-manage-content__back-btn"
                    onClick={handleBackButtonPress}
                >
                    <i className="fa fa-angle-left" style={{fontWeight: 'bold'}}/>
                    &nbsp; Back
                </div>
            </div>
            <div className="mobile-manage-content__header">{newsletterData.name}</div>
            <div className="mobile-manage-content__body">{handleViewDisplay()}</div>
        </div>
    );
};

export default ManageContentMobile;
