import React from 'react';

import {Input, Label, FormGroup} from 'reactstrap';

import {timezones} from 'utils/timezones';
import LabelsBlock from './LabelsBlock';
import InputWithError from 'components/InputWithError';
import SelectWithError from 'components/InputWithError/SelectWithError';
import RelevanceContainerLabels from 'components/Labels/RelevanceContainerLabels';
import Toggle from 'react-toggle';

const General = (props) => {
    const {
        email, setEmail, fullName, errors, isSuspended, setIsSuspended,
        setFullName, setTimezone, selectedTimezone, isSingleUser,
        allLabels, selectedLabels, addToSelected, removeFromSelected,
        addLabel, creatingLabel, isAdmin, setIsAdmin, sendConfirmation, setSendConfirmation,
        changeEmail, setChangeEmail, isEditForm, newEmail, setNewEmail, disableAdminSelection,
    } = props;

    const toggleSuspended = () => {
        setIsSuspended(!isSuspended);
    }

    return (
        <div>
            <h4 className="user-form__header">General</h4>
            <div className="mt-3">
                <InputWithError
                    placeholder="Full name"
                    prependIcon={<i className="fa fa-user"/>}
                    name="fullName"
                    value={fullName}
                    type="text"
                    onChange={setFullName}
                    errorObj={errors}
                    marginBottomClass="mb-3"
                />
            </div>
            <div className="mt-3">
                <InputWithError
                    placeholder="Email"
                    prependIcon="@"
                    name="email"
                    value={email}
                    type="email"
                    onChange={setEmail}
                    disabled={isEditForm}
                    errorObj={errors}
                    marginBottomClass="mb-3"
                />
            </div>
            <div className="mt-3">
                <SelectWithError
                    name="timezone"
                    value={selectedTimezone}
                    type="select"
                    style={{maxWidth: '350px'}}
                    options={timezones}
                    onChange={setTimezone}
                    prependIcon={<i className="fa fa-globe"/>}
                    errorObj={errors}
                    marginBottomClass="mb-3"
                >
                    <option value={''} disabled>
                        Timezone
                    </option>
                    {timezones.map(timezone => {
                        return (
                            <option
                                key={timezone.key}
                                value={timezone.key}
                            >
                                {timezone.name}
                            </option>
                        );
                    })}
                </SelectWithError>
            </div>
            {
                isEditForm && changeEmail &&
                <div className="mt-3">
                    <InputWithError
                        placeholder="New email"
                        prependIcon="@"
                        name="plannedEmail"
                        value={newEmail}
                        type="email"
                        onChange={setNewEmail}
                        errorObj={errors}
                        autoComplete={'off'}
                        // marginBottomClass="mb-3"
                    />
                </div>
            }
            {isEditForm ?
                <div>
                    <div className="d-flex">
                        <div>
                            <FormGroup check style={{marginRight: '1.5rem'}}>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        name="changeEmail"
                                        checked={changeEmail}
                                        onChange={() => setChangeEmail(!changeEmail)}
                                    />{' '}
                                    Change Email
                                </Label>
                            </FormGroup>
                        </div>
                        {changeEmail &&
                        <div>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        name="sendConfirmation"
                                        checked={(changeEmail && isAdmin) || (changeEmail && sendConfirmation)}
                                        disabled={changeEmail && isAdmin}
                                        onChange={() => setSendConfirmation(!sendConfirmation)}
                                    />{' '}
                                    Send Confirmation Email
                                </Label>
                            </FormGroup>
                        </div>
                        }
                    </div>
                    <div className="mt-3">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="isAdmin"
                                    checked={isAdmin}
                                    disabled={disableAdminSelection}
                                    onChange={() => setIsAdmin(!isAdmin)}
                                />{' '}
                                Administrator
                            </Label>
                        </FormGroup>
                    </div>
                </div>
                :
                <div>
                    <div className="mt-3">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="isAdmin"
                                    checked={isAdmin}
                                    onChange={() => setIsAdmin(!isAdmin)}
                                />{' '}
                                Administrator
                            </Label>
                        </FormGroup>
                    </div>
                    <div className="mt-3">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="sendConfirmation"
                                    disabled={isAdmin}
                                    checked={isAdmin || sendConfirmation}
                                    onChange={() => setSendConfirmation(!sendConfirmation)}
                                />{' '}
                                Send Confirmation Email <span className="gray">(Optional, user can be created without confirmation. The record will be locked until confirmed.)</span>
                            </Label>
                        </FormGroup>
                    </div>
                </div>
            }
            {isEditForm &&
            <div className="suspended-toggle-container mt-3">
                <label>
                    <Toggle
                        defaultChecked={isSuspended}
                        icons={false}
                        onChange={toggleSuspended}
                    />
                </label>
                <span id="suspend-label">Suspend Daily Email Delivery</span>
            </div>
            }
            {!isSingleUser &&
            <div className="mt-3">
                {/*<LabelsBlock*/}
                {/*    selectedLabels={selectedLabels}*/}
                {/*    labels={allLabels}*/}
                {/*    addToSelected={addToSelected}*/}
                {/*    removeFromSelected={removeFromSelected}*/}
                {/*    addLabel={addLabel}*/}
                {/*    creatingLabel={creatingLabel}*/}
                {/*/>*/}
                <RelevanceContainerLabels
                    selectedLabels={selectedLabels}
                    labels={allLabels}
                    removeFromSelected={removeFromSelected}
                    addToSelected={addToSelected}
                    addLabel={addLabel}
                    creatingLabel={creatingLabel}
                />
            </div>
            }
        </div>
    );
};

export default General;
