import React, { useContext, useEffect, useState } from 'react';

import List from './List';
import { AuthContext } from 'contexts/AuthContext';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { FilterContext } from 'contexts/FilterContext';
import { getAlertLabels, getAlerts, getAlertsWithoutParams } from 'utils/api/alertsAPI';
import EmptyList from './EmptyList';
import './style.scss';
import moment from 'moment-timezone';

export const SEARCH_TYPE = 'search';
export const CONTENT_TYPE = 'content';
export const COMPANY_TYPE = 'company';
export const CATEGORY_TYPE = 'category';
export const PRACTICES_TYPE = 'practices';
export const INDUSTRIES_TYPE = 'industries';
export const TOPICS_TYPE = 'topics';

const AlertsList = (props) => {
    const { alertType, searchTypeId, searchType } = props;
    const { handleError, state } = useContext(AuthContext);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { setFiltersObject, alertFilters, alertCatCompFilters } = useContext(FilterContext);

    const isSortByName = alertType === 'company' || alertType === 'category';
    const currentFilters = isSortByName ? alertCatCompFilters : alertFilters;
    const { paginationCurrentPageNumber, activeSortProperty, activeSortDirection, activeFilterLabels, searchValue, searchProperty } = currentFilters;

    const [alerts, setAlerts] = useState([]);
    const [navOptions, setNavOptions] = useState({});
    const [fetchingData, setFetchingData] = useState(true);
    const [fetchingLabels, setFetchingLabels] = useState(true);
    const [labels, setLabels] = useState([]);
    const [fetchError, setFetchError] = useState(false);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [submittingSearch, setSubmittingSearch] = useState(false);
    const [showFirstTimeMessage, setShowFirstTimeMessage] = useState(true);
    const [totalPages, setTotalPages] = useState(null);
    const [initialLoad, setInitialLoad] = useState(true);
    const [fetchingCsv, setFetchingCsv] = useState(false);

    const newAlertsData = localStorage?.getItem('newAlertsData');

    const fetchAlerts = async (csv, withoutSpinner, refetch) => {
        try {
            if (csv) {
                setFetchingCsv(true);
                let title = 'ozmosys_alerts_' + moment().tz(moment.tz.guess()).format('MMDDYYYY');
                const csvData = await getAlerts(
                    false,
                    paginationCurrentPageNumber,
                    50,
                    activeSortProperty,
                    activeSortDirection,
                    searchProperty,
                    searchValue,
                    activeFilterLabels,
                    true,
                    title,
                    alertType,
                    searchTypeId
                );
                setFetchingCsv(false);
            } else {
                !submittingSearch && !withoutSpinner && setSubmittingSearch(true);
                let refreshCount = initialLoad || paginationCurrentPageNumber === 1 || !alerts.length;
                const alertData = await getAlerts(
                    refreshCount,
                    paginationCurrentPageNumber,
                    50,
                    activeSortProperty,
                    activeSortDirection,
                    searchProperty,
                    searchValue,
                    activeFilterLabels,
                    false,
                    '',
                    alertType,
                    searchTypeId
                );

                if (!refetch && newAlertsData) {
                    const alertsResults = alertData.result;
                    const alertsList = [
                        {
                            id: null,
                            labels: [],
                            name: JSON.parse(newAlertsData)?.name,
                            createdAt: '2024-01-09 13:06:53+00',
                            countClicks: 0,
                            source: { keyword: JSON.parse(newAlertsData)?.keyword, type: 'internal' },
                            countAssignedUsers: 0,
                        },
                        ...alertsResults,
                    ];
                    setAlerts(alertsList);
                } else {
                    setAlerts(alertData.result);
                }

                if (refreshCount) setTotalPages(alertData.navigation.total_pages);
                setNavOptions(alertData.navigation);
                setFetchingData(false);
                setSubmittingSearch(false);
                if (tempSearchValue !== searchValue) setTempSearchValue(searchValue);
                if (alertData.result.length > 0) {
                    showFirstTimeMessage && setShowFirstTimeMessage(false);
                }
                initialLoad && setInitialLoad(false);
            }
        } catch (err) {
            setFetchError(true);
            setFetchingData(false);
            setSubmittingSearch(false);
            fetchingCsv && setFetchingCsv(false);
            handleError(err);
        }
    };

    const fetchFullAlertList = async () => {
        try {
            setFetchingCsv(true);
            let title = 'ozmosys_alerts_' + moment().tz(moment.tz.guess()).format('MMDDYYYY') + '.csv';
            await getAlertsWithoutParams(true, title);
            setFetchingCsv(false);
        } catch (err) {
            fetchingCsv && setFetchingCsv(false);
            handleError(err);
        }
    };

    const fetchLabels = async () => {
        try {
            !fetchingLabels && setFetchingLabels(true);
            const labels = await getAlertLabels();
            if (labels.length) {
                setLabels(labels);
            } else {
                setLabels([]);
            }
            setFetchingLabels(false);
        } catch (err) {
            addFlashMessage('danger', 'Unable to fetch alerts');
            setFetchingLabels(false);
        }
    };

    const refreshAlerts = async (callback, withoutSpinner) => {
        fetchAlerts(false, withoutSpinner);
        callback && callback();
    };

    useEffect(() => {
        fetchLabels();
    }, []);

    useEffect(() => {
        if (!fetchingLabels) {
            fetchAlerts();
        }
    }, [currentFilters, labels, fetchingLabels, searchTypeId]);

    useEffect(() => {
        if (newAlertsData === 'success') {
            localStorage.removeItem('newAlertsData');
            fetchAlerts(false, false, true);
        }
    }, [newAlertsData]);

    // useEffect(() => {
    //     setFetchingData(true);
    //     fetchAlerts();
    // }, [searchTypeId]);

    const handleSearchValueSubmit = () => {
        if (tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)');
            return;
        }
        let filters = { ...currentFilters };
        filters.searchValue = tempSearchValue;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };

    const handleActiveFilterLabelSelect = (labelName) => {
        let filterArr = [...activeFilterLabels];
        if (filterArr.indexOf(labelName) > -1) {
            filterArr.splice(filterArr.indexOf(labelName), 1);
        } else {
            filterArr.push(labelName);
        }
        let filters = { ...currentFilters };
        filters.activeFilterLabels = filterArr;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };

    const downloadFilteredCsv = async () => {
        await fetchAlerts(true);
    };

    if (fetchError) return null;
    return (
        <div>
            {!fetchingData &&
            alerts.length === 0 &&
            !submittingSearch &&
            searchValue === '' &&
            !currentFilters.activeFilterLabels.length &&
            !searchTypeId ? (
                <EmptyList alertType={alertType} searchType={searchType} />
            ) : (
                <List
                    alerts={alerts}
                    alertFilters={currentFilters}
                    setFiltersObject={setFiltersObject}
                    isSortByName={isSortByName}
                    navOptions={navOptions}
                    fetchingData={fetchingData || fetchingLabels}
                    refreshAlerts={refreshAlerts}
                    paginationCurrentPageNumber={paginationCurrentPageNumber}
                    activeSortProperty={activeSortProperty}
                    activeSortDirection={activeSortDirection}
                    searchValue={tempSearchValue}
                    setSearchValue={setTempSearchValue}
                    submittingSearch={submittingSearch}
                    handleSearchValueSubmit={handleSearchValueSubmit}
                    allLabels={labels}
                    fetchingLabels={fetchingLabels}
                    refreshLabels={fetchLabels}
                    activeFilterLabels={activeFilterLabels}
                    handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                    showFirstTimeMessage={showFirstTimeMessage}
                    totalPages={totalPages}
                    setSubmittingSearch={setSubmittingSearch}
                    downloadFilteredCsv={downloadFilteredCsv}
                    downloadFullCsv={fetchFullAlertList}
                    fetchingCsv={fetchingCsv}
                    isSingleUser={state.isSingleUser}
                    alertType={alertType}
                    searchTypeId={searchTypeId}
                    searchType={searchType}
                />
            )}
        </div>
    );
};

export default AlertsList;
