import React, {useState, useEffect, useContext} from 'react';

import {useHistory} from 'react-router-dom';

import SearchForm from './SearchForm';
import TitleForm from './TitleForm';
import Preview from './Preview';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import {
    addAlertLabel,
    addGoogleAlert,
    checkPossibleToAddAlert,
    editGoogleAlert,
    previewGoogleAlert,
} from 'utils/api/alertsAPI';
import {combinedCountries} from './combinedCountries';
import LoadingSpinner from 'components/LoadingSpinner';

const GoogleAlertForm = (props) => {
    let history = useHistory();
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError, state} = useContext(AuthContext);

    const {foundAlert, isEditForm, labels} = props;
    const [searchValue, setSearchValue] = useState('');
    const [selectedCountryLangGroup, setSelectedCountryLangGroup] = useState('English (United States)');
    const [errors, setErrors] = useState(null);
    const [titleValue, setTitleValue] = useState('');
    const [useGoogleAlerts, setUseGoogleAlerts] = useState(false);
    const [inTitlesOnly, setInTitlesOnly] = useState(false);
    const [inTextOnly, setInTextOnly] = useState(false);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [fullSelectedObjects, setFullSelectedObjects] = useState([]);
    const [preview, setPreview] = useState(null);
    const [titleFormVisible, setTitleFormVisible] = useState(false);
    const [searchSubmitting, setSearchSubmitting] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [creatingLabel, setCreatingLabel] = useState(false);
    const [newLabelToAdd, setNewLabelToAdd] = useState(null);
    const [updatingPreview, setUpdatingPreview] = useState(false);

    useEffect(() => {
        isEditForm && getFoundAlertValues();
    }, []);

    const getFoundAlertValues = async() => {
        if(foundAlert.source.keyword.slice(0, 11) === 'allintitle:') {
            setSearchValue(foundAlert.source.keyword.slice(11, foundAlert.source.keyword.length));
            setInTitlesOnly(true);
        } else if (foundAlert.source.keyword.slice(0, 10) === 'allintext:') {
            setSearchValue(foundAlert.source.keyword.slice(10, foundAlert.source.keyword.length));
            setInTextOnly(true);
        } else {
            setSearchValue(foundAlert.source.keyword);
        }

        setTitleValue(foundAlert.name);

        let activeLangCountry = combinedCountries.find(item => {
            return item.lang === foundAlert.source.language && item.country === foundAlert.source.country;
        });
        activeLangCountry && setSelectedCountryLangGroup(activeLangCountry.displayName);
        setTitleValue(foundAlert.name);
        setTitleFormVisible(true);
        setSelectedLabels(foundAlert.labels);
        let editInitialSubmitParams = {
            keyword: foundAlert.source.keyword,
            lang: foundAlert.source.language,
            country: foundAlert.source.country,
        };
        await handleCreatePreview(editInitialSubmitParams);
    };

    const handleCreatePreview = async(editInitialSubmitParams) => {
        try {
            if(searchValue.length < 1 && !editInitialSubmitParams) return;
            setSearchSubmitting(true);
            setErrors(null);
            let newSearchValue;
            if(inTitlesOnly) {
                newSearchValue = 'allintitle:' + searchValue
            } else if (inTextOnly) {
                newSearchValue = 'allintext:' + searchValue
            } else {
                newSearchValue = searchValue
            }
            let activeLangCountry = combinedCountries.find(item => item.displayName === selectedCountryLangGroup);
            const params = {
                keyword: newSearchValue,
                lang: activeLangCountry.lang,
                country: activeLangCountry.country,
            };
            const preview = await previewGoogleAlert(editInitialSubmitParams ? editInitialSubmitParams : params);
            setTitleFormVisible(true);
            setPreview(preview);
            setSearchSubmitting(false);
        } catch(err) {
            setSearchSubmitting(false);
            setTitleFormVisible(false);
            setPreview(null);
            handleError(err, setErrors);
        }
    };

    const handleSearchValueChange = (text) => setSearchValue(text);

    const handleUseGoogleAlerts = () => setUseGoogleAlerts(!useGoogleAlerts);

    const resetForm = () => {
        setPreview(null);
        setTitleFormVisible(false);
        setTitleValue('');
        setSearchValue('');
        setInTextOnly(false);
        setInTitlesOnly(false);
    };

    const clearPreviewForm = () => {
        setPreview(null);
        setTitleFormVisible(false);
        setTitleValue('');
    };

    const handleSubmit = async() => {
        let newSearchValue;
        if(inTitlesOnly) {
            newSearchValue = 'allintitle:' + searchValue
        } else if (inTextOnly) {
            newSearchValue = 'allintext:' + searchValue
        } else {
            newSearchValue = searchValue
        }
        let activeLangCountry = combinedCountries.find(item => item.displayName === selectedCountryLangGroup);
        const googleSearchValues = {
            keyword: newSearchValue,
            name: titleValue,
            lang: activeLangCountry.lang,
            country: activeLangCountry.country,
            alertLabels: selectedLabels.map(n => n.id),
        };

        try {
            setSubmitting(true);
            setErrors(null);
            if(props.isEditForm) {
                await editGoogleAlert(foundAlert.id, googleSearchValues);
                addFlashMessage('success', 'Alert successfully updated');
                history.push('/account/alerts');
            } else {
                let isPossibleToAdd = await checkPossibleToAddAlert();
                if(isPossibleToAdd.allowed) {
                    await addGoogleAlert(googleSearchValues);
                    setSubmitting(false);
                    addFlashMessage('success', 'Alert successfully created');
                    history.push('/account/alerts');
                } else {
                    addFlashMessage('danger', 'You\'ve reached the limit of allowed number of alerts for your account.\n' +
                        'Contact Customer Care team for more information.');
                }
            }
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };


    useEffect(() => {
        if(newLabelToAdd) {
            const foundLabel = labels.find(label => label.id === newLabelToAdd.id);
            foundLabel && addToSelected(foundLabel);
            setNewLabelToAdd(null);
        }
    }, [labels]);

    const addLabel = async(name) => {
        setCreatingLabel(true);
        try {
            const newLabel = await addAlertLabel({name});
            setNewLabelToAdd(newLabel.content);
            await props.refreshLabels();
            setCreatingLabel(false);
        } catch(res) {
            if(res.errors.name[0].message) {
                addFlashMessage('danger', res.errors.name[0].message);
            }
            setCreatingLabel(false);
        }
    };

    const addToSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if(newSelectedLabels.indexOf(label) === -1) {
            newSelectedLabels.push(label);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const removeFromSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if(newSelectedLabels.indexOf(label) > -1) {
            newSelectedLabels.splice(newSelectedLabels.indexOf(label), 1);
            setSelectedLabels(newSelectedLabels);
        }
    };

    return (
        <div className="mb-4">
            <SearchForm searchValue={searchValue}
                        setSearchValue={handleSearchValueChange}
                        handleCreatePreview={handleCreatePreview}
                        useGoogleAlerts={useGoogleAlerts}
                        handleUseGoogleAlerts={handleUseGoogleAlerts}
                        handleError={handleError}
                        errorObj={errors}
                        resetForm={resetForm}
                        clearPreviewForm={clearPreviewForm}
                        setSelectedAlerts={setSelectedAlerts}
                        setFullSelectedObjects={setFullSelectedObjects}
                        fullSelectedObjects={fullSelectedObjects}
                        searchSubmitting={searchSubmitting}
                        setSearchSubmitting={setSearchSubmitting}
                        selectedAlerts={selectedAlerts}
                        selectedCountryLangGroup={selectedCountryLangGroup}
                        setSelectedCountryLangGroup={setSelectedCountryLangGroup}
                        setInTitlesOnly={setInTitlesOnly}
                        inTitlesOnly={inTitlesOnly}
                        inTextOnly={inTextOnly}
                        setInTextOnly={setInTextOnly}
                        combinedCountries={combinedCountries}
            />

            {titleFormVisible &&
            <TitleForm title={titleValue}
                       setTitleValue={setTitleValue}
                       submitting={submitting}
                       isEditForm={props.isEditForm}
                       handleSubmit={handleSubmit}
                       selectedLabels={selectedLabels}
                       setSelectedLabels={setSelectedLabels}
                       labels={props.labels}
                       addLabel={addLabel}
                       removeFromSelected={removeFromSelected}
                       addToSelected={addToSelected}
                       creatingLabel={creatingLabel}
                       errors={errors}
                       isSingleUser={state.isSingleUser}
            />
            }
            {
                searchSubmitting ?
                    <div className="mt-5">
                        <LoadingSpinner text="Fetching preview"/>
                    </div>
                    : preview &&
                    <Preview
                        preview={preview}
                        updatingPreview={updatingPreview}
                        submitting={submitting}
                        handleSubmit={handleSubmit}
                        isEditForm={isEditForm}
                        selectedLabels={selectedLabels}
                        labels={labels}
                        addLabel={addLabel}
                        addToSelected={addToSelected}
                        removeFromSelected={removeFromSelected}
                        creatingLabel={creatingLabel}
                        isSingleUser={state.isSingleUser}
                    />
            }
        </div>
    );
};

export default GoogleAlertForm;
