import React, {useState, useRef, useEffect} from 'react';

import {Button} from 'reactstrap';
import {CSVLink} from 'react-csv';
import moment from 'moment-timezone';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinnerSmall';

const ComparativeSetListItem = (props) => {
    const {
        compSet, toggleDeleteModal, toggleEditModal,
        handleCompSetSelect, fetchCompSetStatistics, addFlashMessage,
        setCompSetToDelete, setCompSetToEdit, activeSet, handleError
    } = props;

    const [downloadingCSV, setDownloadingCSV] = useState(false);
    const [csvDownloadData, setCsvDownloadData] = useState(false);
    const csvLinkRef = useRef();

    const getCSVData = async() => {
        try {
            setDownloadingCSV(true);
            const data = await fetchCompSetStatistics(compSet.id, true);
            const csvData = [['DATE', ...data.map(item => item.name)]];
            csvData.push(['', ...data.map(() => 'Articles')]);

            data[0].statistics_month.forEach((item, i) =>
                csvData.push([
                    `As of ${item.date}`,
                    ...data.map(value => {
                        return value.statistics_month[i].cnt;
                    }),
                ]),
            );

            csvData.push(['', ...data.map(() => '')]);

            data[0].statistics.forEach((item, i) =>
                csvData.push([
                    item.day,
                    ...data.map(value => {
                        return value.statistics[i].cnt;
                    }),
                ]),
            );
            setCsvDownloadData(csvData);
            setDownloadingCSV(false);
        } catch(err) {
            setDownloadingCSV(false);
            handleError(err);
        }
    };

    useEffect(() => {
        if(csvDownloadData) {
            setTimeout(() => {
                csvLinkRef.current.link.click();
                setCsvDownloadData(false);
            });
        }
    }, [csvDownloadData]);

    return (
        <tr className="alert-list-item">
            <td data-label="Name">
                {compSet.name}
            </td>
            <td data-label="Alerts">{compSet.alerts.length ? compSet.alerts.length : 0}</td>
            <td data-label="Compare">
                <Button
                    color={activeSet && activeSet.id === compSet.id ? 'primary' : 'light'}
                    onClick={() => handleCompSetSelect(compSet)}
                    disabled={!compSet.alerts.length}
                >
                    <i className="fa fa-bar-chart"/>
                </Button>
            </td>
            <td data-label="Download">
                {downloadingCSV ?
                    <Button
                        color="light"
                        style={{height: '35px'}}
                    >
                        <LoadingSpinner/>
                    </Button>
                    :
                    <Button
                        color="light"
                        onClick={getCSVData}
                    >
                        <i className="fa fa-download"/>
                    </Button>
                }
                {csvDownloadData ?
                    <CSVLink
                        ref={csvLinkRef}
                        data={csvDownloadData}
                        filename={
                            `ozmosys_cs_${compSet.name.replace(/ /g, '_')}_` +
                            moment()
                                .utc()
                                .format('MMDDYYYY') +
                            '.csv'
                        }
                    />
                    : undefined
                }
            </td>
            <td data-label="Edit/Delete">
                <div className="responsive-table__edit-block">
                    <Button
                        className="edit-delete-button"
                        color="light"
                        onClick={() => setCompSetToEdit(compSet)}
                    >
                        <i className="fa fa-edit fa-lg"/>
                    </Button>
                    <Button
                        className="delete-button"
                        color="light"
                        onClick={() => setCompSetToDelete(compSet)}
                    >
                        <i className="fa fa-trash fa-lg"/>
                    </Button>
                </div>
            </td>
        </tr>
    );
};

export default ComparativeSetListItem;
