import React from 'react';
import {Input} from 'reactstrap';
import SpinnerButton from 'components/SpinnerButton';
import ManageLabels from '../ManageLabels';
import InputWithError from 'components/InputWithError';

const TitleForm = (props) => {
    const {submitting, title, setTitleValue, handleSubmit, isEditForm, errors} = props;

    const onPressEnter = (e) => {
        if(e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <div className="mt-3 alert-title-form-container">
            <div className="alert-title-form__inner">
                <div className="w-100">
                    <InputWithError
                        type="text"
                        name="name"
                        onChange={setTitleValue}
                        onKeyUp={onPressEnter}
                        value={title}
                        placeholder="Content Name"
                        errorObj={errors}
                        marginBottomClass="mb-3"
                    />
                    {/*<SpinnerButton*/}
                    {/*    type="submit"*/}
                    {/*    color="primary"*/}
                    {/*    className="px-4 alert-title-form__btn alert-title-form__btn--desktop"*/}
                    {/*    onClick={handleSubmit}*/}
                    {/*    submitting={submitting}*/}
                    {/*    title={isEditForm ? 'Save' : 'Create'}*/}
                    {/*/>*/}
                </div>
                {/*{isEditForm && (*/}
                {/*    <div className="alert-title-form__labels-container">*/}
                {/*        <ManageLabels*/}
                {/*            selectedLabels={selectedLabels}*/}
                {/*            labels={labels}*/}
                {/*            removeFromSelected={removeFromSelected}*/}
                {/*            addToSelected={addToSelected}*/}
                {/*            addLabel={addLabel}*/}
                {/*            creatingLabel={creatingLabel}*/}
                {/*        />*/}
                {/*    </div>*/}

                {/*)}*/}
            </div>
            <SpinnerButton
                color="primary"
                type="submit"
                className="px-4 mb-2"
                onClick={handleSubmit}
                submitting={submitting}
                title={isEditForm ? 'Save' : 'Create'}
            />
        </div>
    );
};


export default TitleForm;
