import React, {useState, useEffect, useContext} from 'react';

import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap';

import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import SpinnerButton from 'components/SpinnerButton';
import LoadingSpinner from 'components/LoadingSpinner';
import {getAlertById, getAlerts, assignUsersToAlert} from 'utils/api/alertsAPI';
import {getUserGroups, getUsersWithoutParams} from 'utils/api/usersAPI';
import './style.scss';
import {useHistory, useParams} from 'react-router-dom';
import DualSelect from 'components/DualSelect';

const AssignAlert = () => {
    let history = useHistory();
    let {id} = useParams();

    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError} = useContext(AuthContext);
    const [tempAssignedUsers, setTempAssignedUsers] = useState([]);
    const [tempAssignedAlerts, setTempAssignedAlerts] = useState([]);
    const [loadingContent, setLoadingContent] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [users, setUsers] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [alertData, setAlertData] = useState(null);
    const [fetchingAlertData, setFetchingAlertData] = useState(true);
    const [fetchingUsers, setFetchingUsers] = useState(true);


    useEffect(() => {
        id && fetchAlertData();
        fetchUsers();
    }, []);

    const fetchAlertData = async() => {
        try {
            let alert = await getAlertById(id);
            setAlertData(alert);
            setSelectedUsers(alert.assignedUsers);
            setFetchingAlertData(false);
        } catch(err) {
            setFetchingAlertData(false);
            handleError(err);
        }
    };

    const fetchUsers = async() => {
        try {
            !fetchingUsers && setFetchingUsers(true);
            let userData = await getUsersWithoutParams(false, false, 1, 2000);
            let userGroupData = await getUserGroups();
            setUsers(userData.result);
            setUserGroups(userGroupData);
            setFetchingUsers(false);
        } catch(err) {
            setFetchingUsers(false);
            handleError(err);
        }
    };

    const onSubmit = async() => {
        try {
            setSubmitting(true);
            const params = {
                users: selectedUsers.map(user => user.id),
            };
            await assignUsersToAlert(id, params);
            setSubmitting(false);
            addFlashMessage('success', 'Alert successfully assigned');

            history.goBack();
        } catch(err) {
            setSubmitting(false);
            addFlashMessage('danger', 'Unable to assign alerts at this time');
        }
    };

    if(fetchingAlertData || fetchingUsers) return <LoadingSpinner padding text={'Fetching alert data'}/>;
    if(!fetchingAlertData && !alertData) return null;
    return (
        <div className="animated fadeIn view view--mobile-full">
            <Row>
                <Col xs="12">
                    <Card>
                        <CardHeader>
                            <h1>Assign</h1>
                        </CardHeader>
                        <CardBody>
                            {alertData &&
                            <div className="assign-alert__info">
                                <h2>{alertData.name}</h2>
                                <h6>Alert Keyword: &nbsp;
                                    {alertData.source.type === 'rss' ?
                                        <strong>RSS / XML</strong>
                                        :
                                        alertData.source.type  === 'email_publication' ?
                                            <strong>Email Publication</strong>
                                            :
                                        <strong>{(alertData.source.keyword && alertData.source.keyword.slice(0, 8) === 'intitle:') ? alertData.source.keyword.slice(8, alertData.source.keyword.length) : alertData.source.keyword}</strong>
                                    }
                                </h6>
                            </div>
                            }
                            <div className="mt-6">
                                <DualSelect
                                    selectedList={selectedUsers}
                                    fullList={users}
                                    groups={userGroups}
                                    onSelect={setSelectedUsers}
                                    contentType="users"
                                />
                                <SpinnerButton
                                    className="pull-right mt-4"
                                    color="primary"
                                    submitting={submitting}
                                    onClick={onSubmit}
                                    title="Assign"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AssignAlert;
