import React from 'react';

const ListItem = (props) => {
    const {isActive, label, handleSelect} = props;
    return (
        <div
            className={'list-label-list-item ' + (isActive && 'list-label-list-item--active')}
            onClick={() => handleSelect(label.name)}
        >
            {isActive && <i className="fa fa-check"/>}
            {label.name}
        </div>
    );
};

export default ListItem;
