import React from 'react';

import {truncate} from 'lodash';

import {convertDateString} from 'Helpers/convertDateString';
import {truncateWithoutWordBreak} from 'Helpers/truncateWithoutWordBreak';
import {truncateLongWords} from 'Helpers/truncateLongWords';

const Article = (props) => {
    const {handleAddArticle, handleRemoveArticle, assigned} = props;
    const {title, resource, createdat, id, abstract} = props.article;
    return (
        <div className="mobile-newsletter-article" id={id}>
            <div className="flex-grow-1">
                {title.length > 100 ?
                    <div className="mobile-newsletter-article__title">
                        <strong>{truncateWithoutWordBreak(truncateLongWords(title, 50), 100)}</strong>
                    </div>
                    : <div className="mobile-newsletter-article__title"><strong>{title}</strong></div>
                }
                {props.includeAbstracts &&
                <div className="mobile-newsletter-article__abstract">
                    {truncate(truncateLongWords(abstract, 50), {length: 150, separator: / /})}
                </div>
                }
                <div className="mobile-newsletter-article__info d-flex justify-content-between">
                    <div className="mobile-newsletter-article__publisher">{truncateLongWords(resource, 80)}</div>
                    <div className="mobile-newsletter-article__date">{convertDateString(createdat)}</div>
                </div>
            </div>
            <div className="mobile-newsletter-article__actions">
                {assigned ?
                    <div onClick={() => handleRemoveArticle(id)}>
                        <span>×</span>
                    </div>
                    :
                    <div onClick={() => handleAddArticle(id)}>
                        <i className="fa fa-plus"/>
                    </div>
                }
            </div>
        </div>
    );
};

export default Article;
