import React, {useEffect, useState} from 'react';

import {Button, Table, UncontrolledTooltip} from 'reactstrap';

import UsersListItem from './ListItem';
import {useHistory} from 'react-router-dom';
import DeleteUserModal from 'containers/Users/Modals/Delete';
import PreviewModal from 'containers/Users/Modals/LiveOrLastPreview';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchInput from 'components/SearchInput/SearchInputWithSubmit';
import PaginationWrapper from 'components/PaginationWrapper';
import ListLabelDropdown from 'components/Labels/ListLabelDropdown';
import UsersListMobileFilterDropdown from './UsersMobileFilterDropdown';
import UserCsvButton from './UserListCsvButton';

const OrganizationList = (props) => {
    let history = useHistory();

    const {
        users, fetchingData, allLabels, handleSearchValueSubmit, refreshUsers,
        handleClearSearchValue, submittingSearch, setFiltersObject, fetchCSV,
        handleActiveFilterLabelSelect, tempSearchValue, setTempSearchValue, totalPages,
        addFlashMessage, handleError, userFilters, downloadFilteredCsv, downloadFullCsv,
        fetchingCsv, isSingleUser,
    } = props;

    const {
        paginationCurrentPageNumber, activeSortProperty, activeSortDirection,
        activeFilterLabels,
    } = userFilters;

    const [userToDelete, setUserToDelete] = useState(null);
    const [userToPreview, setUserToPreview] = useState(null);
    const [previewType, setPreviewType] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [previewModalVisible, setPreviewModalVisible] = useState(false);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');

    const goToCreateUser = () => {
        history.push(`/account/users/create`);
    };

    const goToEditUser = (id) => {
        history.push(`/account/users/edit/${id}`);
    };

    const handlePageClick = (data) => {
        let filters = {...userFilters};
        filters.paginationCurrentPageNumber = data.selected + 1;
        filters.useCount = false;
        setFiltersObject('users', filters);
    };

    const handleSortableHeaderClick = (value) => {
        let filters = {...userFilters};
        if(value === activeSortProperty) {
            activeSortDirection === 'asc' ?
                filters.activeSortDirection = 'desc'
                :
                filters.activeSortDirection = 'asc';
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }
        setFiltersObject('users', filters);
    };

    const handleCaret = (value) => {
        if(activeSortProperty === value) {
            return activeSortDirection === 'asc' ?
                <i className="fa fa-caret-up list-sort-caret list-sort-caret--active"/>
                :
                <i className="fa fa-caret-down list-sort-caret list-sort-caret--active"/>;
        } else {
            return <i className="fa fa-caret-down list-sort-caret"/>;
        }
    };

    const handlePreviewClick = (user, type) => {
        setPreviewType(type);
        setUserToPreview(user);
    };

    const togglePreviewModal = () => {
        setPreviewModalVisible(!previewModalVisible);
    };

    useEffect(() => {
        previewType && userToPreview && togglePreviewModal();
    }, [previewType, userToPreview]);

    useEffect(() => {
        if(!previewModalVisible) {
            setPreviewType(null);
            setUserToPreview(null);
        }
    }, [previewModalVisible]);


    const handleDeleteButtonClick = (user) => {
        setUserToDelete(user);
    };

    const toggleDeleteModal = (user) => {
        setDeleteModalVisible(!deleteModalVisible);
    };

    useEffect(() => {
        userToDelete && toggleDeleteModal();
    }, [userToDelete]);

    const handleMobileFilterSelect = (val) => {
        let splitValue = val.split('-');
        let filters = {...userFilters};
        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject('users', filters);
    };

    const determineMobileFiltersValue = () => {
        let filterStr = userFilters.activeSortProperty + '-' + userFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    useEffect(() => {
        userFilters && determineMobileFiltersValue();
    }, [userFilters]);

    const clearActiveLabels = () => {
        let filters = {...userFilters};
        filters.activeFilterLabels = [];
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('users', filters);
    };


    return (
        <div className="list user-list">
            {fetchingData ?
                <LoadingSpinner/>
                :
                <div>
                    {!isSingleUser &&
                    <div>
                        {window.innerWidth > 1250 &&
                        <div className="list__utility-row list__utility-row--desktop">
                            <div className="list__search-container">
                                <SearchInput
                                    value={tempSearchValue}
                                    onChange={setTempSearchValue}
                                    placeholder="Filter by name or email"
                                    onClear={handleClearSearchValue}
                                    onSubmit={handleSearchValueSubmit}
                                    submitting={submittingSearch}
                                    list
                                />
                                <ListLabelDropdown
                                    labels={allLabels}
                                    activeFilterLabels={activeFilterLabels}
                                    handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                                />
                                {activeFilterLabels.length > 0 &&
                                <div
                                    className="ml-3">{activeFilterLabels.length + ' ' + ((activeFilterLabels.length === 1 ? 'label' : 'labels') + ' selected')}
                                    <span className="list__utility-row__clear-labels-btn"
                                          onClick={clearActiveLabels}>×</span></div>
                                }
                            </div>
                            <div className="d-flex">
                                <UserCsvButton
                                    downloading={fetchingCsv}
                                    downloadFilteredCsv={downloadFilteredCsv}
                                    downloadFullCsv={downloadFullCsv}
                                    desktopSize
                                />
                                <Button
                                    onClick={goToCreateUser}
                                    color="primary"
                                >Create User</Button>
                            </div>
                        </div>
                        }
                        {1250 >= window.innerWidth && window.innerWidth > 624 &&
                        <div className="list__utility-row list__utility-row--tablet">
                            <div className="d-flex justify-content-between w-100">
                                <div className="list__search-container">
                                    <SearchInput
                                        value={tempSearchValue}
                                        onChange={setTempSearchValue}
                                        placeholder="Filter by name or email"
                                        onClear={handleClearSearchValue}
                                        onSubmit={handleSearchValueSubmit}
                                        submitting={submittingSearch}
                                        list
                                    />
                                </div>
                            </div>
                            <div className="d-flex mt-2 w-100 justify-content-end">
                                <div className="mr-2 d-flex">
                                    <Button
                                        onClick={goToCreateUser}
                                        color="primary"
                                        className="mb-0"
                                    >
                                        <i className="fa fa-plus"/>&nbsp;
                                        Create User
                                    </Button>
                                </div>
                                <div className="mr-2">
                                    <ListLabelDropdown
                                        labels={allLabels}
                                        activeFilterLabels={activeFilterLabels}
                                        handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                                        useIconOnly
                                    />
                                </div>
                                <div>
                                    <UserCsvButton
                                        downloading={fetchingCsv}
                                        downloadFilteredCsv={downloadFilteredCsv}
                                        downloadFullCsv={downloadFullCsv}
                                    />
                                </div>
                                <UsersListMobileFilterDropdown
                                    handleMobileFilterSelect={handleMobileFilterSelect}
                                    activeMobileFilterValue={activeMobileFilterValue}
                                />
                            </div>
                        </div>
                        }
                        {624 >= window.innerWidth &&
                        <div className="list-utility-row list__utility-row--mobile">
                            <div className="d-flex mb-2">
                                <UserCsvButton
                                    downloading={fetchingCsv}
                                    downloadFilteredCsv={downloadFilteredCsv}
                                    downloadFullCsv={downloadFullCsv}
                                    showText
                                />
                                <Button
                                    onClick={goToCreateUser}
                                    color="primary"
                                    className="ml-1"
                                    style={{flex: '0 0 50%'}}
                                >
                                    <i className="fa fa-plus"/>&nbsp;
                                    Create User
                                </Button>
                            </div>
                            <div className="d-flex mb-2">
                                <UsersListMobileFilterDropdown
                                    handleMobileFilterSelect={handleMobileFilterSelect}
                                    activeMobileFilterValue={activeMobileFilterValue}
                                />
                                <div className="ml-2">
                                    <ListLabelDropdown
                                        labels={allLabels}
                                        activeFilterLabels={activeFilterLabels}
                                        handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                                        useIconOnly
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <SearchInput
                                    value={tempSearchValue}
                                    onChange={setTempSearchValue}
                                    placeholder="Filter by name or email"
                                    onClear={handleClearSearchValue}
                                    onSubmit={handleSearchValueSubmit}
                                    submitting={submittingSearch}
                                    list
                                />
                                {activeFilterLabels.length > 0 &&
                                <div className="list__utility-row__clear-labels-mobile">
                                <span>
                                    {activeFilterLabels.length + ' ' + ((activeFilterLabels.length === 1 ? 'label' : 'labels') + ' selected')}
                                    <span
                                        onClick={clearActiveLabels}
                                        className="list__utility-row__clear-labels-btn">×</span>
                                </span>
                                </div>
                                }
                            </div>
                        </div>
                        }
                    </div>
                    }

                    {users.length < 1 ?
                        <div>
                            <p>No users found</p>
                        </div>
                        :
                        <div>
                            <Table className="responsive-table">
                                <tbody>
                                <tr className="responsive-table header-row">
                                    <th
                                        className="responsive-table__sortable-th responsive-table--long-th"
                                        onClick={() => handleSortableHeaderClick('user.name')}
                                    >
                                        Name
                                        {handleCaret('user.name')}
                                    </th>
                                    <th
                                        className="responsive-table__sortable-th responsive-table--long-th"
                                        onClick={() => handleSortableHeaderClick('user.email')}
                                    >
                                        Email
                                        {handleCaret('user.email')}
                                    </th>
                                    <th>Admin</th>
                                    <th>Confirmed</th>
                                    {!isSingleUser &&
                                    <th className="responsive-table--label-th">Labels</th>
                                    }
                                    <th>Suspended</th>
                                    <th>Dashboard</th>
                                    <th>Prioritize</th>
                                    <th>Last Email</th>
                                    <th>Next Email</th>
                                    <th>{isSingleUser ? 'Edit' : 'Edit/Remove'} </th>
                                </tr>
                                {users.map((user) => {
                                    return (
                                        <UsersListItem
                                            key={user.id}
                                            user={user}
                                            goToEditUser={goToEditUser}
                                            handlePreviewClick={handlePreviewClick}
                                            handleDeleteButtonClick={handleDeleteButtonClick}
                                            isSingleUser={isSingleUser}
                                        />
                                    );
                                })}
                                </tbody>
                            </Table>
                            <PaginationWrapper
                                totalPages={totalPages}
                                handlePageClick={handlePageClick}
                                forcePage={paginationCurrentPageNumber - 1}
                            />
                        </div>
                    }
                </div>
            }
            {deleteModalVisible &&
            <DeleteUserModal
                userToDelete={userToDelete}
                isOpen={deleteModalVisible}
                toggle={toggleDeleteModal}
                addFlashMessage={addFlashMessage}
                handleError={handleError}
                refreshUsers={refreshUsers}
            />
            }
            {previewModalVisible &&
            <PreviewModal
                user={userToPreview}
                type={previewType}
                isOpen={previewModalVisible}
                toggle={togglePreviewModal}
                handleError={handleError}
            />
            }
        </div>
    );
};

export default OrganizationList;
