import React, {useEffect, useState, useContext} from 'react';

import moment from 'moment-timezone';

import {Label, FormGroup, Input} from 'reactstrap';
import ScheduleSelector from 'containers/Settings/AlertsSchedule/ScheduleSelector';
import SpinnerButton from 'components/SpinnerButton';
import LoadingSpinner from 'components/LoadingSpinner';
import {addOrganizationSchedule, deleteOrganizationSchedule, editOrganizationSchedule} from 'utils/api/profileAPI';
import './style.scss';
import {AuthContext} from 'contexts/AuthContext';
import {utils} from 'react-grid-layout/index';
import {timezones} from 'utils/timezones';

const AlertsSchedule = (props) => {
    const {handleError} = useContext(AuthContext);
    const {profile, addFlashMessage, refreshProfile} = props;
    const [frequency, setFrequency] = useState('once');
    const [firstAlertDays, setFirstAlertDays] = useState([]);
    const [firstAlertHour, setFirstAlertHour] = useState('9:00');
    const [firstAlertPeriod, setFirstAlertPeriod] = useState('AM');
    const [secondAlertDays, setSecondAlertDays] = useState([]);
    const [secondAlertHour, setSecondAlertHour] = useState('5:00');
    const [secondAlertPeriod, setSecondAlertPeriod] = useState('PM');
    const [fetchingScheduleData, setFetchingScheduleData] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);
    const [timezone, setTimezone] = useState(null);


    useEffect(() => {
        if(profile.organization) {
            let cnt = profile.organization.schedules.length;
            let {schedules} = profile.organization;
            if(cnt === 2) {
                setFrequency('twice');
                setFirstAlertDays(schedules[0].days);
                setSecondAlertDays(schedules[1].days);
                destructureTime(schedules[0].time, 'first');
                destructureTime(schedules[1].time, 'second');
            } else {
                setFrequency('once');
                setFirstAlertDays(schedules[0].days);
                destructureTime(schedules[0].time, 'first');
            }
            let foundTimezone = timezones.find(obj => {
                return obj.key === profile.organization.timezone;
            });
            setTimezone(foundTimezone.name)
            setFetchingScheduleData(false);
        }
    }, [profile.organization]);

    const destructureTime = (time, schedule) => {
        let hour = moment(time).utc().format('h');
        let period = moment(time).utc().format('A');
        if(schedule === 'first') {
            setFirstAlertPeriod(period);
            setFirstAlertHour(hour);
        } else {
            setSecondAlertPeriod(period);
            setSecondAlertHour(hour);
        }
    };

    const handleDaySelect = (position, day) => {
        let newDayArray = position === 'first' ? [...firstAlertDays] : [...secondAlertDays];
        if(newDayArray.indexOf(day) > -1) {
            newDayArray.splice(newDayArray.indexOf(day), 1);
        } else {
            newDayArray.push(day);
        }
        position === 'first' ? setFirstAlertDays(newDayArray) : setSecondAlertDays(newDayArray);
    };

    const handleSubmit = async() => {
        if(frequency === 'twice' &&
            (firstAlertHour === secondAlertHour) &&
            (firstAlertPeriod === secondAlertPeriod)
        ) {
            // let customError = {
            //     code: "VALIDATION_FAILED",
            //     errors: {sameSchedule: [{message: "Alert schedule times must be unique", code: "IS_BLANK_ERROR", payload: null}]},
            //     message: "Validation failed"
            // }
            // handleError(customError, setErrors);
            addFlashMessage('danger', 'Alert schedule times must be unique');
            return;
        }
        try {
            setSubmitting(true);
            setErrors(null);
            const firstParams = {
                time: moment(firstAlertHour + firstAlertPeriod, ['h:mmA']).format('HH:mm'),
                days: firstAlertDays,
            };
            const secondParams = frequency === 'twice' ? {
                time: moment(secondAlertHour + secondAlertPeriod, ['hh:mm A']).format('HH:mm'),
                days: secondAlertDays,
            } : null;
            if(profile.organization.schedules.length === 1) {
                if(frequency === 'twice') {
                    await editOrganizationSchedule(profile.organization.schedules[0].id, firstParams);
                    await addOrganizationSchedule(secondParams);
                } else {
                    await editOrganizationSchedule(profile.organization.schedules[0].id, firstParams);
                }
            } else {
                if(frequency === 'once') {
                    await editOrganizationSchedule(profile.organization.schedules[0].id, firstParams);
                    await deleteOrganizationSchedule(profile.organization.schedules[1].id);
                } else {
                    await editOrganizationSchedule(profile.organization.schedules[0].id, firstParams);
                    await editOrganizationSchedule(profile.organization.schedules[1].id, secondParams);
                }
            }
            setSubmitting(false);
            await refreshProfile();
            addFlashMessage('success', 'Schedule successfully updated');
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors, addFlashMessage);
        }
    };

    return fetchingScheduleData ?
        <div className="mt-4">
            <LoadingSpinner/>
        </div>
        :
        (
            <div className="alerts-schedule">
                <h4 className="settings__page-header">Schedule</h4>
                <div className="mt-3">
                    <div className="alerts-schedule__frequency">
                        <Label>Daily Frequency</Label>
                        <FormGroup
                            // style={{marginLeft: '4.5rem'}}
                            className="daily__schedule"
                            check
                            inline
                        >
                            <Label check>
                                <Input
                                    onChange={e => setFrequency(e.target.name)}
                                    type="radio"
                                    name="once"
                                    checked={frequency === 'once'}
                                />
                                Once
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    onChange={e => setFrequency(e.target.name)}
                                    type="radio"
                                    name="twice"
                                    checked={frequency === 'twice'}
                                />
                                Twice
                            </Label>
                        </FormGroup>
                    </div>
                    <ScheduleSelector
                        position="first"
                        selectedDays={firstAlertDays}
                        selectedHour={firstAlertHour}
                        handleHourSelect={setFirstAlertHour}
                        selectedPeriod={firstAlertPeriod}
                        handlePeriodSelect={setFirstAlertPeriod}
                        handleDaySelect={handleDaySelect}
                        errorObj={errors}
                        timezone={timezone}
                    />
                    {frequency === 'twice' &&
                    <ScheduleSelector
                        position="second"
                        selectedDays={secondAlertDays}
                        selectedHour={secondAlertHour}
                        handleHourSelect={setSecondAlertHour}
                        selectedPeriod={secondAlertPeriod}
                        handlePeriodSelect={setSecondAlertPeriod}
                        handleDaySelect={handleDaySelect}
                        errorObj={errors}
                        timezone={timezone}
                    />
                    }
                </div>
                <div className="mt-6 mb-4 d-flex">
                    <SpinnerButton
                        color="primary"
                        submitting={submitting}
                        onClick={handleSubmit}
                        title={'Save Changes'}
                        className="px-4"
                    />
                </div>
            </div>
        );
};

export default AlertsSchedule;
