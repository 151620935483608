import React from 'react';
import {Draggable} from 'react-beautiful-dnd';

const SortableAlert = ({alert, index}) => {
    return (
        <Draggable draggableId={String(alert.id)} index={index}>
            {(provided, snapshot) => (
                <div className={'sortable-alert ' + (snapshot.isDragging ? 'sortable-alert--is-dragging' : '')}
                     {...provided.draggableProps}
                     {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    {alert.name}
                </div>
            )}
        </Draggable>
    );
};

SortableAlert.propTypes = {};

export default SortableAlert;
