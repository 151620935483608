import React, {useState} from 'react';

import {Modal, ModalFooter, Button} from 'reactstrap';
import SpinnerButton from 'components/SpinnerButton';
import ModalBodyWithClose from 'components/ModalBodyWithClose';
import InputWithError from 'components/InputWithError';

const DeleteModal = (props) => {
    const {isOpen, toggle, onDelete, errors, deleting} = props;
    const [confirmValue, setConfirmValue] = useState('');

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBodyWithClose toggle={toggle}>
                All Newsdesk feeds and user assignments will be deleted without the option to restore.
                <div className="mt-2 mb-3">
                    Please acknowledge this deletion by typing DELETE in the field below.
                </div>
                <InputWithError
                    // prependIcon={<i className="fa fa-tag"/>}
                    name="jwtToken"
                    value={confirmValue}
                    type="text"
                    onChange={setConfirmValue}
                    errorObj={errors}
                    marginBottomClass="mb-1"
                />
            </ModalBodyWithClose>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Back to safety</Button>{' '}
                <SpinnerButton
                    color="danger"
                    title="Delete"
                    onClick={onDelete}
                    submitting={deleting}
                    disabled={confirmValue !== 'DELETE'}
                />
            </ModalFooter>
        </Modal>
    );
};

export default DeleteModal;
