import React, {useState, useEffect} from 'react';

import {Bar} from 'react-chartjs-2';
import moment from 'moment-timezone';

const ComparativeAnalysisChart = (props) => {
    const {activeSetStats, chartKey, activeIndex} = props;

    const [dataArray, setDataArray] = useState([]);
    const [labels, setLabels] = useState(null);
    const mobileView = window.innerWidth < 650;

    useEffect(() => {
        if(activeSetStats && activeSetStats.length) {
            setDataArray(activeSetStats);
            let labelData = activeSetStats[0].statistics.map(item => item.day);
            setLabels(labelData);
        }

    }, [activeSetStats]);

    const getMonth = (index) => {
        let dateObj = new Date(
            (dataArray[0].statistics[index].day + ' 00:00:00').replace(
                ' ',
                'T',
            ),
        );
        let momentObj = moment(dateObj);
        return momentObj.format('MMM');
    };

    const getValue = (item, index, ticks) => {
        const width = window.innerWidth;
        if(index === 0) {
            return getMonth(index);
        }
        if(index % 4 === 0 && width < 600) {
            if(item[0] === '0' && +ticks[index - 4][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }
        if(index % 2 === 0 && width > 600) {
            if(item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }
    };

    const convertDateString = (dateString) => {
        if(!dateString) return null;
        let obj = new Date(dateString);
        let tz = moment.tz.guess();
        return moment(obj).tz(tz).format('DD');
    };

    const buildLabelDate = (dateString) => {
        if(!dateString) return null;
        let obj = new Date(dateString);
        let tz = moment.tz.guess();
        return moment(obj).tz(tz).format('MMM DD,YYYY');
    };

    const buildMobileLabel = (string) => {
        if(mobileView && string.length > 30) {
            return string.slice(0, 25) + '...';
        }
        return string;
    };

    const filterListByDate = element => {
        this.props.filterByDate(
            moment(element[0]._model.label).format('YYYY-MM-DD'),
        );
    };

    let data;

    const getThemedColor = i => {
        const colorArray = [
            'rgb(26,148,199)',
            'rgb(1,26,84)',
            'rgb(3,189,234)',
            'rgb(144,204,254)',
            'rgb(0, 102, 153)',
            'rgb(30,144,255)',
            'rgb(25,25,112)',
            'rgb(0,0,128)',
            'rgb(0,0,205)',
            'rgb(0,0,255)',
            'rgb(65,105,225)',
            'rgb(147,112,219)',
            'rgb(106,90,205)',
            'rgb(72,61,139)',
            'rgb(75,0,130)',
        ];
        return colorArray[i];
    };

    const newDataset = dataArray.map((item, i) => {
        let randomColor = Math.floor(Math.random()*16777215).toString(16);
        return {
            label: buildMobileLabel(item.name),
            borderWidth: mobileView ? 2 : 4,
            data: item.statistics.map(item => item.cnt),
            borderColor: i >= 15 ? `#${randomColor}` : getThemedColor(i),
            backgroundColor: i >= 15 ? `#${randomColor}` : getThemedColor(i),
            fill: false,
            type: 'line',
            datalabels: {
                display: false,
            },
        };
    });
    data = {
        labels: labels && labels.map(item => convertDateString(item)),
        datasets: newDataset,
    };

    return (
        <div style={{maxHeight: mobileView ? 500 : 300}}>
            <Bar
                key={chartKey}
                data={data}
                height={mobileView ? 500 : 300}
                responsive={false}
                options={{
                    legend: {
                        display: activeSetStats?.length <= 20,
                        position: 'top',
                        paddingLeft: 10,
                        labels: {
                            boxWidth: mobileView ? 20 : 40,
                            fontSize: mobileView ? 15 : 12,
                        },
                    },
                    tooltips: {
                        callbacks: {
                            title: (tooltipItem) => {
                                return buildLabelDate(
                                    dataArray[0].statistics[tooltipItem[0].index].day,
                                );
                            },
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                ticks: {
                                    stepSize: 1,
                                    autoSkip: false,
                                    maxTicksLimit: 15,
                                    userCallback: (item, index, ticks) => {
                                        return getValue(item, index, ticks);
                                    },
                                },
                            },
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            color: 'white',
                            borderRadius: 6,
                            font: {
                                size: 12,
                            },
                            formatter: function(value) {
                                if(value > 0) {
                                    return value;
                                } else {
                                    return null;
                                }
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

export default ComparativeAnalysisChart;