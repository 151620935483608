import React, { useState, useEffect, useContext } from 'react';
import './SourceLookup.scss';
import {Input} from 'reactstrap';
import {searchJointSources, sendRequestSource} from 'utils/api/alertsAPI';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import SpinnerButton from 'components/SpinnerButton';

const TERM_MIN_LENGTH = 3;
const defaultList = [{domain: 'No options'}];

const SourceLookup = () => {
    const [list, setList] = useState(defaultList);
    const [value, setValue] = useState('');
    const [submittingSearch, setSubmittingSearch] = useState(false);
    const [submittingRequest, setSubmittingRequest] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const {addFlashMessage} = useContext(FlashMessageContext);

    useEffect(async () => {
        if (value.length >= TERM_MIN_LENGTH) {
            setSubmittingSearch(true);
            const foundSources = await searchJointSources(value);
            setList(foundSources);
            setIsNotFound(!foundSources.length)
            setSubmittingSearch(false);
        } else {
            setList(defaultList);
            setIsNotFound(false);
        }
    }, [value])

    const handleFocus = () => {
        setDropdownOpen(true);
    };

    const handleBlur = () => {
        if (isNotFound === false) {
            setDropdownOpen(false);
        }
    };

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleClear = () => {
        setValue('');
        setList(defaultList);
        setDropdownOpen(false);
    };

    const handleRequestSource = async () => {
        try {
            setSubmittingRequest(true);
            await sendRequestSource(value);
            addFlashMessage('success', 'A request this source has been sent.');
        } catch(err) {
            addFlashMessage('danger', 'An error occurred while sending.');
        } finally {
            setSubmittingRequest(false);
        }
    };

    return (
        <div className=""
             style={{
                 width: '100%',
                 minHeight: '35px',
                 position: 'relative',
             }}
        >
            <div
                className={'search-input-container'}>
                <Input
                    placeholder="Source Lookup"
                    type="text"
                    className={'search-input'}
                    value={value}
                    onChange={(e) => handleChange(e)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {submittingSearch ?
                    <div className="search-input__icon-container">
                        <i className="fa fa-spinner fa-spin search-input__icon search-input__icon--search"/>
                    </div>
                    :
                    <div className="search-input__icon-container d-flex">
                        {value.length > 0 &&
                        <i
                            className="fa fa-times-circle search-input__icon search-input__icon--close"
                            onClick={handleClear}
                        />
                        }
                        <i
                            className={'fa fa-caret-down search-input__icon search-input__icon--search'}
                        />
                    </div>
                }
            </div>

            <div style={{position: 'relative', width: '100%'}}>
                <div className="sl-textarea-autocomplete"
                     style={{display: dropdownOpen ? 'block' : 'none'}}>
                    {!isNotFound && list.map(item => (
                        <div className="sl-textarea-autocomplete-list-item"
                             key={item.domain}
                        >
                            {item.domain}
                        </div>
                    ))}
                    {isNotFound &&
                        <div>
                            <div className='request-source-text'>No matches</div>
                            <SpinnerButton
                                color="primary"
                                type="button"
                                className="request-source-button"
                                onClick={handleRequestSource}
                                submitting={submittingRequest}
                                title={'Request this source'}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default SourceLookup;
