import React, {useEffect, useState} from 'react';

import {DragDropContext, Droppable} from 'react-beautiful-dnd';

import AddSectionForm from './AddSectionForm';
import Section from './Section';
import UnassignedSection from 'containers/Newsletters/ManageContent/Desktop/ManageSources/Sections/UnassignedSection';
import './style.scss';
import PreviewNewsletterModal from 'containers/Newsletters/Modals/Preview';
import NotesForm from 'containers/Newsletters/ManageContent/Desktop/ManageSources/Sections/NotesForm';

const Sections = (props) => {
    const {
        refreshNewsletter, addFlashMessage, newsletterData,
        assignAlertToSection, removeAllAlertsFromSection,
        handleSectionSorting, savingSectionChanges, handleError,
        moveAlertBetweenSections, resortAlertsInSameSection
    } = props;
    const [sections, setSections] = useState([]);
    const [sectionDragInfo, setSectionDragInfo] = useState({destinationId: '', sourceId: ''});

    useEffect(() => {
        setSections(newsletterData.sections);
    }, [newsletterData.sections]);

    const onDragEnd = (result) => {
        const {destination, source, draggableId, type} = result;
        if(!destination || savingSectionChanges) return;
        if(destination.droppableId === source.droppableId && destination.index === source.index) {
            setSectionDragInfo({destinationId: '', sourceId: ''});
            return;
        }

        if(type === 'section') {
            const existingSectionOrder = Array.from(newsletterData.sections);
            const sectionToMove = newsletterData.sections.find(obj => obj.id == draggableId);

            existingSectionOrder.splice(existingSectionOrder.indexOf(sectionToMove), 1);
            existingSectionOrder.splice(destination.index, 0, sectionToMove);
            let reversedArr = [...existingSectionOrder].reverse();
            setSections(existingSectionOrder);
            let sectionIdArray = reversedArr.map((item, index) => {
                return {
                    id: item.id,
                    sortOrder: index,
                };
            });
            handleSectionSorting(sectionIdArray);
            setSectionDragInfo({destinationId: '', sourceId: ''});
        } else {
            // moveAlertBetweenNewsletterSections(id, alertId, previousSectionId, newSectionId)
            let alertId = draggableId;
            let nextSectionId = destination.droppableId;
            let prevSectionId = source.droppableId;
            setSectionDragInfo({destinationId: '', sourceId: ''});
            if(nextSectionId === prevSectionId) {
                return;
                // resortAlertsInSameSection(Number(alertId), Number(nextSectionId), Number(destination.index) + 1)
            } else {
                moveAlertBetweenSections(Number(alertId), Number(prevSectionId), Number(nextSectionId), Number(destination.index) + 1);
            }
        }
    };

    const handleOnDragUpdate = (result) => {
        if(result.source && result.destination) {
            setSectionDragInfo({destinationId: result.destination.droppableId, sourceId: result.source.droppableId});
        }
    };


    return (
        <div className="newsletter-content-panel newsletter-content-panel--left">
            <NotesForm
                newsletterData={newsletterData}
                refreshNewsletter={refreshNewsletter}
                addFlashMessage={addFlashMessage}
            />
            <div className="newsletter-content-panel__header">
                <h4>Sections</h4>
                {savingSectionChanges && <div style={{color: 'gray'}}>Saving...</div>}
            </div>
            <div className="newsletter-content-panel__body">
                <AddSectionForm
                    refreshNewsletter={refreshNewsletter}
                    addFlashMessage={addFlashMessage}
                    newsletterId={newsletterData.id}
                    savingSectionChanges={savingSectionChanges}
                />
                <div className="newsletter-content-panel__section-instructions">
                    Drag and drop sections or alerts to change their order. Click on section titles to edit.<br/>
                    <strong>*For curated newsletters, you must continue to the next section to add articles</strong>
                </div>
                <DragDropContext
                    // onDragStart={onDragStart}
                    onDragUpdate={handleOnDragUpdate}
                    onDragEnd={onDragEnd}
                >
                    <Droppable droppableId={'section-list'} type="section">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                className="newsletter-content-panel__section-list"
                                {...provided.droppableProps}
                            >
                                {sections && sections.map((section, index) => {
                                    return section.name !== 'Unassigned' &&
                                        <Section
                                            section={section}
                                            index={index}
                                            key={section.id}
                                            assignAlertToSection={assignAlertToSection}
                                            removeAllAlertsFromSection={removeAllAlertsFromSection}
                                            addFlashMessage={addFlashMessage}
                                            newsletterId={newsletterData.id}
                                            refreshNewsletter={refreshNewsletter}
                                            handleError={handleError}
                                            sectionDragInfo={sectionDragInfo}
                                        />;
                                })}
                                {provided.placeholder}
                                {sections.find(section => section.name === 'Unassigned') &&
                                <UnassignedSection
                                    section={sections.find(section => section.name === 'Unassigned')}
                                    assignAlertToSection={assignAlertToSection}
                                    removeAllAlertsFromSection={removeAllAlertsFromSection}
                                    handleError={handleError}
                                    sectionDragInfo={sectionDragInfo}
                                />
                                }
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    );
};

export default Sections;
