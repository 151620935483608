import React from 'react';

import {Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';

import Logo from 'images/logo.png';
import {convertDateString} from 'Helpers/convertDateString';
import {truncateWithoutWordBreak} from 'Helpers/truncateWithoutWordBreak';
import {truncateLongWords} from 'Helpers/truncateLongWords';

const ArticleView = (props) => {
    const {selectedArticle, isPublic} = props;
    return (
        <div className="reader-article-view">
            <div className="animated fadeIn w-100">
                {isPublic &&
                <div className="reader-article-view__public-header">
                    <div className="public-reader-logo">
                        <a href="/" target="_blank">
                            <img src={Logo} alt="logo"/>
                        </a>
                    </div>
                </div>
                }
                <div>
                    {props.toolbar &&
                    <div className="reader-article-view__toolbar">
                        <Link to="/account/users">
                            <i className="fa fa-arrow-left reader-article-view__back-btn"/>
                        </Link>
                    </div>
                    }
                    <div className="reader-article-view__body">
                        {!selectedArticle ?
                            <Row>
                                <Col xs="12">
                                    <h2 className="reader-article-view__title reader-article-view__title--empty">
                                        Select an article to get started
                                    </h2>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col xs="12">
                                    {selectedArticle.title &&
                                    <h2 className="reader-article-view__title"
                                        dangerouslySetInnerHTML={{__html: selectedArticle.title}}
                                    />
                                    }
                                    {selectedArticle.resource && selectedArticle.resource.length < 34 ?
                                        <div className="reader-article-view__info">
                                            <div
                                                className="reader-article-view__source">{selectedArticle.resource}</div>
                                            &nbsp; &#183; &nbsp;
                                            <div
                                                className="reader-article-view__date">{convertDateString(selectedArticle.created_at)}</div>
                                        </div>
                                        : <div>
                                            <div
                                                className="reader-article-view__source">{selectedArticle.resource}</div>
                                            <div
                                                className="reader-article-view__date">{convertDateString(selectedArticle.created_at)}</div>
                                        </div>
                                    }
                                    {/*{selectedArticle.mediaBias ?
                                        <div
                                            className="reader-article-view__bias">Bias: {selectedArticle.mediaBias}</div>
                                        : ''}*/}
                                    <div
                                        className="reader-article-view__content"
                                        dangerouslySetInnerHTML={{__html: truncateLongWords(selectedArticle.abstract, 50)}}
                                    />
                                </Col>
                                <Col xs="12">
                                    <a className="btn btn-primary pull-right reader-article-view__read-more-btn"
                                       target="_blank" href={selectedArticle.url}>Read More</a>
                                </Col>
                            </Row>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArticleView;
