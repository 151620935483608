import React, {useContext, useState, useEffect} from 'react';

import {AuthContext} from 'contexts/AuthContext';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {FilterContext} from 'contexts/FilterContext';

import SingleUserBlock from 'components/SingleUserBlock';
import UserGroupsList from './List';
import EmptyList from './EmptyList';
import LoadingSpinner from 'components/LoadingSpinner';
import {getUserGroups, assignUsersToUserGroup, deleteUserGroup, editUserGroup} from 'utils/api/usersAPI';
import './style.scss';
import CreateUserGroupModal from 'containers/Users/UserGroups/Modals/CreateUserGroup';

const UserGroups = () => {
    const {state, handleError} = useContext(AuthContext);
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {userGroupFilters, setFiltersObject} = useContext(FilterContext);
    const [userGroups, setUserGroups] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);

    useEffect(() => {
        !state.isSingleUser && fetchUserGroups();
    }, []);

    const fetchUserGroups = async() => {
        try {
            // setFetchingData(true);
            const userGroupData = await getUserGroups();
            setUserGroups(userGroupData);
            setFetchingData(false);
        } catch(err) {
            setFetchingData(false);
            handleError(err);
        }
    };

    return (
        <div>
            {state.isSingleUser ?
                <SingleUserBlock itemType="User groups"/>
                :
                fetchingData ?
                    <LoadingSpinner/>
                    :
                    userGroups.length > 0 ?
                        <UserGroupsList
                            userGroups={userGroups}
                            fetchingData={fetchingData}
                            addFlashMessage={addFlashMessage}
                            refreshUserGroups={fetchUserGroups}
                            handleError={handleError}
                            userGroupFilters={userGroupFilters}
                            setFiltersObject={setFiltersObject}
                        />
                        :
                        <EmptyList
                            addFlashMessage={addFlashMessage}
                            refreshUserGroups={fetchUserGroups}
                            handleError={handleError}
                        />
            }
        </div>
    );
};

export default UserGroups;
