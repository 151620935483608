import React, { useState, useEffect } from 'react';

import { Card, CardBody, CardHeader, Col, Row, Button, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';

import TabBar from 'components/TabBar';
import ManageSources from './ManageSources';
import ReviewArticles from './ReviewArticles';
import PreviewNewsletterModal from 'containers/Newsletters/Modals/Preview';
import AssignNewsletterModal from 'containers/Newsletters/Modals/Assign';
import EditNewsletterModal from 'containers/Newsletters/Modals/CreateAndEdit/EditNewsletter';
import { sendInstantNewsletter } from 'utils/api/newslettersAPI';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinnerSmall';
import './style.scss';

const ManageContentDesktop = (props) => {
    const { addFlashMessage, newsletterData, refreshNewsletter, handleError, initialLoad } = props;
    const [activeView, setActiveView] = useState('sources');
    const [previewNewsletterModalOpen, setPreviewNewsletterModalOpen] = useState(false);
    const [assignNewsletterModalOpen, setAssignNewsletterModalOpen] = useState(false);
    const [editNewsletterModalOpen, setEditNewsletterModal] = useState(false);
    const [sendingNewsletter, setSendingNewsletter] = useState(false);
    const scheduleSettings = newsletterData.scheduleSettings;

    useEffect(() => {
        if (!document.body.classList.contains('sidebar-minimized')) {
            document.body.classList.toggle('sidebar-minimized');
        }
    }, []);

    const handleActiveView = () => {
        switch (activeView) {
            case 'sources':
                return (
                    <ManageSources
                        newsletterData={newsletterData}
                        addFlashMessage={addFlashMessage}
                        refreshNewsletter={refreshNewsletter}
                        handleError={handleError}
                    />
                );
            case 'articles':
                return (
                    <ReviewArticles
                        newsletterData={newsletterData}
                        addFlashMessage={addFlashMessage}
                        refreshNewsletter={refreshNewsletter}
                        handleError={handleError}
                    />
                );
            default:
                return (
                    <ManageSources
                        newsletterData={newsletterData}
                        addFlashMessage={addFlashMessage}
                        refreshNewsletter={refreshNewsletter}
                        handleError={handleError}
                    />
                );
        }
    };

    useEffect(() => {
        !initialLoad && refreshNewsletter();
    }, [activeView]);

    const sendInstantDeliveryNewsletter = async (id) => {
        try {
            setSendingNewsletter(true);
            await sendInstantNewsletter(id);
            addFlashMessage('success', 'Newsletter successfully sent');
            refreshNewsletter();
            setSendingNewsletter(false);
        } catch (err) {
            if (err.message === 'Content is empty') {
                addFlashMessage('danger', 'This newsletter is empty. Articles must be added before a newsletter can be sent.');
            } else {
                addFlashMessage('danger', 'Unable to send newsletter at this time');
            }
            setSendingNewsletter(false);
        }
    };

    const toggleNewsletterPreviewModal = () => setPreviewNewsletterModalOpen(!previewNewsletterModalOpen);
    const toggleAssignNewsletterModal = () => setAssignNewsletterModalOpen(!assignNewsletterModalOpen);
    const toggleEditNewsletterModal = () => setEditNewsletterModal(!editNewsletterModalOpen);

    return (
        <div className="view mt-0 d-flex h-100">
            <Row className="flex-1">
                <Col xs="12" className="d-flex">
                    <Card className="flex-1">
                        <CardHeader>
                            <div className="flex-space-between">
                                <h2 className="card-header--small">
                                    Newsletter Content: {newsletterData.name}{' '}
                                    {scheduleSettings?.instant_delivery_email || scheduleSettings?.specific_date_time ? '(Curated)' : '(Automatic)'}
                                </h2>
                            </div>
                        </CardHeader>
                        <CardBody className="card-body--small d-flex flex-column">
                            <div className="newsletter-content__header">
                                <TabBar
                                    activeItem={activeView}
                                    setActive={setActiveView}
                                    items={[
                                        { data: 'sources', name: 'Manage Sources' },
                                        ...(!scheduleSettings?.daily?.first?.days_of_week?.length &&
                                        !scheduleSettings?.daily?.second?.days_of_week?.length &&
                                        !scheduleSettings?.as_available
                                            ? [
                                                  {
                                                      data: 'articles',
                                                      name: 'Review' + ' Articles',
                                                  },
                                              ]
                                            : []),
                                    ]}
                                />
                                <div className="newsletter-content__top-button-row">
                                    <Link to={'/account/newsletters'}>
                                        <Button color="primary">
                                            <i className="fa fa-chevron-left" /> Newsletters
                                        </Button>
                                    </Link>
                                    <Button color="primary" onClick={toggleNewsletterPreviewModal}>
                                        Preview
                                    </Button>
                                    {!scheduleSettings?.instant_delivery_email && (
                                        <Button color="primary" onClick={toggleAssignNewsletterModal}>
                                            Assign
                                        </Button>
                                    )}
                                    <Button color="primary" onClick={toggleEditNewsletterModal}>
                                        Schedule
                                    </Button>
                                    {scheduleSettings?.instant_delivery_email && (
                                        <>
                                            <UncontrolledTooltip
                                                placement="bottom"
                                                target="send-instant-delivery-button"
                                                delay={{ show: 200, hide: 0 }}>
                                                Send Now
                                            </UncontrolledTooltip>
                                            <Button
                                                id="send-instant-delivery-button"
                                                color="light"
                                                onClick={() => sendInstantDeliveryNewsletter(newsletterData.id)}>
                                                {sendingNewsletter ? <LoadingSpinner /> : <i className="fa fa-paper-plane" />}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                            {handleActiveView()}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {previewNewsletterModalOpen && (
                <PreviewNewsletterModal
                    isOpen={previewNewsletterModalOpen}
                    toggle={toggleNewsletterPreviewModal}
                    newsletter={newsletterData}
                    addFlashMessage={addFlashMessage}
                />
            )}
            {assignNewsletterModalOpen && (
                <AssignNewsletterModal
                    isOpen={assignNewsletterModalOpen}
                    toggle={toggleAssignNewsletterModal}
                    newsletter={newsletterData}
                    addFlashMessage={addFlashMessage}
                    refreshNewsletter={refreshNewsletter}
                />
            )}
            {editNewsletterModalOpen && (
                <EditNewsletterModal
                    isOpen={editNewsletterModalOpen}
                    toggle={toggleEditNewsletterModal}
                    newsletter={newsletterData}
                    addFlashMessage={addFlashMessage}
                    refreshNewsletters={refreshNewsletter}
                />
            )}
        </div>
    );
};

export default ManageContentDesktop;
