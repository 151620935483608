import React, { useState, useEffect, useContext } from 'react';

import { Col, Row, FormGroup, Input } from 'reactstrap';

import AlertStatistics from './AlertStatistics';
import ComparativeAnalysis from './ComparativeAnalysis';
import NewsletterStatistics from './NewsletterStatistics';
import { getClicksPerDay } from 'utils/api/statisticsAPI';
import './style.scss';
import addToHomescreen from '../../../node_modules/add-to-homescreen/dist/addtohomescreen.min'; // eslint-disable-line
import addToHomescreenCSS from '../../../node_modules/add-to-homescreen/dist/style/addtohomescreen.css';
import { AuthContext } from 'contexts/AuthContext'; // eslint-disable-line

const Dashboard = () => {
    const { state, handleError } = useContext(AuthContext);
    const [activeView, setActiveView] = useState('alert-statistics');
    const isHasAccessComparativeSet = state?.profile?.organization?.accessComparativeSet;

    const fetchClicksPerDay = async () => {
        try {
            const params = {
                days: 30,
            };
            await getClicksPerDay(params);
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        fetchClicksPerDay();
        window.addToHomescreen();
    }, []);

    const handleTabChange = () => {
        switch (activeView) {
            case 'alert-statistics':
                return <AlertStatistics handleError={handleError} />;
            case 'comparative-analysis':
                return <ComparativeAnalysis handleError={handleError} />;
            case 'newsletter-statistics':
                return <NewsletterStatistics handleError={handleError} />;
            default:
                return <AlertStatistics handleError={handleError} />;
        }
    };

    const alertStatsActive = activeView === 'alert-statistics' ? 'dashboard__tab--active' : '';
    const compActive = activeView === 'comparative-analysis' ? 'dashboard__tab--active' : '';
    const newsStatsActive = activeView === 'newsletter-statistics' ? 'dashboard__tab--active' : '';

    return (
        <div className="view dashboard">
            <div className="animated fadeIn mb-4">
                <Row>
                    <Col xs="12" className="col-no-padding-mobile">
                        {window.innerWidth > 600 ? (
                            <Col xs="12" className="m-0 p-0">
                                <div className="dashboard__tab-container">
                                    <div className={'dashboard__tab ' + alertStatsActive} onClick={() => setActiveView('alert-statistics')}>
                                        Content Statistics
                                    </div>
                                    <div className={'dashboard__tab ' + newsStatsActive} onClick={() => setActiveView('newsletter-statistics')}>
                                        Newsletter Statistics
                                    </div>
                                    {!!isHasAccessComparativeSet && (
                                        <div className={'dashboard__tab ' + compActive} onClick={() => setActiveView('comparative-analysis')}>
                                            Comparative Analysis
                                        </div>
                                    )}
                                </div>
                            </Col>
                        ) : (
                            <div
                                style={{
                                    width: '100%',
                                    marginBottom: '20px',
                                }}
                                className="account__menu">
                                <FormGroup>
                                    <Input onChange={(e) => setActiveView(e.target.value)} type="select" name="menu">
                                        <option value="alert-statistics">Alert Statistics</option>
                                        <option value="newsletter-statistics">Newsletter Statistics</option>
                                        {!!isHasAccessComparativeSet && <option value="comparative-analysis">Comparative Analysis</option>}
                                    </Input>
                                </FormGroup>
                            </div>
                        )}
                    </Col>
                    <Col xs="12" className="col-no-padding-mobile">
                        {handleTabChange()}
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Dashboard;
