import React from 'react';

import {Label, FormGroup, Input} from 'reactstrap';
import ScheduleSelector from './ScheduleSelector';
import LoadingSpinner from 'components/LoadingSpinner';

const EmailSchedule = (props) => {
    const {errors, timezoneDisplay, useCustomSchedule, frequency, setFrequency, firstAlertDays,
        firstAlertHour, firstAlertPeriod, secondAlertDays, secondAlertHour, secondAlertPeriod,
        fetchingScheduleData, setFirstAlertPeriod, setFirstAlertHour,
        setSecondAlertPeriod, setSecondAlertHour, setFirstAlertDays,
        setSecondAlertDays, setUseCustomSchedule
    } = props;

    const handleDaySelect = (position, day) => {
        let newDayArray = position === 'first' ? [...firstAlertDays] : [...secondAlertDays];
        if(newDayArray.indexOf(day) > -1) {
            newDayArray.splice(newDayArray.indexOf(day), 1);
        } else {
            newDayArray.push(day);
        }
        position === 'first' ? setFirstAlertDays(newDayArray) : setSecondAlertDays(newDayArray);
    };
    return (
        <div className="mb-3">
            <h4 className="user-form__header">Schedule</h4>
            <div className="mt-3">
                {fetchingScheduleData ?
                    <LoadingSpinner/>
                    :
                    <div>
                        <div className="mt-3">
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        name="useCustomSchedule"
                                        checked={useCustomSchedule}
                                        onChange={() => setUseCustomSchedule(!useCustomSchedule)}
                                    />{' '}
                                    Use custom schedule
                                </Label>
                            </FormGroup>
                        </div>
                        {useCustomSchedule &&
                        <div className="mt-3">
                            <div className="user-form__frequency">
                                <Label>Daily Frequency</Label>
                                <FormGroup
                                    // style={{marginLeft: '4.5rem'}}
                                    className="daily__schedule"
                                    check
                                    inline
                                >
                                    <Label check>
                                        <Input
                                            onChange={e => setFrequency(e.target.name)}
                                            type="radio"
                                            name="once"
                                            checked={frequency === 'once'}
                                        />
                                        Once
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input
                                            onChange={e => setFrequency(e.target.name)}
                                            type="radio"
                                            name="twice"
                                            checked={frequency === 'twice'}
                                        />
                                        Twice
                                    </Label>
                                </FormGroup>
                            </div>
                            <ScheduleSelector
                                position="first"
                                selectedDays={firstAlertDays}
                                selectedHour={firstAlertHour}
                                handleHourSelect={setFirstAlertHour}
                                selectedPeriod={firstAlertPeriod}
                                handlePeriodSelect={setFirstAlertPeriod}
                                handleDaySelect={handleDaySelect}
                                timezone={timezoneDisplay}
                                errorObj={errors}
                            />
                            {frequency === 'twice' &&
                            <ScheduleSelector
                                position="second"
                                selectedDays={secondAlertDays}
                                selectedHour={secondAlertHour}
                                handleHourSelect={setSecondAlertHour}
                                selectedPeriod={secondAlertPeriod}
                                handlePeriodSelect={setSecondAlertPeriod}
                                handleDaySelect={handleDaySelect}
                                timezone={timezoneDisplay}
                                errorObj={errors}
                            />
                            }
                        </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default EmailSchedule;
