import React, {useEffect, useRef, useState} from 'react';
import {Alert} from 'reactstrap';

const FlashMessage = (props) => {
    const statusBar = useRef(null);
    const [active, setActive] = useState(true);

    useEffect(() => {
        setTimeout(function () {
            setActive(false)
        }, 100)
    }, []);

    const transition = 'width ' + (5000 + 300) + 'ms';

    const {type, message, id, deleteFlashMessage} = props;
    if(!message) return null;
    return (
        <Alert color={type}>
            <button onClick={() => deleteFlashMessage(id)} className="close">×</button>
            {message}
            <span className={'alert-progress '+ (active ? 'active' : '')}
                  ref={statusBar}
                  style={{transition: transition}}
            />
        </Alert>
    )
};

export default FlashMessage;
