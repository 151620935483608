import React from 'react';

import {Modal, ModalBody, ModalFooter, Button} from 'reactstrap';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const ConfirmModal = (props) => {
    const {isOpen, toggle, onConfirm} = props;

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="organization-switch-confirm">
            <ModalBodyWithClose toggle={toggle}>
                Switch your account to organization mode?
            </ModalBodyWithClose>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                <Button color="primary" onClick={onConfirm}>Confirm</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmModal;
