import React, { useContext, useState, useEffect } from 'react';

import { Col, Row, Card, CardHeader, CardBody } from 'reactstrap';
import { useLocation, useParams } from 'react-router-dom';
import { cloneDeep, isEqual } from 'lodash';

import { AuthContext } from 'contexts/AuthContext';
import LoadingSpinner from 'components/LoadingSpinner';
import { getDigDeeperContent, getFreeDigDeeperContent } from 'utils/api/alertsAPI';
import Chart from './ArticlesPerDayChart';
import ArticleList from './List';
import Logo from 'images/logo.png';
import EmptyChart from 'containers/Alerts/DigDeeper/ArticlesPerDayChart/EmptyChart';
import SentimentChart from 'containers/Alerts/DigDeeper/SentimentChart';
import './style.scss';

const emptyTagCategories = ['PERSON', 'LOCATION', 'ORGANIZATION'];

const DigDeeper = (props) => {
    let { id } = useParams();
    let { search } = useLocation();

    const { handleError } = useContext(AuthContext);
    const [fetchingData, setFetchingData] = useState(true);
    const [alertData, setAlertData] = useState([]);
    const [visibleTags, setVisibleTags] = useState([]);
    const [filteredArticleList, setFilteredArticleList] = useState([]);
    const [activeTag, setActiveTag] = useState('');
    const [activeDate, setActiveDate] = useState('');
    const [activeDateNews, setActiveDateNews] = useState([]);
    const [colorArray, setColorArray] = useState([]);
    const [mainColor, setMainColor] = useState('rgba(37,102,166,.5)');
    const [activeIndex, setActiveIndex] = useState(-1);

    const fetchAlertData = async () => {
        try {
            let data;
            if (props.public) {
                const hash = search.slice(3, search.length);
                data = await getFreeDigDeeperContent(id, hash, 30);
            } else {
                data = await getDigDeeperContent(id, 30);
            }
            let newColorArray = [];
            data.newsPerDay.map(() => {
                newColorArray.push({
                    backgroundColor: 'rgba(37,102,166,.5)',
                    borderColor: 'rgba(37,102,166,1)',
                    hoverBackgroundColor: 'rgba(37,102,166,0.9)',
                    hoverBorderColor: 'rgba(37,102,166,1)',
                });
            });
            setAlertData(data);
            setVisibleTags(data.tags);
            setColorArray(newColorArray);
            setFilteredArticleList(data.news);
            setFetchingData(false);
        } catch (err) {
            handleError(err, null, true);
            setFetchingData(false);
        }
    };

    useEffect(() => {
        id && fetchAlertData();
    }, []);

    const resetAllFilters = () => {
        setActiveTag('');
        setActiveDate(null);
        setActiveDateNews([]);
        resetColor();
    };

    const resetColor = () => {
        let newColorArray = [...colorArray];
        newColorArray.map((item) => {
            item.backgroundColor = mainColor;
            item.borderColor = 'rgba(37,102,166,1)';
            item.hoverBackgroundColor = 'rgba(37,102,166,0.9)';
            item.hoverBorderColor = 'rgba(37,102,166,1)';
            return item;
        });
        setColorArray(newColorArray);
        setActiveIndex(-1);
    };

    const changeColor = (index) => {
        resetColor();
        let newColorArray = [...colorArray];
        newColorArray[index] = {
            backgroundColor: 'rgba(31, 128, 224,.9)',
            borderColor: 'rgba(31, 128, 224,1)',
            hoverBackgroundColor: 'rgba(31, 128, 224,0.9)',
            hoverBorderColor: 'rgba(31, 128, 224,1)',
        };
        setColorArray(newColorArray);
        setActiveIndex(index);
    };

    const handleTagSelect = (tag) => {
        if (isEqual(activeTag, tag)) {
            setActiveTag('');
        } else {
            setActiveTag(tag);
        }
    };

    const handleDateSelect = (date) => {
        if (isEqual(activeDate, date)) {
            setActiveDate('');
            setActiveDateNews([]);
        } else {
            setActiveDate(date.day);
            setActiveDateNews(date.news);
        }
    };

    const applyDateFilterToTags = () => {
        const tags = cloneDeep(visibleTags);
        tags.forEach((item) => {
            item.tags = item.tags.filter((innerItem) => {
                return innerItem.news.some((tag) => {
                    return activeDateNews.indexOf(tag) > -1;
                });
            });
        });
        setVisibleTags(tags);
    };

    useEffect(() => {
        if (activeDateNews && activeDateNews.length) {
            applyDateFilterToTags();
        } else {
            setVisibleTags(alertData.tags);
        }
    }, [activeDateNews]);

    const applyFilters = (articleArray) => {
        let filteredArray = [...articleArray];
        if (activeTag) {
            filteredArray = filteredArray.filter((article) => article[activeTag.ind].includes(activeTag.name));
        }

        if (activeDate) {
            filteredArray = filteredArray.filter((article) => {
                return article.created_at.slice(0, 10) === activeDate;
            });
        }

        setFilteredArticleList(filteredArray);
    };

    useEffect(() => {
        alertData.news && applyFilters(alertData.news);
    }, [activeTag, activeDate]);

    return (
        <div className={'view dig-deeper animated fadeIn ' + (props.public ? 'container dig-deeper--public ' : '')}>
            {fetchingData ? (
                <Row className="mt-3">
                    <Col xs="12">
                        <LoadingSpinner text="Fetching alert data" />
                    </Col>
                </Row>
            ) : (
                alertData && (
                    <Row className="mt-3 mb-4">
                        <Col xs="12">
                            <div className="d-flex justify-content-between">
                                <div className="dig-deeper__header-container">
                                    <h1 className="mb-0">{alertData.alertName}</h1>
                                    {alertData.categories && alertData.categories.hasOwnProperty('name') && (
                                        <div className="alert-category">
                                            <div className="alert-category__type">{alertData.categories.type}</div>
                                            {alertData.categories.name}
                                        </div>
                                    )}
                                </div>
                                {props.public && (
                                    <div className="public-dig-deeper-logo">
                                        <img src={Logo} alt="Logo" />
                                    </div>
                                )}
                            </div>
                        </Col>
                        {/*<Col xs="12" md="3">
                            <div className="word-filters">
                                {(visibleTags && visibleTags.length > 0) ? visibleTags.map(item => {
                                        return (
                                            <TagList
                                                key={item.type}
                                                title={item.type}
                                                tags={item.tags}
                                                handleTagSelect={handleTagSelect}
                                                activeTag={activeTag}
                                            />
                                        );
                                    })
                                    :
                                    emptyTagCategories.map(item => {
                                        return (
                                            <TagList
                                                key={item}
                                                title={item}
                                                tags={[]}
                                                handleTagSelect={handleTagSelect}
                                                activeTag={activeTag}
                                            />
                                        );
                                    })

                                }
                            </div>
                        </Col>*/}
                        <Col xs="12">
                            <Row>
                                <Col xs="12" md="12" lg={alertData.news && alertData.news.length > 0 ? '7' : '12'}>
                                    <Card>
                                        <CardHeader>Articles per Day (Last 30 Days)</CardHeader>
                                        <CardBody>
                                            {fetchingData ? (
                                                <LoadingSpinner />
                                            ) : alertData.news && alertData.news.length > 0 ? (
                                                <Chart
                                                    articles={filteredArticleList}
                                                    articlesPerDay={alertData.newsPerDay}
                                                    handleDateSelect={handleDateSelect}
                                                    resetAllFilters={resetAllFilters}
                                                    activeIndex={activeIndex}
                                                    changeColor={changeColor}
                                                    resetColor={resetColor}
                                                    colorArray={colorArray}
                                                />
                                            ) : (
                                                <EmptyChart />
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>
                                {alertData.trader_sma && alertData.news && alertData.news.length > 0 && (
                                    <Col xs="12" md="12" lg="5">
                                        <Card>
                                            <CardHeader className="sma-header" style={{ height: '46px', maxHeight: '46px' }}>
                                                Sentiment Analysis
                                            </CardHeader>
                                            <CardBody>
                                                <SentimentChart data={alertData.trader_sma} />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                            {alertData.news && alertData.news.length > 0 && (
                                <ArticleList
                                    articles={filteredArticleList}
                                    articlesWithoutDuplicates={filteredArticleList.filter((item) => !item.hasOwnProperty('parent'))}
                                    activeTag={activeTag}
                                    activeDate={activeDate}
                                    resetAllFilters={resetAllFilters}
                                    alertId={id}
                                    alertName={alertData.alertName}
                                    handleTagSelect={handleTagSelect}
                                />
                            )}
                        </Col>
                    </Row>
                )
            )}
        </div>
    );
};

export default DigDeeper;
