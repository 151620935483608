import React, {useEffect, useRef, useState} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import useOnClickOutside from 'hooks/clickOutside';
import {getCompanyAutocompleteResults} from 'utils/api/alertsAPI';
import './style.scss';

const CompanyAutocomplete = (props) => {
    const {value, searchValueHasError, error, submitting, onSubmit, preSubmitError, handleSearchValueChange} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [query, setQuery] = useState('');

    const ref = useRef();
    useOnClickOutside(ref, () => setDropdownOpen(false));

    const handleChange = async e => {
        const query = e.target.value;
        setIsLoading(true);
        setQuery(query);
        handleSearchValueChange(query);

        if(query.length > 0) {
            const options = await getCompanyAutocompleteResults(query);
            setItems(options);
            if(!submitting) {
                setDropdownOpen(options.length > 0);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const newQuery = props.inputValue;
        setQuery(newQuery);
        props.onPressEnter(query);
    }, [props.inputValue]);

    const handleClear = () => {
        setQuery('');
        setItems([]);
        setDropdownOpen(false);
        props.onClear();
    };

    const onAutocompleteSelect = (term) => {
        props.handleAutocompleteSelect(term);
        setDropdownOpen(false);
    };


    const handlePressEnter = (e) => {
        props.onPressEnter(e);
        setDropdownOpen(false);
    };

    useEffect(() => {
        if(submitting) dropdownOpen && setDropdownOpen(false);
    }, [submitting])

    const handleSubmit = () => {
        dropdownOpen && setDropdownOpen(false);
        onSubmit();
    }

    return (
        <div className=""
             style={{
                 width: '100%',
                 minHeight: '35px',
                 position: 'relative',
             }}
             ref={ref}
        >
            <TextareaAutosize
                placeholder="Search by a company name or ticker symbol"
                className="internal-search-textarea"
                style={{
                    width: '100%',
                    paddingRight: '65px',
                    border: ((error || preSubmitError) ? '1px solid red' : '')
                }}
                value={value}
                onChange={handleChange}
                onKeyDown={handlePressEnter}
            />
            {submitting ?
                <div className="search-input__icon-container">
                    <i className="fa fa-spinner fa-spin search-input__icon search-input__icon--search"/>
                </div>
                :
                <div className="search-input__icon-container d-flex" style={{zIndex: 2, right: '12px', paddingRight: '.3rem'}}>
                    {value.length > 0 &&
                    <i
                        className="fa fa-times-circle search-input__icon search-input__icon--close"
                        onClick={handleClear}
                    />
                    }
                    <i
                        className="fa fa-search search-input__icon search-input__icon--search"
                        onClick={() => handleSubmit()}
                    />
                </div>
            }
            <div style={{position: 'relative', width: '100%'}}>
                <div className="textarea-autocomplete"
                     style={{display: (dropdownOpen && !searchValueHasError) ? 'block' : 'none'}}>
                    {items.map(item => (
                        <div className="textarea-autocomplete-list-item"
                             key={item.symbol}
                             onClick={() => onAutocompleteSelect(item.symbol)}
                        >
                            {item.name} <br/>
                            {item.symbol}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CompanyAutocomplete;






