import {postDecorator, patchDecorator, getDecorator, deleteDecorator, csvDecorator} from 'utils/api/decorators';


export const getUsers = (help, count, pageNumber, itemsPerPage, sortProperty, sortDirection = 'asc', searchProperty, searchValue = '', labels = [], csv = false, title) => {
    let encodedSearchValue = encodeURIComponent(searchValue);

    let url = `/api/v2/user?help=${help}&count=${count}&page=${pageNumber}&itemsOnPage=${itemsPerPage}&sort%5B0%5D%5Bproperty%5D=${sortProperty}&sort%5B0%5D%5Bdirection%5D=${sortDirection.toUpperCase()}&csv=${csv}`;

    if(encodedSearchValue.length > 0) {
        url = url + `&filter%5B0%5D%5Bproperty%5D=${searchProperty}&filter%5B0%5D%5Boperator%5D=contains&filter%5B0%5D%5Bvalue%5D=${encodedSearchValue}`
    }
    labels.length > 0 && labels.map((label, index) => {
        url += `&filter%5B${index + 1}%5D%5Bproperty%5D=user.labels&filter%5B${index + 1}%5D%5Boperator%5D=equals&filter%5B${index + 1}%5D%5Bvalue%5D=${encodeURIComponent(label)}`
    })

    if(csv) {
        return csvDecorator(url, title);
    } else {
        return getDecorator(url);
    }
};

// export const getUsersWithoutParams = (help, count = false, pageNumber, itemsPerPage) => {
//     const url = `/api/v2/user?help=${help}&count=${count}&page=${pageNumber}&itemsOnPage=${itemsPerPage}`;
//     return getDecorator(url)
// }

export const getUsersWithoutParams = (csv, title) => {
    const url = `/api/v2/user?help=false&count=false&page=1&itemsOnPage=3000&sort%5B0%5D%5Bproperty%5D=user.name&sort%5B0%5D%5Bdirection%5D=ASC&csv=${csv}`;
    if(csv) {
        return csvDecorator(url, title);
    } else {
        return getDecorator(url);
    }
};

export const addUser = (params) => {
    const url = '/api/v2/user';
    return postDecorator(url, params)
}

export const checkPossibleToAddUser = () => {
    const url = '/api/v2/user/is_possible_add_user/';
    return getDecorator(url)
}

export const getUserById = (id) => {
    const url = `/api/v2/user/${id}`;
    return getDecorator(url)
}

export const updateUserById = (id, params) => {
    const url = `/api/v2/user/${id}`;
    return patchDecorator(url, params)
}

export const getUserAlertsById = (id) => {
    const url = `/api/v2/user/${id}/assigned_alerts?help=false&count=true&page=1&itemsOnPage=2000`;
    return getDecorator(url)
}

export const updateUserAlertsById = (id, params) => {
    const url = `/api/v2/user/${id}/assign_alerts`;
    return patchDecorator(url, params);
};

export const deleteUser = (id) => {
    const url = `/api/v2/user/${id}`;
    return deleteDecorator(url)
}

export const getUserGroups = () => {
    const url = '/api/v2/user_group';
    return getDecorator(url);
};

export const addUserGroup = (params) => {
    const url = '/api/v2/user_group';
    return postDecorator(url, params);
};

export const assignUsersToUserGroup = (id, params) => {
    const url = `/api/v2/user_group/${id}/assign`;
    return patchDecorator(url, params);
}

export const editUserGroup = (params, id) => {
    const url = `/api/v2/user_group/${id}`;
    return patchDecorator(url, params);
};

export const deleteUserGroup = (id) => {
    const url = `/api/v2/user_group/${id}`;
    return deleteDecorator(url);
};

export const getUserLabels = () => {
    const url = '/api/v2/user_label';
    return getDecorator(url);
};

export const addUserLabel = (params) => {
    const url = '/api/v2/user_label';
    return postDecorator(url, params);
};

export const editUserLabel = (id, params) => {
    const url = `/api/v2/user_label/${id}`;
    return patchDecorator(url, params);
};

export const deleteUserLabel = (id) => {
    const url = `/api/v2/user_label/${id}`;
    return deleteDecorator(url);
};

export const addUserSchedule = (id, params) => {
    const url = `/api/v2/user/${id}/schedule`;
    return postDecorator(url, params);
};

export const editUserSchedule = (id, scheduleId, params) => {
    const url = `/api/v2/user/${id}/schedule/${scheduleId}`;
    return patchDecorator(url, params);
};

export const deleteUserSchedule = (id, scheduleId) => {
    const url = `/api/v2/user/${id}/schedule/${scheduleId}`;
    return deleteDecorator(url);
};

export const getUserAlertSorting = (id) => {
    const url = `/api/v2/user/${id}/alert_sorting`;
    return getDecorator(url);
};

export const editUserAlertSorting = (id, params) => {
    const url = `/api/v2/user/${id}/alert_sorting`;
    return patchDecorator(url, params);
};

export const getLiveOrLastPreview = (id, type) => {
    const url = `/api/v2/user/${id}/preview_alert_email/${type}`;
    return getDecorator(url);
};


