import React from 'react'
import {InputGroup, Col, Row} from 'reactstrap'
import CompanyAutocomplete from 'components/CompanyAutocomplete';
import {useHistory} from 'react-router-dom';
import { checkExistsCompanyBased } from 'utils/api/alertsAPI';

const SearchForm = (props) => {
    let history = useHistory();
    const {
        searchValue, searchValueError,
        resetForm, handleCreatePreview,
        searchValueEditWarning, errorObj,
        handleSearchValueChange, setSearchValueError,
    } = props;

    const onPressEnter = (e) => {
        if(e.key === 'Enter') {
            e.preventDefault();
            handleCreatePreview();
        }
    };

    const handleAutocompleteSelect = async (term) => {
        // handleSearchValueChange(term);
        const {alertId} = await checkExistsCompanyBased(term);
        if (alertId) {
            history.push(`/account/alerts/${alertId}/company-profile`);
            return;
        }
        history.push(`/account/alerts/${term}/company-preview`);
    };

    return (
        <Row>
            <Col xs={12} md={12}>
                <InputGroup className="rss-search-form mb-0">
                    <CompanyAutocomplete    handleSearchValueChange={handleSearchValueChange}
                                            onPressEnter={onPressEnter}
                                            value={searchValue}
                                            searchValueHasError={searchValueError}
                                            setSearchValueHasError={setSearchValueError}
                                            onSubmit={handleCreatePreview}
                                            onClear={resetForm}
                                            handleAutocompleteSelect={handleAutocompleteSelect}
                                            error={errorObj && errorObj.errors.hasOwnProperty('keyword')}
                                            preSubmitError={searchValueError}
                    />
                </InputGroup>
                {errorObj && errorObj.errors.hasOwnProperty('url') &&
                <div className="mt-1 error-text">{errorObj.errors['url'][0].message || errorObj.errors['url']}</div>
                }
            </Col>
            <Col xs={12}>
                {searchValueEditWarning &&
                <div className="error-text" style={{marginTop: '.5rem', paddingBottom: '.5rem', maxWidth: '600px'}}>
                    The existing feed will be deleted and a new one will be created.
                    The new feed will be auto-assigned to the same users.</div>
                }
                {searchValueError &&
                <div className="rss-preview-error"
                     style={{marginBottom: '.5rem'}}
                >{searchValueError}</div>
                }
            </Col>
        </Row>
    );
};

export default SearchForm;
