import React, {useEffect, useState} from 'react';

import moment from 'moment-timezone';
import {Table, Button, Modal, UncontrolledTooltip} from 'reactstrap';
import CreateLabelModal from './Modals/CreateLabel';
import EditLabelModal from './Modals/EditLabel';
import DeleteLabel from 'containers/Alerts/Labels/Modals/DeleteLabel';
import SearchInput from 'components/SearchInput/SearchInputWithSubmit';
import {filterByValue} from 'Helpers/filterByValue';
import {sortObjects} from 'Helpers/sortObjects';
import {handleCaret, handleSortableHeaderClick} from 'Helpers/handleSortCaret';
import MobileFilterDropdown from './MobileFilterDropdown';

const AlertLabelsList = (props) => {
    const {labels, refreshLabels, handleError, addFlashMessage,
        alertLabelFilters, setFiltersObject} = props;
    const {searchValue, activeSortProperty, activeSortDirection} = alertLabelFilters;

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [visibleLabels, setVisibleLabels] = useState([]);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');

    // useEffect(() => {
    //     setVisibleLabels(labels);
    // }, [labels]);

    useEffect(() => {
        tempSearchValue !== searchValue && setTempSearchValue(searchValue);
    }, []);

    useEffect(() => {
        let filteredList = filterByValue(labels, 'name', searchValue);
        let sortedList = sortObjects(filteredList, activeSortProperty, activeSortDirection);
        setVisibleLabels(sortedList);
    }, [alertLabelFilters, labels]);

    const toggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen);
    };

    const toggleEditModal = (item) => {
        setEditModalOpen(!editModalOpen);
        if(!editModalOpen) {
            setItemToEdit(item);
        } else {
            setItemToEdit(null);
        }
    };

    const toggleDeleteModal = (item) => {
        setDeleteModalOpen(!deleteModalOpen);
        if(!deleteModalOpen) {
            setItemToDelete(item);
        } else {
            setItemToDelete(null);
        }
    };

    const handleSearchValueSubmit = () => {
        if(tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)')
            return;
        }
        let filters = {...alertLabelFilters};
        filters.searchValue = tempSearchValue;
        setFiltersObject('alertLabels', filters);
    };

    const handleClear = () => {
        let filters = {...alertLabelFilters};
        filters.searchValue = '';
        setFiltersObject('alertLabels', filters);
        setTempSearchValue('');
    };

    const handleSortableHeaderClick = (value, activeSortProperty, activeSortDirection) => {
        let filters = {...alertLabelFilters};
        if(value === activeSortProperty) {
            activeSortDirection === 'asc' ?
                filters.activeSortDirection = 'desc'
                :
                filters.activeSortDirection = 'asc';
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }
        setFiltersObject('alertLabels', filters);
    };

    const handleMobileFilterSelect = (val) => {
        let splitValue = val.split('-');
        let filters = {...alertLabelFilters};
        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject('alertLabels', filters);
    };

    const determineMobileFiltersValue = () => {
        let filterStr = alertLabelFilters.activeSortProperty + '-' + alertLabelFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    useEffect(() => {
        alertLabelFilters && determineMobileFiltersValue();
    }, [alertLabelFilters]);

    return (
        <div>
            {window.innerWidth > 1250 &&
            <div className="list__utility-row list__utility-row--desktop">
                <div className="list__search-container">
                    <SearchInput
                        value={tempSearchValue}
                        onChange={setTempSearchValue}
                        placeholder={'Filter by label name'}
                        onClear={handleClear}
                        submitting={false}
                        onSubmit={handleSearchValueSubmit}
                        list
                    />
                </div>
                <div className="d-flex">
                    <Button
                        onClick={toggleCreateModal}
                        color="primary"
                    >Create Label</Button>
                </div>
            </div>
            }
            {1250 >= window.innerWidth && window.innerWidth > 624 &&
            <div className="list__utility-row list__utility-row--tablet">
                <div className="d-flex justify-content-between w-100">
                    <div className="list__search-container">
                        <SearchInput
                            value={tempSearchValue}
                            onChange={setTempSearchValue}
                            placeholder="Filter by label name"
                            onClear={handleClear}
                            submitting={false}
                            onSubmit={handleSearchValueSubmit}
                            list
                        />
                    </div>
                </div>
                <div className="d-flex mt-2 w-100 justify-content-end">
                    <div className="mr-2 d-flex">
                        <Button
                            onClick={toggleCreateModal}
                            color="primary"
                            className="mb-0"
                        >
                            <i className="fa fa-plus"/>&nbsp;
                            Create Label
                        </Button>
                    </div>
                    <MobileFilterDropdown
                        handleMobileFilterSelect={handleMobileFilterSelect}
                        activeMobileFilterValue={activeMobileFilterValue}
                    />
                </div>
            </div>
            }
            {624 >= window.innerWidth &&
            <div className="list-utility-row list__utility-row--mobile">
                <div className="d-flex mb-2">
                    <Button
                        onClick={toggleCreateModal}
                        color="primary"
                        block
                        // className="ml-1"
                        // style={{flex: '0 0 50%'}}
                    >
                        <i className="fa fa-plus"/>&nbsp;
                        Create Label
                    </Button>
                </div>
                <div className="d-flex mb-2">
                    <MobileFilterDropdown
                        handleMobileFilterSelect={handleMobileFilterSelect}
                        activeMobileFilterValue={activeMobileFilterValue}
                    />
                </div>
                <div className="mb-3">
                    <SearchInput
                        value={tempSearchValue}
                        onChange={setTempSearchValue}
                        placeholder={'Filter by label name'}
                        onClear={handleClear}
                        submitting={false}
                        onSubmit={handleSearchValueSubmit}
                        list
                    />
                </div>
            </div>
            }


            {/*<div className="list__utility-row">*/}
            {/*    <SearchInput*/}
            {/*        value={tempSearchValue}*/}
            {/*        onChange={setTempSearchValue}*/}
            {/*        placeholder={'Filter by label name'}*/}
            {/*        onClear={handleClear}*/}
            {/*        submitting={false}*/}
            {/*        onSubmit={handleSearchValueSubmit}*/}
            {/*        list*/}
            {/*    />*/}
            {/*    <Button*/}
            {/*        onClick={toggleCreateModal}*/}
            {/*        color="primary"*/}
            {/*    >Create Label</Button>*/}
            {/*</div>*/}
            <Table className="responsive-table">
                <tbody>
                <tr>
                    <th
                        className="responsive-table__sortable-th"
                        onClick={() => handleSortableHeaderClick('name', activeSortProperty,
                            activeSortDirection)}
                    >
                        Name
                        {handleCaret(activeSortProperty, activeSortDirection, 'name')}
                    </th>
                    <th
                        className="responsive-table__sortable-th"
                        onClick={() => handleSortableHeaderClick('createdAt', activeSortProperty,
                            activeSortDirection)}
                    >
                        Created At
                        {handleCaret(activeSortProperty, activeSortDirection, 'createdAt')}
                    </th>
                    <th>Edit/Remove</th>
                </tr>
                {visibleLabels.length > 0 ? visibleLabels.map(label => (
                        <tr key={label.id}>
                            <td data-label="Name">{label.name}</td>
                            <td data-label="Created At">
                                {moment(label.createdAt).format('MM/DD/YY')}
                            </td>
                            <td className="responsive-table__edit-block" data-label="Edit/Remove">
                                <Button color="light" onClick={() => toggleEditModal(label)}>
                                    <i className="fa fa-edit fa-lg"/>
                                </Button>{' '}
                                <Button color="light" onClick={() => toggleDeleteModal(label)}>
                                    <i className="fa fa-trash fa-lg"/>
                                </Button>
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td>No results found</td>
                        <td/>
                        <td/>
                    </tr>
                }
                </tbody>
            </Table>
            <CreateLabelModal
                isOpen={createModalOpen}
                toggle={toggleCreateModal}
                handleError={handleError}
                addFlashMessage={addFlashMessage}
                refreshLabels={refreshLabels}
            />

            <EditLabelModal
                isOpen={editModalOpen}
                toggle={toggleEditModal}
                item={itemToEdit}
                refreshLabels={refreshLabels}
                handleError={handleError}
                addFlashMessage={addFlashMessage}
            />

            <DeleteLabel
                isOpen={deleteModalOpen}
                toggle={toggleDeleteModal}
                item={itemToDelete}
                handleError={handleError}
                refreshLabels={refreshLabels}
                addFlashMessage={addFlashMessage}
            />
        </div>
    );
};

export default AlertLabelsList;
