import React, {useState, useEffect} from 'react';

const tagCategories = [
    {icon: 'fa fa-map-marker', name: 'Location'},
    {icon: 'fa fa-user', name: 'Person'},
    {icon: 'fa fa-sitemap', name: 'Organization'},
    // {icon: 'fa fa-balance-scale', name: 'Bias'},
];

const SelectCategory = (props) => {
    const {selectedAlert, handleCategorySelect, categories, setActiveView, selectedCategory} = props;

    const handleIcon = (category) => {
        switch(category.type) {
            case 'PERSON':
                return <i className="fa fa-user"/>;
            case 'LOCATION':
                return <i className="fa fa-map-marker"/>;
            case 'ORGANIZATION':
                return <i className="fa fa-sitemap"/>;
            // case 'BIAS':
            //     return <i className="fa fa-balance-scale"/>;
            default:
                return <div/>;
        }
    };

    const capitalizeFirstLetter = (string) => {
        if(!string) return '';
        let str = string.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
        <div className="mobile-reader-list mobile-reader-tag-list">
            <div className="mobile-reader-list__header mobile-reader-tag-list__header">
                <div
                    onClick={() => setActiveView('articles')}
                    className="mobile-reader-back-btn"
                >
                    <i className="fa fa-angle-left" />
                    Back
                </div>
                <div>Select a Category</div>
                <div className="mobile-reader-tag-list__active-alert">Active alert: <strong>{selectedAlert.name}</strong></div>
            </div>
            <div className="mobile-reader-list__body">
                {categories.length < 1 ?
                    <div style={{padding: '.5rem 1rem'}}>No tags found</div>
                    :
                    categories.map(category => (
                        <div
                            key={category.type}
                            onClick={() => handleCategorySelect(category)}
                            className="mobile-reader-tag-list__category"
                        >
                            <div className="mobile-reader-tag-list__category-inner">
                                {handleIcon(category)}
                                <div>{capitalizeFirstLetter(category.type)}</div>
                            </div>
                            <i className="fa fa-angle-right"/>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default SelectCategory;
