import React, {useState, useContext, useEffect} from 'react';

import {Label} from 'reactstrap';

import {AuthContext} from 'contexts/AuthContext';
import AdminList from './AdminList';
import SpinnerButton from 'components/SpinnerButton';
import {editOrganizationDetails} from 'utils/api/profileAPI';
import {timezones} from 'utils/timezones';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import InputWithError from 'components/InputWithError';
import SelectWithError from 'components/InputWithError/SelectWithError';

const General = (props) => {
    const {handleError} = useContext(AuthContext);
    const {addFlashMessage} = useContext(FlashMessageContext);

    const {refreshProfile, profile, isSingleUser} = props;
    const [companyName, setCompanyName] = useState('');
    const [selectedTimezone, setSelectedTimezone] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    const updateOrganizationDetails = async() => {
        try {
            setSubmitting(true);
            setErrors(null);
            const params = {
                name: companyName,
                timezone: selectedTimezone,
            };
            await editOrganizationDetails(params);
            setSubmitting(false);
            addFlashMessage('success', 'Organization successfully updated');
            refreshProfile();
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    useEffect(() => {
        if(profile.organization) {
            setCompanyName(profile.organization.name);
            setSelectedTimezone(profile.organization.timezone);
        }
    }, [profile]);

    return (
        <div>
            {!isSingleUser &&
            <div>
                <h4 className="settings__page-header">Company Settings</h4>
                <div className="mt-3">
                    <Label>Company Name</Label>
                    <div style={{maxWidth: '390px'}}>
                        <InputWithError
                            prependIcon={<i className="fa fa-building"/>}
                            name="name"
                            value={companyName}
                            type="text"
                            onChange={setCompanyName}
                            errorObj={errors}
                            marginBottomClass="mb-3"
                        />
                    </div>
                </div>
                <div className="mt-3">
                    <Label>Timezone</Label>
                    <div style={{maxWidth: '390px'}}>
                        <SelectWithError
                            prependIcon={<i className="fa fa-globe"/>}
                            name="timezone"
                            value={selectedTimezone}
                            type="select"
                            onChange={setSelectedTimezone}
                        >
                            <option value={''} disabled>
                                Timezone
                            </option>
                            {timezones.map(timezone => {
                                return (
                                    <option
                                        key={timezone.key}
                                        value={timezone.key}
                                    >
                                        {timezone.name}
                                    </option>
                                );
                            })}
                        </SelectWithError>
                    </div>
                </div>
                <div className="mt-4">
                    <SpinnerButton
                        color="primary"
                        onClick={updateOrganizationDetails}
                        submitting={submitting}
                        title="Save Changes"
                    />
                </div>
            </div>
            }
            <div className="mb-3">
                {profile.organization && profile.organization.admins &&
                <AdminList
                    isSingleUser={isSingleUser}
                    admins={profile.organization.admins}
                    refreshProfile={refreshProfile}
                    handleError={handleError}
                />
                }
            </div>
        </div>
    );
};

export default General;