import React, {useState} from 'react';
import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap';
import TabBar from 'components/TabBar';
import UserUsageEmailsReport from './UserUsageEmailsReport'
import UserUsagesNewslettersReport from './UserUsagesNewslettersReport'
import MailingObjectReport from './MailingObjectReport'
import BrokenSourcesReport from './BrokenSourcesReport'
import PendingSourcesReport from './PendingSourcesReport'
import './style.scss';

const Reports = () => {
    const [activeView, setActiveView] = useState('userUsageEmailsReport');

    const handleActiveView = () => {
        switch(activeView) {
            case 'userUsageEmailsReport':
                return <UserUsageEmailsReport/>;
            case 'userUsageNewslettersReport':
                return <UserUsagesNewslettersReport/>;
            case 'mailingObjectReport':
                return <MailingObjectReport/>;
            case 'brokenSourcesReport':
                return <BrokenSourcesReport/>
            case 'pendingSourcesReport':
                return <PendingSourcesReport/>
            default:
                return <UserUsageEmailsReport/>;
        }
    }

    return (
        <div className="view view--mobile-full alerts ">
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <div className="flex-space-between">
                                    <h1>Reports</h1>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <TabBar
                                    activeItem={activeView}
                                    setActive={setActiveView}
                                    items={
                                        [
                                            {data: 'userUsageEmailsReport', name: 'User usages report'},
                                            {data: 'userUsageNewslettersReport', name: 'User usages newsletters report'},
                                            {data: 'mailingObjectReport', name: 'Content usage report'},
                                            {data: 'brokenSourcesReport', name: 'Broken Sources Report'},
                                            {data: 'pendingSourcesReport', name: 'Pending Sources Report'},
                                        ]
                                    }
                                />
                                {handleActiveView()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Reports;
