import React from 'react';
import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import Toggle from 'react-toggle';

import { timezones } from 'utils/timezones';
import { RadioInput, CheckboxInput } from 'components/common/Input';

const days = [
    { name: 'Mon', value: 1 },
    { name: 'Tue', value: 2 },
    { name: 'Wed', value: 3 },
    { name: 'Thu', value: 4 },
    { name: 'Fri', value: 5 },
    { name: 'Sat', value: 6 },
    { name: 'Sun', value: 7 },
];

const Automatic = (props) => {
    const {
        selectedTimeHour,
        selectedTimePeriod,
        selectedDays,
        handleHourSelect,
        setSelectedTimePeriod,
        errorMessage,
        handleDaysSelect,
        selectedTimezone,
        errors,
        handleTimezoneSelect,
        includeAbstracts,
        setIncludeAbstracts,
        selectedDailyFrequency,
        setSelectedDailyFrequency,
        selectedFrequency,
        setSelectedFrequency,
        selectedDay,
        setSelectedDay,
    } = props;
    const timezone = timezones.filter((timezone) => timezone.key === selectedTimezone);
    const isAvailable = selectedDailyFrequency === 'available';
    const isTwice = selectedDailyFrequency === 'twice';
    const isDaily = selectedFrequency === 'daily';
    const isMonthly = selectedFrequency === 'monthly';

    return (
        <div className="newsletter-form__automatic-form mt-4 mb-4">
            <div className="mt-3">
                <RadioInput onChange={() => setSelectedFrequency('daily')} checked={isDaily} name="daily" text="Daily Frequency" />
                <RadioInput onChange={() => setSelectedFrequency('monthly')} checked={isMonthly} name="monthly" text="Monthly" />
            </div>
            {isDaily && (
                <div className="mt-3">
                    <RadioInput
                        onChange={() => setSelectedDailyFrequency('once')}
                        checked={selectedDailyFrequency === 'once'}
                        name="once"
                        text="Once"
                    />
                    <RadioInput onChange={() => setSelectedDailyFrequency('twice')} checked={isTwice} name="twice" text="Twice" />
                    <RadioInput
                        onChange={() => setSelectedDailyFrequency('available')}
                        checked={isAvailable}
                        name="asAvailable"
                        text="As Available"
                    />
                </div>
            )}
            {isTwice && (
                <div className="mt-3">
                    <strong>First Notification</strong>
                </div>
            )}
            {(!isAvailable || isMonthly) && (
                <>
                    {isDaily && (
                        <div className={isTwice ? 'mt-3 flex-vertical-center' : 'mt-3'}>
                            {isTwice ? <div className="mr-20">Select specific days:</div> : <div>Select specific days</div>}
                            <div className={isTwice ? 'mt4' : 'mt-3'}>
                                <FormGroup className="newsletter-form__day-select" check inline>
                                    {days.map((day) => (
                                        <CheckboxInput
                                            key={day.value}
                                            type="checkbox"
                                            checked={selectedDays.first.includes(day.value)}
                                            name={day.name}
                                            value={day.value}
                                            onChange={(e) => handleDaysSelect(Number(e.target.value))}
                                            style={{ border: errors && errors.errors.hasOwnProperty('dateTime') ? '1px solid red' : '' }}
                                            text={day.name}
                                        />
                                    ))}
                                </FormGroup>
                                {errors && errors.errors.hasOwnProperty('dateTime') && (
                                    <div className="error-text mt-1" style={{ marginBottom: '-10px' }}>
                                        You must select at least one day.
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className={isTwice ? 'flex' : 'mt-4 flex'}>
                        {isMonthly && (
                            <div style={{ minWidth: '120px', marginRight: '20px' }}>
                                <div>Select specific day:</div>
                                <div className="mt-3">
                                    <FormGroup>
                                        <Input onChange={(e) => setSelectedDay(e.target.value)} value={selectedDay} type="select" name="selectedHour">
                                            {[...Array(31).keys()].map((day) => (
                                                <option key={day + 1} value={day + 1}>
                                                    {day + 1}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </div>
                            </div>
                        )}
                        <div className={isTwice && isDaily ? 'full-width flex-vertical-center' : 'full-width'}>
                            <div className={isTwice && isDaily ? 'mr-20 mt-3 minW125' : ''}>Select specific time:</div>
                            <div className="newsletter-form__time-inputs mt-3 full-width">
                                <FormGroup>
                                    <Input
                                        onChange={(e) => handleHourSelect(e.target.value)}
                                        value={selectedTimeHour.first}
                                        type="select"
                                        name="selectedHour">
                                        {[...Array(12).keys()].map((hour) => (
                                            <option key={hour} value={hour + 1 + ':00'}>
                                                {hour + 1}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        onChange={(e) => setSelectedTimePeriod({ first: e.target.value, second: selectedTimePeriod.second })}
                                        value={selectedTimePeriod.first.toUpperCase()}
                                        type="select"
                                        name="selectedFirstPeriod">
                                        <option value="AM">AM</option>
                                        <option value="PM">PM</option>
                                    </Input>
                                </FormGroup>

                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fa fa-globe" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="timezone"
                                        value={selectedTimezone}
                                        type="select"
                                        options={timezones}
                                        onChange={(e) => handleTimezoneSelect(e.target.value)}>
                                        <option value={''} disabled>
                                            TimeZone
                                        </option>
                                        {timezones.map((timezone) => {
                                            return (
                                                <option key={timezone.key} value={timezone.key}>
                                                    {timezone.name}
                                                </option>
                                            );
                                        })}
                                    </Input>
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                    {isTwice && isDaily && (
                        <>
                            <div className="mt-3">
                                <strong>Second Notification</strong>
                            </div>
                            <div className="mt-3 flex-vertical-center">
                                <div className="mr-20">Select specific days:</div>
                                <div className="mt4">
                                    <FormGroup className="newsletter-form__day-select" check inline>
                                        {days.map((day) => (
                                            <CheckboxInput
                                                key={day.value}
                                                type="checkbox"
                                                checked={selectedDays.second.includes(day.value)}
                                                name={day.name}
                                                value={day.value}
                                                onChange={(e) => handleDaysSelect(Number(e.target.value), true)}
                                                style={{
                                                    border: errors && errors.errors.hasOwnProperty('dateTime') ? '1px solid red' : '',
                                                }}
                                                text={day.name}
                                            />
                                        ))}
                                    </FormGroup>
                                    {errors && errors.errors.hasOwnProperty('dateTime') && (
                                        <div className="error-text mt-1" style={{ marginBottom: '-10px' }}>
                                            You must select at least one day.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={isTwice ? 'full-width flex-vertical-center' : 'full-width'}>
                                <div className="mr-20 mt-3 minW125">Select specific time:</div>
                                <div className="newsletter-form__time-inputs mt-3" style={{ width: '180px' }}>
                                    <FormGroup>
                                        <Input
                                            onChange={(e) => handleHourSelect(e.target.value, true)}
                                            value={selectedTimeHour.second}
                                            type="select"
                                            name="selectedSecondHour">
                                            {[...Array(12).keys()].map((hour) => (
                                                <option key={hour} value={hour + 1 + ':00'}>
                                                    {hour + 1}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            onChange={(e) => setSelectedTimePeriod({ first: selectedTimePeriod.first, second: e.target.value })}
                                            value={selectedTimePeriod.second.toUpperCase()}
                                            type="select"
                                            name="selectedFirstPeriod">
                                            <option value="AM">AM</option>
                                            <option value="PM">PM</option>
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="schedule-selector__timezone-label mt-3">
                                    <i className="fa fa-globe" /> {!!timezone?.length && timezone[0]?.name}
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
            <div className="mt-6">
                <div className="newsletter-form__toggle-container">
                    <label>
                        <Toggle checked={includeAbstracts} name="active" icons={false} onChange={() => setIncludeAbstracts(!includeAbstracts)} />
                    </label>
                    <span id="suspend-label">Include abstracts</span>
                </div>
            </div>
            <div className="error-text schedule__error">{errorMessage}</div>
        </div>
    );
};

export default Automatic;
