import React from 'react';
import {Button, UncontrolledTooltip} from 'reactstrap';

const UserListCsvButton = ({downloadFilteredCsv, showText, downloading, desktopSize}) => {
    return (
        <div className={(desktopSize ? '' : 'w-100 ') + (showText ? ' mr-1' : '')}>
            {showText ?
                <Button
                    className="flex-1 w-100"
                    onClick={downloadFilteredCsv}
                >
                    <i className='fa fa-download'/> &nbsp;
                    Download CSV
                </Button>
                :
                <div>
                    <Button
                        className="mr-2 mb-0"
                        id="users-csv-button-tooltip"
                        onClick={downloadFilteredCsv}
                    >
                        {downloading ?
                            <i className="fa fa-spinner fa-spin"/>
                            :
                            <i className="fa fa-download"/>
                        }
                    </Button>
                    <UncontrolledTooltip placement="bottom" target="users-csv-button-tooltip" delay={{ show: 400, hide: 0 }}>
                        Download as CSV file
                    </UncontrolledTooltip>
                </div>
            }
        </div>
    );
};

export default UserListCsvButton;
