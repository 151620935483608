import React, {useEffect, useState} from 'react';
import './style.scss';

const CharacterCountV2 = ({value, limit, asHtml}) => {
    const [parsedHtmlValue, setParsedHtmlValue] = useState('');

    useEffect(() => {
        if(value && (typeof value === 'object') && asHtml) {
            setParsedHtmlValue(value.getCurrentContent().getPlainText(''))
        }
    }, [value])

    return (
        <div className={'character-count ' + ((asHtml && parsedHtmlValue.length >= limit) || value.length >= limit ? 'character-count--invalid' : '')}>
            Characters remaining: {limit - (asHtml ? parsedHtmlValue.length : value.length)}
        </div>
    );
};

export default CharacterCountV2;
