import React, {useEffect, useState} from 'react';

import Sections from './Sections';
import Sources from './Sources';
import {
    clearNewsletterSection,
    moveAlertBetweenNewsletterSections,
    sortNewsletterSections,
} from 'utils/api/newslettersAPI';
import {cloneDeep} from 'lodash';
// import NewSections from 'containers/Newsletters/ManageContent/Desktop/ManageSources/NewSections';

const ManageSources = (props) => {
    const {newsletterData, addFlashMessage, refreshNewsletter, handleError} = props;
    const [savingSectionChanges, setSavingSectionChanges] = useState(false);
    const [newsletterForDom, setNewsletterForDom] = useState(null);

    useEffect(() => {
        setNewsletterForDom(newsletterData);
    }, [newsletterData]);

    const assignAlertToSection = async(alertId, previousSectionId, newSectionId) => {
        try {
            await moveAlertBetweenNewsletterSections(newsletterData.id, alertId, previousSectionId, newSectionId);
            refreshNewsletter();
        } catch(err) {
            handleError(err);
        }
    };

    const removeAllAlertsFromSection = async(e, sectionId) => {
        try {
            e.stopPropagation();
            await clearNewsletterSection(newsletterData.id, sectionId);
            refreshNewsletter();
        } catch(err) {
            handleError(err);
        }
    };

    const handleSectionSorting = async(sortOrderArr) => {
        try {
            setSavingSectionChanges(true);
            await sortNewsletterSections(newsletterData.id, {sections: sortOrderArr});
            refreshNewsletter();
            setSavingSectionChanges(false);
        } catch(err) {
            setSavingSectionChanges(false);
            handleError(err);
        }
    };


    const moveAlertBetweenSections = async(alertId, previousSectionId, nextSectionId, sortOrder) => {
        try {
            const nextColumn = newsletterForDom.sections.find(obj => obj.id === nextSectionId);
            const prevColumn = newsletterForDom.sections.find(obj => obj.id === previousSectionId);
            if(!nextColumn || !prevColumn) {
                addFlashMessage('danger', 'Unable to move alert');
                return;
            }
            const nextColumnIndex = newsletterForDom.sections.indexOf(nextColumn);
            const prevColumnIndex = newsletterForDom.sections.indexOf(prevColumn);

            const selectedAlert = prevColumn.alerts.find(
                alert => alert.id === alertId,
            );
            if(!selectedAlert) {
                addFlashMessage('danger', 'Unable to move alert');
                return;
            }
            const nextColumnAlerts = Array.from(nextColumn.alerts);
            const prevColumnAlerts = Array.from(prevColumn.alerts);
            selectedAlert.sortOrder = sortOrder;

            nextColumnAlerts.splice(sortOrder - 1, 0, selectedAlert);
            const newNextColumn = {
                ...nextColumn,
                alerts: nextColumnAlerts,
            };
            const newPrevColumn = {
                ...prevColumn,
                alerts: prevColumnAlerts,
            };
            prevColumnAlerts.splice(
                prevColumnAlerts.indexOf(selectedAlert),
                1,
            );
            const newStateSections = cloneDeep(newsletterForDom.sections);
            newStateSections[nextColumnIndex] = newNextColumn;
            newStateSections[prevColumnIndex] = newPrevColumn;
            const newNewsletter = {
                ...newsletterData,
                sections: newStateSections,
            };
            setNewsletterForDom(newNewsletter);
            await moveAlertBetweenNewsletterSections(newsletterData.id, alertId, previousSectionId, nextSectionId, sortOrder);
        } catch(err) {
            handleError(err);
        }
    };


    return (
        <div className="d-flex flex-1">
            {newsletterForDom &&
            <>
                <Sections
                    newsletterData={newsletterForDom}
                    addFlashMessage={addFlashMessage}
                    refreshNewsletter={refreshNewsletter}
                    assignAlertToSection={assignAlertToSection}
                    removeAllAlertsFromSection={removeAllAlertsFromSection}
                    handleSectionSorting={handleSectionSorting}
                    savingSectionChanges={savingSectionChanges}
                    moveAlertBetweenSections={moveAlertBetweenSections}
                />

                <Sources
                    newsletterData={newsletterForDom}
                    addFlashMessage={addFlashMessage}
                    refreshNewsletter={refreshNewsletter}
                    assignAlertToSection={assignAlertToSection}
                />
            </>
            }
        </div>
    );
};

export default ManageSources;
