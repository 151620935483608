import React, { useState, useEffect } from 'react';
import { Label, ModalFooter, Button } from 'reactstrap';
import Toggle from 'react-toggle';
import moment from 'moment-timezone';

import SpinnerButton from 'components/SpinnerButton';
import 'containers/Newsletters/Modals/CreateAndEdit/style.scss';
import Automatic from './Automatic';
import Curated from './Curated';
import InputWithError from 'components/InputWithError';
import ModalBodyWithClose from 'components/ModalBodyWithClose';
import { RadioInput } from 'components/common/Input';

const NewsletterForm = (props) => {
    const { onSubmit, isEditForm, foundNewsletter, submitting, formTitle, toggle, submitButtonTitle, errors, setErrors } = props;
    const [newsletterName, setNewsletterName] = useState('');
    const [emailFromFriendlyName, setEmailFromFriendlyName] = useState('');
    const [emailReplyTo, setEmailReplyTo] = useState('');
    const [newsletterType, setNewsletterType] = useState('automatic');
    const [selectedDays, setSelectedDays] = useState({ first: [], second: [] });
    const [selectedDay, setSelectedDay] = useState('1');
    const [selectedTimeHour, setSelectedTimeHour] = useState({ first: '12:00', second: '12:00' });
    const [selectedTimePeriod, setSelectedTimePeriod] = useState({ first: 'PM', second: 'PM' });
    const [selectedFrequency, setSelectedFrequency] = useState('daily');
    const [selectedDailyFrequency, setSelectedDailyFrequency] = useState('once');
    const [selectedTimezone, setSelectedTimezone] = useState('');
    const [specificDate, setSpecificDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [includeAbstracts, setIncludeAbstracts] = useState(true);
    const [active, setActive] = useState(false);
    const [curatedType, setCuratedType] = useState('scheduled');
    const [instantEmail, setInstantEmail] = useState('');

    const getTimeFromString = (timeString, periodOrHour) => {
        if (periodOrHour === 'period') {
            return moment(timeString, ['hh:mm A']).format('A');
        } else {
            return moment(timeString, ['hh:mm A']).format('h:mm');
        }
    };

    useEffect(() => {
        const scheduleSettings = foundNewsletter?.scheduleSettings;
        const daily = scheduleSettings?.daily;
        const monthly = scheduleSettings?.monthly;
        const asAvailable = scheduleSettings?.as_available;
        const instantEmail = scheduleSettings?.instant_delivery_email;
        const specificDateTime = scheduleSettings?.specific_date_time;

        if (isEditForm && foundNewsletter) {
            setNewsletterName(foundNewsletter.name);
            setEmailFromFriendlyName(foundNewsletter.friendlyName);
            setActive(foundNewsletter.isEnabled);
            setEmailReplyTo(foundNewsletter.replyTo ?? '');
            setIncludeAbstracts(foundNewsletter.withAbstract);
            setSelectedTimezone(foundNewsletter.timezone);

            if (monthly) {
                const runDay = monthly.run_day;
                const runTime = monthly.run_time;

                setSelectedFrequency('monthly');
                setSelectedDay(runDay.toString());
                setSelectedTimePeriod({ first: getTimeFromString(runTime, 'period'), second: selectedTimePeriod.second });
                setSelectedTimeHour({ first: getTimeFromString(runTime, 'hour'), second: selectedTimeHour.second });
            } else if (daily) {
                const firstDaysOfWeek = daily.first.days_of_week;
                const firstRunTime = daily.first.run_time;
                const secondDaysOfWeek = daily?.second?.days_of_week ?? [];
                const secondRunTime = daily?.second?.run_time ?? [];

                if (daily.hasOwnProperty('second')) {
                    setSelectedDailyFrequency('twice');
                    setSelectedDays({ first: firstDaysOfWeek ?? [], second: secondDaysOfWeek ?? [] });
                    setSelectedTimePeriod({ first: getTimeFromString(firstRunTime, 'period'), second: getTimeFromString(secondRunTime, 'period') });
                    setSelectedTimeHour({ first: getTimeFromString(firstRunTime, 'hour'), second: getTimeFromString(secondRunTime, 'hour') });
                } else {
                    setSelectedDays({ first: firstDaysOfWeek ?? [], second: [] });
                    setSelectedTimePeriod({ first: getTimeFromString(firstRunTime, 'period'), second: selectedTimePeriod.second });
                    setSelectedTimeHour({ first: getTimeFromString(firstRunTime, 'hour'), second: selectedTimeHour.second });
                }
            } else if (asAvailable) {
                setSelectedDailyFrequency('available');
            } else if (specificDateTime) {
                const runDate = specificDateTime.run_date;
                const runTime = specificDateTime.run_time;

                setNewsletterType('curated');
                setSpecificDate(moment(runDate).format('YYYY-MM-DD'));
                setSelectedTimePeriod({ first: getTimeFromString(runTime, 'period'), second: selectedTimePeriod.second });
                setSelectedTimeHour({ first: getTimeFromString(runTime, 'hour'), second: selectedTimeHour.second });
            } else if (instantEmail) {
                setNewsletterType('curated');
                setCuratedType('instant');
                setInstantEmail(instantEmail);
            }
        }
    }, [foundNewsletter]);

    useEffect(() => {
        setErrors(null);
    }, [newsletterType, curatedType]);

    const handleDaysSelect = (day, isSecondNotification) => {
        let newDayArray = { ...selectedDays };

        if (isSecondNotification) {
            if (newDayArray.second.indexOf(day) > -1) {
                newDayArray.second.splice(newDayArray.second.indexOf(day), 1);
            } else {
                newDayArray.second.push(day);
            }
        } else {
            if (newDayArray.first.indexOf(day) > -1) {
                newDayArray.first.splice(newDayArray.first.indexOf(day), 1);
            } else {
                newDayArray.first.push(day);
            }
        }

        setSelectedDays(newDayArray);
    };

    const handleTimezoneSelect = (timezone) => {
        setSelectedTimezone(timezone);
    };

    const handleHourSelect = (hour, isSecondNotification) => {
        setSelectedTimeHour(
            isSecondNotification ? { first: selectedTimeHour.first, second: hour } : { first: hour, second: selectedTimeHour.second }
        );
    };

    const getScheduleSetting = () => {
        const firstNotificationRunTime = moment(selectedTimeHour.first + selectedTimePeriod.first, ['hh:mm A']).format('HH:mm');
        const secondNotificationRunTime = moment(selectedTimeHour.second + selectedTimePeriod.second, ['hh:mm A']).format('HH:mm');

        if (newsletterType === 'automatic') {
            if (selectedFrequency === 'daily') {
                if (selectedDailyFrequency === 'available') {
                    return { as_available: true };
                } else {
                    return {
                        daily: {
                            first: { days_of_week: selectedDays.first, run_time: firstNotificationRunTime },
                            ...(selectedDailyFrequency === 'twice'
                                ? { second: { days_of_week: selectedDays.second, run_time: secondNotificationRunTime } }
                                : {}),
                        },
                    };
                }
            } else {
                return {
                    monthly: {
                        run_day: Number(selectedDay),
                        run_time: firstNotificationRunTime,
                    },
                };
            }
        } else {
            if (curatedType === 'scheduled') {
                return {
                    specific_date_time: {
                        run_date: specificDate,
                        run_time: firstNotificationRunTime,
                    },
                };
            } else {
                return { instant_delivery_email: instantEmail };
            }
        }
    };

    const handleSubmit = async () => {
        const params = {
            name: newsletterName,
            friendlyName: emailFromFriendlyName,
            replyTo: emailReplyTo,
            isEnabled: active,
            withAbstract: newsletterType === 'curated' ? true : includeAbstracts,
            timezone: selectedTimezone,
            scheduleSettings: getScheduleSetting(),
        };
        onSubmit(params);
    };

    return (
        <div>
            <ModalBodyWithClose toggle={toggle} style={{ paddingBottom: '2rem' }}>
                <h2>{formTitle}</h2>
                <InputWithError
                    placeholder="Newsletter name"
                    name="name"
                    value={newsletterName}
                    type="text"
                    onChange={setNewsletterName}
                    errorObj={errors}
                    prependIcon={<i className="fa fa-newspaper-o" />}
                    marginBottomClass="mb-3"
                    tooltipText="Newsletter Name"
                />
                <InputWithError
                    placeholder="Friendly name"
                    name="friendlyName"
                    value={emailFromFriendlyName}
                    type="text"
                    onChange={setEmailFromFriendlyName}
                    errorObj={errors}
                    prependIcon={<i className="fa fa-user" />}
                    marginBottomClass="mb-3"
                    tooltipText="Friendly Name"
                />
                <InputWithError
                    placeholder="Reply to"
                    name="replyTo"
                    value={emailReplyTo}
                    type="email"
                    onChange={setEmailReplyTo}
                    errorObj={errors}
                    prependIcon={<i className="fa fa-reply" />}
                    marginBottomClass="mb-3"
                    tooltipText="Reply to"
                />
                <div className="mt-3">
                    <Label>Select Newsletter type</Label>
                    <div>
                        <RadioInput
                            onChange={() => setNewsletterType('automatic')}
                            checked={newsletterType === 'automatic'}
                            name="automatic"
                            text="Automatic"
                        />
                        <RadioInput
                            checked={newsletterType === 'curated'}
                            onChange={() => setNewsletterType('curated')}
                            name="curated"
                            text="Curated"
                        />
                    </div>
                </div>
                {newsletterType === 'automatic' ? (
                    <Automatic
                        selectedDays={selectedDays}
                        selectedTimeHour={selectedTimeHour}
                        selectedTimePeriod={selectedTimePeriod}
                        setSelectedTimePeriod={setSelectedTimePeriod}
                        selectedTimezone={selectedTimezone}
                        includeAbstracts={includeAbstracts}
                        setIncludeAbstracts={setIncludeAbstracts}
                        handleDaysSelect={handleDaysSelect}
                        setSelectedDay={setSelectedDay}
                        selectedDay={selectedDay}
                        handleTimezoneSelect={handleTimezoneSelect}
                        handleHourSelect={handleHourSelect}
                        errors={errors}
                        setSelectedDailyFrequency={setSelectedDailyFrequency}
                        selectedDailyFrequency={selectedDailyFrequency}
                        selectedFrequency={selectedFrequency}
                        setSelectedFrequency={setSelectedFrequency}
                    />
                ) : (
                    <Curated
                        selectedTimeHour={selectedTimeHour}
                        selectedTimePeriod={selectedTimePeriod}
                        setSelectedTimePeriod={setSelectedTimePeriod}
                        selectedTimezone={selectedTimezone}
                        specificDate={specificDate}
                        setSpecificDate={setSpecificDate}
                        curatedType={curatedType}
                        setCuratedType={setCuratedType}
                        instantEmail={instantEmail}
                        setInstantEmail={setInstantEmail}
                        handleTimezoneSelect={handleTimezoneSelect}
                        handleHourSelect={handleHourSelect}
                        errors={errors}
                    />
                )}
                <div className="newsletter-form__toggle-container">
                    <label>
                        <Toggle checked={active} name="active" icons={false} onChange={() => setActive(!active)} />
                    </label>
                    <span id="suspend-label">Set active</span>
                </div>
            </ModalBodyWithClose>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <SpinnerButton type={'modal'} color="primary" onClick={handleSubmit} submitting={submitting} title={submitButtonTitle} />
            </ModalFooter>
        </div>
    );
};

export default NewsletterForm;
