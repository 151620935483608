import React, {useState} from 'react';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import LabelsDropdown from '../FormLabelDropdown';
import Label from './Label';

const RelevanceContainerLabels = (props) => {
    const {labels, selectedLabels, creatingLabel, addLabel, addToSelected,
        removeFromSelected, fullWidth, hideBottomMarginWithEmptyList} = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <div className="relevance-label-container">
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className={fullWidth ? 'w-100' : ''}>
                <DropdownToggle
                    // tag="div"
                    className={'alt-label-toggle mb-2 ' + (fullWidth ? 'w-100' : '')}
                    color="default"
                    // block
                    style={{backgroundColor: '#ececec'}}
                >
                    <i className="fa fa-plus"/> &nbsp; Add or Create Labels
                </DropdownToggle>
                <DropdownMenu right>
                    <button type="button"
                            onClick={() => toggleDropdown()}
                            className="close btn btn-secondary label-dropdown-close-btn">
                        <span aria-hidden="true">×</span>
                    </button>
                    <LabelsDropdown labels={labels} selectedLabels={selectedLabels}
                                    creatingLabel={creatingLabel} addLabel={addLabel}
                                    addToSelected={addToSelected}
                                    removeFromSelected={removeFromSelected}/>
                </DropdownMenu>
            </Dropdown>
            {selectedLabels.length > 0 ?
                <div className="relevance-labels-list">
                    {selectedLabels.map(label => (
                        <Label key={label.name} label={label} removeFromSelected={removeFromSelected}/>
                    ))
                    }
                </div>
                :
                <div style={{marginBottom: hideBottomMarginWithEmptyList ? '0' : '1.5rem'}} />
            }
        </div>
    );
};

export default RelevanceContainerLabels;
