import React, {useEffect, useState} from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import {Table, Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment-timezone';
import {getMailingObjectStatsPerPeriod} from 'utils/api/statisticsAPI';
import CsvButton from 'containers/Reports/CsvButton';

const MailingObjectReportReport = () => {
    const [fetchingData, setFetchingData] = useState(false);
    const [fetchingCsv, setFetchingCsv] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'month').format('MM-DD-YYYY'));
    const [toDate, setToDate] = useState(moment().format('MM-DD-YYYY'));
    const [records, setRecords] = useState([]);
    const [activeSortProperty, setActiveSortProperty] = useState('name');
    const [activeSortDirection, setActiveSortDirection] = useState('asc');

    useEffect(async () => {
        setFetchingData(true);
        setRecords(
            await getMailingObjectStatsPerPeriod(
                moment(fromDate).format('YYYY-MM-DD'),
                moment(toDate).format('YYYY-MM-DD'),
                activeSortProperty,
                activeSortDirection
            )
        );
        setFetchingData(false);
    }, [fromDate, toDate, activeSortProperty, activeSortDirection]);

    const downloadFilteredCsv = async() => {
        setFetchingCsv(true);
        await getMailingObjectStatsPerPeriod(
            moment(fromDate).format('YYYY-MM-DD'),
            moment(toDate).format('YYYY-MM-DD'),
            activeSortProperty,
            activeSortDirection,
            true
        );
        setFetchingCsv(false);
    }

    const handleFromDateChange = day => {
        setFromDate(moment(day).format('MM-DD-YYYY'));
    };

    const handleToDateChange = day => {
        setToDate(moment(day).format('MM-DD-YYYY'));
    };

    const handleCaret = (value) => {
        if(activeSortProperty === value) {
            return activeSortDirection === 'asc' ?
                <i className="fa fa-caret-up list-sort-caret list-sort-caret--active"/>
                :
                <i className="fa fa-caret-down list-sort-caret list-sort-caret--active"/>;
        } else {
            return <i className="fa fa-caret-down list-sort-caret"/>;
        }
    };

    const handleSortableHeaderClick = (value) => {
        if(value === activeSortProperty) {
            activeSortDirection === 'asc' ? setActiveSortDirection('desc') : setActiveSortDirection('asc');
        } else {
            setActiveSortProperty(value);
            setActiveSortDirection('asc');
        }
    };

    return (
    <div className="list">
    {fetchingData ?
        <LoadingSpinner/>
        :
        <div>
        <div className="list__utility-row">
            <div className="list__search-container">
                <DayPickerInput
                    value={new Date(fromDate)}
                    dayPickerProps={{
                        disabledDays: {
                            before: new Date(moment().subtract(12, 'months').format('YYYY-MM-DD')),
                            after: new Date(toDate),
                        },
                    }}
                    autoComplete={false}
                    inputProps={{ref: null}}
                    onDayChange={handleFromDateChange}
                    component={props => (
                        <InputGroup className="pr-1 mb-0">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-calendar"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                {...props}
                                placeholder="MM/DD/YYYY"
                                value={fromDate}
                            />
                        </InputGroup>
                    )}
                />
                <DayPickerInput
                    value={new Date(toDate)}
                    dayPickerProps={{
                        disabledDays: {
                            before: new Date(fromDate),
                            after: new Date(),
                        },
                    }}
                    autoComplete={false}
                    inputProps={{ref: null}}
                    onDayChange={handleToDateChange}
                    component={props => (
                        <InputGroup className="pr-1 mb-0">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-calendar"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                {...props}
                                placeholder="MM/DD/YYYY"
                                value={toDate}
                            />
                        </InputGroup>
                    )}
                />
                <CsvButton
                    downloadFilteredCsv={downloadFilteredCsv}
                    downloading={fetchingCsv}
                />
            </div>
        </div>
        {records.length < 1 ?
            <div>
                No results found
            </div>
            :
            <div>
                <Table className="responsive-table alert-list-table">
                    <tbody>
                    <tr className="responsive-table header-row">
                        <th 
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('type')}
                        >Type {handleCaret('type')}
                        </th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('name')}
                        >Title {handleCaret('name')}</th>
                        <th>Notes</th>
                        <th>Paywall</th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('countUsers')}
                        ># Assigned Users {handleCaret('countUsers')}</th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('numberOpenedEmails')}
                        ># Clicks {handleCaret('numberOpenedEmails')}</th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('rateUsages')}
                        >% Usage (# of clicks/sent) {handleCaret('rateUsages')}</th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('connectionType')}
                        >Connection Type {handleCaret('connectionType')}</th>
                        <th>Labels</th>
                    </tr>
                    {records.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.type}</td>
                                <td>{item.name}</td>
                                <td dangerouslySetInnerHTML={{__html: item.comment}}/>
                                <td>{item.paywall ? 'Yes' : 'No'}</td>
                                <td>{item.countUsers}</td>
                                <td>{item.numberOpenedEmails}</td>
                                <td>{item.rateUsages}</td>
                                <td>{item.connectionType}</td>
                                <td style={{'whiteSpace': 'pre-line'}}>{item.labels.join('\n')}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </div>
        }
        </div>
    }
    </div>
    );
};

export default MailingObjectReportReport;
