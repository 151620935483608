import React from 'react';

import {Link} from 'react-router-dom';
import {Button} from 'reactstrap';
import { COMPANY_TYPE, CONTENT_TYPE, SEARCH_TYPE } from '.';

const EmptyList = (props) => {
    const {alertType} = props;

    const getLink = () => {
        switch(alertType) {
            case CONTENT_TYPE:
                return <Link to="/account/sources/create?type=rss">
                            <Button color="primary">Add RSS</Button>
                        </Link>;
            case COMPANY_TYPE:
                return <Link to="/account/companies/create">
                            <Button color="primary">Add a company</Button>
                        </Link>;
            case SEARCH_TYPE:
            default:
                return <Link to="/account/alerts/create">
                            <Button color="primary">Create Alert</Button>
                        </Link>;
        }
    }

    return (
        <div>
            <p>
                You can get emails when new results for a topic show up in Google Search.
                For example, you can get info about news, products, or mentions of your name.
                Monitor the web for desired news, create your first alert!
            </p>
            {getLink()}
        </div>
    );
};

export default EmptyList;
