import React, {useEffect, useState} from 'react';
import {Button, Label} from 'reactstrap';
import InputWithError from 'components/InputWithError';
import SpinnerButton from 'components/SpinnerButton';
import SpinnerButtonSmall from 'components/SpinnerButton/Small';
import {
    deleteJwtToken,
    editJwtToken,
} from 'utils/api/profileAPI';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ConfirmDeleteModal from 'containers/Settings/JWT/ConfirmDeleteModal';

const Token = (props) => {
    const {jwtTokenObj, addFlashMessage, handleError, refreshProfile} = props;

    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState('');
    const [tempName, setTempName] = useState('');
    const [token, setToken] = useState('');
    const [tempToken, setTempToken] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        setName(jwtTokenObj.name);
        setTempName(jwtTokenObj.name);
        setToken(jwtTokenObj.token.token);
        setTempToken(jwtTokenObj.token.token);
    }, [jwtTokenObj]);

    if(jwtTokenObj.type !== 1) {
        return null;
    }

    const resetFields = () => {
        setTempToken(token);
        setEditMode(false);
    };

    const updateJwt = async() => {
        try {
            setSubmitting(true);
            const params = {
                name: tempName,
                token: tempToken,
            };
            await editJwtToken(jwtTokenObj.id, params);
            addFlashMessage('success', 'Token successfully updated');
            setSubmitting(false);
            setName(tempName);
            setToken(tempToken);
            setEditMode(false);
            refreshProfile();
        } catch(err) {
            // handleError(err, setErrors);
            if(err.message && err.message === 'This token already used') {
                addFlashMessage('danger', 'This token is already in use');
            } else {
                addFlashMessage('danger', 'Invalid token details');

            }
            setSubmitting(false);
        }
    };

    const deleteJwt = async() => {
        try {
            setDeleting(true);
            await deleteJwtToken(jwtTokenObj.id);
            addFlashMessage('success', 'Token successfully deleted');
            setDeleting(false);
            toggleDeleteModal()
            refreshProfile();
        } catch(err) {
            handleError(err, setErrors);
            setDeleting(false);
        }
    };

    const toggleDeleteModal = () => setDeleteModalOpen(prevState => !prevState);

    return (
        <div className="courtlink-token">
            <ConfirmDeleteModal
                onDelete={deleteJwt}
                isOpen={deleteModalOpen}
                toggle={toggleDeleteModal}
                deleting={deleting}
                errors={errors}
            />
            {editMode ?
                <div className="courtlink-token__info">
                    <div>
                        {/*<Label>Client ID</Label>*/}
                        <div style={{maxWidth: '490px', width: '100%'}}>
                            <InputWithError
                                prependIcon={<i className="fa fa-tag"/>}
                                name="name"
                                value={tempName}
                                type="text"
                                onChange={setTempName}
                                errorObj={errors}
                                marginBottomClass="mb-3"
                                placeholder="Token Name"
                            />
                        </div>
                    </div>
                    <div>
                        {/*<Label>Client ID</Label>*/}
                        <div style={{maxWidth: '490px', width: '100%'}}>
                            <InputWithError
                                prependIcon={<i className="fa fa-key"/>}
                                name="token"
                                value={tempToken}
                                type="text"
                                onChange={setTempToken}
                                errorObj={errors}
                                marginBottomClass="mb-0"
                                placeholder="JWT Token"
                            />
                        </div>
                    </div>
                </div>
                :
                <div className="courtlink-token__info">
                    <CopyToClipboard text={name}>
                        <div className="courtlink-token-info-item"><strong>Token Name:</strong> {name}</div>
                    </CopyToClipboard>
                    <CopyToClipboard text={token}>
                        <div className="courtlink-token-info-item"><strong>Token Value:</strong> {token}</div>
                    </CopyToClipboard>
                </div>
            }
            {editMode ?
                <div className="courtlink-token__actions">
                    <Button
                        className="px-4 mr-2 courtlink-field-btn"
                        onClick={resetFields}
                    >
                        Cancel
                    </Button>
                    <SpinnerButton
                        className="courtlink-field-btn"
                        color="primary"
                        submitting={submitting}
                        onClick={updateJwt}
                        title="Update"
                    />
                </div>
                :
                <div className="courtlink-token__actions">
                    <Button className="btn-light mr-2" onClick={() => setEditMode(true)}><i
                        className="fa fa-edit"/></Button>
                    <Button
                        onClick={toggleDeleteModal}
                        color="danger"
                    >
                        <i className="fa fa-trash"/>
                    </Button>
                    {/*<SpinnerButtonSmall*/}
                    {/*    color="danger"*/}
                    {/*    // className="newsletter-section__header-btn"*/}
                    {/*    onClick={() => deleteJwt(jwtTokenObj.id)}*/}
                    {/*    disabled={false}*/}
                    {/*    submitting={deleting}*/}
                    {/*    defaultSize*/}
                    {/*    title={<i className="fa fa-trash"/>}*/}
                    {/*/>*/}
                    {/*<Button className="btn-danger" onClick={() => deleteCourtlinkDetails(jwtTokenObj.id)}><i*/}
                    {/*    className="fa fa-trash"/></Button>*/}
                </div>
            }
        </div>
    );
};

export default Token;
