import React, {useState, useEffect} from 'react';

import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    InputGroupAddon,
    InputGroupText,
    Input, InputGroup,
} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import DualSelect from 'components/DualSelect';
import {assignAlertsToAlertGroup, editAlertGroup, getAlertsForGroupList} from 'utils/api/alertsAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import InputWithError from 'components/InputWithError';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const EditAlertGroupModal = (props) => {
    const {isOpen, toggle, handleError, alertGroup, addFlashMessage, refreshAlertGroups} = props;
    const [name, setName] = useState('');
    const [alerts, setAlerts] = useState([]);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [fetchingAlerts, setFetchingAlerts] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if(alertGroup) {
            setName(alertGroup.name);
            setSelectedAlerts(alertGroup.alerts);
        }
    }, [alertGroup]);

    const fetchAlerts = async() => {
        try {
            const alertData = await getAlertsForGroupList();
            setAlerts(alertData.result);
            setFetchingAlerts(false);
        } catch(err) {
            setFetchingAlerts(false);
            addFlashMessage('danger', 'Unable to fetch alerts');
        }
    };

    useEffect(() => {
        fetchAlerts();
    }, []);

    const handleSubmit = async(id, name, selectedAlerts) => {
        try {
            setSubmitting(true)
            let assignParams = {alerts: selectedAlerts.map(item => item.id)};
            await editAlertGroup(id, {name});
            await assignAlertsToAlertGroup(id, assignParams);
            refreshAlertGroups();
            addFlashMessage('success', 'Alert group successfully edited');
            setSubmitting(false);
            toggle();
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-xl">
            {!alertGroup ?
                <div/>
                :
                fetchingAlerts ?
                    <LoadingSpinner padding text={'Fetching alert data'}/>
                    :
                    <div>
                        <ModalBodyWithClose toggle={toggle}>
                            <h2>Edit Group</h2>
                            <InputWithError
                                prependIcon={<i className="fa fa-tag"/>}
                                placeholder="Alert group name"
                                name="name"
                                value={name}
                                type="text"
                                autoComplete={'off'}
                                onChange={setName}
                                errorObj={errors}
                            />
                            <div className="mt-3">
                                <DualSelect
                                    selectedList={selectedAlerts}
                                    fullList={alerts}
                                    onSelect={setSelectedAlerts}
                                    contentType="users"
                                    isSourcesOnly={true}
                                />
                            </div>
                        </ModalBodyWithClose>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                            <SpinnerButton
                                type={'modal'}
                                color="primary"
                                onClick={() => handleSubmit(alertGroup.id, name, selectedAlerts)}
                                submitting={submitting}
                                title={'Update'}
                            />
                        </ModalFooter>
                    </div>
            }
        </Modal>
    );
};

export default EditAlertGroupModal;
