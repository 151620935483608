import React from 'react';
import {Pie} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const TopPublishersChart = ({publishers}) => {
    const labels = publishers.slice(0, 5).map(item => item.domain);
    const counts = publishers.slice(0, 5).map(item => item.cnt);

    const sum = counts.reduce((a, b) => {
        return a + b;
    })

    let trunc_labels = labels.map(e => e.substring(0, 18) + '...');
    const publisherData = {
        labels: trunc_labels,
        datasets: [{
            data: counts,
            backgroundColor: [
                '#1c5181',
                '#1a94c7',
                '#03bdea',
                '#90ccfe',
                '#c3d2da'
            ],
            hoverBackgroundColor: [
                '#1c5181',
                '#1a94c7',
                '#03bdea',
                '#90ccfe',
                '#c3d2da'
            ]
        }]
    };

    return (
        <div style={{maxHeight: 250}}>
            <Pie data={publisherData}
                 height={250}
                 options={{
                     legend: {
                         display: false
                     },
                     fontSize: 44,
                     maintainAspectRatio: false,
                     tooltips: {
                         callbacks: {
                             label: function(t) {
                                 let xLabel = labels[t.index]
                                 let xLabelArr = xLabel.split(' ');
                                 let tooltipArray = [];
                                 tooltipArray.push(xLabelArr.slice(0, 4).join(' '))
                                 return tooltipArray
                             },

                             afterLabel: function(t) {
                                 let xLabel = labels[t.index]
                                 let xLabelArr = xLabel.split(' ');
                                 xLabelArr = xLabelArr.slice(4, xLabelArr.length)
                                 let length = xLabelArr.length;
                                 if(xLabelArr.length === 0) {
                                     return null
                                 }
                                 let tooltipArray = [];
                                 let increment;
                                 xLabelArr.slice(0, 4).join(' ').length > 26 ? increment = 3 : increment = 4
                                 for(let i = 0; i < length; i+=increment) {
                                     tooltipArray.push(xLabelArr.slice(i, i + increment).join(' '))
                                 }
                                 return tooltipArray
                             }
                         }
                     },
                     plugins: {
                         datalabels: {
                             display: true,
                             color: 'white',
                             font: {
                                 size: 18,
                                 weight: '500'
                             },
                             formatter: function(value) {
                                 if(Math.round((value/sum)*100) >  9) {
                                     return Math.round((value / sum) * 100) + '%';
                                 } else {
                                     return null
                                 }
                             }
                         }
                     }
                 }}
            />
        </div>
    )
}

export default TopPublishersChart;
