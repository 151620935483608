import React, {useContext, useEffect, useState} from 'react';

import moment from 'moment-timezone';

import SingleUserList from 'containers/Users/List/SingleUserList';
import OrganizationList from 'containers/Users/List/OrganizationList';
import {AuthContext} from 'contexts/AuthContext';
import {getUserLabels, getUsers, getUsersWithoutParams} from 'utils/api/usersAPI';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import './style.scss';
import {FilterContext} from 'contexts/FilterContext';
import List from 'containers/Alerts/AlertsList/List';
import {getAlertsWithoutParams} from 'utils/api/alertsAPI';
import SingleUserBlock from 'components/SingleUserBlock';

const UsersList = (props) => {
    const {state, handleError} = useContext(AuthContext);
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {setFiltersObject, userFilters} = useContext(FilterContext);

    const {
        paginationCurrentPageNumber, activeSortProperty, activeSortDirection,
        activeFilterLabels, searchValue, searchProperty,
    } = userFilters;

    const [pageNumber, setPageNumber] = useState(1);
    const [users, setUsers] = useState([]);
    const [navOptions, setNavOptions] = useState({});
    const [fetchingData, setFetchingData] = useState(true);
    const [fetchingLabels, setFetchingLabels] = useState(true);
    const [labels, setLabels] = useState([]);
    const [fetchError, setFetchError] = useState(false);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [submittingSearch, setSubmittingSearch] = useState(false);
    const [showFirstTimeMessage, setShowFirstTimeMessage] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [initialLoad, setInitialLoad] = useState(true);
    const [fetchingCsv, setFetchingCsv] = useState(false);

    const fetchUsers = async(csv) => {
        try {
            if(csv) {
                setFetchingCsv(true);
                let title = 'ozmosys_users_' + moment().tz(moment.tz.guess()).format('MMDDYYYY');
                const csvData = await getUsers(false, false, paginationCurrentPageNumber, 10000, activeSortProperty, activeSortDirection, searchProperty, searchValue, activeFilterLabels, true, title);
                setFetchingCsv(false);
            } else {
                setSubmittingSearch(true);
                let refreshCount = (initialLoad || paginationCurrentPageNumber === 1 || !users.length);
                const userData = await getUsers(false, refreshCount, paginationCurrentPageNumber, 50, activeSortProperty, activeSortDirection, searchProperty, searchValue, activeFilterLabels);
                setUsers(userData.result);
                if(refreshCount) setTotalPages(userData.navigation.total_pages);
                setNavOptions(userData.navigation);
                if(tempSearchValue !== searchValue) setTempSearchValue(searchValue);
                setFetchingData(false);
                setSubmittingSearch(false);
                if(userData.result.length > 0) {
                    showFirstTimeMessage && setShowFirstTimeMessage(false);
                }
                initialLoad && setInitialLoad(false);
            }
        } catch(err) {
            setFetchingData(false);
            fetchingCsv && setFetchingCsv(false);
            setSubmittingSearch(false);
        }
    };

    const fetchFullUserList = async() => {
        try {
            setFetchingCsv(true);
            let title = 'ozmosys_users_' + moment().tz(moment.tz.guess()).format('MMDDYYYY') + '.csv';
            await getUsersWithoutParams(true, title);
            setFetchingCsv(false);
        } catch(err) {
            fetchingCsv && setFetchingCsv(false);
            handleError(err);
        }
    };

    const fetchLabels = async() => {
        try {
            !fetchingLabels && setFetchingLabels(true);
            const labels = await getUserLabels();
            setLabels(labels);
            setFetchingLabels(false);
        } catch(err) {
            addFlashMessage('danger', 'Unable to fetch alerts');
            setFetchingLabels(false);
        }
    };

    useEffect(() => {
        fetchLabels();
    }, []);

    useEffect(() => {
        if(!fetchingLabels) {
            fetchUsers();
        }
    }, [userFilters, labels, fetchingLabels]);

    const refreshUsers = async(callback) => {
        await fetchUsers();
        callback && callback();
    };

    const handleSearchValueSubmit = () => {
        if(tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)')
            return;
        }
        let filters = {...userFilters};
        filters.searchValue = tempSearchValue;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('users', filters);
    };

    const handleActiveFilterLabelSelect = (labelName) => {
        let filterArr = [...activeFilterLabels];
        if(filterArr.indexOf(labelName) > -1) {
            filterArr.splice(filterArr.indexOf(labelName), 1);
        } else {
            filterArr.push(labelName);
        }
        let filters = {...userFilters};
        filters.activeFilterLabels = filterArr;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('users', filters);
    };

    const handleClearSearchValue = () => {
        let filters = {...userFilters};
        filters.searchValue = '';
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('users', filters);
        setTempSearchValue('');
    };

    return (
        <div>
            {state.isSingleUser &&
            <SingleUserBlock itemType="users" wide/>
            }
            <OrganizationList
                users={users}
                isSingleUser={state.isSingleUser}
                navOptions={navOptions}
                fetchingData={fetchingData}
                userFilters={userFilters}
                refreshUsers={refreshUsers}
                tempSearchValue={tempSearchValue}
                setTempSearchValue={setTempSearchValue}
                submittingSearch={submittingSearch}
                handleSearchValueSubmit={handleSearchValueSubmit}
                allLabels={labels}
                fetchingLabels={fetchingLabels}
                refreshLabels={fetchLabels}
                handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                showFirstTimeMessage={showFirstTimeMessage}
                handleClearSearchValue={handleClearSearchValue}
                totalPages={totalPages}
                addFlashMessage={addFlashMessage}
                handleError={handleError}
                setFiltersObject={setFiltersObject}
                downloadFilteredCsv={() => fetchUsers(true)}
                downloadFullCsv={fetchFullUserList}
                fetchingCsv={fetchingCsv}
            />
        </div>
    );
};

export default UsersList;
