import React, { useContext, useState, useEffect } from 'react';

import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import LoadingSpinner from 'components/LoadingSpinner';
import { previewCompanyBasedAlert, addCompanyBasedAlert, checkPossibleToAddAlert } from 'utils/api/alertsAPI';
import List from './List/';
import './style.scss';
import SpinnerButton from 'components/SpinnerButton';
import { useHistory } from 'react-router-dom';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import CompanyPageDescription from './CompanyPageDescription';
import CompanyPageHeader from './CompanyPageHeader';
import ExtendedInformation from './ExtendedInformation';
import useWindowSize from 'hooks/useWindowSize';

const CompanyPagePreview = (props) => {
    const { symbol } = useParams();
    const history = useHistory();
    useWindowSize();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const [fetchingData, setFetchingData] = useState(true);
    const [alertData, setAlertData] = useState([]);
    const [companyDescription, setCompanyDescription] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [keyExecutives, setKeyExecutives] = useState([]);

    const fetchAlertData = async () => {
        try {
            setFetchingData(true);
            let data = await previewCompanyBasedAlert(symbol);
            setAlertData(data);
            setFetchingData(false);
            setCompanyDescription(data.description?.slice(0, 500));
            setKeyExecutives(data.keyExecutives?.slice(0, 3));
        } catch (err) {
            addFlashMessage('danger', 'The company details you have selected are currently unavailable');
            history.length > 2 && document.referrer.indexOf(window.location.host) !== -2 && history.go(-2);
            setFetchingData(false);
        }
    };

    useEffect(() => {
        symbol && fetchAlertData();
    }, [symbol]);

    const handleSubmit = async () => {
        const companyBasedSearchValues = {
            name: alertData.companyName,
            symbol,
        };

        try {
            setSubmitting(true);
            let isPossibleToAdd = await checkPossibleToAddAlert();
            if (isPossibleToAdd) {
                await addCompanyBasedAlert(companyBasedSearchValues);
                setSubmitting(false);
                addFlashMessage('success', 'Alert successfully created');
                history.push('/account/manage-companies');
            } else {
                addFlashMessage(
                    'danger',
                    "You've reached the limit of allowed number of alerts for your account.\n" + 'Contact Customer Care team for more information.'
                );
            }
        } catch (err) {
            setSubmitting(false);
            addFlashMessage('danger', 'Problems during alert creation.');
        }
    };

    return (
        <div className={'view dig-deeper animated fadeIn ' + (props.public ? 'container dig-deeper--public ' : '')}>
            {fetchingData ? (
                <Row className="mt-3">
                    <Col xs="12">
                        <LoadingSpinner text="Fetching company data" />
                    </Col>
                </Row>
            ) : (
                alertData && (
                    <Row className="mt-3 mb-4">
                        <CompanyPageHeader alertData={alertData} />
                        <Col xs="12" className="mt-3">
                            <SpinnerButton
                                color="primary"
                                type="submit"
                                className="px-4 mb-2"
                                onClick={handleSubmit}
                                submitting={submitting}
                                title={'Save'}
                            />
                        </Col>
                        <Col xs={window.innerWidth >= 750 ? '9' : '12'} className="mt-5 mb-5 company-main-block">
                            <CompanyPageDescription
                                alertData={alertData}
                                companyDescription={companyDescription}
                                setCompanyDescription={setCompanyDescription}
                            />
                            {window.innerWidth < 750 && (
                                <ExtendedInformation alertData={alertData} keyExecutives={keyExecutives} setKeyExecutives={setKeyExecutives} />
                            )}
                            {alertData.news && <List articles={alertData.news} articlesWithoutDuplicates={alertData.news} />}
                        </Col>
                        {window.innerWidth >= 750 && (
                            <Col xs="3" className="mt-5 mb-5">
                                <ExtendedInformation alertData={alertData} keyExecutives={keyExecutives} setKeyExecutives={setKeyExecutives} />
                            </Col>
                        )}
                    </Row>
                )
            )}
        </div>
    );
};

export default CompanyPagePreview;
