import React, {useState, useContext, useEffect} from 'react';

import {Button, Card, CardBody, Table} from 'reactstrap';

import CreateSetModal from 'containers/Dashboard/ComparativeAnalysis/Modals/CreateEditSetModal';
import EditSetModal from 'containers/Dashboard/ComparativeAnalysis/Modals/CreateEditSetModal';
import DeleteSetModal from './Modals/DeleteSetModal';
import SearchInput from 'components/SearchInput/SearchInputWithSubmit';
import {getComparativeSets, deleteComparativeSet} from 'utils/api/statisticsAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import CompSetListItem from './ListItem';
import PaginationWrapper from 'components/PaginationWrapper';
import {handleCaret} from 'Helpers/handleSortCaret';
import {isEqual} from 'lodash';

const List = (props) => {

    const {handleCompSetSelect, fetchCompSetStatistics, activeSet, handleError, addFlashMessage, compSetFilters, setFiltersObject} = props;
    const {
        paginationCurrentPageNumber, activeSortProperty, activeSortDirection,
        searchValue, searchProperty,
    } = compSetFilters;

    const [compSets, setCompSets] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);
    const [submittingSearch, setSubmittingSearch] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [navOptions, setNavOptions] = useState({});
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [deleting, setDeleting] = useState(false);
    const [compSetToEdit, setCompSetToEdit] = useState(null);
    const [compSetToDelete, setCompSetToDelete] = useState(null);
    const [totalPages, setTotalPages] = useState(1);

    const fetchCompSets = async() => {
        try {
            setSubmittingSearch(true);
            let refreshCount = (paginationCurrentPageNumber === 1);
            const compSetData = await getComparativeSets(false, refreshCount, paginationCurrentPageNumber, 10, activeSortProperty, activeSortDirection, searchProperty, searchValue);
            setCompSets(compSetData.result);
            if(refreshCount) setTotalPages(compSetData.navigation.total_pages);
            setNavOptions(compSetData.navigation);
            if(tempSearchValue !== searchValue) setTempSearchValue(searchValue);
            setFetchingData(false);
            setSubmittingSearch(false);
        } catch(err) {
            setFetchingData(false);
            setSubmittingSearch(false);
            handleError(err);
        }
    };

    useEffect(() => {
        fetchCompSets();
    }, [compSetFilters]);

    const handleSearchValueSubmit = () => {
        if(tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)')
            return;
        }
        let filters = {...compSetFilters};
        filters.searchValue = tempSearchValue;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('compSets', filters);
    };

    const handleClearSearchValue = () => {
        let filters = {...compSetFilters};
        filters.searchValue = '';
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('compSets', filters);
        setTempSearchValue('');
    };

    const toggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen);
    };

    const toggleEditModal = () => {
        if(editModalOpen) {
            setEditModalOpen(false);
            setCompSetToEdit(null);
        } else {
            setEditModalOpen(true);
        }
    };

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen);
    };

    useEffect(() => {
        if(compSetToEdit) {
            setEditModalOpen(true);
        }
    }, [compSetToEdit]);

    useEffect(() => {
        if(compSetToDelete) {
            setDeleteModalOpen(true);
        }
    }, [compSetToDelete]);

    useEffect(() => {
        if(activeSet) {
            let foundSet = compSets.find(item => item.id === activeSet.id);
            if(!isEqual(activeSet, foundSet)) {
                handleCompSetSelect(foundSet);
            }
        }
    }, [activeSet, compSets]);

    const deleteCompSet = async(id, modalCallback) => {
        try {
            setDeleting(true);
            await deleteComparativeSet(id);
            modalCallback && modalCallback();
            setDeleting(false);
            addFlashMessage('success', 'Comparative set successfully deleted');
            setCompSetToDelete(null);
            await fetchCompSets();
        } catch(err) {
            setDeleting(false);
            handleError(err);
        }
    };

    const handlePageClick = (data) => {
        let filters = {...compSetFilters};
        filters.paginationCurrentPageNumber = data.selected + 1;
        filters.useCount = false;
        setFiltersObject('compSet', filters);
    };

    const handleSortableHeaderClick = (value) => {
        let filters = {...compSetFilters};
        if(value === activeSortProperty) {
            activeSortDirection === 'asc' ?
                filters.activeSortDirection = 'desc'
                :
                filters.activeSortDirection = 'asc';
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }
        setFiltersObject('compSets', filters);
    };

    return (
        <Card className="comparative-analysis-list">
            <CardBody style={{minHeight: 0}}>

                <div className="d-flex mb-4 comparative-analysis-list__toolbar">
                    <SearchInput
                        value={tempSearchValue}
                        onChange={setTempSearchValue}
                        placeholder="Search by comparative set name"
                        onClear={handleClearSearchValue}
                        onSubmit={handleSearchValueSubmit}
                        submitting={submittingSearch}
                        list
                    />
                    <Button className="comparative-analysis-list__create-btn" onClick={toggleCreateModal}
                            color="primary">Create Set</Button>
                </div>
                <div>
                    {fetchingData ?
                        <LoadingSpinner/>
                        :
                        compSets.length < 1 ?
                            <div>No comparative sets found</div>
                            :
                            <div>
                                <Table className="comp-set-table responsive-table">
                                    <tbody>
                                    <tr className="responsive-table header-row">
                                        <th
                                            className="responsive-table__sortable-th"
                                            onClick={() => handleSortableHeaderClick('comparativeSet.name')}
                                        >
                                            Name
                                            {handleCaret('comparativeSet.name', activeSortDirection, 'comparativeSet.name')}
                                        </th>
                                        <th>Alerts</th>
                                        <th>Compare</th>
                                        <th>Download</th>
                                        <th>Edit/Delete</th>
                                    </tr>
                                    {compSets.map((compSet) => {
                                        return (
                                            <CompSetListItem
                                                key={compSet.id}
                                                compSet={compSet}
                                                toggleDeleteModal={toggleDeleteModal}
                                                addFlashMessage={addFlashMessage}
                                                fetchingLabels={fetchingData}
                                                handleCompSetSelect={handleCompSetSelect}
                                                fetchCompSetStatistics={fetchCompSetStatistics}
                                                setCompSetToEdit={setCompSetToEdit}
                                                setCompSetToDelete={setCompSetToDelete}
                                                activeSet={activeSet}
                                                handleError={handleError}
                                            />
                                        );
                                    })}
                                    </tbody>
                                </Table>
                                <PaginationWrapper
                                    totalPages={totalPages}
                                    handlePageClick={handlePageClick}
                                    forcePage={paginationCurrentPageNumber - 1}
                                />
                            </div>
                    }
                </div>
            </CardBody>
            {createModalOpen &&
            <CreateSetModal
                isOpen={createModalOpen}
                toggle={toggleCreateModal}
                addFlashMessage={addFlashMessage}
                refreshCompSets={fetchCompSets}
                handleError={handleError}
            />
            }
            {editModalOpen &&
            <EditSetModal
                isEditForm
                isOpen={editModalOpen}
                toggle={toggleEditModal}
                item={compSetToDelete}
                compSetToEdit={compSetToEdit}
                setCompSetToEdit={setCompSetToEdit}
                refreshCompSets={fetchCompSets}
                addFlashMessage={addFlashMessage}
                activeSet={activeSet}
                compSets={compSets}
                handleCompSetSelect={handleCompSetSelect}
            />
            }
            {deleteModalOpen &&
            <DeleteSetModal
                isOpen={deleteModalOpen}
                toggle={toggleDeleteModal}
                item={compSetToDelete}
                onSubmit={deleteCompSet}
                submitting={deleting}
            />
            }
        </Card>
    );
};

export default List;
