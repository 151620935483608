import React from 'react';

const TabItem = (props) => {
    const {activeItem, item, handleSelect, errorObj, shrink, smallText, right} = props;
    let containsError;
    if(item.data === 'general' && errorObj) {
        if(errorObj.errors &&
            errorObj.errors.hasOwnProperty('fullName') ||
            errorObj.errors.hasOwnProperty('email') ||
            errorObj.errors.hasOwnProperty('timezone')
        ) {
            containsError = true;
        }
    }
    if(item.data === 'emailSchedule' && errorObj) {
        if(errorObj.errors &&
            errorObj.errors.hasOwnProperty('sameSchedule') ||
            errorObj.errors.hasOwnProperty('days')
        ) {
            containsError = true;
        }
    }
    return (
        <div
            onClick={() => handleSelect(item.data)}
            className={'tab-item ' +
            (shrink ? 'tab-item--shrink ' : ' ') +
            (smallText ? 'tab-item--small-text ' : ' ') +
            (activeItem === item.data ? 'tab-item--active ' : ' ') +
            (containsError ? 'tab-item--error ' : '') +
            (right ? 'tab-item--right ' : '')
            }
        >
            {item.name}
            {containsError && <i className="fa fa-exclamation-circle"/>}
        </div>
    );
};

export default TabItem;
