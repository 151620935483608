import React, {useEffect, useState} from 'react';

import {Button, Modal, ModalBody} from 'reactstrap';
import moment from 'moment-timezone';

import LoadingSpinner from 'components/LoadingSpinner';
import {convertDateString} from 'Helpers/convertDateString';
import {getAlertPreview, previewRssAlert} from 'utils/api/alertsAPI';
import AlertPreviewItem from 'components/AlertPreviewItem';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const AlertGroupPreview = (props) => {
    const {toggle, isOpen, alertGroup, handleError} = props;
    const [fetchingData, setFetchingData] = useState(true);
    const [fetchingDataError, setFetchingDataError] = useState(false);
    const [browserLink, setBrowserLink] = useState(null);
    const [previewContent, setPreviewContent] = useState(null);

    const fetchAlertGroupPreview = async() => {
        try {
            let preview = await previewRssAlert(alertGroup.rss);
            setPreviewContent(preview);
            setFetchingData(false);
        } catch(err) {
            setFetchingData(false);
            setFetchingDataError(true);
            handleError(err);
        }
    };

    useEffect(() => {
        alertGroup && fetchAlertGroupPreview();
    }, [alertGroup]);

    const handleScroll = () => {
        let anchor = document.querySelector('#anchor');
        anchor.scrollIntoView({behavior: "smooth"});
    };

    if(!alert) return <div/>;
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="newsletter-preview-modal modal-lg">
            <div>
                <ModalBodyWithClose toggle={toggle}>
                    <div className="d-flex justify-content-between mb-2">
                        <div className="newsletter-preview-modal__header">
                            <h2 className="mb-1">Group Preview</h2>
                            <h5 className="mb-1">{alertGroup.name}</h5>
                            <h5 className="mb-0">{convertDateString(moment())}</h5>
                        </div>
                        <div className="d-flex align-items-end">
                            {previewContent && previewContent.content && previewContent.content.length > 0 &&
                            <Button onClick={handleScroll}>
                                {window.innerWidth > 600 ?
                                    'Back to top'
                                    :
                                    <i className="fa fa-chevron-up"/>
                                }
                            </Button>
                            }
                        </div>
                    </div>
                    {fetchingDataError ?
                        <div className="mt-4 mb-3">
                            <div className="error-block d-flex">
                                <i className="fa fa-exclamation-circle"/>
                                <div>Unable to fetch alert group content at this time. Please try again later.</div>
                            </div>
                        </div>
                        :
                        <div className="newsletter-preview-modal__body">
                            <div id="anchor"/>
                            {fetchingData ?
                                <div style={{padding: '1rem 0'}}>
                                    <LoadingSpinner text="Fetching content"/>
                                </div>
                                :
                                previewContent.content && previewContent.content.length ?
                                    <div className="pr-3 mt-2">
                                        {previewContent.content.map(item => {
                                            return <AlertPreviewItem item={item}/>
                                        })}
                                    </div>
                                    :
                                    <div style={{padding: '1rem 0'}}>
                                        No alert group content is available yet. Please check again later for updated content.
                                    </div>

                            }
                        </div>
                    }
                </ModalBodyWithClose>
            </div>
        </Modal>
    );
};

export default AlertGroupPreview;



