import React, {useState, useEffect, useContext} from 'react';

import CopyToClipboard from 'react-copy-to-clipboard';

import ReaderSettingsToggle from './ReaderSettingsToggle';
import SpinnerButton from 'components/SpinnerButton';
import {getOrganizationLoginToReader, editOrganizationReaderSettings} from 'utils/api/profileAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import {AuthContext} from 'contexts/AuthContext';

const ReaderSettings = (props) => {
    const {handleError} = useContext(AuthContext);
    const {profile, addFlashMessage, refreshProfile} = props;
    const [submitting, setSubmitting] = useState(false);
    const [allowReaderManagement, setAllowReaderManagement] = useState(false);
    const [allowPersonalReaderLogin, setAllowPersonalReaderLogin] = useState(false);
    const [readerLoginDetails, setReaderLoginDetails] = useState(null);
    const [fetchingData, setFetchingData] = useState(true);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if(profile.organization) {
            setAllowReaderManagement(profile.organization.myReaderAuth);
            if(profile.organization.loginToReader) {
                getReaderLogin()
            } else {
                setFetchingData(false);
            }
        }
    }, [profile])

    const getReaderLogin = async() => {
        try {
            const readerLoginDetails = await getOrganizationLoginToReader();
            setAllowPersonalReaderLogin(readerLoginDetails.allowLogin);
            setReaderLoginDetails(readerLoginDetails);
            setFetchingData(false);
        } catch(err) {
            handleError(err);
        }
    };

    const updateOrganizationReaderSettings = async() => {
        try {
            setSubmitting(true);
            setErrors(null);
            const params = {
                myReaderAuth: allowReaderManagement,
                allowLogin: allowPersonalReaderLogin,
                regenerate: false,
            };
            const res = await editOrganizationReaderSettings(params);
            console.log(res)
            if(allowPersonalReaderLogin) {
                getReaderLogin()
            }
            addFlashMessage('success', 'Reader settings successfully updated');
            setSubmitting(false);
            refreshProfile();
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    if(fetchingData) return <LoadingSpinner text="Fetching reader settings"/>;
    return (
        <div className="email-settings">
            <div>
                <h4 className="settings__page-header">Reader Settings</h4>
                <div className="mt-3">
                    <ReaderSettingsToggle
                        text={'Allow users to manage alerts in Reader'}
                        checked={allowReaderManagement}
                        handleCheck={setAllowReaderManagement}
                    />
                </div>
            </div>


            <div>
                <h4 className="settings__page-header mt-5">Reader Login</h4>
                <div className="mt-3">
                    <ReaderSettingsToggle
                        text={'Use personal reader login'}
                        checked={allowPersonalReaderLogin}
                        handleCheck={setAllowPersonalReaderLogin}
                    />
                </div>
                {readerLoginDetails && allowPersonalReaderLogin &&
                <div className="mt-3">
                    <div>Reader URL:</div>
                    <div className="mb-3">
                        <CopyToClipboard text={'https://news.ozmosys.com/company/reader'}>
                            <p style={{cursor: 'copy'}}>https://news.ozmosys.com/company/reader</p>
                        </CopyToClipboard>
                    </div>
                    <span>Company Password:</span>
                    <div className="reader-password-info">
                        <CopyToClipboard text={readerLoginDetails.organizationPassword}>
                            <span style={{cursor: 'copy'}}>{readerLoginDetails.organizationPassword}</span>
                        </CopyToClipboard>
                    </div>
                </div>
                }
            </div>
            <div className="mt-4">
                <SpinnerButton
                    color="primary"
                    title="Save Changes"
                    onClick={updateOrganizationReaderSettings}
                    submitting={submitting}
                />
            </div>
        </div>
    );
};

export default ReaderSettings;
