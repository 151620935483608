import React from 'react';

const Tag = (props) => {
    const {name, news} = props.tag;
    const {handleTagSelect, isActive} = props;

    let fontSize;
    if (news.length >= 10) {
        fontSize = 22;
    } else if (news.length >= 4) {
        fontSize = 16;
    } else {
        fontSize = 13;
    }
    const activeState = isActive ? 'reader-tag-category__tag--active' : '';
    return (
        <div
            onClick={() => handleTagSelect(props.tag)}
            className={'reader-tag-category__tag ' + activeState}
            style={{'fontSize': fontSize}}>
            {name}
        </div>
    );
}

export default Tag;
