import React, { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import SearchForm from './SearchForm';
import TitleForm from './TitleForm';
import Preview from './Preview';
import {
    addAlertLabel,
    addInternalAlert,
    checkPossibleToAddAlert,
    editInternalAlert,
    getGeoLocations,
    previewInternalAlert,
} from 'utils/api/alertsAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import './style.scss';
import { FiltersBlock } from './FiltersBlock';

const InternalAlertForm = (props) => {
    let history = useHistory();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError, state } = useContext(AuthContext);

    const { isEditForm, foundAlert, labels, categories, categoriesLoading } = props;

    const [searchValue, setSearchValue] = useState('');
    const [searchValueError, setSearchValueError] = useState(false);
    const [titleValue, setTitleValue] = useState('');
    const [useGoogleAlerts, setUseGoogleAlerts] = useState(false);
    const [inTitlesOnly, setInTitlesOnly] = useState(false);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [tempSelectedAlerts, setTempSelectedAlerts] = useState([]);
    const [preview, setPreview] = useState(null);
    const [titleFormVisible, setTitleFormVisible] = useState(false);
    const [searchSubmitting, setSearchSubmitting] = useState(!!isEditForm);
    const [submitting, setSubmitting] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [creatingLabel, setCreatingLabel] = useState(false);
    const [newLabelToAdd, setNewLabelToAdd] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [activePreviewSortValue, setActivePreviewSortValue] = useState(null);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [activeCountryFilter, setActiveCountryFilter] = useState(null);
    const [activeRegionFilter, setActiveRegionFilter] = useState(null);
    const [activeSentimentFilter, setActiveSentimentFilter] = useState(null);
    const [minRelValue, setMinRelValue] = useState(0);
    const [updatingPreview, setUpdatingPreview] = useState(false);
    const [hideSlider, setHideSlider] = useState(true);
    const [errors, setErrors] = useState(null);
    const [preSubmitError, setPreSubmitError] = useState(null);
    const [loadingSpinnerText, setLoadingSpinnerText] = useState('');
    const [useStopWords, setUseStopWords] = useState(false);
    const [parseByElastic, setParseByElastic] = useState(false);
    const [isHasError, setIsHasError] = useState(false);
    const [isAdvancedSearchShown, setIsAdvancedSearchShown] = useState(false);
    const sentimentList = [
        { id: '-1', name: 'Negative' },
        { id: '1', name: 'Positive' },
    ];

    const getGeoLocationsForFilter = async () => {
        try {
            const geoLocations = await getGeoLocations();
            geoLocations && setCountries(geoLocations);
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        isEditForm && getFoundAlertValues();
        getGeoLocationsForFilter();
    }, []);

    useEffect(() => {
        if (countries?.length && activeCountryFilter) {
            const regions = countries.find((item) => item.countryName === activeCountryFilter)?.regions;
            regions?.length && setRegions(regions);
        }
    }, [activeCountryFilter, countries]);

    useEffect(() => {
        if (!selectedCategory && !searchValue) return;
        if (searchValue === '' && selectedCategory === 'All AI Topics') {
            resetForm();
            return;
        }
        handleCreatePreview();
    }, [
        selectedCategory,
        activePreviewSortValue,
        inTitlesOnly,
        useStopWords,
        activeCountryFilter,
        activeRegionFilter,
        activeSentimentFilter,
        selectedAlerts,
        useGoogleAlerts,
        parseByElastic,
        minRelValue,
    ]);

    useEffect(() => {
        let messageArr = [
            'processing Top sources',
            'processing My sources',
            'processing All sources',
            'removing known marketing sites',
            'removing irrelevant articles',
            'weighting and ranking results',
        ];
        let googleNews = useGoogleAlerts || (null === useGoogleAlerts && foundAlert !== undefined && !foundAlert.source.excludeSourceTypes);
        // if (googleNews) {
        //     messageArr.splice(1, 0, 'Fetching Google News');
        // }
        setLoadingSpinnerText(messageArr[0]);
        let messageIndex = 0;
        const interval = searchSubmitting
            ? setInterval(
                  () => {
                      messageIndex < messageArr.length - 1 && messageIndex++;
                      setLoadingSpinnerText(messageArr[messageIndex]);
                  },
                  googleNews ? 3000 : 1000
              )
            : null;

        return () => clearInterval(interval);
    }, [searchSubmitting]);

    useEffect(() => {
        if (newLabelToAdd) {
            const foundLabel = labels.find((label) => label.id === newLabelToAdd.id);
            foundLabel && addToSelected(foundLabel);
            setNewLabelToAdd(null);
        }
    }, [labels]);

    const getFoundAlertValues = async () => {
        let preSelectedAlerts = foundAlert.source.attachedAlertsList
            ? foundAlert.source.attachedAlertsList.map((item) => {
                  if (item.id === foundAlert.id) {
                      return null;
                  } else {
                      return {
                          id: item.id,
                          name: item.name,
                      };
                  }
              })
            : [];
        let omitGoogle = foundAlert.source.excludeSourceTypes && foundAlert.source.excludeSourceTypes[0] === 'google';
        setSearchValue(
            foundAlert.source.keyword.slice(0, 8) === 'intitle:'
                ? foundAlert.source.keyword.slice(8, foundAlert.source.keyword.length)
                : foundAlert.source.keyword
        );
        setTitleValue(foundAlert.name);
        setSelectedAlerts(preSelectedAlerts);
        setUseGoogleAlerts(!omitGoogle);
        setTitleFormVisible(true);
        setSelectedLabels(foundAlert.labels);
        setActiveCountryFilter(foundAlert?.source?.geoLocation?.country || '');
        setActiveRegionFilter(foundAlert?.source?.geoLocation?.region || '');
        setInTitlesOnly(foundAlert.source.keyword.slice(0, 8) === 'intitle:');
        setUseStopWords(foundAlert.source.useStopWords);
        setParseByElastic(foundAlert.source.parseByElastic);
        setMinRelValue(foundAlert.source.minRelevanceValue);
        foundAlert.source.categories && foundAlert.source.categories.hasOwnProperty('id') && setSelectedCategory(foundAlert.source.categories.id);
        if (foundAlert.source?.sentiment) {
            setActiveSentimentFilter(foundAlert.source.sentiment);
        }
    };

    const round5 = (x) => {
        if (x === 0) {
            return 0;
        }
        let wholeNumber = x * 100;
        return (Math.floor(wholeNumber / 5) * 5) / 100;
    };

    const clearAllFilters = () => {
        setActiveCountryFilter('');
        setActiveRegionFilter('');
        setRegions([]);
        setActiveSentimentFilter('');
        setActivePreviewSortValue('date-new');
    };

    const handleCreatePreview = async (advancedSearchValue = '') => {
        try {
            if (preSubmitError) return;
            errors && setErrors(null);
            if (searchValue === '' && advancedSearchValue === '' && (useGoogleAlerts || inTitlesOnly)) {
                let errObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        keyword: [
                            {
                                message: "A keyword must be included when 'All Sources' is selected below",
                                code: 'INVALID_CHARACTERS_ERROR',
                                payload: null,
                            },
                        ],
                    },
                };
                setErrors(errObj);
                return;
            }
            setSearchSubmitting(true);
            setHideSlider(true);
            const newSearchValue = (inTitlesOnly ? 'intitle:' : '') + (advancedSearchValue || searchValue);
            let sortValue;
            let sortDirection;
            switch (activePreviewSortValue) {
                case 'date-new':
                    sortValue = 'created_at';
                    sortDirection = 'DESC';
                    break;
                case 'date-old':
                    sortValue = 'created_at';
                    sortDirection = 'ASC';
                    break;
                case 'rel-low':
                    sortValue = 'rank';
                    sortDirection = 'ASC';
                    break;
                case 'rel-high':
                    sortValue = 'rank';
                    sortDirection = 'DESC';
                    break;
                default:
                    sortValue = 'created_at';
                    sortDirection = 'DESC';
                    break;
            }
            const params = {
                keyword: newSearchValue,
                alerts: selectedAlerts.map((item) => item.id),
                excludeSourceTypes: useGoogleAlerts && selectedAlerts.length < 1 ? [] : ['google'],
                sortValue,
                sortDirection,
                useStopWords,
                category: selectedCategory === 'All AI Topics' ? null : selectedCategory,
                minRelevanceValue: minRelValue,
                tagSpacy: activeRegionFilter ? activeRegionFilter : activeCountryFilter ? activeCountryFilter : null,
                parseByElastic: parseByElastic,
                sentiment: activeSentimentFilter,
                geoLocation: { country: activeCountryFilter || null, region: activeRegionFilter || null },
            };
            const preview = await previewInternalAlert(params);
            setTitleFormVisible(true);
            setPreview(preview.content);
            setSearchSubmitting(false);
            setUpdatingPreview(false);
            setHideSlider(false);
        } catch (err) {
            setSearchSubmitting(false);
            setUpdatingPreview(false);
            setTitleFormVisible(false);
            setSearchValueError(true);
            setPreview(null);
            handleError(err, setErrors);
        }
    };

    const handleSearchValueChange = (text) => {
        setErrors(null);
        setSearchValue(text);
    };

    const handleUseGoogleAlerts = () => setUseGoogleAlerts(!useGoogleAlerts);

    const resetForm = () => {
        setPreview(null);
        setTitleFormVisible(false);
        setInTitlesOnly(false);
        setUseGoogleAlerts(false);
        setTitleValue('');
        setSearchValue('');
        setSearchValueError(false);
        handleClearAlerts();
        setErrors(null);
        setUseStopWords(false);
    };

    const clearPreviewForm = () => {
        setPreview(null);
        setTitleFormVisible(false);
        setTitleValue('');
        setErrors(null);
    };

    const handleSubmit = async () => {
        if (preSubmitError) return;
        setErrors(null);
        if (searchValue === '' && (useGoogleAlerts || inTitlesOnly)) {
            let errObj = {
                code: 'VALIDATION_FAILED',
                errors: {
                    keyword: [
                        {
                            message: "A keyword must be included when 'All Sources' is selected below",
                            code: 'INVALID_CHARACTERS_ERROR',
                            payload: null,
                        },
                    ],
                },
            };
            setErrors(errObj);
            return;
        }
        const newSearchValue = inTitlesOnly ? 'intitle:' + searchValue : searchValue;
        const params = {
            name: titleValue,
            keyword: newSearchValue,
            useStopWords,
            alerts: selectedAlerts.map((item) => item.id),
            category: selectedCategory === 'All AI Topics' ? null : selectedCategory,
            excludeSourceTypes: useGoogleAlerts && selectedAlerts.length < 1 ? [] : ['google'],
            alertLabels: selectedLabels.map((n) => n.id),
            minRelevanceValue: minRelValue,
            tagSpacy: activeRegionFilter ? activeRegionFilter : activeCountryFilter ? activeCountryFilter : null,
            parseByElastic: parseByElastic,
            geoLocation: { country: activeCountryFilter || null, region: activeRegionFilter || null },
        };
        try {
            setSubmitting(true);
            if (props.isEditForm) {
                await editInternalAlert(props.foundAlert.id, params);
                addFlashMessage('success', 'Alert successfully updated');
                history.push('/account/alerts');
            } else {
                let isPossibleToAdd = await checkPossibleToAddAlert();
                if (isPossibleToAdd) {
                    history.push('/account/alerts');
                    localStorage.setItem('newAlertsData', JSON.stringify({ name: titleValue, keyword: newSearchValue }));
                    await addInternalAlert(params);
                    setSubmitting(false);
                    addFlashMessage('success', 'Alert successfully created');
                    localStorage.setItem('newAlertsData', 'success');
                } else {
                    addFlashMessage(
                        'danger',
                        "You've reached the limit of allowed number of alerts for your" +
                            ' account.\n Contact Customer Care team for more information.'
                    );
                }
            }
        } catch (err) {
            setSubmitting(false);
            localStorage.removeItem('newAlertsData');

            if (err.code === 'VALIDATION_FAILED' && err.message.slice(0, 17) === 'Alert with source') {
                let errObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        keyword: [
                            {
                                message: 'An alert with this keyword already exists.',
                                code: 'IS_BLANK_ERROR',
                                payload: null,
                            },
                        ],
                    },
                };
                handleError(errObj, setErrors);
            } else {
                handleError(err, setErrors);
            }
        }
    };

    const addLabel = async (name) => {
        setCreatingLabel(true);
        try {
            const newLabel = await addAlertLabel({ name });
            setNewLabelToAdd(newLabel.content);
            await props.refreshLabels();
            setCreatingLabel(false);
        } catch (res) {
            if (res.errors.name[0].message) {
                addFlashMessage('danger', res.errors.name[0].message);
            }
            setCreatingLabel(false);
        }
    };

    const addToSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if (newSelectedLabels.indexOf(label) === -1) {
            newSelectedLabels.push(label);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const removeFromSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if (newSelectedLabels.indexOf(label) > -1) {
            newSelectedLabels.splice(newSelectedLabels.indexOf(label), 1);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const handleAlertSelectSubmit = () => {
        setSelectedAlerts([...tempSelectedAlerts]);
    };

    const handleClearAlerts = () => {
        setSelectedAlerts([]);
        setTempSelectedAlerts([]);
    };

    return (
        <div className="mb-4">
            <SearchForm
                searchValue={searchValue}
                setSearchValue={handleSearchValueChange}
                handleCreatePreview={handleCreatePreview}
                useGoogleAlerts={useGoogleAlerts}
                setUseGoogleAlerts={setUseGoogleAlerts}
                searchValueError={searchValueError}
                setSearchValueError={setSearchValueError}
                resetForm={resetForm}
                clearPreviewForm={clearPreviewForm}
                setSelectedAlerts={setTempSelectedAlerts}
                handleAlertSelectSubmit={handleAlertSelectSubmit}
                searchSubmitting={searchSubmitting}
                setSearchSubmitting={setSearchSubmitting}
                selectedAlerts={selectedAlerts}
                handleClearAlerts={handleClearAlerts}
                inTitlesOnly={inTitlesOnly}
                setInTitlesOnly={setInTitlesOnly}
                errorObj={errors}
                handleError={handleError}
                currentId={foundAlert && foundAlert.id}
                setErrors={setErrors}
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categoriesLoading={categoriesLoading}
                preSubmitError={preSubmitError}
                setPreSubmitError={setPreSubmitError}
                useStopWords={useStopWords}
                setUseStopWords={setUseStopWords}
                setIsHasError={setIsHasError}
                isHasError={isHasError}
                isEditForm={isEditForm}
                setIsAdvancedSearchShown={setIsAdvancedSearchShown}
                titleFormVisible={titleFormVisible}>
                <FiltersBlock
                    isAdvancedSearchShown={isAdvancedSearchShown}
                    regions={regions}
                    countries={countries}
                    setRegions={setRegions}
                    activeCountryFilter={activeCountryFilter}
                    activeRegionFilter={activeRegionFilter}
                    activeSentimentFilter={activeSentimentFilter}
                    sentimentList={sentimentList}
                    minRelValue={minRelValue}
                    setActiveCountryFilter={setActiveCountryFilter}
                    setActiveRegionFilter={setActiveRegionFilter}
                    setActiveSentimentFilter={setActiveSentimentFilter}
                    setMinRelValue={setMinRelValue}
                    isHasAccessElasticSearch={state.profile.organization?.accessElasticSearch}
                    clearAllFilters={clearAllFilters}
                    isSimple
                />
            </SearchForm>
            {titleFormVisible && (
                <TitleForm
                    title={titleValue}
                    setTitleValue={setTitleValue}
                    submitting={submitting}
                    isEditForm={props.isEditForm}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    withoutInfoMessage
                    disabled={isHasError}
                    isAdvancedSearchShown={isAdvancedSearchShown}>
                    <FiltersBlock
                        isAdvancedSearchShown={isAdvancedSearchShown}
                        regions={regions}
                        countries={countries}
                        setRegions={setRegions}
                        activeCountryFilter={activeCountryFilter}
                        activeRegionFilter={activeRegionFilter}
                        activeSentimentFilter={activeSentimentFilter}
                        sentimentList={sentimentList}
                        minRelValue={minRelValue}
                        setActiveCountryFilter={setActiveCountryFilter}
                        setActiveRegionFilter={setActiveRegionFilter}
                        setActiveSentimentFilter={setActiveSentimentFilter}
                        setMinRelValue={setMinRelValue}
                        isHasAccessElasticSearch={state.profile.organization?.accessElasticSearch}
                        clearAllFilters={clearAllFilters}
                    />
                </TitleForm>
            )}

            {searchSubmitting ? (
                <div className="mt-5">
                    <LoadingSpinner text={loadingSpinnerText} />
                </div>
            ) : preview ? (
                <Preview preview={preview} updatingPreview={updatingPreview} />
            ) : null}
        </div>
    );
};

export default InternalAlertForm;
