import React, {useState, useContext, useEffect} from 'react';

import MobileReaderNavbar from './MobileReaderNavbar';
import AlertList from './AlertList';
import ArticleList from './ArticleList';
import SelectCategory from './Filters/SelectCategory';
import SelectTag from './Filters/SelectTag';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import {getUserAlertsById} from 'utils/api/usersAPI';
import {getDigDeeperContent, getFreeDigDeeperContent, getFreeReaderAlerts} from 'utils/api/alertsAPI';
import {isEqual} from 'lodash';
import './style.scss';
import LoadingSpinner from 'components/LoadingSpinner';
import ManageAlerts from './ManageAlerts';

const MobileReader = (props) => {
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {state, handleError} = useContext(AuthContext);

    const {id, search, companyReader} = props;
    const [activeView, setActiveView] = useState('alerts');
    const [alerts, setAlerts] = useState([]);
    const [articles, setArticles] = useState([]);
    const [tagData, setTagData] = useState([]);
    const [fetchingAlerts, setFetchingAlerts] = useState(true);
    const [fetchingAlertContent, setFetchingAlertContent] = useState(false);
    const [alertContent, setAlertContent] = useState(null);
    const [allowManageAlertsInReader, setAllowManageAlertsInReader] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [publicEmail, setPublicEmail] = useState('');

    useEffect(() => {
        if(!props.public) {
            state.profile &&
            state.profile.organization &&
            setAllowManageAlertsInReader(state.profile.organization.myReaderAuth)
        }
    }, [state]);

    const pushEmptyAlertsToEnd = (arr) => {
        let newArr = arr;
        newArr.map(item => {
            if(item.countNews === 0) {
                let index = newArr.indexOf(item);
                newArr.splice(index, 1);
                newArr.push(item);
            }
        })
        return newArr;
    }

    const handleActiveView = () => {
        switch(activeView) {
            case 'alerts':
                return (
                    <AlertList
                        alerts={alerts}
                        selectedAlert={selectedAlert}
                        handleAlertSelect={handleAlertSelect}
                    />
                );
            case 'articles':
                return (
                    <ArticleList
                        articles={articles}
                        selectedArticle={selectedArticle}
                        fetchingArticles={fetchingAlertContent}
                        selectedAlert={selectedAlert}
                        setActiveView={setActiveView}
                        selectedTag={selectedTag}
                    />
                );
            case 'filters-select-category':
                return (
                    <SelectCategory
                        articles={articles}
                        categories={alertContent.tags}
                        selectedAlert={selectedAlert}
                        selectedTag={selectedTag}
                        setActiveView={setActiveView}
                        handleCategorySelect={handleCategorySelect}
                    />
                );
            case 'filters-select-tag':
                return (
                    <SelectTag
                        articles={articles}
                        handleTagSelect={handleTagSelect}
                        selectedAlert={selectedAlert}
                        selectedTag={selectedTag}
                        setActiveView={setActiveView}
                        selectedCategory={selectedCategory}
                    />
                );
            case 'manageAlerts':
                return (
                    <ManageAlerts
                        setActiveView={setActiveView}
                        addFlashMessage={addFlashMessage}
                        assignedAlerts={alerts}
                        isPublic={props.public}
                        state={state}
                        handleError={handleError}
                        userId={props.id}
                        refreshAlerts={fetchAlerts}
                        publicEmail={publicEmail}
                    />
                );
            default:
                return (
                    <AlertList
                        alerts={alerts}
                        selectedAlert={selectedAlert}
                        setActiveView={setActiveView}
                    />
                );
        }
    };

    useEffect(() => {
        fetchAlerts();
        window.addToHomescreen();
    }, []);

    const fetchAlerts = async() => {
        try {
            let userData;
            if(props.public) {
                if(companyReader) {
                    userData = await getFreeReaderAlerts(state.readerInfo.id, state.readerInfo.hash, 10);
                } else {
                    const hash = search.slice(
                        3,
                        search.length
                    );
                    userData = await getFreeReaderAlerts(id, hash, 10);
                }
                setAllowManageAlertsInReader(userData.myReaderAuth);
                setPublicEmail(userData.email);
                let newArr = pushEmptyAlertsToEnd(userData.alerts)
                setAlerts(newArr);
                newArr.length && setSelectedAlert(newArr[0]);
            } else {
                userData = await getUserAlertsById(id);
                let newArr = pushEmptyAlertsToEnd(userData.result)
                setAlerts(newArr);
                newArr.length > 0 && setSelectedAlert(newArr[0]);
            }
            setFetchingAlerts(false);
        } catch(err) {
            setFetchingAlerts(false);
            handleError(err);
        }
    };

    const fetchAlertContent = async(alertId, alertHash) => {
        try {
            setFetchingAlertContent(true);
            let alertContent;
            if(props.public) {
                alertContent = await getFreeDigDeeperContent(alertId, alertHash, 10);
            } else {
                alertContent = await getDigDeeperContent(alertId, 10);
            }
            setAlertContent(alertContent);
            setArticles(alertContent.news);
            setSelectedTag(null);
            setSelectedArticle(alertContent.news[0]);
            setTagData(alertContent.tags);
            setFetchingAlertContent(false);
        } catch(err) {
            setFetchingAlertContent(false);
            handleError(err);
        }
    };

    const handleAlertSelect = (alert) => {
        if(alert.id !== selectedAlert.id) {
            setSelectedAlert(alert);
        } else {
            setActiveView('articles');
        }
    };

    useEffect(() => {
        selectedAlert.id && fetchAlertContent(selectedAlert.id, selectedAlert.hash);
    }, [selectedAlert]);

    const handleCategorySelect = (category) => {
        if(selectedCategory && (category.type === selectedCategory.type)) {
            setActiveView('filters-select-tag');
        } else {
            setSelectedCategory(category);
        }
    };

    useEffect(() => {
        selectedCategory && setActiveView('filters-select-tag');
    }, [selectedCategory]);

    const handleTagSelect = (tag) => {
        if(isEqual(selectedTag, tag)) {
            setSelectedTag(null);
        } else {
            setSelectedTag(tag);
        }
    };

    const applyTagFilter = (articleArray) => {
        let filteredArray = [...articleArray];
        if(selectedTag) {
            filteredArray = filteredArray.filter(article => {
                return selectedTag.news.includes(String(article.id));
            });
        }
        setArticles(filteredArray);
    };

    useEffect(() => {
        if(!alertContent) return;
        if(selectedTag !== null) {
            applyTagFilter(alertContent.news);
            setActiveView('articles');
        } else {
            setArticles(alertContent.news);
        }
    }, [selectedTag]);


    if(fetchingAlerts) return <LoadingSpinner padding text={'Fetching reader data'}/>;
    return (
        <div className="mobile-reader">
            {handleActiveView()}
            <MobileReaderNavbar
                profile={state.profile}
                activeView={activeView}
                setActiveView={setActiveView}
                navItems={[
                    {name: 'Alerts', icon: 'fa-bell', value: 'alerts'},
                    {name: 'Articles', icon: 'fa-book', value: 'articles'},
                    ...(allowManageAlertsInReader ? [{
                        name: 'Manage',
                        icon: 'fa-list',
                        value: 'manageAlerts',
                    }] : []),
                ]}
            />
        </div>
    );
};

export default MobileReader;
