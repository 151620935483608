import React, {useState, useEffect} from 'react';

import {InputGroup, Input, Col, Row, Label, FormGroup} from 'reactstrap';

import WikiAutocomplete from 'components/WikiAutocomplete';
import InfoBlock from 'components/AlertRulesModal/Info';
import LoadingSpinnerSmall from 'components/LoadingSpinner/LoadingSpinnerSmall';
import langList from './langs.json';
import countryList from './countries.json';

const SearchForm = (props) => {
    const {
        searchValue, setSearchValue, errorObj,
        selectedCountryLangGroup, setSelectedCountryLangGroup, handleError,
        searchSubmitting, clearPreviewForm, resetForm, combinedCountries,
        handleUseGoogleAlerts, handleCreatePreview, inTitlesOnly, setInTitlesOnly, inTextOnly, setInTextOnly,
    } = props;

    const handleAutocompleteSelect = (term) => {
        setSearchValue(term);
    };

    const handleSearchValue = (text) => {
        setSearchValue(text);
    };

    const onPressEnter = (e) => {
        e.preventDefault();
        handleCreatePreview();
    };

    const handleDropdownChange = (e) => {
        let value = e.target.value;
        setSelectedCountryLangGroup(value);
    };

    const handleCheckboxSelect = (type) => {
        if(type === 'text') {
            if(inTextOnly) {
                setInTextOnly(false);
            } else {
                setInTextOnly(true);
                setInTitlesOnly(false);
            }
        } else {
            if(inTitlesOnly) {
                setInTitlesOnly(false);
            } else {
                setInTitlesOnly(true);
                setInTextOnly(false);
            }
        }
    };

    return (
        <Row>
            <Col xs={12} md={12}>
                <div className="google-search-form">
                    <InputGroup className="google-search-form__keyword">
                        <WikiAutocomplete
                            handleSearchValue={handleSearchValue}
                            onPressEnter={onPressEnter}
                            onSelectAndEnter={handleCreatePreview}
                            handleAutocompleteSelect={handleAutocompleteSelect}
                            onSubmit={handleCreatePreview}
                            placeholder="Search for anything"
                            value={searchValue}
                            submitting={searchSubmitting}
                            error={errorObj}
                            handleError={handleError}
                            onClear={resetForm}
                        />
                    </InputGroup>
                </div>
                {errorObj && errorObj.errors.hasOwnProperty('keyword') &&
                <div className="mt-1 error-text">{errorObj.errors['keyword'][0].message || errorObj.errors['keyword']}</div>
                }
            </Col>
            <Col xs={12}>
                <div className="alert-form-utility">
                    <div className="alert-form-utility__inner">
                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="inTitlesOnly"
                                    checked={inTitlesOnly}
                                    onChange={() => handleCheckboxSelect('title')}
                                />{' '}
                                In titles only
                            </Label>
                        </FormGroup>
                        <FormGroup check inline>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    name="inTextOnly"
                                    checked={inTextOnly}
                                    onChange={() => handleCheckboxSelect('text')}
                                />{' '}
                                In text only
                            </Label>
                        </FormGroup>
                        <div className="input-group-btn dropdown-util">
                            <Input type="select" name="country"
                                   value={selectedCountryLangGroup}
                                   onChange={handleDropdownChange}
                            >
                                <option disabled>Select Language and Country</option>
                                {combinedCountries.map(group => (
                                    <option key={group.displayName}
                                            value={group.displayName}>{group.displayName}</option>
                                ))}
                            </Input>
                        </div>
                    </div>
                    <InfoBlock/>
                </div>
            </Col>
        </Row>
    );
};

export default SearchForm;
