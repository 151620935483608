import React, { useState, useEffect, useContext } from 'react';

import { InputGroup, Input, Col, Row, Label, FormGroup, UncontrolledTooltip, Button } from 'reactstrap';
import TextareaAutocomplete from 'components/WikiAutocomplete/TextareaAutocomplete';

import InfoBlock from 'components/AlertRulesModal/Info';
import SelectAlertsModal from './Modals/SelectAlerts';
import CharacterCount from 'components/CharacterCount';
import { AuthContext } from 'contexts/AuthContext';
import { RadioInput } from 'components/common/Input';
import Toggle from 'react-toggle';

const SearchForm = (props) => {
    const { state } = useContext(AuthContext);
    const {
        searchValue,
        setSearchValue,
        searchValueError,
        setSearchValueError,
        selectedAlerts,
        clearPreviewForm,
        resetForm,
        useGoogleAlerts,
        setUseGoogleAlerts,
        handleError,
        setErrors,
        inTitlesOnly,
        setInTitlesOnly,
        useStopWords,
        setUseStopWords,
        currentId,
        categories,
        selectedCategory,
        setSelectedCategory,
        setSelectedAlerts,
        handleCreatePreview,
        handleAlertSelectSubmit,
        handleClearAlerts,
        errorObj,
        categoriesLoading,
        preSubmitError,
        setPreSubmitError,
        isHasError,
        setIsHasError,
        isEditForm,
        setIsAdvancedSearchShown,
        titleFormVisible,
        children,
    } = props;

    const [selectAlertsModalOpen, setSelectAlertsModalOpen] = useState(false);
    const [selectedCategoryNameValue, setSelectedCategoryNameValue] = useState('All AI Topics');
    const [dropdownWidth, setDropdownWidth] = useState(152);

    const [isShowCategories, setIsShowCategories] = useState(false);
    const [isAdvancedSearchActive, setIsAdvancedSearchActive] = useState(false);
    const [isShowSecondGroup, setIsShowSecondGroup] = useState(false);
    const [isAnyFirstGroup, setIsAnyFirstGroup] = useState(false);
    const [isAnySecondGroup, setIsAnySecondGroup] = useState(false);
    const [advancedSearch, setAdvancedSearch] = useState({ first: [], second: [], exclude: [] });
    const [search, setSearch] = useState(searchValue);
    const [errorMessage, setErrorMessage] = useState('');

    const firstGroupValue = advancedSearch.first?.map((item) => item)?.join(' ') || '';
    const secondGroupValue = advancedSearch.second?.map((item) => item)?.join(' ') || '';
    const excludeValue = advancedSearch.exclude?.map((item) => item)?.join(' ') || '';
    const isHasAccessAdvancedSearch = state?.profile?.organization?.accessAdvancedSearch;

    useEffect(() => {
        if (state.profile.organization?.accessCategoryInInternalSearch) {
            setIsShowCategories(true);
        }
    }, [state.profile]);

    const toggleSelectAlertsModal = () => {
        setSelectAlertsModalOpen(!selectAlertsModalOpen);
    };

    const handleAutocompleteSelect = (term) => {
        setSearchValue(term);
    };

    const handleInternalSearchValue = (text) => {
        setSearchValue(text);
    };

    const onPressEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleCreatePreview();
        }
    };

    const getTextWidth = (text, font) => {
        let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
        let context = canvas.getContext('2d');
        context.font = font;
        let metrics = context.measureText(text);
        return metrics.width;
    };

    useEffect(() => {
        if (categories) {
            let foundSelectedCategory;
            if (!selectedCategory || selectedCategory === 'All AI Topics') {
                setSelectedCategoryNameValue('All AI Topics');
                setDropdownWidth(155);
            } else {
                let allCategories = [];
                categories.forEach((categoryType) => {
                    allCategories.push(...categoryType.categories);
                });
                foundSelectedCategory = allCategories.find((category) => String(category.id) === String(selectedCategory));
                if (!foundSelectedCategory) return;
                setSelectedCategoryNameValue(foundSelectedCategory.name);
                if (window.innerWidth > 450) {
                    setDropdownWidth(getTextWidth('General -  ' + foundSelectedCategory.name, 'normal 14pt sans-serif'));
                }
            }
        }
    }, [categories, selectedCategory]);

    useEffect(() => {
        if (isAdvancedSearchActive) {
            const { first, second, exclude } = advancedSearch;

            const firstStr = first.join(' ');
            const secondStr = second.join(' ');
            const firstGroup =
                `${stringSeparator(firstStr, firstStr)
                    ?.filter((item) => item)
                    ?.join(` ${isAnyFirstGroup ? 'OR ' : 'AND '}`)}` || '';
            const secondGroup =
                `${stringSeparator(secondStr, secondStr)
                    ?.filter((item) => item)
                    ?.join(` ${isAnySecondGroup ? 'OR ' : 'AND '} `)}` || '';
            const excludeGroup = `${
                exclude?.length ? '-' + exclude?.map((item) => (item.charAt(0) === '-' ? item.slice(1) : item))?.join(' -') : ''
            }`;

            if (firstGroup && secondGroup && isShowSecondGroup) {
                return setSearch(
                    `${first?.length > 1 ? `(${firstGroup})` : firstGroup} AND ${
                        second?.length > 1 ? `(${secondGroup})` : secondGroup
                    } ${excludeGroup}`
                );
            } else {
                return setSearch((firstGroup || secondGroup) + ' ' + excludeGroup);
            }
        }
    }, [advancedSearch, isAnyFirstGroup, isAnySecondGroup, isShowSecondGroup, isAdvancedSearchActive]);

    useEffect(() => {
        if (searchValue) {
            const regexForGroup = /\(([^)]+)\)/g;
            const matchesGroup = searchValue.match(regexForGroup);

            if (matchesGroup?.length) {
                if (matchesGroup?.length > 2) {
                    setErrorMessage('The group limit has been exceeded. The maximum number of groups should not exceed two');
                    setIsHasError(true);
                } else {
                    setErrorMessage('');
                    setIsHasError(false);
                }
            } else {
                setErrorMessage('');
                setIsHasError(false);
            }
        }
    }, [searchValue]);

    const findKeywords = () => {
        const regexForGroup = /\(([^)]+)\)/g;
        const matchesGroup = searchValue.match(regexForGroup);
        const excludeWordsRegex = /-(?:\w+|"[^"]*")/g;
        const matchesExcludeWords = searchValue.match(excludeWordsRegex);
        const operatorRegex = /\b(AND|OR)\b/g;
        const duplicateSpacesRegex = /\s+/g;
        let firstGroup = '';
        let secondGroup = '';
        const excludeWords = matchesExcludeWords?.map((item) => item.substring(1));

        if (matchesGroup?.length) {
            firstGroup = matchesGroup[0];
            secondGroup = matchesGroup?.length > 1 ? matchesGroup[1] : '';
        }

        const wordsOutsideGroups = searchValue
            .replace(regexForGroup, '')
            .replace(excludeWordsRegex, '')
            .replace(operatorRegex, '')
            .replace(duplicateSpacesRegex, ' ')
            .trim()
            .split(' ');

        const { groupItems: firstGroupItems, isAny: isAnyOperatorInFirstGroup } = getItemsForGroup(firstGroup);
        const { groupItems: secondGroupItems, isAny: isAnyOperatorInSecondGroup } = getItemsForGroup(secondGroup);

        const isHasWordsOutsideGroup = wordsOutsideGroups?.length && !!wordsOutsideGroups[0];
        let isNeedReplaceGroup = false;

        if (firstGroupItems?.length && isHasWordsOutsideGroup) {
            let firstMatchIndex = searchValue.length;

            firstGroupItems.forEach((word) => {
                const index = searchValue.indexOf(word);
                if (index !== -1 && index < firstMatchIndex) {
                    firstMatchIndex = index;
                }
            });

            wordsOutsideGroups.forEach((word) => {
                const index = searchValue.indexOf(word);
                if (index !== -1 && index < firstMatchIndex) {
                    firstMatchIndex = index;
                }
            });

            if (firstMatchIndex < searchValue.length) {
                isNeedReplaceGroup = !(
                    firstMatchIndex === searchValue.indexOf(firstGroupItems[0]) || firstMatchIndex === searchValue.indexOf(firstGroupItems[1])
                );
            }
        }

        if (!firstGroupItems?.length && isHasWordsOutsideGroup) {
            setIsAnyFirstGroup(
                searchValue
                    .replace(regexForGroup, '')
                    .replace(excludeWordsRegex, '')
                    ?.split(' ')
                    ?.find((item) => item === 'OR') === 'OR'
            );
        }

        firstGroupItems?.length && !isNeedReplaceGroup && setIsAnyFirstGroup(isAnyOperatorInFirstGroup);
        isNeedReplaceGroup
            ? setIsAnySecondGroup(isAnyOperatorInFirstGroup)
            : secondGroupItems?.length && setIsAnySecondGroup(isAnyOperatorInSecondGroup);
        setIsShowSecondGroup(!!((firstGroupItems?.length && isHasWordsOutsideGroup) || secondGroupItems?.length));

        setAdvancedSearch({
            first: firstGroupItems?.length ? (isNeedReplaceGroup ? wordsOutsideGroups : firstGroupItems) : wordsOutsideGroups || [],
            second:
                wordsOutsideGroups && firstGroupItems?.length && !secondGroupItems?.length
                    ? isNeedReplaceGroup
                        ? firstGroupItems
                        : wordsOutsideGroups
                    : secondGroupItems,
            exclude: excludeWords,
        });

        setIsHasError(false);
    };

    const getItemsForGroup = (arr) => {
        if (!arr?.length) return { groupItems: [], isAny: false };

        const wordsInGroup = arr.match(/\w+|"[^"]*"/g) || [];
        const groupItems = wordsInGroup.filter((item) => item !== 'AND' && item !== 'OR');
        const isAny = wordsInGroup.find((word) => word === 'OR');

        return { groupItems, isAny };
    };

    const stringSeparator = (str, prevValue) => {
        const isDeletedSymbol = prevValue?.length > str?.length;
        const spaceEndStr = /\s$/.test(str);
        const repeatSpaces = /\s\s+$/.test(str);

        if (repeatSpaces && spaceEndStr && !isDeletedSymbol) return [];
        if (isDeletedSymbol && str?.length === 0) return 0;

        const regExp = /("[^"]*("|$)|\S+)/g;
        const result = [];
        let match;

        while ((match = regExp.exec(str)) !== null) {
            result.push(match[0]?.replace(/(\([^)]*\))|(\s{2,})/g, (match, group1) => group1 || ' '));
        }

        spaceEndStr && !isDeletedSymbol && result.push('');

        return result;
    };

    const handleChangeAdvancedSearch = (type, str, prevValue) => {
        const value = stringSeparator(str, prevValue);
        value === 0 && setAdvancedSearch({ ...advancedSearch, [type]: [] });
        value?.length && setAdvancedSearch({ ...advancedSearch, [type]: value });
    };

    const handleSubmitAdvancedSearch = () => {
        setSearchValue(search.trim());
        console.log('setSearch');
        handleCreatePreview(search.trim());
    };

    const handleToggleAdvancedSearch = () => {
        if (!isAdvancedSearchActive) {
            errorMessage &&
                setErrorMessage(
                    'The group limit has been exceeded. The maximum number of groups should not exceed two. The extra groups were omitted'
                );
            findKeywords();
        } else {
            errorMessage && setErrorMessage('The group limit has been exceeded. The maximum number of groups should not exceed two');
            setSearchValue(search.trim());
        }
        setIsAdvancedSearchActive(!isAdvancedSearchActive);
        setIsAdvancedSearchShown(!isAdvancedSearchActive);
    };

    return (
        <Row>
            {/*<Col xs={12}>
                <QueryBuilder queryString={searchValue}/>
            </Col>*/}

            <Col xs={8} md={8}>
                {isAdvancedSearchActive && (
                    <div style={{ marginBottom: '10px' }}>
                        Search value: <b>{search}</b>
                    </div>
                )}
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                <div className="internal-search-form">
                    {isAdvancedSearchActive ? (
                        <div style={{ width: '100%' }}>
                            <InputGroup className="d-flex">
                                <Input
                                    required
                                    value={firstGroupValue}
                                    onChange={(e) => handleChangeAdvancedSearch('first', e?.target?.value, firstGroupValue)}
                                    placeholder="FIRST GROUP*"
                                />
                                <div className="d-flex flex-column ml-3" style={{ width: '125px' }}>
                                    <RadioInput onChange={() => setIsAnyFirstGroup(true)} checked={isAnyFirstGroup} name="anyFirst" text="Any of" />
                                    <RadioInput onChange={() => setIsAnyFirstGroup(false)} checked={!isAnyFirstGroup} name="allFirst" text="All of" />
                                </div>
                            </InputGroup>
                            <Button color="light" style={{ cursor: 'pointer' }} onClick={() => setIsShowSecondGroup(!isShowSecondGroup)}>
                                {isShowSecondGroup ? 'Remove' : 'Add'} Second Group{' '}
                                {isShowSecondGroup ? <i className="fa fa-minus ml-1" /> : <i className="fa fa-plus ml-1" />}
                            </Button>
                            {isShowSecondGroup && (
                                <InputGroup className="d-flex">
                                    <Input
                                        value={secondGroupValue}
                                        onChange={(e) => handleChangeAdvancedSearch('second', e?.target?.value, secondGroupValue)}
                                        placeholder="SECOND GROUP"
                                    />
                                    <div className="d-flex flex-column ml-3" style={{ width: '125px' }}>
                                        <RadioInput
                                            onChange={() => setIsAnySecondGroup(true)}
                                            checked={isAnySecondGroup}
                                            name="anySecond"
                                            text="Limited by Any"
                                        />
                                        <RadioInput
                                            onChange={() => setIsAnySecondGroup(false)}
                                            checked={!isAnySecondGroup}
                                            name="allSecond"
                                            text="Limited by All"
                                        />
                                    </div>
                                </InputGroup>
                            )}
                            <InputGroup className="d-flex mt-1">
                                <Input
                                    value={excludeValue}
                                    onChange={(e) => handleChangeAdvancedSearch('exclude', e?.target?.value, excludeValue)}
                                    placeholder="Remove these keywords and phrases"
                                />
                                <div className="ml-3" style={{ width: '125px' }} />
                            </InputGroup>
                            {children}
                        </div>
                    ) : (
                        <InputGroup className="internal-search-form__keyword">
                            <TextareaAutocomplete
                                handleInternalSearchValue={handleInternalSearchValue}
                                onPressEnter={onPressEnter}
                                value={searchValue}
                                searchValueHasError={searchValueError}
                                setSearchValueHasError={setSearchValueError}
                                onSubmit={handleCreatePreview}
                                onClear={resetForm}
                                setErrors={setErrors}
                                handleAutocompleteSelect={handleAutocompleteSelect}
                                error={errorObj && errorObj.errors.hasOwnProperty('keyword')}
                                preSubmitError={preSubmitError}
                                setPreSubmitError={setPreSubmitError}
                            />
                            <div className="mt-1" style={{ textAlign: 'right' }}>
                                <CharacterCount value={searchValue} limit={2048} />
                            </div>
                        </InputGroup>
                    )}
                    {isShowCategories ? (
                        <FormGroup
                            className="internal-search-form__category"
                            style={{ flex: `0 0 ${dropdownWidth + 8 > 155 ? dropdownWidth + 8 : 155}px` }}>
                            {categoriesLoading ? (
                                <Input type="select">
                                    <option disabled>Fetching categories...</option>
                                </Input>
                            ) : (
                                <Input
                                    type="select"
                                    name="categorySelect"
                                    id="categorySelect"
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                    value={String(selectedCategory)}>
                                    <>
                                        <option>All AI Topics</option>
                                        {categories?.map((item) => (
                                            <optgroup key={item.type} label={item.type}>
                                                {item.categories.map((category) => (
                                                    <option key={category.id} value={String(category.id)}>
                                                        {category.name} - {item.type}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </>
                                </Input>
                            )}
                        </FormGroup>
                    ) : (
                        ''
                    )}
                </div>
                {preSubmitError && preSubmitError.errors.hasOwnProperty('keyword') && (
                    <div className="mt-1 error-text">{preSubmitError.errors['keyword'][0].message || preSubmitError.errors['keyword']}</div>
                )}
                {errorObj && errorObj.errors.hasOwnProperty('keyword') && (
                    <div className="mt-1 error-text">{errorObj.errors['keyword'][0].message || errorObj.errors['keyword']}</div>
                )}
            </Col>
            <Col xs={2} md={2} className="pl-0 mt-2">
                <InfoBlock />
            </Col>
            {!titleFormVisible && !isAdvancedSearchActive && <div className="ml-3">{children}</div>}
            <Col xs={12}>
                <div className="alert-form-utility">
                    <div className="alert-form-utility__inner">
                        <FormGroup check inline>
                            <Label check>
                                <Input type="checkbox" name="inTitlesOnly" checked={inTitlesOnly} onChange={() => setInTitlesOnly(!inTitlesOnly)} />
                                Headlines
                            </Label>
                        </FormGroup>
                        {selectedAlerts.length < 1 && (
                            <FormGroup check inline>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        name="useGoogleAlerts"
                                        checked={useGoogleAlerts}
                                        onChange={() => setUseGoogleAlerts(!useGoogleAlerts)}
                                    />
                                    All Sources
                                </Label>
                            </FormGroup>
                        )}
                        <FormGroup id="stock-news" check inline>
                            <Label check>
                                <Input type="checkbox" name="useStopWords" checked={useStopWords} onChange={() => setUseStopWords(!useStopWords)} />
                                -stock news
                            </Label>
                        </FormGroup>
                        <UncontrolledTooltip placement="bottom" target="stock-news" delay={{ show: 300, hide: 0 }}></UncontrolledTooltip>

                        <div className="form-group mb-0">
                            {selectedAlerts.length > 0 ? (
                                <div className="alert-form-utility__select-alerts">
                                    <div className="alert-name-span" onClick={toggleSelectAlertsModal}>
                                        {selectedAlerts.length} {selectedAlerts.length > 1 ? 'Alerts' : 'Alert'}
                                    </div>
                                    <div className="closebtn" onClick={() => handleClearAlerts()}>
                                        <span>×</span>
                                    </div>
                                </div>
                            ) : (
                                <div className="alert-form-utility__select-alerts" onClick={toggleSelectAlertsModal}>
                                    <span>
                                        Select Sources <i className="fa fa-caret-down" style={{ marginLeft: '.5rem' }} />
                                    </span>
                                </div>
                            )}
                        </div>
                        {!!isHasAccessAdvancedSearch && (
                            <label style={{ display: 'flex' }}>
                                <Toggle checked={isAdvancedSearchActive} icons={false} onChange={handleToggleAdvancedSearch} />
                                <span style={{ marginLeft: '10px' }}>Advanced Search</span>
                            </label>
                        )}
                        {/*{state.profile.organization?.accessElasticSearch &&
                            <FormGroup check inline>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        name="parseByElastic"
                                        checked={parseByElastic}
                                        onChange={() => setParseByElastic(!parseByElastic)}
                                    />{' '}
                                    Use Es
                                </Label>
                            </FormGroup>
                        }*/}
                    </div>
                </div>
                {isAdvancedSearchActive && !isEditForm && (
                    <Button disabled={isHasError} style={{ marginTop: '15px' }} onClick={handleSubmitAdvancedSearch} color="primary" className="mr-2">
                        Search
                    </Button>
                )}
            </Col>
            <SelectAlertsModal
                isOpen={selectAlertsModalOpen}
                toggle={toggleSelectAlertsModal}
                selectedList={selectedAlerts}
                setSelectedAlerts={setSelectedAlerts}
                clearPreviewForm={clearPreviewForm}
                handleAlertSelectSubmit={handleAlertSelectSubmit}
                handleError={handleError}
                currentId={currentId}
            />
        </Row>
    );
};

export default SearchForm;
