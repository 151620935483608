import React, {useState, useEffect, useContext} from 'react';

import {Row, Col} from 'reactstrap';
import Toggle from 'react-toggle';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';

import {getUserAlertSorting, editUserAlertSorting} from 'utils/api/usersAPI';
import SortableAlert from './SortableAlert';
import {useHistory, useParams} from 'react-router-dom';
import './style.scss';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import LoadingSpinner from 'components/LoadingSpinner';
import SpinnerButton from 'components/SpinnerButton';

const SortAlerts = () => {
    let {id} = useParams();
    let history = useHistory();

    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError} = useContext(AuthContext);

    const [assignedAlerts, setAssignedAlerts] = useState([]);
    const [userNotFound, setUserNotFound] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [useCustomSorting, setUseCustomSorting] = useState(false);
    const [currentAlertOrder, setCurrentAlertOrder] = useState([]);
    const [customAlertOrder, setCustomAlertOrder] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [user, setUser] = useState(null);

    const fetchUserSorting = async() => {
        try {
            const sortData = await getUserAlertSorting(id);
            sortData.length > 0 && setUseCustomSorting(true);
            setUser(sortData.profile);
            setAssignedAlerts(sortData.alerts);
            setUseCustomSorting(sortData.profile.customAlertSorting);
            setFetchingData(false);
        } catch(err) {
            setFetchingData(false);
            handleError(err);
        }
    };

    useEffect(() => {
        fetchUserSorting();
    }, []);

    const onDragEnd = result => {
        const {destination, source, draggableId} = result;
        if(!destination) {
            return;
        }
        if(destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const newAlertIds = Array.from(currentAlertOrder);
        newAlertIds.splice(source.index, 1);
        newAlertIds.splice(destination.index, 0, draggableId);

        const newAlertList = Array.from(assignedAlerts);

        const selectedAlert = assignedAlerts.find(
            alert => alert.id == draggableId,
        );
        newAlertList.splice(newAlertList.indexOf(selectedAlert), 1);
        newAlertList.splice(destination.index, 0, selectedAlert);
        setAssignedAlerts(newAlertList);
    };

    const toggleCustomSorting = () => {
        setUseCustomSorting(!useCustomSorting);
    };

    const onSubmit = async() => {
        try {
            setSubmitting(true);
            if(useCustomSorting) {
                await editUserAlertSorting(id, {ids: assignedAlerts.map(item => item.id)});
            } else {
                await editUserAlertSorting(id, {ids: []});
            }
            setSubmitting(false);
            addFlashMessage('success', 'Sort order successfully updated');
            history.push('/account/users');
        } catch(err) {
            setSubmitting(false);
            handleError(err);
        }
    };


    if(userNotFound) {
        return (
            <div className="animated fadeIn view sort-alerts">
                <Row className="mt-3">
                    <Col xs="12">
                        <div className="sort-alerts__container">
                            <h1>Sort Alerts</h1>
                            <div className="mt-4 mb-3">
                                <div className="error-block d-flex" style={{maxWidth: '600px'}}>
                                    <i className="fa fa-exclamation-circle"/>
                                    <div>User not found.</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
    return (
        <div className="animated fadeIn view sort-alerts">
            <Row className="mt-3">
                <Col xs="12">
                    <div className="sort-alerts__container">
                        <h1>Sort Alerts</h1>
                        {fetchingData ?
                            <div>
                                <LoadingSpinner text={'Fetching sort data'}/>
                            </div>
                            :
                            <div className="sort-alerts__inner">
                                <h4 style={{color: 'gray'}}>{user && user.name}</h4>
                                <div>
                                    <div className="sort-alerts__toggle toggle">
                                        <label className="toggle-label">
                                            <Toggle
                                                defaultChecked={useCustomSorting}
                                                icons={false}
                                                disabled={assignedAlerts.length < 1}
                                                onChange={toggleCustomSorting}
                                            />
                                        </label>
                                        <span className="toggle-text">Use Custom Sorting</span>
                                    </div>
                                    {assignedAlerts.length < 1 ?
                                        <div>Alerts that you assign will appear here.</div>
                                        :
                                        <div>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <div
                                                    className={'sort-alerts__list ' + (!useCustomSorting ? 'sort-alerts__list--disabled' : '')}>
                                                    <div className="sort-alerts__list-index">
                                                        {assignedAlerts.map((item, i) => (
                                                            <div className="sort-alert-list-index-num">{i + 1}</div>
                                                        ))}
                                                    </div>
                                                    <Droppable droppableId={'column-1'}>
                                                        {(provided) => (
                                                            <div className="sort-alert-list-inner"
                                                                 ref={provided.innerRef}
                                                                 {...provided.droppableProps}>

                                                                {assignedAlerts.length > 0 &&
                                                                assignedAlerts.map((alert, i) => (
                                                                    <div style={{flex: '1'}}>
                                                                        <SortableAlert alert={alert} key={alert.id}
                                                                                       index={i}/>
                                                                    </div>
                                                                ))
                                                                }
                                                                {provided.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            </DragDropContext>
                                            <div className="add-user mt-3 mb-4 d-flex justify-content-end"
                                                 style={{borderTop: 'none'}}>
                                                <SpinnerButton
                                                    color="primary"
                                                    onClick={onSubmit}
                                                    title="Save Changes"
                                                    submitting={submitting}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default SortAlerts;


