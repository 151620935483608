import React, {useEffect, useState} from 'react';
import {Button, Label} from 'reactstrap';
import InputWithError from 'components/InputWithError';
import SpinnerButton from 'components/SpinnerButton';
import SpinnerButtonSmall from 'components/SpinnerButton/Small';
import {deleteCourtlinkToken, editCourtlinkToken} from 'utils/api/profileAPI';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Token = (props) => {
    const {tokenPair, addFlashMessage, handleError, refreshProfile} = props;

    const [editMode, setEditMode] = useState(false);
    const [clientName, setClientName] = useState('');
    const [tempClientName, setTempClientName] = useState('');
    const [clientId, setClientId] = useState('');
    const [tempClientId, setTempClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [tempClientSecret, setTempClientSecret] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        setClientName(tokenPair.name);
        setTempClientName(tokenPair.name);
        setClientId(tokenPair.token.client_id);
        setTempClientId(tokenPair.token.client_id);
        setClientSecret(tokenPair.token.client_secret);
        setTempClientSecret(tokenPair.token.client_secret);
    }, [tokenPair]);

    if(tokenPair.type !== 2) {
        return null;
    }

    const resetFields = () => {
        setTempClientName(clientName);
        setTempClientId(clientId);
        setTempClientSecret(clientSecret);
        setEditMode(false);
    };

    const updateCourtlinkDetails = async() => {
        try {
            setSubmitting(true);
            const params = {
                clientId: tempClientId,
                name: tempClientName,
                clientSecret: tempClientSecret,
            };
            await editCourtlinkToken(tokenPair.id, params);
            addFlashMessage('success', 'Token successfully updated');
            setSubmitting(false);
            setClientName(tempClientName);
            setClientId(tempClientId);
            setTempClientSecret(tempClientSecret);
            setEditMode(false);
            refreshProfile();
        } catch(err) {
            // handleError(err, setErrors);
            if(err.message && err.message === 'This token already used') {
                addFlashMessage('danger', 'This token is already in use');
            } else {
                addFlashMessage('danger', 'Invalid token details');

            }
            setSubmitting(false);
        }
    };

    const deleteCourtlinkDetails = async() => {
        try {
            setDeleting(true);
            await deleteCourtlinkToken(tokenPair.id);
            addFlashMessage('success', 'Token successfully deleted');
            setDeleting(false);
            refreshProfile();
        } catch(err) {
            handleError(err, setErrors);
            setDeleting(false);
        }
    };

    return (
        <div className="courtlink-token">
            {editMode ?
                <div className="courtlink-token__info">
                    <div>
                        {/*<Label>Client ID</Label>*/}
                        <div style={{maxWidth: '390px', width: '100%'}}>
                            <InputWithError
                                prependIcon={<i className="fa fa-user"/>}
                                name="clientName"
                                value={tempClientName}
                                type="text"
                                onChange={setTempClientName}
                                errorObj={errors}
                                marginBottomClass="mb-3"
                                placeholder="Client Name"
                            />
                        </div>
                    </div>
                    <div>
                        {/*<Label>Client ID</Label>*/}
                        <div style={{maxWidth: '390px', width: '100%'}}>
                            <InputWithError
                                prependIcon={<i className="fa fa-user"/>}
                                name="clientId"
                                value={tempClientId}
                                type="text"
                                onChange={setTempClientId}
                                errorObj={errors}
                                marginBottomClass="mb-3"
                                placeholder="Client ID"
                            />
                        </div>
                    </div>
                    <div>
                        {/*<Label>Client Secret</Label>*/}
                        <div style={{maxWidth: '390px'}}>
                            <InputWithError
                                prependIcon={<i className="fa fa-key"/>}
                                name="secret"
                                value={tempClientSecret}
                                type="text"
                                onChange={setTempClientSecret}
                                errorObj={errors}
                                marginBottomClass="mb-0"
                                placeholder="Client Secret"
                            />
                        </div>
                    </div>
                </div>
                :
                <div className="courtlink-token__info">
                    <CopyToClipboard text={clientName}>
                        <div className="courtlink-token-info-item"><strong>Client Name:</strong> {clientName}</div>
                    </CopyToClipboard>
                    <CopyToClipboard text={clientId}>
                        <div className="courtlink-token-info-item"><strong>Client ID:</strong> {clientId}</div>
                    </CopyToClipboard>
                    <CopyToClipboard text={clientSecret}>
                        <div className="courtlink-token-info-item"><strong>Client Secret:</strong> {clientSecret}</div>
                    </CopyToClipboard>
                </div>
            }
            {editMode ?
                <div className="courtlink-token__actions">
                    <Button
                        className="px-4 mr-2 courtlink-field-btn"
                        onClick={resetFields}
                    >
                        Cancel
                    </Button>
                    <SpinnerButton
                        className="courtlink-field-btn"
                        color="primary"
                        submitting={submitting}
                        onClick={updateCourtlinkDetails}
                        title="Update"
                    />
                </div>
                :
                <div className="courtlink-token__actions">
                    <Button className="btn-light mr-2" onClick={() => setEditMode(true)}><i
                        className="fa fa-edit"/></Button>
                    <SpinnerButtonSmall
                        color="danger"
                        // className="newsletter-section__header-btn"
                        onClick={() => deleteCourtlinkDetails(tokenPair.id)}
                        disabled={false}
                        submitting={deleting}
                        defaultSize
                        title={<i className="fa fa-trash"/>}
                    />
                    {/*<Button className="btn-danger" onClick={() => deleteCourtlinkDetails(tokenPair.id)}><i*/}
                    {/*    className="fa fa-trash"/></Button>*/}
                </div>
            }
        </div>
    );
};

export default Token;
