import React, {useState, useEffect} from 'react';

import Article from './Article';
import LoadingSpinner from 'components/LoadingSpinner';

const ArticleList = (props) => {
    const {articles, selectedAlert, handleArticleSelect, fetchingArticles, selectedTag, setActiveView} = props;

    return (
        <div className="mobile-reader-list mobile-reader-article-list">
            <div className="mobile-reader-list__header mobile-reader-article-list__header">
                Showing Articles for "{selectedAlert.name}"
                {selectedTag &&
                <div className="mobile-reader-list__active-tag">
                    Active Filter: <strong>{selectedTag.name}</strong>
                </div>
                }
            </div>
            {!fetchingArticles &&
            <div className="mobile-reader-article-list__subheader">
                <div>
                    {articles.length} {articles.length === 1 ? 'Result' : 'Results'}
                </div>
                <div onClick={() => setActiveView('filters-select-category')}>
                    Filters
                    <i className="fa fa-angle-right"/>
                </div>
            </div>
            }
            {fetchingArticles ?
                <div className="mobile-reader-article mobile-reader-article--loading">
                    <LoadingSpinner text="Fetching articles"/>
                </div>
                :
                <div className="mobile-reader-list__body mobile-reader-article-list__body">
                    {articles.length < 1 ?
                        <div className="mobile-reader-article">
                            No articles found
                        </div>
                        :
                        articles.map(article => (
                            <Article
                                article={article}
                                key={article.id}
                                isSelected={article.id === selectedAlert.id}
                                handleArticleSelect={handleArticleSelect}
                            />
                        ))
                    }
                </div>
            }
        </div>
    );
};

export default ArticleList;
