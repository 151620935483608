import React from 'react';
import {Col, Row, Button} from 'reactstrap';
import Logo from 'images/logo.png';
import {useHistory} from 'react-router-dom';

const AccountSuspended = () => {
    let history = useHistory();

    const redirectToLogin = () => {
        history.push('/signin');
    };

    return (
        <div className="auth-block">
            <div className="auth-block__form">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{marginBottom: '.5rem'}}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo}
                                         alt="Ozmosys"
                                         className="auth-block__image img-fluid"
                                    />
                                </a>
                            </div>
                            <div>

                                <div className="text-center">
                                    <h2>Account Suspended</h2>
                                    <p className="mt-4 mb-3 text-muted">You have successfully suspended your account. For reinstatement, return to the login screen, attempt to sign in, and follow the subsequent instructions.
                                    </p>
                                    <Button block className="mt-4 " color={'primary'} onClick={redirectToLogin}>
                                        Return to Login
                                    </Button>
                                </div>

                            </div>


                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default AccountSuspended;
