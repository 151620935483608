import React, { Suspense, useState, useEffect, useContext, lazy } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { AppFooter, AppHeader } from '@coreui/react';
import Sidebar from './Sidebar';
import Users from 'containers/Users';
import Alerts from 'containers/Alerts';
import ManageSources from 'containers/Alerts/Sources';
import CreateSource from 'containers/Alerts/Sources/CreateSource';
import CreateAlerts from 'containers/Alerts/CreateAndEdit';
import EditAlerts from 'containers/Alerts/CreateAndEdit/Edit';
import AssignAlerts from 'containers/Alerts/Assignment';
import DigDeeper from 'containers/Alerts/DigDeeper';
import Dashboard from 'containers/Dashboard';
import GlobalAssignment from 'containers/GlobalAssignment';
import PrivacyPolicyAuth from 'components/PrivacyPolicy/PrivacyPolicyAuth';
import Settings from 'containers/Settings';
import Reader from 'containers/Reader';
import Newsletters from 'containers/Newsletters';
import AssignNewsletter from 'containers/Newsletters/Assignment';
import ManageNewsletterContent from 'containers/Newsletters/ManageContent';
import CreateUser from 'containers/Users/CreateAndEdit';
import EditUser from 'containers/Users/CreateAndEdit/Edit';
import SortAlerts from 'containers/Users/SortAlerts';
import Reports from 'containers/Reports';
import CompanyPage from 'containers/Alerts/CompanyPage';
import CompanyPagePreview from 'containers/Alerts/CompanyPage/Preview';
import ManageCompanies from 'containers/Alerts/Companies';
import CreateCompanyBased from 'containers/Alerts/Companies/CreateCompanyBased';
import { AuthContext } from 'contexts/AuthContext';
import ManageCategoryBased from '../Alerts/CategoryBased';

const Footer = lazy(() => import('containers/Layout/Footer'));
const Header = lazy(() => import('containers/Layout/Header/Header'));

const Layout = (props) => {
    const { state } = useContext(AuthContext);
    let match = useRouteMatch();
    const [accessCompanyBasedAlerts, setAccessCompanyBasedAlerts] = useState(false);

    useEffect(() => {
        if (state.profile.organization?.accessCompanyBasedAlerts) {
            setAccessCompanyBasedAlerts(true);
        }
    }, [state.profile]);

    const loading = () => (
        <div className="animated fadeIn pt-1 text-center">
            <div className="sk-spinner sk-spinner-pulse" />
        </div>
    );

    return (
        <div className="app">
            <AppHeader fixed>
                <Suspense fallback={loading()}>
                    <Header />
                </Suspense>
            </AppHeader>
            <div className="app-body">
                <Sidebar {...props} />
                <main className="main">
                    <Suspense fallback={loading()}>
                        <Switch>
                            <Route path={`/account/dashboard`}>
                                <Dashboard />
                            </Route>
                            <Route path={`/account/reader/:id`}>
                                <Reader />
                            </Route>
                            <Route path={`/account/assignment`}>
                                <GlobalAssignment />
                            </Route>
                            <Route path={`/account/alerts/dig-deeper/:id`}>
                                <DigDeeper />
                            </Route>
                            <Route path={`/account/alerts/assign/:id`}>
                                <AssignAlerts />
                            </Route>
                            <Route path={`/account/alerts/edit/:id`}>
                                <EditAlerts />
                            </Route>
                            <Route path={`/account/searches/edit/:id`}>
                                <EditAlerts isSources={false} />
                            </Route>
                            <Route path={`/account/sources/edit/:id`}>
                                <EditAlerts isSources={true} />
                            </Route>
                            <Route exact path={`/account/alerts/create`}>
                                <CreateAlerts />
                            </Route>
                            <Route exact path={`/account/sources/create`}>
                                <CreateSource />
                            </Route>
                            <Route path={`/account/users/edit/:id`}>
                                <EditUser />
                            </Route>
                            <Route path={`/account/users/sort/:id`}>
                                <SortAlerts />
                            </Route>
                            <Route path={`/account/users/create`}>
                                <CreateUser />
                            </Route>
                            <Route path={`/account/users`}>
                                <Users />
                            </Route>
                            <Route path={`/account/manage-category-based/:searchType`}>
                                <ManageCategoryBased />
                            </Route>
                            <Route exact path={`/account/alerts`}>
                                <Alerts />
                            </Route>
                            <Route path={`/account/manage-sources`}>
                                <ManageSources />
                            </Route>
                            <Route path={`/account/manage-companies`}>
                                {state.profile?.id ? (
                                    state.profile.organization?.accessCompanyBasedAlerts ? (
                                        <ManageCompanies />
                                    ) : (
                                        <Redirect from="/" to="/account/dashboard" />
                                    )
                                ) : (
                                    loading()
                                )}
                            </Route>
                            {accessCompanyBasedAlerts && (
                                <Route exact path={`/account/companies/create`}>
                                    <CreateCompanyBased />
                                </Route>
                            )}
                            {accessCompanyBasedAlerts && (
                                <Route path={`/account/alerts/:id/company-profile`}>
                                    <CompanyPage />
                                </Route>
                            )}
                            {accessCompanyBasedAlerts && (
                                <Route path={`/account/alerts/:symbol/company-preview`}>
                                    <CompanyPagePreview />
                                </Route>
                            )}
                            <Route path={`/account/privacy-policy`}>
                                <PrivacyPolicyAuth />
                            </Route>
                            <Route exact path={'/settings'}>
                                <Settings />
                            </Route>
                            <Route path={`/account/newsletters/assign/:id`}>
                                <AssignNewsletter />
                            </Route>
                            <Route path={`/account/newsletters/content/:id`}>
                                <ManageNewsletterContent />
                            </Route>
                            <Route path={`/account/newsletters`}>
                                <Newsletters />
                            </Route>
                            <Route path={`/account/reports`}>
                                <Reports />
                            </Route>
                            <Redirect from="/" to="/account/dashboard" />
                        </Switch>
                    </Suspense>
                </main>
            </div>
            <AppFooter>
                <Suspense fallback={loading()}>
                    <Footer />
                </Suspense>
            </AppFooter>
        </div>
    );
};

export default Layout;
