import React from 'react';
import {Card, CardHeader, CardBody} from 'reactstrap';
import LoadingSpinner from 'components/LoadingSpinner';
import Chart from './ArticlesPerDayChart';
import EmptyChart from './ArticlesPerDayChart/EmptyChart';
import './style.scss';

const CardPerDayChart = (props) => {
    const {alertData, fetchingData} = props;

    return (
        <Card>
            <CardHeader>News per Day (Last 30 Days)</CardHeader>
            <CardBody>
                {fetchingData ?
                    <LoadingSpinner/>
                    :
                    alertData.news && alertData.news.length > 0 ?
                        <Chart
                            articles={alertData.news}
                            articlesPerDay={alertData.newsPerDay}
                        />
                        :
                        <EmptyChart/>

                }
            </CardBody>
        </Card>
    );
};

export default CardPerDayChart;
