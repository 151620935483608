import React, { useEffect, useState } from 'react';
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Collapse } from 'reactstrap';
import { Draggable } from 'react-beautiful-dnd';

import { convertDateString } from 'Helpers/convertDateString';
import CommentForm from '../../CommentForm';
import { truncateLongWords } from 'Helpers/truncateLongWords';
import { truncateWithoutWordBreak } from 'Helpers/truncateWithoutWordBreak';
import { addCommentToNewsletterArticle } from 'utils/api/newslettersAPI';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const AssignedArticle = (props) => {
    const {
        article,
        handleRemoveAssignedArticle,
        section,
        index,
        refreshNewsletter,
        newsletterId,
        listOpen,
        handleError,
        addFlashMessage,
        includeAbstracts,
        isUnassignedSection = false,
    } = props;
    const [commentFormOpen, setCommentFormOpen] = useState(false);
    const [commentValue, setCommentValue] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (article && article.comment) {
            const blocksFromHtml = htmlToDraft(article.comment);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            // setCommentValue(EditorState.createWithContent(ContentState.createFromText(article.comment)))
            setCommentValue(editorState);
        } else {
            setCommentValue(EditorState.createEmpty());
        }
    }, [article]);

    useEffect(() => {
        if (!listOpen) setCommentFormOpen(false);
    }, [listOpen]);

    const toggleCommentFormOpen = (e) => {
        e.stopPropagation();
        setCommentFormOpen(!commentFormOpen);
    };

    const handleCommentSubmit = async () => {
        try {
            let commentValueAsHtml;
            if (commentValue) {
                const rawContentState = convertToRaw(commentValue.getCurrentContent());
                commentValueAsHtml = draftToHtml(rawContentState);
            }
            let trimmedHtmlTagsValue = commentValueAsHtml?.replace(new RegExp('<[^>]*>', 'g'), '')?.trim();
            const params = {
                comment: trimmedHtmlTagsValue.length ? trimmedHtmlTagsValue : '',
            };
            await addCommentToNewsletterArticle(newsletterId, section.id, article.news.id, params);
            refreshNewsletter();
            addFlashMessage('success', 'Comment successfully saved');
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <Draggable draggableId={String(article.news.id)} index={index}>
            {(provided, snapshot) => (
                <div
                    className={'newsletter-section-article ' + (snapshot.isDragging ? 'newsletter-section-article--is-dragging' : '')}
                    {...provided.draggableProps}
                    ref={provided.innerRef}>
                    <div className="d-flex flex-row flex-grow-1 justify-content-between" {...provided.dragHandleProps}>
                        <div className="newsletter-section-article__inner">
                            <div className="newsletter-section-article__title">
                                <a href={article.news.url} target="_blank">
                                    {article.news.title.length > 57 ? (
                                        <div className="article-in-section-title">
                                            <strong>{truncateLongWords(truncateWithoutWordBreak(article.news.title, 57), 80)}</strong>
                                        </div>
                                    ) : (
                                        <div className="article-in-section-title">
                                            <strong>{truncateLongWords(article.news.title, 100)}</strong>
                                        </div>
                                    )}
                                </a>
                            </div>
                            {includeAbstracts && (
                                <div className="newsletter-section-article__abstract">
                                    {truncateLongWords(truncateWithoutWordBreak(article.news.abstract, 157), 80)}
                                </div>
                            )}
                            <div className="newsletter-section-article__info">
                                <span>{article.news.newsResource.domain || ''}</span>
                                <span className="newsletter-section-article__date">
                                    {article.news.createdAt ? convertDateString(article.news.createdAt) : 'Pending Updates'}
                                </span>
                            </div>
                        </div>
                        <div className="newsletter-section-article__utility">
                            {!isUnassignedSection && (
                                <div className="newsletter-section-article__comment-btn" onClick={toggleCommentFormOpen}>
                                    <i
                                        className={'fa fa-comment ' + (article.comment && article.comment.length ? 'theme-text-color' : 'dark-gray')}
                                    />
                                </div>
                            )}
                            <div
                                className="newsletter-section-article__unassign-btn"
                                onClick={() => handleRemoveAssignedArticle(article.news.id, section)}>
                                <span>×</span>
                            </div>
                        </div>
                    </div>
                    <Collapse isOpen={commentFormOpen}>
                        <CommentForm
                            value={commentValue ? commentValue : ''}
                            handleValueChange={setCommentValue}
                            onSubmit={handleCommentSubmit}
                            disabled={commentValue ? commentValue.length > 500 || commentValue === article.comment : true}
                        />
                    </Collapse>
                </div>
            )}
        </Draggable>
    );
};

export default AssignedArticle;
