import React, {useState} from 'react';
import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap';

import TabBar from 'components/TabBar';
import AlertsList, { CONTENT_TYPE } from '../AlertsList';
import AlertGroups from '../AlertGroups';
import Labels from '../Labels';

const ManageSources = () => {
    const [activeView, setActiveView] = useState('alerts');

    const handleActiveView = () => {
        switch(activeView) {
            case 'alerts':
                return <AlertsList alertType={CONTENT_TYPE} />;
            case 'alertGroups':
                return <AlertGroups/>;
            case 'labels':
                return <Labels/>;
            default:
                return <AlertsList alertType={CONTENT_TYPE} />;
        }
    };

    return (
        <div className="view view--mobile-full alerts ">
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <div className="flex-space-between">
                                    <h1>Manage Sources</h1>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <TabBar
                                    activeItem={activeView}
                                    setActive={setActiveView}
                                    items={
                                        [
                                            {data: 'alerts', name: 'My Sources'},
                                            {data: 'alertGroups', name: 'Groups'},
                                            {data: 'labels', name: 'Labels'},
                                        ]
                                    }
                                />
                                {handleActiveView()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ManageSources;
