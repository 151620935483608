import React, {useState, useEffect, useContext} from 'react';
import SearchForm from './SearchForm';
import TitleForm from './TitleForm';
import Preview from './Preview';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {addAlertLabel, addCompanyBasedAlert, checkPossibleToAddAlert, 
    editCompanyBasedAlert, checkExistsCompanyBased, companyCheckExistsCompanyBased} from 'utils/api/alertsAPI';
import {useHistory} from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import {AuthContext} from 'contexts/AuthContext';
import './style.scss';

const CompanyBasedForm = (props) => {
    let history = useHistory();
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError, state} = useContext(AuthContext);

    const {foundAlert, isEditForm, labels, internalGroup} = props;

    const [searchValue, setSearchValue] = useState('');
    const [searchValueError, setSearchValueError] = useState(false);
    const [searchValueEditWarning, setSearchValueEditWarning] = useState(false);
    const [titleValue, setTitleValue] = useState('');
    const [preview, setPreview] = useState(null);
    const [titleFormVisible, setTitleFormVisible] = useState(false);
    const [searchSubmitting, setSearchSubmitting] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [creatingLabel, setCreatingLabel] = useState(false);
    const [newLabelToAdd, setNewLabelToAdd] = useState(null);
    const [updatingPreview, setUpdatingPreview] = useState(false);
    const [errors, setErrors] = useState(null);


    useEffect(() => {
        const {isEditForm, foundAlert} = props;
        const getFoundAlertValues = () => {
            setSearchValue(foundAlert.source.symbol);
            setTitleValue(foundAlert.name);
            setTitleFormVisible(true);
            handleCreatePreview(foundAlert.source.symbol);
            setSelectedLabels(foundAlert.labels);
        };

        isEditForm && getFoundAlertValues();
    }, []);

    const handleCreatePreview = async(initialLoadValue) => {
        let tempSearchValue = initialLoadValue ? initialLoadValue : searchValue;
        // try {
        //     if(tempSearchValue.length > 0) {
        //         setPreview(null);
        //         setSearchSubmitting(true);
        //         setErrors(null);
        //         const preview = await previewCompanyBasedAlert(tempSearchValue);
        //         if(preview.errors) {
        //             handleCompanyBasedErrors(preview);
        //             return;
        //         }
        //         !isEditForm && setTitleValue(preview.globalTitle);
        //         setTitleFormVisible(true);
        //         setPreview(preview);
        //         setSearchSubmitting(false);
        //     }
        // } catch(err) {
        //     handleCompanyBasedErrors(err);
        // }

        try {
            if(tempSearchValue.length > 0) {
                setSearchSubmitting(true);
                const response = await companyCheckExistsCompanyBased(tempSearchValue);
                if(response.errors) {
                    handleCompanyBasedErrors(preview);
                    return;
                }
                if (response.length === 0) {
                    addFlashMessage('warning', 'Company not found');
                    setSearchSubmitting(false);
                    return;
                }

                const {alertId} = await checkExistsCompanyBased(tempSearchValue);
                if (alertId) {
                    history.push(`/account/alerts/${alertId}/company-profile`);
                    return;
                }
                history.push(`/account/alerts/${tempSearchValue}/company-preview`);
            }
        } catch(err) {
            handleCompanyBasedErrors(err);
        }
    };

    const handleCompanyBasedErrors = (err) => {
        handleError(err, setErrors);
        setSearchSubmitting(false);
        setTitleFormVisible(false);

    };

    const handleSearchValueChange = (text) => {
        setSearchValue(text);
    };

    useEffect(() => {
        if(props.isEditForm) {
            if(searchValue !== props.foundAlert.source.symbol) {
                setSearchValueEditWarning(true);
            } else {
                setSearchValueEditWarning(false);
            }
        }
    }, [searchValue]);

    const resetForm = () => {
        setPreview(null);
        setTitleFormVisible(false);
        setTitleValue('');
        setSearchValue('');
        setErrors(null);
    };

    const clearPreviewForm = () => {
        setPreview(null);
        setTitleFormVisible(false);
        setTitleValue('');
    };

    const handleSubmit = async() => {
        const companyBasedSearchValues = {
            name: titleValue,
            symbol: searchValue,
            alertLabels: selectedLabels.map(n => n.id),
        };

        try {
            setSubmitting(true);
            if(props.isEditForm) {
                await editCompanyBasedAlert(props.foundAlert.id, companyBasedSearchValues);
                addFlashMessage('success', 'Alert successfully updated');
                history.push('/account/alerts');
            } else {
                let isPossibleToAdd = await checkPossibleToAddAlert();
                if(isPossibleToAdd) {
                    await addCompanyBasedAlert(companyBasedSearchValues);
                    setSubmitting(false);
                    addFlashMessage('success', 'Alert successfully created');
                    history.push('/account/alerts');
                } else {
                    addFlashMessage('danger', 'You\'ve reached the limit of allowed number of alerts for your account.\n' +
                        'Contact Customer Care team for more information.');
                }
            }
        } catch(err) {
            setSubmitting(false);
            if(err.code === 'NOT_FOUND') {
                let errObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        url: [
                            {message: '404 Not Found'},
                        ],
                    },
                };
                handleError(errObj, setErrors);
                return;
            }
            if(err.code === 'VALIDATION_FAILED') {
                if(err.errors.name) {
                    if(err.errors.name[0].code === 'TOO_SHORT_ERROR') {
                        addFlashMessage('danger', 'Alert name must be at least 2 characters long.');
                    } else if(err.errors.name[0].code === 'IS_BLANK_ERROR') {
                        addFlashMessage('danger', 'Alert name must be at least 2 characters long.');
                    }
                } else {
                    if(err.message.slice(0, 17) === 'Alert with source') {
                        let errObj = {
                            code: 'VALIDATION_FAILED',
                            errors: {
                                url: [
                                    {message: 'An alert with this company already exists.'},
                                ],
                            },
                        };
                        handleError(errObj, setErrors);
                    }
                }
            } else {
                handleError(err, setErrors);
            }
        }
    };

    useEffect(() => {
        if(newLabelToAdd) {
            const foundLabel = labels.find(label => label.id === newLabelToAdd.id);
            foundLabel && addToSelected(foundLabel);
            setNewLabelToAdd(null);
        }
    }, [labels]);

    const addLabel = async(name) => {
        setCreatingLabel(true);
        try {
            const newLabel = await addAlertLabel({name});
            setNewLabelToAdd(newLabel.content);
            await props.refreshLabels();
            setCreatingLabel(false);
        } catch(res) {
            if(res.errors.name[0].message) {
                addFlashMessage('danger', res.errors.name[0].message);
            }
            setCreatingLabel(false);
        }
    };

    const addToSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if(newSelectedLabels.indexOf(label) === -1) {
            newSelectedLabels.push(label);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const removeFromSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if(newSelectedLabels.indexOf(label) > -1) {
            newSelectedLabels.splice(newSelectedLabels.indexOf(label), 1);
            setSelectedLabels(newSelectedLabels);
        }
    };

    return (
        <div className="mb-4">
            <SearchForm searchValue={searchValue}
                        handleSearchValueChange={handleSearchValueChange}
                        handleCreatePreview={handleCreatePreview}
                        searchValueError={searchValueError}
                        setSearchValueError={setSearchValueError}
                        resetForm={resetForm}
                        clearPreviewForm={clearPreviewForm}
                        searchSubmitting={searchSubmitting}
                        setSearchSubmitting={setSearchSubmitting}
                        searchValueEditWarning={searchValueEditWarning}
                        errorObj={errors}
                        internalGroup={internalGroup}
            />

            {titleFormVisible &&
            <TitleForm title={titleValue}
                       setTitleValue={setTitleValue}
                       submitting={submitting}
                       isEditForm={props.isEditForm}
                       handleSubmit={handleSubmit}
            />
            }

            {
                searchSubmitting ?
                    <div className="mt-4">
                        <LoadingSpinner text="Fetching preview"/>
                    </div>
                    : preview &&
                    <Preview
                        preview={preview}
                        updatingPreview={updatingPreview}
                        submitting={submitting}
                        handleSubmit={handleSubmit}
                        isEditForm={isEditForm}
                        selectedLabels={selectedLabels}
                        labels={labels}
                        addLabel={addLabel}
                        addToSelected={addToSelected}
                        removeFromSelected={removeFromSelected}
                        creatingLabel={creatingLabel}
                        isSingleUser={state.isSingleUser}
                    />
            }
        </div>
    );
};

export default CompanyBasedForm;
