export const suppressSocialStatuses = [
    {
      key:'allow',
      name: 'Allow'
    },
    {
      key:'suppress',
      name: 'Suppress'
    },
    {
      key:'suppress_regular_user',
      name: 'Suppress for regular users'
    },
  ]
  
  