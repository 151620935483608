import React, {useState, useEffect, useContext} from 'react';

import {Modal} from 'reactstrap';

import {editNewsletterById, getNewsletterById} from 'utils/api/newslettersAPI';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import Form from 'containers/Newsletters/Modals/CreateAndEdit/Form/Form';
import LoadingSpinner from 'components/LoadingSpinner';
import './style.scss';

const EditNewsletterModal = (props) => {
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError} = useContext(AuthContext);
    const {isOpen, toggle, refreshNewsletters, newsletter} = props;
    const [submitting, setSubmitting] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if(newsletter) setFetchingData(false);
    }, [newsletter]);

    const updateNewsletter = async(params) => {
        try {
            setSubmitting(true);
            setErrors(null);
            await editNewsletterById(newsletter.id, params);
            setSubmitting(false);
            refreshNewsletters();
            addFlashMessage('success', 'Newsletter successfully updated');
            toggle();
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="edit-newsletter-modal modal-lg">
            {fetchingData ?
                <LoadingSpinner text="Fetching newsletter data" padding/>
                :
                <Form
                    isEditForm
                    foundNewsletter={newsletter}
                    formTitle="Edit Newsletter"
                    submitting={submitting}
                    onSubmit={updateNewsletter}
                    toggle={toggle}
                    submitButtonTitle="Update"
                    errors={errors}
                    setErrors={setErrors}
                />
            }
        </Modal>
    );
};

export default EditNewsletterModal;
