import React from 'react';
import { Table, FormGroup, Input, Label, Col, UncontrolledTooltip } from 'reactstrap';
import '../AlertRulesModal/Info.scss';

const TableColCategories = (props) => {
    const { categories, handleChangeCategories } = props;

    return (
        <Col className="pr-1" xs="4">
            <Table className="table-striped" size="sm">
                <tbody>
                    {categories?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <FormGroup id="category-item" check inline key={item.id}>
                                        <Input
                                            id={item.id + '--'}
                                            type="checkbox"
                                            value={item.id}
                                            onChange={(e) => handleChangeCategories(e.target.value, e.target.checked)}
                                            disabled={item.assigned > 0 || !!item?.children}
                                            defaultChecked={item.alerts > 0}
                                        />
                                        <Label style={{ margin: '5px 0 0 5px' }} for={item.id + '--'}>
                                            {item.name}
                                        </Label>
                                    </FormGroup>
                                    {item?.children > 0 && (
                                        <UncontrolledTooltip placement="bottom" target="category-item" offset="20, 5">
                                            There are sources associated with this category that needs to be removed first
                                        </UncontrolledTooltip>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Col>
    );
};

export default TableColCategories;
