import {
    postDecorator,
    patchDecorator,
    getDecorator,
    deleteDecorator,
    publicDecorator,
    csvDecorator,
    publicPostDecorator,
} from 'utils/api/decorators';
import axios from 'axios';
import { CATEGORY_TYPE, COMPANY_TYPE, CONTENT_TYPE, SEARCH_TYPE } from 'containers/Alerts/AlertsList';

export const SEARCH_TYPE_PRACTICE = 1;
export const SEARCH_TYPE_INDUSTRY = 2;
export const SEARCH_TYPE_TOPIC = 3;

export const getAlerts = (
    count,
    pageNumber,
    itemsPerPage,
    sortProperty,
    sortDirection,
    searchProperty,
    searchValue = '',
    labels = [],
    csv = false,
    title,
    alertType = SEARCH_TYPE,
    searchTypeId = null
) => {
    let encodedSearchValue = encodeURIComponent(searchValue);
    let url = `/api/v2/alert?help=false&count=${count}&page=${pageNumber}&itemsOnPage=${itemsPerPage}&sort%5B0%5D%5Bproperty%5D=${sortProperty}&sort%5B0%5D%5Bdirection%5D=${sortDirection.toUpperCase()}&csv=${csv}`;

    if (encodedSearchValue.length > 0) {
        url =
            url +
            `&filter%5B0%5D%5Bproperty%5D=${searchProperty}&filter%5B0%5D%5Boperator%5D=contains&filter%5B0%5D%5Bvalue%5D=${encodedSearchValue}`;
    }
    labels.length > 0 &&
        labels.map((label, index) => {
            url += `&filter%5B${index + 1}%5D%5Bproperty%5D=alert.labels&filter%5B${index + 1}%5D%5Boperator%5D=equals&filter%5B${
                index + 1
            }%5D%5Bvalue%5D=${encodeURIComponent(label)}`;
        });

    url += `&filter%5B${labels.length + 1}%5D%5Bproperty%5D=source.type&filter%5B${labels.length + 1}%5D%5Boperator%5D=in`;

    const alertTypes = {
        [SEARCH_TYPE]: ['google', 'internal', 'internal_group'],
        [CONTENT_TYPE]: ['rss', 'email_publication', 'courtlink', 'translation'],
        [COMPANY_TYPE]: ['company_based'],
        [CATEGORY_TYPE]: ['ozmosys_categorized'],
    };

    alertTypes[alertType].map((value, index) => {
        url += `&filter%5B${labels.length + 1}%5D%5Bvalue%5D%5B${index}%5D=${value}`;
    });

    if (searchTypeId) {
        url += `&searchTypeId=${searchTypeId}`;
    }

    if (csv) {
        return csvDecorator(url, title);
    } else {
        return getDecorator(url);
    }
};

export const getAlertsWithoutParams = (csv, title) => {
    let url = `/api/v2/alert?help=false&count=false&page=1&itemsOnPage=3000&sort%5B0%5D%5Bproperty%5D=alert.createdAt&sort%5B0%5D%5Bdirection%5D=DESC&csv=${csv}`;

    url += `&filter[1][property]=source.type&filter[1][operator]=not_in`;
    ['google_hidden'].map((value, index) => {
        url += `&filter%5B1%5D%5Bvalue%5D%5B${index}%5D=${value}`;
    });

    if (csv) {
        return csvDecorator(url, title);
    } else {
        return getDecorator(url);
    }
};

export const getAlertsForGroupList = () => {
    let url = '/api/v2/alert?count=false&page=1&itemsOnPage=3000&sort%5B0%5D%5Bproperty%5D=alert.createdAt&sort%5B0%5D%5Bdirection%5D=DESC';
    url +=
        '&filter%5B1%5D%5Bproperty%5D=source.type&filter%5B1%5D%5Boperator%5D=in&filter%5B1%5D%5Bvalue%5D%5B0%5D=rss&filter%5B1%5D%5Bvalue%5D%5B1%5D=email_publication&filter%5B1%5D%5Bvalue%5D%5B2%5D=courtlink&filter%5B1%5D%5Bvalue%5D%5B3%5D=translation';

    return getDecorator(url);
};

export const getAlertById = (id) => {
    const url = `/api/v2/alert/${id}`;
    return getDecorator(url);
};

export const addGoogleAlert = (params) => {
    const url = '/api/v2/alert/google';
    return postDecorator(url, params);
};

export const editGoogleAlert = (id, params) => {
    const url = `/api/v2/alert/google/${id}`;
    return patchDecorator(url, params);
};

export const previewGoogleAlert = (params) => {
    const { keyword, lang, country } = params;
    let encodedKeyword = encodeURIComponent(keyword);
    const url = `/api/v2/alert/google/preview?keyword=${encodedKeyword}&lang=${lang}&country=${country}`;
    return getDecorator(url, params);
};

export const addInternalAlert = (params) => {
    const url = '/api/v2/internal';
    return postDecorator(url, params);
};

export const editInternalAlert = (id, params) => {
    const url = `/api/v2/internal/${id}`;
    return patchDecorator(url, params);
};

export const previewInternalAlert = (params) => {
    const {
        keyword,
        alerts,
        minRelevanceValue,
        sortValue,
        sortDirection,
        excludeSourceTypes,
        category,
        tagSpacy,
        // parseByElastic,
        useStopWords,
        sentiment,
        geoLocation,
    } = params;
    let encodedKeyword = encodeURIComponent(keyword);
    let url = `/api/v2/internal/preview`;
    const postParams = {
        keyword: encodedKeyword,
        alerts,
        excludeSourceTypes: excludeSourceTypes && excludeSourceTypes.length ? ['google'] : [],
        minRelevanceValue: minRelevanceValue,
        // searchInSharedSources: true,
        sortField: sortValue,
        sortDirection,
        tagSpacy,
        category,
        useStopWords,
        // parseByElastic,
        sentiment,
        geoLocation,
    };
    return postDecorator(url, postParams);
};

export const getInternalSearchCategories = () => {
    const url = `/api/v2/alert/list_categories`;
    return getDecorator(url);
};

export const getAiCategories = (excludeSearchTypeId) => {
    const url = `/api/v2/alert/list_categories/ex/` + excludeSearchTypeId;
    return getDecorator(url);
};

export const getCategoriesBySearchTyoeId = (searchTypeId) => {
    const url = `/api/v2/alert/list_categories_by_search_type?searchTypeId=${searchTypeId}`;
    return getDecorator(url);
};

export const addRssAlert = (params) => {
    const url = '/api/v2/alert/rss';
    return postDecorator(url, params);
};

export const editRssAlert = (id, params) => {
    const url = `/api/v2/alert/rss/${id}`;
    return patchDecorator(url, params);
};

export const previewRssAlert = (searchValue) => {
    let encodedSearchValue = encodeURIComponent(searchValue);
    const url = `/api/v2/alert/rss/preview?url=${encodedSearchValue}`;
    return getDecorator(url);
};

export const addCompanyBasedAlert = (params) => {
    const url = '/api/v2/alert/company_based';
    return postDecorator(url, params);
};

export const editCompanyBasedAlert = (id, params) => {
    const url = `/api/v2/alert/company_based/${id}`;
    return patchDecorator(url, params);
};

export const previewCompanyBasedAlert = (searchValue) => {
    let encodedSearchValue = encodeURIComponent(searchValue);
    const url = `/api/v2/alert/company_based/preview?symbol=${encodedSearchValue}`;
    return getDecorator(url);
};

export const getCompanyAutocompleteResults = (query) => {
    const url = `/api/v2/alert/company_based/autocomplete?query=${query}`;
    return getDecorator(url);
};

export const getCompanyBasedProfile = (id) => {
    const url = `/api/v2/alert/company_based/${id}/profile`;
    return getDecorator(url);
};

export const getFreeCompanyBasedProfile = (id, hash) => {
    const url = `/api/v2/free/dig_deeper/alert/${id}/${hash}/30`;
    return publicDecorator(url);
};

export const checkExistsCompanyBased = (symbol) => {
    let encodedSearchValue = encodeURIComponent(symbol);
    const url = `/api/v2/alert/company_based/check_exists?symbol=${encodedSearchValue}`;
    return getDecorator(url);
};

export const companyCheckExistsCompanyBased = (symbol) => {
    let encodedSearchValue = encodeURIComponent(symbol);
    const url = `/api/v2/alert/company_based/company_check_exists?query=${encodedSearchValue}`;
    return getDecorator(url);
};

export const addOzmosysCategorizedAlert = (params) => {
    const url = '/api/v2/ozmosys_categorized';
    return postDecorator(url, params);
};

export const editOzmosysCategorizedAlert = (id, params) => {
    const url = `/api/v2/ozmosys_categorized/${id}`;
    return patchDecorator(url, params);
};

export const previewOzmosysCategorizedAlert = (params) => {
    const url = '/api/v2/ozmosys_categorized/preview';
    return postDecorator(url, params);
};

export const getGeoLocations = () => {
    const url = '/api/v2/free/geo_locations';
    return getDecorator(url);
};

export const deleteOzmosysCategorizedAlert = (params) => {
    const url = '/api/v2/ozmosys_categorized/delete/' + params.ids;
    return deleteDecorator(url);
};

export const deleteAlert = (id) => {
    const url = `/api/v2/alert/${id}`;
    return deleteDecorator(url);
};

export const suspendAlert = (id, params) => {
    const url = `/api/v2/alert/${id}/switch_status`;
    return patchDecorator(url, params);
};

export const checkPossibleToAddAlert = () => {
    const url = '/api/v2/organization/is_possible_add_alert';
    return getDecorator(url);
};

export const assignUsersToAlert = (id, params) => {
    const url = `/api/v2/alert/${id}/assign_users`;
    return patchDecorator(url, params);
};

export const universalAssignAlerts = (params) => {
    const url = '/api/v2/organization/assign_alerts';
    return patchDecorator(url, params);
};

export function parseOPML(file) {
    const formData = new FormData();
    formData.append('file', file);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
        },
    };

    const rawToken = localStorage.getItem('token');
    const parsedToken = rawToken && JSON.parse(rawToken);

    return axios('/api/v2/alert/rss/opml/parse', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-AUTH-TOKEN': parsedToken.accessToken,
        },
        credentials: 'same-origin',
        data: formData,
        config: config,
    })
        .then((res) => {
            if (Array.isArray(res.data.content)) {
                return res.data.content;
            } else {
                return Object.keys(res.data.content).map((k) => res.data.content[k]);
            }
        })
        .catch((err) => {
            throw err.response.data.content;
        });
}

export const deleteUser = (id) => {
    const url = `/api/v2/user/${id}`;
    return deleteDecorator(url);
};

export const getAlertGroups = () => {
    const url = '/api/v2/alert_group';
    return getDecorator(url);
};

export const addAlertGroup = (params) => {
    const url = '/api/v2/alert_group';
    return postDecorator(url, params);
};

export const assignAlertsToAlertGroup = (id, params) => {
    const url = `/api/v2/alert_group/${id}/assign`;
    return patchDecorator(url, params);
};

export const editAlertGroup = (id, params) => {
    const url = `/api/v2/alert_group/${id}`;
    return patchDecorator(url, params);
};

export const deleteAlertGroup = (id) => {
    const url = `/api/v2/alert_group/${id}`;
    return deleteDecorator(url);
};

export const getAlertLabels = () => {
    const url = '/api/v2/alert_label';
    return getDecorator(url);
};

export const addAlertLabel = (params) => {
    const url = '/api/v2/alert_label';
    return postDecorator(url, params);
};

export const editAlertLabel = (id, params) => {
    const url = `/api/v2/alert_label/${id}`;
    return patchDecorator(url, params);
};

export const assignLabelToAlert = (id, params) => {
    const url = `/api/v2/alert/${id}/assign_labels`;
    return patchDecorator(url, params);
};

export const assignAlertToLabel = (id, params) => {
    const url = `/api/v2/alert_label/${id}/assign`;
    return patchDecorator(url, params);
};

export const deleteAlertLabel = (id) => {
    const url = `/api/v2/alert_label/${id}`;
    return deleteDecorator(url);
};

export const getWikiResults = (query) => {
    const url = `/api/v2/free/wiki_autocomplete?query=${query}`;
    return getDecorator(url);
};

export const getDigDeeperContent = (id, days) => {
    const url = `/api/v2/alert/${id}/dig_deeper/${days}`;
    return getDecorator(url);
};

export const getAlertPreview = (id) => {
    const url = `/api/v2/alert/${id}/preview`;
    return getDecorator(url);
};

export const getFreeDigDeeperContent = (id, hash, days) => {
    const url = `/api/v2/free/dig_deeper/alert/${id}/${hash}/${days}`;
    return publicDecorator(url);
};

export const getFreeReaderAlerts = (id, hash, days) => {
    const url = `/api/v2/free/reader/user/${id}/${hash}/${days}`;
    return publicDecorator(url);
};

export const getFreeEmailPreview = (id, hash) => {
    const url = `/api/v2/free/show_email/${id}/${hash}`;
    return publicDecorator(url);
};

export const getFreeNewsletterPreview = (id, hash) => {
    const url = `/api/v2/free/show_newsletter/${id}/${hash}`;
    return publicDecorator(url);
};

// Reader endpoints

export const getReaderProfile = () => {
    const url = `/api/v2/reader/profile`;
    return getDecorator(url);
};

export const getReaderAssignedAlerts = () => {
    const url = `/api/v2/reader/my_alerts`;
    return getDecorator(url);
};

export const getReaderAlerts = () => {
    const url = `/api/v2/reader/alerts`;
    return getDecorator(url);
};

export const getReaderAlertGroups = () => {
    const url = `/api/v2/reader/alert_groups`;
    return getDecorator(url);
};

export const manageReaderAlerts = (params) => {
    const url = `/api/v2/reader/manage_alerts`;
    return patchDecorator(url, params);
};

export const addLegiScanBillAlert = (params) => {
    const url = '/api/v2/alert/legi_scan_bill_internal';
    return postDecorator(url, params);
};

export const editLegiScanBillAlert = (id, params) => {
    const url = `/api/v2/alert/legi_scan_bill_internal/${id}`;
    return patchDecorator(url, params);
};

export const previewLegiScanBillAlert = (searchValue) => {
    let encodedSearchValue = encodeURIComponent(searchValue);
    const url = `/api/v2/alert/legi_scan_bill_internal/preview?url=${encodedSearchValue}`;
    return getDecorator(url);
};

export const editCommentAlert = (id, comment) => {
    const url = `/api/v2/alert/${id}/edit_comment`;
    return patchDecorator(url, { comment });
};

export const searchJointSources = (searchValue) => {
    let encodedSearchValue = encodeURIComponent(searchValue);
    const url = `/api/v2/joint_sources/search?term=${encodedSearchValue}`;
    return getDecorator(url);
};

export const sendRequestSource = (source) => {
    const url = `/api/v2/joint_sources/send_request_source`;
    return postDecorator(url, { source });
};

export const receiveOpenaiSummary = (params) => {
    const url = '/api/v2/free/openai_summary';
    return publicPostDecorator(url, params);
};

export const getOpenaiAnalysis = (params) => {
    const url = '/api/v2/free/latest_trend';
    return publicPostDecorator(url, params);
};

export const getBitUrl = (alertId, data) => {
    const url = '/api/v2/alert/url_bit/' + alertId;
    return postDecorator(url, data);
};

export const initBriefingReport = (data) => {
    const url = '/api/v2/alert/openai_briefing_report';
    return postDecorator(url, data);
};

//
// GET​/api​/v2​/free​/show_email​/{id}​/{hash}             Show Email on web-site
// GET​/api​/v2​/free​/show_newsletter​/{id}​/{hash}    Show Newsletter on web-site
