import React from 'react';
import { FormGroup, Label, Input as ReactStrapInput } from 'reactstrap';

export const RadioInput = (props) => {
    const { isCheck = true, isInline = true, onChange, checked = false, name, text } = props;

    return (
        <FormGroup check={isCheck} inline={isInline}>
            <Label check={isCheck}>
                <ReactStrapInput type="radio" onChange={onChange} name={name} checked={checked} />
                {text}
            </Label>
        </FormGroup>
    );
};

export const CheckboxInput = (props) => {
    const { isCheck = true, isInline = true, onChange, value, checked = false, name, text, style } = props;

    return (
        <FormGroup check={isCheck} inline={isInline}>
            <Label check={isCheck}>
                <ReactStrapInput type="checkbox" value={value} onChange={onChange} name={name} checked={checked} style={style} />
                {text}
            </Label>
        </FormGroup>
    );
};
