import React, { useContext, useEffect, useState } from 'react';

import { Table } from 'reactstrap';
import moment from 'moment-timezone';

import LoadingSpinner from 'components/LoadingSpinner';
import AlertListItem from './ListItem';
import DeleteAlertModal from './Modals/DeleteAlert';
import PreviewAlertModal from './Modals/PreviewAlert';
import PaginationWrapper from 'components/PaginationWrapper';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { SafeTrim } from 'Helpers/safeTrim';
import { handleError } from 'Helpers/handleError';
import AlertListHeader from 'containers/Alerts/AlertsList/ListHeader';
import SourceListHeader from 'containers/Alerts/Sources/SourceListHeader';
import { CATEGORY_TYPE, COMPANY_TYPE, CONTENT_TYPE, SEARCH_TYPE } from '.';
import CompanyListHeader from '../Companies/CompanyListHeader';
import CategoryBasedListHeader from '../CategoryBased/CategoryBasedListHeader';

const List = (props) => {
    const { addFlashMessage } = useContext(FlashMessageContext);

    const {
        alerts,
        fetchingData,
        allLabels,
        fetchingLabels,
        refreshLabels,
        refreshAlerts,
        navOptions,
        alertFilters,
        setFiltersObject,
        isSortByName,
        activeSortProperty,
        activeSortDirection,
        handleSearchValueSubmit,
        searchValue,
        setSearchValue,
        submittingSearch,
        handleActiveFilterLabelSelect,
        activeFilterLabels,
        paginationCurrentPageNumber,
        totalPages,
        setSubmittingSearch,
        downloadFilteredCsv,
        downloadFullCsv,
        fetchingCsv,
        isSingleUser,
        alertType,
        searchTypeId,
        searchType,
    } = props;

    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToPreview, setItemToPreview] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');
    const [csvData, setCsvData] = useState([]);

    const toggleDeleteModal = (item) => {
        setDeleteModalOpen(!deleteModalOpen);
        if (!deleteModalOpen) {
            setItemToDelete(item);
        } else {
            setItemToDelete(null);
        }
    };

    const handleAlertPreview = (alert) => {
        setItemToPreview(alert);
    };

    const togglePreviewModal = () => {
        setPreviewModalOpen(!previewModalOpen);
    };

    useEffect(() => {
        alertFilters && determineMobileFiltersValue();
    }, []);

    useEffect(() => {
        itemToPreview && togglePreviewModal();
    }, [itemToPreview]);

    useEffect(() => {
        !previewModalOpen && setItemToPreview(null);
    }, [previewModalOpen]);

    const handlePageClick = (data) => {
        let filters = { ...alertFilters };
        filters.paginationCurrentPageNumber = data.selected + 1;
        filters.useCount = false;
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };

    const handleCaret = (value) => {
        if (activeSortProperty === value) {
            return activeSortDirection === 'asc' ? (
                <i className="fa fa-caret-up list-sort-caret list-sort-caret--active" />
            ) : (
                <i className="fa fa-caret-down list-sort-caret list-sort-caret--active" />
            );
        } else {
            return <i className="fa fa-caret-down list-sort-caret" />;
        }
    };

    const handleSortableHeaderClick = (value) => {
        let newFilters = { ...alertFilters };
        if (value === activeSortProperty) {
            activeSortDirection === 'asc' ? (newFilters.activeSortDirection = 'desc') : (newFilters.activeSortDirection = 'asc');
        } else {
            newFilters.activeSortProperty = value;
            newFilters.activeSortDirection = 'asc';
        }
        newFilters.paginationCurrentPageNumber = 1;
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', newFilters);
    };

    const handleClearSearchValue = async () => {
        let filters = { ...alertFilters };
        await setSubmittingSearch(true);
        filters.searchValue = '';
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };

    const buildCSVfile = (arr) => {
        const csvData = [['Alert Name', 'Alert Keyword', 'Labels', 'Created At', 'Updated At']];
        arr.map((item) => {
            csvData.push([
                SafeTrim(item.name),
                item.source.keyword ? SafeTrim(filterKeywordParam(item.source.keyword)) : 'RSS / XML',
                ...(item.labels.length > 0
                    ? [
                          sortAlphabetically(item.labels)
                              .map((label) => label.name)
                              .join(', '),
                      ]
                    : [' ']),
                moment(item.createdAt).format('MM/DD/YY'),
                moment(item.source.contentUpdatedAt).format('MM/DD/YY'),
            ]);
        });
        return csvData;
    };

    useEffect(() => {
        alerts.length && setCsvData(buildCSVfile(alerts));
    }, [alerts]);

    const filterKeywordParam = (keyword) => {
        if (keyword.slice(0, 8) === 'intitle:') {
            return keyword.slice(8, keyword.length);
        } else if (keyword.slice(0, 11) === 'allintitle:') {
            return keyword.slice(11, keyword.length);
        } else if (keyword.slice(0, 10) === 'allintext:') {
            return keyword.slice(10, keyword.length);
        } else {
            return keyword;
        }
    };

    const sortAlphabetically = (arr) => {
        return arr.sort((a, b) => a.name.localeCompare(b.name));
    };

    const handleMobileFilterSelect = (val) => {
        let splitValue = val.split('-');
        let filters = { ...alertFilters };
        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };

    const determineMobileFiltersValue = () => {
        let filterStr = alertFilters.activeSortProperty + '-' + alertFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    useEffect(() => {
        alertFilters && determineMobileFiltersValue();
    }, [alertFilters]);

    useEffect(() => {}, [activeFilterLabels]);

    const clearActiveLabels = () => {
        let filters = { ...alertFilters };
        filters.activeFilterLabels = [];
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject(isSortByName ? 'alertCatComp' : 'alerts', filters);
    };

    const getHeader = () => {
        switch (alertType) {
            case CONTENT_TYPE:
                return (
                    <SourceListHeader
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        handleClearSearchValue={handleClearSearchValue}
                        handleSearchValueSubmit={handleSearchValueSubmit}
                        submittingSearch={submittingSearch}
                        allLabels={allLabels}
                        activeFilterLabels={activeFilterLabels}
                        handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                        clearActiveLabels={clearActiveLabels}
                        downloadFullCsv={downloadFullCsv}
                        downloadFilteredCsv={downloadFilteredCsv}
                        fetchingCsv={fetchingCsv}
                        handleMobileFilterSelect={handleMobileFilterSelect}
                        activeMobileFilterValue={activeMobileFilterValue}
                    />
                );
            case COMPANY_TYPE:
                return (
                    <CompanyListHeader
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        handleClearSearchValue={handleClearSearchValue}
                        handleSearchValueSubmit={handleSearchValueSubmit}
                        submittingSearch={submittingSearch}
                        allLabels={allLabels}
                        activeFilterLabels={activeFilterLabels}
                        handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                        clearActiveLabels={clearActiveLabels}
                        downloadFullCsv={downloadFullCsv}
                        downloadFilteredCsv={downloadFilteredCsv}
                        fetchingCsv={fetchingCsv}
                        handleMobileFilterSelect={handleMobileFilterSelect}
                        activeMobileFilterValue={activeMobileFilterValue}
                    />
                );
            case CATEGORY_TYPE:
                return (
                    <CategoryBasedListHeader
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        handleClearSearchValue={handleClearSearchValue}
                        handleSearchValueSubmit={handleSearchValueSubmit}
                        submittingSearch={submittingSearch}
                        allLabels={allLabels}
                        activeFilterLabels={activeFilterLabels}
                        handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                        clearActiveLabels={clearActiveLabels}
                        downloadFullCsv={downloadFullCsv}
                        downloadFilteredCsv={downloadFilteredCsv}
                        fetchingCsv={fetchingCsv}
                        handleMobileFilterSelect={handleMobileFilterSelect}
                        activeMobileFilterValue={activeMobileFilterValue}
                        searchTypeId={searchTypeId}
                        searchType={searchType}
                        alertsLength={alerts.length}
                    />
                );
            case SEARCH_TYPE:
            default:
                return (
                    <AlertListHeader
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        handleClearSearchValue={handleClearSearchValue}
                        handleSearchValueSubmit={handleSearchValueSubmit}
                        submittingSearch={submittingSearch}
                        allLabels={allLabels}
                        activeFilterLabels={activeFilterLabels}
                        handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                        clearActiveLabels={clearActiveLabels}
                        downloadFullCsv={downloadFullCsv}
                        downloadFilteredCsv={downloadFilteredCsv}
                        fetchingCsv={fetchingCsv}
                        handleMobileFilterSelect={handleMobileFilterSelect}
                        activeMobileFilterValue={activeMobileFilterValue}
                    />
                );
        }
    };

    const getTHHeader = () => {
        switch (alertType) {
            case CONTENT_TYPE:
                return 'Type';
            case CATEGORY_TYPE:
                return 'Type';
            case COMPANY_TYPE:
                return 'Ticker';
            case SEARCH_TYPE:
            default:
                return 'Keyword';
        }
    };

    return (
        <div className="list alert-list">
            {fetchingData ? (
                <LoadingSpinner />
            ) : (
                <div>
                    {getHeader()}
                    {alerts.length < 1 ? (
                        <div>No results found</div>
                    ) : (
                        <div>
                            <Table className="responsive-table alert-list-table">
                                <tbody>
                                    <tr className="responsive-table header-row">
                                        <th
                                            className={
                                                'responsive-table__sortable-th alert-list-table__th responsive-table--long-th ' +
                                                (alertType === CATEGORY_TYPE ? 'w-35' : '')
                                            }
                                            onClick={() => handleSortableHeaderClick('alert.name')}>
                                            Title
                                            {handleCaret('alert.name')}
                                        </th>
                                        <th
                                            className={
                                                'responsive-table__sortable-th responsive-table--long-th' +
                                                (alertType === CATEGORY_TYPE ? 'w-150' : '')
                                            }
                                            onClick={() => handleSortableHeaderClick('source.keyword')}>
                                            {' '}
                                            {getTHHeader()}
                                            {handleCaret('source.keyword')}
                                        </th>
                                        {!isSingleUser && (
                                            <th className={'responsive-table--label-th' + (alertType === CATEGORY_TYPE ? 'w-150' : '')}>Labels</th>
                                        )}
                                        <th
                                            className="responsive-table__sortable-th alert-list-table__th--assigned-to responsive-table--number-th"
                                            onClick={() => handleSortableHeaderClick('alert.countAssignedUsers')}>
                                            Assigned To
                                            {handleCaret('alert.countAssignedUsers')}
                                        </th>
                                        <th className="responsive-table__sortable-th" onClick={() => handleSortableHeaderClick('alert.createdAt')}>
                                            Created At
                                            {handleCaret('alert.createdAt')}
                                        </th>
                                        <th
                                            className="responsive-table__sortable-th"
                                            onClick={() => handleSortableHeaderClick('source.contentUpdatedAt')}>
                                            Updated At
                                            {handleCaret('source.contentUpdatedAt')}
                                        </th>
                                        <th>Assignment</th>
                                        <th className="responsive-table--number-th">Dig Deeper</th>
                                        <th
                                            className="responsive-table__sortable-th responsive-table--number-th"
                                            onClick={() => handleSortableHeaderClick('alert.countClicks')}>
                                            Clicks
                                            {handleCaret('alert.countClicks')}
                                        </th>
                                        <th>Actions</th>
                                    </tr>
                                    {alerts.map((alert, index) => {
                                        return (
                                            <AlertListItem
                                                key={alert.id || index}
                                                alert={alert}
                                                toggleDeleteModal={toggleDeleteModal}
                                                addFlashMessage={addFlashMessage}
                                                refreshAlerts={refreshAlerts}
                                                allLabels={allLabels}
                                                fetchingLabels={fetchingLabels}
                                                refreshLabels={refreshLabels}
                                                handleAlertPreview={handleAlertPreview}
                                                isSingleUser={isSingleUser}
                                                alertType={alertType}
                                            />
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <PaginationWrapper
                                totalPages={totalPages}
                                handlePageClick={handlePageClick}
                                forcePage={paginationCurrentPageNumber - 1}
                            />
                        </div>
                    )}
                </div>
            )}
            {deleteModalOpen && (
                <DeleteAlertModal
                    isOpen={deleteModalOpen}
                    toggle={toggleDeleteModal}
                    handleError={handleError}
                    item={itemToDelete}
                    addFlashMessage={addFlashMessage}
                    refreshAlerts={refreshAlerts}
                />
            )}
            {previewModalOpen && (
                <PreviewAlertModal
                    isOpen={previewModalOpen}
                    toggle={togglePreviewModal}
                    alert={itemToPreview}
                    handleError={handleError}
                    alertType={alertType}
                />
            )}
        </div>
    );
};

export default List;
