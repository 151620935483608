import React from 'react';

import ReactPaginate from 'react-paginate';

const PaginationWrapper = ({totalPages, handlePageClick, forcePage}) => {
    if(totalPages <= 1) return null;
    return (
        <div className="mt-3">
            <ReactPaginate
                previousLabel={'«'}
                nextLabel={'»'}
                breakLabel={'...'}
                breakClassName={'pagination__item'}
                breakLinkClassName={'pagination__link'}
                pageCount={totalPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={window.innerWidth > 600 ? 10 : window.innerWidth > 350 ? 3 : 2}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                pageClassName={'pagination__item'}
                pageLinkClassName={'pagination__link'}
                activeClassName={'pagination__item--active'}
                activeLinkClassName={'pagination__link--active'}
                previousClassName={'pagination__item'}
                previousLinkClassName={'pagination__link'}
                nextClassName={'pagination__item'}
                nextLinkClassName={'pagination__link'}
                forcePage={forcePage}
            />
        </div>
    );
};

export default PaginationWrapper;
