export const filterByValue = (arr, propertyToFilterBy, searchValue) => {
    let newArr = [...arr];
    if (typeof propertyToFilterBy === 'string') {
        newArr = newArr.filter(item => {
            if (item[propertyToFilterBy]) {
                return item[propertyToFilterBy].toLowerCase().includes(searchValue.toLowerCase());
            }
        })
    } else if (propertyToFilterBy.constructor === Array) {
        newArr = newArr.filter(item => {
            let flag = false;
            propertyToFilterBy.forEach((ind) => {
                if (item[ind] !== null && item[ind].toLowerCase().includes(searchValue.toLowerCase())) {
                    flag = true;
                }
            });
            if (flag) {
                return item
            }
        })
    }
    return newArr;
}

