import React from 'react';

import {Input} from 'reactstrap';

import './style.scss';

const SearchInput = (props) => {
    const {value, onChange, placeholder, onClear, classNames, extraParams, onPressEnter, list, iconClassNames} = props;

    const handleChange = (e) => {
        if(extraParams) {
            onChange(extraParams, e.target.value);
        } else {
            onChange(e.target.value);
        }
    };

    return (
        <div className={'search-input-container ' + (list ? 'search-input-container--list ' : '')}>
            <Input
                placeholder={placeholder}
                type="text"
                // className="form-control"
                autoComplete={'off'}
                className={'search-input ' + (value.length > 0 ? 'search-input--extra-padding ' : '') + (classNames && classNames.join(' '))}
                value={value}
                onChange={(e) => handleChange(e)}
            />
            {/*<span*/}
            {/*    className={'search-input__icon search-input__icon--close ' +*/}
            {/*    (iconClassNames && iconClassNames.join(' '))}*/}
            {/*    onClick={() => onClear()}>×</span>*/}
            <div className="search-input__icon-container">
                {value.length > 0 &&
                <i
                    className={'fa fa-times-circle search-input__icon search-input__icon--close'}
                    onClick={() => onClear()}
                />
                }
                <i
                    className={'fa fa-search search-input__icon ' + (iconClassNames && iconClassNames.join(' '))}
                />
            </div>
        </div>
    );
};

export default SearchInput;
