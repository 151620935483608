import React from 'react';
import {Button} from 'reactstrap';

const SpinnerButtonSquare = (props) => {
    const {type, submitting, title, disabled, block, color, onClick, className, defaultSize} = props;

    return (
        <Button
            color={color}
            style={{
                width: defaultSize ? 'auto' : '27.03px',
                height: defaultSize ? 'auto' : '22px',
            }}
            type="button"
            className={className}
            disabled={disabled}
            block={block}
            onClick={onClick}
        >
            {submitting ? <i className="fa fa-spinner fa-spin"/> : title}
        </Button>
    );
}

export default SpinnerButtonSquare;