import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Collapse, Button, CardBody, Card, CardHeader, Input, UncontrolledTooltip} from 'reactstrap';
import Alert from './Alert';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import SpinnerButton from 'components/SpinnerButton/Small';
import {deleteNewsletterSection, editNewsletterSection} from 'utils/api/newslettersAPI';
import CommentForm from 'containers/Newsletters/ManageContent/Desktop/CommentForm';
import {sortObjects} from 'Helpers/sortObjects';
import htmlToDraft from 'html-to-draftjs';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const Section = (props) => {
    const {
        section, index, assignAlertToSection, removeAllAlertsFromSection,
        newsletterId, refreshNewsletter, addFlashMessage, handleError,
        sectionDragInfo,
    } = props;
    const [alertListOpen, setAlertListOpen] = useState(false);
    const [commentFormOpen, setCommentFormOpen] = useState(false);
    const [titleFormOpen, setTitleFormOpen] = useState(false);
    const [commentValue, setCommentValue] = useState(EditorState.createEmpty());
    const [titleValue, setTitleValue] = useState('');
    const [deletingSection, setDeletingSection] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        setTitleValue(section.name);
    }, []);

    useEffect(() => {
        if(section && section.comment) {
            const blocksFromHtml = htmlToDraft(section.comment);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setCommentValue(editorState)
        } else {
            setCommentValue(EditorState.createEmpty());
        }

    }, [section])

    const toggleAlertListOpen = () => {
        setAlertListOpen(!alertListOpen);
    };

    const toggleCommentFormOpen = (e) => {
        e.stopPropagation();
        setCommentFormOpen(!commentFormOpen);
    };

    const handleCommentSubmit = async() => {
        try {
            let commentValueAsHtml;
            if(commentValue) {
                const rawContentState = convertToRaw(commentValue.getCurrentContent());
                commentValueAsHtml = draftToHtml(rawContentState);
            }
            let trimmedHtmlTagsValue = commentValueAsHtml?.replace(new RegExp('<[^>]*>', 'g'), '')?.trim();
            const params = {
                name: section.name,
                comment: trimmedHtmlTagsValue.length ? commentValueAsHtml : '',
            };
            await editNewsletterSection(newsletterId, section.id, params);
            refreshNewsletter();
            setCommentFormOpen(false);
            addFlashMessage('success', 'Comment successfully saved');
        } catch(err) {
            handleError(err);
        }
    };

    const deleteSection = async(e, sectionId) => {
        e.stopPropagation();
        try {
            setDeletingSection(true);
            await deleteNewsletterSection(newsletterId, sectionId);
            refreshNewsletter();
            // setDeletingSection(false);
        } catch(err) {
            setDeletingSection(false);
            handleError(err);
        }
    };

    const toggleTitleForm = () => {
        setTitleFormOpen(!titleFormOpen);
    };

    useLayoutEffect(() => {
        if(titleFormOpen) {
            const input = document.getElementById(`sectionTitleForm${section.id}`);
            input.focus();
        }
    }, [titleFormOpen, inputRef]);

    const submitTitleForm = async() => {
        try {
            let commentValueAsHtml;
            if(commentValue) {
                const rawContentState = convertToRaw(commentValue.getCurrentContent());
                commentValueAsHtml = draftToHtml(rawContentState);
            }
            let trimmedHtmlTagsValue = commentValueAsHtml?.replace(new RegExp('<[^>]*>', 'g'), '')?.trim();
            const params = {
                name: titleValue,
                comment: trimmedHtmlTagsValue.length ? commentValueAsHtml : '',
            };
            await editNewsletterSection(newsletterId, section.id, params);
            refreshNewsletter();
            toggleTitleForm();
        } catch(err) {
            console.log(err);
            if(err.errors.name && err.errors.name) {
                if(err.errors.name[0].code === 'NOT_UNIQUE_ERROR') {
                    addFlashMessage('danger', 'This section name is already in use for this newsletter');
                } else {
                    addFlashMessage('danger', 'Unable to save section name');
                }
            } else {
                addFlashMessage('danger', 'Unable to save section name');
            }
        }
    };

    const handlePressEnter = (e) => {
        if(e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            submitTitleForm();
        }
    };


    return (
        <Draggable draggableId={String(section.id)} index={index} type="section">
            {(draggableProvided, snapshot) => (
                <div
                    // className="newsletter-section"
                    className="mt-2"
                    {...draggableProvided.draggableProps}
                    ref={draggableProvided.innerRef}
                >
                    <Droppable droppableId={String(section.id)} type="alert">
                        {(provided, snapshot) => (
                            <div
                                className={'newsletter-section ' +
                                (snapshot.isDraggingOver ?
                                    (sectionDragInfo && sectionDragInfo.destinationId === sectionDragInfo.sourceId ?
                                        'section-is-dragging-over-source'
                                        :
                                        'section-is-dragging-over')
                                    : '')
                                }
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                // className="newsletter-section"
                            >
                                <div className="section-collapse d-flex flex-column">
                                    <CardHeader
                                        className={'section-collapse-toggle newsletter-section__header ' +
                                        (snapshot.isDragging ? 'newsletter-section__header--dragging' : '')}
                                        color="primary"
                                        onClick={toggleAlertListOpen}
                                    >
                                        <div className="newsletter-section__block-1">
                                            <i className={'fa fa-angle-right ' + (alertListOpen ? 'rotate-90-down' : '')}/>
                                            <div className="newsletter-section__title ml-2">
                                                {titleFormOpen ?
                                                    <Input
                                                        className="newsletter-section__title-form"
                                                        name="sectionTitle"
                                                        value={titleValue}
                                                        id={`sectionTitleForm${section.id}`}
                                                        onBlur={submitTitleForm}
                                                        onKeyDown={(e) => handlePressEnter(e)}
                                                        autofocus
                                                        ref={inputRef}
                                                        onChange={(e) => setTitleValue(e.target.value)}
                                                    />
                                                    :
                                                    <span>
                                                    {section.name.length > 30 ? (section.name.substring(0, 30) + '...') : section.name}
                                                </span>
                                                }
                                            </div>
                                            <i className="fa fa-edit ml-2 dark-gray" onClick={toggleTitleForm}/>
                                            <i
                                                className={'fa fa-comment ml-2 ' + (section.comment && section.comment.length > 0 ? 'theme-text-color' : 'dark-gray')}
                                                onClick={(e) => toggleCommentFormOpen(e)}
                                            />
                                            &nbsp;
                                            <div className="newsletter-section__item-cnt">
                                                {section.alerts.length === 1 ? section.alerts.length + ' Source' : section.alerts.length + ' Sources'}
                                            </div>
                                        </div>
                                        <div className="newsletter-section__block-2">
                                            <Button
                                                color="secondary"
                                                id={'clear-alerts-' + section.id}
                                                className="newsletter-section__header-btn newsletter-section__header-btn--clear"
                                                onClick={(e) => removeAllAlertsFromSection(e, section.id)}
                                                disabled={section.alerts.length < 1}
                                            >
                                                Clear
                                            </Button>
                                            <UncontrolledTooltip
                                                placement="bottom"
                                                delay={{show: 200, hide: 0}}
                                                target={'clear-alerts-' + section.id}
                                            >
                                                Remove all alerts from this section
                                            </UncontrolledTooltip>
                                            <SpinnerButton
                                                color="danger"
                                                className="newsletter-section__header-btn"
                                                onClick={(e) => deleteSection(e, section.id)}
                                                disabled={deletingSection}
                                                submitting={deletingSection}
                                                title={<i className="fa fa-trash"/>}
                                            />
                                            <i className="fa fa-bars ml-2" {...draggableProvided.dragHandleProps}/>
                                        </div>
                                    </CardHeader>
                                    <Collapse isOpen={commentFormOpen}>
                                        <CommentForm
                                            value={commentValue}
                                            // disabled={commentValue ? commentValue.length > 500 : true}
                                            handleValueChange={setCommentValue}
                                            onSubmit={handleCommentSubmit}
                                        />
                                    </Collapse>
                                    <Collapse isOpen={alertListOpen}>
                                        <CardBody className="section-collapse-body p-0">
                                            <div className="section-body">
                                                <div
                                                    className="section-list"
                                                >
                                                    {section.alerts.length === 0 && (
                                                        <div className="empty-section-item">
                                                            No Alerts have been added to this section yet
                                                        </div>
                                                    )}
                                                    {section.alerts.length > 0 && alertListOpen && sortObjects(section.alerts, 'name', 'asc').map((alert, i) => {
                                                        return (
                                                            <Alert
                                                                key={section.id + '-' + alert.id}
                                                                alert={alert}
                                                                index={i}
                                                                assignAlertToSection={assignAlertToSection}
                                                                sectionId={section.id}
                                                            />
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Collapse>
                                </div>
                            </div>
                        )}
                    </Droppable>
                </div>
            )}
        </Draggable>
    );
};

export default Section;


// import React, {useEffect, useState, useRef, useLayoutEffect} from 'react';
//
// import {CardHeader, CardBody, Button, UncontrolledTooltip, Collapse, Input} from 'reactstrap';
// import {Draggable, Droppable} from 'react-beautiful-dnd';
//
// import Alert from './Alert';
// import SpinnerButton from 'components/SpinnerButton/Small';
// import CommentForm from 'containers/Newsletters/ManageContent/Desktop/CommentForm';
// import {deleteNewsletterSection, editNewsletterSection} from 'utils/api/newslettersAPI';
//
// const Section = (props) => {
//     const {
//         section, index, assignAlertToSection, removeAllAlertsFromSection,
//         newsletterId, refreshNewsletter, addFlashMessage, handleError,
//     } = props;
//     const [sectionListOpen, setSectionListOpen] = useState(false);
//     const [commentFormOpen, setCommentFormOpen] = useState(false);
//     const [titleFormOpen, setTitleFormOpen] = useState(false);
//     const [commentValue, setCommentValue] = useState('');
//     const [titleValue, setTitleValue] = useState('');
//     const [deletingSection, setDeletingSection] = useState(false);
//     const inputRef = useRef(null);
//
//     useEffect(() => {
//         setCommentValue(section.comment);
//         setTitleValue(section.name);
//     }, []);
//
//     useEffect(() => {
//         if(!commentFormOpen) {
//             commentValue !== section.comment && setCommentValue(section.comment);
//         }
//     }, [commentFormOpen]);
//
//     const toggleSectionListOpen = () => {
//         setSectionListOpen(!sectionListOpen);
//     };
//
//     const toggleCommentFormOpen = (e) => {
//         e.stopPropagation();
//         setCommentFormOpen(!commentFormOpen);
//     };
//
//     const handleCommentSubmit = async() => {
//         try {
//             const params = {
//                 name: section.name,
//                 comment: commentValue,
//             };
//             await editNewsletterSection(newsletterId, section.id, params);
//             refreshNewsletter();
//             addFlashMessage('success', 'Comment successfully saved');
//         } catch(err) {
//             handleError(err);
//         }
//     };
//
//     const deleteSection = async(e, sectionId) => {
//         e.stopPropagation();
//         try {
//             setDeletingSection(true);
//             await deleteNewsletterSection(newsletterId, sectionId);
//             refreshNewsletter();
//             // setDeletingSection(false);
//         } catch(err) {
//             setDeletingSection(false);
//             handleError(err);
//         }
//     };
//
//     const toggleTitleForm = () => {
//         setTitleFormOpen(!titleFormOpen);
//     };
//
//     useLayoutEffect(() => {
//         if (titleFormOpen) {
//             const input = document.getElementById(`sectionTitleForm${section.id}`);
//             input.focus();
//         }
//     }, [titleFormOpen, inputRef]);
//
//     const submitTitleForm = async() => {
//         try {
//             const params = {
//                 name: titleValue,
//             };
//             await editNewsletterSection(newsletterId, section.id, params);
//             refreshNewsletter();
//             toggleTitleForm();
//         } catch(err) {
//             console.log(err);
//             if(err.errors.name && err.errors.name) {
//                 if(err.errors.name[0].code === 'NOT_UNIQUE_ERROR') {
//                     addFlashMessage('danger', 'This section name is already in use for this newsletter');
//                 } else {
//                     addFlashMessage('danger', 'Unable to save section name');
//                 }
//             } else {
//                 addFlashMessage('danger', 'Unable to save section name');
//             }
//         }
//     };
//
//     const handlePressEnter = (e) => {
//         if(e.key === 'Enter') {
//             e.preventDefault();
//             e.stopPropagation();
//             submitTitleForm();
//         }
//     };
//
//     return (
//         <Draggable draggableId={String(section.id)} index={index} type="section">
//             {(draggableProvided, snapshot) => (
//                 <div
//                     className="newsletter-section"
//                     {...draggableProvided.draggableProps}
//                     ref={draggableProvided.innerRef}
//                 >
//                     <Droppable droppableId={String(section.id)} type="alert">
//                         {(provided, snapshot) => (
//                             <div
//                                 className={(snapshot.isDraggingOver ? 'newsletter-section--is-dragging-over' : '')}
//                                 ref={provided.innerRef}
//                                 {...provided.droppableProps}
//                             >
//                                 <CardHeader
//                                     className={'section-collapse-toggle newsletter-section__header ' +
//                                     (snapshot.isDragging ? 'newsletter-section__header--dragging' : '')}
//                                     onClick={toggleSectionListOpen}
//                                 >
//                                     <div className="newsletter-section__block-1">
//                                         <i className={'fa fa-angle-right ' + (sectionListOpen ? 'rotate-90-down' : '')}/>
//                                         <div className="newsletter-section__title ml-2">
//                                             {titleFormOpen ?
//                                                 <Input
//                                                     className="newsletter-section__title-form"
//                                                     name="sectionTitle"
//                                                     value={titleValue}
//                                                     id={`sectionTitleForm${section.id}`}
//                                                     onBlur={submitTitleForm}
//                                                     onKeyDown={(e) => handlePressEnter(e)}
//                                                     autofocus
//                                                     ref={inputRef}
//                                                     onChange={(e) => setTitleValue(e.target.value)}
//                                                 />
//                                                 :
//                                                 <span>
//                                                     {section.name}
//                                                     {/*{titleValue}*/}
//                                                 </span>
//                                             }
//                                         </div>
//                                         <i className="fa fa-edit ml-2 dark-gray" onClick={toggleTitleForm}/>
//                                         <i
//                                             className={'fa fa-comment ml-2 ' + (section.comment && section.comment.length > 0 ? 'theme-text-color' : 'dark-gray')}
//                                             onClick={(e) => toggleCommentFormOpen(e)}
//                                         />
//                                         &nbsp;
//                                         <div className="newsletter-section__item-cnt">
//                                             {section.alerts.length === 1 ? section.alerts.length + ' Alert' : section.alerts.length + ' Alerts'}
//                                         </div>
//                                     </div>
//                                     <div className="newsletter-section__block-2">
//                                         <Button
//                                             color="secondary"
//                                             id={'clear-alerts-' + section.id}
//                                             className="newsletter-section__header-btn newsletter-section__header-btn--clear"
//                                             onClick={(e) => removeAllAlertsFromSection(e, section.id)}
//                                             disabled={section.alerts.length < 1}
//                                         >
//                                             Clear Alerts
//                                         </Button>
//                                         <UncontrolledTooltip
//                                             placement="bottom"
//                                             delay={{show: 200, hide: 0}}
//                                             target={'clear-alerts-' + section.id}
//                                         >
//                                             Remove all alerts from this section
//                                         </UncontrolledTooltip>
//                                         <SpinnerButton
//                                             color="danger"
//                                             className="newsletter-section__header-btn"
//                                             onClick={(e) => deleteSection(e, section.id)}
//                                             disabled={deletingSection}
//                                             submitting={deletingSection}
//                                             title={<i className="fa fa-trash"/>}
//                                         />
//                                         <i className="fa fa-bars ml-2" {...draggableProvided.dragHandleProps}/>
//                                     </div>
//                                 </CardHeader>
//
//                                 <CardBody className="p-0">
//                                     <Collapse isOpen={commentFormOpen}>
//                                         <CommentForm
//                                             value={commentValue}
//                                             disabled={commentValue ? commentValue.length > 500 : true}
//                                             handleValueChange={setCommentValue}
//                                             onSubmit={handleCommentSubmit}
//                                         />
//                                     </Collapse>
//                                     <Collapse isOpen={sectionListOpen}>
//                                         {section.alerts.length > 0 ?
//                                             <div className="newsletter-section__alert-list">
//                                                 {section.alerts.map((alert, index) => (
//                                                     <Alert
//                                                         key={section.id + '-' + alert.id}
//                                                         alert={alert}
//                                                         index={index}
//                                                         assignAlertToSection={assignAlertToSection}
//                                                         sectionId={section.id}
//                                                     />
//                                                 ))}
//                                             </div>
//                                             :
//                                             <div className="newsletter-section-alert newsletter-section-alert--no-hover">
//                                                 No Alerts have been added to this section yet
//                                             </div>
//                                         }
//                                         {provided.placeholder}
//                                     </Collapse>
//                                 </CardBody>
//                             </div>
//                         )}
//                     </Droppable>
//                 </div>
//             )}
//         </Draggable>
//     );
// };
//
// export default Section;
