import React, {useState, useRef, useEffect} from 'react';
import {Input} from 'reactstrap';
import useOnClickOutside from 'hooks/clickOutside';
import {getWikiResults} from 'utils/api/alertsAPI';
import './style.scss';
import SearchInput from 'components/SearchInput/SearchInputWithSubmit';

const WikiAutocomplete = (props) => {
    const {placeholder, handleSearchValue, value, searchValueHasError, setSearchValueHasError, submitting, error, onSubmit} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [query, setQuery] = useState('');
    const ref = useRef();
    useOnClickOutside(ref, () => setDropdownOpen(false));

    const handleChange = async(e) => {
        const query = e;
        setIsLoading(true);
        setQuery(query);
        handleSearchValue(query);

        if(query.length > 2) {
            const options = await getWikiResults(query);
            setItems(options.map(item => ({'label': item})));
            if(!submitting) {
                setDropdownOpen(options.length > 0);
            }
        }
        setIsLoading(false);
    };

    const handleClear = () => {
        setQuery('');
        setItems([]);
        setDropdownOpen(false);
        props.onClear();
    };

    const onAutocompleteSelect = (term) => {
        props.handleAutocompleteSelect(term);
        setDropdownOpen(false);
    };

    const handlePressEnter = (e) => {
        if (e.key === 'Enter') {
            props.onPressEnter(e);
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        if(submitting) dropdownOpen && setDropdownOpen(false);
    }, [submitting])

    return (
        <div className="wiki-autocomplete"
             ref={ref}
        >
            <SearchInput
                placeholder={placeholder}
                className={'internal-search-textarea ' + (searchValueHasError ? 'input-has-error' : '')}
                style={{
                    width: '100%',
                    paddingRight: '55px',
                    border: (error && error.errors && error.errors.hasOwnProperty('keyword')) ? '1px solid red' : ''
                }}
                value={value}
                onChange={handleChange}
                onKeyDown={handlePressEnter}
                onClear={handleClear}
                onSubmit={onSubmit}
            />
            {/*<Input*/}
            {/*    placeholder={placeholder}*/}
            {/*    className={'internal-search-textarea ' + (searchValueHasError ? 'input-has-error' : '')}*/}
            {/*    style={{*/}
            {/*        width: '100%',*/}
            {/*        paddingRight: '55px',*/}
            {/*        border: (error && error.errors && error.errors.hasOwnProperty('keyword')) ? '1px solid red' : ''*/}
            {/*    }}*/}
            {/*    value={value}*/}
            {/*    onChange={handleChange}*/}
            {/*    onKeyDown={handlePressEnter}*/}
            {/*/>*/}
            <div style={{position: 'relative', width: '100%'}}>
                <div className="textarea-autocomplete"
                     style={{display: (dropdownOpen && !searchValueHasError) ? 'block' : 'none'}}>
                    {items.map(item => (
                        <div className="textarea-autocomplete-list-item"
                             key={item.label}
                             onClick={() => onAutocompleteSelect(item.label)}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WikiAutocomplete;
