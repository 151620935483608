import React from 'react';

const LoadingSpinner = (props) => {
    return (
        <div className="d-flex align-items-center" style={{padding: props.padding ? '1rem' : '0'}}>
            <i className="fa fa-spinner fa-spin" style={{fontSize: '1.2rem', marginRight: '1rem', color: '#6f7071'}}/>
            {props.text && <span>{props.text}...</span>}
        </div>
    );
};

export default LoadingSpinner;
