import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import TitleForm from './../InternalAlerts/TitleForm';
import Preview from './Preview';
import {
    addOzmosysCategorizedAlert,
    checkPossibleToAddAlert,
    editOzmosysCategorizedAlert,
    previewOzmosysCategorizedAlert,
    getAiCategories,
    getGeoLocations,
} from 'utils/api/alertsAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import './style.scss';

const OzmosysCategorizedAlertForm = (props) => {
    let history = useHistory();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);

    const { isEditForm, foundAlert } = props;

    const [titleValue, setTitleValue] = useState('');
    const [additionalTitleValue, setAdditionalTitleValue] = useState('');
    const [isDisableTitle, setIsDisableTitle] = useState(false);
    const [preview, setPreview] = useState(null);
    const [titleFormVisible, setTitleFormVisible] = useState(false);
    const [searchSubmitting, setSearchSubmitting] = useState(!!isEditForm);
    const [submitting, setSubmitting] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [activePreviewSortValue, setActivePreviewSortValue] = useState(null);
    const [activeCountryFilter, setActiveCountryFilter] = useState(null);
    const [activeRegionFilter, setActiveRegionFilter] = useState(null);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [activeAdditionalCategoryFilter, setActiveAdditionalCategoryFilter] = useState(null);
    const [activeAdditionalCategoryName, setActiveAdditionalCategoryName] = useState('');
    const [activeSentimentFilter, setActiveSentimentFilter] = useState(null);
    const [typeOfSource, setTypeOfSource] = useState('all');
    const [updatingPreview, setUpdatingPreview] = useState(false);
    const [errors, setErrors] = useState(null);
    const [preSubmitError, setPreSubmitError] = useState(null);
    const [loadingSpinnerText, setLoadingSpinnerText] = useState('');
    const [messageArr, setMessageArr] = useState(['Fetching News', 'Applying AI models', 'Applying relevance', 'Sorting & filtering data']);
    const [additionalCategories, setAdditionalCategories] = useState([]);
    const [isInitialTypeLegal, setIsInitialTypeLegal] = useState(false);

    const getGeoLocationsForFilter = async () => {
        try {
            const geoLocations = await getGeoLocations();
            geoLocations && setCountries(geoLocations);
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        isEditForm && getFoundAlertValues() && fetchCategories();
        getGeoLocationsForFilter();
    }, []);

    useEffect(() => {
        if (countries?.length && activeCountryFilter) {
            const regions = countries.find((item) => item.countryName === activeCountryFilter)?.regions;
            regions?.length && setRegions(regions);
        }
    }, [activeCountryFilter, countries]);

    useEffect(() => {
        if (!selectedCategory) return;
        handleCreatePreview();
    }, [
        selectedCategory,
        activePreviewSortValue,
        activeCountryFilter,
        activeRegionFilter,
        activeAdditionalCategoryFilter,
        activeSentimentFilter,
        typeOfSource,
    ]);

    useEffect(() => {
        setLoadingSpinnerText(messageArr[0]);
        let messageIndex = 0;

        const interval = searchSubmitting
            ? setInterval(() => {
                  messageIndex < messageArr.length - 1 && messageIndex++;
                  setLoadingSpinnerText(messageArr[messageIndex]);
              }, 6000)
            : null;

        return () => clearInterval(interval);
    }, [searchSubmitting]);

    useEffect(() => {
        activeAdditionalCategoryFilter &&
            setActiveAdditionalCategoryName(additionalCategories.find((cat) => cat.id === Number(activeAdditionalCategoryFilter))?.name ?? '');
    }, [activeAdditionalCategoryFilter]);

    useEffect(() => {
        const additionalCatName = additionalCategories.find((item) => item.id?.toString() === activeAdditionalCategoryFilter?.toString())?.name;
        const categoryName = additionalCatName?.replace(' (Topic)', '')?.replace(' (Industry)', '')?.replace(' (Practice)', '');
        const activeCountry = activeCountryFilter ? countries?.filter((country) => country?.countryName === activeCountryFilter) : [];
        const activeRegion = activeRegionFilter ? regions?.filter((region) => region?.name === activeRegionFilter) : [];

        setAdditionalTitleValue(
            foundAlert?.source?.newsCategory?.name +
                (typeOfSource === 'legal' ? ' :: Legal only' : '') +
                (activeCountry?.length && activeCountry[0]?.countryName ? ` :: ${activeCountry[0].countryName}` : '') +
                (activeRegion?.length && activeCountry?.length && activeRegion[0]?.name ? `/${activeRegion[0].name}` : '') +
                (categoryName ? ` :: ${categoryName}` : '')
        );
    }, [
        activeAdditionalCategoryFilter,
        additionalCategories,
        activeCountryFilter,
        activeRegionFilter,
        typeOfSource,
        foundAlert,
        preview,
        countries,
        regions,
    ]);

    const getFoundAlertValues = async () => {
        if (foundAlert.source?.additionalNewsCategory?.id) {
            setTitleValue(foundAlert.name);
            setAdditionalTitleValue(foundAlert.name);
            setIsDisableTitle(true);
        } else {
            setTitleValue(foundAlert.name);
            setAdditionalTitleValue('');
            setIsDisableTitle(false);
        }

        setTitleFormVisible(true);
        setActiveCountryFilter(foundAlert?.source?.geoLocation?.country || '');
        setActiveRegionFilter(foundAlert?.source?.geoLocation?.region || '');
        setSelectedCategory(foundAlert.source?.newsCategory.id);
        setTypeOfSource(foundAlert.source?.legalOnly ? 'legal' : 'all');
        setIsInitialTypeLegal(!!foundAlert.source?.legalOnly);

        if (foundAlert.source?.additionalNewsCategory) {
            setActiveAdditionalCategoryFilter(foundAlert.source.additionalNewsCategory.id);
        }
        if (foundAlert.source?.sentiment) {
            setActiveSentimentFilter(foundAlert.source.sentiment);
        }
    };

    const handleCreatePreview = async () => {
        try {
            if (preSubmitError) return;
            errors && setErrors(null);
            setSearchSubmitting(true);
            let sortValue;
            let sortDirection;
            switch (activePreviewSortValue) {
                case 'date-new':
                    sortValue = 'created_at';
                    sortDirection = 'DESC';
                    break;
                case 'date-old':
                    sortValue = 'created_at';
                    sortDirection = 'ASC';
                    break;
                case 'rel-low':
                    sortValue = 'rank';
                    sortDirection = 'ASC';
                    break;
                case 'rel-high':
                    sortValue = 'rank';
                    sortDirection = 'DESC';
                    break;
                default:
                    sortValue = 'created_at';
                    sortDirection = 'DESC';
                    break;
            }
            const params = {
                sortValue,
                sortDirection,
                newsCategory: selectedCategory,
                additionalNewsCategory: activeAdditionalCategoryFilter,
                sentiment: activeSentimentFilter,
                useAllSources: typeOfSource === 'all',
                legalOnly: typeOfSource === 'legal',
                geoLocation: { country: activeCountryFilter || null, region: activeRegionFilter || null },
            };
            const preview = await previewOzmosysCategorizedAlert(params);
            setTitleFormVisible(true);
            setPreview(preview.content);
            setSearchSubmitting(false);
        } catch (err) {
            setSearchSubmitting(false);
            setTitleFormVisible(false);
            setPreview(null);
            handleError(err, setErrors);
        }
    };

    const handleSubmit = async () => {
        if (preSubmitError) return;

        setErrors(null);
        const saveAsNewSource = isEditForm && typeOfSource === 'legal' && !isInitialTypeLegal;
        const params = {
            name: titleValue,
            newsCategory: selectedCategory,
            additionalNewsCategory: activeAdditionalCategoryFilter,
            sentiment: activeSentimentFilter,
            geoLocation: { country: activeCountryFilter || null, region: activeRegionFilter || null },
            useAllSources: saveAsNewSource ? false : typeOfSource === 'all',
            legalOnly: saveAsNewSource ? true : typeOfSource === 'legal',
        };
        try {
            setSubmitting(true);
            const searchTypeId = props.foundAlert.source.newsCategory?.searchTypeId ?? 1;
            const searchType = { 1: 'practices', 2: 'industries', 3: 'topics' };

            if (props.isEditForm) {
                await editOzmosysCategorizedAlert(props.foundAlert.id, params);
                addFlashMessage('success', 'Alert successfully updated');
                history.push(`/account/manage-category-based/${searchType[searchTypeId]}`);
            } else {
                let isPossibleToAdd = await checkPossibleToAddAlert();
                if (isPossibleToAdd) {
                    await addOzmosysCategorizedAlert(params);
                    setSubmitting(false);
                    addFlashMessage('success', 'Alert successfully created');
                    history.push(`/account/manage-category-based/${searchType[searchTypeId]}`);
                } else {
                    addFlashMessage(
                        'danger',
                        "You've reached the limit of allowed number of alerts for your" +
                            ' account.\n Contact Customer Care team for more information.'
                    );
                }
            }
        } catch (err) {
            setSubmitting(false);

            if (err.code === 'VALIDATION_FAILED' && err.message.slice(0, 17) === 'Alert with source') {
                let errObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        keyword: [
                            {
                                message: 'An alert with this keyword already exists.',
                                code: 'IS_BLANK_ERROR',
                                payload: null,
                            },
                        ],
                    },
                };

                addFlashMessage('danger', 'Alert with these parameters already exists');
                handleError(errObj, setErrors);
            } else {
                const checkExistence = (err) => {
                    for (let key in err) {
                        if (err.hasOwnProperty(key)) {
                            if (typeof err[key] === 'object') {
                                if (checkExistence(err[key], 'already exist')) {
                                    return true;
                                }
                            } else if (err[key].toString().includes('already exist')) {
                                return true;
                            }
                        }
                    }
                    return false;
                };

                if (checkExistence(err)) {
                    addFlashMessage('danger', 'Alert with these parameters already exists');
                } else {
                    if (err?.hasOwnProperty('errors') && err?.errors?.hasOwnProperty('parentId')) {
                        typeof err?.errors?.parentId === 'string' && addFlashMessage('danger', err.errors.parentId);
                    } else {
                        addFlashMessage('danger', 'Unable to complete your request at this time');
                    }
                }

                handleError(err, setErrors);
            }
        }
    };

    const fetchCategories = async () => {
        try {
            const categoriesData = await getAiCategories(foundAlert.source.newsCategory.searchTypeId);
            setAdditionalCategories(categoriesData);
        } catch (err) {
            handleError(err);
        }
    };

    return (
        <div className="mb-4">
            {titleFormVisible && (
                <>
                    <TitleForm
                        title={titleValue}
                        setTitleValue={setTitleValue}
                        submitting={submitting}
                        isEditForm={props.isEditForm}
                        handleSubmit={handleSubmit}
                        errors={errors}
                        isDisableTitle={isDisableTitle}
                        isEditCategory
                        additionalTitle={additionalTitleValue}
                        setAdditionalTitle={setAdditionalTitleValue}
                        isCategory
                    />
                </>
            )}
            {searchSubmitting ? (
                <div className="mt-5">
                    <LoadingSpinner text={loadingSpinnerText} />
                </div>
            ) : (
                preview && (
                    <Preview
                        preview={preview}
                        setActivePreviewSortValue={setActivePreviewSortValue}
                        updatingPreview={updatingPreview}
                        activeCountryFilter={activeCountryFilter}
                        setActiveCountryFilter={setActiveCountryFilter}
                        activeRegionFilter={activeRegionFilter}
                        setActiveRegionFilter={setActiveRegionFilter}
                        activeAdditionalCategoryFilter={activeAdditionalCategoryFilter}
                        setActiveAdditionalCategoryFilter={setActiveAdditionalCategoryFilter}
                        activeSentimentFilter={activeSentimentFilter}
                        setActiveSentimentFilter={setActiveSentimentFilter}
                        typeOfSource={typeOfSource}
                        setTypeOfSource={setTypeOfSource}
                        additionalCategories={additionalCategories}
                        countries={countries}
                        regions={regions}
                    />
                )
            )}
        </div>
    );
};

export default OzmosysCategorizedAlertForm;
