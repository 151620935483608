import React, {useState, useEffect} from 'react';

import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    InputGroupAddon,
    InputGroupText,
    Input, InputGroup,
} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import {getUsersWithoutParams} from 'utils/api/usersAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import DualSelect from 'components/DualSelect';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const AssignAlertGroupModal = (props) => {
    const {isOpen, toggle, onSubmit, submitting, addFlashMessage, alertGroup} = props;
    const [users, setUsers] = useState('');
    const [fetchingUsers, setFetchingUsers] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
        setSelectedUsers([]);
    }, [isOpen]);

    const fetchUsers = async() => {
        try {
            const userData = await getUsersWithoutParams(false, false, 1, 2000);
            setUsers(userData.result);
            setFetchingUsers(false);
        } catch(err) {
            setFetchingUsers(false);
            addFlashMessage('danger', 'Unable to fetch user data');
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-alert-group-modal modal-xl">
            {fetchingUsers ?
                <LoadingSpinner padding text={'Fetching user data'}/>
                :
                <div>
                    <ModalBodyWithClose toggle={toggle}>
                        <h2>Assign Group</h2>
                        <div className="mt-2" style={{fontSize: '1rem'}}>
                            Alert Group: <strong>{alertGroup && alertGroup.name}</strong>
                        </div>
                        <div className="mt-3">
                            <h4>Select Users</h4>
                            <DualSelect
                                selectedList={selectedUsers}
                                fullList={users}
                                onSelect={setSelectedUsers}
                            />
                        </div>
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <SpinnerButton
                            type="modal"
                            color="primary"
                            onClick={() => onSubmit(alertGroup, selectedUsers, toggle)}
                            submitting={submitting}
                            title="Assign"
                        />
                    </ModalFooter>
                </div>
            }
        </Modal>
    );
};

export default AssignAlertGroupModal;
