import React, {useEffect, useState} from 'react';

import {Table, Button, UncontrolledTooltip} from 'reactstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {CSVLink} from 'react-csv';

import CreateAlertGroupModal from './Modals/CreateAlertGroup';
import EditAlertGroupModal from './Modals/EditAlertGroup';
import DeleteAlertGroupModal from './Modals/DeleteAlertGroup';
import AssignAlertGroupModal from './Modals/AssignAlertGroup';
import RSSLogo from 'images/rss-icon.png';
import SearchInput from 'components/SearchInput/SearchInputWithSubmit';
import {filterByValue} from 'Helpers/filterByValue';
import {sortObjects, sortByValueLength} from 'Helpers/sortObjects';
import {handleCaret} from 'Helpers/handleSortCaret';
import {SafeTrim} from 'Helpers/safeTrim';
import moment from 'moment-timezone';
import SpinnerButtonSquare from 'components/SpinnerButton/Square';
import AlertListCsvButton from 'containers/Alerts/AlertsList/AlertListCsvButton';
import MobileFilterDropdown from './MobileFilterDropdown';
import PreviewAlertGroupModal from './Modals/PreviewAlertGroup';

const AlertGroupList = (props) => {
    const {alertGroups, submitting, handleError, deleteGroup,
        addFlashMessage, handleAssignAlertGroup, refreshAlertGroups,
        alertGroupFilters, setFiltersObject} = props;
    const {searchValue, activeSortProperty, activeSortDirection} = alertGroupFilters;

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToAssign, setItemToAssign] = useState(null);
    const [tooltipCopied, setTooltipCopied] = useState(null);
    const [visibleAlertGroups, setVisibleAlertGroups] = useState([]);
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [csvData, setCsvData] = useState([]);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');
    const [itemToPreview, setItemToPreview] = useState(null);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);

    useEffect(() => {
        tempSearchValue !== searchValue && setTempSearchValue(searchValue);
    }, []);

    useEffect(() => {
        let filteredList = filterByValue(alertGroups, 'name', searchValue);
        let sortedList = activeSortProperty === 'alerts' ? sortByValueLength(filteredList, activeSortProperty, activeSortDirection) :
            sortObjects(filteredList, activeSortProperty, activeSortDirection);
        setVisibleAlertGroups(sortedList);
    }, [alertGroupFilters, alertGroups]);

    const toggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen);
    };

    const toggleEditModal = () => {
        if(editModalOpen) {
            setItemToEdit(null);
        }
        setEditModalOpen(!editModalOpen);
    };

    useEffect(() => {
        itemToEdit && toggleEditModal();
    }, [itemToEdit]);

    const toggleDeleteModal = (item) => {
        setDeleteModalOpen(!deleteModalOpen);
        if(!deleteModalOpen) {
            setItemToDelete(item);
        } else {
            setItemToDelete(null);
        }
    };

    const toggleAssignModal = () => {
        if(assignModalOpen) {
            setItemToAssign(null);
        }
        setAssignModalOpen(!assignModalOpen);
    };

    useEffect(() => {
        itemToAssign && toggleAssignModal();
    }, [itemToAssign]);

    const setTooltipText = () => {
        setTooltipCopied(true);
    };

    const resetTooltipText = () => {
        setTooltipCopied(false);
    };

    const tooltipText = tooltipCopied
        ? 'Copied to Clipboard'
        : 'Copy to Clipboard';

    const handleSearchValueSubmit = () => {
        if(tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)')
            return;
        }
        let filters = {...alertGroupFilters};
        filters.searchValue = tempSearchValue;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('alertGroups', filters);
    };

    const handleClear = () => {
        let filters = {...alertGroupFilters};
        filters.searchValue = '';
        setFiltersObject('alertGroups', filters);
        setTempSearchValue('');
    };

    const buildCSVfile = (arr) => {
        const csvData = [['Name', 'Alerts']];
        // if(arr.length) {
        //     arr.map(item => {
        //         csvData.push([SafeTrim(item.name), item.alerts.length]);
        //     });
        // }
        // return csvData;

        arr.map((item) => {
            item.alerts.length > 1 ?
                sortAlphabetically(item.alerts).map(alert => csvData.push([SafeTrim(item.name), alert.name]))
                :
                csvData.push([SafeTrim(item.name)]);
        });
        return csvData;


    };

    const sortAlphabetically = (arr) => {
        return arr.sort((a, b) => a.name.localeCompare(b.name));
    };

    useEffect(() => {
        visibleAlertGroups.length && setCsvData(buildCSVfile(visibleAlertGroups));
    }, [visibleAlertGroups]);


    const handleSortableHeaderClick = (value, activeSortProperty, activeSortDirection) => {
        let filters = {...alertGroupFilters};
        if(value === activeSortProperty) {
            activeSortDirection === 'asc' ?
                filters.activeSortDirection = 'desc'
                :
                filters.activeSortDirection = 'asc';
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }
        setFiltersObject('alertGroups', filters);
    };

    const handleMobileFilterSelect = (val) => {
        let splitValue = val.split('-');
        let filters = {...alertGroupFilters};
        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject('alertGroups', filters);
    };

    const determineMobileFiltersValue = () => {
        let filterStr = alertGroupFilters.activeSortProperty + '-' + alertGroupFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    useEffect(() => {
        alertGroupFilters && determineMobileFiltersValue();
    }, [alertGroupFilters]);

    const togglePreviewModal = () => {
        setPreviewModalOpen(!previewModalOpen);
    };

    useEffect(() => {
        itemToPreview && togglePreviewModal();
    }, [itemToPreview]);

    return (
        <div className="list alert-group-list">
            {window.innerWidth > 1250 &&
            <div className="list__utility-row list__utility-row--desktop">
                <div className="list__search-container">
                    <SearchInput
                        value={tempSearchValue}
                        onChange={setTempSearchValue}
                        placeholder="Filter by name"
                        onClear={handleClear}
                        extraParams={null}
                        submitting={false}
                        onSubmit={handleSearchValueSubmit}
                        list
                    />
                </div>
                <div className="d-flex">
                    <CSVLink
                        data={csvData}
                        filename={
                            'ozmosys_alert-groups_' +
                            moment()
                                .tz(moment.tz.guess())
                                .format('MMDDYYYY') +
                            '.csv'
                        }
                    >
                        <SpinnerButtonSquare
                            title={<i className="fa fa-download"/>}
                            className="mr-2 d-block"
                            id="alert-group-csv-button-tooltip"
                        />
                    </CSVLink>
                    <UncontrolledTooltip
                        placement="bottom"
                        target="alert-group-csv-button-tooltip"
                        delay={{show: 400, hide: 0}}
                    >
                        Download as CSV file
                    </UncontrolledTooltip>
                    <Button
                        onClick={toggleCreateModal}
                        color="primary"
                    >Create Group</Button>
                </div>
            </div>
            }
            {1250 >= window.innerWidth && window.innerWidth > 624 &&
            <div className="list__utility-row list__utility-row--tablet">
                <div className="d-flex justify-content-between w-100">
                    <div className="list__search-container">
                        <SearchInput
                            value={tempSearchValue}
                            onChange={setTempSearchValue}
                            placeholder="Filter by name"
                            onClear={handleClear}
                            extraParams={null}
                            submitting={false}
                            onSubmit={handleSearchValueSubmit}
                            list
                        />
                    </div>
                </div>
                <div className="d-flex mt-2 w-100 justify-content-end">
                    <div className="mr-2 d-flex">
                        <Button
                            onClick={toggleCreateModal}
                            color="primary"
                            className="mb-0"
                        >
                            <i className="fa fa-plus"/>&nbsp;
                            Create Group
                        </Button>
                    </div>
                    <div>
                        <CSVLink
                            data={csvData}
                            filename={
                                'ozmosys_alert-groups_' +
                                moment()
                                    .tz(moment.tz.guess())
                                    .format('MMDDYYYY') +
                                '.csv'
                            }
                        >
                            <SpinnerButtonSquare
                                title={<i className="fa fa-download"/>}
                                className="mr-2"
                                id="alert-group-csv-button-tooltip-tablet"
                            />
                        </CSVLink>
                        <UncontrolledTooltip
                            placement="bottom"
                            target="alert-group-csv-button-tooltip-tablet"
                            delay={{show: 400, hide: 0}}
                        >
                            Download as CSV file
                        </UncontrolledTooltip>
                    </div>
                    <MobileFilterDropdown
                        handleMobileFilterSelect={handleMobileFilterSelect}
                        activeMobileFilterValue={activeMobileFilterValue}
                    />
                </div>
            </div>
            }
            {624 >= window.innerWidth &&
            <div className="list-utility-row list__utility-row--mobile">
                <div className="d-flex mb-2">
                    <CSVLink
                        data={csvData}
                        className="w-100"
                        filename={
                            'ozmosys_alert-groups_' +
                            moment()
                                .tz(moment.tz.guess())
                                .format('MMDDYYYY') +
                            '.csv'
                        }
                    >
                        <Button
                            className="mr-1 w-100"
                            id="alert-group-csv-button-tooltip-mobile"
                        >
                            <i className="fa fa-download"/>  Download CSV
                        </Button>
                    </CSVLink>
                    <UncontrolledTooltip
                        placement="bottom"
                        target="alert-group-csv-button-tooltip-mobile"
                        delay={{show: 400, hide: 0}}
                    >
                        Download as CSV file
                    </UncontrolledTooltip>
                    <Button
                        onClick={toggleCreateModal}
                        color="primary"
                        className="ml-1"
                        style={{flex: '0 0 50%'}}
                    >
                        <i className="fa fa-plus"/>&nbsp;
                        Create Group
                    </Button>
                </div>
                <div className="d-flex mb-2">
                    <MobileFilterDropdown
                        handleMobileFilterSelect={handleMobileFilterSelect}
                        activeMobileFilterValue={activeMobileFilterValue}
                    />
                </div>
                <div className="mb-3">
                    <SearchInput
                        value={tempSearchValue}
                        onChange={setTempSearchValue}
                        placeholder="Filter by name"
                        onClear={handleClear}
                        extraParams={null}
                        submitting={false}
                        onSubmit={handleSearchValueSubmit}
                        list
                    />
                </div>
            </div>
            }

            <Table className="responsive-table">
                <tbody>
                <tr>
                    <th
                        className="responsive-table__sortable-th"
                        onClick={() => handleSortableHeaderClick('name', activeSortProperty, activeSortDirection)}
                    >
                        Name
                        {handleCaret(activeSortProperty, activeSortDirection, 'name')}
                    </th>
                    <th
                        className="responsive-table__sortable-th"
                        onClick={() => handleSortableHeaderClick('alerts', activeSortProperty, activeSortDirection)}

                    >
                        Searches/Sources
                        {handleCaret(activeSortProperty, activeSortDirection, 'alerts')}
                    </th>
                    <th>RSS Feed URL</th>
                    <th>Assignment</th>
                    {/*<th>Created At</th>*/}
                    <th>Edit/Remove</th>
                </tr>
                {visibleAlertGroups.length > 0 ? visibleAlertGroups.map(alertGroup => (
                        <tr key={alertGroup.id}>
                            <td data-label="Name">
                                <a
                                    className="link-style"
                                    onClick={() => setItemToPreview(alertGroup)}>
                                    {alertGroup.name}
                                </a>
                            </td>
                            <td data-label="Alerts">
                                {alertGroup.alerts.length}
                            </td>
                            <td data-label="RSS Feed URL">
                                <div className="alert-group-rss-label-container">
                                    <CopyToClipboard text={alertGroup.rss}>
                                        <div
                                            className="alert-group-rss-label"
                                            style={{
                                                wordBreak: 'break-all',
                                                wordWrap: 'break-word',
                                            }}
                                            onMouseEnter={() => setTooltipCopied(false)}
                                        >
                                            {/*<span>{item.publicUrl}</span>*/}
                                            <div style={{cursor: 'pointer'}}>
                                                <img
                                                    className="rss-logo"
                                                    height="35px"
                                                    src={RSSLogo}
                                                    alt="rss_logo"
                                                    id={'rss-label-tooltip-' + alertGroup.id}
                                                    onClick={() => setTooltipCopied(true)}
                                                />
                                            </div>
                                        </div>
                                    </CopyToClipboard>
                                </div>
                                <UncontrolledTooltip
                                    placement="right"
                                    autohide={false}
                                    target={'rss-label-tooltip-' + alertGroup.id}
                                    delay={{show: 200, hide: 0}}
                                >
                                    {tooltipText}
                                </UncontrolledTooltip>
                            </td>
                            <td data-label="Assignment">
                                <Button
                                    color={'primary'}
                                    onClick={() => setItemToAssign(alertGroup)}
                                >
                                    Assign To
                                </Button>
                            </td>
                            <td className="responsive-table__edit-block" data-label="Edit/Remove">
                                <Button color="light" onClick={() => setItemToEdit(alertGroup)}>
                                    <i className="fa fa-edit fa-lg"/>
                                </Button>{' '}
                                <Button color="light" onClick={() => toggleDeleteModal(alertGroup)}>
                                    <i className="fa fa-trash fa-lg"/>
                                </Button>
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td>No results found</td>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                    </tr>
                }
                </tbody>
            </Table>

            {createModalOpen &&
            <CreateAlertGroupModal
                isOpen={createModalOpen}
                toggle={toggleCreateModal}
                addFlashMessage={addFlashMessage}
                refreshAlertGroups={refreshAlertGroups}
                handleError={handleError}
            />
            }

            {editModalOpen &&
            <EditAlertGroupModal
                isOpen={editModalOpen}
                toggle={toggleEditModal}
                alertGroup={itemToEdit}
                addFlashMessage={addFlashMessage}
                handleError={handleError}
                refreshAlertGroups={refreshAlertGroups}
            />
            }

            {deleteModalOpen &&
            <DeleteAlertGroupModal
                isOpen={deleteModalOpen}
                onSubmit={deleteGroup}
                toggle={toggleDeleteModal}
                item={itemToDelete}
                submitting={submitting}
                addFlashMessage={addFlashMessage}
            />
            }

            {assignModalOpen &&
            <AssignAlertGroupModal
                isOpen={assignModalOpen}
                onSubmit={handleAssignAlertGroup}
                toggle={toggleAssignModal}
                alertGroup={itemToAssign}
                submitting={submitting}
                addFlashMessage={addFlashMessage}
            />
            }

            {previewModalOpen &&
            <PreviewAlertGroupModal
                isOpen={previewModalOpen}
                toggle={togglePreviewModal}
                alertGroup={itemToPreview}
                handleError={handleError}
            />
            }
        </div>
    );
};

export default AlertGroupList;
