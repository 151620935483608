import React, {useState, useEffect} from 'react';

import SpinnerButton from 'components/SpinnerButton';
import {getAlertGroups, getAlertsWithoutParams, getReaderAlerts, getReaderAlertGroups, manageReaderAlerts} from 'utils/api/alertsAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import DualSelect from 'components/DualSelect';
import {updateUserAlertsById} from 'utils/api/usersAPI';

const ManageAlerts = (props) => {
    const {assignedAlerts, handleError, refreshAlerts, setActiveView, userId, addFlashMessage, isPublic} = props;
    const [fetchingAlerts, setFetchingAlerts] = useState(true);
    const [alerts, setAlerts] = useState([]);
    const [alertGroups, setAlertGroups] = useState([]);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setSelectedAlerts(assignedAlerts);
    }, []);

    const fetchAlerts = async() => {
        try {
            const alertData = isPublic ? await getReaderAlerts() : await getAlertsWithoutParams(false, false, 1, 2000);
            const alertGroupData =  isPublic ? await getReaderAlertGroups() : await getAlertGroups();
            setAlerts(alertData.result);
            setAlertGroups(alertGroupData);
            setFetchingAlerts(false);
        } catch(err) {
            setFetchingAlerts(false);
            handleError(err);
        }
    };

    const handleManageAlertsSubmit = async() => {
        try {
            setSubmitting(true);
            const params = {
                alerts: selectedAlerts.map(alert => alert.id),
            };
            isPublic ? await manageReaderAlerts(params) : await updateUserAlertsById(userId, params);
            refreshAlerts();
            setSubmitting(false);
            setActiveView('alerts');
            addFlashMessage('success', 'Alert list successfully updated');
        } catch(err) {
            console.log(err)
            handleError(err);
            addFlashMessage('danger', 'Unable to update alerts at this time');
            setSubmitting(false);
        }
    };

    useEffect(() => {
        fetchAlerts();
    }, []);

    return (
        <div className="mobile-reader-list mobile-reader-manage-alerts">
            <div className="mobile-reader-list__header mobile-reader-manage-alerts__header">
                Manage Alerts
            </div>
            <div className="mobile-reader-list__body mobile-reader-manage-alerts__body">
                {fetchingAlerts ?
                    <LoadingSpinner text="Fetching alert data"/>
                    :
                    <div>
                        <DualSelect
                            selectedList={selectedAlerts}
                            fullList={alerts}
                            groups={alertGroups}
                            onSelect={setSelectedAlerts}
                            contentType="alerts"
                        />
                        <div className="mt-3">
                            <SpinnerButton
                                type="modal"
                                color="primary"
                                onClick={handleManageAlertsSubmit}
                                submitting={submitting}
                                title="Save Changes"
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ManageAlerts;

