import React, { useState, useEffect, useContext } from 'react';

import { Button, Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip } from 'reactstrap';

import ListItem from './ListItem';
import PaginationWrapper from 'components/PaginationWrapper';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { truncateWithoutWordBreak } from 'Helpers/truncateWithoutWordBreak';
import { AuthContext } from 'contexts/AuthContext';
import { initBriefingReport } from 'utils/api/alertsAPI';
import ReactTooltip from 'react-tooltip';

const ArticleList = (props) => {
    const { articles, activeTag, activeDate, resetAllFilters, articlesWithoutDuplicates, alertId, alertName, handleTagSelect } = props;

    const [totalPages, setTotalPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [articlesToEmail, setArticlesToEmail] = useState([]);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { state } = useContext(AuthContext);
    const [articlesToBriefingReport, setArticlesToBriefingReport] = useState([]);
    const [accessBriefingReport, setAccessBriefingReport] = useState(false);
    const [emails, setEmails] = useState('');

    useEffect(() => {
        if (state.isAuthenticated && state.profile.organization?.accessBriefingReport) {
            setAccessBriefingReport(true);
        }
    }, [state]);

    useEffect(() => {
        setTotalPages(Math.ceil(articlesWithoutDuplicates.length / 10));
        setCurrentPage(1);
    }, [articles]);

    const handlePaginationClick = (e) => {
        const selectedPage = e.selected + 1;
        setCurrentPage(selectedPage);
    };

    const buildMailtoUrl = () => {
        let toSend = articles.filter((x) => articlesToEmail.indexOf(x.id) !== -1);
        toSend = toSend.map((i) => i.title + '\n' + i.bit_url);
        toSend = toSend.join('\n\n');

        return 'mailto:?subject=' + truncateWithoutWordBreak(alertName) + '&body=' + encodeURIComponent(toSend);
    };

    const handleShareEmailClick = (e) => {
        e.preventDefault();

        if (articlesToEmail.length < 1) {
            addFlashMessage('danger', 'First you need select articles...');
            return false;
        }

        window.open(buildMailtoUrl(), '_blank', 'noopener,noreferrer');
    };

    const validateEmails = () => {
        if (!emails) {
            return false;
        }
        let valid = true;
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const arr = emails.split(' ');
        arr.forEach((el) => {
            el = el.trim();
            if (el && !emailRegex.test(el) && valid) {
                addFlashMessage('danger', 'Please enter a valid email address');
                valid = false;
            }
        });
        if (valid) {
            setEmails(emails);
        }
        return valid;
    };

    const handleBriefingReportClick = async (e) => {
        e.preventDefault();
        if (articlesToBriefingReport.length < 2 || articlesToBriefingReport.length > 5) {
            addFlashMessage('danger', 'You need select between 2 and 5 articles...');
            return false;
        }
        if (!emails || !validateEmails()) {
            addFlashMessage('danger', 'Please enter a valid email address');
            return false;
        }
        let urls = articles.filter((x) => articlesToBriefingReport.indexOf(x.id) !== -1);
        urls = urls.map((x) => x.url);
        try {
            await initBriefingReport({
                urls: urls,
                emails: emails.split(' '),
            });
            addFlashMessage('success', 'Briefing Report successfully sent');
        } catch (err) {
            addFlashMessage('danger', 'Something wrong...');
        }
    };

    const isDisabled = activeTag === '' && activeDate === '';
    return (
        <div className="dig-deeper-article-list">
            <div className="dig-deeper-article-list__header">
                {articlesWithoutDuplicates.length || articles?.length ? <h3>Matches ({articles.length})</h3> : <h3>No Matches</h3>}
                <div className="flex">
                    {state.isAuthenticated && !!articlesToEmail?.length && (
                        <div>
                            <UncontrolledTooltip placement="bottom" target="email-label-tooltip" delay={{ show: 200, hide: 0 }}>
                                Share via email
                            </UncontrolledTooltip>
                            <a target="_blank" className="btn btn-secondary" id="email-label-tooltip" onClick={handleShareEmailClick} href="#">
                                <i className="fa fa-envelope-o" />
                            </a>
                        </div>
                    )}
                    <Button className="clear-filters-btn" disabled={isDisabled} size="sm" onClick={resetAllFilters}>
                        Clear Filters
                    </Button>
                </div>
            </div>
            {state.isAuthenticated && accessBriefingReport && articlesToBriefingReport.length > 0 && (
                <div className="dig-deeper-article-list__header">
                    <InputGroup className={'m-0'}>
                        <InputGroupAddon addonType="prepend" data-tip="Build Briefing Report">
                            <InputGroupText>
                                <i className="fa fa-user" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <ReactTooltip place="bottom" effect="solid" delayShow={200} className="tooltip-thin" />
                        <Input
                            placeholder="Email address(es) separated by space"
                            value={emails}
                            type="text"
                            onChange={(e) => setEmails(e.target.value)}
                            onBlur={validateEmails}
                        />
                        <UncontrolledTooltip placement="bottom" target={'briefing-report-label-tooltip'} delay={{ show: 200, hide: 0 }}>
                            Build Briefing Report
                        </UncontrolledTooltip>
                        <a className="btn btn-secondary" id="briefing-report-label-tooltip" onClick={handleBriefingReportClick} href="#">
                            <i className="fa fa-cubes" />
                        </a>
                    </InputGroup>
                </div>
            )}
            {articlesWithoutDuplicates && (
                <div>
                    <ul className="list-unstyled list-group">
                        {(articlesWithoutDuplicates?.length ? articlesWithoutDuplicates : articles)
                            .slice(currentPage * 10 - 10, currentPage * 10)
                            .map((article) => {
                                return (
                                    <ListItem
                                        key={article.id}
                                        item={article}
                                        activeTag={activeTag}
                                        articlesToEmail={articlesToEmail}
                                        setArticlesToEmail={setArticlesToEmail}
                                        alertId={alertId}
                                        isAuthenticated={state.isAuthenticated}
                                        accessBriefingReport={state.isAuthenticated && accessBriefingReport}
                                        articlesToBriefingReport={articlesToBriefingReport}
                                        setArticlesToBriefingReport={setArticlesToBriefingReport}
                                        handleTagSelect={handleTagSelect}
                                    />
                                );
                            })}
                        {totalPages > 1 && (
                            <PaginationWrapper totalPages={totalPages} forcePage={currentPage - 1} handlePageClick={handlePaginationClick} />
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ArticleList;
