import React, { useEffect, useState } from 'react';
import { Modal, ModalFooter, Button } from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import DualSelect from 'components/DualSelect';
import './style.scss';
import { getAlertGroups, getAlertsWithoutParams } from 'utils/api/alertsAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const SelectAlerts = (props) => {
    const { toggle, isOpen, submitting, currentId, setSelectedAlerts, handleAlertSelectSubmit, selectedList, handleError } = props;
    const [loadingAlerts, setLoadingAlerts] = useState(true);
    const [fullAlertList, setFullAlertList] = useState([]);
    const [alertGroups, setAlertGroups] = useState([]);

    const fetchAlerts = async () => {
        try {
            setLoadingAlerts(true);
            const alerts = await getAlertsWithoutParams(false, false, 1, 2000);
            const alertGroupData = await getAlertGroups();
            setAlertGroups(alertGroupData);
            if (currentId) {
                let newList = [...alerts.result];
                let foundAlert = newList.find((item) => item.id === currentId);
                newList.splice(alerts.result.indexOf(foundAlert), 1);
                setFullAlertList(newList);
            } else {
                setFullAlertList(alerts.result);
            }
            setLoadingAlerts(false);
        } catch (err) {
            handleError(err);
        }
    };

    const handleSubmit = () => {
        handleAlertSelectSubmit();
        toggle();
    };

    useEffect(() => {
        isOpen && fetchAlerts();
    }, [isOpen]);

    return (
        <Modal className="internal-search-select" isOpen={isOpen} toggle={toggle}>
            <ModalBodyWithClose toggle={toggle}>
                <h2>Select Sources</h2>
                {loadingAlerts ? (
                    <LoadingSpinner />
                ) : (
                    <DualSelect
                        selectedList={selectedList}
                        fullList={fullAlertList}
                        onSelect={setSelectedAlerts}
                        contentType="alerts"
                        groups={alertGroups}
                        isSearch
                    />
                )}
            </ModalBodyWithClose>
            <ModalFooter>
                <Button type="button" onClick={toggle}>
                    Cancel
                </Button>
                <SpinnerButton
                    color="primary"
                    submitting={submitting}
                    onClick={handleSubmit}
                    className="float-right"
                    title={'Save Changes'}
                    type={'modal'}
                />
            </ModalFooter>
        </Modal>
    );
};

export default SelectAlerts;
