import React, { useContext, useState } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { convertDateString } from 'Helpers/convertDateString';
import { truncateWithoutWordBreak } from 'Helpers/truncateWithoutWordBreak';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { getBitUrl } from 'utils/api/alertsAPI';

const ListItem = (props) => {
    const {
        activeTag,
        duplicateArticle,
        articlesToEmail,
        setArticlesToEmail,
        item,
        alertId,
        isAuthenticated,
        accessBriefingReport,
        articlesToBriefingReport,
        setArticlesToBriefingReport,
        handleTagSelect,
    } = props;
    const { title, abstract, url, created_at, resource, paywall, sentiment, duplicates, org, person, loc, categories } = props.item;
    const [duplicatesVisible, setDuplicatesVisible] = useState(false);
    const maxArticlesToEmail = 10;
    const maxArticlesBriefingReport = 5;
    const { addFlashMessage } = useContext(FlashMessageContext);
    let taggedAbstract;
    let taggedTitle;

    if (activeTag.name && activeTag.name.length > 0) {
        const escaped = activeTag.name.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        const regexp = new RegExp(`\\b(${escaped})\\b`, 'gi');
        taggedAbstract = abstract != null && abstract.replace(regexp, '<span class="tag-highlight">$&</span>');
        taggedTitle = title != null && title.replace(regexp, '<span class="tag-highlight">$&</span>');
    }

    const bitUrl = async () => {
        try {
            const bitUrl = await getBitUrl(alertId, item);
            item['bit_url'] = bitUrl['content']['url'];
        } catch (err) {
            console.log(err);
        }
    };

    const handleArticleToBriefingReport = async (e) => {
        let newArticlesBriefingReport = [...articlesToBriefingReport];
        const ind = getAddedIndex(articlesToBriefingReport);

        if (+ind === -1) {
            if (articlesToBriefingReport.length >= maxArticlesBriefingReport) {
                addFlashMessage('danger', 'You can add only ' + maxArticlesBriefingReport + ' articles...');
                return false;
            }
            newArticlesBriefingReport.push(item.id);
        } else {
            delete newArticlesBriefingReport[ind];
        }
        newArticlesBriefingReport = newArticlesBriefingReport.filter((int) => int > -1);
        setArticlesToBriefingReport(newArticlesBriefingReport);
        return false;
    };

    const handleArticleToEmail = async (e) => {
        let newArticlesToEmail = [...articlesToEmail];
        const ind = getAddedIndex(articlesToEmail);

        if (+ind === -1) {
            if (articlesToEmail.length >= maxArticlesToEmail) {
                addFlashMessage('danger', 'You can add only ' + maxArticlesToEmail + ' articles...');
                return false;
            }
            await bitUrl();
            newArticlesToEmail.push(item.id);
        } else {
            delete newArticlesToEmail[ind];
        }
        newArticlesToEmail = newArticlesToEmail.filter((int) => int > -1);
        setArticlesToEmail(newArticlesToEmail);
        return false;
    };

    const getAddedIndex = (articles) => {
        return articles !== undefined ? articles.indexOf(item.id) : -1;
    };

    return (
        <div className="alert-preview-item">
            <div className="alert-preview-item__body">
                {isAuthenticated && !duplicateArticle && (
                    <div>
                        <UncontrolledTooltip placement="bottom" target={'add-to-email-' + item.id} delay={{ show: 200, hide: 0 }}>
                            {getAddedIndex(articlesToEmail) === -1 ? 'Add to email' : 'Remove from email'}
                        </UncontrolledTooltip>
                        <a
                            id={'add-to-email-' + item.id}
                            href={'#!'}
                            onClick={handleArticleToEmail}
                            style={{ color: getAddedIndex(articlesToEmail) === -1 ? '#bfe1ee' : '' }}>
                            <i className="fa fa-share fa-2x" aria-hidden="true"></i>
                        </a>
                        {accessBriefingReport && (
                            <span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <UncontrolledTooltip placement="bottom" target={'add-to-report-' + item.id} delay={{ show: 200, hide: 0 }}>
                                    {getAddedIndex(articlesToBriefingReport) === -1 ? 'Add to Briefing Report' : 'Remove from Briefing Report'}
                                </UncontrolledTooltip>
                                <a
                                    id={'add-to-report-' + item.id}
                                    href={'#!'}
                                    onClick={handleArticleToBriefingReport}
                                    style={{ color: getAddedIndex(articlesToBriefingReport) === -1 ? '#bfe1ee' : '' }}>
                                    <i className="fa fa-plus-square fa-2x" aria-hidden="true"></i>
                                </a>
                            </span>
                        )}
                    </div>
                )}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    /*style={{
                       color: '#06205c',
                       marginBottom: '.2rem',
                       fontSize: '16px',
                       fontWeight: 500,
                       lineHeight: 1.2,
                   }}*/
                    href={url}
                    className="alert-preview-item__title"
                    dangerouslySetInnerHTML={{ __html: taggedTitle || title }}
                />
                <div className="alert-preview-item__info" style={{ color: '#9a9a9a' }}>
                    <span className="alert-preview-item__source" dangerouslySetInnerHTML={{ __html: resource }} />
                    {' · '}
                    <span className="alert-preview-item__date">{convertDateString(created_at, true)}</span>
                    {paywall && <span className="alert-preview-item__date"> · Paywall</span>}
                </div>
                {abstract && (
                    <div
                        className="alert-preview-item__abstract"
                        style={{ fontSize: '14px' }}
                        dangerouslySetInnerHTML={{ __html: truncateWithoutWordBreak(taggedAbstract, 280) || truncateWithoutWordBreak(abstract, 280) }}
                    />
                )}
                {categories.length > 0 && (
                    <div style={{ paddingTop: '10px' }}>
                        <b style={{ color: '#565454' }}>Topics: </b>
                        {categories.map((cat) => (
                            <div
                                key={cat}
                                onClick={() => handleTagSelect({ ind: 'categories', name: cat })}
                                className={activeTag.name === cat ? 'dig-deeper-tag-list__tag_active' : 'dig-deeper-tag-list__tag'}>
                                {cat}
                            </div>
                        ))}
                    </div>
                )}
                {loc.length > 0 && (
                    <div style={{ paddingTop: '10px' }}>
                        <b style={{ color: '#565454' }}>Geo: </b>
                        {loc.map((loc) => (
                            <div
                                key={loc}
                                onClick={() => handleTagSelect({ ind: 'loc', name: loc })}
                                className={activeTag.name === loc ? 'dig-deeper-tag-list__tag_active' : 'dig-deeper-tag-list__tag'}>
                                {loc}
                            </div>
                        ))}
                    </div>
                )}
                {window.location.origin !== 'https://news.ozmosys.com' && person.length > 0 && (
                    <div style={{ paddingTop: '10px' }}>
                        <b style={{ color: '#565454' }}>Persons: </b>
                        {person.map((item) => (
                            <div
                                key={item}
                                onClick={() => handleTagSelect({ ind: 'person', name: item })}
                                className={activeTag.name === item ? 'dig-deeper-tag-list__tag_active' : 'dig-deeper-tag-list__tag'}>
                                {item}
                            </div>
                        ))}
                    </div>
                )}
                {org.length > 0 && (
                    <div style={{ paddingTop: '10px' }}>
                        <b style={{ color: '#565454' }}>Org: </b>
                        {org.map((item) => (
                            <div
                                key={item}
                                onClick={() => handleTagSelect({ ind: 'org', name: item })}
                                className={activeTag.name === item ? 'dig-deeper-tag-list__tag_active' : 'dig-deeper-tag-list__tag'}>
                                {item}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {duplicates && !duplicateArticle && (
                <Button size="xs" className="duplicates-btn" onClick={() => setDuplicatesVisible((prevState) => !prevState)}>
                    {duplicatesVisible ? 'Hide' : 'Show'} similar articles
                </Button>
            )}
            {duplicatesVisible && (
                <div className="alert-preview-item__duplicates-container">
                    {duplicates.map((n) => {
                        return <ListItem key={n.id} item={n} activeTag={activeTag} handleTagSelect={handleTagSelect} duplicateArticle={true} />;
                    })}
                </div>
            )}
        </div>
    );
};

export default ListItem;
