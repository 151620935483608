import React, {useState, useEffect} from 'react';

import {Modal, ModalBody, ModalFooter, Button,} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import DualSelect from 'components/DualSelect';
import {assignUsersToUserGroup, editUserGroup, getUsersWithoutParams} from 'utils/api/usersAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import InputWithError from 'components/InputWithError';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const EditUserGroupModal = (props) => {
    const {isOpen, toggle, item, allUsers, addFlashMessage, refreshUserGroups, handleError} = props;
    const [name, setName] = useState('');
    const [users, setUsers] = useState([]);
    const [errors, setErrors] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [fetchingUsers, setFetchingUsers] = useState(true);

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        if(item) {
            setName(item.name);
            setSelectedUsers(item.users);
        }
        item && setName(item.name);
    }, [item]);


    const fetchUsers = async() => {
        try {
            const userData = await getUsersWithoutParams(false, false, 1, 2000);
            setUsers(userData.result);
            setFetchingUsers(false);
        } catch(err) {
            setFetchingUsers(false);
            handleError(err);
        }
    };

    const editGroup = async() => {
        try {
            setSubmitting(true);
            setErrors(null);
            await editUserGroup({name}, item.id);
            await assignUsersToUserGroup(item.id, {users: selectedUsers.map(item => item.id)})
            await refreshUserGroups();
            addFlashMessage('success', 'User group successfully updated');
            setSubmitting(false);
            toggle();
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
            {fetchingUsers ?
                <LoadingSpinner padding text={'Fetching user data'}/>
                :
                <>
                    {!item ?
                        <div/>
                        :
                        <div>
                            <ModalBodyWithClose toggle={toggle}>
                                <h2>Edit User Group</h2>
                                <InputWithError
                                    placeholder="User group name"
                                    name="name"
                                    value={name}
                                    type="text"
                                    autoComplete="off"
                                    onChange={setName}
                                    errorObj={errors}
                                    prependIcon={<i className="fa fa-tag"/>}
                                />
                                <div className="mt-4">
                                    <DualSelect
                                        selectedList={selectedUsers}
                                        fullList={users}
                                        onSelect={setSelectedUsers}
                                        contentType="users"
                                        hideGroups
                                    />
                                </div>
                            </ModalBodyWithClose>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                                <SpinnerButton
                                    type="modal"
                                    color="primary"
                                    onClick={editGroup}
                                    submitting={submitting}
                                    title="Update"
                                />
                            </ModalFooter>
                        </div>
                    }
                </>
            }
        </Modal>
    );
};

export default EditUserGroupModal;
