import React from 'react';
import ModalBody from 'reactstrap/es/ModalBody';
import './style.scss';

const ModalBodyWithClose = (props) => {
    return (
        <ModalBody {...props} toggle="toggle">
            <div
                className="modal-close-btn"
                onClick={props.toggle}
            >
                <span aria-hidden="true">×</span>
            </div>
            {props.children}
        </ModalBody>
    );
};

export default ModalBodyWithClose;
