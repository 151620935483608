import React from 'react';
import './style.scss';

import {Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';

const SelectInputWithError = (props) => {
    const {prependIcon, onChange, autoCompleteOn, errorObj, name, marginBottomClass, noDefault} = props;

    const hasError = errorObj && errorObj.errors.hasOwnProperty(name)
    return (
        <div className={'d-flex flex-column ' + (hasError ?  'mb-2' : (marginBottomClass ? marginBottomClass : 'mb-4'))}>
            <InputGroup className={'m-0 ' + (hasError ? 'input-group-with-error' : '')}>
                {prependIcon &&
                <InputGroupAddon
                    addonType="prepend">
                    <InputGroupText>{prependIcon}</InputGroupText>
                </InputGroupAddon>
                }
                <Input
                    autoComplete={autoCompleteOn}
                    {...props}
                    onChange={(e) => onChange(e.target.value)}
                    style={{borderLeft: (!prependIcon && hasError) ? '1px solid red' : ''}}
                >
                    {props.children}
                </Input>
            </InputGroup>
            {hasError &&
            <div className="error-text mt-1">
                {errorObj.errors[name][0].message}
            </div>
            }
        </div>
    );
};

export default SelectInputWithError;
