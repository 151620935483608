import React, {useState, useEffect, useContext} from 'react';

import {Modal} from 'reactstrap';

import {addNewsletter} from 'utils/api/newslettersAPI';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import Form from 'containers/Newsletters/Modals/CreateAndEdit/Form/Form';
import './style.scss';

const CreateNewsletterModal = (props) => {
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError} = useContext(AuthContext);
    const {isOpen, toggle, refreshNewsletters} = props;
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(false);

    const createNewsletter = async(params) => {
        try {
            setSubmitting(true);
            setErrors(null);
            await addNewsletter(params);
            setSubmitting(false);
            refreshNewsletters();
            addFlashMessage('success', 'Newsletter successfully created');
            toggle();
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-newsletter-modal modal-lg">
            <Form
                formTitle="Create Newsletter"
                submitting={submitting}
                onSubmit={createNewsletter}
                toggle={toggle}
                submitButtonTitle="Create Newsletter"
                errors={errors}
                setErrors={setErrors}
            />
        </Modal>
    );
};

export default CreateNewsletterModal;
