import React from 'react';
import RSSLogo from 'images/rss-icon.png';
import BlueLogo from 'images/ozmosys_blue_cropped.png';
import OpenEnvelope from 'images/open-envelope.svg';

const Alert = ({alert}) => {
    const handleAlertTypeIcon = (sourceType) => {
        switch(sourceType) {
            case 'rss':
                return <img height={16} src={RSSLogo} alt="rss-logo"/>;
            case 'google':
                return <i style={{fontSize: '1.1rem'}} className="google-icon fa fa-google "/>;
            case 'internal':
                return <img height={16} src={BlueLogo} alt="internal-logo"/>;
            case 'email_publication':
                return <img height={16} src={OpenEnvelope} alt="email-logo"/>;
            default:
                return null;
        }
    };

    return (
        <div className="mobile-newsletter-alert d-flex align-items-center">
            <div className="mobile-newsletter-add-alerts__icon">{handleAlertTypeIcon(alert.source ? alert.source.type : alert.type)}</div> {alert.name}
        </div>
    );
};

export default Alert;
