import React from 'react';

import {Draggable} from 'react-beautiful-dnd';

import {convertDateString} from 'Helpers/convertDateString';

const Alert = ({alert, assignAlertToSection, sectionId, index}) => {
    return (
        <Draggable
            draggableId={String(alert.id)}
            index={index}
            type="alert"
        >
            {(provided, snapshot) => (
                <div
                    className={'newsletter-section-alert ' + (snapshot.isDragging ?
                        'newsletter-section-alert--is-dragging' : '')}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <div className="newsletter-section-alert__inner">
                        <span className="newsletter-section-alert__title">{alert.name}</span>
                        <span
                            className="newsletter-section-alert__date">{alert.source.contentUpdatedAt ? convertDateString(alert.source.contentUpdatedAt) : 'Pending Updates'}</span>
                    </div>
                    <div
                        className="newsletter-section-alert__unassign-btn"
                        onClick={() => assignAlertToSection(alert.id, sectionId, 0)}
                    >
                        <span>×</span>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default Alert;
