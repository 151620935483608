import React from 'react';
import {FormGroup, Input} from 'reactstrap';

const UsersListMobileFilterDropdown = ({activeMobileFilterValue, handleMobileFilterSelect}) => {
    return (
        <div className="list__mobile-select">
            <FormGroup className="m-0">
                <Input
                    onChange={e => handleMobileFilterSelect(e.target.value)}
                    value={activeMobileFilterValue}
                    type="select"
                    name="usersListMobileFilters"
                >
                    <option value="user.name-asc">Name(A-Z)</option>
                    <option value="user.name-desc">Name(Z-A)</option>
                    <option value="user.email-asc">Email(A-Z)</option>
                    <option value="user.email-desc">Email(Z-A)</option>
                </Input>
            </FormGroup>
        </div>
    );
};

export default UsersListMobileFilterDropdown;
