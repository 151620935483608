import React, {useState, useEffect} from 'react';

import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    InputGroupAddon,
    InputGroupText,
    Input, InputGroup,
} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import {editAlertLabel} from 'utils/api/alertsAPI';
import InputWithError from 'components/InputWithError';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const CreateLabelModal = (props) => {
    const {isOpen, toggle, refreshLabels, item, handleError, addFlashMessage} = props;
    const [name, setName] = useState('');
    const [errors, setErrors] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        item && setName(item.name);
    }, [item]);

    const handleSubmit = async() => {
        try {
            setSubmitting(true);
            setErrors(null);
            let params = {
                name,
            };
            await editAlertLabel(item.id, params);
            refreshLabels();
            addFlashMessage('success', 'Alert label successfully edited');
            setSubmitting(false);
            toggle();
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-label-modal">
            {!item ?
                <div/>
                :
                <div>
                    <ModalBodyWithClose toggle={toggle}>
                        <h2>Edit Label</h2>
                        <InputWithError
                            placeholder="Label name"
                            prependIcon={<i className="fa fa-tag"/>}
                            name="name"
                            value={name}
                            type="text"
                            onChange={setName}
                            errorObj={errors}
                        />
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <SpinnerButton
                            type={'modal'}
                            color="primary"
                            onClick={handleSubmit}
                            submitting={submitting}
                            title={'Update'}
                        />
                    </ModalFooter>
                </div>
            }
        </Modal>
    );
};

export default CreateLabelModal;
