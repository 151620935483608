import React from 'react';

import {Button} from 'reactstrap';

const EmptyList = ({toggleCreateNewsletter}) => {
    return (
        <div>
            <p>Create your first newsletter!</p>
            <Button onClick={toggleCreateNewsletter} color="primary">Create Newsletter</Button>
        </div>
    );
};

export default EmptyList;
