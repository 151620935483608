import React, {Suspense, useContext, useState, useEffect} from 'react';

import {
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarMinimizer,
    AppSidebarNav,
} from '@coreui/react';
import {Nav} from 'reactstrap';
import * as router from 'react-router-dom';

import navigation, {extendedNav} from './_nav';
import './style.scss';
import {AuthContext} from 'contexts/AuthContext';

const MyComponent = (props) => {
    const {state} = useContext(AuthContext);
    const [accessCompanyBasedAlerts, setAccessCompanyBasedAlerts] = useState(false);
    const [isDefaultOpen, setIsDefaultOpen] = useState(false);

    useEffect(() => {
        if (state.profile.organization?.accessCompanyBasedAlerts) {
            setAccessCompanyBasedAlerts(true);
        }
    }, [state.profile]);

    useEffect(() => {
        if (!isDefaultOpen) {
            document.getElementsByClassName(`nav-dropdown`)[0].classList.add('open');
        }
        setIsDefaultOpen(true);
    }, []);

    /*useEffect(() => {
        if (accessCompanyBasedAlerts) {
            document.getElementsByClassName(`beta`)[0].childNodes[0].classList.add('nav-link-extended');
            document.getElementsByClassName(`beta`)[0].childNodes[0].innerHTML += "<div class='label-beta'>BETA</div>";
        }
        setIsDefaultOpen(true);
    }, [accessCompanyBasedAlerts]);*/

    const mobileNav  = {
        items: [
            {
                name: 'Dashboard',
                url: '/dashboard',
                icon: 'fa fa-tachometer',
                attributes: {exact: true}
            },
            // {
            //     name: 'My Reader',
            //     url: `/account/reader/${state.profile.id}`,
            //     icon: 'fa fa-book',
            //     attributes: {exact: true}
            // },
        ]
    };

    return (
        <AppSidebar fixed display="lg">
            <Suspense>
                {window.innerWidth < 1024 &&
                <AppSidebarNav navConfig={mobileNav} {...props} className="p-0"/>
                }
                {
                    accessCompanyBasedAlerts
                        ? <AppSidebarNav navConfig={extendedNav} {...props} />
                        : <AppSidebarNav navConfig={navigation} {...props} router={router} />
                }
                {/*<AppSidebarNav {...props}>*/}
                {/*    <li className="nav-item nav-item--custom" style={{listStyle: 'none'}}>*/}
                {/*        <a className="nav-link" href="mailto:support@ozmosys.com">*/}
                {/*            <i className="nav-icon fas fa-envelope"/>*/}
                {/*            Contact Us*/}
                {/*        </a>*/}
                {/*    </li>*/}
                {/*</AppSidebarNav>*/}


                {/*<nav className="sidebar-nav">*/}
                {/*    <Nav>*/}
                {/*        <li className="nav-item">*/}
                {/*            <a className="nav-link" href="mailto:support@ozmosys.com">*/}
                {/*                <i className="fa fa-envelope" />*/}
                {/*                Contact Us*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*    </Nav>*/}
                {/*</nav>*/}


                <nav className="sidebar-nav">
                    <Nav>
                        <li className="nav-item nav-item--custom" style={{listStyle: 'none'}}>
                            <a className="nav-link" href="mailto:support@ozmosys.com">
                                <i className="nav-icon fa fa-envelope"/>
                                Contact Us
                            </a>
                        </li>
                    </Nav>
                </nav>
            </Suspense>
            <AppSidebarFooter/>
            <AppSidebarMinimizer/>
        </AppSidebar>
    );
};

export default router.withRouter(MyComponent);
