import React, {useState, useEffect, useContext} from 'react';
import {useParams} from 'react-router-dom';

import {FlashMessageContext} from 'contexts/FlashMessageContext';
import LoadingSpinner from 'components/LoadingSpinner';
import {getNewsletterById} from 'utils/api/newslettersAPI';
import Desktop from './Desktop';
import Mobile from './Mobile';
import {AuthContext} from 'contexts/AuthContext';

const ManageContent = () => {
    let {id} = useParams();
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError} = useContext(AuthContext);
    const [fetchingData, setFetchingData] = useState(true);
    const [newsletterData, setNewsletterData] = useState(true);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        id && fetchNewsletterData();
    }, []);

    const fetchNewsletterData = async() => {
        try {
            const newsletter = await getNewsletterById(id);
            setNewsletterData(newsletter);
            setFetchingData(false);
            initialLoad && setInitialLoad(false)
        } catch(err) {
            setFetchingData(false);
            handleError(err);
        }
    }


    if(fetchingData) return <LoadingSpinner text={'Fetching newsletter data'} padding/>;
    return (
        <div className="animated fadeIn h-100" style={{paddingTop: '1rem'}}>
            {window.innerWidth > 1024 ?
                <Desktop
                    addFlashMessage={addFlashMessage}
                    newsletterData={newsletterData}
                    refreshNewsletter={fetchNewsletterData}
                    handleError={handleError}
                    initialLoad={initialLoad}
                />
                :
                <Mobile
                    newsletterData={newsletterData}
                    refreshNewsletter={fetchNewsletterData}
                    handleError={handleError}
                />
            }

        </div>
    );
};

export default ManageContent;
