import React from "react";
import { GeoDropdownFilter } from "../../GeoDropdownFilter";
import FilterDropdown from "components/FilterDropdown";
import { Button, FormGroup, Input, Label } from "reactstrap";

export const FiltersBlock = (props) => {
  const {
    isAdvancedSearchShown,
    regions,
    countries,
    setRegions,
    activeCountryFilter,
    activeRegionFilter,
    activeSentimentFilter,
    sentimentList,
    minRelValue,
    setActiveCountryFilter,
    setActiveRegionFilter,
    setActiveSentimentFilter,
    setMinRelValue,
    isHasAccessElasticSearch,
    clearAllFilters,
    isSimple,
  } = props;
  return (
    <>
      {isAdvancedSearchShown || isSimple ? (
        <div className="mt-2 mb-3 flex">
          <div className="mr-3">
            <GeoDropdownFilter
              activeCountryFilter={activeCountryFilter}
              setActiveCountryFilter={setActiveCountryFilter}
              setActiveRegionFilter={setActiveRegionFilter}
              countries={countries || []}
              setRegions={setRegions}
              isCountyFilter
            />
          </div>
          {!!regions?.length && (
            <GeoDropdownFilter
              activeRegionFilter={activeRegionFilter}
              setActiveRegionFilter={setActiveRegionFilter}
              regions={regions || []}
            />
          )}
        </div>
      ) : (
        <div className="bordered-preview-list__summary">
          <GeoDropdownFilter
            activeCountryFilter={activeCountryFilter}
            setActiveCountryFilter={setActiveCountryFilter}
            setActiveRegionFilter={setActiveRegionFilter}
            countries={countries || []}
            setRegions={setRegions}
            isCountyFilter
          />
          {!!regions?.length && (
            <GeoDropdownFilter
              activeRegionFilter={activeRegionFilter}
              setActiveRegionFilter={setActiveRegionFilter}
              regions={regions || []}
            />
          )}
          {window.location.origin !== "https://news.ozmosys.com" && (
            <FilterDropdown
              title={"Sentiment"}
              activeFilter={activeSentimentFilter}
              setActiveFilter={setActiveSentimentFilter}
              list={sentimentList}
            />
          )}
          {isHasAccessElasticSearch && (
            <div>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    onChange={() => setMinRelValue(0.75)}
                    checked={minRelValue * 1 === 0.75}
                    name="0_75"
                  />{" "}
                  Top Results
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    onChange={() => setMinRelValue(0)}
                    checked={minRelValue * 1 === 0}
                    name="1_00"
                  />{" "}
                  All Results
                </Label>
              </FormGroup>
            </div>
          )}
          <Button
            color="link"
            className="btn-clear-all"
            style={{ width: "90px" }}
            onClick={clearAllFilters}
          >
            Clear all
          </Button>
        </div>
      )}
    </>
  );
};
