export const sortObjects = (arr, objectStringValue, direction) => {
    return direction === 'asc' ?
        arr.sort((a, b) => String(a[objectStringValue]).localeCompare(String(b[objectStringValue])))
        :
        arr.sort((a, b) => String(b[objectStringValue]).localeCompare(String(a[objectStringValue])));
};

export const sortObjectsByNumber = (arr, numValue, direction) => {
    return direction === 'asc' ?
        arr.sort((a, b) => a[numValue] - b[numValue])
        :
        arr.sort((a, b) => b[numValue]  - a[numValue])
};

export const sortByValueLength = (arr, valueToMeasure, direction) => {
    return direction === 'asc' ?
        arr.sort((a, b) => a[valueToMeasure].length - b[valueToMeasure].length)
        :
        arr.sort((a, b) => b[valueToMeasure].length - a[valueToMeasure].length)
};