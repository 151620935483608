import React from 'react';

const UnassignedSectionsListItem = (props) => {
    const {section, handleSectionSelect} = props;

    return (
        <div
            onClick={() => handleSectionSelect(section)}
            className="sections-list-item sections-list-item--unassigned"
        >
            <div className="sections-list-item__title">
                {section.name} <span className="ml-1" style={{color: 'gray'}}>(Will not be delivered)</span>
            </div>
        </div>
    );
};

export default UnassignedSectionsListItem;
