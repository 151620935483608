import React, { useState } from 'react';

import { Col, Row } from 'reactstrap';
import './style.scss';
import { convertFormatString } from 'Helpers/convertPhoneString';
import { useHistory } from 'react-router-dom';

const CompanyPageHeader = (props) => {
    const { alertData, publicMode } = props;

    const [image, setImage] = useState(alertData.image || null);
    const history = useHistory();

    const convertWebsite = () => {
        return alertData.website?.split('/').slice(0, 3).join('/');
    };

    const labelWebsite = () => {
        if (!convertWebsite()) {
            return convertWebsite();
        }

        const splitedWebsite = convertWebsite().split('://');
        return splitedWebsite.length === 2 ? splitedWebsite[1] : splitedWebsite[0];
    };

    const handleCompetitor = (item) => {
        history.push(item.alertId ? `/account/alerts/${item.alertId}/company-profile` : `/account/alerts/${item.symbol}/company-preview`);
    };

    const getAddress = () => {
        return alertData.address;
    };

    const getGeo = () => {
        let geo = alertData.city ? `${alertData.city}` : '';
        geo += alertData.state ? `${geo ? ', ' : ''}${alertData.state}` : '';
        geo += alertData.country ? `${geo ? ', ' : ''}${alertData.country}` : '';
        return geo;
    };

    const getCompetitors = () => {
        return alertData.competitors?.slice(0, 10).map((item, index) => {
            return (
                <>
                    {publicMode !== true ? (
                        <a href="#" onClick={() => handleCompetitor(item)} key={item.symbol}>
                            {item.symbol}&nbsp;
                        </a>
                    ) : (
                        item.symbol + ' '
                    )}
                    {(index + 1) % 5 === 0 && <br />}
                </>
            );
        });
    };

    return (
        <Row className="col-12">
            {image !== null ? (
                <Col xs={1250 >= window.innerWidth && window.innerWidth >= 750 ? '2' : window.innerWidth < 750 ? '3' : '1'}>
                    <div className="company-logo">
                        <img src={image} onError={() => setImage(null)} alt="Logo" />
                    </div>
                </Col>
            ) : null}

            {1250 < window.innerWidth && (
                <Row className="col-11">
                    <Col xs="12">
                        <div className="company-name">
                            {!!alertData.symbol && (
                                <strong>
                                    {alertData.companyName} ({alertData.symbol})
                                </strong>
                            )}
                        </div>
                    </Col>
                    <Col xs="2">
                        <h4 className="company-header">CEO</h4>
                        <p className="company-header-info">{alertData.ceo}</p>
                    </Col>
                    <Col xs="2">
                        <h4 className="company-header">Sector</h4>
                        <p className="company-header-info">{alertData.sector}</p>
                    </Col>
                    <Col xs="2">
                        <h4 className="company-header">Industry</h4>
                        <p className="company-header-info">{alertData.industry}</p>
                    </Col>
                    <Col xs="3">
                        <h4 className="company-header">Company Peers</h4>
                        <div className="competitors">{getCompetitors()}</div>
                    </Col>
                    <Col xs="3" className="company-header-info-block">
                        <p className="company-header-info">{getAddress()}</p>
                        <p className="company-header-info">{getGeo()}</p>
                        <p className="company-header-info">{convertFormatString(alertData.phone, alertData.country)}</p>
                        <a href={convertWebsite()} target="blank" className="company-header-info">
                            {labelWebsite()}
                        </a>
                    </Col>
                </Row>
            )}
            {1250 >= window.innerWidth && window.innerWidth >= 750 && (
                <Row className="col-10">
                    <Col xs="12">
                        <div className="company-name">
                            <strong>
                                {alertData.companyName} ({alertData.symbol})
                            </strong>
                        </div>
                    </Col>
                    <Col xs="6">
                        <div className="company-content-space-between">
                            <h4 className="company-header">CEO</h4>
                            <p className="company-header-info">{alertData.ceo}</p>
                        </div>
                        <div className="company-content-space-between">
                            <h4 className="company-header">Sector</h4>
                            <p className="company-header-info">{alertData.sector}</p>
                        </div>
                        <div className="company-content-space-between">
                            <h4 className="company-header">Industry</h4>
                            <p className="company-header-info">{alertData.industry}</p>
                        </div>
                    </Col>
                    <Col xs="6" className="company-header-info-block">
                        <p className="company-header-info">{getAddress()}</p>
                        <p className="company-header-info">{getGeo()}</p>
                        <p className="company-header-info">{convertFormatString(alertData.phone, alertData.country)}</p>
                        <a href={convertWebsite()} target="blank" className="company-header-info">
                            {labelWebsite()}
                        </a>
                    </Col>
                    <Col xs="6">
                        <div className="company-content-space-between">
                            <h4 className="company-header">Company Peers</h4>
                            <div className="competitors">{getCompetitors()}</div>
                        </div>
                    </Col>
                </Row>
            )}
            {750 > window.innerWidth && window.innerWidth >= 450 && (
                <Row className="col-9">
                    <Col xs="12">
                        <div className="company-name">
                            <strong>
                                {alertData.companyName} ({alertData.symbol})
                            </strong>
                        </div>
                    </Col>
                    <Col xs="12">
                        <Row>
                            <Col xs="6">
                                <h4 className="company-header">CEO</h4>
                            </Col>
                            <Col xs="6">
                                <p className="company-header-info">{alertData.ceo}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <h4 className="company-header">Sector</h4>
                            </Col>
                            <Col xs="6">
                                <p className="company-header-info">{alertData.sector}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <h4 className="company-header">Industry</h4>
                            </Col>
                            <Col xs="6">
                                <p className="company-header-info">{alertData.industry}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="6">
                                <h4 className="company-header">Company Peers</h4>
                            </Col>
                            <Col xs="6">
                                <div className="competitors">{getCompetitors()}</div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="12" className="company-header-info-block">
                                <p className="company-header-info">{getAddress()}</p>
                                <p className="company-header-info">{getGeo()}</p>
                                <p className="company-header-info">{convertFormatString(alertData.phone, alertData.country)}</p>
                                <a href={convertWebsite()} target="blank" className="company-header-info">
                                    {labelWebsite()}
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
            {450 > window.innerWidth && (
                <>
                    <Col xs="9">
                        <div className="company-name">
                            <strong>
                                {alertData.companyName} ({alertData.symbol})
                            </strong>
                        </div>
                    </Col>
                    <Row className="col-12" style={{ marginTop: '50px' }}>
                        <Col xs="12">
                            <Row>
                                <Col xs="6">
                                    <h4 className="company-header">CEO</h4>
                                </Col>
                                <Col xs="6">
                                    <p className="company-header-info">{alertData.ceo}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <h4 className="company-header">Sector</h4>
                                </Col>
                                <Col xs="6">
                                    <p className="company-header-info">{alertData.sector}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <h4 className="company-header">Industry</h4>
                                </Col>
                                <Col xs="6">
                                    <p className="company-header-info">{alertData.industry}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="6">
                                    <h4 className="company-header">Company Peers</h4>
                                </Col>
                                <Col xs="6">
                                    <div className="competitors">{getCompetitors()}</div>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs="12" className="company-header-info-block">
                                    <p className="company-header-info">{getAddress()}</p>
                                    <p className="company-header-info">{getGeo()}</p>
                                    <p className="company-header-info">{convertFormatString(alertData.phone, alertData.country)}</p>
                                    <a href={convertWebsite()} target="blank" className="company-header-info">
                                        {labelWebsite()}
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
        </Row>
    );
};

export default CompanyPageHeader;
