import React, {useState, useEffect, useContext} from 'react';
import {Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, Input, FormGroup, Label} from 'reactstrap';
import Logo from 'images/logo.png';
import {useHistory, useLocation} from 'react-router-dom';
import SpinnerButton from 'components/SpinnerButton';
import {checkRegistrationToken, finishRegistration} from 'utils/api/authAPI';
import queryString from 'query-string';
import {timezones} from 'utils/timezones';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import InputWithError from 'components/InputWithError';
import SelectWithError from 'components/InputWithError/SelectWithError';

const ConfirmRegistration = (props) => {
    let history = useHistory();
    const {addFlashMessage} = useContext(FlashMessageContext)
    const {signIn, handleError} = useContext(AuthContext)

    const [token, setToken] = useState(null);
    const [tokenChecked, setTokenChecked] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [timezone, setTimezone] = useState('');
    const [accountType, setAccountType] = useState('single');
    const [organizationName, setOrganizationName] = useState('');
    const [errors, setErrors] = useState(null);

    let queryParams = useLocation();

    const redirectToInvalidLink = () => {
        history.push('/invite-expired');
    };

    const checkToken = async(token) => {
        try {
            const parsedQuery = queryString.parse(queryParams.search);
            await checkRegistrationToken(parsedQuery.token);
            setToken(parsedQuery.token);
            setTokenChecked(true);
        } catch(err) {
            redirectToInvalidLink();
        }
    };

    const onSubmit = async() => {
        try {
            if(accountType === 'organization' && organizationName === '') {
                setSubmitting(false);
                let err = {
                    code: "VALIDATION_FAILED",
                    errors: {
                        organizationName: [{message: "This field is required", code: "NULL", payload: null}]
                    },
                    message: "Validation failed"
                }
                handleError(err, setErrors);
                return;
            }
            setSubmitting(true);
            const params = {
                token,
                plainPassword: {
                    first: password,
                    second: confirmPassword
                },
                fullName,
                timezone,
                organization: accountType === 'organization' ? organizationName : null,
            };
            const res = await finishRegistration(params)
            signIn(res.content.token)
            // setSubmitting(false)
            // history.push('/');

            // signIn(res.content.token)
            history.push('/account');

        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    useEffect(() => {
        checkToken();
    }, []);


    if(!tokenChecked) {
        return null;
    }
    return (
        <div className="auth-block">
            <div className="auth-block__form">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{marginBottom: '.5rem'}}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo}
                                         alt="Ozmosys"
                                         className="auth-block__image img-fluid"
                                    />
                                </a>
                            </div>
                            <div>
                                <div className="text-center mb-4">
                                    <h2>Complete Registration</h2>
                                </div>

                                <div>
                                    <FormGroup tag="fieldset">
                                        <FormGroup check className="mb-2">
                                            <Label check>
                                                <Input type="radio"
                                                       name="radio1"
                                                       onChange={() => setAccountType('single')}
                                                       checked={accountType === 'single'}
                                                />{' '}
                                                Single User
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check className="mb-1">
                                            <Label check>
                                                <Input type="radio"
                                                       name="radio1"
                                                       onChange={() => setAccountType('organization')}
                                                       checked={accountType === 'organization'}
                                                />{' '}
                                                Organization
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                </div>

                                {accountType === 'organization' &&
                                <div>
                                    <Label>Organization Info</Label>
                                    <InputWithError
                                        placeholder="Organization Name"
                                        prependIcon={<i className="fa fa-building"/>}
                                        name="organizationName"
                                        value={organizationName}
                                        type="text"
                                        onChange={setOrganizationName}
                                        errorObj={errors}
                                        autoComplete="off"
                                        marginBottomClass="mb-3"
                                    />
                                </div>
                                }

                                <div>
                                    {accountType === 'organization' && <Label>User Info</Label>}
                                    <div>
                                        <InputWithError
                                            placeholder="Full Name"
                                            prependIcon={<i className="fa fa-user"/>}
                                            name="fullName"
                                            value={fullName}
                                            type="text"
                                            onChange={setFullName}
                                            errorObj={errors}
                                            autoComplete="off"
                                            marginBottomClass="mb-3"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <InputWithError
                                        placeholder="Password (Min 6 Characters)"
                                        prependIcon={<i className="fa fa-lock"/>}
                                        name="password"
                                        value={password}
                                        type="password"
                                        onChange={setPassword}
                                        errorObj={errors}
                                        autoComplete="off"
                                        marginBottomClass="mb-3"
                                    />
                                </div>

                                <div>
                                    <InputWithError
                                        placeholder="Confirm Password"
                                        prependIcon={<i className="fa fa-lock"/>}
                                        name="plainPassword"
                                        value={confirmPassword}
                                        type="password"
                                        onChange={setConfirmPassword}
                                        errorObj={errors}
                                        autoComplete="off"
                                        marginBottomClass="mb-3"
                                    />
                                </div>

                                {/*<InputGroup>*/}
                                {/*    <InputGroupAddon addonType="prepend">*/}
                                {/*        <InputGroupText>*/}
                                {/*            <i className="fa fa-lock"/>*/}
                                {/*        </InputGroupText>*/}
                                {/*    </InputGroupAddon>*/}
                                {/*    <Input*/}
                                {/*        placeholder="Confirm Password"*/}
                                {/*        type="password"*/}
                                {/*        name="confirmPassword"*/}
                                {/*        onChange={(e) => setConfirmPassword(e.target.value)}*/}
                                {/*        value={confirmPassword}*/}
                                {/*    />*/}
                                {/*</InputGroup>*/}

                                <SelectWithError
                                    name="timezone"
                                    value={timezone}
                                    type="select"
                                    options={timezones}
                                    onChange={setTimezone}
                                    prependIcon={<i className="fa fa-globe"/>}
                                    errorObj={errors}
                                    marginBottomClass="mb-3"
                                >
                                    <option value={''} disabled>
                                        Timezone
                                    </option>
                                    {timezones.map(timezone => {
                                        return (
                                            <option
                                                key={timezone.key}
                                                value={timezone.key}
                                            >
                                                {timezone.name}
                                            </option>
                                        );
                                    })}
                                </SelectWithError>

                                {/*<InputGroup>*/}
                                {/*    <InputGroupAddon addonType="prepend">*/}
                                {/*        <InputGroupText>*/}
                                {/*            <i className="fa fa-globe"/>*/}
                                {/*        </InputGroupText>*/}
                                {/*    </InputGroupAddon>*/}
                                {/*    <Input*/}
                                {/*        name="timezone"*/}
                                {/*        value={timezone}*/}
                                {/*        type="select"*/}
                                {/*        options={timezones}*/}
                                {/*        defaultValue={null}*/}
                                {/*        onChange={(e) => setTimezone(e.target.value)}*/}
                                {/*    >*/}
                                {/*        <option value={null} disabled>*/}
                                {/*            Timezone*/}
                                {/*        </option>*/}
                                {/*        {timezones.map(timezone => {*/}
                                {/*            return (*/}
                                {/*                <option*/}
                                {/*                    key={timezone.key}*/}
                                {/*                    value={timezone.key}*/}
                                {/*                >*/}
                                {/*                    {timezone.name}*/}
                                {/*                </option>*/}
                                {/*            );*/}
                                {/*        })}*/}
                                {/*    </Input>*/}
                                {/*</InputGroup>*/}

                                {/*<InputGroup>*/}
                                {/*    <InputGroupAddon addonType="prepend">*/}
                                {/*        <InputGroupText>*/}
                                {/*            <i className="fas fa-globe-americas"/>*/}
                                {/*        </InputGroupText>*/}
                                {/*    </InputGroupAddon>*/}
                                {/*    <Input type="select"*/}
                                {/*           name="timezone"*/}
                                {/*           options={timezones}*/}
                                {/*           onChange={(e) => setTimezone(e.target.value)}*/}
                                {/*    >*/}
                                {/*        <option value={''} selected disabled>Timezone</option>*/}
                                {/*        {Object.keys(timezones).map(key => (*/}
                                {/*            <option key={key} value={key}>*/}
                                {/*                {key.replace('_', ' ')}*/}
                                {/*            </option>*/}
                                {/*        ))}*/}
                                {/*    </Input>*/}
                                {/*</InputGroup>*/}

                                <Row>
                                    <Col xs="12" className="text-right">
                                        <SpinnerButton
                                            type="submit"
                                            color="primary"
                                            className="px-4"
                                            block
                                            onClick={onSubmit}
                                            submitting={submitting}
                                            title={'Sign In'}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
        ;
};

export default ConfirmRegistration;
