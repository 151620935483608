import React, { useState } from 'react';

import { Button, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment-timezone';

import { convertDateString } from 'Helpers/convertDateString';
import { useHistory } from 'react-router-dom';
import { sendInstantNewsletter } from 'utils/api/newslettersAPI';

const NewsletterListItem = (props) => {
    const { newsletter, addFlashMessage, toggleEditModal, setItemToPreview, setLastSentItemToPreview, setItemToDelete, handleError } = props;

    const history = useHistory();
    const [sendingNewsletter, setSendingNewsletter] = useState(false);
    const scheduleSettings = newsletter.scheduleSettings;
    const instantDeliveryEmail = scheduleSettings?.instant_delivery_email;

    const goToManageContent = (id) => {
        history.push(`/account/newsletters/content/${id}`);
    };

    const goToAssignment = (id) => {
        history.push(`/account/newsletters/assign/${id}`);
    };

    const numberToOrdinal = (n) => {
        return n + (n % 100 >= 11 && n % 100 <= 13 ? 'th' : n % 10 === 1 ? 'st' : n % 10 === 2 ? 'nd' : n % 10 === 3 ? 'rd' : 'th');
    };

    const buildSchedule = () => {
        const specificRunDate = scheduleSettings?.specific_date_time?.run_date ?? null;
        const specificRunTime = scheduleSettings?.specific_date_time?.run_time ?? null;

        if (scheduleSettings?.as_available) {
            return 'As Available';
        } else if (scheduleSettings?.monthly) {
            const ordinal = scheduleSettings.monthly?.run_day;
            const runTime = scheduleSettings.monthly?.run_time;

            return (
                <>
                    On the {numberToOrdinal(ordinal) ?? ''} of each month <br /> at {moment(runTime, 'HH:mm').format('hh:mm A')}{' '}
                    {newsletter.timezone.replace(/_/g, ' ')}
                </>
            );
        } else if (scheduleSettings?.daily) {
            const firstDaysArray = [];
            const secondDaysArray = [];
            const first = scheduleSettings?.daily?.first;
            const second = scheduleSettings?.daily?.second;
            const allDays = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

            (first?.days_of_week ?? []).map((item) => {
                firstDaysArray.push(allDays[Number(item)]);
            });
            (second?.days_of_week ?? []).map((item) => {
                secondDaysArray.push(allDays[Number(item)]);
            });

            return (
                <span>
                    {firstDaysArray.join(', ')}
                    <br />
                    {'at ' + moment(first?.run_time, 'HH:mm').format('hh:mm A') + ' '}
                    {second ? (
                        <>
                            {newsletter.timezone.replace(/_/g, ' ')}
                            <br />
                            {secondDaysArray.join(', ')}
                            <br />
                            at {moment(second?.run_time, 'HH:mm').format('hh:mm A')}
                        </>
                    ) : (
                        ''
                    )}{' '}
                    {newsletter.timezone.replace(/_/g, ' ')}
                </span>
            );
        } else {
            if (instantDeliveryEmail) {
                return 'Instant Delivery';
            } else if (moment(specificRunDate + ' ' + specificRunTime).isBefore(moment())) {
                if (newsletter.numberAssignedUsers > 0) {
                    return (
                        <span>
                            {`Delivered on ` + moment(specificRunTime, 'HH:mm').format('hh:mm A') + ' ' + newsletter.timezone.replace(/_/g, ' ')}
                        </span>
                    );
                } else {
                    return <span>{`Delivered to ${newsletter.numberAssignedUsers} recipients`}</span>;
                }
            } else {
                return (
                    <span>
                        {'Scheduled for ' + moment(specificRunDate).format('MM/DD/YYYY')}
                        <br />
                        {'at ' + moment(specificRunTime, 'HH:mm').format('hh:mm A') + ' ' + newsletter.timezone.replace(/_/g, ' ')}
                    </span>
                );
            }
        }
    };

    const sendInstantDeliveryNewsletter = async () => {
        try {
            setSendingNewsletter(true);
            await sendInstantNewsletter(newsletter.id);
            addFlashMessage('success', 'Newsletter successfully sent');
            setSendingNewsletter(false);
        } catch (err) {
            setSendingNewsletter(false);
            handleError(err);
        }
    };

    return (
        <tr className="newsletter-list-item">
            <td data-label="Name">
                {/*<div className="link-style" onClick={() => setItemToPreview(newsletter)}>*/}
                <div>{newsletter.name}</div>
            </td>
            <td data-label="Type">{instantDeliveryEmail || scheduleSettings?.specific_date_time ? '(Curated)' : '(Automatic)'}</td>
            <td data-label="Created At">{convertDateString(newsletter.createdAt)}</td>
            <td data-label="Last Sent Date">
                {newsletter.lastSentEmail && newsletter.lastSentEmail.sentAt ? (
                    <div className="link-style" onClick={() => setLastSentItemToPreview(newsletter)}>
                        {convertDateString(newsletter.lastSentEmail.sentAt)}
                    </div>
                ) : (
                    <span>Newsletter has not been sent</span>
                )}
            </td>
            <td data-label="Active">{newsletter.isEnabled && <i className="fa fa-check" />}</td>
            <td data-label="Content">
                <Button color="primary" onClick={() => goToManageContent(newsletter.id)}>
                    Manage Sources
                </Button>
                <div className="d-flex flex-column">
                    {scheduleSettings?.daily?.first?.days_of_week?.length ||
                    scheduleSettings?.daily?.second?.days_of_week?.length ||
                    scheduleSettings?.as_available ? (
                        <strong>
                            {newsletter.numberAlerts} {newsletter.numberAlerts === 1 ? 'Alert' : 'Alerts'}
                        </strong>
                    ) : (
                        <strong>
                            {newsletter.numberNews} {newsletter.numberNews === 1 ? 'Article' : 'Articles'}
                        </strong>
                    )}
                </div>
            </td>
            <td data-label="Assignment">
                {!instantDeliveryEmail && (
                    <>
                        <Button color="primary" onClick={() => goToAssignment(newsletter.id)}>
                            Assign
                        </Button>
                        <div>
                            <strong>{newsletter.numberAssignedUsers + (newsletter.numberAssignedUsers === 1 ? ' Recipient' : ' Recipients')}</strong>
                        </div>
                    </>
                )}
            </td>
            <td data-label="Schedule">{buildSchedule()}</td>
            <td data-label="Last 30 Days Clicks">{newsletter.countClicks}</td>
            <td data-label="Send Now">
                {instantDeliveryEmail &&
                    (sendingNewsletter ? (
                        <Button color="light" style={{ height: 35, width: 40 }}>
                            <i className="fa fa-spinner fa-spin" />
                        </Button>
                    ) : (
                        <>
                            <UncontrolledTooltip placement="bottom" target="send-instant-delivery-button" delay={{ show: 200, hide: 0 }}>
                                Send Now
                            </UncontrolledTooltip>
                            <Button id="send-instant-delivery-button" color="light" onClick={sendInstantDeliveryNewsletter}>
                                <i className="fa fa-paper-plane" />
                            </Button>
                        </>
                    ))}
            </td>
            <td data-label="Actions">
                <div className="responsive-table__edit-block">
                    <Button color="light" onClick={() => toggleEditModal(newsletter)}>
                        <i className="fa fa-edit fa-lg" />
                    </Button>{' '}
                    <Button className="delete-button" color="light" onClick={() => setItemToDelete(newsletter)}>
                        <i className="fa fa-trash fa-lg" />
                    </Button>
                </div>
            </td>
        </tr>
    );
};

export default NewsletterListItem;
