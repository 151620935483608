import React, {useState, useEffect, useContext} from 'react';

import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import LoadingSpinner from 'components/LoadingSpinner';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import {getAlertGroups, getAlertsWithoutParams, universalAssignAlerts} from 'utils/api/alertsAPI';
import {getUserGroups, getUsersWithoutParams} from 'utils/api/usersAPI';
import DualSelect from 'components/DualSelect';
import './style.scss';
import {useHistory} from 'react-router-dom';
import ConfirmAllAssignment from 'containers/GlobalAssignment/ConfirmAllModal';

const GlobalAssignment = () => {
    let history = useHistory();
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError} = useContext(AuthContext)
    const [tempAssignedUsers, setTempAssignedUsers] = useState([]);
    const [tempAssignedAlerts, setTempAssignedAlerts] = useState([]);
    const [fetchingAlerts, setFetchingAlerts] = useState(true);
    const [fetchingUsers, setFetchingUsers] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [users, setUsers] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [alertGroups, setAlertGroups] = useState([]);
    const [confirmAssignModalOpen, setConfirmAssignModalOpen] = useState(false);

    useEffect(() => {
        fetchAlerts();
        fetchUsers();
    }, []);

    const fetchAlerts = async() => {
        try {
            !fetchingAlerts && setFetchingAlerts(true);
            let alertData = await getAlertsWithoutParams(false, false, 1, 2000);
            let alertGroupData = await getAlertGroups();
            setAlerts(alertData.result);
            setAlertGroups(alertGroupData);
            setFetchingAlerts(false);
        } catch(err) {
            setFetchingAlerts(false);
            addFlashMessage('danger', 'Unable to fetch alerts');
        }
    };

    const fetchUsers = async() => {
        try {
            !fetchingUsers && setFetchingUsers(true);
            let userData = await getUsersWithoutParams(false, false, 1, 2000);
            let userGroupData = await getUserGroups();
            setUsers(userData.result);
            setUserGroups(userGroupData);
            setFetchingUsers(false);
        } catch(err) {
            setFetchingUsers(false);
            handleError(err);
        }
    };

    const onSubmit = async() => {
        try {
            if(!tempAssignedUsers.length || !tempAssignedAlerts) {
                addFlashMessage('danger', 'At least one user and one alert must be selected');
                return;
            }
            setSubmitting(true);
            if(tempAssignedAlerts.length === alerts.length) {
                toggleConfirmModal();
                setSubmitting(false);
            } else {
                const params = {
                    users: tempAssignedUsers.map(user => user.id),
                    alerts: tempAssignedAlerts.map(alert => alert.id),
                };
                await universalAssignAlerts(params);
                setSubmitting(false);
                addFlashMessage('success', 'Alerts successfully assigned');
                history.push(`/account/alerts`);
            }
        } catch(err) {
            setSubmitting(false);
            handleError(err);
        }
    };

    const toggleConfirmModal = () => {
        setConfirmAssignModalOpen(!confirmAssignModalOpen);
    }

    return (
        <div className="view view--mobile-full global-assign">
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <h1>Routing</h1>
                            </CardHeader>
                            <CardBody>
                                {(fetchingAlerts || fetchingUsers) ?
                                    <div>
                                        <LoadingSpinner/>
                                    </div>
                                    :
                                    <div className="mt-3">
                                        <div>
                                            <h3>Select Users</h3>
                                            <DualSelect
                                                selectedList={tempAssignedUsers}
                                                fullList={users}
                                                groups={userGroups}
                                                onSelect={setTempAssignedUsers}
                                                contentType="users"
                                                hideDoubleArrows
                                            />
                                        </div>

                                        <div className="mt-5">
                                            <h3>Select Content</h3>
                                            <DualSelect
                                                selectedList={tempAssignedAlerts}
                                                fullList={alerts}
                                                groups={alertGroups}
                                                onSelect={setTempAssignedAlerts}
                                                contentType="alerts"
                                                hideDoubleArrows
                                            />
                                        </div>
                                        <div className="global-assign__submit-container">
                                            <SpinnerButton
                                                color="primary"
                                                submitting={submitting}
                                                onClick={onSubmit}
                                                title="Save"
                                            />
                                        </div>
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            {confirmAssignModalOpen &&
                <ConfirmAllAssignment
                    toggle={toggleConfirmModal}
                    isOpen={confirmAssignModalOpen}
                    tempAssignedUsers={tempAssignedUsers}
                    tempAssignedAlerts={tempAssignedAlerts}
                    history={history}
                    addFlashMessage={addFlashMessage}
                    handleError={handleError}
                />
            }
        </div>
    );
};

export default GlobalAssignment;
