import React from 'react';

import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const DeleteLabel = (props) => {
    const {isOpen, toggle, onSubmit, submitting} = props;

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="confirm-suspend-modal">
            <ModalBodyWithClose toggle={toggle}>
                <span>Are you sure you want to suspend your account?</span>
            </ModalBodyWithClose>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                <SpinnerButton
                    type={'modal'}
                    color="danger"
                    onClick={() => onSubmit()}
                    submitting={submitting}
                    title={'Delete'}
                />
            </ModalFooter>
        </Modal>
    );
};

export default DeleteLabel;
