import React, {useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {getAlertLabels} from 'utils/api/alertsAPI';
import '../CreateAndEdit/style.scss';
import CompanyBasedForm from '../CreateAndEdit/CompanyBased';

const CreateCompanyBased = () => {
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        fetchLabels();
    }, []);

    const fetchLabels = async() => {
        try {
            const foundLabels = await getAlertLabels();
            setLabels(foundLabels);
        } catch(err) {
            console.log(err);
        }
    };

    const refreshLabels = async() => {
        const refreshedLabels = await getAlertLabels();
        setLabels(refreshedLabels);
    };

    return (
        <div className="animated fadeIn view">
            <Row className="mt-3">
                <Col xs="12">
                    <div className="alert-container">
                        <h1>Add a Company</h1>
                        <CompanyBasedForm labels={labels} refreshLabels={refreshLabels}/>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CreateCompanyBased;
