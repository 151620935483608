import React, {useState, useEffect, useRef} from 'react';

import Toggle from 'react-toggle';
import ReactSlider from 'react-slider';
import {AutoSizer, List, CellMeasurer, CellMeasurerCache} from 'react-virtualized';
import {cloneDeep} from 'lodash';

import SearchInput from 'components/SearchInput';
import {filterByValue} from 'Helpers/filterByValue';
import {getFreeCuratedArticlesByDate, moveArticleBetweenNewsletterSections} from 'utils/api/newslettersAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import Article from './Article';
import AssignedArticle from './AssignedArticle';

const cache = new CellMeasurerCache({
    fixedWidth: true,
});

const AddArticles = (props) => {
    const {newsletterData, section, setNewsletterForDom, addFlashMessage, handleError} = props;
    const [unassignedSearchValue, setUnassignedSearchValue] = useState('');
    const [assignedSearchValue, setAssignedSearchValue] = useState('');
    const [filteredUnassignedList, setFilteredUnassignedList] = useState([]);
    const [filteredAssignedList, setFilteredAssignedList] = useState([]);
    const [retrievalDate, setRetrievalDate] = useState(5);
    const [fetchingArticles, setFetchingArticles] = useState(true);
    const [articles, setArticles] = useState([]);
    const [includeAbstracts, setIncludeAbstracts] = useState(true);
    const [tempSliderValue, setTempSliderValue] = useState(1);
    const [updatingList, setUpdatingList] = useState(false);
    const listRef = useRef(null);

    useEffect(() => {
        getArticlesByDays();
        setTempSliderValue(5);
        cache.clearAll();
    }, []);

    const getArticlesByDays = async(updating) => {
        try {
            if(updating) {
                setUpdatingList(true);
            } else {
                !fetchingArticles && setFetchingArticles(true);
            }
            let articles = await getFreeCuratedArticlesByDate(newsletterData.id, retrievalDate);
            setArticles(articles);
            applyFilters('unassigned', articles);
            updating ? setUpdatingList(false) : setFetchingArticles(false);
        } catch(err) {
            updating ? setUpdatingList(false) : setFetchingArticles(false);
            handleError(err);
        }
    };

    useEffect(() => {
        applyFilters('unassigned', articles);
    }, [newsletterData, section]);

    useEffect(() => {
        applyFilters('assigned', section.newsletterSectionNews);
    }, [section.newsletterSectionNews]);

    const applyFilters = (list, arr) => {
        if(list === 'unassigned') {
            let newArr = filterByValue(arr, 'title', unassignedSearchValue);
            setFilteredUnassignedList(newArr);
        } else {
            let newArr = filterAssignedByValue(arr, 'title', assignedSearchValue);
            setFilteredAssignedList(newArr);
        }

        cache.clearAll();
        listRef && listRef.current && listRef.current.recomputeRowHeights();
    };

    const filterAssignedByValue = (arr, propertyToFilterBy, searchValue) => {
        let newArr = [...arr];
        newArr = newArr.filter(item => {
            if(item.news[propertyToFilterBy]) {
                return item.news[propertyToFilterBy].toLowerCase().includes(searchValue.toLowerCase());
            }
        })
        return newArr;
    }

    useEffect(() => {
        applyFilters('unassigned', articles);
    }, [unassignedSearchValue]);

    useEffect(() => {
        applyFilters('assigned', section.newsletterSectionNews);
    }, [assignedSearchValue]);

    const toggleIncludeAbstracts = () => setIncludeAbstracts(!includeAbstracts);

    useEffect(() => {
        cache.clearAll();
        listRef && listRef.current && listRef.current.recomputeRowHeights();
    }, [includeAbstracts])

    const handleSliderChange = (e) => {
        setTempSliderValue(e);
    };

    const handleSliderConfirm = () => {
        setRetrievalDate(tempSliderValue);
    };

    useEffect(() => {
        articles.length > 0 && getArticlesByDays(true);
    }, [retrievalDate]);

    const handleAddArticle = async(articleId) => {
        try {
            const {sections} = newsletterData;

            const nextColumn = sections.find(obj => obj.id === section.id);
            const nextColumnIndex = sections.indexOf(nextColumn);

            const newStateUnassignedNews = cloneDeep(articles);
            const selectedArticle = newStateUnassignedNews.find(
                article => article.id === articleId
            );
            if(!selectedArticle) return;
            const nextColumnNews = Array.from(nextColumn.newsletterSectionNews);

            let newArticleObj = {
                comment: null,
                news: {
                    createdAt: selectedArticle.createdat,
                    id: selectedArticle.id,
                    newsResource: {id: null, domain: selectedArticle.resource, mediaBias: null},
                    title: selectedArticle.title,
                    url: null,
                },
                sortOrder: nextColumnNews.length + 1
            }
            nextColumnNews.push(newArticleObj);
            const newNextColumn = {
                ...nextColumn,
                newsletterSectionNews: nextColumnNews
            };
            newStateUnassignedNews.splice(
                newStateUnassignedNews.indexOf(selectedArticle),
                1
            );
            const newStateSections = cloneDeep(sections);
            newStateSections[nextColumnIndex] = newNextColumn;
            const newNewsletter = {
                ...newsletterData,
                sections: newStateSections
            };
            setNewsletterForDom(newNewsletter)
            setArticles(newStateUnassignedNews)

            await moveArticleBetweenNewsletterSections(newsletterData.id, articleId, 0, section.id, 1);
        } catch(err) {
            handleError(err);
        }
    }

    const handleRemoveArticle = async(articleId) => {
        try {
            const {sections} = newsletterData;

            const selectedSection = sections.find(obj => obj.id === section.id);
            const selectedSectionIndex = sections.indexOf(selectedSection);

            const newStateUnassignedNews = cloneDeep(articles);
            const newStateSections = cloneDeep(sections);

            const selectedArticle = selectedSection.newsletterSectionNews.find(
                article => article.news.id === articleId
            );
            if(!selectedArticle) return;
            const selectedSectionNews = Array.from(
                selectedSection.newsletterSectionNews
            );
            selectedSectionNews.splice(selectedSectionNews.indexOf(selectedArticle), 1);
            const newSelectedSection = {
                ...selectedSection,
                newsletterSectionNews: selectedSectionNews
            };

            let newArticleObj = {
                abstract: null,
                createdat: selectedArticle.news.createdAt,
                id: selectedArticle.news.id,
                resource: selectedArticle.news.newsResource.domain,
                sectionid: null,
                sectionName: null,
                title: selectedArticle.news.title
            }

            newStateUnassignedNews.unshift(newArticleObj);
            newStateSections[selectedSectionIndex] = newSelectedSection;
            const newNewsletter = {
                ...newsletterData,
                sections: newStateSections
            };
            setNewsletterForDom(newNewsletter)
            setArticles(newStateUnassignedNews)

            await moveArticleBetweenNewsletterSections(newsletterData.id, articleId, section.id, 0, 1);
        } catch(err) {
            handleError(err);
        }
    }

    const rowRenderer = ({ index, key, style, parent }) => {
        if (filteredUnassignedList[index]) {
            return (
                <CellMeasurer key={key}
                              cache={cache}
                              parent={parent}
                              columnIndex={0}
                              rowIndex={index}>
                    <div className="mobile-article-row-item" style={{ ...style }}>
                        <Article
                            article={filteredUnassignedList[index]}
                            includeAbstracts={includeAbstracts}
                            handleAddArticle={handleAddArticle}
                        />
                    </div>
                </CellMeasurer>
            );
        }
    };


    return (
        <div className="mobile-newsletter-add-alerts">
            <h5>{section.name}</h5>
            {!fetchingArticles && articles.length &&
            <div className="mobile-newsletter-toggle-container">
                    <span id="suspend-label">
                        View Abstracts
                    </span>
                <label>
                    <Toggle
                        checked={includeAbstracts}
                        onChange={toggleIncludeAbstracts}
                        icons={false}
                    />
                </label>
            </div>
            }
            <div className="d-flex align-items-center justify-content-between mt-3 mb-1" style={{overflow: 'hidden'}}>
                <h6 className="m-0">Articles List</h6>
                {!fetchingArticles && articles.length &&
                <div className="mobile-newsletter-slider-container">
                    <div className="mobile-newsletter-slider-container__label">
                        {Number(tempSliderValue) === 1 ? tempSliderValue + ' day' : tempSliderValue + ' days'}
                    </div>
                    <ReactSlider
                        className="mobile-newsletter-days-slider"
                        thumbClassName="mobile-newsletter-days-slider__thumb"
                        trackClassName="mobile-newsletter-days-slider__track"
                        disabled={updatingList}
                        min={1}
                        max={30}
                        withTracks
                        step={1}
                        onChange={(val) => handleSliderChange(val)}
                        value={tempSliderValue}
                        onAfterChange={(val) => handleSliderConfirm(val)}
                    />
                </div>
                }
            </div>
            {fetchingArticles ?
                <div className="pt-1">
                    <LoadingSpinner text={'Fetching articles'}/>
                </div>
                :
                <>
                    <div>
                        <div className="mb-2">
                            <SearchInput
                                onClear={() => setUnassignedSearchValue('')}
                                onChange={setUnassignedSearchValue}
                                placeholder="Search by article name"
                                value={unassignedSearchValue}
                            />
                        </div>
                    </div>
                    <div className="mobile-newsletter-add-alerts__list mb-4" style={{height: '400px'}}>
                        {articles.length ?
                            updatingList ?
                                <div className="p-3">
                                    <LoadingSpinner text={'Refreshing articles'}/>
                                </div>
                                :
                                filteredUnassignedList.length > 0 ?
                                    <div style={{height: '100%'}}>
                                        <AutoSizer>
                                            {({height, width}) => (
                                                <List
                                                    ref={listRef}
                                                    width={width}
                                                    height={height}
                                                    deferredMeasurementCache={cache}
                                                    rowHeight={cache.rowHeight}
                                                    rowCount={filteredUnassignedList.length}
                                                    rowRenderer={rowRenderer}
                                                />
                                            )}
                                        </AutoSizer>
                                    </div>
                                    :
                                    <div style={{padding: '.5rem'}}>No results found</div>
                            :
                            <div style={{padding: '.5rem'}}>No articles found</div>
                        }
                    </div>
                </>
            }
            <h6 className="mt-3">Assigned Articles</h6>
            <div>
                <div className="mb-2">
                    <SearchInput
                        onClear={() => setAssignedSearchValue('')}
                        onChange={setAssignedSearchValue}
                        placeholder={'Search by article name'}
                        value={assignedSearchValue}
                    />
                </div>
            </div>
            <div className="mobile-newsletter-add-alerts__list mb-4">
                {section.newsletterSectionNews.length > 0 ?
                    filteredAssignedList.length > 0 ?
                        filteredAssignedList.map(article => {
                            return (
                                <AssignedArticle
                                    key={article.id}
                                    article={article}
                                    includeAbstracts={includeAbstracts}
                                    handleRemoveArticle={handleRemoveArticle}
                                    assigned
                                    withControls
                                />
                            );
                        })
                        :
                        <div style={{padding: '.5rem'}}>No results found</div>
                    :
                    <div style={{padding: '.5rem'}}>No assigned articles</div>
                }
            </div>

        </div>
    );
};

export default AddArticles;