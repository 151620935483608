import React from 'react';
import { Button } from 'reactstrap';

const SpinnerButton = (props) => {
    const { type, submitting, title, disabled, block, color, onClick, className } = props;

    return (
        <Button
            color={color}
            style={{
                minWidth: '91px',
                minHeight: type === 'modal' ? '35px' : '0',
            }}
            type="button"
            className={'px-4 ' + className}
            disabled={disabled}
            block={window.innerWidth < 450 || block}
            onClick={() => !submitting && onClick()}>
            {submitting ? <i className="fa fa-spinner fa-spin" /> : title}
        </Button>
    );
};

export default SpinnerButton;
