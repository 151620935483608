import moment from 'moment-timezone';

export const convertDateString = (dateString, withHour) => {
    if(!dateString) return null;
    // let obj = new Date(dateString.replace(/-/,' '));
    let obj = moment(dateString);
    let tz = moment.tz.guess();
    return withHour ? moment(obj).tz(tz).format('MM/DD/YYYY [at] h:mm A')
        : moment(obj).tz(tz).format('MM/DD/YYYY')
};

export const convertDateStringV2 = (dateString) => {
    if(!dateString) return null;
    let obj = moment(dateString);
    let tz = moment.tz.guess();
    return moment(obj).tz(tz).format('MM/DD/YYYY');
};