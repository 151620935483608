import React, {useState, useEffect} from 'react';
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import AlertPreviewItem from 'components/AlertPreviewItem';
import LoadingSpinner from 'components/LoadingSpinner';
import SubmitAndLabelsContainer from '../SubmitAndLabelsContainer';

const List = (props) => {
    const {
        preview, hideSlider, median, showHeader, setActivePreviewSortValue,
        activePreviewSortValue, setMinRelValue, minRelValue, updatingPreview,
        submitting, handleSubmit, isEditForm, selectedLabels, labels,
        addLabel, addToSelected, removeFromSelected, creatingLabel, isSingleUser
    } = props;

    const [pageNumbers, setPageNumbers] = useState([]);
    const [articlesPerPage, setArticlesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPaginationDisplay, setCurrentPaginationDisplay] = useState(10);
    const [windowSize, setWindowSize] = useState(null);

    useEffect(() => {
        const pageNumbers = [];
        for(let i = preview.content.length; i > 0; i -= 10) {
            pageNumbers.push(i);
        }
        setPageNumbers(pageNumbers);
        setCurrentPage(1);
        setWindowSize(window.innerWidth);
    }, [props.preview]);

    const handlePaginationClick = (e) => {
        let num = Number(e.target.innerText);
        setCurrentPage(num);
    };

    const previousPage = () => {
        if(currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if(currentPage < pageNumbers.length) {
            let newPage = currentPage + 1;
            setCurrentPage(newPage);
        }
    };

    const renderPagination = () => {
        if(currentPage <= 4) {
            let firstPage = 0;
            let lastPage = windowSize > 600 ? 10 : 6;
            return pageNumbers.slice(firstPage, lastPage).map((item) => {
                let index = pageNumbers.indexOf(item);
                return (
                    <PaginationItem key={index + 1} active={index + 1 == currentPage}>
                        <PaginationLink id={index + 1} key={index + 1} onClick={handlePaginationClick}>
                            {index + 1}
                        </PaginationLink>
                    </PaginationItem>
                );
            });
        } else {
            let firstPage = windowSize > 600 ? currentPage - 5 : currentPage - 3;
            let lastPage = windowSize > 600 ? currentPage + 5 : currentPage + 3;
            return pageNumbers.slice(firstPage, lastPage).map((item) => {
                let index = pageNumbers.indexOf(item);
                return (
                    <PaginationItem active={index + 1 == currentPage}>
                        <PaginationLink id={index + 1} key={index + 1} onClick={handlePaginationClick}>
                            {index + 1}
                        </PaginationLink>
                    </PaginationItem>
                );
            });
        }
    };

    return (
        <div className="mt-2 internal-preview-list bordered-preview-list">
            <div className="bordered-preview-list__summary">
                <strong>{preview.count} {preview.count === 1 ? 'article' : 'articles'} matched your filters for the last 5 days</strong>
            </div>
            <div className="mb-3 d-flex">
                <div className="bordered-preview-list__left">
                    <div style={{marginTop: '20px'}}>
                        {
                            preview.content.length ?
                                preview.content.slice((currentPage * 10 - 10), (currentPage * 10)).map(item => (
                                    <AlertPreviewItem item={item} key={item.id} withHour/>
                                ))
                                :
                                <div>
                                    <h5>No results found</h5>
                                </div>
                        }
                    </div>
                </div>
                <div className="bordered-preview-list__right mobile-hide">
                    {updatingPreview ?
                        <div className="relevance-selector-container">
                            <LoadingSpinner text="Applying search relevance"/>
                        </div>
                        :
                        <SubmitAndLabelsContainer
                            preview={preview}
                            updatingPreview={updatingPreview}
                            submitting={submitting}
                            handleSubmit={handleSubmit}
                            isEditForm={isEditForm}
                            selectedLabels={selectedLabels}
                            labels={labels}
                            addLabel={addLabel}
                            addToSelected={addToSelected}
                            removeFromSelected={removeFromSelected}
                            creatingLabel={creatingLabel}
                            hideSlider={hideSlider}
                            isSingleUser={isSingleUser}
                        />
                    }
                </div>
            </div>
            {pageNumbers.length > 1 &&
            <Pagination style={{marginTop: '10px'}}>
                <PaginationItem>
                    <PaginationLink previous onClick={previousPage}/>
                </PaginationItem>
                {renderPagination()}
                <PaginationItem>
                    <PaginationLink next onClick={nextPage}/>
                </PaginationItem>
            </Pagination>
            }
        </div>
    );
};

export default List;
