import React, {useState, useEffect, useContext} from 'react';

import SectionsListItem from './SectionsListItem';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import UnassignedSection from './UnassignedSectionsListItem';
import AddSection from './Modals/RenameSection';
import {Button} from 'reactstrap';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import {sortNewsletterSections} from 'utils/api/newslettersAPI';

const SectionsList = (props) => {
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {state, handleError} = useContext(AuthContext);

    const {updateSectionsId, newsletterData, handleSectionSelect,
        refreshNewsletter, handleRefresh} = props;
    const [addSectionModalOpen, setAddSectionModalOpen] = useState(false);
    const [savingSectionChanges, setSavingSectionChanges] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [unassignedSection, setUnassignedSection] = useState(null);


    useEffect(() => {
        let foundUnassignedSection = newsletterData.sections.find(
            section => section.name === 'Unassigned',
        );
        setUnassignedSection(foundUnassignedSection);
    }, [newsletterData]);

    const handleSectionSorting = async(sortOrderArr) => {
        try {
            setSavingSectionChanges(true);
            await sortNewsletterSections(newsletterData.id, {sections: sortOrderArr});
            refreshNewsletter();
            setSavingSectionChanges(false);
        } catch(err) {
            setSavingSectionChanges(false);
            handleError(err);
        }
    }

    const onDragEnd = async(result) => {
        const {destination, source, draggableId} = result;
        if(!destination) {
            return;
        }
        if(
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const newTaskIds = Array.from(newsletterData.sections);
        const selectedSection = newsletterData.sections.find(
            obj => obj.id == draggableId,
        );

        newTaskIds.splice(newTaskIds.indexOf(selectedSection), 1);
        newTaskIds.splice(destination.index, 0, selectedSection);

        const submitArray = newTaskIds.reverse().map((section, i) => {
            return {id: section.id, sortOrder: i};
        });
        updateSectionsId(newTaskIds.reverse());
        await handleSectionSorting(submitArray);
    };

    const toggleAddSectionModal = () => {
        setAddSectionModalOpen(!addSectionModalOpen);
    };

    const handleAddButton = () => {
        let sectionLimit = state.profile.organization.limitNewsletterSections;
        if(newsletterData.sections.length >= sectionLimit + 1) {
            addFlashMessage(
                'danger',
                `Maximum section limit for this newsletter (${sectionLimit}) has been reached.`,
            );
            setSubmitting(false);
        } else {
            toggleAddSectionModal()
        }
    };

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-2">
                <h4 className="m-0">Sections</h4>
                <Button
                    style={{minWidth: '104.39px'}}
                    size="small"
                    color="primary"
                    onClick={handleAddButton}
                >
                    Add Section
                </Button>
            </div>
            <div className="section-list-inner">
                <DragDropContext onDragEnd={onDragEnd}>
                    <div>
                        <Droppable
                            droppableId="all-sections"
                            direction="vertical"
                            type="section"
                        >
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {newsletterData.sections &&
                                    newsletterData.sections.map((section, i) => {
                                        return (
                                            section.name !== 'Unassigned' && (
                                                <SectionsListItem
                                                    key={section.id}
                                                    section={section}
                                                    handleSectionSelect={handleSectionSelect}
                                                    index={i}
                                                />
                                            )
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        {unassignedSection && (
                            <UnassignedSection
                                section={unassignedSection}
                                handleSectionSelect={handleSectionSelect}
                            />
                        )}
                    </div>
                </DragDropContext>
            </div>
            {addSectionModalOpen && (
                <AddSection
                    isOpen={addSectionModalOpen}
                    refreshNewsletter={refreshNewsletter}
                    type="add"
                    handleRefresh={handleRefresh}
                    newsletterId={newsletterData.id}
                    toggle={toggleAddSectionModal}
                    addFlashMessage={addFlashMessage}
                    handleError={handleError}
                />
            )}
        </div>
    );
};


export default SectionsList;
