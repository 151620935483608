import React, {useEffect, useState, useContext} from 'react';

import {Button, Modal, ModalBody} from 'reactstrap';
import moment from 'moment-timezone';

import LoadingSpinner from 'components/LoadingSpinner';
import {convertDateString} from 'Helpers/convertDateString';
import {getNewsletterPreview} from 'utils/api/newslettersAPI';
import ModalBodyWithClose from 'components/ModalBodyWithClose';
import PdfLogo from 'images/pdf-outline.svg';
import SpinnerButtonSquare from 'components/SpinnerButton/Square';
import {AuthContext} from 'contexts/AuthContext';
import './style.scss';

const NewsletterPreview = (props) => {
    const {toggle, isOpen, newsletter, lastSentId} = props;
    const {handleError} = useContext(AuthContext);
    const [fetchingData, setFetchingData] = useState(true);
    const [fetchingDataError, setFetchingDataError] = useState(false);
    const [newsletterDisabled, setNewsletterDisabled] = useState(false);
    const [previewContent, setPreviewContent] = useState(null);
    const [downloadingPdf, setDownloadingPdf] = useState(false);
    const [viewInBrowserLink, setViewInBrowserLink] = useState(null);

    const fetchNewsletterPreview = async() => {
        try {
            let preview = await getNewsletterPreview(newsletter.id, lastSentId);
            setPreviewContent(preview);
            lastSentId && preview.browser_link && setViewInBrowserLink(preview.browser_link);
            setFetchingData(false);
        } catch(err) {
            if(err.code === 'LOGICAL_ERROR' && err.message === 'Newsletter disabled') {
                setNewsletterDisabled(true);
            } else {
                setFetchingDataError(true);
            }
            setFetchingData(false);
        }
    };

    const fetchNewsletterPdf = async() => {
        try {
            setDownloadingPdf(true);
            await getNewsletterPreview(newsletter.id, lastSentId, true, `newsletter-${newsletter.id}`);
            setDownloadingPdf(false);
        } catch(err) {
            setDownloadingPdf(false);
            handleError(err);
        }
    };

    useEffect(() => {
        newsletter && fetchNewsletterPreview();
    }, [newsletter]);

    const handleScroll = () => {
        let anchor = document.querySelector('#anchor');
        // anchor.scrollIntoView();
        anchor.scrollIntoView({behavior: "smooth"});
    };

    useEffect(() => {
        if(previewContent && previewContent.hasOwnProperty('html')) {
            setTimeout(() => {
                let elList = document.querySelectorAll('.scroll-to-link-button');
                elList.forEach((el) => {
                    el.addEventListener('click', () => {
                        let name = el.getAttribute('tabindex');
                        let elementToScrollTo = document.getElementById(name);
                        elementToScrollTo.scrollIntoView({behavior: "smooth"});
                    });
                })
            }, 700);
        }
    }, [previewContent]);

    if(!newsletter) return <div/>;
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="newsletter-preview-modal modal-lg">
            <div>
                <ModalBodyWithClose toggle={toggle}>
                    <div className="d-flex justify-content-between mb-2">
                        <div className="newsletter-preview-modal__header">
                            <h2 className="mb-1">{lastSentId ? 'Last Sent Newsletter' : 'Newsletter Preview'}</h2>
                            <h5 className="mb-1">{newsletter.name}</h5>
                            <h5 className="mb-0">{lastSentId ? convertDateString(newsletter.lastSentEmail.sentAt)  : convertDateString(moment())}</h5>
                        </div>
                        {
                            previewContent && previewContent.html &&
                            <div className="d-flex align-items-end">
                                <SpinnerButtonSquare
                                    submitting={downloadingPdf}
                                    color="primary"
                                    className="mr-2"
                                    onClick={fetchNewsletterPdf}
                                    title={<img height={24} src={PdfLogo} alt="Download-Pdf"/>}
                                />
                                <Button onClick={handleScroll}>
                                    {window.innerWidth > 600 ?
                                        'Back to top'
                                        :
                                        <i className="fa fa-chevron-up"/>
                                    }
                                </Button>
                            </div>
                        }
                    </div>
                    {fetchingDataError ?
                        <div className="mt-4 mb-3">
                            <div className="error-block d-flex">
                                <i className="fa fa-exclamation-circle"/>
                                <div>Unable to fetch newsletter content at this time. Please try again later.</div>
                            </div>
                        </div>
                        :
                        <div className="newsletter-preview-modal__body">
                            <div id="anchor"/>
                            {fetchingData ?
                                <div style={{padding: '1rem 0'}}>
                                    <LoadingSpinner text={'Fetching newsletter content'}/>
                                </div>
                                :
                                (previewContent && previewContent.html) ?
                                    <div>
                                        {viewInBrowserLink &&
                                        <div className="newsletter-preview-vib-link-container" style={{backgroundColor: '#f2f2f2'}}>
                                            <a
                                                href={viewInBrowserLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="newsletter-preview-vib-link"
                                            >
                                                View in Browser
                                            </a>
                                        </div>
                                        }
                                        <div
                                            className="email-preview-inner"
                                            dangerouslySetInnerHTML={{__html: previewContent.html}}
                                        />
                                    </div>
                                    :
                                    newsletterDisabled ?
                                        <div className="pt-3 pb-3 pl-1">
                                            This newsletter is currently set to inactive. Activate this newsletter in
                                            the Edit Newsletter page to preview content.
                                        </div>
                                        :
                                        <div className="pt-3 pb-3 pl-1">
                                            No Newsletter content is available yet. Please check again later for updated
                                            content.
                                        </div>

                            }
                        </div>
                    }
                </ModalBodyWithClose>
            </div>


        </Modal>
    );
};

export default NewsletterPreview;



