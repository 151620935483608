import React, {useContext, useEffect, useState} from 'react';

import {Row, Col} from 'reactstrap';

import ClicksPerDay from './ClicksPerDay';
import AlertsBreakout from './AlertsBreakout';
import TopArticles from './TopArticles';
import TopPublishers from './TopPublishers';
import TopAlerts from './TopAlerts';
import {AuthContext} from 'contexts/AuthContext';

const NewsletterStatistics = ({handleError}) => {
    const [labelContainerHeight, setLabelContainerHeight] = useState(null);
    const [articleListLength, setArticleListLength] = useState(null);
    const [publisherListLength, setPublisherListLength] = useState(null);
    const [alertListLength, setAlertListLength] = useState(null);

    useEffect(() => {
        if(Number.isInteger(articleListLength) && Number.isInteger(publisherListLength) && Number.isInteger(alertListLength)) {
            let largest = Math.max(articleListLength, publisherListLength, alertListLength);
            let containerHeight = largest > 0 ? largest * 21 + 20 : 41;
            setLabelContainerHeight(containerHeight);
        }
    }, [articleListLength, publisherListLength, alertListLength]);

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <ClicksPerDay
                        type="newsletter"
                    />
                </Col>
            </Row>
            {/*<AlertsBreakout type="articles"/>*/}
            <Row>
                <Col xl={4} lg={6} md={12} xs={12}>
                    <TopArticles
                        type="newsletter"
                        labelContainerHeight={labelContainerHeight}
                        setArticleListLength={setArticleListLength}
                        handleError={handleError}
                    />
                </Col>
                <Col xl={4} lg={6} md={12} xs={12}>
                    <TopPublishers
                        type="newsletter"
                        labelContainerHeight={labelContainerHeight}
                        setPublisherListLength={setPublisherListLength}
                        handleError={handleError}
                    />
                </Col>
                <Col xl={4} lg={6} md={12} xs={12}>
                    <TopAlerts
                        type="newsletter"
                        labelContainerHeight={labelContainerHeight}
                        setAlertListLength={setAlertListLength}
                        handleError={handleError}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default NewsletterStatistics;
