import React from 'react'
import {Row, Col} from 'reactstrap'
import logo from 'images/logo.png'
import './style.scss'

const PrivacyPolicyAuth = () => {
    return (
        <div className="animated fadeIn privacy-policy-auth">
            <Row className="mt-3">
                <Col xs="12" style={{maxWidth: '800px', margin: '0 auto'}}>
                    <div className="privacy-policy-logo">
                        <img src={logo} alt="logo"/>
                    </div>
                    <h2>Privacy Notice</h2>
                    <p>This privacy notice discloses the privacy practices for <a href="/" target="_blank">Ozmosys, Inc</a>. This privacy notice applies solely to information collected by this website.</p>

                    <h4>Information Collection, Use, and Sharing</h4>
                    <p>We only collect information that you voluntarily provide us. We will not sell or share this information to anyone or with any third-party.</p>

                    <h4>Security</h4>
                    <p>We take precautions to protect your information. Wherever we collect sensitive information, that information is encrypted and transmitted to us securely.</p>

                    <strong><p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to <a href="mailto:support@ozmosys.com">contact us.</a></p></strong>
                </Col>
            </Row>
        </div>
    )
}


export default PrivacyPolicyAuth
