import React, {useState} from 'react'

import {InputGroup, Input, Col, Row} from 'reactstrap'

import SearchInput from 'components/SearchInput/SearchInputWithSubmit';

const SearchForm = (props) => {
    const {
        searchValue, searchValueError,
        searchSubmitting, resetForm, handleCreatePreview,
        searchValueEditWarning, errorObj, internalGroup
    } = props;

    const onPressEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleCreatePreview()
        }
    };

    const handleSearchChange = (e) => {
        props.handleSearchValueChange(e)
    };

    return (
        <Row>
            <Col xs={12} md={12}>
                <InputGroup className="rss-search-form mb-0">
                    <SearchInput
                        onChange={handleSearchChange}
                        onSelect={handleSearchChange}
                        name="url"
                        placeholder="RSS Feed Url"
                        value={searchValue}
                        hasError={errorObj && errorObj.errors.hasOwnProperty('url')}
                        type="url"
                        autoComplete
                        style={{
                            border: (errorObj && errorObj.errors.hasOwnProperty('url')) ? '1px solid red' : ''
                        }}
                        onSubmit={handleCreatePreview}
                        submitting={searchSubmitting}
                        onClear={resetForm}
                        noMarginBottom
                        disabled={internalGroup}
                    />
                </InputGroup>
                {errorObj && errorObj.errors.hasOwnProperty('url') &&
                <div className="mt-1 error-text">{errorObj.errors['url'][0].message || errorObj.errors['url']}</div>
                }
            </Col>
            {/*{internalGroup && <Col xs={12} style={{color: 'gray', marginTop: '1px'}}><strong>*URLs from Alert Groups cannot be changed.</strong></Col>}*/}
            <Col xs={12}>
                {searchValueEditWarning &&
                <div className="error-text" style={{marginTop: '.5rem', paddingBottom: '.5rem', maxWidth: '600px'}}>
                    The existing feed will be deleted and a new one will be created.
                    The new feed will be auto-assigned to the same users.</div>
                }
                {searchValueError &&
                <div className="rss-preview-error"
                     // style={{marginBottom: searchValueEditWarning ? '.5rem' : '0'}}
                     style={{marginBottom: '.5rem'}}
                >{searchValueError}</div>
                }
            </Col>
        </Row>
    );
};

export default SearchForm;
