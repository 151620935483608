import React, {useEffect, useState} from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import {Table, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Col} from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment-timezone';
import {getUserUsageEmailsPerPeriod} from 'utils/api/statisticsAPI';
import CsvButton from 'containers/Reports/CsvButton';

const UserUsageEmailsReport = () => {
    const [fetchingData, setFetchingData] = useState(false);
    const [fetchingCsv, setFetchingCsv] = useState(false);
    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'month').format('MM-DD-YYYY'));
    const [toDate, setToDate] = useState(moment().format('MM-DD-YYYY'));
    const [records, setRecords] = useState([]);
    const totalPages = Math.floor(records.length / 2);
    const [activeSortProperty, setActiveSortProperty] = useState('userFullname');
    const [activeSortDirection, setActiveSortDirection] = useState('asc');
    const [isShowContents, setIsShowContents] = useState({});
    const [isShowBounces, setIsShowBounces] = useState({});

    useEffect(async () => {
        setFetchingData(true);
        setRecords(
            await getUserUsageEmailsPerPeriod(
                moment(fromDate).format('YYYY-MM-DD'),
                moment(toDate).format('YYYY-MM-DD'),
                activeSortProperty,
                activeSortDirection
            )
        );
        setFetchingData(false);
    }, [fromDate, toDate, activeSortProperty, activeSortDirection]);

    const downloadFilteredCsv = async() => {
        setFetchingCsv(true);
        await getUserUsageEmailsPerPeriod(
            moment(fromDate).format('YYYY-MM-DD'),
            moment(toDate).format('YYYY-MM-DD'),
            activeSortProperty,
            activeSortDirection,
            true
        );
        setFetchingCsv(false);
    }

    const handlePageClick = (pageNum) => {
        setCurrentPageNum(pageNum.selected);
        console.log(
            currentPageNum,
            (currentPageNum-1)*2, currentPageNum*2,
            records.slice(currentPageNum*2, (currentPageNum+1)*2)
        );
    };

    const handleFromDateChange = day => {
        setFromDate(moment(day).format('MM-DD-YYYY'));
    };

    const handleToDateChange = day => {
        setToDate(moment(day).format('MM-DD-YYYY'));
    };

    const handleCaret = (value) => {
        if(activeSortProperty === value) {
            return activeSortDirection === 'asc' ?
                <i className="fa fa-caret-up list-sort-caret list-sort-caret--active"/>
                :
                <i className="fa fa-caret-down list-sort-caret list-sort-caret--active"/>;
        } else {
            return <i className="fa fa-caret-down list-sort-caret"/>;
        }
    };

    const handleSortableHeaderClick = (value) => {
        if(value === activeSortProperty) {
            activeSortDirection === 'asc' ? setActiveSortDirection('desc') : setActiveSortDirection('asc');
        } else {
            setActiveSortProperty(value);
            setActiveSortDirection('asc');
        }
    };

    const toggleShowContents = (index) => {
        isShowContents[index] = isShowContents[index] ? !isShowContents[index] : true;
        setIsShowContents(isShowContents);
    }

    const toggleShowBounces = (index) => {
        isShowBounces[index] = isShowBounces[index] ? !isShowBounces[index] : true;
        setIsShowBounces(isShowBounces);
    }

    const showBounces = (bounces) => {
        return  bounces.map((bounce, key) => (
            <div key={key}><span>{bounce.type}</span>: <span>{bounce.count}</span></div>
        ));
    }

    return (
    <div className="list">
    {fetchingData ?
        <LoadingSpinner/>
        :
        <div>
        <div className="list__utility-row">
            <div className="list__search-container">
                <DayPickerInput
                    value={new Date(fromDate)}
                    dayPickerProps={{
                        disabledDays: {
                            before: new Date(moment().subtract(12, 'months').format('YYYY-MM-DD')),
                            after: new Date(toDate),
                        },
                    }}
                    autoComplete={false}
                    inputProps={{ref: null}}
                    onDayChange={handleFromDateChange}
                    component={props => (
                        <InputGroup className="pr-1 mb-0">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-calendar"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                {...props}
                                placeholder="MM/DD/YYYY"
                                value={fromDate}
                            />
                        </InputGroup>
                    )}
                />
                <DayPickerInput
                    value={new Date(toDate)}
                    dayPickerProps={{
                        disabledDays: {
                            before: new Date(fromDate),
                            after: new Date(),
                        },
                    }}
                    autoComplete={false}
                    inputProps={{ref: null}}
                    onDayChange={handleToDateChange}
                    component={props => (
                        <InputGroup className="pr-1 mb-0">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="fa fa-calendar"/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                {...props}
                                placeholder="MM/DD/YYYY"
                                value={toDate}
                            />
                        </InputGroup>
                    )}
                />
                <CsvButton
                        downloadFilteredCsv={downloadFilteredCsv}
                        downloading={fetchingCsv}
                />
            </div>
        </div>
        {records.length < 1 ?
            <div>
                No results found
            </div>
            :
            <div>
                <Table className="responsive-table alert-list-table">
                    <tbody>
                    <tr className="responsive-table header-row">
                        <th 
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('userFullname')}
                        >User {handleCaret('userFullname')}
                        </th>
                        <th 
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('numberSentEmails')}
                        >Number of Sent {handleCaret('numberSentEmails')}</th>
                        <th 
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('numberOpenedEmails')}
                        >Number of opened {handleCaret('numberOpenedEmails')}</th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('rateOpenedEmails')}
                        >Rate (%) {handleCaret('rateOpenedEmails')}</th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('numberBouncedEmails')}
                        >Number of  Bounced {handleCaret('numberBouncedEmails')}</th>
                        <th>Assigned titles</th>
                        <th>Titles</th>
                        <th>Type</th>
                        <th>Labels</th>
                    </tr>
                    {records.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.userFullname}</td>
                                <td>{item.numberSentEmails}</td>
                                <td>{item.numberOpenedEmails}</td>
                                <td>{item.rateOpenedEmails}</td>
                                <td>
                                    {
                                        item.numberBouncedEmails > 0 ?
                                            (
                                                isShowBounces[index]
                                                    ?
                                                    <>
                                                        {showBounces(item.bounces)}
                                                        {item.numberBouncedEmails} <a key={index} href='#' onClick={() => toggleShowBounces(index)}>&nbsp;Hide</a>
                                                    </>
                                                    :
                                                    <>
                                                        {item.numberBouncedEmails} <a key={index} href='#' onClick={() => toggleShowBounces(index)}>Show</a>
                                                    </>
                                            )
                                        : 0
                                    }
                                </td>
                                <td>{item.countAlerts}</td>
                                <td colSpan={2} style={{'whiteSpace': 'pre-line'}}>
                                    
                                    {
                                        isShowContents[index] 
                                        ? 
                                        <>
                                            {item.alerts?.map(alert => {
                                                return (
                                                    <Row>
                                                        <Col xs="6">{alert.title}</Col>
                                                        <Col xs="6">{alert.type}</Col>
                                                    </Row>
                                                );
                                            })}
                                            <a key={index} href='#' onClick={() => toggleShowContents(index)}>&nbsp;Hide</a>
                                        </>
                                        : 
                                        <a key={index} href='#' onClick={() => toggleShowContents(index)}>Show</a> 
                                    }
                                </td>
                                <td style={{'whiteSpace': 'pre-line'}}>
                                    {
                                        item.labels?.length
                                        ? 
                                            <>
                                                {
                                                    isShowContents[index] 
                                                    ? 
                                                    <>
                                                        {item.labels.join('\n')}
                                                        <a key={index} href='#' onClick={() => toggleShowContents(index)}>&nbsp;Hide</a>
                                                    </>
                                                    : 
                                                    <a key={index} href='#' onClick={() => toggleShowContents(index)}>Show</a> 
                                                }
                                            </>
                                        : item.labels.join('\n')
                                    }
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
                {/* <PaginationWrapper
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                /> */}
            </div>
        }
        </div>
    }
    </div>
    );
};

export default UserUsageEmailsReport;
