import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Collapse, Button, CardBody, Card, CardHeader, Input, UncontrolledTooltip} from 'reactstrap';
import Alert from './Alert';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import SpinnerButton from 'components/SpinnerButton/Small';
import {deleteNewsletterSection, editNewsletterSection} from 'utils/api/newslettersAPI';

const UnassignedSection = (props) => {
    const {
        section, index, assignAlertToSection, removeAllAlertsFromSection,
        newsletterId, refreshNewsletter, addFlashMessage, handleError,
        sectionDragInfo,
    } = props;
    const [alertListOpen, setAlertListOpen] = useState(false);
    const [commentFormOpen, setCommentFormOpen] = useState(false);
    const [titleFormOpen, setTitleFormOpen] = useState(false);
    const [commentValue, setCommentValue] = useState('');
    const [titleValue, setTitleValue] = useState('');
    const [deletingSection, setDeletingSection] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        setCommentValue(section.comment);
        setTitleValue(section.name);
    }, []);

    useEffect(() => {
        if(!commentFormOpen) {
            commentValue !== section.comment && setCommentValue(section.comment);
        }
    }, [commentFormOpen]);

    const toggleAlertListOpen = () => {
        setAlertListOpen(!alertListOpen);
    };

    return (

        <div className="mt-2">
            <Droppable droppableId={String(section.id)} type="alert">
                {(provided, snapshot) => (
                    <div
                        className={'newsletter-section ' +
                        (snapshot.isDraggingOver ?
                            (sectionDragInfo && sectionDragInfo.destinationId === sectionDragInfo.sourceId ?
                                'section-is-dragging-over-source'
                                :
                                'section-is-dragging-over')
                            : '')
                        }
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        // className="newsletter-section"
                    >
                        <div className="section-collapse d-flex flex-column">
                            <CardHeader
                                className={'section-collapse-toggle newsletter-section__header ' +
                                (snapshot.isDragging ? 'newsletter-section__header--dragging' : '')}
                                color="primary"
                                onClick={toggleAlertListOpen}
                            >
                                <div className="newsletter-section__block-1">
                                    <i className={'fa fa-angle-right ' + (alertListOpen ? 'rotate-90-down' : '')}/>
                                    <div className="newsletter-section__title ml-2">
                                        <span>{section.name}</span>
                                    </div>
                                    &nbsp;
                                    <div
                                        className="newsletter-section__item-cnt newsletter-section__item-cnt--unassigned">
                                        {section.alerts.length === 1 ? section.alerts.length + ' Source' : section.alerts.length + ' Sources'}
                                        <span className={section.alerts.length > 0 ? 'error-text' : ''}> (Sources in this section will not be delivered)</span>
                                    </div>
                                </div>
                                <div className="newsletter-section__block-2">
                                    <Button
                                        color="secondary"
                                        id={'clear-alerts-' + section.id}
                                        className="newsletter-section__header-btn newsletter-section__header-btn--clear"
                                        onClick={(e) => removeAllAlertsFromSection(e, section.id)}
                                        disabled={section.alerts.length < 1}
                                    >
                                        Clear
                                    </Button>
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        delay={{show: 200, hide: 0}}
                                        target={'clear-alerts-' + section.id}
                                    >
                                        Remove all alerts from this section
                                    </UncontrolledTooltip>
                                </div>
                            </CardHeader>
                            <Collapse isOpen={alertListOpen}>
                                <CardBody className="section-collapse-body p-0">
                                    <div className="section-body">
                                        <div
                                            className="section-list"
                                        >
                                            {section.alerts.length === 0 && (
                                                <div className="empty-section-item">
                                                    No Alerts have been added to this section yet
                                                </div>
                                            )}
                                            {section.alerts.length > 0 && alertListOpen && section.alerts
                                                .sort((a, b) => {
                                                    if(a.name < b.name) { return -1; }
                                                    if(a.name > b.name) { return 1; }
                                                    return 0;
                                                })
                                                .map((alert, i) => {
                                                    return (
                                                        <Alert
                                                            key={section.id + '-' + alert.id}
                                                            alert={alert}
                                                            index={i}
                                                            assignAlertToSection={assignAlertToSection}
                                                            sectionId={section.id}
                                                        />
                                                    );
                                                })
                                            }
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                </CardBody>
                            </Collapse>
                        </div>
                    </div>
                )}
            </Droppable>
        </div>

    );
};

export default UnassignedSection;


// import React, {useState} from 'react';
//
// import {CardHeader, CardBody, Button, UncontrolledTooltip, Collapse} from 'reactstrap';
// import {Droppable} from 'react-beautiful-dnd';
//
// import Alert from './Alert';
//
// const UnassignedSection = (props) => {
//     const {section, assignAlertToSection, removeAllAlertsFromSection} = props;
//     const [sectionListOpen, setSectionListOpen] = useState(false);
//
//     const toggleSectionListOpen = () => {
//         setSectionListOpen(!sectionListOpen);
//     };
//
//     return (
//         <Droppable droppableId={String(section.id)} type="alert">
//             {(provided, snapshot) => (
//                 <div
//                     className={'newsletter-section newsletter-section--unassigned ' + (snapshot.isDraggingOver ? 'section-is-dragging-over' : '')}
//                     ref={provided.innerRef}
//                     {...provided.droppableProps}
//                 >
//                     <CardHeader
//                         className={'section-collapse-toggle newsletter-section__header ' +
//                         (snapshot.isDragging ? 'newsletter-section__header--dragging' : '')}
//                         onClick={toggleSectionListOpen}
//                     >
//                         <div className="newsletter-section__block-1">
//                             <i className={'fa fa-angle-right ' + (sectionListOpen ? 'rotate-90-down' : '')}/>
//                             <div className="newsletter-section__title ml-2">
//                                 {section.name}
//                             </div>
//                             &nbsp;
//                             <div className="newsletter-section__item-cnt newsletter-section__item-cnt--unassigned">
//                                 {section.alerts.length === 1 ? section.alerts.length + ' Alert' : section.alerts.length + ' Alerts'}
//                                 <span className={section.alerts.length > 0 ? 'error-text' : ''}> (Alerts in this section will not be delivered)</span>
//                             </div>
//                         </div>
//                         <div className="newsletter-section__block-2">
//                             <Button
//                                 color="secondary"
//                                 id={'clear-alerts-' + section.id}
//                                 className="newsletter-section__header-btn newsletter-section__header-btn--clear"
//                                 onClick={(e) => removeAllAlertsFromSection(e, section.id)}
//                                 disabled={section.alerts.length < 1}
//                             >
//                                 Clear Alerts
//                             </Button>
//                             <UncontrolledTooltip
//                                 placement="bottom"
//                                 delay={{show: 200, hide: 0}}
//                                 target={'clear-alerts-' + section.id}
//                             >
//                                 Remove all alerts from this section
//                             </UncontrolledTooltip>
//                         </div>
//                     </CardHeader>
//
//                     <CardBody className="p-0">
//                         <Collapse isOpen={sectionListOpen}>
//                             {section.alerts.length > 0 ?
//                                 <div className="newsletter-section__alert-list">
//                                     {section.alerts.map((alert, index) => (
//                                         <Alert
//                                             key={alert.id}
//                                             alert={alert}
//                                             index={index}
//                                             assignAlertToSection={assignAlertToSection}
//                                             sectionId={section.id}
//                                         />
//                                     ))}
//                                 </div>
//                                 :
//                                 <div className="newsletter-section-alert">
//                                     No Alerts have been added to this section yet
//                                 </div>
//                             }
//                             {provided.placeholder}
//                         </Collapse>
//                     </CardBody>
//                 </div>
//             )}
//         </Droppable>
//     );
// };
//
// export default UnassignedSection;