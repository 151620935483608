import React, { useEffect, useState } from 'react';

import { Button } from 'reactstrap';
import { convertDateString } from 'Helpers/convertDateString';
import { truncateLongWords } from 'Helpers/truncateLongWords';
import { truncateWithoutWordBreak } from 'Helpers/truncateWithoutWordBreak';
import SectionDropdown from 'containers/Newsletters/ManageContent/Desktop/ReviewArticles/Articles/SectionDropdown';

const Article = (props) => {
    const { article, assignUnassignedArticleToSection, hideArticle, sections, includeAbstracts } = props;
    const [assignmentSection, setAssignmentSection] = useState();

    useEffect(() => {
        article.sectionid && setAssignmentSection({ id: article.sectionid, name: article.sectionname });
    }, [article]);

    return (
        <div className="newsletter-content-article">
            <div className="newsletter-content-article__content">
                <div className="newsletter-content-article__title">
                    <a href={article.url} target="_blank">
                        {article.title.length > 95 ? (
                            <strong>{truncateLongWords(truncateWithoutWordBreak(article.title, 95), 50)}</strong>
                        ) : (
                            <strong>{truncateLongWords(article.title, 100)}</strong>
                        )}
                    </a>
                </div>
                {includeAbstracts && article.abstract && (
                    <div className="newsletter-content-article__abstract">
                        {article.abstract.length > 250 ? truncateWithoutWordBreak(article.abstract, 250) : article.abstract}
                    </div>
                )}
                <div className="newsletter-content-article__info">
                    <span className="newsletter-content-article__source">{truncateLongWords(article.resource, 100)}</span>
                    <span className="newsletter-content-article__date">{convertDateString(article.createdat)}</span>
                </div>
            </div>
            <div className="newsletter-content-article__utility">
                {/*<Button color="primary">{assignmentSection && assignmentSection.name} <i className="fa fa-caret-down"/></Button>*/}
                <SectionDropdown
                    assignmentSection={assignmentSection}
                    setAssignmentSection={setAssignmentSection}
                    sections={sections}
                    articleId={article.id}
                />
                <i className="fa fa-plus" onClick={() => assignUnassignedArticleToSection(article.id, assignmentSection, true)} />
                <i className="fa fa-trash" onClick={() => hideArticle(article.id)} />
            </div>
        </div>
    );
};

export default Article;
