import React, {useState, useEffect, useContext} from 'react';

import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap';

import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import SpinnerButton from 'components/SpinnerButton';
import LoadingSpinner from 'components/LoadingSpinner';
import {getUserGroups, getUsersWithoutParams} from 'utils/api/usersAPI';
import {assignUsersToNewsletter, globalAssignNewsletter} from 'utils/api/newslettersAPI';
import {useHistory, useParams} from 'react-router-dom';
import DualSelect from 'components/DualSelect';
import {getNewsletterById} from 'utils/api/newslettersAPI';

const AssignNewsletter = () => {
    let {id} = useParams();
    let history = useHistory();

    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError} = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);
    const [users, setUsers] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [newsletterData, setNewsletterData] = useState(null);
    const [fetchingNewsletterData, setFetchingNewsletterData] = useState(true);
    const [fetchingUsers, setFetchingUsers] = useState(true);
    const [instantNewsletter, setInstantNewsletter] = useState(false);


    useEffect(() => {
        id && fetchNewsletterData();
        fetchUsers();
    }, []);

    const fetchNewsletterData = async() => {
        try {
            let newsletter = await getNewsletterById(id);
            if(newsletter.instantDeliveryEmail) {
                setInstantNewsletter(true);
                setFetchingNewsletterData(false);
                return;
            }
            setNewsletterData(newsletter);
            setSelectedUsers(newsletter.assignedUsers);
            setFetchingNewsletterData(false);
        } catch(err) {
            setFetchingNewsletterData(false);
            handleError(err);
        }
    };

    const fetchUsers = async() => {
        try {
            let userData = await getUsersWithoutParams(false, false, 1, 2000);
            let userGroupData = await getUserGroups();
            setUsers(userData.result);
            setUserGroups(userGroupData);
            setFetchingUsers(false);
        } catch(err) {
            setFetchingUsers(false);
            handleError(err);
        }
    };

    const onSubmit = async() => {
        try {
            setSubmitting(true);
            const params = {
                users: selectedUsers.map(user => user.id),
            };
            await assignUsersToNewsletter(id, params);
            setSubmitting(false);
            addFlashMessage('success', 'Newsletter successfully updated');
            history.push('/account/newsletters');
        } catch(err) {
            setSubmitting(false);
            handleError(err);
        }
    };

    if(fetchingNewsletterData || fetchingUsers) return <LoadingSpinner padding text={'Fetching newsletter data'}/>;
    if(instantNewsletter) return (
        <div className="view" style={{maxWidth: '600px', margin: '0 auto'}}>
            <div className="mt-6 mb-3">
                <div className="error-block d-flex">
                    <i className="fa fa-exclamation-circle" />
                    <div>Instant delivery newsletters can only be assigned to the specified email address in the Edit Newsletter modal.</div>
                </div>
            </div>
        </div>
    );
    return (
        <div className="animated fadeIn view">
            <Row className="mt-3">
                <Col xs="12">
                    <Card>
                        <CardHeader>
                            <h1>Assign Newsletter</h1>
                        </CardHeader>
                        <CardBody>
                            {newsletterData &&
                            <div className="assign-newsletter__info">
                                <h2>{newsletterData.name}</h2>
                            </div>
                            }
                            <div className="mt-6">
                                <DualSelect
                                    selectedList={selectedUsers}
                                    fullList={users}
                                    onSelect={setSelectedUsers}
                                    groups={userGroups}
                                    contentType="users"
                                />
                                <SpinnerButton
                                    className="pull-right mt-4"
                                    color="primary"
                                    submitting={submitting}
                                    onClick={onSubmit}
                                    title={'Assign'}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AssignNewsletter;
