import React from 'react';

import {Draggable} from 'react-beautiful-dnd';

const SectionsListItem = (props) => {
    const {section, handleSectionSelect, index} = props;
    return (
        <Draggable draggableId={String(section.id)} index={index}>
            {(provided, snapshot) => (
                <div onClick={() => handleSectionSelect(section)}
                     className={'sections-list-item ' + (snapshot.isDragging ? 'section-is-dragging' : '')}
                     ref={provided.innerRef}
                     {...provided.draggableProps}
                >
                    <div className="sections-list-item__title">
                        {section.name} &nbsp; <span
                        style={{color: 'gray'}}>{section.alerts && (section.alerts.length === 1 ? section.alerts.length + ' Alert' : section.alerts.length  + ' Alerts')}</span>
                    </div>
                    <div className="sections-list-item__actions"
                         {...provided.dragHandleProps}
                    >
                        <i className="fa fa-bars"/>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default SectionsListItem;
