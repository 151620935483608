import React, {useContext, useEffect, useState} from 'react';

import {Button, Modal, ModalBody} from 'reactstrap';
import moment from 'moment-timezone';

import LoadingSpinner from 'components/LoadingSpinner';
import {convertDateString} from 'Helpers/convertDateString';
import {getAlertPreview} from 'utils/api/alertsAPI';
import AlertPreviewItem from 'components/AlertPreviewItem';
import ModalBodyWithClose from 'components/ModalBodyWithClose';
import {Link} from 'react-router-dom';
import { COMPANY_TYPE } from '..';

const AlertPreview = (props) => {
    const {toggle, isOpen, alert, handleError, alertType} = props;
    const [fetchingData, setFetchingData] = useState(true);
    const [fetchingDataError, setFetchingDataError] = useState(false);
    const [browserLink, setBrowserLink] = useState(null);
    const [previewContent, setPreviewContent] = useState(null);

    const fetchAlertPreview = async() => {
        try {
            let preview = await getAlertPreview(alert.id);
            preview.content = preview.content.map((el) => {
                el.title = el.title.replace(/\([a-z0-9]{32}\)/, '').trim();
                return el;
            });
            setPreviewContent(preview);
            setFetchingData(false);
        } catch(err) {
            setFetchingDataError(true);
            setFetchingData(false);
            // handleError(err);
        }
    };

    useEffect(() => {
        alert && fetchAlertPreview();
    }, [alert]);

    const handleScroll = () => {
        let anchor = document.querySelector('#anchor');
        anchor.scrollIntoView({behavior: 'smooth'});
    };

    const getTitle = () => {
        if (alertType === COMPANY_TYPE) {
            return 'Company News Preview';
        }
        return 'Alert Preview';
    };

    if(!alert) return <div/>;
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="newsletter-preview-modal modal-lg">
            <div>
                <ModalBodyWithClose toggle={toggle}>
                    <div className="d-flex justify-content-between mb-2">
                        <div className="newsletter-preview-modal__header">
                            <h2 className="mb-1">{getTitle()}</h2>
                            <h5 className="mb-1">{alert.name}</h5>
                            <h5 className="mb-0">{convertDateString(moment())}</h5>
                        </div>
                        <div className="d-flex align-items-end">
                            {previewContent && previewContent.content && previewContent.content.length > 0 &&
                                <Button onClick={handleScroll}>
                                    {window.innerWidth > 600 ?
                                        'Back to top'
                                        :
                                        <i className="fa fa-chevron-up"/>
                                    }
                                </Button>
                            }
                        </div>
                    </div>
                    {fetchingDataError ?
                        <div className="mt-4 mb-3">
                            <div className="error-block d-flex">
                                <i className="fa fa-exclamation-circle"/>
                                <div>Unable to fetch alert content at this time. Please try again later.</div>
                            </div>
                        </div>
                        :
                        <div className="newsletter-preview-modal__body">
                            <div id="anchor"/>
                            {fetchingData ?
                                <div style={{padding: '1rem 0'}}>
                                    <LoadingSpinner text="Fetching alert content"/>
                                </div>
                                :
                                (previewContent && previewContent.content && previewContent.content.length) ?
                                    <div className="pr-3 mt-2">
                                        {previewContent.content.map((item, index) => {
                                            return <AlertPreviewItem
                                                alertId={alert.id}
                                                item={item}
                                                key={index}
                                            />;
                                        })}
                                    </div>
                                    :
                                    <div style={{padding: '1rem 0'}}>
                                        {alert.source.type === 'internal' ?
                                            <>
                                                There is no content for the last 5 days. <Link
                                                to={`/account/alerts/dig-deeper/${alert.id}`}>Click here</Link> to see
                                                content for the last 30 days.
                                            </>
                                            :
                                            <>
                                                No alert content is available yet. Please check again later for updated
                                                content.
                                            </>
                                        }
                                    </div>
                            }
                        </div>
                    }
                </ModalBodyWithClose>
            </div>
        </Modal>
    );
};

export default AlertPreview;



