import React, {useState, useEffect, useContext} from 'react';

import AlertList from './AlertList';
import ArticleList from './ArticleList';
import ArticleView from './ArticleView';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import {getDigDeeperContent, getFreeDigDeeperContent, getFreeReaderAlerts} from 'utils/api/alertsAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import {isEqual} from 'lodash';
import {getUserAlertsById} from 'utils/api/usersAPI';
import './style.scss';
import {sortObjectsByNumber} from 'Helpers/sortObjects';

const DesktopReader = (props) => {
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {state, handleError} = useContext(AuthContext);

    const {id, search, companyReader} = props;
    const [selectedAlert, setSelectedAlert] = useState({});
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [articles, setArticles] = useState([]);
    const [tagData, setTagData] = useState([]);
    const [fetchingAlerts, setFetchingAlerts] = useState(true);
    const [fetchingAlertContent, setFetchingAlertContent] = useState(false);
    const [alertContent, setAlertContent] = useState(null);
    const [allowManageAlertsInReader, setAllowManageAlertsInReader] = useState(false);
    const [unconfirmedUser, setUnconfirmedUser] = useState(false);
    const [publicEmail, setPublicEmail] = useState('');

    useEffect(() => {
        if (!document.body.classList.contains('sidebar-minimized')) {
            document.body.classList.toggle('sidebar-minimized');
        }
    }, [alerts]);

    useEffect(() => {
        fetchAlerts('initialLoad');
    }, []);

    useEffect(() => {
        if(!props.public) {
            state.profile &&
            state.profile.organization &&
            setAllowManageAlertsInReader(state.profile.organization.myReaderAuth)
        }
    }, [state]);

    const pushEmptyAlertsToEnd = (arr) => {
        let newArr = arr;
        newArr.map(item => {
            if(item.countNews === 0) {
                let index = newArr.indexOf(item);
                newArr.splice(index, 1);
                newArr.push(item);
            }
        })
        return newArr;
    }

    const fetchAlerts = async(initialLoad) => {
        try {
            let userData;
            if(props.public) {
                if(companyReader) {
                    userData = await getFreeReaderAlerts(state.readerInfo.id, state.readerInfo.hash, 10);
                } else {
                    const hash = search.slice(
                        3,
                        search.length
                    );
                    userData = await getFreeReaderAlerts(id, hash, 10);
                }
                // sortObjectsByNumber(userData.alerts, 'countNews', 'desc');
                setAllowManageAlertsInReader(userData.myReaderAuth);
                setPublicEmail(userData.email);
                let newArr = pushEmptyAlertsToEnd(userData.alerts)
                setAlerts(newArr);
                newArr.length && setSelectedAlert(newArr[0]);
            } else {
                userData = await getUserAlertsById(id);
                // sortObjectsByNumber(userData.result, 'countNews', 'desc');
                let newArr = pushEmptyAlertsToEnd(userData.result)
                setAlerts(newArr);
                initialLoad && newArr.length > 0 && setSelectedAlert(newArr[0]);
            }
            setFetchingAlerts(false);
        } catch(err) {
            setFetchingAlerts(false);
            handleError(err);
        }
    };

    const fetchAlertContent = async(alertId, alertHash) => {
        try {
            setFetchingAlertContent(true);
            let alertContent;
            if(props.public) {
                alertContent = await getFreeDigDeeperContent(alertId, alertHash, 10);
            } else {
                alertContent = await getDigDeeperContent(alertId, 10);
            }

            setAlertContent(alertContent);
            setArticles(alertContent.news);
            setSelectedTag(null);
            setSelectedArticle(alertContent.news[0]);
            setTagData(alertContent.tags);
            setFetchingAlertContent(false);
        } catch(err) {
            setFetchingAlertContent(false);
            handleError(err);
        }
    };

    const handleAlertSelect = (alert) => {
        setSelectedAlert(alert);
    };

    useEffect(() => {
        selectedAlert.id && fetchAlertContent(selectedAlert.id, selectedAlert.hash);
    }, [selectedAlert]);

    const handleTagSelect = (tag) => {
        if(isEqual(selectedTag, tag)) {
            setSelectedTag(null);
        } else {
            setSelectedTag(tag);
        }
    };

    const applyTagFilter = (articleArray) => {
        let filteredArray = [...articleArray];
        if(selectedTag) {
            filteredArray = filteredArray.filter(article => {
                return selectedTag.news.includes(String(article.id));
            })
        }
        setArticles(filteredArray)
    }

    useEffect(() => {
        if(!alertContent) return;
        if(selectedTag !== null) {
            applyTagFilter(alertContent.news)
        } else {
            setArticles(alertContent.news)
        }
    }, [selectedTag]);

    const handleArticleSelect = (article) => {
        setSelectedArticle(article);
    };

    if(unconfirmedUser) return (
        <div className="view" style={{maxWidth: '600px', margin: '0 auto'}}>
            <div className="mt-6 mb-3">
                <div className="error-block d-flex">
                    <i className="fa fa-exclamation-circle"/>
                    <div>This user has not confirmed their account.</div>
                </div>
            </div>
        </div>
    );
    if(fetchingAlerts) return <LoadingSpinner padding text={'Fetching reader data'}/>;
    return (
        <div className="reader">
            <AlertList
                alerts={alerts}
                handleAlertSelect={handleAlertSelect}
                fetchingAlertContent={fetchingAlertContent}
                selectedAlert={selectedAlert}
                tagData={tagData}
                handleTagSelect={handleTagSelect}
                selectedTag={selectedTag}
                addFlashMessage={addFlashMessage}
                refreshAlerts={fetchAlerts}
                userId={props.id}
                setFetchingAlerts={setFetchingAlerts}
                allowManageAlertsInReader={allowManageAlertsInReader}
                isPublic={props.public}
                state={state}
                publicEmail={publicEmail}
            />
            <ArticleList
                articles={articles}
                handleArticleSelect={handleArticleSelect}
                fetchingArticles={fetchingAlertContent}
                selectedArticle={selectedArticle}
            />
            <ArticleView
                selectedArticle={selectedArticle}
                toolbar={!props.public}
                isPublic={props.public}
            />
        </div>
    );
};

export default DesktopReader;