import React from 'react';

const DualSelectItem = (props) => {
    const { item, handleItemSelect, selectedItems, source, isSearch } = props;
    return (
        <div
            className={'dual-select-item ' + (selectedItems.indexOf(item) > -1 ? 'dual-select-item--selected' : '')}
            onClick={() => handleItemSelect(item, source)}>
            {isSearch ? (
                <>
                    {!!item.group && <i className="icon-placeholder fa fa-list" />}
                    {item.name}
                    {item.group && (
                        <>
                            &nbsp; <span className="badge badge-pill badge-secondary">{item.items.length}</span>
                        </>
                    )}
                </>
            ) : (
                !item.group && item.name
            )}
        </div>
    );
};

export default DualSelectItem;
