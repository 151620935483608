import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Row, Col} from 'reactstrap';
import EmailToRss from '../CreateAndEdit/EmailToRss';
import RSS from '../CreateAndEdit/RSS';
import {getAlertLabels} from 'utils/api/alertsAPI';
import '../CreateAndEdit/style.scss';

const CreateSource = () => {
    const { search } = useLocation();
    const [activeAlertType, setActiveAlertType] = useState((new URLSearchParams(search)).get('type'));
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        fetchLabels();
    }, []);

    const showAlertForm = () => {
        switch(activeAlertType) {
            case 'rss':
                return <RSS labels={labels} refreshLabels={refreshLabels}/>;
            case 'email_to_rss':
                return <div class="email-to-rss"><EmailToRss/></div>;
            default:
                return <RSS labels={labels} refreshLabels={refreshLabels}/>;
        }
    };

    const fetchLabels = async() => {
        try {
            const foundLabels = await getAlertLabels();
            setLabels(foundLabels);
        } catch(err) {
            console.log(err);
        }
    };

    const refreshLabels = async() => {
        const refreshedLabels = await getAlertLabels();
        setLabels(refreshedLabels);
    };


    const rssIsActive = activeAlertType === 'rss' ? 'active' : '';
    const emailToRssIsActive = activeAlertType === 'email_to_rss' ? 'active' : '';

    return (
        <div className="animated fadeIn view">
            <Row className="mt-3">
                <Col xs="12">
                    <div className="alert-container">
                        <h1>Add Content</h1>
                        <div className="alert-type-button-container justify-content-between">
                            <div className="desktop-rss-tab-container">
                                <div className={'alert-type-button-lable'}>Add:</div>
                                <div
                                    className={'alert-type-button ' + rssIsActive}
                                    onClick={() => setActiveAlertType('rss')}
                                >
                                    <i className="fa fa-rss"/>
                                    <span className="i-space">&nbsp;</span> RSS
                                </div>
                                <div
                                    className={'alert-type-button ' + emailToRssIsActive}
                                    onClick={() => setActiveAlertType('email_to_rss')}
                                >
                                    <i className="fa fa-envelope-o"/>
                                    <span className="i-space">&nbsp;</span> Inbox/Email
                                </div>
                            </div>
                        </div>
                        {showAlertForm()}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CreateSource;
