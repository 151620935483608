import React, { useState } from 'react';

import { Modal, ModalFooter, Button } from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import { deleteAlert } from 'utils/api/alertsAPI';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const DeleteAlert = (props) => {
    const { isOpen, toggle, item, handleError, addFlashMessage, refreshAlerts } = props;
    const [deleting, setDeleting] = useState(false);

    const handleDeleteAlert = async () => {
        try {
            setDeleting(true);
            await deleteAlert(item.id);
            await refreshAlerts();
            setDeleting(false);
            toggle();
            addFlashMessage('success', 'Alert successfully deleted');
        } catch (err) {
            setDeleting(false);
            if (err?.hasOwnProperty('message') && err?.message === 'This Source has children and can not be deleted') {
                addFlashMessage('danger', err?.message);
            } else {
                handleError(err, err, addFlashMessage);
            }
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            {!item ? (
                <div />
            ) : (
                <div>
                    <ModalBodyWithClose toggle={toggle}>
                        <span>Are you sure you want to delete {item.name}?</span>
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>{' '}
                        <SpinnerButton type={'modal'} color="danger" onClick={handleDeleteAlert} submitting={deleting} title={'Delete'} />
                    </ModalFooter>
                </div>
            )}
        </Modal>
    );
};

export default DeleteAlert;
