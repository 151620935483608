import React, {useEffect, useContext} from 'react';
import FlashMessage from './FlashMessage';
import {Row, Col, Container} from 'reactstrap';
import './FlashMessageList.scss'
import {FlashMessageContext} from 'contexts/FlashMessageContext';

const FlashMessagesContainer = (props) => {
    const {flashMessages, deleteFlashMessage, handleAutoDelete} = useContext(FlashMessageContext);

    const messages = flashMessages.map(item => {
        handleAutoDelete(item);
        return <FlashMessage
            key={item.id}
            id={item.id}
            type={item.type}
            message={item.message}
            deleteFlashMessage={deleteFlashMessage}
        />
    });

    return (
        <Container className="flash-message-list-wrapper">
            <Row>
                <Col xs={{size: 6, offset: 3}} className="flash-message-list-container">
                    {/*{messages}*/}
                    {/*<ReactCSSTransitionGroup*/}
                        {/*transitionName="FlashMessage"*/}
                        {/*transitionEnterTimeout={300}*/}
                        {/*transitionLeaveTimeout={300}*/}
                    {/*>*/}
                        {messages}
                    {/*</ReactCSSTransitionGroup>*/}
                </Col>
            </Row>
        </Container>
    );

};

export default FlashMessagesContainer;
