import React, {useState, useEffect, useContext} from 'react';
import SearchForm from './SearchForm';
import TitleForm from './TitleForm';
import Preview from './Preview';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {addAlertLabel, addRssAlert, checkPossibleToAddAlert, editRssAlert, previewRssAlert} from 'utils/api/alertsAPI';
import {useHistory} from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import {AuthContext} from 'contexts/AuthContext';
import './style.scss';

const RssForm = (props) => {
    let history = useHistory();
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError, state} = useContext(AuthContext);

    const {foundAlert, isEditForm, labels, internalGroup} = props;

    const [searchValue, setSearchValue] = useState('');
    const [searchValueError, setSearchValueError] = useState(false);
    const [searchValueEditWarning, setSearchValueEditWarning] = useState(false);
    const [titleValue, setTitleValue] = useState('');
    const [preview, setPreview] = useState(null);
    const [titleFormVisible, setTitleFormVisible] = useState(false);
    const [searchSubmitting, setSearchSubmitting] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [creatingLabel, setCreatingLabel] = useState(false);
    const [newLabelToAdd, setNewLabelToAdd] = useState(null);
    const [updatingPreview, setUpdatingPreview] = useState(false);
    const [errors, setErrors] = useState(null);


    useEffect(() => {
        const {isEditForm, foundAlert} = props;
        const getFoundAlertValues = () => {
            setSearchValue(foundAlert.source.url);
            setTitleValue(foundAlert.name);
            setTitleFormVisible(true);
            handleCreatePreview(foundAlert.source.url);
            setSelectedLabels(foundAlert.labels);
        };

        isEditForm && getFoundAlertValues();
    }, []);

    const handleCreatePreview = async(initialLoadValue) => {
        let tempSearchValue = initialLoadValue ? initialLoadValue : searchValue;
        try {
            if(tempSearchValue.length > 0) {
                setPreview(null);
                // setSearchValueError(null);
                setSearchSubmitting(true);
                setErrors(null);
                const preview = await previewRssAlert(tempSearchValue);
                if(preview.errors) {
                    handleRSSErrors(preview, tempSearchValue);
                    return;
                }
                !isEditForm && setTitleValue(preview.globalTitle.length ? preview.globalTitle : 'Enter Title');
                setTitleFormVisible(true);
                setPreview(preview);
                setSearchSubmitting(false);
            }
        } catch(err) {
            handleRSSErrors(err);
        }
    };

    const handleRSSErrors = (err, url) => {
        if(err.code === 'VALIDATION_FAILED') {
            handleError(err, setErrors);
        } else {
            let tempErrObj = {
                code: 'VALIDATION_FAILED',
                errors: {
                    url: [{message: '404 Not Found'}],
                },
            };
            handleError(tempErrObj, setErrors);
        }
        setSearchSubmitting(false);
        setTitleFormVisible(false);

    };

    const handleSearchValueChange = (text) => {
        setSearchValue(text);
    };

    useEffect(() => {
        if(props.isEditForm) {
            if(searchValue !== props.foundAlert.source.url) {
                setSearchValueEditWarning(true);
            } else {
                setSearchValueEditWarning(false);
            }
        }
    }, [searchValue]);

    const resetForm = () => {
        setPreview(null);
        setTitleFormVisible(false);
        setTitleValue('');
        setSearchValue('');
        setErrors(null);
    };

    const clearPreviewForm = () => {
        setPreview(null);
        setTitleFormVisible(false);
        setTitleValue('');
        // setErrors(null);
    };

    const handleSubmit = async() => {
        const rssSearchValues = {
            name: titleValue,
            url: searchValue,
            alertLabels: selectedLabels.map(n => n.id),
        };

        try {
            setSubmitting(true);
            if(props.isEditForm) {
                await editRssAlert(props.foundAlert.id, rssSearchValues);
                addFlashMessage('success', 'Alert successfully updated');
                history.push('/account/manage-sources');
            } else {
                let isPossibleToAdd = await checkPossibleToAddAlert();
                if(isPossibleToAdd) {
                    await addRssAlert(rssSearchValues);
                    setSubmitting(false);
                    addFlashMessage('success', 'Alert successfully created');
                    history.push('/account/manage-sources');
                } else {
                    addFlashMessage('danger', 'You\'ve reached the limit of allowed number of alerts for your account.\n' +
                        'Contact Customer Care team for more information.');
                }
            }
        } catch(err) {
            setSubmitting(false);
            if(err.code === 'NOT_FOUND') {
                let errObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        url: [
                            {message: '404 Not Found'},
                        ],
                    },
                };
                handleError(errObj, setErrors);
                return;
            }
            if(err.code === 'VALIDATION_FAILED') {
                if(err.errors.name) {
                    if(err.errors.name[0].code === 'TOO_SHORT_ERROR') {
                        addFlashMessage('danger', 'Alert name must be at least 2 characters long.');
                    } else if(err.errors.name[0].code === 'IS_BLANK_ERROR') {
                        addFlashMessage('danger', 'Alert name must be at least 2 characters long.');
                    }
                } else {
                    if(err.message && err.message.substr(0, 13) === 'Client error:') {
                        let errObj = {
                            code: 'VALIDATION_FAILED',
                            errors: {
                                url: [
                                    {message: '404 Not Found'},
                                ],
                            },
                        };
                        handleError(errObj, setErrors);
                    } else if(
                        err.message.slice(0, 17) === 'Alert with source'
                        || (err.errors.hasOwnProperty('url') && err.errors.url[0].code === 'NOT_UNIQUE_ERROR')
                    ) {
                        let errObj = {
                            code: 'VALIDATION_FAILED',
                            errors: {
                                url: [
                                    {message: 'An alert with this url already exists.'},
                                ],
                            },
                        };
                        handleError(errObj, setErrors);
                    }
                }
            } else {
                handleError(err, setErrors);
            }
        }
    };

    useEffect(() => {
        if(newLabelToAdd) {
            const foundLabel = labels.find(label => label.id === newLabelToAdd.id);
            foundLabel && addToSelected(foundLabel);
            setNewLabelToAdd(null);
        }
    }, [labels]);

    const addLabel = async(name) => {
        setCreatingLabel(true);
        try {
            const newLabel = await addAlertLabel({name});
            setNewLabelToAdd(newLabel.content);
            await props.refreshLabels();
            setCreatingLabel(false);
        } catch(res) {
            if(res.errors.name[0].message) {
                addFlashMessage('danger', res.errors.name[0].message);
            }
            setCreatingLabel(false);
        }
    };

    const addToSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if(newSelectedLabels.indexOf(label) === -1) {
            newSelectedLabels.push(label);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const removeFromSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if(newSelectedLabels.indexOf(label) > -1) {
            newSelectedLabels.splice(newSelectedLabels.indexOf(label), 1);
            setSelectedLabels(newSelectedLabels);
        }
    };

    return (
        <div className="mb-4">
            <SearchForm searchValue={searchValue}
                        handleSearchValueChange={handleSearchValueChange}
                        handleCreatePreview={handleCreatePreview}
                        searchValueError={searchValueError}
                        setSearchValueError={setSearchValueError}
                        resetForm={resetForm}
                        clearPreviewForm={clearPreviewForm}
                        searchSubmitting={searchSubmitting}
                        setSearchSubmitting={setSearchSubmitting}
                        searchValueEditWarning={searchValueEditWarning}
                        errorObj={errors}
                        internalGroup={internalGroup}
            />

            {titleFormVisible &&
            <TitleForm title={titleValue}
                       setTitleValue={setTitleValue}
                       submitting={submitting}
                       isEditForm={props.isEditForm}
                       handleSubmit={handleSubmit}
                // selectedLabels={selectedLabels}
                // setSelectedLabels={setSelectedLabels}
                // labels={props.labels}
                // addLabel={addLabel}
                // removeFromSelected={removeFromSelected}
                // addToSelected={addToSelected}
                // creatingLabel={creatingLabel}
            />
            }

            {
                searchSubmitting ?
                    <div className="mt-4">
                        <LoadingSpinner text="Fetching preview"/>
                    </div>
                    : preview &&
                    <Preview
                        preview={preview}
                        updatingPreview={updatingPreview}
                        submitting={submitting}
                        handleSubmit={handleSubmit}
                        isEditForm={isEditForm}
                        selectedLabels={selectedLabels}
                        labels={labels}
                        addLabel={addLabel}
                        addToSelected={addToSelected}
                        removeFromSelected={removeFromSelected}
                        creatingLabel={creatingLabel}
                        isSingleUser={state.isSingleUser}
                    />
            }
        </div>
    );
};

export default RssForm;
