import React from 'react';
import { FormGroup, Input } from 'reactstrap';

const FilterDropdown = (props) => {
    const { title, activeFilter, setActiveFilter, list } = props;

    const activeCategoryName = title === 'AI Category' && activeFilter && list?.find((item) => item.id === Number(activeFilter));
    const activeCategoryLength = activeCategoryName && activeCategoryName?.name?.length;

    const handleSortDropdownSelect = (val) => {
        setActiveFilter(val);
    };

    return (
        <div className="d-flex align-items-center md-ml-2">
            {window.innerWidth > 630 && (
                <>
                    <span style={{ color: 'black' }}>Filter by</span> &nbsp;
                </>
            )}
            <FormGroup className="m-0">
                <Input
                    style={{ width: activeCategoryLength ? `${7 * activeCategoryLength + 25}px` : '80px' }}
                    type="select"
                    name="select"
                    placeholder={title}
                    value={activeFilter || ''}
                    onChange={(e) => handleSortDropdownSelect(e.target.value)}
                    className="countries-select">
                    <option value={''}>{title}</option>
                    {list &&
                        list.length > 0 &&
                        list.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                </Input>
            </FormGroup>
        </div>
    );
};

export default FilterDropdown;
