import React, { useState, useEffect, useContext } from 'react';
import ListItem from './ListItem';
import PaginationWrapper from 'components/PaginationWrapper';
import { Card, CardBody, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row, UncontrolledTooltip } from 'reactstrap';
import TabBar from 'components/TabBar';
import { AuthContext } from 'contexts/AuthContext';
import ReactTooltip from 'react-tooltip';
import { truncateWithoutWordBreak } from 'Helpers/truncateWithoutWordBreak';
import { initBriefingReport } from 'utils/api/alertsAPI';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { isEqual } from 'lodash';

const ArticleList = (props) => {
    const { state } = useContext(AuthContext);
    const { articlesWithoutDuplicates, alertId, alertName, articles: allArticles } = props;
    const [articles, setArticles] = useState([]);
    const [sec, setSec] = useState([]);
    const [activeView, setActiveView] = useState('latest_news');
    const [totalPages, setTotalPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalSecPages, setTotalSecPages] = useState([]);
    const [currentSecPage, setCurrentSecPage] = useState(1);
    const [accessSec, setAccessSec] = useState(false);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const [articlesToBriefingReport, setArticlesToBriefingReport] = useState([]);
    const [accessBriefingReport, setAccessBriefingReport] = useState(false);
    const [emails, setEmails] = useState('');
    const [articlesToEmail, setArticlesToEmail] = useState([]);
    const [activeTag, setActiveTag] = useState('');

    useEffect(() => {
        if (state.profile.organization?.accessCompanyBasedSec) {
            setAccessSec(true);
        }
        if (state.profile.organization?.accessBriefingReport) {
            setAccessBriefingReport(true);
        }
    }, [state.profile]);

    useEffect(() => {
        const allA = allArticles.filter((el) => el.resource !== 'sec.gov');
        const allS = allArticles.filter((el) => el.resource === 'sec.gov');
        const a = articlesWithoutDuplicates.filter((el) => el.resource !== 'sec.gov');
        const s = articlesWithoutDuplicates.filter((el) => el.resource === 'sec.gov');

        if (activeTag && activeView !== 'sec_filings') {
            let filteredArray = [...allA];
            let filteredArrayWithoutDuplicates = [...a];

            filteredArray = filteredArray.filter((article) => article[activeTag.ind].includes(activeTag.name));
            filteredArrayWithoutDuplicates = filteredArrayWithoutDuplicates.filter((article) => article[activeTag.ind].includes(activeTag.name));
            const resultArray = filteredArrayWithoutDuplicates?.length ? filteredArrayWithoutDuplicates : filteredArray;

            setArticles(resultArray);
            setTotalPages(Math.ceil(resultArray.length / 10));
        } else {
            setArticles(a);
            setTotalPages(Math.ceil(a.length / 10));
            setSec(
                s.map((el) => {
                    el.title = el.title.replace(/\([a-z0-9]{32}\)/, '').trim();
                    return el;
                })
            );
            setTotalSecPages(Math.ceil(s.length / 10));
        }

        setCurrentPage(1);
    }, [articlesWithoutDuplicates, activeTag, allArticles, activeView]);

    const handleActiveView = () => {
        switch (activeView) {
            case 'sec_filings':
                return secFilingsView();
            default:
                return latestNewsView();
        }
    };

    const handlePaginationClick = (e) => {
        const selectedPage = e.selected + 1;
        setCurrentPage(selectedPage);
    };

    const handleSecPaginationClick = (e) => {
        const selectedPage = e.selected + 1;
        setCurrentSecPage(selectedPage);
    };

    const handleTagSelect = (tag) => {
        if (isEqual(activeTag, tag)) {
            setActiveTag('');
        } else {
            setActiveTag(tag);
        }
    };

    const getItems = () => {
        let items = [{ data: 'latest_news', name: 'Latest news (' + articles.length + ')' }];
        if (accessSec) {
            items.push({ data: 'sec_filings', name: 'SEC Filings (' + sec.length + ')' });
        }
        return items;
    };

    const latestNewsView = () => {
        return (
            <div className="company-news-list">
                <div>
                    <ul className="list-unstyled list-group">
                        {articles?.slice(currentPage * 10 - 10, currentPage * 10)?.map((article) => (
                            <ListItem
                                key={article.id}
                                item={article}
                                withSummary={true}
                                alertId={alertId}
                                accessBriefingReport={accessBriefingReport}
                                articlesToEmail={articlesToEmail}
                                setArticlesToEmail={setArticlesToEmail}
                                articlesToBriefingReport={articlesToBriefingReport}
                                setArticlesToBriefingReport={setArticlesToBriefingReport}
                                activeTag={activeTag}
                                handleTagSelect={handleTagSelect}
                                countOfArticles={articles.length}
                            />
                        ))}
                        {totalPages > 1 && (
                            <PaginationWrapper totalPages={totalPages} forcePage={currentPage - 1} handlePageClick={handlePaginationClick} />
                        )}
                    </ul>
                </div>
            </div>
        );
    };

    const secFilingsView = () => {
        return (
            <div className="company-news-list">
                <div>
                    <ul className="list-unstyled list-group">
                        {sec?.slice(currentSecPage * 10 - 10, currentSecPage * 10)?.map((article) => (
                            <ListItem
                                key={article.id}
                                item={article}
                                withSummary={false}
                                alertId={alertId}
                                accessBriefingReport={accessBriefingReport}
                                articlesToEmail={articlesToEmail}
                                setArticlesToEmail={setArticlesToEmail}
                                articlesToBriefingReport={articlesToBriefingReport}
                                setArticlesToBriefingReport={setArticlesToBriefingReport}
                                activeTag={activeTag}
                                handleTagSelect={handleTagSelect}
                                countOfArticles={sec.length}
                                isSecFilings
                            />
                        ))}
                        {totalSecPages > 1 && (
                            <PaginationWrapper totalPages={totalSecPages} forcePage={currentSecPage - 1} handlePageClick={handleSecPaginationClick} />
                        )}
                    </ul>
                </div>
            </div>
        );
    };

    const handleShareEmailClick = (e) => {
        e.preventDefault();

        if (articlesToEmail.length < 1) {
            addFlashMessage('danger', 'First you need select articles...');
            return false;
        }
        window.open(buildMailtoUrl(), '_blank', 'noopener,noreferrer');
    };

    const buildMailtoUrl = () => {
        let toSend = articlesWithoutDuplicates.filter((x) => articlesToEmail.indexOf(x.id) !== -1);
        toSend = toSend.map((i) => i.title + '\n' + i.bit_url);
        toSend = toSend.join('\n\n');

        return 'mailto:?subject=' + truncateWithoutWordBreak(alertName) + '&body=' + encodeURIComponent(toSend);
    };

    const validateEmails = () => {
        if (!emails) {
            return false;
        }
        let valid = true;
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const arr = emails.split(' ');
        arr.forEach((el) => {
            el = el.trim();
            if (el && !emailRegex.test(el) && valid) {
                addFlashMessage('danger', 'Please enter a valid email address');
                valid = false;
            }
        });
        if (valid) {
            setEmails(emails);
        }
        return valid;
    };

    const handleBriefingReportClick = async (e) => {
        e.preventDefault();

        if (articlesToBriefingReport.length < 2 || articlesToBriefingReport.length > 5) {
            addFlashMessage('danger', 'You need select between 2 and 5 articles...');
            return false;
        }

        if (!emails || !validateEmails()) {
            addFlashMessage('danger', 'Please enter a valid email address');
            return false;
        }

        let urls = articles.filter((x) => articlesToBriefingReport.indexOf(x.id) !== -1);
        urls = urls.map((x) => x.url);

        try {
            await initBriefingReport({
                urls: urls,
                emails: emails.split(' '),
            });
            addFlashMessage('success', 'Briefing Report successfully sent');
        } catch (err) {
            addFlashMessage('danger', 'Something wrong...');
        }
    };

    return (
        <div className="view view--mobile-full alerts ">
            {alertId && (
                <div className="row">
                    <div className="col-10">
                        {accessBriefingReport && articlesToBriefingReport.length > 0 && (
                            <>
                                <InputGroup className={'m-0'}>
                                    <InputGroupAddon addonType="prepend" data-tip="Build Briefing Report">
                                        <InputGroupText>
                                            <i className="fa fa-user" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <ReactTooltip place="bottom" effect="solid" delayShow={200} className="tooltip-thin" />
                                    <Input
                                        placeholder="Email address(es) separated by space"
                                        value={emails}
                                        type="text"
                                        onChange={(e) => setEmails(e.target.value)}
                                        onBlur={validateEmails}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={'briefing-report-label-tooltip'} delay={{ show: 200, hide: 0 }}>
                                        Build Briefing Report
                                    </UncontrolledTooltip>
                                    <a
                                        className={'btn btn-secondary'}
                                        id="briefing-report-label-tooltip"
                                        onClick={handleBriefingReportClick}
                                        href={'#'}>
                                        <i className="fa fa-cubes" />
                                    </a>
                                </InputGroup>
                            </>
                        )}
                    </div>
                    <div className="col-2">
                        {articlesToEmail.length > 0 && (
                            <>
                                <UncontrolledTooltip placement="bottom" target={'email-label-tooltip'} delay={{ show: 200, hide: 0 }}>
                                    Share via email
                                </UncontrolledTooltip>
                                <a
                                    target={'_blank'}
                                    className={'btn btn-secondary'}
                                    id="email-label-tooltip"
                                    onClick={handleShareEmailClick}
                                    href={'#'}>
                                    <i className="fa fa-envelope-o" />
                                </a>
                            </>
                        )}
                    </div>
                </div>
            )}

            <div className="animated fadeIn">
                <Row>
                    <Col xs="12">
                        <Card style={{ border: 'none' }}>
                            <CardBody>
                                <TabBar activeItem={activeView} setActive={setActiveView} items={getItems()} />
                                {handleActiveView()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ArticleList;
