import React, {useState, useRef, useCallback} from 'react';

import ReactCrop from 'react-image-crop';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import 'react-image-crop/dist/ReactCrop.css';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const CropModal = (props) => {
    const {isOpen, toggle, submitting, addFlashMessage, file, imagePreviewUrl, onSubmit} = props;
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const [crop, setCrop] = useState({unit: '%', width: 100, height: 80});
    const [previewUrl, setPreviewUrl] = useState();

    const onSelectFile = e => {
        if(e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    const makeClientCrop = async crop => {
        if(imgRef.current && crop.width && crop.height) {
            createCropPreview(imgRef.current, crop, 'newFile.jpeg');
        }
    };

    const createCropPreview = async(image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );

        setPreviewUrl(canvas.toDataURL('image/jpeg'));
        // return canvas.toDataURL('image/jpeg');

        // return new Promise((resolve, reject) => {
        //     canvas.toBlob(blob => {
        //         if(!blob) {
        //             reject(new Error('Canvas is empty'));
        //             return;
        //         }
        //         blob.name = fileName;
        //         window.URL.revokeObjectURL(previewUrl);
        //         setPreviewUrl(window.URL.createObjectURL(blob));
        //     }, 'image/jpeg');
        // });
    };

    if(!imagePreviewUrl) return null;
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="photo-upload-modal">
            <div>
                <ModalBodyWithClose toggle={toggle}>
                    <h2>Crop your image</h2>
                    <div>
                        <ReactCrop
                            src={imagePreviewUrl}
                            onImageLoaded={onLoad}
                            crop={crop}
                            onChange={c => setCrop(c)}
                            onComplete={makeClientCrop}
                        />
                    </div>
                </ModalBodyWithClose>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                    <SpinnerButton
                        type={'modal'}
                        color="primary"
                        onClick={() => onSubmit(previewUrl, toggle)}
                        // disabled={!croppedUrl}
                        submitting={submitting}
                        title={'Upload'}
                    />
                </ModalFooter>
            </div>
        </Modal>
    );
};

export default CropModal;
