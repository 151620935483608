import {deleteDecorator, getDecorator, patchDecorator, postDecorator, csvDecorator} from 'utils/api/decorators';
import axios from 'axios';


export const getNewsletters = (count, pageNumber, itemsPerPage, sortProperty, sortDirection, searchProperty, searchValue = '', csv = false, title) => {
    let url = `/api/v2/newsletter?help=false&count=${count}&page=${pageNumber}&itemsOnPage=${itemsPerPage}&sort%5B0%5D%5Bproperty%5D=${sortProperty}&sort%5B0%5D%5Bdirection%5D=${sortDirection.toUpperCase()}&csv=${csv}`;

    if(searchValue.length > 0) {
        let encodedSearchValue = encodeURIComponent(searchValue);
        url = url + `&filter%5B0%5D%5Bproperty%5D=${searchProperty}&filter%5B0%5D%5Boperator%5D=contains&filter%5B0%5D%5Bvalue%5D=${encodedSearchValue}`
    }

    if(csv) {
        return csvDecorator(url, title);
    } else {
        return getDecorator(url);
    }
};

export const getNewslettersWithoutParams = (csv, title) => {
    const url = `/api/v2/newsletter?help=false&count=true&page=$1&itemsOnPage=200&csv=${csv}`;
    if(csv) {
        return csvDecorator(url, title);
    } else {
        return getDecorator(url);
    }
};

export const addNewsletter = (params) => {
    const url = '/api/v2/newsletter';
    return postDecorator(url, params);
};

export const getNewsletterById = (id) => {
    const url = `/api/v2/newsletter/${id}`;
    return getDecorator(url);
};

export const editNewsletterById = (id, params) => {
    const url = `/api/v2/newsletter/${id}`;
    return patchDecorator(url, params);
};

export const editNewsletterComment = (id, params) => {
    const url = `/api/v2/newsletter/${id}/comment`;
    return patchDecorator(url, params);
};

export const deleteNewsletter = (id) => {
    const url = `/api/v2/newsletter/${id}`;
    return deleteDecorator(url);
};

export const addNewsletterSection = (id, params) => {
    const url = `/api/v2/newsletter/${id}/section`;
    return postDecorator(url, params);
};

export const editNewsletterSection = (id, sectionId, params) => {
    const url = `/api/v2/newsletter/${id}/section/${sectionId}`;
    return patchDecorator(url, params);
};

export const deleteNewsletterSection = (id, sectionId) => {
    const url = `/api/v2/newsletter/${id}/section/${sectionId}`;
    return deleteDecorator(url);
};

export const sortNewsletterSections = (id, params) => {
    const url = `/api/v2/newsletter/${id}/sections_sorting`;
    return patchDecorator(url, params);
};

export const clearNewsletterSection = (id, sectionId) => {
    const url = `/api/v2/newsletter/${id}/section/${sectionId}/clear_alerts`;
    return patchDecorator(url);
};

export const moveAlertBetweenNewsletterSections = (id, alertId, previousSectionId, newSectionId) => {
    const url = `/api/v2/newsletter/${id}/alert/${alertId}/previous_section/${previousSectionId}/new_section/${newSectionId}`;
    return patchDecorator(url);
};

export const moveArticleBetweenNewsletterSections = (id, articleId, previousSectionId, newSectionId, sortOrder) => {
    const url = `/api/v2/newsletter/${id}/news/${articleId}/previous_section/${previousSectionId}/new_section/${newSectionId}/sort_order/${sortOrder}`;
    return patchDecorator(url);
};

export const addCommentToNewsletterArticle = (id, sectionId, articleId, params) => {
    const url = `/api/v2/newsletter/${id}/section/${sectionId}/news/${articleId}/add_comment`;
    return patchDecorator(url, params);
};

export const getFreeCuratedArticlesByDate = (id, daysAgo, sortByDate = 'desc') => {
    const url = `/api/v2/newsletter/${id}/free_news?from=${daysAgo}%20days%20ago&to=now&sortByDate=${sortByDate}`;
    return getDecorator(url);
};

export const clearCuratedNewsletterSection = (id, sectionId) => {
    const url = `/api/v2/newsletter/${id}/section/${sectionId}/clear`;
    return deleteDecorator(url);
};

export const sendInstantNewsletter = (id) => {
    const url = `/api/v2/newsletter/${id}/instant_delivery`;
    return patchDecorator(url);
};

export const getNewsletterPreview = (id, sentId, pdf = false, title) => {
    let url = `/api/v2/newsletter/${id}/preview?&pdf=${pdf}`;
    if(sentId) {
        url += `&sentId=${sentId}`;
    }
    if(pdf) {
        return csvDecorator(url, title, true);
    } else {
        return getDecorator(url);
    }
};

export const checkPossibleToAddNewsletter = () => {
    const url = '/api/v2/organization/is_possible_add_newsletter';
    return getDecorator(url);
};

export const assignUsersToNewsletter = (id, params) => {
    const url = `/api/v2/newsletter/${id}/assign_users`
    return patchDecorator(url, params);
};

export const globalAssignNewsletter = (params) => {
    const url = `/api/v2/organization/assign_newsletters`;
    return patchDecorator(url, params);
};

export const hideArticleFromList = (id, articleId) => {
    const url = `/api/v2/newsletter/${id}/hide_news/${articleId}`
    return patchDecorator(url);
};


export function addArticleToCuratedNewsletter(id, sectionId, params) {
    const rawToken = localStorage.getItem('token')
    const parsedToken = rawToken && JSON.parse(rawToken)

    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('url', params.url);
    formData.append('abstract', params.abstract);
    formData.append('comment', params.comment);
    formData.append('title', params.title);

    return axios(`/api/v2/newsletter/${id}/section/${sectionId}/news`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Type': 'multipart/form-data',
            'X-AUTH-TOKEN': parsedToken.accessToken
        },
        credentials: 'same-origin',
        data: formData
    })
        .then(res => res.data)
        .catch(err => {
            throw err.response.data.content;
        });
}