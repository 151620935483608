import React from 'react';
import './style.scss';
import ReactTooltip from 'react-tooltip';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const InputWithError = (props) => {
    const { prependIcon, onChange, autoCompleteOn, errorObj, name, marginBottomClass, placeholder, value, type, disabled, tooltipText } = props;

    const hasError = errorObj && errorObj.errors && errorObj.errors.hasOwnProperty(name);
    return (
        <div className={'d-flex flex-column flex-1 ' + (hasError ? 'mb-2' : marginBottomClass ? marginBottomClass : 'mb-4')}>
            <InputGroup className={'m-0 ' + (hasError ? 'input-group-with-error' : '')}>
                {prependIcon && (
                    <>
                        <InputGroupAddon addonType="prepend" data-tip={tooltipText}>
                            <InputGroupText>{prependIcon}</InputGroupText>
                        </InputGroupAddon>
                        <ReactTooltip place="bottom" effect="solid" delayShow={200} className="tooltip-thin" />
                    </>
                )}
                <Input
                    autoComplete={autoCompleteOn}
                    placeholder={placeholder}
                    value={value}
                    type={type}
                    disabled={disabled}
                    onChange={(e) => onChange(e.target.value)}
                    style={{ borderLeft: !prependIcon && hasError ? '1px solid red' : '' }}
                />
            </InputGroup>
            {hasError && <div className="error-text mt-1">{errorObj.errors[name][0].message}</div>}
        </div>
    );
};

export default InputWithError;
