import React from 'react';

import {convertDateString} from 'Helpers/convertDateString';
import {truncateLongWords} from 'Helpers/truncateLongWords';
import {truncateWithoutWordBreak} from 'Helpers/truncateWithoutWordBreak';

const Article = (props) => {
    const {article, selectedArticle} = props;
    const {title, abstract, resource, created_at, id} = article;

    return (
        <a className="mobile-reader-article-link" href={article.url} target="_blank">
            <div
                className={'mobile-reader-article ' +
                ((selectedArticle && selectedArticle.id === id) ? 'mobile-reader-article--active' : '')}
                id={id}
            >
                <div className="mobile-reader-article__info-block">
                    <div className="reader-article__publisher">{resource}</div>
                    <div className="reader-article__date">{convertDateString(created_at)}</div>
                </div>
                {title.length > 100 ?
                    <div className="mobile-reader-article__title"
                         dangerouslySetInnerHTML={{__html: truncateWithoutWordBreak(truncateLongWords(title, 50), 100)}}/>
                    : <div className="mobile-reader-article__title" dangerouslySetInnerHTML={{__html: title}}/>
                }
                {abstract && abstract.length > 36 ?
                    <div className="mobile-reader-article__content"
                         dangerouslySetInnerHTML={{__html: truncateWithoutWordBreak(truncateLongWords(abstract, 50), 100)}}/>
                    : <div className="mobile-reader-article-content" dangerouslySetInnerHTML={{__html: abstract}}/>
                }
            </div>
        </a>
    );
};

export default Article;
