import React, {createContext, useState} from 'react';
import shortid from 'shortid';

export const FlashMessageContext = createContext();

const FlashMessageContextProvider = (props) => {
    const [flashMessages, setFlashMessages] = useState([]);

    const addFlashMessage = (type, message) => {
        if(flashMessages.length === 0) {
            let newArr = [...flashMessages];
            newArr.push({
                type,
                message,
                id: shortid.generate()
            });
            setFlashMessages(newArr)
        }
    };

    const handleAutoDelete = (message) => {
        setTimeout(function () {
            deleteFlashMessage(message.id)
        }, 5000)
    };

    const deleteFlashMessage = (messageId) => {
        // setTimeout(function () {
            let newArr = [...flashMessages];
            let foundMessage = newArr.find(item => item.id === messageId);
            if(foundMessage) {
                newArr.splice(newArr.indexOf(foundMessage), 1);
                setFlashMessages(newArr);
            }
        // }, 500)
    };

    const clearFlashMessages = () => {
        setFlashMessages([])
    };


    return (
        <FlashMessageContext.Provider value={{
            flashMessages,
            addFlashMessage,
            deleteFlashMessage,
            handleAutoDelete,
            clearFlashMessages,
        }}>
            {props.children}
        </FlashMessageContext.Provider>
    );
};

export default FlashMessageContextProvider;