import React, {useContext, useEffect, useState} from 'react';

import {CSVLink} from 'react-csv';
import moment from 'moment-timezone';

import Chart from './Chart';
import {Card, CardHeader, CardBody, UncontrolledTooltip} from 'reactstrap';
import {getClicksPerDay} from 'utils/api/statisticsAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import {AuthContext} from 'contexts/AuthContext';

const ClicksPerDay = (props) => {
    const {handleError} = useContext(AuthContext);
    const {type} = props;
    const [clicksData, setClicksData] = useState(null);
    const [fetchingData, setFetchingData] = useState(true);
    const [csvData, setCsvData] = useState([]);

    const safeTrim = (string) => {
        return string && typeof(string) === 'string' && string.length > 0 ? string.trim() : ' ';
    }

    const fetchClicksPerDay = async() => {
        try {
            setFetchingData(true);
            const params = {
                days: 30,
                isNewsletter: type === 'newsletter',
            };
            let clicks = await getClicksPerDay(params);
            setClicksData(clicks)
            const csvDataRaw = [['Clicks Per Day (Last 30 Days)'], ['Date', 'Clicks']];
            clicks.map(item => {
                return csvDataRaw.push([safeTrim(item.day), item.cnt])
            });
            setCsvData(csvDataRaw)
            setFetchingData(false);
        } catch(err) {
            setFetchingData(false);
            handleError(err);
        }
    };

    useEffect(() => {
        fetchClicksPerDay();
    }, []);

    return (
        <div>
            <Card className="chart-card">
                <CardHeader className="dashboard__header">
                    <span>Clicks Per Day (Last 30 Days)</span>
                    <div>
                        <CSVLink data={csvData} filename={'ozmosys_clicks-per-day_' + moment().utc().format('MMDDYYYY') + '.csv'}>
                            <div id="clicks-csv-button-tooltip">
                                <i className="fa fa-download dashboard-csv-icon"/>
                            </div>
                        </CSVLink>
                        <UncontrolledTooltip placement="bottom" target="clicks-csv-button-tooltip" delay={{ show: 400, hide: 0 }}>
                            Download as CSV file
                        </UncontrolledTooltip>
                    </div>
                </CardHeader>
                <CardBody>
                    {fetchingData ?
                        <LoadingSpinner/>
                        :
                        (clicksData && clicksData.length > 0 ?
                                <Chart clicks={clicksData}/>
                                :
                                <div>View more articles to start collecting data</div>
                        )
                    }
                </CardBody>
            </Card>
        </div>
    );
};

export default ClicksPerDay;
