import React, {useState, useEffect} from 'react';
import {CChart} from '@coreui/react-chartjs';
import 'chartjs-plugin-datalabels';
import moment from 'moment-timezone';
import {Line} from 'react-chartjs-2';

const ArticlesPerDayChart = (props) => {
    const mobileView = window.innerWidth < 650;
    const {articles, articlesPerDay, colorArray, showEmptyMessage} = props;

    const [dataArray, setDataArray] = useState([]);

    useEffect(() => {
        const dataObj = JSON.parse(JSON.stringify(articles));
        const dataArray = dataObj.map(article =>
                article.created_at = article.created_at.slice(0, 10),
        );
        setDataArray(dataArray);
    }, [articles]);

    const convertDateString = dateString => {
        if(!dateString) return null;
        let tz = moment.tz.guess();
        let dateObj = new Date(dateString);
        let momentObj = moment(dateObj).tz(tz);
        return momentObj.format('DD');
    };

    const getOccurrence = (array, value) => {     // Get number of times article date appears in last 30 days array
        return array.filter(v => v === value).length;
    };

    const getValue = (item, index, ticks) => {
        const width = window.innerWidth;
        if(index === 0) {
            return getMonth(index);
        }
        if(width >= 380 && index % 2 === 0) {
            if(item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }
        if(width < 380 && index % 3 === 0) {
            if(item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }
    };

    const getMonth = (index) => {
        let tz = moment.tz.guess();
        let dateObj = new Date(
            (articlesPerDay[index].day + ' 00:00:00').replace(' ', 'T'),
        );
        let momentObj = moment(dateObj).tz(tz);
        return momentObj.format('MMM');
    };

    const buildLabelDate = dateString => {
        if(dateString == null) {
            return '';
        }
        let tz = moment.tz.guess();
        let dateObj = new Date((dateString + ' 00:00:00').replace(' ', 'T'));
        let momentObj = moment(dateObj).tz(tz);
        return momentObj.format('MMM DD,YYYY');
    };

    const labels = articlesPerDay.map(item => item.day);

    const articlesBarArray = labels.map(item => {
        return getOccurrence(dataArray, item);
    });

    return (
        <div style={{maxHeight: 300}}>
            {showEmptyMessage &&
                <div className="dig-deeper-empty-chart">No alert content is available yet. Please check again later for updated content.</div>
            }

            {/* <CChart
                type="line" 
                data={{
                    labels: labels.map(item => convertDateString(item)),
                    datasets: [
                        {
                            label: "Volume",
                            backgroundColor: "rgba(151, 187, 205, 0.2)",
                            borderColor: "rgba(151, 187, 205, 1)",
                            pointBackgroundColor: "rgba(151, 187, 205, 1)",
                            pointBorderColor: "#fff",
                            data: articlesBarArray,
                            xAxisID: 'xAxis',
                        },
                    ],
                    
                }}

                options={{
                    responsive: false,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: function(context) {
                                    return buildLabelDate(
                                        articlesPerDay[context[0].dataIndex].day,
                                    );
                                }
                            }
                        }
                    },
                    scales: {
                        xAxis: {
                            ticks: {
                                autoSkip: false,
                                callback: function (value, index, ticks) {
                                    return getValue(this.getLabelForValue(value), index, ticks.map(item => this.getLabelForValue(item.value)));
                                },
                            }
                        },
                    }
                }} 
                />*/}

            <Line
                data={{
                    labels: labels.map(item => convertDateString(item)),
                    datasets: [
                        {
                            label: "Volume",
                            borderColor: 'rgba(151, 187, 205, 1)',
                            backgroundColor: 'rgba(151, 187, 205, 0.2)',
                            data: articlesBarArray,
                        },
                    ],
                    
                }}
                height={mobileView ? 500 : 300}
                responsive={false}
                options={{
                    legend: {
                        display: true,
                        position: 'top',
                        paddingLeft: 10,
                        labels: {
                            boxWidth: mobileView ? 20 : 40,
                            fontSize: mobileView ? 15 : 12,
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                ticks: {
                                    maxTicksLimit: 15,
                                },
                            },
                        ],
                    },
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                    },
                }}
            />
            
        </div>
    );
};

export default ArticlesPerDayChart;
