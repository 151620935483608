import React, { useState, useEffect } from 'react';

import { UncontrolledTooltip } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Badge, Button, Spinner } from 'reactstrap';

import AlertCommentForm from 'containers/Alerts/AlertsList/AlertCommentForm';

import { useHistory } from 'react-router-dom';
import LabelsBlock from './LabelsBlock';
import LoadingSpinnerSmall from 'components/LoadingSpinner/LoadingSpinnerSmall';
import RSSLogo from 'images/rss-icon.png';
import RSSLexisNexisLogo from 'images/rssnllogo3.png';
import BlueLogo from 'images/ozmosy-blue-logo.png';
import OpenEnvelope from 'images/open-envelope.svg';
import GroupLogo from 'images/group-logo-cropped3.png';
import { convertDateString } from 'Helpers/convertDateString';
import { suspendAlert } from 'utils/api/alertsAPI';
import ReactTooltip from 'react-tooltip';
import { CONTENT_TYPE } from '.';

const AlertListItem = (props) => {
    const {
        alert,
        addFlashMessage,
        refreshAlerts,
        toggleDeleteModal,
        allLabels,
        fetchingLabels,
        refreshLabels,
        handleAlertPreview,
        isSingleUser,
        alertType,
    } = props;
    let history = useHistory();

    const [suspended, setSuspended] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [tooltipCopied, setTooltipCopied] = useState(false);

    const isDisable = alert.id === null;
    const isCategorySourceHasChildren = alertType === 'category' && alert?.source?.countOfChildren > 0;

    useEffect(() => {
        setSuspended(alert.suspended);
        setUpdating(false);
    }, [alert.suspended]);

    const goToDigDeeper = (id) => {
        if (alert.source.type === 'company_based') {
            history.push(`/account/alerts/${id}/company-profile`);
            return;
        }
        history.push(`/account/alerts/dig-deeper/${id}`);
    };

    const goToAssignment = (id) => {
        history.push(`/account/alerts/assign/${id}`);
    };

    const goToEdit = (id) => {
        if (alertType === CONTENT_TYPE) {
            history.push(`/account/sources/edit/${id}`);
        } else {
            history.push(`/account/searches/edit/${id}`);
        }
    };

    const toggleSuspension = async (id) => {
        if (!updating) {
            try {
                setUpdating(true);
                const params = {
                    suspend: !alert.suspended,
                };
                await suspendAlert(id, params);
                refreshAlerts(null, true);
            } catch (err) {
                setUpdating(false);
                addFlashMessage('danger', 'Unable to change alert status at this time');
            }
        }
    };

    const setTooltipText = () => {
        setTooltipCopied(true);
    };

    const resetTooltipText = () => {
        setTooltipCopied(false);
    };

    const tooltipText = tooltipCopied ? 'Copied to Clipboard' : 'Copy to Clipboard';

    const handleGoogleKeywordValue = (keyword) => {
        if (keyword.slice(0, 11) === 'allintitle:') {
            return keyword.slice(11, keyword.length);
        } else if (keyword.slice(0, 10) === 'allintext:') {
            return keyword.slice(10, keyword.length);
        } else {
            return keyword;
        }
    };

    return (
        <tr key={alert.id} className="alert-list-item">
            <td data-label="Name / Source Preview" style={{ midWidth: '120px' }}>
                {/*<a className="link-style"
                    onClick={() => handleAlertPreview(alert)}>*/}
                {alert.name}
                {/*</a>*/}
                {alert.source.type === 'internal' &&
                    alert.source.categories &&
                    alert.source.categories.hasOwnProperty('name') &&
                    alert.source.categories.name && (
                        <>
                            <br />
                            <div className="alert-list-item__category">
                                <div className="category-type">{alert.source.categories.type}</div>
                                {alert.source.categories.name}
                            </div>
                        </>
                    )}
                {isDisable ? (
                    <Spinner size="sm" style={{ marginLeft: '7px' }} />
                ) : (
                    <AlertCommentForm alert={alert} addFlashMessage={addFlashMessage} refreshAlerts={refreshAlerts} />
                )}
            </td>
            <td data-label="Source / Keyword" className="alert-list-td">
                <div>
                    {alert.source.type === 'ozmosys_categorized' && (
                        <CopyToClipboard text={alert.source.url}>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                    wordWrap: 'break-word',
                                    cursor: 'pointer',
                                }}
                                onMouseEnter={() => resetTooltipText()}>
                                <div onClick={() => setTooltipText()} id={'google-label-tooltip-' + alert.id}>
                                    <div style={{ marginLeft: 10 }}>
                                        {alert.source.keyword ? (
                                            <div>
                                                <img
                                                    style={{
                                                        marginLeft: 10,
                                                        maxHeight: '25px',
                                                        position: 'relative',
                                                        top: '-2px',
                                                    }}
                                                    alt={'internal-alert-icon'}
                                                    src={BlueLogo}
                                                />
                                                <div style={{ marginLeft: 10 }}>{alert.source.keyword}</div>
                                            </div>
                                        ) : (
                                            <img
                                                className="rss-logo"
                                                height="35px"
                                                style={{ marginLeft: 10 }}
                                                src={RSSLogo}
                                                alt="rss_logo"
                                                id={'rss-label-tooltip-' + alert.id}
                                                onClick={() => setTooltipText()}
                                            />
                                        )}
                                    </div>
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        autohide={false}
                                        target={'google-label-tooltip-' + alert.id}
                                        delay={{ show: 200, hide: 0 }}>
                                        {tooltipText}
                                    </UncontrolledTooltip>
                                </div>
                            </div>
                        </CopyToClipboard>
                    )}
                    {alert.source.type === 'company_based' && (
                        <CopyToClipboard text={alert.source.url}>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                    wordWrap: 'break-word',
                                    cursor: 'pointer',
                                }}
                                onMouseEnter={() => resetTooltipText()}>
                                <div onClick={() => setTooltipText()} id={'google-label-tooltip-' + alert.id}>
                                    <div style={{ marginLeft: 10 }}>{alert.source.symbol}</div>
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        autohide={false}
                                        target={'google-label-tooltip-' + alert.id}
                                        delay={{ show: 200, hide: 0 }}>
                                        {tooltipText}
                                    </UncontrolledTooltip>
                                </div>
                            </div>
                        </CopyToClipboard>
                    )}
                    {alert.source.type === 'google' && (
                        <CopyToClipboard text={alert.source.url}>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                    wordWrap: 'break-word',
                                    cursor: 'pointer',
                                }}
                                onMouseEnter={() => resetTooltipText()}>
                                <div onClick={() => setTooltipText()} id={'google-label-tooltip-' + alert.id}>
                                    <i style={{ marginLeft: 10, fontSize: '1.2rem' }} className="google-icon fa fa-google " />
                                    <Badge style={{ marginLeft: 5 }} color="info">
                                        {alert.source.language}
                                    </Badge>
                                    <Badge>{alert.source.country}</Badge>

                                    <div style={{ marginLeft: 10 }}>
                                        {!alert.source.keyword ? '' : handleGoogleKeywordValue(alert.source.keyword)}
                                    </div>
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        autohide={false}
                                        target={'google-label-tooltip-' + alert.id}
                                        delay={{ show: 200, hide: 0 }}>
                                        {tooltipText}
                                    </UncontrolledTooltip>
                                </div>
                            </div>
                        </CopyToClipboard>
                    )}
                    {alert.source.type === 'internal' && (
                        <CopyToClipboard text={alert.source.url}>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                    wordWrap: 'break-word',
                                    cursor: isDisable ? 'initial' : 'pointer',
                                }}
                                onMouseEnter={() => !isDisable && resetTooltipText()}>
                                <div onClick={() => !isDisable && setTooltipText()} id={'internal-label-tooltip-' + alert.id}>
                                    <img
                                        style={{
                                            marginLeft: 10,
                                            maxHeight: '25px',
                                            position: 'relative',
                                            top: '-2px',
                                        }}
                                        alt={'internal-alert-icon'}
                                        src={BlueLogo}
                                    />
                                    <div style={{ marginLeft: 10 }}>
                                        {!alert.source.keyword
                                            ? ''
                                            : alert.source.keyword.slice(0, 8) === 'intitle:'
                                              ? alert.source.keyword.slice(8, alert.source.keyword.length)
                                              : alert.source.keyword}
                                    </div>
                                    {!isDisable && (
                                        <UncontrolledTooltip
                                            placement="bottom"
                                            autohide={false}
                                            target={'internal-label-tooltip-' + alert.id}
                                            delay={{ show: 200, hide: 0 }}>
                                            {tooltipText}
                                        </UncontrolledTooltip>
                                    )}
                                </div>
                            </div>
                        </CopyToClipboard>
                    )}
                    {alert.source.type === 'email_publication' && (
                        <CopyToClipboard text={alert.source.url}>
                            <div
                                style={{
                                    wordBreak: 'break-all',
                                    wordWrap: 'break-word',
                                    cursor: 'pointer',
                                }}
                                onMouseEnter={() => resetTooltipText()}>
                                <div onClick={() => setTooltipText()} id={'internal-label-tooltip-' + alert.id}>
                                    <img style={{ marginLeft: '10px' }} src={OpenEnvelope} alt="Publication" />
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        autohide={false}
                                        target={'internal-label-tooltip-' + alert.id}
                                        delay={{ show: 200, hide: 0 }}>
                                        {tooltipText}
                                    </UncontrolledTooltip>
                                </div>
                            </div>
                        </CopyToClipboard>
                    )}
                    {(alert.source.type === 'rss' ||
                        alert.source.type === 'internal_group' ||
                        alert.source.type === 'courtlink' ||
                        alert.source.type === 'legi_scan_bill_internal') && (
                        <div>
                            <CopyToClipboard text={alert.source.url}>
                                <div
                                    className="alert-group-rss-label"
                                    style={{
                                        wordBreak: 'break-all',
                                        wordWrap: 'break-word',
                                    }}
                                    onMouseEnter={() => resetTooltipText()}>
                                    <div style={{ cursor: 'pointer' }}>
                                        {alert.source.type === 'rss' ||
                                        alert.source.type === 'courtlink' ||
                                        alert.source.type === 'legi_scan_bill_internal' ? (
                                            alert.webToken &&
                                            alert.webToken.length > 0 &&
                                            (alert.webToken[0].type === 1 || alert.webToken[0].type === 2) ? (
                                                <img
                                                    className="rss-logo"
                                                    height="44px"
                                                    style={{ marginLeft: 5 }}
                                                    src={RSSLexisNexisLogo}
                                                    alt="rss_logo"
                                                    id={'rss-label-tooltip-' + alert.id}
                                                    onClick={() => setTooltipText()}
                                                />
                                            ) : (
                                                <img
                                                    className="rss-logo"
                                                    height="35px"
                                                    style={{ marginLeft: 10 }}
                                                    src={RSSLogo}
                                                    alt="rss_logo"
                                                    id={'rss-label-tooltip-' + alert.id}
                                                    onClick={() => setTooltipText()}
                                                />
                                            )
                                        ) : (
                                            <img
                                                className="rss-logo"
                                                height="44px"
                                                style={{ marginLeft: 6 }}
                                                src={GroupLogo}
                                                alt="rss_logo"
                                                id={'rss-label-tooltip-' + alert.id}
                                                onClick={() => setTooltipText()}
                                            />
                                        )}
                                    </div>
                                </div>
                            </CopyToClipboard>
                            <UncontrolledTooltip
                                placement="bottom"
                                autohide={false}
                                target={'rss-label-tooltip-' + alert.id}
                                delay={{ show: 200, hide: 0 }}>
                                {tooltipText}
                            </UncontrolledTooltip>
                        </div>
                    )}
                </div>
            </td>
            {!isSingleUser && (
                <td data-label="Labels">
                    <LabelsBlock
                        alert={alert}
                        labels={alert.labels}
                        allLabels={allLabels}
                        refreshLabels={refreshLabels}
                        fetchingLabels={fetchingLabels}
                        refreshAlerts={refreshAlerts}
                        disabled={isDisable}
                    />
                </td>
            )}
            <td data-label="Assigned To" style={{ minWidth: '90px' }} className="alert-list-td">
                {alert.countAssignedUsers}
            </td>
            <td data-label="Created At" className="alert-list-td">
                {convertDateString(alert.createdAt)}
            </td>
            <td data-label="Content Updated At" className="alert-list-td">
                {alert.source.contentUpdatedAt === null ? 'Pending updates' : convertDateString(alert.source.contentUpdatedAt)}
            </td>
            <td data-label="Assignment" className="alert-list-td">
                <Button
                    color="primary"
                    style={{ cursor: isDisable ? 'not-allowed' : 'pointer' }}
                    disabled={isDisable}
                    onClick={() => !isDisable && goToAssignment(alert.id)}>
                    Assign
                </Button>
            </td>
            <td data-label="Dig Deeper" className="alert-list-td">
                <Button
                    color="light"
                    style={{ cursor: isDisable ? 'not-allowed' : 'pointer' }}
                    onClick={() => !isDisable && goToDigDeeper(alert.id)}
                    disabled={isDisable}>
                    <i className="fa fa-bar-chart fa-lg" />
                </Button>
            </td>
            <td data-label="Last 30 Days Clicks" className="alert-list-td">
                {alert.countClicks}
            </td>
            <td data-label="Actions" style={{ minWidth: '168px' }}>
                <div className="responsive-table__edit-block">
                    {alert.source.type !== 'company_based' && (
                        <Button
                            className="edit-delete-button"
                            color="light"
                            style={{ cursor: isDisable ? 'not-allowed' : 'pointer' }}
                            disabled={isDisable}
                            onClick={() => !isDisable && goToEdit(alert.id)}>
                            <i className="fa fa-edit fa-lg" />
                        </Button>
                    )}
                    <div style={{ display: suspended ? 'inline-block' : 'none' }}>
                        <Button
                            color="light"
                            style={{ cursor: isDisable ? 'not-allowed' : 'pointer' }}
                            disabled={isDisable}
                            onClick={() => !isDisable && toggleSuspension(alert.id)}
                            data-tip="Resume Alert">
                            {updating ? <LoadingSpinnerSmall /> : <i className="fa fa-play fa-lg" />}
                        </Button>
                        <ReactTooltip place="bottom" effect="solid" delayShow={200} className="tooltip-thin" />
                    </div>

                    <div style={{ display: !suspended ? 'inline-block' : 'none' }}>
                        <Button
                            color="light"
                            style={{ cursor: isDisable ? 'not-allowed' : 'pointer' }}
                            disabled={isDisable}
                            onClick={() => !isDisable && toggleSuspension(alert.id, 'true')}
                            data-tip="Pause Alert">
                            {updating ? (
                                <LoadingSpinnerSmall />
                            ) : alert.suspended ? (
                                <i className="fa fa-play fa-lg" />
                            ) : (
                                <i className="fa fa-pause fa-lg" />
                            )}
                        </Button>
                        <ReactTooltip place="bottom" effect="solid" delayShow={200} className="tooltip-thin" />
                    </div>
                    <Button
                        className="delete-button"
                        color="light"
                        data-tip={
                            isCategorySourceHasChildren
                                ? 'There are sources associated with this category that needs to be removed first'
                                : 'Delete Alert'
                        }
                        style={{ cursor: isDisable || isCategorySourceHasChildren ? 'not-allowed' : 'pointer' }}
                        disabled={isDisable || isCategorySourceHasChildren}
                        onClick={() => !isDisable && !isCategorySourceHasChildren && toggleDeleteModal(alert)}>
                        <i className="fa fa-trash fa-lg" />
                    </Button>
                    <ReactTooltip place="bottom" effect="solid" delayShow={200} className="tooltip-thin" />
                </div>
            </td>
        </tr>
    );
};

export default AlertListItem;
