import React from 'react';
import {FormGroup, Input} from 'reactstrap';

const AlertListMobileFilterDropdown = ({activeMobileFilterValue, handleMobileFilterSelect}) => {
    return (
        <div className="list__mobile-select">
            <FormGroup className="m-0">
                <Input
                    onChange={e => handleMobileFilterSelect(e.target.value)}
                    value={activeMobileFilterValue}
                    type="select"
                    name="alertListMobileFilters"
                >
                    <option value="alert.name-asc">Name(A-Z)</option>
                    <option value="alert.name-desc">Name(Z-A)</option>
                    <option value="source.keyword-asc">Keyword(A-Z)</option>
                    <option value="source.keyword-desc">Keyword(Z-A)</option>
                    <option value="alert.countAssignedUsers-asc">Assigned Users(Ascending)</option>
                    <option value="alert.countAssignedUsers-desc">Assigned Users(Descending)</option>
                    <option value="alert.createdAt-asc">Created At(Ascending)</option>
                    <option value="alert.createdAt-desc">Created At(Descending)</option>
                    <option value="source.contentUpdatedAt-asc">Updated At(Ascending)</option>
                    <option value="source.contentUpdatedAt-desc">Updated At(Descending)</option>
                    <option value="alert.countClicks-asc">Last 30 Days Clicks(Ascending)</option>
                    <option value="alert.countClicks-desc">Last 30 Days Clicks(Descending)</option>
                </Input>
            </FormGroup>

        </div>
    );
};

export default AlertListMobileFilterDropdown;
