import React, {createContext, useState} from 'react';
import shortid from 'shortid';

export const FilterContext = createContext();

const FilterContextProvider = (props) => {
    const [alertFilters, setAlertFilters] = useState({
        paginationCurrentPageNumber: 1,
        activeSortProperty: 'alert.createdAt',
        activeSortDirection: 'desc',
        activeFilterLabels: [],
        searchValue: '',
        searchProperty: 'alert.nameOrKeyword',
        useCount: true,
    });
    const [alertCatCompFilters, setAlertCatCompFilters] = useState({
        paginationCurrentPageNumber: 1,
        activeSortProperty: 'alert.name',
        activeSortDirection: 'asc',
        activeFilterLabels: [],
        searchValue: '',
        searchProperty: 'alert.nameOrKeyword',
        useCount: true,
    });
    const [alertGroupFilters, setAlertGroupFilters] = useState({
        searchValue: '',
        activeSortProperty: 'name',
        activeSortDirection: 'asc'
    });
    const [alertLabelFilters, setAlertLabelFilters] = useState({
        searchValue: '',
        activeSortProperty: 'name',
        activeSortDirection: 'asc'
    });
    const [userFilters, setUserFilters] = useState({
        paginationCurrentPageNumber: 1,
        activeSortProperty: 'user.name',
        activeSortDirection: 'asc',
        activeFilterLabels: [],
        searchValue: '',
        searchProperty: 'user.emailOrName',
    });
    const [userGroupFilters, setUserGroupFilters] = useState({
        searchValue: '',
        activeSortProperty: 'name',
        activeSortDirection: 'asc'
    });
    const [userLabelFilters, setUserLabelFilters] = useState({
        searchValue: '',
        activeSortProperty: 'name',
        activeSortDirection: 'asc'
    });
    const [newsletterFilters, setNewsletterFilters] = useState({
        paginationCurrentPageNumber: 1,
        activeSortProperty: 'newsletter.name',
        activeSortDirection: 'asc',
        searchValue: '',
        searchProperty: 'newsletter.name',
    });
    const [compSetFilters, setCompSetFilters] = useState({
        paginationCurrentPageNumber: 1,
        activeSortProperty: 'comparativeSet.name',
        activeSortDirection: 'asc',
        searchValue: '',
        searchProperty: 'comparativeSet.name',
    });

    const setFiltersObject = (type, valueObj) => {
        switch(type) {
            case 'alerts':
                setAlertFilters(valueObj);
                break;
            case 'alertCatComp':
                setAlertCatCompFilters(valueObj);
                break;
            case 'alertGroups':
                setAlertGroupFilters(valueObj);
                break;
            case 'alertLabels':
                setAlertLabelFilters(valueObj);
                break;
            case 'users':
                setUserFilters(valueObj);
                break;
            case 'userGroups':
                setUserGroupFilters(valueObj);
                break;
            case 'userLabels':
                setUserLabelFilters(valueObj);
                break;
            case 'newsletters':
                setNewsletterFilters(valueObj);
                break;
            case 'compSets':
                setCompSetFilters(valueObj);
                break;
            default:
                return;
        }
    }

    const resetAllFilters = () => {
        setFiltersObject('alerts', {
            paginationCurrentPageNumber: 1,
            activeSortProperty: 'alert.createdAt',
            activeSortDirection: 'desc',
            activeFilterLabels: [],
            searchValue: '',
            searchProperty: 'alert.nameOrKeyword',
            useCount: true,
        });
        setFiltersObject('alertCatComp', {
            paginationCurrentPageNumber: 1,
            activeSortProperty: 'alert.name',
            activeSortDirection: 'asc',
            activeFilterLabels: [],
            searchValue: '',
            searchProperty: 'alert.nameOrKeyword',
            useCount: true,
        });
        setFiltersObject('alertGroups', {
            searchValue: '',
            activeSortProperty: 'name',
            activeSortDirection: 'asc'
        });
        setFiltersObject('alertLabels', {
            searchValue: '',
            activeSortProperty: 'name',
            activeSortDirection: 'asc'
        });
        setFiltersObject('users', {
            paginationCurrentPageNumber: 1,
            activeSortProperty: 'user.name',
            activeSortDirection: 'asc',
            activeFilterLabels: [],
            searchValue: '',
            searchProperty: 'user.emailOrName',
        });
        setFiltersObject('userGroups', {
            searchValue: '',
            activeSortProperty: 'name',
            activeSortDirection: 'asc'
        });
        setFiltersObject('userLabels', {
            searchValue: '',
            activeSortProperty: 'name',
            activeSortDirection: 'asc'
        });
        setFiltersObject('newsletters', {
            paginationCurrentPageNumber: 1,
            activeSortProperty: 'newsletter.name',
            activeSortDirection: 'asc',
            searchValue: '',
            searchProperty: 'newsletter.name',
        });
        setFiltersObject('compSets', {
            paginationCurrentPageNumber: 1,
            activeSortProperty: 'comparativeSet.name',
            activeSortDirection: 'asc',
            searchValue: '',
            searchProperty: 'comparativeSet.name',
        });
    }

    return (
        <FilterContext.Provider value={{
            setFiltersObject,
            alertFilters,
            alertCatCompFilters,
            alertLabelFilters,
            alertGroupFilters,
            userFilters,
            userGroupFilters,
            userLabelFilters,
            newsletterFilters,
            compSetFilters,
            resetAllFilters
        }}>
            {props.children}
        </FilterContext.Provider>
    );
};

export default FilterContextProvider;