import React, {useState, useEffect} from 'react';

import SpinnerButton from 'components/SpinnerButton';
import RelevanceContainerLabels from 'components/Labels/RelevanceContainerLabels';

const SubmitAndLabelsContainer = (props) => {
    const {updatingPreview, handleSubmit, submitting, isEditForm, selectedLabels, labels,
        addToSelected, addLabel, removeFromSelected, creatingLabel, isSingleUser} = props;

    return (
        <div className="alert-form-submit">
            {!isSingleUser &&
            <div className="alert-form-submit__labels-container">
                <div style={{fontWeight: 500, marginBottom: '.5rem'}}>Labels</div>
                {labels &&
                <RelevanceContainerLabels
                    selectedLabels={selectedLabels}
                    labels={labels}
                    removeFromSelected={removeFromSelected}
                    addToSelected={addToSelected}
                    addLabel={addLabel}
                    creatingLabel={creatingLabel}
                    hideBottomMarginWithEmptyList
                    fullWidth
                />
                }
            </div>
            }
        </div>
    );
};

export default SubmitAndLabelsContainer;
