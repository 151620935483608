import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import GoogleAlerts from './GoogleAlerts';
import RSS from './RSS';
import CompanyBased from './CompanyBased';
import InternalAlerts from './InternalAlerts';
import LoadingSpinner from 'components/LoadingSpinner';
import { getAlertById, getAlertLabels, getInternalSearchCategories } from 'utils/api/alertsAPI';
import './style.scss';
import { AuthContext } from 'contexts/AuthContext';
import BillsSearch from 'containers/Alerts/CreateAndEdit/BillsSearch';
import OzmosysCategorizedAlertForm from './OzmosysCategorized';

const EditAlertForm = (props) => {
    const { isSources } = props;
    let { id } = useParams();

    const { handleError } = useContext(AuthContext);
    const [isEditForm, setIsEditForm] = useState(false);
    const [foundAlert, setFoundAlert] = useState({});
    const [loading, setLoading] = useState(true);
    const [fetchingCategories, setFetchingCategories] = useState(true);
    const [categories, setCategories] = useState(null);
    const [formTitle, setFormTitle] = useState(null);

    const [labels, setLabels] = useState([]);

    const fetchLabels = async () => {
        try {
            const foundLabels = await getAlertLabels();
            setLabels(foundLabels);
        } catch (err) {
            handleError(err);
        }
    };

    const refreshLabels = async () => {
        const refreshedLabels = await getAlertLabels();
        setLabels(refreshedLabels);
    };

    useEffect(() => {
        fetchLabels();
        fetchCategories();
    }, []);

    const checkRoute = async () => {
        try {
            const foundAlert = await getAlertById(id);
            setFoundAlert(foundAlert);
            setIsEditForm(true);
            setLoading(false);
            if (foundAlert?.source?.newsCategory?.searchTypeId === 1) {
                setFormTitle('Edit Practice');
            } else if (foundAlert?.source?.newsCategory?.searchTypeId === 2) {
                setFormTitle('Edit Industry');
            } else if (foundAlert?.source?.newsCategory?.searchTypeId === 3) {
                setFormTitle('Edit Topic');
            } else {
                setFormTitle('Edit ' + (isSources ? 'Source' : 'Search'));
            }
        } catch (err) {
            handleError(err);
            setLoading(true);
        }
    };

    useEffect(() => {
        checkRoute();
    }, []);

    const showAlertForm = () => {
        let alertType = foundAlert.source.type;
        switch (alertType) {
            case 'internal':
                return (
                    <InternalAlerts
                        isEditForm
                        foundAlert={foundAlert}
                        labels={labels}
                        refreshLabels={refreshLabels}
                        categories={categories}
                        categoriesLoading={fetchingCategories}
                    />
                );
            case 'rss':
                return <RSS isEditForm foundAlert={foundAlert} labels={labels} refreshLabels={refreshLabels} />;
            case 'legi_scan_bill_internal':
                return <BillsSearch isEditForm foundAlert={foundAlert} labels={labels} refreshLabels={refreshLabels} />;
            case 'internal_group':
                return <RSS isEditForm internalGroup foundAlert={foundAlert} labels={labels} refreshLabels={refreshLabels} />;
            case 'email_publication':
                return <RSS isEditForm foundAlert={foundAlert} labels={labels} refreshLabels={refreshLabels} />;
            case 'google':
                return <GoogleAlerts isEditForm foundAlert={foundAlert} labels={labels} refreshLabels={refreshLabels} />;
            case 'company_based':
                return <CompanyBased isEditForm foundAlert={foundAlert} labels={labels} refreshLabels={refreshLabels} />;
            case 'ozmosys_categorized':
                return <OzmosysCategorizedAlertForm isEditForm foundAlert={foundAlert} labels={labels} refreshLabels={refreshLabels} />;
            default:
                return <InternalAlerts isEditForm foundAlert={foundAlert} labels={labels} refreshLabels={refreshLabels} />;
        }
    };

    const fetchCategories = async () => {
        try {
            setFetchingCategories(true);
            let categoriesData = await getInternalSearchCategories();
            setCategories(categoriesData);
            setFetchingCategories(false);
        } catch (err) {
            handleError(err);
            setFetchingCategories(false);
        }
    };

    return (
        <div className="animated fadeIn view">
            <Row className="mt-3">
                <Col xs="12">
                    <div className="alert-container">
                        <h1>{formTitle}</h1>
                        {loading ? <LoadingSpinner /> : foundAlert && showAlertForm()}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default EditAlertForm;
