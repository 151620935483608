import React, {useState, useEffect} from 'react';

import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import {addAlertGroup, assignAlertsToAlertGroup, getAlertsForGroupList} from 'utils/api/alertsAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import DualSelect from 'components/DualSelect';
import InputWithError from 'components/InputWithError';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const CreateAlertGroupModal = (props) => {
    const {isOpen, toggle, handleError, addFlashMessage, refreshAlertGroups} = props;
    const [name, setName] = useState('');
    const [fetchingAlerts, setFetchingAlerts] = useState(true);
    const [alerts, setAlerts] = useState([]);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        setName('');
    }, [isOpen]);

    const fetchAlerts = async() => {
        try {
            const alertData = await getAlertsForGroupList();
            setAlerts(alertData.result);
            setFetchingAlerts(false);
        } catch(err) {
            setFetchingAlerts(false);
            handleError(err, setErrors, addFlashMessage)
        }
    };

    const handleSubmit = async(name, selectedAlerts) => {
        try {
            setSubmitting(true);
            setErrors(null);
            let createParams = {name};
            let assignParams = {alerts: selectedAlerts.map(item => item.id)};
            const newAlertGroup = await addAlertGroup(createParams);
            await assignAlertsToAlertGroup(newAlertGroup.content.id, assignParams)
            await refreshAlertGroups();
            addFlashMessage('success', 'Alert group successfully created');
            setSubmitting(false)
            toggle();
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors, addFlashMessage);
        }
    }

    useEffect(() => {
        fetchAlerts();
    }, []);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-alert-group-modal modal-xl">
            {fetchingAlerts ?
                <LoadingSpinner padding text={'Fetching alert data'}/>
                :
                <div>
                    <ModalBodyWithClose toggle={toggle}>
                        <h2>Create Group</h2>
                        <InputWithError
                            placeholder="Group name"
                            prependIcon={<i className="fa fa-tag"/>}
                            name="name"
                            value={name}
                            type="text"
                            onChange={setName}
                            errorObj={errors}
                        />
                        <div className="mt-3">
                            <DualSelect
                                selectedList={selectedAlerts}
                                fullList={alerts}
                                onSelect={setSelectedAlerts}
                                contentType="users"
                                isSourcesOnly={true}
                            />
                        </div>
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <SpinnerButton
                            type={'modal'}
                            color="primary"
                            onClick={() => handleSubmit(name, selectedAlerts)}
                            submitting={submitting}
                            title={'Create'}
                        />
                    </ModalFooter>
                </div>
            }
        </Modal>
    );
};

export default CreateAlertGroupModal;
