import {postDecorator, patchDecorator, getDecorator, deleteDecorator} from 'utils/api/decorators';

export const setCustomLogo = (params) => {
    const url = '/api/v2/organization/set_custom_logo';
    return postDecorator(url, params);
};

export const editOrganizationDetails = (params) => {
    const url = `/api/v2/organization/general`;
    return patchDecorator(url, params);
};

export const editOrganizationEmailSettings = (params) => {
    const url = `/api/v2/organization/email_settings`;
    return patchDecorator(url, params);
};

export const editOrganizationLogoPosition = (params) => {
    const url = `/api/v2/organization/logo_position`;
    return patchDecorator(url, params);
};

export const editOrganizationReaderSettings = (params) => {
    const url = `/api/v2/organization/reader_settings`;
    return patchDecorator(url, params);
};

export const getOrganizationLoginToReader = () => {
    const url = `/api/v2/organization/login_to_reader`;
    return getDecorator(url);
};

export const getReaderLoginSettings = () => {
    const url = `/api/v2/organization/login_to_reader`;
    return getDecorator(url);
};

export const editReaderLoginSettings = (params) => {
    const url = `/api/v2/organization/login_to_reader`;
    return patchDecorator(url, params);
};

export const addOrganizationSchedule = (params) => {
    const url = `/api/v2/organization/schedule`;
    return postDecorator(url, params);
};

export const editOrganizationSchedule = (id, params) => {
    const url = `/api/v2/organization/schedule/${id}`;
    return patchDecorator(url, params);
};

export const deleteOrganizationSchedule = (id) => {
    const url = `/api/v2/organization/schedule/${id}`;
    return deleteDecorator(url);
};

export const suspendOrganizationAccount = () => {
    const url = `/api/v2/organization/suspend_account`;

    return patchDecorator(url);
};

export const setAllowNotifications = (id, params) => {
    const url = `/api/v2/user/${id}/allow_notification`;
    return patchDecorator(url, params);
};


export const addCourtlinkToken = (params) => {
    const url = `/api/v2/organization/courtlink_token`;
    return postDecorator(url, params);
};

export const editCourtlinkToken = (id, params) => {
    const url = `/api/v2/organization/courtlink_token/${id}`;
    return patchDecorator(url, params);
};

export const deleteCourtlinkToken = (id) => {
    const url = `/api/v2/organization/courtlink_token/${id}`;
    return deleteDecorator(url);
};


export const addJwtToken = (params) => {
    const url = `/api/v2/organization/jw_token`;
    return postDecorator(url, params);
};

export const editJwtToken = (id, params) => {
    const url = `/api/v2/organization/jw_token/${id}`;
    return patchDecorator(url, params);
};

export const deleteJwtToken = (id) => {
    const url = `/api/v2/organization/jw_token/${id}`;
    return deleteDecorator(url);
};

export const getNotifications = () => {
    const url = `/api/v2/profile/notifications`;
    return getDecorator(url);
};

export const readNotifications = (id) => {
    const url = `/api/v2/profile/read_notifications/${id}`;
    return patchDecorator(url, {});
};
