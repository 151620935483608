import React from 'react';

const LabelsListItem = ({label, active, addToSelected, removeFromSelected}) => {
    const isSelected = active ? 'external-labels-dropdown-list-item--selected' : '';

    const handleSelect = (selectedLabel) => {
        if(active) {
            removeFromSelected(selectedLabel)
        } else {
            addToSelected(selectedLabel)
        }
    };

    return (
        <div className={'external-labels-dropdown-list-item ' + isSelected} onClick={() => handleSelect(label)}>
            {isSelected && <i className="fa fa-check"/>}
            {label.name}
        </div>
    );
};

export default LabelsListItem;
