import React, {useContext, useEffect} from 'react';
import {
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import SignIn from 'containers/Authentication/SignIn';
import SignUp from 'containers/Authentication/SignUp';
import Layout from 'containers/Layout';
import Page404 from './Page404';
import InviteExpired from 'containers/Authentication/InvalidLink';
import ConfirmRegistration from 'containers/Authentication/ConfirmRegistration';
import ConfirmInvite from 'containers/Authentication/ConfirmInvite';
import AccountSuspended from 'containers/Authentication/AccountSuspended';
import AccountSuspendedForm from 'containers/Authentication/AccountSuspendedForm';
import ResetPassword from 'containers/Authentication/ResetPassword';
import ForgotPassword from 'containers/Authentication/ForgotPassword';
import PublicEmailPreview from 'PublicContainers/PublicEmailPreview';
import {AuthContext} from 'contexts/AuthContext';
import PublicDigDeeper from 'containers/Alerts/DigDeeper';
import PublicReader from 'containers/Reader';
import CompanyReaderSignIn from 'containers/Reader/CompanyReaderSignIn';
import PublicCompanyPage from 'containers/Alerts/CompanyPage';

const PrivateRoute = ({children, ...rest}) => {
    const {state} = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={({location}) =>
                state.isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin',
                            // state: {from: location},
                        }}
                    />
                )
            }
        />
    );
};

const PrivateReaderRoute = ({children, ...rest}) => {
    const {state} = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={({location}) =>
                state.isReaderAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/company/reader/signin',
                            // state: {from: location},
                        }}
                    />
                )
            }
        />
    );
};


const PublicRoute = ({children, ...rest}) => {
    const {state} = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={({location}) =>
                !state.isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/account',
                            // state: {from: location},
                        }}
                    />
                )
            }
        />
    );
};

const Routes = () => {
    const {state} = useContext(AuthContext);
    if(!state.authChecked) return null;

    return (
        <Switch>
            {/*<PublicRoute path="/signin">*/}
            {/*    <SignIn/>*/}
            {/*</PublicRoute>*/}
            {/*<PublicRoute path="/signup">*/}
            {/*    <SignUp/>*/}
            {/*</PublicRoute>*/}
            {/*<PublicRoute path="/registration/confirm">*/}
            {/*    <ConfirmRegistration/>*/}
            {/*</PublicRoute>*/}
            {/*<PublicRoute path="/invite-expired">*/}
            {/*    <InviteExpired/>*/}
            {/*</PublicRoute>*/}
            <Route path="/404">
                <Page404/>
            </Route>
            <Route path="/signin">
                <SignIn/>
            </Route>
            <Route path="/signup">
                <SignUp/>
            </Route>
            <Route path="/registration/confirm">
                <ConfirmRegistration/>
            </Route>
            <Route exact path="/invite">
                <ConfirmInvite/>
            </Route>
            <Route path="/invite-expired">
                <InviteExpired/>
            </Route>
            <Route path="/suspended/request">
                <AccountSuspendedForm/>
            </Route>
            <Route exact path="/suspended">
                <AccountSuspended/>
            </Route>
            <Route exact path="/reset-password">
                <ResetPassword/>
            </Route>
            <Route exact path="/forgot-password">
                <ForgotPassword/>
            </Route>
            <Route exact path="/public/email-preview/:id">
                <PublicEmailPreview/>
            </Route>
            <Route exact path="/public/newsletter-preview/:id" children={<PublicEmailPreview newsletter/>}/>
            <Route exact path="/public/dig-deeper/:id" children={<PublicDigDeeper public/>}/>
            <Route exact path="/public/company-profile/:id" children={<PublicCompanyPage public/>}/>
            <Route exact path="/public/reader/:id" children={<PublicReader public/>}/>
            <Route exact path="/company/reader/signin" children={<CompanyReaderSignIn/>}/>
            <Route exact path="/company/reader/forgot-password">
                <ForgotPassword isFromReader/>
            </Route>
            <Route exact path="/company/reader/reset-password">
                <ResetPassword isFromReader/>
            </Route>
            {/*<Route exact path="/company/reader" children={<PublicReader public companyReader/>}/>*/}
            <PrivateReaderRoute path="/company/reader">
                <Route exact path="/company/reader" children={<PublicReader public companyReader/>}/>
            </PrivateReaderRoute>
            <PrivateRoute path="/">
                <Layout/>
            </PrivateRoute>
        </Switch>
    );
};

export default Routes;
