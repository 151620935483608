import { useState, useEffect } from 'react';

export const useTextTypingAnimation = ({ text, speed }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [isLoading, setIsLoading] = useState(null);
    const [withTags, setWithTags] = useState(false);

    useEffect(() => {
        if (text === null) return;

        const typeText = (index) => {
            setIsLoading(true);
            const dots = index % 2 === 0 ? '.' : index % 3 === 0 ? '..' : '...';

            const handleTypingText = (text) => {
                if (text && index <= text.length) {
                    setDisplayedText(text.slice(0, index + 10) + dots);
                    setTimeout(() => typeText(index + 11), speed);
                } else {
                    setIsLoading(false);
                    setDisplayedText((prevValue) => prevValue.replace(/\.*$/, ''));
                }
            };

            if (typeof text === 'string') {
                handleTypingText(text);
            } else if (typeof text === 'object') {
                if (Object.keys(text).length > 1) {
                    setWithTags(true);

                    const mPhrase = Object.entries(text)
                        .map(([key, value]) => `${(key[0].toUpperCase() + key.slice(1)).replace('_', ' ')}: ${value}`)
                        .join('</br>');

                    handleTypingText(mPhrase);
                } else {
                    handleTypingText(Object.values(text)[0]);
                }
            }
        };

        if (text) {
            typeText(0);
        } else {
            setIsLoading(false);
        }
    }, [text, speed]);

    return { displayedText, isLoading, withTags };
};
