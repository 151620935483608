import {postDecorator, patchDecorator, getDecorator, deleteDecorator, csvDecorator} from 'utils/api/decorators';


export const getClicksPerDay = (params) => {
    const {days, isNewsletter} = params;
    const url = `/api/v2/statistics/clicks_per_day?days=${days}&isNewsletter=${isNewsletter}`;
    return getDecorator(url);
};

export const getTopArticles = (params) => {
    const {articles, days, isNewsletter} = params;
    const url = `/api/v2/statistics/top_articles?articles=${articles}&days=${days}&isNewsletter=${isNewsletter}`;
    return getDecorator(url);
};

export const getTopAlerts = (params) => {
    const {alerts, days, isNewsletter} = params;
    const url = `/api/v2/statistics/top_alerts?alerts=${alerts}&days=${days}&isNewsletter=${isNewsletter}`;
    return getDecorator(url);
};

export const getTopPublishers = (params) => {
    const {publishers, days, isNewsletter} = params;
    const url = `/api/v2/statistics/top_publishers?publishers=${publishers}&days=${days}&isNewsletter=${isNewsletter}`;
    return getDecorator(url);
};

export const getZScores = (params) => {
    const {days, limit} = params;
    const url = `/api/v2/statistics/z_scores?days=${days}&limit=${limit}`;
    return getDecorator(url);
};

export const getComparativeSets = (help, count, pageNumber, itemsPerPage, sortProperty, sortDirection, searchProperty, searchValue = '') => {
    // const url = `/api/v2/comparative_set?help=${help}&count=${count}&page=${pageNumber}&itemsOnPage=${itemsPerPage}&sort%5B0%5D%5Bproperty%5D=${sortProperty}&sort%5B0%5D%5Bdirection%5D=${sortDirection.toUpperCase()}&filter%5B0%5D%5Bproperty%5D=${searchProperty}&filter%5B0%5D%5Boperator%5D=contains&filter%5B0%5D%5Bvalue%5D=${searchValue}`;

    let encodedSearchValue = encodeURIComponent(searchValue);
    let url = `/api/v2/comparative_set?help=${help}&count=${count}&page=${pageNumber}&itemsOnPage=${itemsPerPage}&sort%5B0%5D%5Bproperty%5D=${sortProperty}&sort%5B0%5D%5Bdirection%5D=${sortDirection.toUpperCase()}`;

    if(encodedSearchValue.length > 0) {
        url = url + `&filter%5B0%5D%5Bproperty%5D=${searchProperty}&filter%5B0%5D%5Boperator%5D=contains&filter%5B0%5D%5Bvalue%5D=${encodedSearchValue}`;
    }

    return getDecorator(url);
};

export const addComparativeSet = (params) => {
    const url = '/api/v2/comparative_set';
    return postDecorator(url, params);
};

export const getComparativeSetById = (id) => {
    const url = `/api/v2/comparative_set/${id}`;
    return getDecorator(url);
};

export const updateComparativeSet = (id, params) => {
    const url = `/api/v2/comparative_set/${id}`;
    return patchDecorator(url, params);
};

export const deleteComparativeSet = (id) => {
    const url = `/api/v2/comparative_set/${id}`;
    return deleteDecorator(url);
};

export const getComparativeSetStatistics = (id, days) => {
    const url = `/api/v2/comparative_set/${id}/statistics/${days}`;
    return getDecorator(url);
};

export const getAlertsForCompSetList = (searchValue = '', sortProperty, sortDirection) => {
    let url;
    searchValue.length > 0 ?
        url = `/api/v2/alert?help=false&count=false&page=1&itemsOnPage=2000&sort%5B0%5D%5Bproperty%5D=${sortProperty}&sort%5B0%5D%5Bdirection%5D=${sortDirection}&filter%5B0%5D%5Bproperty%5D=alert.name&filter%5B0%5D%5Boperator%5D=contains&filter%5B0%5D%5Bvalue%5D=${searchValue}`
            :
        url = `/api/v2/alert?help=false&count=false&page=1&itemsOnPage=2000&sort%5B0%5D%5Bproperty%5D=${sortProperty}&sort%5B0%5D%5Bdirection%5D=${sortDirection}`;
    return getDecorator(url);
};

export const getUserUsageEmailsPerPeriod = (fromDate, toDate, sortProperty, sortDirection, csv = false) => {
    const url = `/api/v2/statistics/user_usage_emails_per_period?fromDate=${fromDate}&toDate=${toDate}
        &sort%5Bproperty%5D=${sortProperty}&sort%5Bdirection%5D=${sortDirection.toLowerCase()}&csv=${csv}`;
    if(csv) {
        return csvDecorator(url, `user_usages_emails_${fromDate}_${toDate}`);
    } else {
        return getDecorator(url);
    }
};

export const getUserUsageNewslettersPerPeriod = (fromDate, toDate, sortProperty, sortDirection, csv = false) => {
    const url = `/api/v2/statistics/user_usage_newsletters_per_period?fromDate=${fromDate}&toDate=${toDate}
        &sort%5Bproperty%5D=${sortProperty}&sort%5Bdirection%5D=${sortDirection.toLowerCase()}&csv=${csv}`;
    if(csv) {
        return csvDecorator(url, `user_usages_newsletters_${fromDate}_${toDate}`);
    } else {
        return getDecorator(url);
    }
};

export const getMailingObjectStatsPerPeriod = (fromDate, toDate, sortProperty, sortDirection, csv = false) => {
    const url = `/api/v2/statistics/mailing_object_stats_per_period?fromDate=${fromDate}&toDate=${toDate}
        &sort%5Bproperty%5D=${sortProperty}&sort%5Bdirection%5D=${sortDirection.toLowerCase()}&csv=${csv}`;
    if(csv) {
        return csvDecorator(url, `alerts_newsletters_used_${fromDate}_${toDate}`);
    } else {
        return getDecorator(url);
    }
};

export const getBrokenSources = (fromDate, toDate, sortProperty, sortDirection, csv = false) => {
    const url = `/api/v2/statistics/broken_sources?fromDate=${fromDate}&toDate=${toDate}
        &sort_property=${sortProperty}&sort_direction=${sortDirection.toLowerCase()}&csv=${csv}`;
    if(csv) {
        return csvDecorator(url, `broken_sources_${fromDate}_${toDate}`);
    } else {
        return getDecorator(url);
    }
};

export const getPendingSources = (fromDate, toDate, sortProperty, sortDirection, csv = false) => {
    const url = `/api/v2/statistics/pending_sources?fromDate=${fromDate}&toDate=${toDate}
        &sort_property=${sortProperty}&sort_direction=${sortDirection.toLowerCase()}&csv=${csv}`;
    if(csv) {
        return csvDecorator(url, `pending_sources__${fromDate}_${toDate}`);
    } else {
        return getDecorator(url);
    }
};


