import React, {useState, useContext, useEffect} from 'react';
import SpinnerButton from 'components/SpinnerButton';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {DropdownMenu, Dropdown, DropdownToggle, InputGroup} from 'reactstrap';
import {editCommentAlert} from 'utils/api/alertsAPI';
import {AuthContext} from 'contexts/AuthContext';
import TextareaAutosize from 'react-textarea-autosize';

const AlertCommentForm = ({addFlashMessage, refreshAlerts, alert}) => {
    const [submitting, setSubmitting] = useState(false);
    const [noteFormOpen, setNoteFormOpen] = useState(false);
    const [commentValue, setCommentValue] = useState('');
    const {handleError} = useContext(AuthContext);

    useEffect(() => {
        if(alert && alert.comment) {
            setCommentValue(alert.comment);
        } else {
            setCommentValue('');
        }
    }, []);

    const handleSubmit = async() => {
        try {
            setSubmitting(true);
            await editCommentAlert(alert.id, commentValue);
            refreshAlerts();
            setNoteFormOpen(false);
            addFlashMessage('success', 'Comment successfully saved');
            setSubmitting(false);
        } catch(err) {
            handleError(err);
            setSubmitting(false);
        }
    };

    const toggleNoteFormOpen = (e) => {
        e.stopPropagation();
        setNoteFormOpen(!noteFormOpen);
    };

    return (
        <Dropdown isOpen={noteFormOpen} className="alert-list-item__label-dropdown">
        <DropdownToggle color="" className="alert-list-item__add-label-btn">
            <i 
                className={'fa fa-comment ml-2 ' + (alert.comment && alert.comment.length > 0 ? 'theme-text-color' : 'dark-gray')}
                style={{cursor: 'pointer'}}
                onClick={(e) => toggleNoteFormOpen(e)}
            />
        </DropdownToggle>
        <DropdownMenu>
            <div
                onClick={() => setNoteFormOpen(false)}
                className="alert-list-item__label-dropdown-close-btn">
                <span aria-hidden="true">×</span>
            </div>
            <div className={'external-alert-note-dropdown external-alert-note-dropdown--extra-padding'}>
                <div>
                    <div className="external-alert-note-dropdown__form">
                        <InputGroup
                            id="company-header"
                            className="external-alert-note-dropdown__searchbar-container m-0"
                        >
                            <TextareaAutosize
                                placeholder="Add a note"
                                minRows={3}
                                autoFocus
                                name="addNote"
                                value={commentValue}
                                onChange={(e) => setCommentValue(e.target.value)}
                                style={{width: "100%"}}
                            />
                        </InputGroup>
                        <SpinnerButton 
                                color="primary"
                                className="mt-2 external-alert-note-dropdown__btn"
                                onClick={handleSubmit}
                                submitting={submitting}
                                title="Save"
                        />
                    </div>
                </div>
            </div>
        </DropdownMenu>
        </Dropdown>
    );
};

export default AlertCommentForm;
