import React, {useState, useEffect} from 'react';

import {FormGroup, Button, Label, Input} from 'reactstrap';
import Dropzone from 'react-dropzone';

import SpinnerButton from 'components/SpinnerButton';
import LoadingSpinner from 'components/LoadingSpinner';
import {editOrganizationLogoPosition, setCustomLogo} from 'utils/api/profileAPI';
import CropModal from './CropModal';
import './style.scss';

const Appearance = (props) => {

    const {isSingleUser, profile, refreshProfile, addFlashMessage, handleError} = props;
    const [fetchingImage, setFetchingImage] = useState(true);
    const [brandLogoUrl, setBrandLogoUrl] = useState('');
    const [brandLogoPosition, setBrandLogoPosition] = useState('');
    const [file, setFile] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [cropModalOpen, setCropModalOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if(profile.organization) {
            setBrandLogoPosition(profile.organization.brandLogoPosition);
            let origin = window.location.origin === 'https://alerts2-tst.dataprodev.com' ?
                `https://alerts2-api-tst.dataprodev.com/img/`
                :
                `https://api.ozmosys.com/img/`;
            if(profile.organization.brandLogo) {
                setBrandLogoUrl(`${origin}${profile.organization.brandLogo}`);
            } else {
                setBrandLogoUrl(`${origin}/logo.png`);
            }
            setFetchingImage(false);
        }
    }, [profile]);

    const handleDropAccept = (fileArray) => {
        let testArr = fileArray.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
        }));
        setImagePreviewUrl(fileArray[0].preview);
        toggleCropModal();
    };

    const handleDropReject = (item) => {
        if(!(item[0].file.type == 'image/jpeg' || item[0].file.type == 'image/png')) {
            addFlashMessage('danger', 'Invalid file type. File must be in .jpg or .png format.');
        } else if(item[0].file.size > 2000000) {
            addFlashMessage('danger', 'File exceeds the maximum upload size (2MB).');
        }
    };

    const handleLogoUploadSubmit = async(file, modalCallback) => {
        try {
            setSubmitting(true);
            await setCustomLogo({source: file});
            refreshProfile();
            setSubmitting(false);
            addFlashMessage('success', 'Image successfully uploaded');
            modalCallback && modalCallback();
        } catch(err) {
            setSubmitting(false);
            if(err.errors && err.errors.hasOwnProperty('file')) {
                addFlashMessage('danger', err.errors.file[0].message);
            } else {
                addFlashMessage('danger', 'Unable to complete your request at this time');
            }
        }
    };

    const handlePositionSubmit = async() => {
        try {
            setSubmitting(true);
            await editOrganizationLogoPosition({brandLogoPosition});
            refreshProfile();
            setSubmitting(false);
            addFlashMessage('success', 'Settings successfully updated');
        } catch(err) {
            setSubmitting(false);
            handleError(err, null, addFlashMessage);
        }
    };

    const toggleCropModal = () => {
        setCropModalOpen(!cropModalOpen);
    };

    if(fetchingImage) return <LoadingSpinner text={'Fetching email logo'}/>;
    return (
        <div className="appearance">
            <h4 className="settings__page-header">{isSingleUser ? 'Custom Logo' : 'Company Image'}</h4>
            <div className="appearance__img-info">
                This image will appear at the top of your daily email alerts.
                <br/>
                Image will be scaled to a maximum height and width of 300px.
                <br/>
                Minimum resolution: 100 x 80. Maximum file size: 2MB.
            </div>
            <Dropzone
                className="appearance__dropzone"
                accept="image/jpeg, image/png"
                onDropAccepted={handleDropAccept}
                onDropRejected={handleDropReject}
                multiple={false}
                maxSize={2000000}
            >
                {({getRootProps, getInputProps}) => (
                    <div>
                        <div
                            className="appearance__dropzone-content"
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            {fetchingImage ?
                                <div className="mt-2 mb-4">
                                    <LoadingSpinner/>
                                </div>
                                :
                                <div
                                    className="appearance__dropzone-image"
                                >
                                    <img
                                        src={brandLogoUrl}
                                        alt="company-logo"
                                    />
                                </div>
                            }
                            <div style={{color: '#4c4c4c'}}/>
                            <Button
                                color="primary"
                                className="mt-2"
                                block
                                style={{maxWidth: '200px'}}
                            >
                                Add Custom Logo
                            </Button>
                        </div>
                    </div>
                )}
            </Dropzone>
            <div className="mb-4">
                <h4 className="settings__page-header mt-6">Newsletter Logo Placement</h4>
                <FormGroup tag="fieldset" className="mt-3 mb-0">
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" name="placement"
                                   onChange={() => setBrandLogoPosition('left')}
                                   checked={brandLogoPosition === 'left'}/>{' '}
                            Left
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" name="placement"
                                   onChange={() => setBrandLogoPosition('center')}
                                   checked={brandLogoPosition === 'center'}/>{' '}
                            Center
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" name="placement"
                                   onChange={() => setBrandLogoPosition('right')}
                                   checked={brandLogoPosition === 'right'}/>{' '}
                            Right
                        </Label>
                    </FormGroup>
                </FormGroup>
                <div className="mt-4">
                    <SpinnerButton
                        submitting={submitting}
                        color="primary"
                        onClick={handlePositionSubmit}
                        type="button"
                        title={'Save Changes'}
                    />
                </div>
            </div>
            {cropModalOpen && <CropModal
                isOpen={cropModalOpen}
                toggle={toggleCropModal}
                imagePreviewUrl={imagePreviewUrl}
                submitting={submitting}
                addFlashMessage={addFlashMessage}
                onSubmit={handleLogoUploadSubmit}
            />}
        </div>
    );
};

export default Appearance;
