import React, {useState, useEffect} from 'react';

import {Modal, ModalBody, ModalFooter, Button,} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import {addUserLabel} from 'utils/api/usersAPI';
import InputWithError from 'components/InputWithError';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const CreateLabelModal = (props) => {
    const {isOpen, toggle, addFlashMessage, handleError, refreshLabels} = props;

    const [name, setName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        setName('')
    }, [isOpen]);

    const handleSubmit = async() => {
        try {
            setSubmitting(true);
            setErrors(null);
            let params = {
                name
            };
            await addUserLabel(params);
            refreshLabels();
            addFlashMessage('success', 'User label successfully created');
            setSubmitting(false)
            toggle();
        } catch(err) {
            setSubmitting(false)
            handleError(err, setErrors);
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-label-modal">
            <ModalBodyWithClose toggle={toggle} className="pb-0">
                <h2>Create Label</h2>
                <InputWithError
                    placeholder="Label name"
                    name="name"
                    value={name}
                    autoComplete="off"
                    type="text"
                    onChange={setName}
                    errorObj={errors}
                    prependIcon={<i className="fa fa-tag"/>}
                    marginBottomClass="mb-3"
                />
            </ModalBodyWithClose>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                <SpinnerButton
                    type="modal"
                    color="primary"
                    onClick={handleSubmit}
                    submitting={submitting}
                    title="Create"
                />
            </ModalFooter>
        </Modal>
    );
};

export default CreateLabelModal;
