import React, {useState} from 'react';

import {Button} from 'reactstrap';

import CreateAlertGroupModal from './Modals/CreateAlertGroup';

const EmptyList = (props) => {
    const {createGroup, submitting, refreshAlertGroups, handleError, addFlashMessage} = props;
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const toggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen);
    }

    return (
        <div>
            <p>Create your first alert group!</p>
            <Button
                color="primary"
                onClick={toggleCreateModal}
            >
                Create Alert Group
            </Button>
            {createModalOpen &&
            <CreateAlertGroupModal
                isOpen={createModalOpen}
                toggle={toggleCreateModal}
                addFlashMessage={addFlashMessage}
                refreshAlertGroups={refreshAlertGroups}
                handleError={handleError}
            />
            }
        </div>
    );
};

export default EmptyList;
