import React, {useEffect, useState} from 'react';

import {Button, Modal, ModalBody} from 'reactstrap';

import LoadingSpinner from 'components/LoadingSpinner';
import {getLiveOrLastPreview} from 'utils/api/usersAPI';
import './style.scss';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const LiveOrLastPreview = (props) => {
    const {toggle, isOpen, user, type, handleError} = props;
    const [fetchingData, setFetchingData] = useState(true);
    const [fetchingDataError, setFetchingDataError] = useState(false);
    const [browserLink, setBrowserLink] = useState(null);
    const [previewContent, setPreviewContent] = useState(null);

    const fetchUserEmailPreview = async(id) => {
        try {
            let preview = await getLiveOrLastPreview(id, type);
            setPreviewContent(preview);
            setBrowserLink(preview.browser_link);
            setFetchingData(false);
        } catch(err) {
            setFetchingData(false);
            setFetchingDataError(true);
            handleError(err);
        }
    };

    useEffect(() => {
        user && fetchUserEmailPreview(user.id);
    }, [user]);

    const handleBackToTopScroll = () => {
        let anchor = document.querySelector('#anchor');
        anchor.scrollIntoView({behavior: "smooth"});
    };

    useEffect(() => {
        if(previewContent && previewContent.hasOwnProperty('html')) {
            setTimeout(() => {
                let elList = document.querySelectorAll('.scroll-to-link-button');
                elList.forEach((el) => {
                    el.addEventListener('click', () => {
                        let name = el.getAttribute('tabindex');
                        let elementToScrollTo = document.getElementById(name);
                        elementToScrollTo.scrollIntoView({behavior: "smooth"});
                    });
                })
            }, 700);
        }
    }, [previewContent]);

    if(!user) return <div/>;
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="newsletter-preview-modal modal-lg">
            <div>
                <ModalBodyWithClose toggle={toggle}>
                    <div className="d-flex justify-content-between mb-2">
                        <div className="newsletter-preview-modal__header">
                            <h2 className="mb-1">{type === 'last' ? 'Last' : 'Next'} Alert Email Content</h2>
                            <h5 className="mb-0">{user.email}</h5>
                        </div>
                        {previewContent && previewContent.hasOwnProperty('html') &&
                        <div className="d-flex align-items-end">
                            <Button onClick={handleBackToTopScroll}>
                                {window.innerWidth > 600 ?
                                    'Back to top'
                                    :
                                    <i className="fa fa-chevron-up"/>
                                }
                            </Button>
                        </div>
                        }
                    </div>

                    {fetchingDataError ?
                        <div className="mt-4 mb-3">
                            <div className="error-block d-flex">
                                <i className="fa fa-exclamation-circle"/>
                                <div>Unable to fetch email content at this time. Please try again later.</div>
                            </div>
                        </div>
                        :
                        <div className="alert-preview-modal__body">
                            <div id="anchor"/>
                            {fetchingData ?
                                <div style={{padding: '1rem 0'}}>
                                    <LoadingSpinner text="Fetching email content"/>
                                </div>
                                :
                                previewContent && previewContent.html ?
                                    <div>
                                        {type === 'last' &&
                                        <div className="view-in-browser-link-container">
                                            <a
                                                href={browserLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="view-in-browser-link"
                                            >
                                                View in Browser
                                            </a>
                                        </div>
                                        }
                                        <div
                                            className="email-preview-inner"
                                            dangerouslySetInnerHTML={{__html: previewContent.html}}
                                        />
                                    </div>
                                    :
                                    <div className="mt-3 mb-2">No email content is available yet. Please check again
                                        later for updated
                                        content.</div>

                            }
                        </div>
                    }
                </ModalBodyWithClose>
            </div>
        </Modal>
    );
};

export default LiveOrLastPreview;



