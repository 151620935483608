import React, {} from 'react';
import {Bar} from 'react-chartjs-2';
import LoadingSpinner from 'components/LoadingSpinner';

const EmptyChart = ({loading}) => {
    const clickData = {
        datasets: null,
    };
    const mobileView = window.innerWidth < 650;

    return (
        <div className="comparative-analysis-chart comparative-analysis-chart--empty">
            <div className="comparative-analysis-chart__empty-label">
                <span>No alert content is available yet. Please check again later for updated content.</span>
            </div>
            <div
                style={{
                    opacity: 0.5,
                    maxHeight: mobileView ? 500 : 300,
                    alignItems: 'center',
                }}
            >
                <Bar
                    data={clickData}
                    height={mobileView ? 500 : 300}
                    responsive={false}
                    options={{
                        fillOpacity: 0.3,
                        legend: {
                            display: true,
                        },
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [
                                {
                                    ticks: {
                                        autoSkip: true,
                                        maxTicksLimit: 7,
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            ],
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                color: 'white',

                                borderRadius: 6,
                                font: {
                                    size: 12,
                                },
                                formatter: function(value) {
                                    if(value > 0) {
                                        return value;
                                    } else {
                                        return null;
                                    }
                                },
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default EmptyChart;