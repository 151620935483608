import React, {useState} from 'react';

import {
    Modal,
    ModalFooter,
    Button,
} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import {universalAssignAlerts} from 'utils/api/alertsAPI';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const ConfirmAllAssignment = (props) => {
    const {isOpen, toggle, handleError, addFlashMessage, history, tempAssignedUsers, tempAssignedAlerts} = props;
    const [submitting, setSubmitting] = useState(false);

    const handleConfirmAssign = async() => {
        try {
            setSubmitting(true);
            const params = {
                users: tempAssignedUsers.map(user => user.id),
                alerts: tempAssignedAlerts.map(alert => alert.id),
            };
            await universalAssignAlerts(params);
            setSubmitting(false);
            addFlashMessage('success', 'Alerts successfully assigned');
            history.push(`/account/alerts`);
        } catch(err) {
            setSubmitting(false);
            handleError(err);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <div>
                <ModalBodyWithClose toggle={toggle}>
                    <span>You have selected all existing alerts for assignment. Are you sure you want to assign that many alerts?</span>
                </ModalBodyWithClose>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                    <SpinnerButton
                        type="modal"
                        color="primary"
                        onClick={handleConfirmAssign}
                        submitting={submitting}
                        title="Confirm"
                    />
                </ModalFooter>
            </div>
        </Modal>
    );
};

export default ConfirmAllAssignment;
