import React from 'react';

import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const DeleteAlertGroup = (props) => {
    const {isOpen, toggle, onSubmit, submitting, item} = props;

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            {!item ?
                <div/>
                :
                <div>
                    <ModalBodyWithClose toggle={toggle}>
                        <span>Are you sure you want to delete {item.name}?</span>
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <SpinnerButton
                            type={'modal'}
                            color="danger"
                            onClick={() => onSubmit(item.id, toggle)}
                            submitting={submitting}
                            title={'Delete'}
                        />
                    </ModalFooter>
                </div>
            }
        </Modal>
    );
};

export default DeleteAlertGroup;
