import React from 'react';
import SpinnerButton from 'components/SpinnerButton';
import InputWithError from 'components/InputWithError';
import RelevanceContainerLabels from 'components/Labels/RelevanceContainerLabels';

const TitleForm = (props) => {
    const {
        submitting, title, setTitleValue, handleSubmit, isEditForm, selectedLabels, labels,
        addToSelected, addLabel, removeFromSelected, creatingLabel, errors, isSingleUser
    } = props;

    const onPressEnter = (e) => {
        if(e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <div className="mt-3 alert-title-form-container">
            <div className="alert-title-form__inner">
                <div className="w-100">
                    <InputWithError
                        className="w-100"
                        type="text"
                        name="name"
                        autoComplete="off"
                        onChange={setTitleValue}
                        onKeyUp={onPressEnter}
                        value={title}
                        placeholder="Search Name"
                        errorObj={errors}
                        marginBottomClass="mb-3"
                    />
                    {/*{isEditForm && !isSingleUser && (*/}
                    {/*    <div className="alert-title-form__labels-container" style={{marginBottom: selectedLabels.length ? 0 : '.5rem'}}>*/}
                    {/*        <RelevanceContainerLabels*/}
                    {/*            selectedLabels={selectedLabels}*/}
                    {/*            labels={labels}*/}
                    {/*            removeFromSelected={removeFromSelected}*/}
                    {/*            addToSelected={addToSelected}*/}
                    {/*            addLabel={addLabel}*/}
                    {/*            creatingLabel={creatingLabel}*/}
                    {/*            hideBottomMarginWithEmptyList*/}
                    {/*        />*/}
                    {/*    </div>*/}

                    {/*)}*/}
                </div>

            </div>
            <SpinnerButton
                color="primary"
                // className="px-4 alert-title-form__btn alert-title-form__btn--mobile"
                // className="px-4 alert-title-form__btn"
                className="px-4 mb-2"
                onClick={handleSubmit}
                submitting={submitting}
                title={isEditForm ? 'Save' : 'Create'}
            />
        </div>
    );
};


export default TitleForm;
