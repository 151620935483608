import React, {useState, useEffect, useContext} from 'react';

import {Card, CardBody, CardHeader, UncontrolledTooltip} from 'reactstrap';
import moment from 'moment-timezone';
import {CSVLink} from 'react-csv';

import Chart from './Chart';
import Grid from 'components/StatisticsGrid';
import LoadingSpinner from 'components/LoadingSpinner';
import {SafeTrim} from 'Helpers/safeTrim';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {getTopPublishers} from 'utils/api/statisticsAPI';

const TopPublishers = (props) => {
    const {addFlashMessage} = useContext(FlashMessageContext);

    const {labelContainerHeight, type, setPublisherListLength, handleError} = props;

    const [publishers, setPublishers] = useState([]);
    const [sum, setSum] = useState(0);
    const [fetchingData, setFetchingData] = useState(true);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        fetchTopPublishers();
    }, []);

    const fetchTopPublishers = async() => {
        try {
            const params = {
                publishers: 15,
                days: 30,
                isNewsletter: type === 'newsletter',
            };
            const publisherData = await getTopPublishers(params);
            const csvDataRaw = [['Top Publishers (Last 30 Days)', 'Clicks']];
            publisherData.map(item => {
                return csvDataRaw.push([SafeTrim(item.domain), item.cnt]);
            });
            setPublishers(publisherData);
            setCsvData(csvDataRaw);
            setFetchingData(false);
            // if(handleLabelContainerHeight) {
            //     handleLabelContainerHeight(publisherData.slice(0, 5).length, 'publishers');
            // }
            setPublisherListLength(publisherData.slice(0, 5).length);
        } catch(err) {
            setFetchingData(false);
            handleError(err);
        }
    };

    useEffect(() => {
        getPublisherCountSum();
    }, [publishers]);

    const getPublisherCountSum = () => {
        if(publishers.length) {
            const publisherCount = publishers.slice(0, 5).map(item => {
                return item.cnt;
            });
            const newSum = publisherCount.reduce((a, b) => {
                return a + b;
            });
            setSum(newSum);
        }
    };

    const colors = ['#011a54', '#1a94c7', '#03bdea', '#90ccfe', '#c3d2da'];
    let i = -1;
    return (
        <div>
            <Card className="chart-card">
                <CardHeader className="dashboard__header">
                    Top Publishers (Last 30 Days)
                    {publishers.length > 0 &&
                    <div>
                        <CSVLink
                            data={csvData}
                            filename={
                                'ozmosys_top-publishers_' +
                                moment()
                                    .utc()
                                    .format('MMDDYYYY') +
                                '.csv'
                            }
                        >
                            <div id="publishers-csv-button-tooltip">
                                <i className="fa fa-download dashboard-csv-icon"/>
                            </div>
                        </CSVLink>
                        <UncontrolledTooltip
                            placement="bottom"
                            target="publishers-csv-button-tooltip"
                            delay={{show: 400, hide: 0}}
                        >
                            Download as CSV file
                        </UncontrolledTooltip>
                    </div>
                    }
                </CardHeader>
                <CardBody>
                    {(fetchingData || !Number.isInteger(labelContainerHeight)) ?
                        <LoadingSpinner/>
                        :
                        (publishers && publishers.length >= 1 && sum > 0 ? (
                                <div>
                                    <Chart publishers={publishers}/>
                                    <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{height: labelContainerHeight}}
                                    >
                                        <ul className="chart-card__label-list">
                                            {publishers.slice(0, 5).map(publisher => {
                                                i++;
                                                return (
                                                    <li key={i} className="chart-card__label-list-item">
                                                        <div className="chart-card__label-title">
                                                            <div
                                                                className="color-block"
                                                                style={{backgroundColor: colors[i]}}
                                                            />
                                                            <span
                                                                className="chart-label-item-text"
                                                            >
                                                            {publisher.domain.substring(0, 24) + '... '}
                                                            </span>
                                                        </div>
                                                        <div className="chart-card__label-percentage">
                                                            {Math.round((publisher.cnt / sum) * 100) + '%'}
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            ) : (
                                <div>View more publishers to start collecting data</div>
                            )
                        )
                    }
                </CardBody>
            </Card>
            {publishers && publishers.length >= 1 && <Grid items={publishers} type="publisher" withLinks/>}
        </div>
    );
};

export default TopPublishers;
