import React from 'react';
import ManageAlerts from './ManageAlerts';
import MobileSignIn from './MobileSignIn';

const CheckReaderAuth = (props) => {
    const {
        setActiveView,
        addFlashMessage,
        assignedAlerts,
        isPublic,
        state,
        handleError,
        userId,
        refreshAlerts,
        publicEmail
    } = props;
    if(state.isAuthenticated || state.isReaderProfileAuthenticated) {
        return (
            <ManageAlerts
                setActiveView={setActiveView}
                addFlashMessage={addFlashMessage}
                assignedAlerts={assignedAlerts}
                isPublic={isPublic}
                state={state}
                handleError={handleError}
                userId={userId}
                publicEmail={publicEmail}
                refreshAlerts={refreshAlerts}
            />
        );
    } else {
        return (
            <MobileSignIn
                state={state}
                addFlashMessage={addFlashMessage}
                handleError={handleError}
                publicEmail={publicEmail}
            />
        );
    }
};

export default CheckReaderAuth;
