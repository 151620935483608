import React from 'react';

const SelectTag = (props) => {
    const {tags, selectedAlert, handleTagSelect, selectedTag, setActiveView, selectedCategory} = props;

    const capitalizeFirstLetter = (string) => {
        if(!string) return '';
        let str = string.toLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    if(!selectedCategory || !selectedAlert) return null;
    return (
        <div className="mobile-reader-list mobile-reader-tag-list">
            <div className="mobile-reader-list__header mobile-reader-tag-list__header">
                <div
                    onClick={() => setActiveView('filters-select-category')}
                    className="mobile-reader-back-btn"
                >
                    <i className="fa fa-angle-left" />
                    Back
                </div>
                <div>Showing filters for "{selectedAlert.name}"</div>
                <div className="mobile-reader-tag-list__active-alert">Category: <strong>{capitalizeFirstLetter(selectedCategory.type)}</strong></div>
            </div>
            <div className="mobile-reader-list__body">
                {selectedCategory.tags.length < 1 ?
                    <div style={{padding: '.5rem 1rem'}}>No tags found</div>
                    :
                    selectedCategory.tags.map(tag => (
                        <div
                            key={tag.name}
                            onClick={() => handleTagSelect(tag)}
                            className={'mobile-reader-tag-list__tag ' +
                            (!selectedTag ? '' : tag.name.toLowerCase() === selectedTag.name.toLowerCase() ?
                                'mobile-reader-tag-list__tag--active' : '')}
                        >
                            <div>{tag.name}</div>
                            <div className="mobile-reader-tag-list__cnt">
                                {tag.cnt}
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default SelectTag;

