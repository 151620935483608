import React from 'react';

export const handleCaret = (activeSortProperty, activeSortDirection, value) => {
    if(activeSortProperty === value) {
        return activeSortDirection === 'asc' ?
            <i className="fa fa-caret-up list-sort-caret list-sort-caret--active"/>
            :
            <i className="fa fa-caret-down list-sort-caret list-sort-caret--active"/>
    } else {
        return <i className="fa fa-caret-down list-sort-caret"/>
    }
}

export const handleSortableHeaderClick = (
    value, activeSortProperty, setActiveSortProperty,
    activeSortDirection, setActiveSortDirection
) => {
    if(value === activeSortProperty) {
        activeSortDirection === 'asc' ?
            setActiveSortDirection('desc')
            :
            setActiveSortDirection('asc')
    } else {
        setActiveSortProperty(value)
        setActiveSortDirection('asc')
    }
}