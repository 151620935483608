import React from 'react';

const LoadingSpinner = (props) => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <i className="fa fa-spinner fa-spin"/>
        </div>
    );
};

export default LoadingSpinner;
