import React, {useState, useContext, useEffect} from 'react';

import {Button, Table, UncontrolledTooltip} from 'reactstrap';
import {useHistory} from 'react-router-dom';

import LoadingSpinner from 'components/LoadingSpinner';
import NewsletterListItem from './ListItem';
import DeleteNewsletterModal from '../Modals/Delete';
import EditNewsletterModal from '../Modals/CreateAndEdit/EditNewsletter';
import CreateNewsletterModal from '../Modals/CreateAndEdit/CreateNewsletter';
import PreviewNewsletterModal from '../Modals/Preview';
import EmptyList from 'containers/Newsletters/List/EmptyList';
import {
    checkPossibleToAddNewsletter,
    deleteNewsletter,
    getNewsletters, getNewslettersWithoutParams,
    sendInstantNewsletter,
} from 'utils/api/newslettersAPI';
import SearchInput from 'components/SearchInput/SearchInputWithSubmit';
import PaginationWrapper from 'components/PaginationWrapper';
import {handleCaret} from 'Helpers/handleSortCaret';
import {AuthContext} from 'contexts/AuthContext';
import {FilterContext} from 'contexts/FilterContext';
import {CSVLink} from 'react-csv';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import moment from 'moment-timezone';
import SpinnerButtonSquare from 'components/SpinnerButton/Square';
import {SafeTrim} from 'Helpers/safeTrim';
import NewslettersListCsvButton from './NewslettersListCsvButton';
import MobileFilterDropdown from './MobileFilterDropdown';
import {getAlerts, getAlertsWithoutParams} from 'utils/api/alertsAPI';
import AlertListCsvButton from 'containers/Alerts/AlertsList/AlertListCsvButton';

const NewslettersList = (props) => {
    let history = useHistory();
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError} = useContext(AuthContext);
    const {setFiltersObject, newsletterFilters} = useContext(FilterContext);

    const {paginationCurrentPageNumber, activeSortProperty, activeSortDirection, searchValue, searchProperty} = newsletterFilters;

    const [newsletters, setNewsletters] = useState([]);
    const [createNewsletterModalOpen, setCreateNewsletterModalOpen] = useState(false);
    const [editNewsletterModalOpen, setEditNewsletterModalOpen] = useState(false);
    const [deleteNewsletterModalOpen, setDeleteNewsletterModalOpen] = useState(false);
    const [previewNewsletterModalOpen, setPreviewNewsletterModalOpen] = useState(false);
    const [lastSentNewsletterModalOpen, setLastSentNewsletterModalOpen] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [itemToPreview, setItemToPreview] = useState(null);
    const [lastSentItemToPreview, setLastSentItemToPreview] = useState(null);
    const [fetchingData, setFetchingData] = useState(true);
    const [navOptions, setNavOptions] = useState({});
    const [tempSearchValue, setTempSearchValue] = useState('');
    const [submittingSearch, setSubmittingSearch] = useState(false);
    // const [showFirstTimeMessage, setShowFirstTimeMessage] = useState(true);
    const [deleting, setDeleting] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [csvData, setCsvData] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [activeMobileFilterValue, setActiveMobileFilterValue] = useState('');
    const [fetchingCsv, setFetchingCsv] = useState(false);

    const fetchNewsletters = async(csv, clearSearchValue) => {
        try {
            if(csv) {
                setFetchingCsv(true);
                let title = 'ozmosys_newsletters_' + moment().tz(moment.tz.guess()).format('MMDDYYYY');
                const csvData = await getNewsletters(false, paginationCurrentPageNumber, 50, activeSortProperty, activeSortDirection, searchProperty, searchValue, true, title);
                setFetchingCsv(false);
            } else {
                !submittingSearch && setSubmittingSearch(true);
                let refreshCount = (initialLoad || paginationCurrentPageNumber === 1 || !newsletters.length);
                const newslettersData = await getNewsletters(refreshCount, paginationCurrentPageNumber, 50, activeSortProperty, activeSortDirection, searchProperty, clearSearchValue ? '' : searchValue);
                setNewsletters(newslettersData.result);
                if(refreshCount) setTotalPages(newslettersData.navigation.total_pages);
                setNavOptions(newslettersData.navigation);
                if(tempSearchValue !== searchValue) setTempSearchValue(searchValue);
                setFetchingData(false);
                setSubmittingSearch(false);
                initialLoad && setInitialLoad(false);
            }
        } catch(err) {
            setFetchingData(false);
            setSubmittingSearch(false);
            setFetchError(true);
            fetchingCsv && setFetchingCsv(false);
            handleError(err);
        }
    };

    const downloadFilteredCsv = async() => {
        await fetchNewsletters(true);
    };

    const downloadFullCsv = async() => {
        try {
            setFetchingCsv(true);
            let title = 'ozmosys_newsletters_' + moment().tz(moment.tz.guess()).format('MMDDYYYY') + '.csv';
            await getNewslettersWithoutParams(true, title);
            setFetchingCsv(false);
        } catch(err) {
            fetchingCsv && setFetchingCsv(false);
            handleError(err);
        }
    };

    useEffect(() => {
        fetchNewsletters();
    }, [newsletterFilters]);

    const goToManageContent = (id) => {
        history.push(`/account/newsletters/content/${id}`);
    };

    const goToAssign = (id) => {
        history.push(`/account/newsletters/assign/${id}`);
    };

    const toggleCreateNewsletter = async() => {
        if(!createNewsletterModalOpen) {
            let addStatus = await checkPossibleToAddNewsletter();
            if(!addStatus[0]) {
                addFlashMessage('danger', 'Your maximum newsletter limit has been reached.');
                return;
            }
            setCreateNewsletterModalOpen(!createNewsletterModalOpen);
        } else {
            setCreateNewsletterModalOpen(!createNewsletterModalOpen);
        }
    };

    const handleDeleteNewsletter = async(id, modalCallback) => {
        try {
            setDeleting(true);
            await deleteNewsletter(id);
            modalCallback && modalCallback();
            await fetchNewsletters(false, true);
            addFlashMessage('success', 'Newsletter successfully deleted');
            setDeleting(false);
        } catch(err) {
            setDeleting(false);
            handleError(err);
        }
    };

    const toggleEditNewsletter = (newsletter) => {
        setItemToEdit(newsletter);
        setEditNewsletterModalOpen(!editNewsletterModalOpen);
    };

    const toggleDeleteNewsletter = () => {
        if(deleteNewsletterModalOpen) {
            setItemToDelete(null);
        }
        setDeleteNewsletterModalOpen(!deleteNewsletterModalOpen);
    };

    useEffect(() => {
        if(itemToDelete) {
            toggleDeleteNewsletter();
        }
    }, [itemToDelete]);

    const toggleNewsletterPreview = () => {
        if(previewNewsletterModalOpen) {
            setItemToPreview(null);
        }
        setPreviewNewsletterModalOpen(!previewNewsletterModalOpen);
    };

    useEffect(() => {
        if(itemToPreview) {
            toggleNewsletterPreview();
        }
    }, [itemToPreview]);

    const toggleLastSentNewsletterPreview = () => {
        if(lastSentNewsletterModalOpen) {
            setLastSentItemToPreview(null);
        }
        setLastSentNewsletterModalOpen(!lastSentNewsletterModalOpen);
    };

    useEffect(() => {
        if(lastSentItemToPreview) {
            toggleLastSentNewsletterPreview();
        }
    }, [lastSentItemToPreview]);

    const handleSearchValueSubmit = () => {
        if(tempSearchValue.length > 2048) {
            addFlashMessage('danger', 'Your search exceeds the maximum number of allowed characters (2048)')
            return;
        }
        let filters = {...newsletterFilters};
        filters.searchValue = tempSearchValue;
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('newsletters', filters);
    };

    const handlePageClick = (data) => {
        let filters = {...newsletterFilters};
        filters.paginationCurrentPageNumber = data.selected + 1;
        setFiltersObject('newsletters', filters);
    };

    const handleClearSearchValue = async() => {
        let filters = {...newsletterFilters};
        setSubmittingSearch(true);
        filters.paginationCurrentPageNumber = 1;
        filters.searchValue = '';
        setFiltersObject('newsletters', filters);
        setTempSearchValue('');
    };

    // const buildCSVfile = arr => {
    //     const csvData = [
    //         ['Newsletter', 'CreatedAt', 'UpdatedAt', 'Enabled', 'Type'],
    //     ];
    //     arr.map(item => {
    //         csvData.push([
    //             SafeTrim(item.name),
    //             SafeTrim(item.createdAt),
    //             SafeTrim(item.contentUpdatedAt),
    //             String(Boolean(item.isEnabled)),
    //             (item.schedule.length || item.asAvailable) ? 'Automatic' : 'Curated',
    //         ]);
    //     });
    //     return csvData;
    // };

    // useEffect(() => {
    //     newsletters.length && setCsvData(buildCSVfile(newsletters));
    // }, [newsletters]);

    const handleSortableHeaderClick = (value) => {
        let filters = {...newsletterFilters};
        if(value === activeSortProperty) {
            activeSortDirection === 'asc' ?
                filters.activeSortDirection = 'desc'
                :
                filters.activeSortDirection = 'asc';
        } else {
            filters.activeSortProperty = value;
            filters.activeSortDirection = 'asc';
        }
        filters.paginationCurrentPageNumber = 1;
        setFiltersObject('newsletters', filters);
    };

    const handleMobileFilterSelect = (val) => {
        let splitValue = val.split('-');
        let filters = {...newsletterFilters};
        filters.activeSortProperty = splitValue[0];
        filters.activeSortDirection = splitValue[1];
        setFiltersObject('newsletters', filters);
    };

    const determineMobileFiltersValue = () => {
        let filterStr = newsletterFilters.activeSortProperty + '-' + newsletterFilters.activeSortDirection;
        setActiveMobileFilterValue(filterStr);
    };

    useEffect(() => {
        newsletterFilters && determineMobileFiltersValue();
    }, [newsletterFilters]);

    return (
        <div>
            {fetchingData ?
                <LoadingSpinner/>
                :
                ((
                        newsletters.length === 0 &&
                        searchValue === '' &&
                        !submittingSearch
                    )
                        ?
                        <EmptyList toggleCreateNewsletter={toggleCreateNewsletter}/>
                        :
                        <div>
                            {window.innerWidth > 1250 &&
                            <div className="list__utility-row list__utility-row--desktop">
                                <div className="list__search-container">
                                    <SearchInput
                                        value={tempSearchValue}
                                        onChange={setTempSearchValue}
                                        placeholder="Filter by newsletter name"
                                        onClear={handleClearSearchValue}
                                        onSubmit={handleSearchValueSubmit}
                                        submitting={submittingSearch}
                                        list
                                    />
                                </div>
                                <div className="d-flex">
                                    {/*<NewslettersListCsvButton csvData={csvData} desktopSize/>*/}
                                    <NewslettersListCsvButton
                                        downloadFullCsv={downloadFullCsv}
                                        downloadFilteredCsv={downloadFilteredCsv}
                                        downloading={fetchingCsv}
                                        desktopSize
                                    />
                                    <Button
                                        onClick={toggleCreateNewsletter}
                                        color="primary"
                                    >Create Newsletter</Button>
                                </div>
                            </div>
                            }
                            {1250 >= window.innerWidth && window.innerWidth > 624 &&
                            <div className="list__utility-row list__utility-row--tablet">
                                <div className="d-flex justify-content-between w-100">
                                    <div className="list__search-container">
                                        <SearchInput
                                            value={tempSearchValue}
                                            onChange={setTempSearchValue}
                                            placeholder="Filter by newsletter name"
                                            onClear={handleClearSearchValue}
                                            onSubmit={handleSearchValueSubmit}
                                            submitting={submittingSearch}
                                            list
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mt-2 w-100 justify-content-end">
                                    <div className="mr-2 d-flex">
                                        <Button
                                            onClick={toggleCreateNewsletter}
                                            color="primary"
                                            className="mb-0"
                                        >
                                            <i className="fa fa-plus"/>&nbsp;
                                            Create Newsletter
                                        </Button>
                                    </div>
                                    <div>
                                        <NewslettersListCsvButton
                                            downloadFullCsv={downloadFullCsv}
                                            downloadFilteredCsv={downloadFilteredCsv}
                                            downloading={fetchingCsv}
                                        />
                                    </div>
                                    <MobileFilterDropdown
                                        handleMobileFilterSelect={handleMobileFilterSelect}
                                        activeMobileFilterValue={activeMobileFilterValue}
                                    />
                                </div>
                            </div>
                            }
                            {624 >= window.innerWidth &&
                            <div className="list-utility-row list__utility-row--mobile">
                                <div className="d-flex mb-2">
                                    <NewslettersListCsvButton
                                        downloadFullCsv={downloadFullCsv}
                                        downloadFilteredCsv={downloadFilteredCsv}
                                        downloading={fetchingCsv}
                                        showText
                                    />
                                    <Button
                                        onClick={toggleCreateNewsletter}
                                        color="primary"
                                        className="ml-1"
                                        style={{flex: '0 0 50%'}}
                                    >
                                        <i className="fa fa-plus"/>&nbsp;
                                        Create Newsletter
                                    </Button>
                                </div>
                                <div className="d-flex mb-2">
                                    <MobileFilterDropdown
                                        handleMobileFilterSelect={handleMobileFilterSelect}
                                        activeMobileFilterValue={activeMobileFilterValue}
                                    />
                                </div>
                                <div className="mb-3">
                                    <SearchInput
                                        value={tempSearchValue}
                                        onChange={setTempSearchValue}
                                        placeholder="Filter by newsletter name"
                                        onClear={handleClearSearchValue}
                                        onSubmit={handleSearchValueSubmit}
                                        submitting={submittingSearch}
                                        list
                                    />
                                </div>
                            </div>
                            }
                            {newsletters.length < 1 ?
                                <div>No results found</div>
                                :
                                <div>
                                    <Table className="single-users-table responsive-table">
                                        <tbody>
                                        <tr className="responsive-table header-row">
                                            <th
                                                className="responsive-table__sortable-th responsive-table--long-th"
                                                onClick={() => handleSortableHeaderClick('newsletter.name', activeSortProperty, activeSortDirection)}
                                            >
                                                Name
                                                {handleCaret(activeSortProperty, activeSortDirection, 'newsletter.name')}
                                            </th>
                                            <th
                                                className="responsive-table__sortable-th"
                                                onClick={() => handleSortableHeaderClick('newsletter.type', activeSortProperty, activeSortDirection)}
                                            >
                                                Type
                                                {handleCaret(activeSortProperty, activeSortDirection, 'newsletter.type')}
                                            </th>
                                            <th
                                                className="responsive-table__sortable-th"
                                                onClick={() => handleSortableHeaderClick('newsletter.createdAt', activeSortProperty, activeSortDirection)}
                                            >
                                                Created At
                                                {handleCaret(activeSortProperty, activeSortDirection, 'newsletter.createdAt')}
                                            </th>
                                            <th
                                                className="responsive-table__sortable-th"
                                                onClick={() => handleSortableHeaderClick('newsletter.lastSentEmail', activeSortProperty, activeSortDirection)}
                                            >
                                                Last Sent Date
                                                {handleCaret(activeSortProperty, activeSortDirection, 'newsletter.lastSentEmail')}
                                            </th>
                                            <th>Active</th>
                                            <th>Content</th>
                                            <th>Assignment</th>
                                            <th className="responsive-table--long-th">Schedule</th>
                                            <th
                                                className="responsive-table__sortable-th responsive-table--number-th"
                                                onClick={() => handleSortableHeaderClick('newsletter.countClicks', activeSortProperty, activeSortDirection)}
                                            >
                                                {(window.innerWidth < 1685 && window.innerWidth > 1250) ?
                                                    'Clicks'
                                                    :
                                                    'Last 30 Days Clicks'
                                                }
                                                {handleCaret(activeSortProperty, activeSortDirection,'newsletter.countClicks')}
                                            </th>
                                            <th>Send Now</th>
                                            <th>Edit/Remove</th>
                                        </tr>
                                        {newsletters.map((newsletter) => {
                                            return (
                                                <NewsletterListItem
                                                    key={newsletter.id}
                                                    newsletter={newsletter}
                                                    toggleDeleteModal={toggleDeleteNewsletter}
                                                    toggleEditModal={toggleEditNewsletter}
                                                    goToManageContent={goToManageContent}
                                                    goToAssign={goToAssign}
                                                    addFlashMessage={addFlashMessage}
                                                    refreshNewsletters={fetchNewsletters}
                                                    toggleNewsletterPreview={toggleNewsletterPreview}
                                                    setItemToPreview={setItemToPreview}
                                                    setLastSentItemToPreview={setLastSentItemToPreview}
                                                    setItemToDelete={setItemToDelete}
                                                    handleError={handleError}
                                                />
                                            );
                                        })}
                                        </tbody>
                                    </Table>
                                    <PaginationWrapper
                                        totalPages={totalPages}
                                        handlePageClick={handlePageClick}
                                        forcePage={paginationCurrentPageNumber - 1}
                                    />
                                </div>
                            }
                        </div>
                )
            }

            {deleteNewsletterModalOpen &&
            <DeleteNewsletterModal
                newsletter={itemToDelete}
                isOpen={deleteNewsletterModalOpen}
                toggle={toggleDeleteNewsletter}
                onSubmit={handleDeleteNewsletter}
                deleting={deleting}
            />
            }

            {editNewsletterModalOpen &&
            <EditNewsletterModal
                isOpen={editNewsletterModalOpen}
                toggle={toggleEditNewsletter}
                refreshNewsletters={fetchNewsletters}
                newsletter={itemToEdit}
            />
            }

            {createNewsletterModalOpen &&
            <CreateNewsletterModal
                isOpen={createNewsletterModalOpen}
                toggle={toggleCreateNewsletter}
                refreshNewsletters={fetchNewsletters}
            />
            }

            {previewNewsletterModalOpen &&
            <PreviewNewsletterModal
                isOpen={previewNewsletterModalOpen}
                toggle={toggleNewsletterPreview}
                newsletter={itemToPreview}
                addFlashMessage={addFlashMessage}
            />
            }

            {lastSentNewsletterModalOpen &&
            <PreviewNewsletterModal
                isOpen={lastSentNewsletterModalOpen}
                toggle={toggleLastSentNewsletterPreview}
                newsletter={lastSentItemToPreview}
                addFlashMessage={addFlashMessage}
                lastSentId={lastSentItemToPreview.lastSentEmail.id}
            />
            }
        </div>
    );
};

export default NewslettersList;
