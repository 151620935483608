import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Collapse, Button, CardBody, Card, CardHeader, Input, UncontrolledTooltip } from 'reactstrap';
import Article from './AssignedArticle';
import { Droppable, Draggable } from 'react-beautiful-dnd';

const UnassignedSection = (props) => {
    const { section, index, removeAllArticlesFromSection, assignArticleToSection, refreshNewsletter, handleRemoveAssignedArticle, includeAbstracts } =
        props;
    const [articleListOpen, setArticleListOpen] = useState(false);
    const [commentFormOpen, setCommentFormOpen] = useState(false);
    const [commentValue, setCommentValue] = useState('');
    const [titleValue, setTitleValue] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        setCommentValue(section.comment);
        setTitleValue(section.name);
    }, []);

    useEffect(() => {
        if (!commentFormOpen) {
            commentValue !== section.comment && setCommentValue(section.comment);
        }
    }, [commentFormOpen]);

    const toggleArticleListOpen = () => {
        setArticleListOpen(!articleListOpen);
    };

    return (
        <div className="mt-2">
            <Droppable droppableId={String(section.id)} type="article">
                {(provided, snapshot) => (
                    <div
                        className={'newsletter-section ' + (snapshot.isDraggingOver ? 'section-is-dragging-over' : '')}
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        <div className="section-collapse d-flex flex-column">
                            <CardHeader
                                className={
                                    'section-collapse-toggle newsletter-section__header ' +
                                    (snapshot.isDragging ? 'newsletter-section__header--dragging' : '')
                                }
                                color="primary"
                                onClick={toggleArticleListOpen}>
                                <div className="newsletter-section__block-1">
                                    <i className={'fa fa-angle-right ' + (articleListOpen ? 'rotate-90-down' : '')} />
                                    <div className="newsletter-section__title ml-2">
                                        <span>{section.name}</span>
                                    </div>
                                    &nbsp;
                                    <div className="newsletter-section__item-cnt newsletter-section__item-cnt--unassigned">
                                        {section.newsletterSectionNews.length === 1
                                            ? section.newsletterSectionNews.length + ' Article'
                                            : section.newsletterSectionNews.length + ' Articles'}
                                        <span className={section.newsletterSectionNews.length > 0 ? 'error-text' : ''}>
                                            {' '}
                                            (Articles in this section will not be delivered)
                                        </span>
                                    </div>
                                </div>
                                <div className="newsletter-section__block-2">
                                    <Button
                                        color="secondary"
                                        id={'clear-articles-' + section.id}
                                        className="newsletter-section__header-btn newsletter-section__header-btn--clear"
                                        onClick={(e) => removeAllArticlesFromSection(e, section.id)}
                                        disabled={section.newsletterSectionNews.length < 1}>
                                        Clear
                                    </Button>
                                    <UncontrolledTooltip placement="bottom" delay={{ show: 200, hide: 0 }} target={'clear-articles-' + section.id}>
                                        Remove all articles from this section
                                    </UncontrolledTooltip>
                                </div>
                            </CardHeader>
                            <Collapse isOpen={articleListOpen}>
                                <CardBody className="section-collapse-body p-0">
                                    <div className="section-body">
                                        <div className="section-list">
                                            {section.newsletterSectionNews.length === 0 && (
                                                <div className="empty-section-item">No Articles have been added to this section yet</div>
                                            )}
                                            {section.newsletterSectionNews.length > 0 &&
                                                articleListOpen &&
                                                section.newsletterSectionNews.map((article, i) => (
                                                    <Article
                                                        key={article.news.id}
                                                        article={article}
                                                        index={i}
                                                        section={section}
                                                        refreshNewsletter={refreshNewsletter}
                                                        assignArticleToSection={assignArticleToSection}
                                                        sectionId={section.id}
                                                        includeAbstracts={includeAbstracts}
                                                        handleRemoveAssignedArticle={handleRemoveAssignedArticle}
                                                        isUnassignedSection
                                                    />
                                                ))}
                                            {provided.placeholder}
                                        </div>
                                    </div>
                                </CardBody>
                            </Collapse>
                        </div>
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default UnassignedSection;
