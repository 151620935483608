import React, {useState, useContext} from 'react';

import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    FormGroup,
    Label,
} from 'reactstrap';
import Dropzone from 'react-dropzone';

import {addArticleToCuratedNewsletter} from 'utils/api/newslettersAPI';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import SpinnerButton from 'components/SpinnerButton';
import CharacterCount from 'components/CharacterCount';
import InputWithError from 'components/InputWithError';
import ModalBodyWithClose from 'components/ModalBodyWithClose';
import './style.scss';
import {timezones} from 'utils/timezones';
import SelectWithError from 'components/InputWithError/SelectWithError';

const AddCustomArticle = (props) => {
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError} = useContext(AuthContext);
    const {isOpen, toggle, refreshNewsletter, sections, newsletterId} = props;
    const [submitting, setSubmitting] = useState(false);
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [uploadType, setUploadType] = useState('url');
    const [abstract, setAbstract] = useState('');
    const [comment, setComment] = useState('');
    const [selectedSectionId, setSelectedSectionId] = useState(null);
    const [uploadedPdfFile, setUploadedPdfFile] = useState(null);
    const [errors, setErrors] = useState(null);

    const handleDropAccept = async(file) => {
        setUploadedPdfFile(file[0]);
    };

    const handleDropReject = () => {
        addFlashMessage('danger', 'File must be in .PDF format and cannot exceed 20MB');
    };

    const handleClearFile = () => {
        setUploadedPdfFile(null);
    };

    const handleSubmit = async() => {
        try {
            if(!selectedSectionId) {
                let testObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        section: [
                            {
                                message: 'A section must be selected.',
                                code: 'IS_BLANK_ERROR',
                                payload: null,
                            },
                        ],
                    },
                };
                handleError(testObj, setErrors);
                return;
            }
            setSubmitting(true);
            setErrors(null);
            const params = {
                title,
                url: uploadType === 'url' ? url : null,
                abstract,
                comment,
                file: uploadType === 'pdf' ? uploadedPdfFile : null,
            };
            await addArticleToCuratedNewsletter(newsletterId, selectedSectionId, params);
            refreshNewsletter();
            addFlashMessage('success', 'Article successfully added');
            toggle();
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    const handleDisabled = () => {
        return abstract.length > 300 || comment.length > 500;
    };

    return (
        <Modal className="add-custom-article modal-lg" isOpen={isOpen}>
            <ModalBodyWithClose toggle={toggle} className="pb-0">
                <h2>Add Article</h2>
                <div className="mb-3">
                    <InputWithError
                        errorObj={errors}
                        prependIcon={<i className="fa fa-newspaper-o"/>}
                        placeholder="Title"
                        name="title"
                        value={title}
                        type="text"
                        onChange={setTitle}
                        marginBottomClass="mb-3"
                    />
                </div>
                <div className="mb-3">
                    <div className="add-custom-article__upload-container">
                        <FormGroup className="radio-container">
                            <FormGroup check className="url">
                                <Label check>
                                    <Input type="radio"
                                           name="url-type"
                                           onChange={() => setUploadType('url')}
                                           checked={uploadType === 'url'}
                                    />{' '}
                                    Enter URL
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input type="radio"
                                           name="url-type"
                                           onChange={() => setUploadType('pdf')}
                                           checked={uploadType === 'pdf'}
                                    />{' '}
                                    Upload PDF
                                </Label>
                            </FormGroup>
                        </FormGroup>
                        {uploadType === 'url' ?
                            <div className="add-custom-article__url-container">
                                <InputWithError
                                    errorObj={errors}
                                    prependIcon="@"
                                    placeholder="URL"
                                    name="url"
                                    value={url}
                                    type="text"
                                    onChange={setUrl}
                                    marginBottomClass="mb-0"
                                />
                            </div>
                            :
                            <div className="add-custom-article__pdf-container">
                                <div className="d-flex align-items-center">
                                    <Dropzone
                                        className="add-custom-article__dropzone"
                                        onDropAccepted={handleDropAccept}
                                        onDropRejected={handleDropReject}
                                        maxSize={20000000}
                                        multiple={false}
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <Button>
                                                        <i className="fa fa-cloud-upload"
                                                           style={{marginRight: '.4rem'}}/>
                                                        Upload PDF
                                                    </Button>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    {/*<Dropzone*/}
                                    {/*    className="add-custom-article__dropzone"*/}
                                    {/*    accept=".pdf"*/}
                                    {/*    onDropAccepted={handleDropAccept}*/}
                                    {/*    onDropRejected={handleDropReject}*/}
                                    {/*    maxSize={20000000}*/}
                                    {/*    multiple={false}*/}
                                    {/*>*/}
                                    {/*    {({getRootProps, getInputProps}) => (*/}
                                    {/*        <div style={{height: 200, width: 200, border: '2px' +*/}
                                    {/*                ' solid red'}}>*/}
                                    {/*            <Button*/}
                                    {/*                {...getInputProps()}*/}
                                    {/*            >*/}
                                    {/*                <i className="fa fa-cloud-upload"*/}
                                    {/*                   style={{marginRight: '.4rem'}}/>*/}
                                    {/*                Upload PDF*/}
                                    {/*            </Button>*/}
                                    {/*        </div>*/}

                                    {/*    )}*/}
                                    {/*</Dropzone>*/}
                                    {uploadedPdfFile &&
                                    <div className="add-custom-article__uploaded-pdf">
                                        &nbsp;{uploadedPdfFile.name}&nbsp;
                                        <span onClick={handleClearFile}>×</span>
                                    </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div>
                    <FormGroup>
                        <Input
                            type="textarea"
                            name="abstract"
                            value={abstract}
                            placeholder="Abstract"
                            autoComplete="off"
                            onChange={(e) => setAbstract(e.target.value)}
                        />
                        <CharacterCount value={abstract} limit={300}/>
                    </FormGroup>

                    <FormGroup>
                        <Input
                            type="textarea"
                            name="comment"
                            value={comment}
                            placeholder="Comment"
                            autoComplete="off"
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <CharacterCount value={comment} limit={500}/>
                    </FormGroup>

                    <SelectWithError
                        name="section"
                        value={selectedSectionId ? selectedSectionId : 'Select a section'}
                        type="select"
                        onChange={setSelectedSectionId}
                        errorObj={errors}
                        noDefault
                    >
                        <option disabled>
                            Select a section
                        </option>
                        {sections.map(section => {
                            return (
                                <option key={section.id} value={section.id}>
                                    {section.name}
                                </option>
                            );
                        })}
                    </SelectWithError>
                </div>
            </ModalBodyWithClose>
            <ModalFooter>
                <Button type="button" onClick={toggle}>
                    Cancel
                </Button>
                <SpinnerButton
                    color="primary"
                    style={{minWidth: '71.53px'}}
                    onClick={handleSubmit}
                    title="Create"
                    disabled={handleDisabled()}
                    submitting={submitting}
                />
            </ModalFooter>
        </Modal>
    );
};

export default AddCustomArticle;
