import React from 'react';

import {convertDateString} from 'Helpers/convertDateString';
import {truncateLongWords} from 'Helpers/truncateLongWords';
import {truncateWithoutWordBreak} from 'Helpers/truncateWithoutWordBreak';

const Article = (props) => {
    const {article, selectedArticle, handleArticleSelect} = props;
    const {title, abstract, resource, created_at, id} = article;

    return (
        <div
            className={'reader-article ' +
            ((selectedArticle && selectedArticle.id === id) ? 'reader-article--active' : '')}
            id={id}
            onClick={() => handleArticleSelect(article)}
        >
            <div className="reader-article__info-block">
                <div className="reader-article__publisher">{resource}</div>
                <div className="reader-article__date">{convertDateString(created_at)}</div>
            </div>
            {title.length > 100 ?
                <div className="reader-article__title" dangerouslySetInnerHTML={{__html: truncateWithoutWordBreak(truncateLongWords(title, 50), 100)}}/>
                : <div className="reader-article__title" dangerouslySetInnerHTML={{__html: title}}/>
            }
            {abstract && abstract.length > 36 ?
                <div className="reader-article__content" dangerouslySetInnerHTML={{__html: truncateWithoutWordBreak(truncateLongWords(abstract, 50), 120)}}/>
                : <div className="reader-article-content" dangerouslySetInnerHTML={{__html: abstract}}/>
            }
        </div>
    );
};

export default Article;
