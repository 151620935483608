import React, {useState} from 'react';

import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';

import LoadingSpinner from 'components/LoadingSpinner';
import {deleteNewsletterSection} from 'utils/api/newslettersAPI';
import SpinnerButton from 'components/SpinnerButton';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const ConfirmDelete = (props) => {
    const {section, newsletterId, setActiveView, toggle, refreshNewsletter, isOpen, handleError} = props;
    const [deleting, setDeleting] = useState(false);

    const handleSubmit = async() => {
        try {
            setDeleting(true);
            await deleteNewsletterSection(newsletterId, section.id);
            await refreshNewsletter();
            setActiveView('sectionsList');
            toggle();
            setDeleting(false);
        } catch(err) {
            setDeleting(false);
            handleError(err);
        }
    };

    if(!section) return <div/>;
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            {!section ?
                <div/>
                :
                <>
                    <ModalBodyWithClose toggle={toggle}>
                        Are you sure you want to delete {section.name}?
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <SpinnerButton
                            type="modal"
                            color="danger"
                            title="Delete"
                            submitting={deleting}
                            onClick={handleSubmit}
                            style={{minWidth: '67.78px'}}
                        />
                    </ModalFooter>
                </>
            }
        </Modal>
    );
};

export default ConfirmDelete;
