import React, {useState, useEffect, useContext} from 'react';
import {Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, Input, FormGroup, Label} from 'reactstrap';
import Logo from 'images/logo.png';
import {useHistory, useLocation} from 'react-router-dom';
import SpinnerButton from 'components/SpinnerButton';
import {checkRegistrationToken, resetPassword} from 'utils/api/authAPI';
import queryString from 'query-string';
import {timezones} from 'utils/timezones';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import {isEqual} from 'lodash';

const ResetPassword = ({isFromReader}) => {
    let history = useHistory();
    const {addFlashMessage} = useContext(FlashMessageContext)
    const {signIn, state, logout, authorizeManagePublicReader} = useContext(AuthContext)

    const [token, setToken] = useState(null);
    const [tokenChecked, setTokenChecked] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newToken, setNewToken] = useState(null);

    let queryParams = useLocation();

    const redirectToInvalidLink = () => {
        history.push('/invite-expired');
    };

    useEffect(() => {
        checkToken();
        if(state.token) {
            logout();
        }
    }, []);

    useEffect(() => {
        if(newToken && isEqual(state.tokenData, newToken)) {
            if(isFromReader) {
                history.push('/company/reader');
            } else {
                history.push('/');
            }
        }
    }, [state.tokenData, newToken]);

    const checkToken = async() => {
        try {
            const parsedQuery = queryString.parse(queryParams.search);
            await checkRegistrationToken(parsedQuery.token);
            setToken(parsedQuery.token);
            setTokenChecked(true);
        } catch(err) {
            redirectToInvalidLink();
            console.log(err);
        }
    };

    const onSubmit = async() => {
        try {
            setSubmitting(true);
            const params = {
                token,
                plainPassword: {
                    first: password,
                    second: confirmPassword
                },
            };
            const res = await resetPassword(params);
            addFlashMessage('success', 'Password successfully changed');
            // if(res.content?.area && res.content?.area[0] === 'reader') {
            if(isFromReader || res.content?.area === 'reader') {
                authorizeManagePublicReader(res.content?.token)
                history.push('/company/reader')
                return;
            } else {
                signIn(res.content?.token)
            }
            // signIn(res.content.token);
            setNewToken(res.content?.token);
            setSubmitting(false);
        } catch(err) {
            console.log(err);
            if(password !== confirmPassword) {
                addFlashMessage('danger', 'Passwords do not match');
            } else if(password.length < 6 || confirmPassword.length < 6) {
                addFlashMessage('danger', 'Password must be at least 6 characters');
            } else {
                addFlashMessage('danger', 'Unable to complete your request at this time');
            }
            setSubmitting(false);
        }
    };

    if(!tokenChecked) {
        return null;
    }
    return (
        <div className="auth-block">
            <div className="auth-block__form">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{marginBottom: '.5rem'}}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo}
                                         alt="Ozmosys"
                                         className="auth-block__image img-fluid"
                                    />
                                </a>
                            </div>
                            <div>
                                <div className="text-center mb-4">
                                    <h2>Reset Password</h2>
                                </div>

                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fa fa-lock"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Password (Min 6 Characters)"
                                        type="password"
                                        name="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fa fa-lock"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Confirm Password"
                                        type="password"
                                        name="confirmPassword"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        value={confirmPassword}
                                    />
                                </InputGroup>

                                <Row>
                                    <Col xs="12" className="text-right">
                                        <SpinnerButton
                                            type="submit"
                                            color="primary"
                                            className="px-4"
                                            block
                                            onClick={onSubmit}
                                            submitting={submitting}
                                            title={'Sign In'}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
