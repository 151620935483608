import React, {useState, useEffect, useContext} from 'react';

import {Row, Col} from 'reactstrap';
import isEmpty from 'lodash';

import TabBar from 'components/TabBar';
import General from './General';
import AlertsSchedule from 'containers/Settings/AlertsSchedule';
import Appearance from 'containers/Settings/Appearance';
import SuspendAccount from 'containers/Settings/SuspendAccount';
import EmailSettings from 'containers/Settings/EmailSettings';
import {AuthContext} from 'contexts/AuthContext';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import ReaderSettings from 'containers/Settings/ReaderSettings';
import JWT from 'containers/Settings/JWT';
import API from 'containers/Settings/API';
import Courtlink from 'containers/Settings/Courtlink';
import './style.scss';


const Settings = (props) => {
    const {state, fetchProfile, handleError} = useContext(AuthContext);
    const {addFlashMessage} = useContext(FlashMessageContext);

    const [activeView, setActiveView] = useState('general');

    const handleActiveView = () => {
        switch(activeView) {
            case 'alerts':
                return (
                    <General
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        handleError={handleError}
                    />
                );
            case 'emailSettings':
                return (
                    <EmailSettings
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );
            case 'alertsSchedule':
                return (
                    <AlertsSchedule
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );
            case 'appearance':
                return (
                    <Appearance
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );
            case 'suspendAccount':
                return (
                    <SuspendAccount
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );
            case 'reader':
                return (
                    <ReaderSettings
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );
            case 'courtlink':
                return (
                    <Courtlink
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );
            case 'jwt':
                return (
                    <JWT
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );
            case 'api':
                return (
                    <API
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        addFlashMessage={addFlashMessage}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );
            default:
                return (
                    <General
                        profile={state.profile}
                        isSingleUser={state.isSingleUser}
                        refreshProfile={fetchProfile}
                        handleError={handleError}
                    />
                );
        }
    };

    if(!isEmpty(state.profile)) return null;
    return (
        <div className="animated fadeIn view">
            <Row className="mt-3">
                <Col xs="12" className="col-no-padding-mobile">
                    <div className="settings mb-5">
                        <h1>Settings</h1>
                        <TabBar
                            activeItem={activeView}
                            setActive={setActiveView}
                            useMobileSelect
                            items={
                                [
                                    {data: 'general', name: 'General'},
                                    {data: 'emailSettings', name: 'Email Settings'},
                                    {data: 'reader', name: 'Reader'},
                                    {data: 'alertsSchedule', name: 'Schedule'},
                                    {data: 'appearance', name: 'Appearance'},
                                    {data: 'suspendAccount', name: 'Suspend Account'},
                                    ...((state.profile.organization && state.profile.organization.apiAccess) ? [{data: 'api', name: 'API'}] : []),
                                    ...((state.profile.organization && state.profile.organization.isJwtAvailable) ? [{data: 'jwt', name: 'JWT'}] : []),
                                    ...((state.profile.organization && state.profile.organization.isCourtlinkAvailable) ? [{data: 'courtlink', name: 'CourtLink'}] : []),
                                ]
                            }
                        />
                        {handleActiveView()}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Settings;
