import React from 'react';

import { FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import 'react-day-picker/lib/style.css';
import { timezones } from 'utils/timezones';
import moment from 'moment-timezone';
import InputWithError from 'components/InputWithError';
import { CheckboxInput } from 'components/common/Input';

const Curated = (props) => {
    const {
        curatedType,
        setCuratedType,
        specificDate,
        errors,
        handleTimezoneSelect,
        selectedTimezone,
        setSelectedTimePeriod,
        setSpecificDate,
        selectedTimePeriod,
        handleHourSelect,
        selectedTimeHour,
        instantEmail,
        setInstantEmail,
    } = props;

    const handleDayPickerChange = (day) => {
        setSpecificDate(moment(day).format('YYYY-MM-DD'));
    };

    return (
        <div className="newsletter-form__curated-form mt-4 mb-5">
            <div>
                <Label className="mb-0">Select delivery type</Label>
                <div className="mt-3">
                    <CheckboxInput
                        name="scheduled"
                        checked={curatedType === 'scheduled'}
                        onChange={() => setCuratedType('scheduled')}
                        text="Scheduled"
                    />
                    <CheckboxInput
                        name="instant"
                        checked={curatedType === 'instant'}
                        onChange={() => setCuratedType('instant')}
                        text="Instant Delivery"
                    />
                </div>
            </div>
            {curatedType === 'scheduled' && (
                <div className="newsletter-form__option newsletter-form__option--scheduled mt-4">
                    <div className="day-picker newsletter-form__curated-day-picker">
                        <Label className="mr-2 mb-0">Select specific date: </Label>
                        <div>
                            <DayPickerInput
                                value={new Date(specificDate)}
                                dayPickerProps={{
                                    disabledDays: {
                                        before: new Date(),
                                    },
                                }}
                                autoComplete={false}
                                inputProps={{ ref: null }}
                                onDayChange={handleDayPickerChange}
                                component={(props) => (
                                    <InputGroup className="newsletter-form__day-picker mb-0">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-calendar" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input {...props} placeholder="MM/DD/YYYY" value={specificDate} />
                                    </InputGroup>
                                )}
                            />
                        </div>
                    </div>
                    <div className="mt-3 mb-2">
                        <div>Select specific time:</div>
                        <div className="newsletter-form__time-inputs mt-3 mb-3">
                            <FormGroup>
                                <Input
                                    onChange={(e) => handleHourSelect(e.target.value)}
                                    value={selectedTimeHour.first}
                                    type="select"
                                    name="selectedHour">
                                    {[...Array(12).keys()].map((hour) => (
                                        <option key={hour} value={hour + 1 + ':00'}>
                                            {hour + 1}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    onChange={(e) => setSelectedTimePeriod({ first: e.target.value, second: setSelectedTimePeriod.second })}
                                    value={selectedTimePeriod.first.toUpperCase()}
                                    type="select"
                                    name="selectedFirstPeriod">
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </Input>
                            </FormGroup>

                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-globe" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    name="timezone"
                                    value={selectedTimezone}
                                    type="select"
                                    options={timezones}
                                    onChange={(e) => handleTimezoneSelect(e.target.value)}>
                                    <option value={''} disabled>
                                        TimeZone
                                    </option>
                                    {timezones.map((timezone) => {
                                        return (
                                            <option key={timezone.key} value={timezone.key}>
                                                {timezone.name}
                                            </option>
                                        );
                                    })}
                                </Input>
                            </InputGroup>
                        </div>
                    </div>
                    {errors && errors.errors.hasOwnProperty('dateTime') && (
                        <div className="error-text" style={{ marginTop: '-10px' }}>
                            Date cannot be in the past.
                        </div>
                    )}
                </div>
            )}
            {curatedType === 'instant' && (
                <div className={'newsletter-form__option newsletter-form__option--instant mt-4'}>
                    <Label>Enter target email</Label>
                    <div className="mt-2" style={{ maxWidth: 351 }}>
                        <InputWithError
                            placeholder="Target email"
                            name="instantDeliveryEmail"
                            value={instantEmail}
                            type="email"
                            onChange={setInstantEmail}
                            errorObj={errors}
                            prependIcon={<i className="fa fa-envelope" />}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Curated;
