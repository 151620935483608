import React, {useState, useEffect} from 'react';

import {InputGroup, Input} from 'reactstrap';
import SearchInput from 'components/SearchInput';
import {filterByValue} from 'Helpers/filterByValue';
import {sortObjects} from 'Helpers/sortObjects';
import RSSLogo from 'images/rss-icon.png';
import BlueLogo from 'images/ozmosys_blue_cropped.png';
import OpenEnvelope from 'images/open-envelope.svg';

const AddAlerts = (props) => {
    const {newsletterData, section} = props;
    const [unassignedSearchValue, setUnassignedSearchValue] = useState('');
    const [assignedSearchValue, setAssignedSearchValue] = useState('');
    const [filteredUnassignedList, setFilteredUnassignedList] = useState([]);
    const [filteredAssignedList, setFilteredAssignedList] = useState([]);

    useEffect(() => {
        applyFilters('unassigned', newsletterData.unassignedAlerts);
    }, [newsletterData, section]);

    useEffect(() => {
        applyFilters('assigned', section.alerts);
    }, [section]);

    const handleAlertTypeIcon = (sourceType) => {
        switch(sourceType) {
            case 'rss':
                return <img height={16} src={RSSLogo} alt="rss-logo"/>;
            case 'google':
                return <i style={{fontSize: '1.1rem'}} className="google-icon fa fa-google "/>;
            case 'internal':
                return <img height={16} src={BlueLogo} alt="internal-logo"/>;
            case 'email_publication':
                return <img height={16} src={OpenEnvelope} alt="email-logo"/>;
            default:
                return null;
        }
    };

    const applyFilters = (list, arr) => {
        if(list === 'unassigned') {
            let newArr = filterByValue(arr, 'name', unassignedSearchValue);

            let sortedArr = sortObjects(newArr, 'name', 'asc');
            setFilteredUnassignedList(sortedArr);
        } else {
            let newArr = filterByValue(arr, 'name', assignedSearchValue);
            let sortedArr = sortObjects(newArr, 'name', 'asc');
            setFilteredAssignedList(sortedArr);
        }
    }

    useEffect(() => {
        applyFilters('unassigned', newsletterData.unassignedAlerts);
    }, [unassignedSearchValue]);

    useEffect(() => {
        applyFilters('assigned', section.alerts);
    }, [assignedSearchValue]);

    return (
        <div className="mobile-newsletter-add-alerts">
            <h5>{section.name}</h5>
            <h6 className="mt-3">Alerts List</h6>
            <div>
                <div className="mb-2">
                    <SearchInput
                        onClear={() => setUnassignedSearchValue('')}
                        onChange={setUnassignedSearchValue}
                        placeholder={'Search by alert name'}
                        value={unassignedSearchValue}
                    />
                </div>
            </div>
            <div className="mobile-newsletter-add-alerts__list mb-4">
                {newsletterData.unassignedAlerts.length > 0 ?
                    filteredUnassignedList.length > 0 ?
                        filteredUnassignedList.map(alert => {
                            return (
                                <div
                                    key={alert.id}
                                    className="mobile-newsletter-add-alerts__list-item">
                                    <div className="d-flex align-items-center">
                                        <div className="mobile-newsletter-add-alerts__icon">{handleAlertTypeIcon(alert.type)}</div>
                                        {alert.name}
                                    </div>
                                    <div className="mobile-newsletter-add-alerts__add-btn"
                                         onClick={() => props.handleAddAlert(section.id, alert.id)}><i
                                        className="fa fa-plus"/></div>
                                </div>
                            );
                        })
                        :
                        <div style={{padding: '.5rem'}}>No results found</div>
                    :
                    <div style={{padding: '.5rem'}}>No alerts found</div>
                }
            </div>
            <h6 className="mt-3">Assigned Alerts</h6>
            <div>
                <div className="mb-2">
                    <SearchInput
                        onClear={() => setAssignedSearchValue('')}
                        onChange={setAssignedSearchValue}
                        placeholder={'Search by alert name'}
                        value={assignedSearchValue}
                    />
                </div>
            </div>
            <div className="mobile-newsletter-add-alerts__list mb-4">
                {section.alerts.length > 0 ?
                    filteredAssignedList.length > 0 ?
                        filteredAssignedList.map(alert => {
                            return (
                                <div
                                    key={alert.id}
                                    className="mobile-newsletter-add-alerts__list-item mobile-newsletter-add-alerts__list-item--assigned"
                                >
                                    {alert.name}
                                    <div
                                        className="mobile-newsletter-add-alerts__add-btn"
                                        onClick={() => props.handleRemoveAlert(section.id, alert.id)}
                                    >
                                        <span>×</span>
                                    </div>
                                </div>
                            );
                        })
                        :
                        <div style={{padding: '.5rem'}}>No results found</div>
                    :
                    <div style={{padding: '.5rem'}}>No assigned alerts</div>
                }
            </div>

        </div>
    );
};

export default AddAlerts;
