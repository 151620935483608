import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

const API = () => {
    return (
        <div>
            <h4 className="settings__page-header">API</h4>
            <div className="mt-3">
                <div>URL:</div>
                <div className="mb-3">
                    {/*<CopyToClipboard text={'https://api.ozmosys.com/api/doc'}>*/}
                    {/*    <p style={{cursor: 'copy'}}>https://api.ozmosys.com/api/doc</p>*/}
                        <a href="https://api.ozmosys.com/api/doc" target="_blank" rel="noopener noreferrer">https://api.ozmosys.com/api/doc</a>
                    {/*</CopyToClipboard>*/}
                </div>
            </div>
        </div>
    );
};

export default API;
