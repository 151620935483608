import React, {useState} from 'react';

import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import {deleteUserLabel} from 'utils/api/usersAPI';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const DeleteLabel = (props) => {
    const {isOpen, toggle, item, refreshLabels, addFlashMessage} = props;
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async() => {
        try {
            setSubmitting(true);
            await deleteUserLabel(item.id);
            refreshLabels();
            addFlashMessage('success', 'User label successfully deleted');
            setSubmitting(false);
            toggle();
        } catch(err) {
            setSubmitting(false);
            addFlashMessage('danger', 'Unable to delete user label');
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-label-modal">
            {!item ?
                <div/>
                :
                <div>
                    <ModalBodyWithClose toggle={toggle}>
                        <span>Are you sure you want to delete {item.name}?</span>
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <SpinnerButton
                            type="modal"
                            color="danger"
                            onClick={handleSubmit}
                            submitting={submitting}
                            title="Delete"
                        />
                    </ModalFooter>
                </div>
            }
        </Modal>
    );
};

export default DeleteLabel;
