import React from 'react';
import { Input } from 'reactstrap';
import './style.scss';
import TabItem from 'components/TabBar/TabItem';

const TabBar = (props) => {
    const { items, activeItem, setActive, errorObj, useMobileSelect, shrink } = props;

    const handleSelect = (itemData) => {
        setActive(itemData);
    };

    const getItems = (isRight) => {
        return items
            .filter((item) => isRight === !!item.right)
            .map((item) => (
                <TabItem
                    activeItem={activeItem}
                    key={item.data}
                    item={item}
                    handleSelect={handleSelect}
                    errorObj={errorObj}
                    shrink={shrink}
                    smallText={items && items.length > 6}
                />
            ));
    };

    return (
        <>
            {useMobileSelect && window.innerWidth < 850 ? (
                <div style={{ maxWidth: '391px' }}>
                    <Input onChange={(e) => handleSelect(e.target.value)} type="select" name="menu">
                        {items.map((item, i) => (
                            <option key={i} value={item.data}>
                                {item.name}
                            </option>
                        ))}
                    </Input>
                </div>
            ) : (
                <div className={'tab-bar ' + (shrink ? 'tab-bar--shrink' : '')}>
                    <div className="sub-tab-bar">{getItems(false)}</div>
                    <div className="sub-tab-bar">{getItems(true)}</div>
                </div>
            )}
        </>
    );
};

export default TabBar;
