import React, {useEffect, useRef, useState} from 'react';

import {FormGroup, Input, Button, UncontrolledTooltip} from 'reactstrap';
import {AutoSizer, List, CellMeasurer, CellMeasurerCache} from 'react-virtualized';

import SearchInput from 'components/SearchInput';
import {filterByValue} from 'Helpers/filterByValue';
import {handleCaret} from 'Helpers/handleSortCaret';
import Article from './Article';
import LoadingSpinner from 'components/LoadingSpinner';
import AddCustomArticleModal from '../../../../Modals/AddCustomArticle';

const cache = new CellMeasurerCache({
    fixedWidth: true,
});

const Articles = (props) => {
    const {
        articles, newsletterData, retrievalDate, hideArticle, includeAbstracts,
        setRetrievalDate, fetchingArticles, assignUnassignedArticleToSection, refreshNewsletter,
        sortByDate, setSortByDate,
    } = props;
    const [searchValue, setSearchValue] = useState('');
    const [visibleArticles, setVisibleArticles] = useState([]);
    const [activeFilterSection, setActiveFilterSection] = useState('all');
    const [customArticleModalOpen, setCustomArticleModalOpen] = useState(false);
    const [tempRetrievalDate, setTempRetrievalDate] = useState(null);
    const listRef = useRef(null);

    useEffect(() => {
        retrievalDate !== tempRetrievalDate && setTempRetrievalDate(retrievalDate);
    }, [retrievalDate]);

    // useEffect(() => {
    //     cache.clearAll();
    //     listRef && listRef.current && listRef.current.recomputeRowHeights();
    // }, [searchValue]);

    const applyFilters = (arr, returnValue) => {
        let newArr = filterByValue(arr, ['title', 'abstract'], searchValue);

        if(activeFilterSection === 'all') {
            setVisibleArticles(newArr);
            cache.clearAll();
            listRef && listRef.current && listRef.current.recomputeRowHeights();
            if(returnValue) {
                return newArr
            } else {
                return;
            }
        }
        let arrFilteredBySection = newArr.filter(article => {
            return article.sectionid === Number(activeFilterSection);
        });
        if(returnValue) {
            return arrFilteredBySection;
        } else {
            setVisibleArticles(arrFilteredBySection);
            cache.clearAll();
            listRef && listRef.current && listRef.current.recomputeRowHeights();
        }
    };

    useEffect(() => {
        applyFilters(articles);
    }, [articles, activeFilterSection]);

    useEffect(() => {
        cache.clearAll();
        listRef && listRef.current && listRef.current.recomputeRowHeights();
    }, [includeAbstracts]);

    useEffect(() => {
        let arrFilteredBySection = applyFilters(articles, true);
        if(searchValue.length) {
            let filteredList = filterByValue(arrFilteredBySection, ['title', 'abstract'], searchValue);
            setVisibleArticles(filteredList);
        } else {
            setVisibleArticles(arrFilteredBySection);
        }
        cache.clearAll();
        listRef && listRef.current && listRef.current.recomputeRowHeights();
    }, [searchValue]);

    const toggleCustomArticleModal = () => setCustomArticleModalOpen(!customArticleModalOpen);

    const rowRenderer = ({index, key, style, parent}) => {
        if(visibleArticles[index]) {
            return (
                <CellMeasurer key={key}
                              cache={cache}
                              parent={parent}
                              columnIndex={0}
                              rowIndex={index}>
                    <div className="newsletter-content-article-row" style={{...style}}>
                        <Article
                            article={visibleArticles[index]}
                            includeAbstracts={includeAbstracts}
                            sections={newsletterData.sections}
                            assignUnassignedArticleToSection={assignUnassignedArticleToSection}
                            hideArticle={hideArticle}
                        />
                    </div>
                </CellMeasurer>
            );
        }
    };

    const handleRetrievalDaysChange = (e) => {
        setTempRetrievalDate(e.target.value);
        e.preventDefault();
        e.stopPropagation();
    };

    const confirmRetrievalDaysChange = (e) => {
        setRetrievalDate(tempRetrievalDate);
        e.preventDefault();
        e.stopPropagation();
    };

    const handleSortByDateChange = (e) => {
        setSortByDate(sortByDate === 'asc' ? 'desc' : 'asc');
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        if(newsletterData && newsletterData.sections) {
            cache.clearAll();
            listRef && listRef.current && listRef.current.recomputeRowHeights();
        }
    }, [newsletterData]);

    if(!newsletterData) return null;
    return (
        <div className="newsletter-content-panel newsletter-content-panel--right">
            <div className="newsletter-content-panel__header">
                <h4>Articles</h4>
                {tempRetrievalDate && !fetchingArticles &&
                <div className="newsletter-content-panel__slider days-slider"
                     style={{position: 'relative', top: '-5px'}}>
                    <div className="days-slider__label">
                        <span>
                        {tempRetrievalDate} {Number(tempRetrievalDate) === 1 ? 'day' : 'days'}
                      </span>
                    </div>
                    <input
                        className="days-slider__input"
                        disabled={fetchingArticles}
                        style={{width: '180px', margin: '0 10px'}}
                        min="1"
                        max="30"
                        // onMouseDown={this.handleDisabled}
                        onMouseUp={e => confirmRetrievalDaysChange(e)}
                        value={tempRetrievalDate}
                        onChange={e => handleRetrievalDaysChange(e)}
                        type="range"
                    />
                </div> 
                }
                <div style={{cursor: 'pointer'}}
                    onClick={(e) => handleSortByDateChange(e)}
                >
                    Sort by Date
                    {handleCaret(true, sortByDate, true)}
                </div>
                {!fetchingArticles &&
                (window.innerWidth > 1362 ?
                        <Button
                            style={{
                                height: '31.5px',
                                fontSize: '13px',
                                marginBottom: articles.length ? 0 : '.5rem'
                            }}
                            onClick={toggleCustomArticleModal}>
                            Add Custom Article
                        </Button>
                        :
                        <div>
                            <Button
                                id="add-custom-article-btn"
                                onClick={toggleCustomArticleModal}
                            >
                                <i className="fa fa-plus"/>
                            </Button>
                            <UncontrolledTooltip
                                placement="bottom"
                                target="add-custom-article-btn"
                                delay={{show: 200, hide: 0}}
                            >
                                Add Custom Article
                            </UncontrolledTooltip>
                        </div>
                )}
            </div>
            {fetchingArticles ?
                <div className="newsletter-content-panel__body">
                    <div className="pt-2 pl-0 pb-2">
                        <LoadingSpinner text={'Building article list'}/>
                    </div>
                </div>
                :
                <div className="newsletter-content-panel__body">
                    <div className="newsletter-content-panel__toolbar">
                        <SearchInput
                            onClear={() => setSearchValue('')}
                            onChange={setSearchValue}
                            placeholder="Filter by title and abstract"
                            value={searchValue}
                            // extraParams={source}
                        />
                        <FormGroup className="m-0" style={{minWidth: '250px', maxWidth: '250px'}}>
                            <Input
                                type="select"
                                name="select"
                                id="user-list-sort-select"
                                value={activeFilterSection}
                                onChange={e => setActiveFilterSection(e.target.value)}
                            >
                                <option value="all">Filter by Section</option>
                                {newsletterData.sections.length && newsletterData.sections.map(section => {
                                    return <option key={section.id} value={section.id}>{section.name}</option>;
                                })}
                            </Input>
                        </FormGroup>
                    </div>
                    <div style={{flex: '1 1 auto', display: 'flex'}}>
                        <div className="newsletter-content-panel__source-list">
                            {visibleArticles.length < 1 ?
                                <div>No articles found</div>
                                :
                                <div className="virtualized-flex-outer">
                                    <div className="virtualized-flex-inner">
                                        <AutoSizer>
                                            {({height, width}) => (
                                                <List
                                                    ref={listRef}
                                                    width={width}
                                                    height={height}
                                                    //rowHeight={rowHeight}
                                                    deferredMeasurementCache={cache}
                                                    rowHeight={cache.rowHeight}
                                                    rowCount={visibleArticles.length}
                                                    rowRenderer={rowRenderer}
                                                    // handleSectionSelect={this.handleSectionSelect}
                                                />
                                            )}
                                        </AutoSizer>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {customArticleModalOpen &&
            <AddCustomArticleModal
                sections={newsletterData.sections}
                refreshNewsletter={refreshNewsletter}
                isOpen={customArticleModalOpen}
                toggle={toggleCustomArticleModal}
                newsletterId={newsletterData.id}
            />
            }
        </div>
    );
};

export default Articles;
