import React, {useState, useEffect, useContext} from 'react';
import {Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, Input, FormGroup, Label} from 'reactstrap';
import Logo from 'images/logo.png';
import {useHistory, useLocation} from 'react-router-dom';
import SpinnerButton from 'components/SpinnerButton';
import {checkRegistrationToken, finishRegistration} from 'utils/api/authAPI';
import queryString from 'query-string';
import {timezones} from 'utils/timezones';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';

const ConfirmRegistration = (props) => {
    let history = useHistory();
    const {addFlashMessage} = useContext(FlashMessageContext)
    const {signIn, logout} = useContext(AuthContext)

    const [token, setToken] = useState(null);
    const [tokenChecked, setTokenChecked] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [fullName, setFullName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [timezone, setTimezone] = useState('');
    const [accountType, setAccountType] = useState('single');
    const [organizationName, setOrganizationName] = useState('');

    let queryParams = useLocation();

    const redirectToInvalidLink = () => {
        history.push('/invite-expired');
    };

    const checkToken = async() => {
        try {
            const parsedQuery = queryString.parse(queryParams.search);
            await checkRegistrationToken(parsedQuery.token);
            setToken(parsedQuery.token);
            setTokenChecked(true);
        } catch(err) {
            redirectToInvalidLink();
        }
    };

    useEffect(() => {
        logout();
        checkToken();
    }, [])

    const onSubmit = async() => {
        try {
            setSubmitting(true);
            const params = {
                token,
                plainPassword: {
                    first: password,
                    second: confirmPassword
                },
                // plainPassword: confirmPassword,
                // fullName,
                // timezone,
                //
                // organization: accountType === 'organization' ? organizationName : null,
            };
            const res = await finishRegistration(params)
            if(res.content?.area === 'reader') {
                history.push('/company/reader/signin');
                return;
            }
            signIn(res.content.token);
            history.push('/account/users');
            setSubmitting(false);
        } catch(err) {
            if(err.errors.timezone) {
                addFlashMessage('danger', 'You must select a valid timezone.')
            }
            setSubmitting(false);
        }
    };

    if(!tokenChecked) {
        return null;
    }
    return (
        <div className="auth-block">
            <div className="auth-block__form">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{marginBottom: '.5rem'}}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo}
                                         alt="Ozmosys"
                                         className="auth-block__image img-fluid"
                                    />
                                </a>
                            </div>
                            <div>
                                <div className="text-center mb-4">
                                    <h2>Complete Registration</h2>
                                    <p className="mt-4 mb-3 text-muted">
                                        Please set up your password<br/>
                                        (Minimum 6 Characters)
                                    </p>
                                </div>


                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fa fa-lock"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Password (Min 6 Characters)"
                                        type="password"
                                        name="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fa fa-lock"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Confirm Password"
                                        type="password"
                                        name="confirmPassword"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        value={confirmPassword}
                                    />
                                </InputGroup>


                                <Row>
                                    <Col xs="12" className="text-right">
                                        <SpinnerButton
                                            type="submit"
                                            color="primary"
                                            className="px-4"
                                            block
                                            onClick={onSubmit}
                                            submitting={submitting}
                                            title={'Sign In'}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
        ;
};

export default ConfirmRegistration;
