import React, { useContext, useState, useEffect } from 'react';

import { Col, Row } from 'reactstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import LoadingSpinner from 'components/LoadingSpinner';
import { getCompanyBasedProfile, getFreeCompanyBasedProfile } from 'utils/api/alertsAPI';
import List from './List/';
import './style.scss';
import CompanyPageHeader from './CompanyPageHeader';
import ExtendedInformation from './ExtendedInformation';
import CompanyPageDescription from './CompanyPageDescription';
import CardPerDayChart from './CardPerDayChart';
import useWindowSize from 'hooks/useWindowSize';
import { FlashMessageContext } from 'contexts/FlashMessageContext';

const CompanyPage = (props) => {
    const { id } = useParams();
    const { search } = useLocation();
    const history = useHistory();
    useWindowSize();
    const { handleError } = useContext(AuthContext);
    const { addFlashMessage } = useContext(FlashMessageContext);
    const [fetchingData, setFetchingData] = useState(true);
    const [alertData, setAlertData] = useState([]);
    const [keyExecutives, setKeyExecutives] = useState([]);
    const [companyDescription, setCompanyDescription] = useState('');

    const fetchAlertData = async () => {
        try {
            setFetchingData(true);
            let data;
            if (props.public) {
                const hash = search.slice(3, search.length);
                data = await getFreeCompanyBasedProfile(id, hash);
            } else {
                data = await getCompanyBasedProfile(id);
            }
            setAlertData(data);
            setFetchingData(false);
            setCompanyDescription(data.description?.slice(0, 500));
            setKeyExecutives(data.keyExecutives?.slice(0, 3));
        } catch (err) {
            if (err?.message === 'No Company Data' || err?.code === 'NOT_FOUND') {
                addFlashMessage('danger', 'The company details  are currently unavailable');
                history.length > 1 && document.referrer.indexOf(window.location.host) !== -1 && history.go(-1);
            } else {
                handleError(err, null, true);
            }
            setFetchingData(false);
        }
    };

    useEffect(() => {
        id && fetchAlertData();
    }, [id]);

    return (
        <div className={'view dig-deeper animated fadeIn ' + (props.public ? 'dig-deeper--public ' : '')}>
            {fetchingData ? (
                <Row className="mt-3">
                    <Col xs="12">
                        <LoadingSpinner text="Fetching company data" />
                    </Col>
                </Row>
            ) : (
                alertData && (
                    <Row className="mt-3 mb-4">
                        <CompanyPageHeader alertData={alertData} publicMode={props.public} />
                        <Col xs={window.innerWidth >= 750 ? '9' : '12'} className="mt-5 mb-5 company-main-block">
                            <CompanyPageDescription
                                alertData={alertData}
                                companyDescription={companyDescription}
                                setCompanyDescription={setCompanyDescription}
                            />
                            {1250 >= window.innerWidth && <CardPerDayChart alertData={alertData} fetchingData={fetchingData} />}
                            {window.innerWidth < 750 && (
                                <ExtendedInformation alertData={alertData} keyExecutives={keyExecutives} setKeyExecutives={setKeyExecutives} />
                            )}
                            {alertData.news && (
                                <List
                                    articles={alertData.news}
                                    articlesWithoutDuplicates={alertData.news.filter((item) => !item.hasOwnProperty('parent'))}
                                    alertId={id}
                                    alertName={alertData.companyName}
                                />
                            )}
                        </Col>
                        {window.innerWidth >= 750 && (
                            <Col xs="3" className="mt-5 mb-5">
                                {1250 < window.innerWidth && <CardPerDayChart alertData={alertData} fetchingData={fetchingData} />}
                                <ExtendedInformation alertData={alertData} keyExecutives={keyExecutives} setKeyExecutives={setKeyExecutives} />
                            </Col>
                        )}
                    </Row>
                )
            )}
        </div>
    );
};

export default CompanyPage;
