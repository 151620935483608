export const combinedCountries = [
    {
        displayName: 'English (United States)',
        lang: 'en',
        country: 'US',
    },
    {
        displayName: 'English (Australia)',
        lang: 'en',
        country: 'AU',
    },
    {
        displayName: 'English (Botswana)',
        lang: 'en',
        country: 'BW',
    },
    {
        displayName: 'English (Canada)',
        lang: 'en',
        country: 'CA',
    },
    {
        displayName: 'English (Ethiopia)',
        lang: 'en',
        country: 'ET',
    },
    {
        displayName: 'English (Ghana)',
        lang: 'en',
        country: 'GH',
    },
    {
        displayName: 'English (India)',
        lang: 'en',
        country: 'IN',
    },
    {
        displayName: 'English (Indonesia)',
        lang: 'en',
        country: 'ID',
    },
    {
        displayName: 'English (Ireland)',
        lang: 'en',
        country: 'IE',
    },
    {
        displayName: 'English (Israel)',
        lang: 'en',
        country: 'IL',
    },
    {
        displayName: 'English (Kenya)',
        lang: 'en',
        country: 'KE',
    },
    {
        displayName: 'English (Latvia)',
        lang: 'en',
        country: 'LV',
    },
    {
        displayName: 'English (Malaysia)',
        lang: 'en',
        country: 'MY',
    },
    {
        displayName: 'English (Namibia)',
        lang: 'en',
        country: 'NA',
    },
    {
        displayName: 'English (New Zealand)',
        lang: 'en',
        country: 'NZ',
    },
    {
        displayName: 'English (Nigeria)',
        lang: 'en',
        country: 'NG',
    },
    {
        displayName: 'English (Pakistan)',
        lang: 'en',
        country: 'PK',
    },
    {
        displayName: 'English (Philippines)',
        lang: 'en',
        country: 'PH',
    },
    {
        displayName: 'English (Singapore)',
        lang: 'en',
        country: 'SG',
    },
    {
        displayName: 'English (South Africa)',
        lang: 'en',
        country: 'ZA',
    },
    {
        displayName: 'English (Tanzania)',
        lang: 'en',
        country: 'TZ',
    },
    {
        displayName: 'English (Uganda)',
        lang: 'en',
        country: 'UG',
    },
    {
        displayName: 'English (United Kingdom)',
        lang: 'en',
        country: 'GB',
    },
    {
        displayName: 'English (Ukraine)',
        lang: 'en',
        country: 'UA',
    },
    {
        displayName: 'English (Zimbabwe)',
        lang: 'en',
        country: 'ZW',
    },
    {
        displayName: 'Indonesian (Indonesia)',
        lang: 'id',
        country: 'ID',
    },
    {
        displayName: 'Czech (Czechia)',
        lang: 'cs',
        country: 'CZ',
    },
    {
        displayName: 'German (Germany)',
        lang: 'de',
        country: 'DE',
    },
    {
        displayName: 'German (Austria)',
        lang: 'de',
        country: 'AT',
    },
    {
        displayName: 'German (Switzerland)',
        lang: 'de',
        country: 'CH',
    },
    {
        displayName: 'Spanish (Argentina)',
        lang: 'es',
        country: 'AR',
    },
    {
        displayName: 'Spanish (Chile)',
        lang: 'es',
        country: 'CL',
    },
    {
        displayName: 'Spanish (Colombia)',
        lang: 'es',
        country: 'CO',
    },
    {
        displayName: 'Spanish (Cuba)',
        lang: 'es',
        country: 'CU',
    },
    {
        displayName: 'Spanish (United States)',
        lang: 'es',
        country: 'US',
    },
    {
        displayName: 'Spanish (Mexico)',
        lang: 'es',
        country: 'MX',
    },
    {
        displayName: 'Spanish (Peru)',
        lang: 'es',
        country: 'PE',
    },
    {
        displayName: 'Spanish (Venezuela)',
        lang: 'es',
        country: 'VE',
    },
    {
        displayName: 'French (Belgium)',
        lang: 'fr',
        country: 'BE',
    },
    {
        displayName: 'French (Canada)',
        lang: 'fr',
        country: 'CA',
    },
    {
        displayName: 'French (France)',
        lang: 'fr',
        country: 'FR',
    },
    {
        displayName: 'French (Morocco)',
        lang: 'fr',
        country: 'MA',
    },
    {
        displayName: 'French (Senegal)',
        lang: 'fr',
        country: 'SN',
    },
    {
        displayName: 'French (Switzerland)',
        lang: 'fr',
        country: 'CH',
    },
    {
        displayName: 'Italian (Italy)',
        lang: 'it',
        country: 'IT',
    },
    {
        displayName: 'Latvian (Latvia)',
        lang: 'lv',
        country: 'LV',
    },
    {
        displayName: 'Hungarian (Hungarian)',
        lang: 'hu',
        country: 'HU',
    },
    {
        displayName: 'Dutch (Belgium)',
        lang: 'nl',
        country: 'BE',
    },
    {
        displayName: 'Dutch (Netherlands)',
        lang: 'nl',
        country: 'NL',
    },
    {
        displayName: 'Norwegian (Norway)',
        lang: 'no',
        country: 'NO',
    },
    {
        displayName: 'Polish (Poland)',
        lang: 'pl',
        country: 'PL',
    },
    {
        displayName: 'Portuguese (Brazil)',
        lang: 'pt',
        country: 'BR',
    },
    {
        displayName: 'Portuguese (Portugal)',
        lang: 'pt',
        country: 'PT',
    },
    {
        displayName: 'Romanian (Romania)',
        lang: 'ro',
        country: 'RO',
    },
    {
        displayName: 'Slovak (Slovakia)',
        lang: 'sk',
        country: 'SK',
    },
    {
        displayName: 'Slovenian (Slovenia)',
        lang: 'sl',
        country: 'SI',
    },
    {
        displayName: 'Swedish (Sweden)',
        lang: 'sv',
        country: 'SE',
    },
    {
        displayName: 'Vietnamese (Vietnam)',
        lang: 'vi',
        country: 'VN',
    },
    {
        displayName: 'Turkish (Turkey)',
        lang: 'tr',
        country: 'TR',
    },
    {
        displayName: 'Greek (Greece)',
        lang: 'el',
        country: 'GR',
    },
    {
        displayName: 'Bulgarian (Bulgaria)',
        lang: 'bg',
        country: 'BG',
    },
    {
        displayName: 'Russian (Russia)',
        lang: 'ru',
        country: 'RU',
    },
    {
        displayName: 'Russian (Ukraine)',
        lang: 'ru',
        country: 'UA',
    },
    {
        displayName: 'Serbian (Serbia)',
        lang: 'sr',
        country: 'RS',
    },
    {
        displayName: 'Hebrew (Israel)',
        lang: 'he',
        country: 'IL',
    },
    {
        displayName: 'Arabic (United Arab Emirates)',
        lang: 'ar',
        country: 'AE',
    },
    {
        displayName: 'Arabic (Kingdom of Saudi Arabia)',
        lang: 'ar',
        country: 'SA',
    },
    {
        displayName: 'Arabic (Lebanon)',
        lang: 'ar',
        country: 'LB',
    },
    {
        displayName: 'Arabic (Egypt)',
        lang: 'ar',
        country: 'EG',
    },
    {
        displayName: 'Marathi (India)',
        lang: 'mr',
        country: 'IN',
    },
    {
        displayName: 'Hindi (India)',
        lang: 'hi',
        country: 'IN',
    },
    {
        displayName: 'Tamil (India)',
        lang: 'ta',
        country: 'IN',
    },
    {
        displayName: 'Telugu (India)',
        lang: 'te',
        country: 'IN',
    },
    {
        displayName: 'Thai (Thailand)',
        lang: 'th',
        country: 'TH',
    },
    {
        displayName: 'Chinese - Simplified (China)',
        lang: 'zh-CN',
        country: 'CN',
    },
    {
        displayName: 'Chinese - Traditional (China)',
        lang: 'zh-TW',
        country: 'CN',
    },
    {
        displayName: 'Chinese (Taiwan)',
        lang: 'zh-TW',
        country: 'TW',
    },
    {
        displayName: 'Chinese (Hong Kong)',
        lang: 'zh-HK',
        country: 'HK',
    },
    {
        displayName: 'Japanese (Japan)',
        lang: 'ja',
        country: 'JP',
    },
    {
        displayName: 'Korean (South Korea)',
        lang: 'ko',
        country: 'KR',
    },
];