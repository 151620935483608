import React, {useState, useEffect, useContext} from 'react';

import {Collapse, Button, CardBody, CardHeader, Badge} from 'reactstrap';

import DeleteSection from './Modals/DeleteSection';
import RenameSection from './Modals/RenameSection';
import Alert from './Alert';
import AssignedArticle from './AssignedArticle';

const SectionView = (props) => {
    const {section, newsletter, deletedArticles, newsletterType, refreshNewsletter, setActiveView, handleError} = props;
    const [tempSection, setTempSection] = useState(null);
    const [tempNewsletter, setTempNewsletter] = useState(null);
    const [assignedArticles, setAssignedArticles] = useState([]);
    const [visibleArticles, setVisibleArticles] = useState([]);
    const [deleteSectionModalOpen, setDeleteSectionModalOpen] = useState(false);
    const [renameSectionModalOpen, setRenameSectionModalOpen] = useState(false);
    const [alertListOpen, setAlertListOpen] = useState(false);
    const [articleListOpen, setArticleListOpen] = useState(false);

    useEffect(() => {
        let filteredArticles;
        if(newsletter && section) {
            setTempNewsletter(newsletter);
            setTempSection(section);
        }
    }, [newsletter, section]);

    const filterObjectArray = (arr, filterArr) => (
        arr.filter(article => !filterArr.includes(article.id))
    );

    const toggleDeleteSectionModal = () => {
        setDeleteSectionModalOpen(!deleteSectionModalOpen);
    };
    const toggleRenameSectionModal = () => setRenameSectionModalOpen(!renameSectionModalOpen);
    const toggleAlertListOpen = () => setAlertListOpen(!alertListOpen);
    const toggleArticleListOpen = () => setArticleListOpen(!articleListOpen);

    if(!newsletter || !section) return null;
    return (
        <div className="mobile-section-view">
            <h5 className="mobile-section-view__header mb-3">Section: {section.name}</h5>
            <div className="mobile-section-collapse">
                <CardHeader
                    className="mobile-section-collapse__header"
                    onClick={toggleAlertListOpen}
                >
                        <span>
                            <i className={'fa fa-angle-right ' + (alertListOpen ? 'rotate-90-down' : '')}/> &nbsp;Alerts
                            &nbsp; <Badge>{section.alerts && section.alerts.length}</Badge>
                        </span>
                    <div
                        className="mobile-section-collapse__edit"
                        onClick={() => setActiveView('addAlerts')}
                    >
                        <i className="fa fa-edit"/>
                    </div>
                </CardHeader>
                <Collapse isOpen={alertListOpen}>
                    <CardBody className="mobile-section-collapse__body">
                        {section.alerts && section.alerts.length ?
                            section.alerts.map(alert => (
                                <Alert alert={alert} key={alert.id}/>
                            ))
                            :
                            <div className="p-3">This section has no assigned alerts.</div>
                        }
                    </CardBody>
                </Collapse>
            </div>
            {newsletterType === 'curated' &&
            <div className="mobile-section-collapse">
                <CardHeader
                    className="mobile-section-collapse__header"
                    color="primary"
                    onClick={toggleArticleListOpen}
                >
                        <span>
                            <i className={'fa fa-angle-right ' + (articleListOpen ? 'rotate-90-down' : '')}/> &nbsp;Articles
                            &nbsp;
                            <Badge>{section.newsletterSectionNews && section.newsletterSectionNews.length}</Badge>
                        </span>
                    <div className="mobile-section-collapse__edit" onClick={() => setActiveView('addArticles')}>
                        <i className="fa fa-edit"/>
                    </div>
                </CardHeader>
                <Collapse isOpen={articleListOpen}>
                    <CardBody className="mobile-section-collapse__body">
                        {section.newsletterSectionNews && section.newsletterSectionNews.length ?
                            section.newsletterSectionNews.map((article, i) => (
                                <AssignedArticle key={article.id} article={article}/>
                            ))
                            :
                            <div className="p-3">This section has no assigned articles.</div>
                        }
                    </CardBody>
                </Collapse>
            </div>
            }
            {section.name !== 'Unassigned' &&
            <Button block color="primary" className="mt-3" onClick={toggleRenameSectionModal}>
                Rename Section
            </Button>
            }
            {section.name !== 'Unassigned' &&
            <Button block color="danger" className="mt-2" onClick={toggleDeleteSectionModal}>
                Delete Section
            </Button>
            }
            {deleteSectionModalOpen &&
            <DeleteSection
                isOpen={deleteSectionModalOpen}
                newsletterId={newsletter.id}
                refreshNewsletter={refreshNewsletter}
                toggle={toggleDeleteSectionModal}
                section={section}
                setActiveView={setActiveView}
            />
            }
            {renameSectionModalOpen &&
            <RenameSection
                isOpen={renameSectionModalOpen}
                sectionName={section.name}
                sectionId={section.id}
                refreshNewsletter={refreshNewsletter}
                type="edit"
                newsletterId={newsletter.id}
                toggle={toggleRenameSectionModal}
                handleError={handleError}
            />
            }

        </div>
    );
};

export default SectionView;
