import React, {useEffect, useState} from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import {Input, InputGroup, InputGroupAddon, InputGroupText, Table} from 'reactstrap';
import {getBrokenSources} from 'utils/api/statisticsAPI';
import {truncateLongWords} from 'Helpers/truncateLongWords';
import CsvButton from "../CsvButton";
import DayPickerInput from "react-day-picker/DayPickerInput";
import moment from "moment-timezone";

const BrokenSourcesReport = () => {
    const [fetchingData, setFetchingData] = useState(false);
    const [fetchingCsv, setFetchingCsv] = useState(false);
    const [records, setRecords] = useState([]);
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'month').format('MM-DD-YYYY'));
    const [toDate, setToDate] = useState(moment().format('MM-DD-YYYY'));
    const [activeSortProperty, setActiveSortProperty] = useState('content_updated_at');
    const [activeSortDirection, setActiveSortDirection] = useState('desc');

    useEffect(async () => {
        setFetchingData(true);
        setRecords(
            await getBrokenSources(
                moment(fromDate).format('YYYY-MM-DD'),
                moment(toDate).format('YYYY-MM-DD'),
                activeSortProperty,
                activeSortDirection
            )
        );
        setFetchingData(false);
    }, [fromDate, toDate, activeSortProperty, activeSortDirection]);

    const handleCaret = (value) => {
        if(activeSortProperty === value) {
            return activeSortDirection === 'asc' ?
                <i className="fa fa-caret-up list-sort-caret list-sort-caret--active"/>
                :
                <i className="fa fa-caret-down list-sort-caret list-sort-caret--active"/>;
        } else {
            return <i className="fa fa-caret-down list-sort-caret"/>;
        }
    };

    const downloadFilteredCsv = async() => {
        setFetchingCsv(true);
        await getBrokenSources(activeSortProperty, activeSortDirection, true);
        setFetchingCsv(false);
    }

    const handleFromDateChange = day => {
        setFromDate(moment(day).format('MM-DD-YYYY'));
    };

    const handleToDateChange = day => {
        setToDate(moment(day).format('MM-DD-YYYY'));
    };

    const handleSortableHeaderClick = (value) => {
        if(value === activeSortProperty) {
            activeSortDirection === 'asc' ? setActiveSortDirection('desc') : setActiveSortDirection('asc');
        } else {
            setActiveSortProperty(value);
            setActiveSortDirection('asc');
        }
    };

    return (
    <div className="list">
    {fetchingData ?
        <LoadingSpinner/>
        :
        <div>
            <div className="list__utility-row">
                <div className="list__search-container">
                    <DayPickerInput
                        value={new Date(fromDate)}
                        dayPickerProps={{
                            disabledDays: {
                                before: new Date(moment().subtract(60, 'months').format('YYYY-MM-DD')),
                                after: new Date(toDate),
                            },
                        }}
                        autoComplete={false}
                        inputProps={{ref: null}}
                        onDayChange={handleFromDateChange}
                        component={props => (
                            <InputGroup className="pr-1 mb-0">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-calendar"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    {...props}
                                    placeholder="MM/DD/YYYY"
                                    value={fromDate}
                                />
                            </InputGroup>
                        )}
                    />
                    <DayPickerInput
                        value={new Date(toDate)}
                        dayPickerProps={{
                            disabledDays: {
                                before: new Date(fromDate),
                                after: new Date(),
                            },
                        }}
                        autoComplete={false}
                        inputProps={{ref: null}}
                        onDayChange={handleToDateChange}
                        component={props => (
                            <InputGroup className="pr-1 mb-0">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-calendar"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    {...props}
                                    placeholder="MM/DD/YYYY"
                                    value={toDate}
                                />
                            </InputGroup>
                        )}
                    />
                    <CsvButton
                        downloadFilteredCsv={downloadFilteredCsv}
                        downloading={fetchingCsv}
                    />
                </div>
            </div>
        {records.length < 1 ?
            <div>
                No results found
            </div>
            :
            <div>
                <Table className="responsive-table alert-list-table">
                    <tbody>
                    <tr className="responsive-table header-row">
                        <th 
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('name')}
                        >Source Title {handleCaret('name')}
                        </th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('type')}
                        >Source Type {handleCaret('type')}</th>
                        <th>URL</th>
                        <th>Error Message</th>
                        <th>Date of Error</th>
                        <th>Last Updated</th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('solved')}
                        >Solved {handleCaret('solved')}</th>
                        <th
                            className="responsive-table__sortable-th"
                            onClick={() => handleSortableHeaderClick('users')}
                        >Assigned Users {handleCaret('users')}</th>
                    </tr>
                    {records.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.type}</td>
                                <td><a target='_blank' href={item.url}>{truncateLongWords(item.url, 30)}</a> </td>
                                <td style={{wordBreak: 'break-all'}}>{item.last_error}</td>
                                <td>{item.broken_at}</td>
                                <td>{item.content_updated_at}</td>
                                <td>{item.solved ? 'Yes' : 'No'}</td>
                                <td>{item.users}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </div>
        }
        </div>
    }
    </div>
    );
};

export default BrokenSourcesReport;
