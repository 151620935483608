import React, { useState, useEffect } from 'react';

import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import moment from 'moment-timezone';

const ArticlesPerDayChart = (props) => {
    const { articles, articlesPerDay, handleDateSelect, resetAllFilters, activeIndex, colorArray, resetColor, changeColor, showEmptyMessage } = props;

    const [dataArray, setDataArray] = useState([]);

    useEffect(() => {
        const dataObj = JSON.parse(JSON.stringify(articles));
        const dataArray = dataObj.map((article) => (article.created_at = article.created_at.slice(0, 10)));
        setDataArray(dataArray);
    }, [articles]);

    const convertDateString = (dateString) => {
        if (!dateString) return null;

        // let tz = moment.tz.guess();
        // // let dateObj = new Date((dateString + ' 00:00:00').replace(' ', 'T'));
        // let dateObj = new Date(dateString);
        // let momentObj = moment(dateObj).tz(tz);
        // return momentObj.format('DD');
        return moment(dateString).format('DD');
    };

    const getOccurrence = (array, value) => {
        // Get number of times article date appears in last 30 days array
        return array.filter((v) => v === value).length;
    };

    const getValue = (item, index, ticks) => {
        const width = window.innerWidth;

        if (index === 0) {
            return getMonth(index);
        } else if (width >= 380 && index % 2 === 0) {
            if (item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        } else if (width < 380 && index % 3 === 0) {
            if (item[0] === '0' && +ticks[index - 2][0] > +item[0]) {
                return getMonth(index);
            }
            return item;
        }
    };

    const getMonth = (index) => {
        // let tz = moment.tz.guess();
        // let dateObj = new Date((articlesPerDay[index].day + ' 00:00:00').replace(' ', 'T'));
        // let momentObj = moment(dateObj).tz(tz);
        // return momentObj.format('MMM');
        return moment(articlesPerDay[index].day).format('MMM');
    };

    const buildLabelDate = (dateString) => {
        if (dateString == null) {
            return '';
        }

        // let tz = moment.tz.guess();
        // let dateObj = new Date((dateString + ' 00:00:00').replace(' ', 'T'));
        // let momentObj = moment(dateObj).tz(tz);
        // return momentObj.format('MMM DD,YYYY');
        return moment(dateString).format('MMM DD,YYYY');
    };

    const filterListByDate = (element) => {
        handleDateSelect(articlesPerDay[element[0]._index]);
    };

    const labels = articlesPerDay.map((item) => item.day);
    // const traderSma = articlesPerDay.map(item => item.trader_sma);
    const linearRegression = articlesPerDay.map((item) => item.linear_regression);

    const articlesBarArray = labels.map((item) => {
        return getOccurrence(dataArray, item);
    });

    const data = {
        labels: labels.map((item) => convertDateString(item)),
        datasets: [
            {
                label: 'Trends',
                data: linearRegression,
                borderColor: 'rgba(37,102,166,1)',
                backgroundColor: 'rgba(37,102,166,1)',
                fill: false,
                type: 'line',
                datalabels: {
                    display: false,
                },
            },
            // {
            //     label: 'Simple Moving Average',
            //     data: traderSma,
            //     borderColor: '#ff8042',
            //     backgroundColor: '#ff8042',
            //     fill: false,
            //     type: 'line',
            //     datalabels: {
            //         display: false,
            //     },
            // },
            {
                label: 'Articles',
                backgroundColor: colorArray.map((item) => item.backgroundColor),
                borderColor: colorArray.map((item) => item.borderColor),
                borderWidth: 1,
                hoverBackgroundColor: colorArray.map((item) => item.hoverBackgroundColor),
                hoverBorderColor: colorArray.map((item) => item.hoverBorderColor),
                data: articlesBarArray,
            },
        ],
    };

    return (
        <div style={{ maxHeight: 300 }}>
            {showEmptyMessage && (
                <div className="dig-deeper-empty-chart">No alert content is available yet. Please check again later for updated content.</div>
            )}
            <Bar
                data={data}
                height={300}
                responsive={false}
                getElementAtEvent={(element) => {
                    if (element.length) {
                        let index = element[0]._index;
                        if (activeIndex === index) {
                            resetColor();
                            resetAllFilters();
                        } else {
                            changeColor(index);
                            filterListByDate(element);
                        }
                    }
                }}
                options={{
                    legend: {
                        display: true,
                    },
                    tooltips: {
                        callbacks: {
                            title: (tooltipItem) => {
                                return buildLabelDate(articlesPerDay[tooltipItem[0].index].day);
                            },
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [
                            {
                                ticks: {
                                    autoSkip: false,
                                    display: true,
                                    stepSize: 1,
                                    userCallback: (item, index, ticks) => {
                                        return getValue(item, index, ticks);
                                    },
                                    maxTicksLimit: 30,
                                },
                            },
                        ],
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            color: 'white',
                            borderRadius: 6,
                            font: {
                                size: 12,
                            },
                            formatter: function (value) {
                                if (value > 0) {
                                    return value;
                                } else {
                                    return null;
                                }
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

export default ArticlesPerDayChart;
