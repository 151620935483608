import React, {useState, useContext} from 'react';

import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {switchToOrganization} from 'utils/api/authAPI';
import SpinnerButton from 'components/SpinnerButton';
import ConfirmModal from 'components/SingleUserBlock/ConfirmModal';
import './style.scss';
import {useHistory} from 'react-router-dom';
import {AuthContext} from 'contexts/AuthContext';

const SingleUserBlock = (props) => {
    const {itemType, wide} = props;
    let history = useHistory();

    const {addFlashMessage} = useContext(FlashMessageContext);
    const {fetchProfile} = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const handleAccountTypeChange = async() => {
        try {
            await switchToOrganization()
            await fetchProfile()
            history.push('/account')
            addFlashMessage('success', 'Account mode is now set to Organization.')
        } catch(err) {
            addFlashMessage('danger', 'Unable to change account mode at this time.')
        }
    }

    const toggleConfirmModal = () => {
        setModalOpen(!modalOpen)
    }

    return (
        <div className={'single-user-block ' + (wide ? 'single-user-block--wide' : '')}>
            <p>In order to create {itemType}, your account must be set to organization mode.</p>
            <div className="list-with-csv list-with-csv--desktop">
                <SpinnerButton
                    onClick={toggleConfirmModal}
                    color="primary"
                    submitting={submitting}
                    title={'Change Mode'}
                >
                    Change Mode
                </SpinnerButton>
            </div>
            <ConfirmModal isOpen={modalOpen} toggle={toggleConfirmModal} onConfirm={handleAccountTypeChange}/>
        </div>
    );
};

export default SingleUserBlock;
