import React, {useState, useEffect, useContext} from 'react';

import {
    Modal,
    ModalFooter,
    Button,
} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import {getAlertGroups, getAlertsWithoutParams, getReaderAlertGroups, getReaderAlerts} from 'utils/api/alertsAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import DualSelect from 'components/DualSelect';
import {AuthContext} from 'contexts/AuthContext';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const ManageAlertsModal = (props) => {
    const {handleError} = useContext(AuthContext);
    const {isOpen, toggle, onSubmit, submitting, assignedAlerts, isPublic} = props;
    const [name, setName] = useState('');
    const [fetchingAlerts, setFetchingAlerts] = useState(true);
    const [alerts, setAlerts] = useState([]);
    const [alertGroups, setAlertGroups] = useState([]);
    const [selectedAlerts, setSelectedAlerts] = useState([]);

    useEffect(() => {
        setName('');
        setSelectedAlerts(assignedAlerts)
    }, [isOpen]);

    const fetchAlerts = async() => {
        try {
            const alertData = isPublic ? await getReaderAlerts() : await getAlertsWithoutParams(false, false, 1, 2000);
            const alertGroupData = isPublic ? await getReaderAlertGroups() : await getAlertGroups();
            setAlerts(alertData.result);
            setAlertGroups(alertGroupData);
            setFetchingAlerts(false);
        } catch(err) {
            setFetchingAlerts(false);
            handleError(err);
        }
    };

    useEffect(() => {
        fetchAlerts();
    }, []);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="reader-manage-alerts-modal modal-xl">
            {fetchingAlerts ?
                <LoadingSpinner padding text="Fetching alert data"/>
                :
                <div>
                    <ModalBodyWithClose toggle={toggle}>
                        <h2>Manage Alerts</h2>
                        <div className="mt-4">
                            <DualSelect
                                selectedList={selectedAlerts}
                                fullList={alerts}
                                groups={alertGroups}
                                onSelect={setSelectedAlerts}
                                contentType="alerts"
                            />
                        </div>
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <SpinnerButton
                            type="modal"
                            color="primary"
                            onClick={() => onSubmit(selectedAlerts, toggle)}
                            submitting={submitting}
                            title="Save Changes"
                        />
                    </ModalFooter>
                </div>
            }
        </Modal>
    );
};

export default ManageAlertsModal;
