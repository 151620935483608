import React from 'react';
import { FormGroup, Input } from 'reactstrap';

export const GeoDropdownFilter = (props) => {
    const { activeCountryFilter, setActiveCountryFilter, setActiveRegionFilter, activeRegionFilter, countries, regions, setRegions, isCountyFilter } =
        props;
    const options = isCountyFilter ? countries : regions;
    const activeFilter = isCountyFilter ? activeCountryFilter : activeRegionFilter;

    const handleDropdownSelect = (val) => {
        if (isCountyFilter) {
            setActiveCountryFilter(val);
            setActiveRegionFilter('');
            if (!val) {
                setActiveRegionFilter(null);
                setRegions && setRegions([]);
            }
        } else {
            setActiveRegionFilter(val);
        }
    };

    return (
        <div className="d-flex align-items-center md-ml-2">
            {window.innerWidth > 630 && (
                <>
                    <span style={{ color: 'black', width: '70px' }}>Filter by</span> &nbsp;
                </>
            )}

            <FormGroup className="m-0 mr-2">
                <Input
                    type="select"
                    name="select"
                    placeholder={isCountyFilter ? 'Countries' : 'Regions'}
                    value={activeFilter || ''}
                    onChange={(e) => handleDropdownSelect(e.target.value)}
                    className="countries-select">
                    <option value={''}>{isCountyFilter ? 'Country' : 'Region'}</option>
                    {!!options?.length &&
                        options.map((item) => (
                            <option key={item?.countryShortCode || item?.shortCode} value={item?.countryName || item?.name}>
                                {item?.countryName || item?.name}
                            </option>
                        ))}
                </Input>
            </FormGroup>
        </div>
    );
};
