import React, { useState, useEffect } from 'react';
import { Pagination, PaginationItem, PaginationLink, Button, Label, FormGroup, Input } from 'reactstrap';
import AlertPreviewItem from 'components/AlertPreviewItem';
import LoadingSpinner from 'components/LoadingSpinner';
import { GeoDropdownFilter } from 'containers/Alerts/GeoDropdownFilter';
import FilterDropdown from 'components/FilterDropdown';

const Preview = (props) => {
    const {
        preview,
        setActivePreviewSortValue,
        updatingPreview,
        activeCountryFilter,
        setActiveCountryFilter,
        activeRegionFilter,
        setActiveRegionFilter,
        additionalCategories,
        activeAdditionalCategoryFilter,
        setActiveAdditionalCategoryFilter,
        activeSentimentFilter,
        setActiveSentimentFilter,
        typeOfSource,
        setTypeOfSource,
        handleSubmit,
        isEditForm,
        countries,
        regions,
    } = props;

    const [pageNumbers, setPageNumbers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [windowSize, setWindowSize] = useState(null);
    const sentimentList = [
        { id: '-1', name: 'Negative' },
        { id: '1', name: 'Positive' },
    ];

    useEffect(() => {
        getPageNumbers(preview.content);
        setCurrentPage(1);
        setWindowSize(window.innerWidth);
    }, [props.preview]);

    const clearAllFilters = () => {
        setActiveCountryFilter('');
        setActiveRegionFilter('');
        setActiveAdditionalCategoryFilter('');
        setActiveSentimentFilter('');
        setActivePreviewSortValue('date-new');
        setTypeOfSource('all');
    };

    const getPageNumbers = (arr) => {
        const pageNumbers = [];
        for (let i = arr.length; i > 0; i -= 10) {
            pageNumbers.push(i);
        }
        setPageNumbers(pageNumbers);
    };

    const handlePaginationClick = (e) => {
        let num = Number(e.target.innerText);
        setCurrentPage(num);
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage < pageNumbers.length) {
            let newPage = currentPage + 1;
            setCurrentPage(newPage);
        }
    };

    const renderPagination = () => {
        if (currentPage <= 4) {
            let firstPage = 0;
            let lastPage = windowSize > 600 ? 10 : 6;

            return pageNumbers.slice(firstPage, lastPage).map((item) => {
                let index = pageNumbers.indexOf(item);

                return (
                    <PaginationItem key={index + 1} active={index + 1 === currentPage}>
                        <PaginationLink id={index + 1} key={index + 1} onClick={handlePaginationClick}>
                            {index + 1}
                        </PaginationLink>
                    </PaginationItem>
                );
            });
        } else {
            let firstPage = windowSize > 600 ? currentPage - 5 : currentPage - 3;
            let lastPage = windowSize > 600 ? currentPage + 5 : currentPage + 3;

            return pageNumbers.slice(firstPage, lastPage).map((item) => {
                let index = pageNumbers.indexOf(item);

                return (
                    <PaginationItem active={index + 1 === currentPage}>
                        <PaginationLink id={index + 1} key={index + 1} onClick={handlePaginationClick}>
                            {index + 1}
                        </PaginationLink>
                    </PaginationItem>
                );
            });
        }
    };

    return (
        <div className="mt-2 internal-preview-list bordered-preview-list">
            <div className="bordered-preview-list__summary" style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" onChange={() => setTypeOfSource('all')} checked={typeOfSource === 'all'} name="all" /> All Sources
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" checked={typeOfSource === 'legal'} onChange={() => setTypeOfSource('legal')} name="legal" />
                            Legal Sources
                        </Label>
                    </FormGroup>
                </div>
                <div style={{ width: 'calc(100% - 120px)', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    <GeoDropdownFilter
                        activeCountryFilter={activeCountryFilter}
                        setActiveCountryFilter={setActiveCountryFilter}
                        setActiveRegionFilter={setActiveRegionFilter}
                        countries={countries || []}
                        isCountyFilter
                    />
                    {!!regions?.length && (
                        <GeoDropdownFilter
                            activeRegionFilter={activeRegionFilter}
                            setActiveRegionFilter={setActiveRegionFilter}
                            regions={regions || []}
                        />
                    )}
                    <FilterDropdown
                        title={'AI Category'}
                        activeFilter={activeAdditionalCategoryFilter}
                        setActiveFilter={setActiveAdditionalCategoryFilter}
                        list={additionalCategories}
                    />

                    {window.location.origin !== 'https://news.ozmosys.com' && (
                        <FilterDropdown
                            title={'Sentiment'}
                            activeFilter={activeSentimentFilter}
                            setActiveFilter={setActiveSentimentFilter}
                            list={sentimentList}
                        />
                    )}

                    <Button color="link" className="btn-clear-all" onClick={clearAllFilters}>
                        Clear all
                    </Button>
                </div>
            </div>
            {/*<div style={{justifyContent: 'right'}} className="bordered-preview-list__summary">
                <PreviewOptionsDropdown
                    setActivePreviewSortValue={setActivePreviewSortValue}
                    activePreviewSortValue={activePreviewSortValue}
                />

            </div>*/}
            <div className="mb-3 d-flex">
                <div className="bordered-preview-list__left">
                    <strong>
                        {preview.count} {preview.count === 1 ? 'article' : 'articles'} matched your filters for the last 30 days&nbsp;&nbsp;
                    </strong>
                    <div style={{ marginTop: '20px' }}>
                        <>
                            {updatingPreview ? (
                                <LoadingSpinner text="Fetching news" />
                            ) : preview.content.length ? (
                                preview.content
                                    .slice(currentPage * 10 - 10, currentPage * 10)
                                    .map((item) => <AlertPreviewItem item={item} key={item.id} withHour withRelevance includeSentiment />)
                            ) : (
                                <div>
                                    <h5>No results found</h5>
                                </div>
                            )}
                        </>
                    </div>
                </div>
                {/*<div className="bordered-preview-list__right mobile-hide">
                    {updatingPreview ?
                        <div className="relevance-selector-container" style={{minHeight: '251.19px'}}>
                            <LoadingSpinner text="Applying search relevance"/>
                        </div>
                        :
                        (preview.trader_sma &&
                            <div className="flex-1 mt-4">
                                <div style={{fontWeight: 'bold', fontSize: '1.1rem', color: '#4692b9'}}>Sentiment Analysis</div>
                                <SentimentChart
                                    data={preview.trader_sma}
                                />
                            </div>
                        )
                    }
                </div>*/}
            </div>
            {updatingPreview ? (
                <div className="relevance-selector-container">
                    <LoadingSpinner text="Applying search relevance" />
                </div>
            ) : (
                pageNumbers.length > 1 && (
                    <Pagination style={{ marginTop: '10px' }}>
                        <PaginationItem>
                            <PaginationLink previous onClick={previousPage} />
                        </PaginationItem>
                        {renderPagination()}
                        <PaginationItem>
                            <PaginationLink next onClick={nextPage} />
                        </PaginationItem>
                    </Pagination>
                )
            )}
        </div>
    );
};

export default Preview;
