import React, {useEffect, useState} from 'react';

import {Button, Modal, ModalBody, ModalFooter, Input} from 'reactstrap';
import LoadingSpinner from 'components/LoadingSpinner';
import {editNewsletterSection, addNewsletterSection} from 'utils/api/newslettersAPI';
import SpinnerButton from 'components/SpinnerButton';
import InputWithError from 'components/InputWithError';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const RenameSection = (props) => {
    const {type, sectionId, newsletterId, sectionName, addFlashMessage,
        refreshNewsletter, toggle, isOpen, handleRefresh, handleError} = props;
    const [name, setName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if(type === 'edit') {
            setName(sectionName);
        }
    }, []);

    useEffect(() => {
        if(!isOpen) {
            setName('');
        }
    }, [isOpen]);

    const handleSubmit = async() => {
        if(!submitting) {
            setSubmitting(true);
            setErrors(null);
            try {
                if(type === 'edit') {
                    await editNewsletterSection(newsletterId, sectionId, {name});
                    await refreshNewsletter();
                    toggle();
                } else {
                    await addNewsletterSection(newsletterId, {name});
                    await refreshNewsletter();
                    toggle();
                }
            } catch(err) {
                setSubmitting(false);
                handleError(err, setErrors);
            }
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBodyWithClose toggle={toggle}>
                <h4>
                    {type === 'edit' ?
                        'Rename Section'
                        :
                        'Create Section'
                    }
                </h4>
                <div>
                    <InputWithError
                        value={name}
                        errorObj={errors}
                        name="name"
                        onChange={setName}
                        marginBottomClass="mb-0"
                        placeholder="Section name"
                    />
                </div>
            </ModalBodyWithClose>

            {type === 'edit' ?
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                    <SpinnerButton
                        submitting={submitting}
                        title="Save"
                        style={{minWidth: '68.95px'}}
                        color="primary"
                        onClick={handleSubmit}
                    />
                </ModalFooter>
                :
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                    <SpinnerButton
                        submitting={submitting}
                        title="Create"
                        style={{minWidth: '68.95px'}}
                        color="primary"
                        onClick={handleSubmit}
                    />
                </ModalFooter>
            }
        </Modal>
    );
};

export default RenameSection;
