import React, {useState} from 'react';
import './style.scss';

const CompanyPageDescription = (props) => {
    const {alertData, companyDescription, setCompanyDescription} = props;
    const [expandedCompanyDescription, setExpandedCompanyDescription] = useState(false);

    const expandDescription = () => {
        setCompanyDescription(alertData.description);
        setExpandedCompanyDescription(true);
    }

    const hideDescription = () => {
        setCompanyDescription(alertData.description?.slice(0, 500));
        setExpandedCompanyDescription(false);
    }

    return (
        <>
            <div className='company-header-description'>
                <h4 className='company-header'>Description</h4>
            </div>
            <div className='company-description'>
                <p>
                    {companyDescription}&nbsp;
                    {
                        alertData.description?.length > 500 && 
                        (
                            expandedCompanyDescription === false
                            ? <a href='#' onClick={expandDescription}>more</a>
                            : <a href='#' onClick={hideDescription}>hide</a>
                        )
                    }
                </p>
            </div>
        </>
    );
};

export default CompanyPageDescription;
