import React, {useState} from 'react';

import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap';

import TabBar from 'components/TabBar';
import AlertsList, { SEARCH_TYPE } from './AlertsList';
import AlertGroups from './AlertGroups';
import Labels from './Labels';

const Alerts = () => {
    const [activeView, setActiveView] = useState('alerts');

    const handleActiveView = () => {
        switch(activeView) {
            case 'alerts':
                return <AlertsList alertType={SEARCH_TYPE}/>;
            case 'alertGroups':
                return <AlertGroups/>;
            case 'labels':
                return <Labels/>;
            default:
                return <AlertsList alertType={SEARCH_TYPE}/>;
        }
    }

    return (
        <div className="view view--mobile-full alerts ">
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <div className="flex-space-between">
                                    <h1>Searches</h1>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <TabBar
                                    activeItem={activeView}
                                    setActive={setActiveView}
                                    items={
                                        [
                                            {data: 'alerts', name: 'Searches'},
                                            {data: 'alertGroups', name: 'Groups'},
                                            // {data: 'labels', name: 'Labels'},
                                        ]
                                    }
                                />
                                {handleActiveView()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Alerts;
