import React, {useState, useEffect} from 'react';

import {Modal, ModalBody, ModalFooter, Button,} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import {addUserGroup, assignUsersToUserGroup, getUsersWithoutParams} from 'utils/api/usersAPI';
import LoadingSpinner from 'components/LoadingSpinner';
import DualSelect from 'components/DualSelect';
import InputWithError from 'components/InputWithError';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const CreateUserGroupModal = (props) => {
    const {isOpen, toggle, refreshUserGroups, addFlashMessage, handleError} = props;
    const [name, setName] = useState('');
    const [fetchingUsers, setFetchingUsers] = useState(true);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [errors, setErrors] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setName('');
    }, [isOpen]);

    const fetchUsers = async() => {
        try {
            const userData = await getUsersWithoutParams(false, false, 1, 2000);
            setUsers(userData.result);
            setFetchingUsers(false);
        } catch(err) {
            setFetchingUsers(false);
            handleError(err);
        }
    };

    const createGroup = async() => {
        try {
            setSubmitting(true);
            setErrors(null);
            let createParams = {name: name ? name : null};
            let assignParams = {users: selectedUsers.map(item => item.id)};
            const newUserGroup = await addUserGroup(createParams);
            await assignUsersToUserGroup(newUserGroup.content.id, assignParams);
            await refreshUserGroups();
            addFlashMessage('success', 'User group successfully created');
            setSubmitting(false);
            toggle();
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
            {fetchingUsers ?
                <LoadingSpinner padding text="Fetching user data"/>
                :
                <div>
                    <ModalBodyWithClose toggle={toggle}>
                        <h2>Create User Group</h2>
                        <InputWithError
                            prependIcon={<i className="fa fa-tag"/>}
                            placeholder="User group name"
                            name="name"
                            value={name}
                            type="text"
                            onChange={setName}
                            errorObj={errors}
                        />
                        <div>
                            <DualSelect
                                selectedList={selectedUsers}
                                fullList={users}
                                onSelect={setSelectedUsers}
                                contentType="users"
                                hideGroups
                            />
                        </div>
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <SpinnerButton
                            type="modal"
                            color="primary"
                            onClick={() => createGroup(name, selectedUsers, toggle)}
                            submitting={submitting}
                            title="Create"
                        />
                    </ModalFooter>
                </div>
            }
        </Modal>
    );
};

export default CreateUserGroupModal;
