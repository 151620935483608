import React, {useState, useEffect} from 'react';

import {Modal, ModalBody, ModalFooter, Button} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import {editUserLabel} from 'utils/api/usersAPI';
import InputWithError from 'components/InputWithError';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const EditLabelModal = (props) => {
    const {isOpen, toggle, refreshLabels, handleError, item, addFlashMessage} = props;
    const [name, setName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        item && setName(item.name);
    }, [item]);

    const handleSubmit = async() => {
        try {
            setSubmitting(true);
            setErrors(null);
            let params = {
                name,
            };
            await editUserLabel(item.id, params);
            refreshLabels();
            addFlashMessage('success', 'User label successfully edited');
            setSubmitting(false);
            toggle();
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="create-label-modal">
            {!item ?
                <div/>
                :
                <div>
                    <ModalBodyWithClose toggle={toggle} className="pb-0">
                        <h2>Edit Label</h2>
                        <InputWithError
                            placeholder="Label name"
                            name="name"
                            value={name}
                            type="text"
                            onChange={setName}
                            errorObj={errors}
                            prependIcon={<i className="fa fa-tag"/>}
                            marginBottonClass="mb-3"
                        />
                    </ModalBodyWithClose>
                    <ModalFooter>
                        <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                        <SpinnerButton
                            type="modal"
                            color="primary"
                            onClick={handleSubmit}
                            submitting={submitting}
                            title="Update"
                        />
                    </ModalFooter>
                </div>
            }
        </Modal>
    );
};

export default EditLabelModal;
