import React from 'react';

const Label = ({label, removeFromSelected}) => {
    const {name} = label;
    return (
        <div className="relevance-label">
            {name} <div onClick={() => removeFromSelected(label)}>×</div>
        </div>
    );
};

export default Label;
