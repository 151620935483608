import React, {useEffect, useState, useContext} from 'react';
import {
    Row,
    Col,
    Button,
    Input,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
} from 'reactstrap';
import SpinnerButton from 'components/SpinnerButton';
import Logo from 'images/logo.png';
import './style.scss';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import {Link, useHistory} from 'react-router-dom';
import {login} from 'utils/api/authAPI';
// import LegacyDropdown from 'containers/Authentication/LgeacyDropdown';
import { createBrowserHistory } from 'history';
import {FilterContext} from 'contexts/FilterContext';

const SignIn = (props) => {
    let history = useHistory();
    const customHistory = createBrowserHistory();

    const {addFlashMessage} = useContext(FlashMessageContext);
    const {signIn, handleError} = useContext(AuthContext);
    const {resetAllFilters} = useContext(FilterContext);

    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const unauthenticatedUserCallback = async() => {
        addFlashMessage('danger', 'Your account type does not have access to the main application, please contact your company administrator.');
    };

    useEffect(() => {
        resetAllFilters();
    }, [])

    const onSubmit = async () => {
        setSubmitting(true);
        try {
            const res = await login(email, password);
            console.log(res)
            if(res.content?.area === 'admin') {
                signIn(res.content.token)
                history.push('/account');
                return;
            }
            let sampleError = {
                "message": "Email or password is invalid",
                "code": "USERNAME_INVALID",
                "errors": []
            }
            handleError(sampleError);
            setSubmitting(false);
        } catch(err) {
            handleError(err);
            setSubmitting(false);
        }
    };

    return (
        <div className="auth-block">
            <div className="auth-block__inner">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{marginBottom: '.5rem'}}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo}
                                         alt="Ozmosys"
                                         className="auth-block__image img-fluid"
                                    />
                                </a>
                            </div>
                            <div className="text-center">
                                <h2>Sign In</h2>
                                <p className="mb-4 mt-3 text-muted">Welcome back! Please sign in to continue</p>
                            </div>

                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>@</InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Email"
                                       name="email"
                                       value={email}
                                       type="email"
                                       autoComplete="email"
                                       onChange={(e) => setEmail(e.target.value)}
                                />
                            </InputGroup>

                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fa fa-lock"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder="Password"
                                       name="password"
                                       value={password}
                                       autoComplete="password"
                                       onChange={(e) => setPassword(e.target.value)}
                                       type="password"
                                />
                            </InputGroup>

                            <Row>
                                <Col xs="12" className="text-left">
                                    <SpinnerButton
                                        color="primary"
                                        block
                                        disabled={email.length < 1 || password.length < 1}
                                        onClick={onSubmit}
                                        submitting={submitting}
                                        title={'Login'}
                                    />
                                    <div className="mt-1">
                                        <Link to={'/forgot-password'}>Forgot password?</Link>
                                        {/*<span className="link-style" >Forgot password?</span>*/}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-center mt-2">
                            <span className="align-middle text-muted">Don't have an account?</span>
                            <Link to={'/signup'}><Button type="button" color="link">Sign up</Button></Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default SignIn;