import React from 'react';

import {FormGroup, Input, Label} from 'reactstrap';

const days = [
    {name: 'Mon', value: 1},
    {name: 'Tue', value: 2},
    {name: 'Wed', value: 3},
    {name: 'Thu', value: 4},
    {name: 'Fri', value: 5},
    {name: 'Sat', value: 6},
    {name: 'Sun', value: 7},
];

const ScheduleSelector = (props) => {
    const {
        selectedHour, selectedPeriod, selectedDays,
        handleHourSelect, handlePeriodSelect, position,
        errorMessage, handleDaySelect, errorObj, timezone
    } = props;

    return (
        <div className="schedule-selector">
            <strong>{position === 'first' ? 'First Notification' : 'Second Notification'}</strong>
            <div className="schedule-selector__days">
                <div>Day of week:</div>
                <FormGroup className="schedule-selector__day-select" check inline>
                    {days.map((day) => (
                        <FormGroup check inline key={day.value}>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={selectedDays.includes(day.value)}
                                    name={day.name}
                                    value={day.value}
                                    onChange={(e) => handleDaySelect(position, Number(e.target.value))}
                                />
                                {day.name}
                            </Label>
                        </FormGroup>
                    ))}
                </FormGroup>
            </div>
            {errorObj && !selectedDays.length &&
            <div className="mt-1 error-text">At least one day must be selected</div>
            }
            <div className="schedule-selector__time">
                <div>Select specific time:</div>
                <div className="schedule-selector__time-inputs">
                    <FormGroup>
                        <Input
                            onChange={e => handleHourSelect(e.target.value)}
                            value={selectedHour}
                            type="select"
                            name="selectedHour"
                        >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            onChange={(e) => handlePeriodSelect(e.target.value)}
                            value={selectedPeriod.toUpperCase()}
                            type="select"
                            name="selectedFirstPeriod"
                        >
                            <option value="AM">AM</option>
                            <option value="PM">PM</option>
                        </Input>
                    </FormGroup>
                </div>
                <div className="schedule-selector__timezone-label">
                    <i className="fa fa-globe"/> {timezone}
                </div>
            </div>
            <div className="error-text schedule__error mt-1">
                {errorObj && errorObj.errors.hasOwnProperty('sameSchedule') && <span>Alert schedule times must be unique</span>}
            </div>
            <div className="error-text schedule__error">
                {errorMessage}
            </div>
        </div>
    );
};

export default ScheduleSelector;
