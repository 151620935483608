import React, { useState, useEffect, useContext } from 'react';
import SearchForm from './SearchForm';
import TitleForm from './TitleForm';
import Preview from './Preview';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { addAlertLabel, addLegiScanBillAlert, checkPossibleToAddAlert, editLegiScanBillAlert, previewLegiScanBillAlert } from 'utils/api/alertsAPI';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import { AuthContext } from 'contexts/AuthContext';
import './style.scss';
import { FormGroup, Input, InputGroup, Row, Col, Label } from 'reactstrap';
import CharacterCount from 'components/CharacterCount';
import { states } from 'utils/states';
import InfoBlock from 'components/AlertRulesModal/Info';
import SelectAlertsModal from 'containers/Alerts/CreateAndEdit/InternalAlerts/Modals/SelectAlerts';
import SelectAlertGroupsModal from 'containers/Alerts/CreateAndEdit/BillsSearch/Modals/SelectAlertGroupsModal';
import RelevanceContainerLabels from 'components/Labels/RelevanceContainerLabels';
import SpinnerButton from 'components/SpinnerButton';

const BillsSearch = (props) => {
    let history = useHistory();
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError, state } = useContext(AuthContext);

    const { foundAlert, isEditForm, labels, internalGroup } = props;

    const [keywordValue, setKeywordValue] = useState('');
    const [titleValue, setTitleValue] = useState('');

    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(null);
    const [dropdownWidth, setDropdownWidth] = useState(152);
    const [selectedState, setSelectedState] = useState('');
    const [selectedAlertGroups, setSelectedAlertGroups] = useState([]);
    const [tempSelectedAlertGroups, setTempSelectedAlertGroups] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [alertGroupsModalOpen, setAlertGroupsModalOpen] = useState(false);
    const [creatingLabel, setCreatingLabel] = useState(false);
    const [newLabelToAdd, setNewLabelToAdd] = useState(null);

    useEffect(() => {
        const { isEditForm, foundAlert } = props;
        const getFoundAlertValues = () => {
            setKeywordValue(foundAlert.source.keyword);
            setTitleValue(foundAlert.name);
            // handleCreatePreview(foundAlert.source.url);
            setSelectedLabels(foundAlert.labels);
        };

        isEditForm && getFoundAlertValues();
    }, []);

    const resetForm = () => {
        // setPreview(null);
        // setTitleFormVisible(false);
        setTitleValue('');
        setKeywordValue('');
        setErrors(null);
    };

    const handleSubmit = async () => {
        const searchValues = {
            name: titleValue,
            keyword: keywordValue,
            alertLabels: selectedLabels.map((n) => n.id),
            alertGroups: selectedAlertGroups.map((n) => n.id),
            state: selectedState,
        };

        try {
            setSubmitting(true);
            if (props.isEditForm) {
                await editLegiScanBillAlert(props.foundAlert.id, searchValues);
                addFlashMessage('success', 'Alert successfully updated');
                history.push('/account/alerts');
            } else {
                let isPossibleToAdd = await checkPossibleToAddAlert();
                if (isPossibleToAdd) {
                    await addLegiScanBillAlert(searchValues);
                    setSubmitting(false);
                    addFlashMessage('success', 'Alert successfully created');
                    history.push('/account/alerts');
                } else {
                    addFlashMessage(
                        'danger',
                        "You've reached the limit of allowed number of alerts for your account.\n" +
                            'Contact Customer Care team for more information.'
                    );
                }
            }
        } catch (err) {
            setSubmitting(false);
            if (err.code === 'VALIDATION_FAILED' && err.message.slice(0, 17) === 'Alert with source') {
                let errObj = {
                    code: 'VALIDATION_FAILED',
                    errors: {
                        keyword: [
                            {
                                message: 'An alert with this keyword already exists.',
                                code: 'IS_BLANK_ERROR',
                                payload: null,
                            },
                        ],
                    },
                };
                handleError(errObj, setErrors);
            } else {
                handleError(err, setErrors);
            }
        }
    };

    useEffect(() => {
        if (newLabelToAdd) {
            const foundLabel = labels.find((label) => label.id === newLabelToAdd.id);
            foundLabel && addToSelected(foundLabel);
            setNewLabelToAdd(null);
        }
    }, [labels]);

    const addLabel = async (name) => {
        setCreatingLabel(true);
        try {
            const newLabel = await addAlertLabel({ name });
            setNewLabelToAdd(newLabel.content);
            await props.refreshLabels();
            setCreatingLabel(false);
        } catch (res) {
            if (res.errors.name[0].message) {
                addFlashMessage('danger', res.errors.name[0].message);
            }
            setCreatingLabel(false);
        }
    };

    const addToSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if (newSelectedLabels.indexOf(label) === -1) {
            newSelectedLabels.push(label);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const removeFromSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if (newSelectedLabels.indexOf(label) > -1) {
            newSelectedLabels.splice(newSelectedLabels.indexOf(label), 1);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const toggleAlertGroupsModal = () => {
        setAlertGroupsModalOpen((prevState) => !prevState);
    };

    const handleClearAlertGroups = () => {
        setSelectedAlertGroups([]);
        setTempSelectedAlertGroups([]);
    };

    // const onPressEnter = (e) => {
    //     if(e.key === 'Enter') {
    //         e.preventDefault();
    //         handleCreatePreview();
    //     }
    // };

    return (
        <div className="mb-3">
            <Row>
                <Col xs={12} md={12} className="mb-3">
                    <div className="internal-search-form ">
                        <InputGroup className="d-flex flex-column">
                            <Input
                                className="w-100"
                                value={keywordValue}
                                onChange={(e) => setKeywordValue(e.target.value)}
                                placeholder="Search Keyword"
                            />
                            <div className="mt-1" style={{ textAlign: 'left' }}>
                                <CharacterCount value={keywordValue} limit={2048} />
                            </div>
                        </InputGroup>
                        <FormGroup
                            className="internal-search-form__category"
                            style={{ flex: `0 0 ${dropdownWidth + 8 > 155 ? dropdownWidth + 8 : 155}px` }}>
                            <Input
                                type="select"
                                name="stateSelect"
                                id="stateSelect"
                                onChange={(e) => setSelectedState(e.target.value)}
                                value={selectedState}>
                                <>
                                    <option>All States</option>
                                    {states?.map((state, i) => (
                                        <option key={`state-${state}`} value={state}>
                                            {state}
                                        </option>
                                    ))}
                                </>
                            </Input>
                        </FormGroup>
                    </div>
                    {errors && errors.errors.hasOwnProperty('keyword') && (
                        <div className="mt-1 error-text">{errors.errors['keyword'][0].message || errors.errors['keyword']}</div>
                    )}
                </Col>
                {/*<Col xs={12}>*/}
                {/*    <div className="alert-form-utility">*/}
                {/*        <div className="alert-form-utility__inner">*/}
                {/*            <div className="form-group mb-0">*/}
                {/*                {selectedAlertGroups?.length > 0 ?*/}
                {/*                    <div className="alert-form-utility__select-alerts">*/}
                {/*                        <div className="alert-name-span"*/}
                {/*                             onClick={toggleAlertGroupsModal}>{selectedAlertGroups?.length} {selectedAlertGroups?.length > 1 ? 'Alerts' : 'Alert'}</div>*/}
                {/*                        <div className="closebtn" onClick={() => handleClearAlertGroups()}>*/}
                {/*                            <span>×</span></div>*/}
                {/*                    </div>*/}
                {/*                    :*/}
                {/*                    <div className="alert-form-utility__select-alerts" onClick={toggleAlertGroupsModal}>*/}
                {/*                    /!*<span>*!/*/}
                {/*                    /!*Select Alerts Groups <i className="fa fa-caret-down" style={{marginLeft: '.5rem'}}/>*!/*/}
                {/*                    /!*</span>*!/*/}
                {/*                    </div>*/}
                {/*                }*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*<InfoBlock/>*!/*/}
                {/*    </div>*/}
                {/*</Col>*/}
                {/*    <SelectAlertGroupsModal*/}
                {/*        isOpen={alertGroupsModalOpen}*/}
                {/*        toggle={toggleAlertGroupsModal}*/}
                {/*        selectedList={selectedAlertGroups}*/}
                {/*        setSelectedAlerts={setSelectedAlertGroups()}*/}
                {/*        // clearPreviewForm={clearPreviewForm}*/}
                {/*        // handleAlertSelectSubmit={handleAlertSelectSubmit}*/}
                {/*        // handleError={handleError}*/}
                {/*        // currentId={currentId}*/}
                {/*    />*/}
                <Col xs={12} md={12}>
                    <div className="internal-search-form">
                        <InputGroup className="internal-search-form__keyword">
                            <Input value={titleValue} onChange={(e) => setTitleValue(e.target.value)} placeholder="Title" />
                        </InputGroup>
                    </div>
                    {errors && errors.errors.hasOwnProperty('name') && (
                        <div className="mt-1 error-text">{errors.errors['name'][0].message || errors.errors['name']}</div>
                    )}
                </Col>
            </Row>
            {!state.isSingleUser && (
                <div class="mb-3">
                    <div className="mt-3 bills-form-label-container">
                        <div style={{ fontWeight: 500, marginBottom: '.5rem' }}>Labels</div>
                        {labels && (
                            <RelevanceContainerLabels
                                selectedLabels={selectedLabels}
                                labels={labels}
                                removeFromSelected={removeFromSelected}
                                addToSelected={addToSelected}
                                addLabel={addLabel}
                                creatingLabel={creatingLabel}
                                hideBottomMarginWithEmptyList
                                fullWidth
                            />
                        )}
                    </div>
                </div>
            )}
            <SpinnerButton
                color="primary"
                type="submit"
                className="px-4 mb-2"
                onClick={handleSubmit}
                submitting={submitting}
                title={isEditForm ? 'Save' : 'Create'}
            />
        </div>
    );
};

export default BillsSearch;
