import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from 'reactstrap';
import ListLabelDropdown from 'components/Labels/ListLabelDropdown';
import SearchInput from 'components/SearchInput/SearchInputWithSubmit';
import AlertListMobileFilterDropdown from 'containers/Alerts/AlertsList/AlertListMobileFilterDropdown';
import AlertListCsvButton from 'containers/Alerts/AlertsList/AlertListCsvButton';
import SourceLookup from 'components/SourceLookup/SourceLookup';

const AlertListHeader = (props) => {
    let history = useHistory();

    const {
        searchValue, setSearchValue, handleClearSearchValue, handleSearchValueSubmit,
        submittingSearch, allLabels, activeFilterLabels, handleActiveFilterLabelSelect,
        clearActiveLabels, downloadFullCsv, downloadFilteredCsv, fetchingCsv,
        handleMobileFilterSelect, activeMobileFilterValue
    } = props;

    const goToCreateAlert = () => {
        history.push(`/account/alerts/create`);
    };

    return (
        <div>
             {window.innerWidth > 1250 &&
                    <div className="list__utility-row list__utility-row--desktop">
                        <div className="d-flex">
                            <Button
                                onClick={goToCreateAlert}
                                color="primary"
                                className="mr-2"
                            >Create Search</Button>
                        </div>
                        <div className="list__search-container">
                            <SearchInput
                                value={searchValue}
                                onChange={setSearchValue}
                                placeholder="Filter by name or keyword"
                                onClear={handleClearSearchValue}
                                onSubmit={handleSearchValueSubmit}
                                extraParams={null}
                                submitting={submittingSearch}
                                list
                            />
                            <ListLabelDropdown
                                labels={allLabels}
                                activeFilterLabels={activeFilterLabels}
                                handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                            />
                            {activeFilterLabels.length > 0 &&
                            <div
                                className="ml-3">{activeFilterLabels.length + ' ' + ((activeFilterLabels.length === 1 ? 'label' : 'labels') + ' selected')}
                                <span className="list__utility-row__clear-labels-btn"
                                      onClick={clearActiveLabels}>×</span></div>
                            }
                        </div>
                        <div className="mr-2">
                            <SourceLookup/>
                        </div>
                        <div className="d-flex">
                            <AlertListCsvButton
                                downloadFullCsv={downloadFullCsv}
                                downloadFilteredCsv={downloadFilteredCsv}
                                downloading={fetchingCsv}
                                desktopSize
                            />
                        </div>
                    </div>
                    }
                    {1250 >= window.innerWidth && window.innerWidth > 624 &&
                    <div className="list__utility-row list__utility-row--tablet">
                        <div className="d-flex justify-content-between w-100">
                            <div className="list__search-container">
                                <SearchInput
                                    value={searchValue}
                                    onChange={setSearchValue}
                                    placeholder="Filter by name or keyword"
                                    onClear={handleClearSearchValue}
                                    onSubmit={handleSearchValueSubmit}
                                    extraParams={null}
                                    submitting={submittingSearch}
                                    list
                                />
                            </div>
                        </div>
                         <div className="d-flex justify-content-between mt-2 w-100">
                            <SourceLookup/>
                        </div>
                        <div className="d-flex mt-2 w-100 justify-content-end">
                            <div className="mr-2 d-flex">
                                <Button
                                    onClick={goToCreateAlert}
                                    color="primary"
                                    className="mb-0"
                                >
                                    <i className="fa fa-plus"/>&nbsp;
                                    Create Search
                                </Button>
                            </div>
                            <div className="mr-2">
                                <ListLabelDropdown
                                    labels={allLabels}
                                    activeFilterLabels={activeFilterLabels}
                                    handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                                    useIconOnly
                                />
                            </div>
                            <div>
                                <AlertListCsvButton
                                    downloadFullCsv={downloadFullCsv}
                                    downloadFilteredCsv={downloadFilteredCsv}
                                    downloading={fetchingCsv}
                                />
                            </div>
                            <AlertListMobileFilterDropdown
                                handleMobileFilterSelect={handleMobileFilterSelect}
                                activeMobileFilterValue={activeMobileFilterValue}
                            />
                        </div>
                    </div>
                    }
                    {624 >= window.innerWidth &&
                    <div className="list-utility-row list__utility-row--mobile">
                        <div className="d-flex justify-content-between mb-2 w-100">
                            <SourceLookup/>
                        </div>
                        <div className="d-flex mb-2">
                            <AlertListCsvButton
                                downloadFullCsv={downloadFullCsv}
                                downloadFilteredCsv={downloadFilteredCsv}
                                downloading={fetchingCsv}
                                showText
                            />
                            <Button
                                onClick={goToCreateAlert}
                                color="primary"
                                className="ml-1"
                                style={{flex: '0 0 50%'}}
                            >
                                <i className="fa fa-plus"/>&nbsp;
                                Create Search
                            </Button>
                        </div>
                        <div className="d-flex mb-2">
                            <AlertListMobileFilterDropdown
                                handleMobileFilterSelect={handleMobileFilterSelect}
                                activeMobileFilterValue={activeMobileFilterValue}
                            />
                            <div className="ml-2">
                                <ListLabelDropdown
                                    labels={allLabels}
                                    activeFilterLabels={activeFilterLabels}
                                    handleActiveFilterLabelSelect={handleActiveFilterLabelSelect}
                                    useIconOnly
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <SearchInput
                                value={searchValue}
                                onChange={setSearchValue}
                                placeholder="Filter by name or keyword"
                                onClear={handleClearSearchValue}
                                onSubmit={handleSearchValueSubmit}
                                extraParams={null}
                                submitting={submittingSearch}
                                list
                            />
                            {activeFilterLabels.length > 0 &&
                            <div className="list__utility-row__clear-labels-mobile">
                                <span>
                                    {activeFilterLabels.length + ' ' + ((activeFilterLabels.length === 1 ? 'label' : 'labels') + ' selected')}
                                    <span
                                        onClick={clearActiveLabels}
                                        className="list__utility-row__clear-labels-btn">×</span>
                                </span>
                            </div>
                            }
                        </div>
                    </div>
                    }
        </div>
    );
};

export default AlertListHeader;
