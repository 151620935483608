import React, {useState, useContext, useEffect} from 'react';

import {useHistory, useParams} from 'react-router-dom';

import {isEmpty} from 'lodash';
import moment from 'moment-timezone';

import General from './General';
import EmailSchedule from './EmailSchedule';
import AssignAlerts from './AssignAlerts';
import {Col, Row} from 'reactstrap';
import TabBar from 'components/TabBar';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {
    updateUserById,
    updateUserAlertsById,
    addUserLabel,
    addUserSchedule,
    getUserById,
    getUserLabels,
    getUserAlertsById, deleteUserSchedule, editUserSchedule,
} from 'utils/api/usersAPI';
import SpinnerButton from 'components/SpinnerButton';
import LoadingSpinner from 'components/LoadingSpinner';
import {AuthContext} from 'contexts/AuthContext';
import './style.scss';
import {timezones} from 'utils/timezones';

const EditUser = (props) => {
    let history = useHistory();
    let {id} = useParams();

    const {addFlashMessage} = useContext(FlashMessageContext);
    const {state, handleError} = useContext(AuthContext);

    const [activeView, setActiveView] = useState('general');
    const [timezone, setTimezone] = useState('');
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [sendConfirmation, setSendConfirmation] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [allLabels, setAllLabels] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [creatingLabel, setCreatingLabel] = useState(false);
    const [newLabelToAdd, setNewLabelToAdd] = useState(null);
    const [useCustomSchedule, setUseCustomSchedule] = useState(false);
    const [frequency, setFrequency] = useState('once');
    const [firstAlertDays, setFirstAlertDays] = useState([]);
    const [firstAlertHour, setFirstAlertHour] = useState('9');
    const [firstAlertPeriod, setFirstAlertPeriod] = useState('AM');
    const [secondAlertDays, setSecondAlertDays] = useState([]);
    const [secondAlertHour, setSecondAlertHour] = useState('5');
    const [secondAlertPeriod, setSecondAlertPeriod] = useState('PM');
    const [fetchingScheduleData, setFetchingScheduleData] = useState(true);
    const [fetchingUserData, setFetchingUserData] = useState(true);
    const [fetchingAlertData, setFetchingAlertData] = useState(true);
    const [changeEmail, setChangeEmail] = useState(false);
    const [foundUser, setFoundUser] = useState(null);
    const [newEmail, setNewEmail] = useState('');
    const [disableAdminSelection, setDisableAdminSelection] = useState(false);
    const [errors, setErrors] = useState(false);
    const [timezoneDisplay, setTimezoneDisplay] = useState(false);

    useEffect(() => {
        fetchLabels();
    }, []);

    useEffect(() => {
        id && !isEmpty(state.profile) && fetchUserData(id);
    }, [id, state.profile]);

    const fetchLabels = async() => {
        try {
            const labels = await getUserLabels();
            setAllLabels(labels);
        } catch(err) {
            console.log(err);
            addFlashMessage('danger', 'Unable to fetch label data');
        }
    };

    const fetchUserData = async(id) => {
        try {
            let userData = await getUserById(id);
            setFoundUser(userData);
            setTimezone(userData.timezone ? userData.timezone : state.profile.organization.timezone);
            setFullName(userData.name);
            setEmail(userData.email);
            setIsAdmin(userData.roles[0] === 'ROLE_ADMIN');
            setIsSuspended(userData.suspendSubscriber);
            if(state.profile.email === userData.email) {
                setDisableAdminSelection(true);
            }
            setSelectedLabels(userData.labels);

            if(userData.schedules.length > 0) {
                setUseCustomSchedule(true);
                setFirstAlertDays(userData.schedules[0].days);
                destructureTime(userData.schedules[0].time, 'first');
            }
            if(userData.schedules.length > 1) {
                setFrequency('twice');
                setSecondAlertDays(userData.schedules[1].days);
                destructureTime(userData.schedules[1].time, 'second');
            }
            // setSelectedAlerts(userAlerts.result);
            setFetchingUserData(false);
            setFetchingScheduleData(false);
        } catch(err) {
            addFlashMessage('danger', 'Unable to fetch user data');
            setFetchingUserData(false);
        }
    };

    const destructureTime = (time, schedule) => {
        let hour = moment(time).utc().format('h');
        let period = moment(time).utc().format('A');
        if(schedule === 'first') {
            setFirstAlertPeriod(period);
            setFirstAlertHour(hour);
        } else {
            setSecondAlertPeriod(period);
            setSecondAlertHour(hour);
        }
    };

    useEffect(() => {
        let foundTimezone = timezones.find(obj => {
            return obj.key === timezone;
        });
        foundTimezone && setTimezoneDisplay(foundTimezone.name);
    }, [timezone]);

    const fetchUserAlertData = async(id) => {
        try {
            // let userData = await getUserById(id);
            let userAlerts = await getUserAlertsById(id);
            setSelectedAlerts(userAlerts.result);
            setFetchingAlertData(false)
        } catch(err) {
            addFlashMessage('danger', 'Unable to fetch alert data');
            setFetchingAlertData(false);
        }
    };

    useEffect(() => {
        foundUser && foundUser.confirmed && fetchUserAlertData(id);
    }, [foundUser])

    const handleActiveView = () => {
        switch(activeView) {
            case 'general':
                return (
                    <General
                        selectedTimezone={timezone}
                        setTimezone={setTimezone}
                        allLabels={allLabels}
                        selectedLabels={selectedLabels}
                        addToSelected={addToSelected}
                        removeFromSelected={removeFromSelected}
                        addLabel={addLabel}
                        creatingLabel={creatingLabel}
                        sendConfirmation={sendConfirmation}
                        setSendConfirmation={setSendConfirmation}
                        isAdmin={isAdmin}
                        setIsAdmin={setIsAdmin}
                        fullName={fullName}
                        setFullName={setFullName}
                        email={email}
                        setEmail={setEmail}
                        changeEmail={changeEmail}
                        setChangeEmail={setChangeEmail}
                        isEditForm
                        hide
                        disableAdminSelection={disableAdminSelection}
                        errors={errors}
                        newEmail={newEmail}
                        setNewEmail={setNewEmail}
                        isSuspended={isSuspended}
                        setIsSuspended={setIsSuspended}
                        isSingleUser={state.isSingleUser}
                    />
                );
            case 'emailSchedule':
                return (
                    <EmailSchedule
                        useCustomSchedule={useCustomSchedule}
                        frequency={frequency}
                        setFrequency={setFrequency}
                        firstAlertDays={firstAlertDays}
                        firstAlertHour={firstAlertHour}
                        firstAlertPeriod={firstAlertPeriod}
                        secondAlertDays={secondAlertDays}
                        secondAlertHour={secondAlertHour}
                        secondAlertPeriod={secondAlertPeriod}
                        fetchingScheduleData={fetchingScheduleData}
                        setFirstAlertPeriod={setFirstAlertPeriod}
                        setFirstAlertHour={setFirstAlertHour}
                        setSecondAlertPeriod={setSecondAlertPeriod}
                        setSecondAlertHour={setSecondAlertHour}
                        setFirstAlertDays={setFirstAlertDays}
                        setSecondAlertDays={setSecondAlertDays}
                        setUseCustomSchedule={setUseCustomSchedule}
                        isEditForm
                        errors={errors}
                        timezoneDisplay={timezoneDisplay}
                    />
                );
            case 'assignAlerts':
                return (
                    <AssignAlerts
                        addFlashMessage={addFlashMessage}
                        selectedAlerts={selectedAlerts}
                        setSelectedAlerts={setSelectedAlerts}
                        isEditForm
                        fetchingSelectedAlerts={fetchingAlertData}
                    />
                );
            default:
                return null;
        }
    };

    const handleSubmit = async() => {
        if(state.profile.email === foundUser.email && (isAdmin !== foundUser.enabled)) {
            addFlashMessage('danger', 'You cannot change administrator status for your own account');
            return;
        }
        if(frequency === 'twice' &&
            (firstAlertHour === secondAlertHour) &&
            (firstAlertPeriod === secondAlertPeriod)
        ) {
            let customError = {
                code: "VALIDATION_FAILED",
                errors: {sameSchedule: [{message: "Alert schedule times must be unique", code: "IS_BLANK_ERROR", payload: null}]},
                message: "Validation failed"
            }
            handleError(customError, setErrors);
            return;
        }
        try {
            setSubmitting(true);
            setErrors(null);
            const params = {
                email,
                plannedEmail: changeEmail ? newEmail : null,
                targetUrl: window.location.protocol + '//' + window.location.host + '/invite',
                fullName,
                admin: isAdmin,
                suspend: isSuspended,
                withoutEmailSend: !sendConfirmation,
                timezone,
                // alerts: selectedAlerts.map(alert => alert.id),
                userLabels: selectedLabels.map(label => label.id),
            };

            const firstScheduleParams = {
                time: moment(firstAlertHour + firstAlertPeriod, ['h:mmA']).format('HH:mm'),
                days: firstAlertDays.sort(),
            };
            const secondScheduleParams = frequency === 'twice' ? {
                time: moment(secondAlertHour + secondAlertPeriod, ['hh:mm A']).format('HH:mm'),
                days: secondAlertDays.sort(),
            } : null;

            await updateUserById(id, params);
            await updateUserAlertsById(id, {alerts: selectedAlerts.map(alert => alert.id)})

            if(!useCustomSchedule) {
                if(foundUser.schedules.length === 2) {
                    await deleteUserSchedule(id, foundUser.schedules[1].id);
                    await deleteUserSchedule(id, foundUser.schedules[0].id);
                } else if (foundUser.schedules.length === 1) {
                    await deleteUserSchedule(id, foundUser.schedules[0].id);
                }
            } else {
                if(foundUser.schedules.length === 2) {
                    if(frequency === 'twice') {
                        await editUserSchedule(id, foundUser.schedules[1].id, secondScheduleParams);
                        await editUserSchedule(id, foundUser.schedules[0].id, firstScheduleParams);
                    } else {
                        await deleteUserSchedule(id, foundUser.schedules[1].id);
                        await editUserSchedule(id, foundUser.schedules[0].id, firstScheduleParams);
                    }
                } else if(foundUser.schedules.length === 1) {
                    if(frequency === 'twice') {
                        await editUserSchedule(id, foundUser.schedules[0].id, firstScheduleParams);
                        await addUserSchedule(id, secondScheduleParams);
                    } else {
                        await editUserSchedule(id, foundUser.schedules[0].id, firstScheduleParams);
                    }
                } else {
                    await addUserSchedule(foundUser.id, firstScheduleParams);
                    frequency === 'twice' && await addUserSchedule(foundUser.id, secondScheduleParams);
                }
            }

            addFlashMessage('success', 'User successfully updated');
            history.push('/account/users');
        } catch(err) {
            setSubmitting(false);
            handleError(err, setErrors);
        }
    };

    useEffect(() => {
        if(newLabelToAdd) {
            const foundLabel = allLabels.find(label => label.id === newLabelToAdd.id);
            foundLabel && addToSelected(foundLabel);
            setNewLabelToAdd(null);
        }
    }, [allLabels]);

    const addLabel = async(name) => {
        setCreatingLabel(true);
        try {
            const newLabel = await addUserLabel({name});
            setNewLabelToAdd(newLabel.content);
            await fetchLabels();
            setCreatingLabel(false);
        } catch(res) {
            if(res.errors.name[0].message) {
                addFlashMessage('danger', res.errors.name[0].message);
            }
            setCreatingLabel(false);
        }
    };

    const addToSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if(newSelectedLabels.indexOf(label) === -1) {
            newSelectedLabels.push(label);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const removeFromSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if(newSelectedLabels.indexOf(label) > -1) {
            newSelectedLabels.splice(newSelectedLabels.indexOf(label), 1);
            setSelectedLabels(newSelectedLabels);
        }
    };

    if(fetchingUserData) return <LoadingSpinner padding text="Fetching user data"/>;
    if(!fetchingUserData && !foundUser) return <div>Unable to retrieve user data. Please try again later</div>;
    return (
        <div className="animated fadeIn view create-edit-user">
            <Row className="mt-3">
                <Col xs="12" className="col-no-padding-mobile">
                    <div className="add-user">
                        <h1>Edit User</h1>
                        <h5 className="add-user" style={{color: '#656565'}}>{foundUser.email}</h5>
                        {foundUser && !foundUser.confirmed && (
                            <div className="mt-6 mb-3">
                                <div className="error-block d-flex">
                                    <i className="fa fa-exclamation-circle"/>
                                    <div>This user has not confirmed their account and cannot be edited at this time.</div>
                                </div>
                                {/*<h5 style={{color: 'red'}}>User awaiting confirmation</h5>*/}
                            </div>
                        )}
                    </div>
                    {foundUser.confirmed &&
                    <div>
                        <TabBar
                            activeItem={activeView}
                            setActive={setActiveView}
                            errorObj={errors}
                            shrink
                            items={
                                [
                                    {data: 'general', name: 'General'},
                                    {data: 'assignAlerts', name: 'Assign Content'},
                                    {data: 'emailSchedule', name: 'Schedule'},
                                ]
                            }
                        />
                        {handleActiveView()}
                    </div>
                    }
                </Col>
                {foundUser.confirmed &&
                <Col className={'col-no-padding-mobile ' + (window.innerWidth < 450 ? 'mt-2' : 'mt-4')}>
                    <div className="d-flex justify-content-end">
                        <SpinnerButton
                            submitting={submitting}
                            onClick={handleSubmit}
                            color="primary"
                            title="Save Changes"
                            block={window.innerWidth < 450}
                        />
                    </div>
                </Col>
                }
            </Row>
        </div>
    );
};

export default EditUser;
