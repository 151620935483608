import React, {useContext, useState, useEffect} from 'react';

import {AuthContext} from 'contexts/AuthContext';
import {FlashMessageContext} from 'contexts/FlashMessageContext';

import SingleUserBlock from 'components/SingleUserBlock';
import AlertGroupsList from './List';
import EmptyList from './EmptyList';
import LoadingSpinner from 'components/LoadingSpinner';
import {getAlertGroups, deleteAlertGroup, universalAssignAlerts} from 'utils/api/alertsAPI';
import './style.scss';
import {FilterContext} from 'contexts/FilterContext';
import CreateAlertGroupModal from 'containers/Alerts/AlertGroups/Modals/CreateAlertGroup';

const AlertGroups = () => {
    const {state, handleError} = useContext(AuthContext);
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {alertGroupFilters, setFiltersObject} = useContext(FilterContext);
    const [alertGroups, setAlertGroups] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        !state.isSingleUser && fetchAlertGroups();
    }, []);

    const fetchAlertGroups = async() => {
        try {
            // setFetchingData(true);
            const alertGroupData = await getAlertGroups();
            setAlertGroups(alertGroupData);
            setFetchingData(false);
        } catch(err) {
            setFetchingData(false);
            handleError(err, null, addFlashMessage);
        }
    };

    const deleteGroup = async(id, modalCallback) => {
        try {
            setSubmitting(true)
            await deleteAlertGroup(id);
            await fetchAlertGroups();
            addFlashMessage('success', 'Alert group successfully deleted');
            setSubmitting(false);
            modalCallback && modalCallback();
        } catch(err) {
            setSubmitting(false);
            handleError(err, null, addFlashMessage);
        }
    }

    const handleAssignAlertGroup = async(alertGroup, selectedUsers, modalCallback) => {
        try {
            setSubmitting(true)
            let params = {
                alerts: alertGroup.alerts.map(alert => alert.id),
                users: selectedUsers.map(user => user.id)
            };
            await universalAssignAlerts(params)
            await fetchAlertGroups()
            addFlashMessage('success', 'Alert group successfully assigned');
            setSubmitting(false);
            modalCallback();
        } catch(err) {
            setSubmitting(false);
            handleError(err, null, addFlashMessage);
        }
    }

    return (
        <div>
            {state.isSingleUser ?
                <SingleUserBlock itemType={'alert groups'}/>
                :
                fetchingData ?
                    <LoadingSpinner/>
                    :
                    alertGroups.length > 0 ?
                        <AlertGroupsList
                            alertGroups={alertGroups}
                            fetchingData={fetchingData}
                            deleteGroup={deleteGroup}
                            submitting={submitting}
                            addFlashMessage={addFlashMessage}
                            handleAssignAlertGroup={handleAssignAlertGroup}
                            refreshAlertGroups={fetchAlertGroups}
                            handleError={handleError}
                            alertGroupFilters={alertGroupFilters}
                            setFiltersObject={setFiltersObject}
                        />
                        :
                        <EmptyList
                            submitting={submitting}
                            addFlashMessage={addFlashMessage}
                            refreshAlertGroups={fetchAlertGroups}
                            handleError={handleError}
                        />
            }
        </div>
    );
};

export default AlertGroups;
