import React, {useEffect, useState, useContext} from 'react';
import {
    Row,
    Col,
    Button,
    Input,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
} from 'reactstrap';
import SpinnerButton from 'components/SpinnerButton';
import Logo from 'images/logo.png';
import './style.scss';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import {Link, useHistory, useParams} from 'react-router-dom';
import {login, resetPasswordRequest} from 'utils/api/authAPI';
import LegacyDropdown from 'containers/Authentication/LgeacyDropdown';
import {createBrowserHistory} from 'history';
import InputWithError from 'components/InputWithError';

const ForgotPassword = ({isFromReader}) => {
    let history = useHistory();
    const customHistory = createBrowserHistory();

    const {addFlashMessage} = useContext(FlashMessageContext);
    const {signIn, handleError} = useContext(AuthContext);

    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [errors, setErrors] = useState(null);

    const unauthenticatedUserCallback = async() => {
        addFlashMessage('danger', 'Your account type does not have access to the main application, please contact your company administrator.');
    };

    const onSubmit = async() => {
        setSubmitting(true);
        try {
            const params = {
                email,
                targetUrl: `${window.location.origin}${isFromReader ? '/company/reader' : ''}/reset-password`,
            };
            const res = await resetPasswordRequest(params);
            setEmailSent(true);
        } catch(err) {
            setSubmitting(false);
            if(err.code === 'VALIDATION_FAILED') {
                let error = {
                    code: "VALIDATION_FAILED",
                    errors: {
                        email: [{
                            message: "Invalid email address",
                            code: "IS_BLANK_ERROR",
                            payload: null
                        }]
                    },
                    message: "Validation failed"
                }
                handleError(error, setErrors);
            } else {
                handleError(err, setErrors);
            }
        }
    };

    return (
        <div className="auth-block">
            <div className="auth-block__inner">
                <div className="animated fadeIn">
                    <Row className="justify-content-center mt-5" style={{marginBottom: '.5rem'}}>
                        <Col md={9}>
                            <div className="auth-block__image-container">
                                <a href="/">
                                    <img src={Logo}
                                         alt="Ozmosys"
                                         className="auth-block__image img-fluid"
                                    />
                                </a>
                            </div>

                            {emailSent ?
                                <div>
                                    <div className="text-center">
                                        <h2>Check your Email</h2>
                                        <p className="mb-4 mt-3 text-muted">An email containing a password reset link
                                            was sent to the email you provided. Please click the link in the email and
                                            follow the instructions provided.</p>
                                        {isFromReader ?
                                            <Link to="/company/reader">
                                                <Button block color="primary">Return to Reader</Button>
                                            </Link>
                                            :
                                            <Link to="/signin">
                                                <Button block color="primary">Return to Login</Button>
                                            </Link>
                                        }
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="text-center">
                                        <h2>Reset Password</h2>
                                        <p className="mb-4 mt-3 text-muted">Enter your email address and we'll send a link to reset your password.</p>
                                    </div>

                                    {/*<InputGroup>*/}
                                    {/*    <InputGroupAddon addonType="prepend">*/}
                                    {/*        <InputGroupText>@</InputGroupText>*/}
                                    {/*    </InputGroupAddon>*/}
                                    {/*    <Input placeholder="Email"*/}
                                    {/*           name="email"*/}
                                    {/*           value={email}*/}
                                    {/*           type="email"*/}
                                    {/*           onChange={(e) => setEmail(e.target.value)}*/}
                                    {/*    />*/}
                                    {/*</InputGroup>*/}

                                    <InputWithError
                                        prependIcon={'@'}
                                        errorObj={errors}
                                        placeholder="Email"
                                        name="email"
                                        value={email}
                                        type="email"
                                        onChange={setEmail}
                                        marginBottomClass="mb-3"
                                    />

                                    <Row>
                                        <Col xs="12" className="text-left">
                                            <SpinnerButton
                                                color="primary"
                                                block
                                                disabled={email.length < 1}
                                                onClick={onSubmit}
                                                submitting={submitting}
                                                title="Send Link"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </Col>
                    </Row>

                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;