import React from 'react';

const EmailToRssForm = () => {
    return (
        <div className="mb-6">
            <iframe src="https://ozkaas.com/" allow="clipboard-read; clipboard-write" height="600px" frameBorder="0" />;
        </div>
    );
};

export default EmailToRssForm;
