import React from 'react';
import {FormGroup, Input} from 'reactstrap';

const UserLabelsMobileFilterDropdown = ({activeMobileFilterValue, handleMobileFilterSelect}) => {
    return (
        <div className="list__mobile-select">
            <FormGroup className="m-0">
                <Input
                    onChange={e => handleMobileFilterSelect(e.target.value)}
                    value={activeMobileFilterValue}
                    type="select"
                    name="userLabelsMobileFilters"
                >
                    <option value="name-asc">Name(A-Z)</option>
                    <option value="name-desc">Name(Z-A)</option>
                    <option value="createdAt-asc">Created At(Ascending)</option>
                    <option value="createdAt-desc">Created At(Descending)</option>
                </Input>
            </FormGroup>
        </div>
    );
};

export default UserLabelsMobileFilterDropdown;
