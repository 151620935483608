import React, {useState} from 'react';
import {Table} from 'reactstrap';
import PaginationWrapper from 'components/PaginationWrapper';

const Grid = (props) => {
    const {items, type} = props;
    const [activePage, setActivePage] = useState(1);

    const handlePageClick = (pageNum) => {
        console.log(pageNum);
    };

    const renderName = (item) => {
        switch(type) {
            case 'alert':
                return item.name;
            case 'article':
                return (
                    <a href={item.url}
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                        {item.title}
                    </a>
                );
            case 'publisher':
                return item.domain;
            default:
                return item.name;
        }
    };

    const renderHeader = () => {
        switch(type) {
            case 'article':
                return 'Articles';
            case 'alert':
                return 'Sources/Searches';
            case 'publisher':
                return 'Publishers';
            default:
                return 'Articles';
        }
    };

    return (
        <div className="grid-container">
            <Table striped bordered className="data-grid">
                <thead>
                <tr>
                    <th>Top {renderHeader()} (Last 30 Days)</th>
                    <th>Clicks</th>
                </tr>
                </thead>
                <tbody>
                {activePage === 1 &&
                items.slice(0, 5).map(i => {
                    return (
                        <tr key={Math.max(Math.random() * 100)}>
                            <td>
                                <div className="td-limit-height">
                                    {renderName(i)}
                                </div>
                            </td>
                            <td>{i.cnt}</td>
                        </tr>
                    );
                })
                }
                </tbody>
            </Table>
            {items.length > 5 &&
            <PaginationWrapper
                totalPages={Math.floor(items / 5)}
                handlePageClick={handlePageClick}
            />
            }
        </div>
    );
};


export default Grid;
