import React, {useState, useEffect, useContext} from 'react';

import SearchInput from 'components/SearchInput';

import {updateUserAlertsById} from 'utils/api/usersAPI';
import {manageReaderAlerts} from 'utils/api/alertsAPI';
import Alert from './Alert';
import {filterByValue} from 'Helpers/filterByValue';
import AssignAlertGroupModal from 'containers/Alerts/AlertGroups/Modals/AssignAlertGroup';
import ManageAlertsModal from './Modals/ManageAlertsModal';
import PublicModalSignIn from 'containers/Reader/Desktop/AlertList/Modals/PublicModalSignIn';
import {AuthContext} from 'contexts/AuthContext';

const AlertList = (props) => {
    const {
        alerts, handleAlertSelect, fetchingAlertContent,
        tagData, selectedAlert, handleTagSelect, selectedTag,
        userId, refreshAlerts, addFlashMessage, setFetchingAlerts,
        allowManageAlertsInReader, isPublic, state, publicEmail
    } = props;
    const [searchValue, setSearchValue] = useState('');
    const [visibleAlerts, setVisibleAlerts] = useState([]);
    const [manageAlertsModalOpen, setManageAlertsModalOpen] = useState(false);
    const [signInModalOpen, setSignInModalOpen] = useState(false);
    const [updatingAlerts, setUpdatingAlerts] = useState(false);
    const {logout} = useContext(AuthContext);

    useEffect(() => {
        setVisibleAlerts(alerts);
    }, [alerts]);

    useEffect(() => {
        let newList = filterByValue(alerts, 'name', searchValue);
        setVisibleAlerts(newList);
    }, [searchValue]);

    const handleManageAlertsSubmit = async(selectedAlerts, modalCallback) => {
        try {
            setUpdatingAlerts(true);
            const params = {
                alerts: selectedAlerts.map(alert => alert.id),
            };
            isPublic ? await manageReaderAlerts(params) : await updateUserAlertsById(userId, params);
            refreshAlerts('initialLoad');
            modalCallback();
            setUpdatingAlerts(false);
        } catch(err) {
            addFlashMessage('danger', 'Unable to update alerts at this time');
            setUpdatingAlerts(false);
        }
    };

    const toggleManageAlertsModal = () => {
        setManageAlertsModalOpen(!manageAlertsModalOpen);
    };

    const toggleSignInModal = () => {
        setSignInModalOpen(!signInModalOpen);
    };

    const handleAlertModalButton = () => {
        if(state.isAuthenticated || state.isReaderProfileAuthenticated) {
            toggleManageAlertsModal();
        } else {
            toggleSignInModal();
        }
    }

    const handleLogout = () => {
        logout();
        window.location.reload()
    };

    return (
        <div className="reader-column reader-alert-list">
            <div className="reader-column__wrapper">
                <div className="reader-column__header reader-alert-list__header">
                    <span>My Sources</span>
                    {allowManageAlertsInReader &&
                    <i className="fa fa-plus" onClick={handleAlertModalButton}/>
                    }
                </div>
                <div className="reader-column__searchbar reader-alert-list__searchbar">
                    <SearchInput
                        placeholder="Search by name"
                        value={searchValue}
                        classNames={['reader-column__input reader-alert-list__input']}
                        iconClassNames={['reader-alert-list__searchbar-icon']}
                        closeClassNames={['reader-alert-list__searchbar-icon']}
                        onChange={setSearchValue}
                        onClear={() => setSearchValue('')}
                    />
                </div>
                <div className="reader-column__body reader-alert-list__body">
                    {visibleAlerts.length < 1 ?
                        <div style={{padding: '0.375rem 0.75rem'}}>No alerts found</div>
                        :
                        visibleAlerts.map(alert => {
                            return (
                                <Alert
                                    alert={alert}
                                    key={alert.id}
                                    tagData={tagData}
                                    handleAlertSelect={handleAlertSelect}
                                    fetchingAlertContent={fetchingAlertContent}
                                    selectedAlert={selectedAlert}
                                    handleTagSelect={handleTagSelect}
                                    selectedTag={selectedTag}
                                />
                            );
                        })
                    }
                </div>
                <div className="reader-column__footer reader-alert-list__footer">
                    {visibleAlerts.length} {visibleAlerts.length === 1 ? 'Item' : 'Items'}
                </div>
                <div className="reader-column__footer">
                    <i 
                        className={'fa fa-lock'} 
                        style={{cursor: 'pointer'}} 
                        onClick={() => handleLogout()}
                    />
                </div>
            </div>
            {manageAlertsModalOpen &&
            <ManageAlertsModal
                isOpen={manageAlertsModalOpen}
                onSubmit={handleManageAlertsSubmit}
                toggle={toggleManageAlertsModal}
                submitting={updatingAlerts}
                addFlashMessage={addFlashMessage}
                assignedAlerts={alerts}
                isPublic={isPublic}
                state={state}
            />
            }
            {signInModalOpen &&
            <PublicModalSignIn
                isOpen={signInModalOpen}
                // onSubmit={handleManageAlertsSubmit}
                toggle={toggleSignInModal}
                // submitting={updatingAlerts}
                // addFlashMessage={addFlashMessage}
                publicEmail={publicEmail}
            />
            }
        </div>
    );
};

export default AlertList;
