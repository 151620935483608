import React, {useContext, useState, useEffect} from 'react';

import {AuthContext} from 'contexts/AuthContext';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {FilterContext} from 'contexts/FilterContext';

import SingleUserBlock from 'components/SingleUserBlock';
import {getAlertLabels, addAlertLabel, editAlertLabel, deleteAlertLabel} from 'utils/api/alertsAPI';
import LabelsList from './List';
import EmptyList from './EmptyList';
import LoadingSpinner from 'components/LoadingSpinner';

const Labels = () => {
    const {state, handleError} = useContext(AuthContext);
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {setFiltersObject, alertLabelFilters} = useContext(FilterContext);
    const [labels, setLabels] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);

    useEffect(() => {
        !state.isSingleUser && fetchLabels();
    }, []);

    const fetchLabels = async() => {
        try {
            const labelData = await getAlertLabels();
            setLabels(labelData);
            setFetchingData(false);
        } catch(err) {
            console.log(err);
            setFetchingData(false);
            addFlashMessage('danger', 'Unable to fetch labels');
        }
    };

    return (
        <div>
            {state.isSingleUser ?
                <SingleUserBlock itemType="labels"/>
                :
                fetchingData ?
                    <LoadingSpinner/>
                    :
                    labels.length > 0 ?
                        <LabelsList
                            labels={labels}
                            fetchingData={fetchingData}
                            refreshLabels={fetchLabels}
                            addFlashMessage={addFlashMessage}
                            handleError={handleError}
                            setFiltersObject={setFiltersObject}
                            alertLabelFilters={alertLabelFilters}
                        />
                        :
                        <EmptyList
                            fetchingData={fetchingData}
                            refreshLabels={fetchLabels}
                            addFlashMessage={addFlashMessage}
                            handleError={handleError}
                            setFiltersObject={setFiltersObject}
                            alertLabelFilters={alertLabelFilters}
                        />
            }
        </div>
    );
};

export default Labels;
