import React from 'react';
import {convertDateString} from 'Helpers/convertDateString';
import RSSLogo from 'images/rss-icon.png';
import RSSLexisNexisLogo from 'images/rssnllogo3.png';
import BlueLogo from 'images/ozmosys_blue_cropped.png';
import OpenEnvelope from 'images/open-envelope.svg';
import GroupLogo from 'images/group-logo-cropped3.png';

const Source = (props) => {
    const {source, assignAlertToSection, activeQuickAddSection} = props;

    const handleAlertTypeIcon = (sourceType) => {
        switch(sourceType) {
            case 'rss':
                return <img height={16} src={RSSLogo} alt="rss-logo"/>;
            case 'google':
                return <i style={{fontSize: '.8rem'}} className="google-icon fa fa-google "/>;
            case 'internal':
                return <img height={16} src={BlueLogo} alt="internal-logo"/>;
            case 'email_publication':
                return <img height={16} src={OpenEnvelope} alt="email-logo"/>;
            case 'company_based':
                return <i style={{fontSize: '.8rem'}} className="fa fa-building "/>;
            default:
                return null;
        }
    };

    return (
        <div className="newsletter-source">
            <div className="newsletter-source__block-1">
                <div className="newsletter-source__title d-flex">
                    <div className="newsletter-source__icon">
                        {handleAlertTypeIcon(source.type)}
                    </div>
                    {/*<span><a href={source.url} target="_blank">{source.name}</а></span>*/}
                    <span>
                        {source.name}
                    </span>
                </div>
                <div className="newsletter-source__date">
                    {source.content_updated_at ?
                        convertDateString(source.content_updated_at)
                        :
                        'Pending updates'
                    }
                </div>
            </div>
            {activeQuickAddSection &&
            <div
                className="newsletter-source__add-btn"
                onClick={() => assignAlertToSection(source.id, 0, activeQuickAddSection.id)}
            >
                <i className="fa fa-plus"/>
            </div>
            }
        </div>
    );
};

export default Source;
