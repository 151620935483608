import React, {useState} from 'react';

import {Modal, ModalBody, ModalFooter, Button} from 'reactstrap';
import SpinnerButton from 'components/SpinnerButton';
import {deleteUser} from 'utils/api/usersAPI';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const DeleteModal = (props) => {
    const {isOpen, toggle, userToDelete, addFlashMessage, handleError, refreshUsers} = props;
    const [deleting, setDeleting] = useState(false);

    const handleDeleteUser = async() => {
        try {
            setDeleting(true);
            await deleteUser(userToDelete.id);
            await refreshUsers();
            setDeleting(false);
            toggle();
            addFlashMessage('success', 'User successfully deleted');
        } catch(err) {
            setDeleting(false);
            handleError(err);
        }
    };

    if(!userToDelete) return <div/>;
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="organization-switch-confirm">
            <ModalBodyWithClose toggle={toggle}>
                Are you sure you want to delete {userToDelete.name}?
            </ModalBodyWithClose>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                <SpinnerButton
                    color="danger"
                    title="Confirm"
                    onClick={handleDeleteUser}
                    submitting={deleting}
                />
            </ModalFooter>
        </Modal>
    );
};

export default DeleteModal;
