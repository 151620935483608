import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from 'react-router-dom';
import AuthContextProvider from 'contexts/AuthContext';
import FlashMessageContextProvider from 'contexts/FlashMessageContext';
import FilterContextProvider from 'contexts/FilterContext';
import Routes from 'Routes';
import FlashMessagesContainer from 'components/FlashMessage/FlashMessageContainer';
import ErrorBoundary from 'components/ErrorBoundary';

function App() {
    return (
        <Router>
            <FlashMessageContextProvider>
                <AuthContextProvider>
                    <FilterContextProvider>
                        <div style={{height: '100%'}}>
                            <FlashMessagesContainer/>
                            <ErrorBoundary>
                                <Routes/>
                            </ErrorBoundary>
                        </div>
                    </FilterContextProvider>
                </AuthContextProvider>
            </FlashMessageContextProvider>
        </Router>
    );
}

export default App;

