import React, {useState, useContext} from 'react';

import {Button} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import {useHistory} from 'react-router-dom';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import SuspendAccountModal from './Modals/ConfirmSuspend';
import {suspendOrganizationAccount} from 'utils/api/profileAPI';
import './style.scss';
import {AuthContext} from 'contexts/AuthContext';

const SuspendAccount = (props) => {
    let history = useHistory();
    const {addFlashMessage} = useContext(FlashMessageContext)
    const {handleError} = useContext(AuthContext)

    const redirectToAccountSuspended = () => {
        history.push('/suspended');
    };

    const [submitting, setSubmitting] = useState(false);
    const [suspendModalOpen, setSuspendModalOpen] = useState(false);

    const handleSuspendAccount = async() => {
        try {
            setSubmitting(true);
            await suspendOrganizationAccount();
            setSubmitting(false);
            redirectToAccountSuspended();
        } catch(err) {
            setSubmitting(false);
            // addFlashMessage('danger', 'Unable to suspend account at this time');
            handleError(err)
        }
    };

    const toggleSuspendModal = () => {
        setSuspendModalOpen(!suspendModalOpen);
    };


    return (
        <div className="suspend-account">
            <h4 className="settings__page-header">Suspend Account</h4>
            {props.isSingleUser ?
                <div className="suspend-account__info">
                    Clicking this button will log you out, and suspend your account. For reinstatement, you must attempt
                    to sign in and change your password.
                </div>
                :
                <div className="suspend-account__info">
                    Clicking this button will log you out, and suspend the accounts of you and all of your users. For reinstatement, the admin who suspended this account must attempt to sign in and change their password.
                </div>
            }
            <div className="suspend-account__btn">
                <SpinnerButton
                    color="primary"
                    submitting={submitting}
                    onClick={handleSuspendAccount}
                    title={'Suspend Account'}
                />
            </div>
            <SuspendAccountModal
                isOpen={suspendModalOpen}
                toggle={toggleSuspendModal}
                submitting={submitting}
                onSubmit={handleSuspendAccount}
            />
        </div>
    );
};

export default SuspendAccount;
