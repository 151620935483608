import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';

const TagCategory = (props) => {
    const {category, handleTagSelect, selectedTag} = props;
    const [tagsOpen, setTagsOpen] = useState(false);
    // state = {
    //     tagsOpen: false,
    //     tagView: 'short',
    //     initialRender: true,
    //     icon: ''
    // }

    // toggleTagView() {
    //     const {tagView} = this.state
    //     const tagState = tagView === 'short' ? 'expanded' : 'short'
    //     this.setState({tagView: tagState})
    // }
    //
    // renderTagLink() {
    //     const {tagView} = this.state
    //     const {tags} = this.props
    //     if(tags) {
    //         if (tags.length > 10) {
    //             if (tagView === 'short') {
    //                 return <div onClick={() => this.toggleTagView()} className="view-all-button">View All</div>
    //             } else {
    //                 return <div onClick={() => this.toggleTagView()} className="view-all-button">Collapse</div>
    //             }
    //         }
    //     }
    // }
    //
    const handleIcon = () => {
        switch(category.type) {
            case 'PERSON':
                return <i className="fa fa-user reader-tag-category__icon"/>;
            case 'LOCATION':
                return <i className="fa fa-map-marker reader-tag-category__icon"/>;
            case 'ORGANIZATION':
                return <i className="fa fa-sitemap reader-tag-category__icon"/>;
            // case 'BIAS':
            //     return <i className="fa fa-balance-scale reader-tag-category__icon"/>;
            default:
                return <div/>;
        }
    };

    const renderTagList = () => {
        category.tags.sort(function(a, b) {
            return b.cnt - a.cnt || a.name.localeCompare(b.name);
        });
        if(category.tags && category.tags.length > 0 && tagsOpen) {
            return (<ul className="reader-tag-category__list">
                {category.tags.slice(0, 10).map(tag => {
                    return (
                        <Tag
                            tag={tag}
                            key={category.tags.indexOf(tag) + tag.name}
                            activeTag={selectedTag}
                            handleTagSelect={handleTagSelect}
                            isActive={!selectedTag ? false : tag.name.toLowerCase() === selectedTag.name.toLowerCase()}
                        />
                    );
                })}
            </ul>);
        } else if(category.tags && category.tags.length === 0 && tagsOpen) {
            return (
                <ul className="reader-alert__empty-tag">No tags found</ul>
            );
        }
    };

    const toggleTagsOpen = () => {
        setTagsOpen(!tagsOpen);
    };
    //
    //     const {name} = this.props
    //     const {tagsOpen, icon} = this.state
    const rotateIcon = tagsOpen ? 'rotate-90' : '';
    const isActive = tagsOpen ? 'reader-tag-category__title-container--active' : '';
    return (
        <div className="reader-tag-category">
            <div
                className={'reader-tag-category__title-container ' + isActive}
                onClick={toggleTagsOpen}
            >
                <div className="reader-tag-category__title">
                    {handleIcon()}
                    &nbsp;{category.type.slice(0, 1) + category.type.slice(1, category.type.length).toLowerCase()}
                </div>
                <div className="reader-tag-category__arrow">
                    <i className={'fa fa-angle-left ' + rotateIcon}/>
                </div>
            </div>
            {renderTagList()}
        </div>
    );
};

export default TagCategory;
