import React, { useEffect, useState, useContext } from 'react';

import Articles from './Articles';
import Sections from './Sections';
import {
    clearCuratedNewsletterSection,
    clearNewsletterSection,
    getFreeCuratedArticlesByDate,
    hideArticleFromList,
    moveArticleBetweenNewsletterSections,
    sortNewsletterSections,
} from 'utils/api/newslettersAPI';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { AuthContext } from 'contexts/AuthContext';
import { cloneDeep } from 'lodash';

const ReviewArticles = (props) => {
    const { addFlashMessage } = useContext(FlashMessageContext);
    const { handleError } = useContext(AuthContext);
    const { newsletterData, refreshNewsletter } = props;
    const [retrievalDate, setRetrievalDate] = useState(1);
    const [sortByDate, setSortByDate] = useState('desc');
    const [articles, setArticles] = useState([]);
    const [articlesForDom, setArticlesForDom] = useState([]);
    const [fetchingArticles, setFetchingArticles] = useState(true);
    const [newsletterForDom, setNewsletterForDom] = useState(null);
    const [savingSectionChanges, setSavingSectionChanges] = useState(false);
    const [includeAbstracts, setIncludeAbstracts] = useState(true);

    useEffect(() => {
        getArticlesByDays();
    }, [retrievalDate, sortByDate, newsletterData]);

    useEffect(() => {
        setNewsletterForDom(newsletterData);
    }, [newsletterData]);

    const getArticlesByDays = async () => {
        try {
            !fetchingArticles && setFetchingArticles(true);
            let articles = await getFreeCuratedArticlesByDate(newsletterData.id, retrievalDate, sortByDate);
            setArticles(articles);
            setFetchingArticles(false);
        } catch (err) {
            setFetchingArticles(false);
            handleError(err);
        }
    };

    useEffect(() => {
        setArticlesForDom(articles);
    }, [articles]);

    const assignArticleToSection = async (articleId, previousSectionId, newSectionId, sortOrder) => {
        try {
            await moveArticleBetweenNewsletterSections(newsletterData.id, articleId, previousSectionId, newSectionId, 1);
        } catch (err) {
            handleError(err);
        }
    };

    const assignUnassignedArticleToSection = async (articleId, section, isReviewArticles = false) => {
        try {
            const { sections } = newsletterForDom;

            const nextColumn = sections.find((obj) => obj.id === section.id);
            if (!nextColumn) {
                addFlashMessage('danger', 'Unable to assign article');
                return;
            }
            const nextColumnIndex = sections.indexOf(nextColumn);

            const newStateUnassignedNews = cloneDeep(articles);
            const selectedArticle = newStateUnassignedNews.find((article) => article.id === articleId);
            if (!selectedArticle) return;
            const nextColumnNews = Array.from(nextColumn.newsletterSectionNews);
            let newSortOrder = nextColumnNews.length + 1;
            let newArticleObj = {
                comment: null,
                news: {
                    createdAt: selectedArticle.createdat,
                    id: selectedArticle.id,
                    newsResource: { id: null, domain: selectedArticle.resource, mediaBias: null },
                    title: selectedArticle.title,
                    url: selectedArticle.url,
                    abstract: selectedArticle.abstract,
                    sectionid: selectedArticle.sectionid,
                    sectionname: selectedArticle.sectionname,
                },
                sortOrder: newSortOrder,
            };

            isReviewArticles ? nextColumnNews.unshift(newArticleObj) : nextColumnNews.push(newArticleObj);

            const newNextColumn = {
                ...nextColumn,
                newsletterSectionNews: nextColumnNews,
            };

            newStateUnassignedNews.splice(newStateUnassignedNews.indexOf(selectedArticle), 1);
            const newStateSections = cloneDeep(sections);
            newStateSections[nextColumnIndex] = newNextColumn;
            const newNewsletter = {
                ...newsletterData,
                sections: newStateSections,
            };
            setNewsletterForDom(newNewsletter);
            setArticles(newStateUnassignedNews);

            await moveArticleBetweenNewsletterSections(newsletterData.id, articleId, 0, section.id, 1);
        } catch (err) {
            handleError(err);
        }
    };

    const handleRemoveAssignedArticle = async (articleId, section) => {
        try {
            const { sections } = newsletterForDom;
            const selectedSection = sections.find((obj) => obj.id === section.id);
            const selectedSectionIndex = sections.indexOf(selectedSection);

            const newStateUnassignedNews = cloneDeep(articles);
            const newStateSections = cloneDeep(sections);

            const selectedArticle = selectedSection.newsletterSectionNews.find((article) => article.news.id === articleId);
            if (!selectedArticle) return;
            const selectedSectionNews = Array.from(selectedSection.newsletterSectionNews);
            selectedSectionNews.splice(selectedSectionNews.indexOf(selectedArticle), 1);
            const newSelectedSection = {
                ...selectedSection,
                newsletterSectionNews: selectedSectionNews,
            };

            let newArticleObj = {
                abstract: selectedArticle.news?.abstract,
                createdat: selectedArticle.news.createdAt,
                id: selectedArticle.news.id,
                resource: selectedArticle.news.newsResource.domain,
                sectionid: selectedArticle.news?.sectionid,
                sectionname: selectedArticle.news?.sectionname,
                title: selectedArticle.news.title,
                url: selectedArticle.news?.url,
            };

            newStateUnassignedNews.unshift(newArticleObj);
            newStateSections[selectedSectionIndex] = newSelectedSection;
            const newNewsletter = {
                ...newsletterData,
                sections: newStateSections,
            };
            setNewsletterForDom(newNewsletter);
            setArticles(newStateUnassignedNews);

            await moveArticleBetweenNewsletterSections(newsletterData.id, articleId, section.id, 0, 1);
        } catch (err) {
            handleError();
        }
    };

    const moveArticleBetweenSections = async (articleId, previousSectionId, nextSectionId, sortOrder) => {
        try {
            const nextColumn = newsletterForDom.sections.find((obj) => obj.id === nextSectionId);
            const prevColumn = newsletterForDom.sections.find((obj) => obj.id === previousSectionId);
            if (!nextColumn || !prevColumn) {
                addFlashMessage('danger', 'Unable to assign article');
                return;
            }
            const nextColumnIndex = newsletterForDom.sections.indexOf(nextColumn);
            const prevColumnIndex = newsletterForDom.sections.indexOf(prevColumn);

            const selectedArticle = prevColumn.newsletterSectionNews.find((article) => article.news.id === articleId);
            if (!selectedArticle) {
                addFlashMessage('danger', 'Unable to assign article');
                return;
            }
            const nextColumnNews = Array.from(nextColumn.newsletterSectionNews);
            const prevColumnNews = Array.from(prevColumn.newsletterSectionNews);
            selectedArticle.sortOrder = sortOrder;

            nextColumnNews.splice(sortOrder - 1, 0, selectedArticle);

            const newNextColumn = {
                ...nextColumn,
                newsletterSectionNews: nextColumnNews,
            };
            const newPrevColumn = {
                ...prevColumn,
                newsletterSectionNews: prevColumnNews,
            };
            prevColumnNews.splice(prevColumnNews.indexOf(selectedArticle), 1);
            const newStateSections = cloneDeep(newsletterForDom.sections);
            newStateSections[nextColumnIndex] = newNextColumn;
            newStateSections[prevColumnIndex] = newPrevColumn;
            const newNewsletter = {
                ...newsletterData,
                sections: newStateSections,
            };
            setNewsletterForDom(newNewsletter);
            await moveArticleBetweenNewsletterSections(newsletterData.id, articleId, previousSectionId, nextSectionId, sortOrder);
        } catch (err) {
            handleError(err);
        }
    };

    const resortArticlesInSameSection = async (articleId, sectionId, newIndex) => {
        try {
            let section = newsletterForDom.sections.find((obj) => obj.id === sectionId);
            let newSection = { ...section };
            const selectedArticle = section.newsletterSectionNews.find((article) => article.news.id === articleId);
            let oldIndex = section.newsletterSectionNews.indexOf(selectedArticle);
            if (newIndex >= section.newsletterSectionNews.length) {
                let k = newIndex - newSection.newsletterSectionNews.length + 1;
                while (k--) {
                    newSection.newsletterSectionNews.push(undefined);
                }
            }
            newSection.newsletterSectionNews.splice(newIndex, 0, newSection.newsletterSectionNews.splice(oldIndex, 1)[0]);
            await moveArticleBetweenNewsletterSections(newsletterData.id, articleId, sectionId, sectionId, newIndex + 1);
        } catch (err) {
            handleError(err);
        }
    };

    const removeAllArticlesFromSection = async (e, sectionId) => {
        try {
            e.stopPropagation();
            await clearCuratedNewsletterSection(newsletterData.id, sectionId);
            refreshNewsletter();
            await getArticlesByDays();
        } catch (err) {
            handleError(err);
        }
    };

    const handleSectionSorting = async (sortOrderArr) => {
        try {
            setSavingSectionChanges(true);
            await sortNewsletterSections(newsletterData.id, { sections: sortOrderArr });
            refreshNewsletter();
            setSavingSectionChanges(false);
        } catch (err) {
            setSavingSectionChanges(false);
            handleError(err);
        }
    };

    const hideArticle = async (articleId) => {
        try {
            const newStateUnassignedNews = cloneDeep(articles);
            const selectedArticle = newStateUnassignedNews.find((article) => article.id === articleId);
            if (!selectedArticle) {
                addFlashMessage('danger', 'Unable to complete your request at this time');
                return;
            }
            newStateUnassignedNews.splice(newStateUnassignedNews.indexOf(selectedArticle), 1);
            setArticles(newStateUnassignedNews);
            await hideArticleFromList(newsletterForDom.id, articleId);
        } catch (err) {
            handleError(err);
        }
    };

    const toggleIncludeAbstracts = () => {
        setIncludeAbstracts(!includeAbstracts);
    };

    return (
        <div className="d-flex flex-1">
            {newsletterForDom && (
                <Sections
                    newsletterData={newsletterForDom}
                    removeAllArticlesFromSection={removeAllArticlesFromSection}
                    addFlashMessage={addFlashMessage}
                    handleError={handleError}
                    handleSectionSorting={handleSectionSorting}
                    handleRemoveAssignedArticle={handleRemoveAssignedArticle}
                    refreshNewsletter={refreshNewsletter}
                    moveArticleBetweenSections={moveArticleBetweenSections}
                    resortArticlesInSameSection={resortArticlesInSameSection}
                    toggleIncludeAbstracts={toggleIncludeAbstracts}
                    includeAbstracts={includeAbstracts}
                />
            )}
            <Articles
                newsletterData={newsletterForDom}
                retrievalDate={retrievalDate}
                setRetrievalDate={setRetrievalDate}
                sortByDate={sortByDate}
                setSortByDate={setSortByDate}
                articles={articlesForDom}
                assignArticleToSection={assignArticleToSection}
                assignUnassignedArticleToSection={assignUnassignedArticleToSection}
                fetchingArticles={fetchingArticles}
                hideArticle={hideArticle}
                refreshNewsletter={refreshNewsletter}
                handleError={handleError}
                includeAbstracts={includeAbstracts}
            />
        </div>
    );
};

export default ReviewArticles;
