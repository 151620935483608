import React, {useState, useEffect} from 'react';

import {Table, FormGroup, Label, Input} from 'reactstrap';

import {setAllowNotifications} from 'utils/api/profileAPI';

const AdminListItem = ({admin, refreshProfile, handleError}) => {
    const [allowNotificationsSwitch, setAllowNotificationsSwitch] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setAllowNotificationsSwitch(admin.allowNotifications);
    }, []);

    const handleNotificationsChange = async(item) => {
        try {
            setSaving(true);
            if(allowNotificationsSwitch) {
                setAllowNotificationsSwitch(false);
                await setAllowNotifications(item.id, {allowNotifications: false});
            } else {
                setAllowNotificationsSwitch(true);
                await setAllowNotifications(item.id, {allowNotifications: true});
            }
            await refreshProfile();
            setSaving(false);
        } catch(err) {
            handleError(err);
            setSaving(false);
        }
    };

    return (
        <tr key={admin.id}>
            <td data-label="Name">{admin.name}</td>
            <td data-label="Email">{admin.email}</td>
            <td data-label="Allow Notifications">
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            checked={allowNotificationsSwitch}
                            onClick={() => handleNotificationsChange(admin)}
                        />{' '}
                        &nbsp;
                    </Label>
                    {saving && window.innerWidth > 1250 && <i className="fa fa-spinner fa-spin"/>}
                </FormGroup>
            </td>
        </tr>
    );
};

export default AdminListItem;
