// export default {
//   'America/Chicago': 'Central Standard Time (Chicago)',
//   'America/Los_Angeles': 'Pacific Standard Time (Los Angeles)',
//   'America/New_York': 'Eastern Standard Time (New York)',
//   'America/Phoenix': 'Mountain Standard Time (Phoenix)',
//   'Asia/Dubai': 'Gulf Standard Time (Dubai)',
//   'Asia/Shanghai': 'China Taiwan Time (Beijing)',
//   'Asia/Tokyo': 'Japan Standard Time (Tokyo)',
//   'Australia/Sydney': 'Australia Eastern Time (Sydney)',
//   'Europe/London': 'Greenwich Mean Time (London)',
//   'Europe/Paris': 'European Central Time (Paris)'
// };

export const timezones = [
  {
    key:'Asia/Shanghai',
    name: 'Asia Pacific Time (Hong Kong)'
  },
  {
    key:'Australia/Sydney',
    name: 'Australia Eastern Time (Sydney)'
  },
  {
    key:'America/Chicago',
    name: 'Central Standard Time (Chicago)'
  },
  {
    key:'America/New_York',
    name: 'Eastern Standard Time (New York)'
  },
  {
    key:'Europe/Paris',
    name: 'European Central Time (Paris)'
  },
  {
    key:'Europe/London',
    name: 'Greenwich Mean Time (London)'
  },
  {
    key:'Asia/Dubai',
    name: 'Gulf Standard Time (Dubai)'
  },
  {
    key:'Asia/Tokyo',
    name: 'Japan Standard Time (Tokyo)'
  },
  {
    key:'America/Phoenix',
    name: 'Mountain Standard Time (Phoenix)'
  },
  {
    key:'America/Los_Angeles',
    name: 'Pacific Standard Time (Los Angeles)'
  },
  {
    key:'UTC',
    name: 'UTC'
  },
]

