import React, {createContext, useState, useReducer, useEffect, useContext} from 'react';

import {useHistory} from 'react-router-dom';

import {getProfile, updateProfile} from 'utils/api/authAPI';
import {authReducer} from 'reducers/authReducer';
import moment from 'moment-timezone';
import isEmpty from 'lodash';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {getReaderProfile} from 'utils/api/alertsAPI';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
    let history = useHistory();
    const {addFlashMessage} = useContext(FlashMessageContext);

    const [state, dispatch] = useReducer(authReducer, {
        isAuthenticated: false,
        isReaderAuthenticated: false,
        isSingleUser: false,
        tokenData: {},
        readerInfo: {},
        profile: {},
        authChecked: false,
    });

    useEffect(() => {     //Check if token exists in local storage
        let status = localStorage.getItem('token');
        if(!status || status === 'undefined') {
            dispatch({type: 'CHECK_AUTH'});
            return;
        }
        let parsedStatus = JSON.parse(status);
        if(parsedStatus.hasOwnProperty('accessToken') &&
            moment().isBefore(parsedStatus.accessTokenExpiredAt)) {
            if(parsedStatus.isReaderProfileToken) {
                dispatch({type: 'SET_READER_PROFILE', payload: parsedStatus});
            } else {
                dispatch({type: 'SET_AUTH', payload: parsedStatus});
            }
        }
        dispatch({type: 'CHECK_AUTH'});
    }, []);

    useEffect(() => {
        let status = localStorage.getItem('readerInfo');
        if(!status || status === 'undefined') {
            dispatch({type: 'CHECK_READER_AUTH'});
            return;
        }
        let parsedInfo = JSON.parse(status);
        dispatch({type: 'SET_READER_AUTH', payload: parsedInfo});
        dispatch({type: 'CHECK_READER_AUTH'});
    }, []);

    useEffect(() => {   //fetch Profile data when state gets token object
        if(state.tokenData.hasOwnProperty('accessToken')){
            if(state.isAuthenticated) {
                fetchProfile(state.tokenData);
            } else if(state.isReaderAuthenticated) {
                fetchReaderProfile(state.tokenData)
            }
        }
    }, [state.tokenData]);


    const fetchProfile = async(token) => {
        try {
            let tokenVar = token ? token : state.tokenData;
            const profile = await getProfile(tokenVar.accessToken);
            profile && dispatch({type: 'SET_PROFILE', payload: profile, isSingle: profile.organization.single});
        } catch(err) {
            handleError(err);
        }
    };

    const fetchReaderProfile = async() => {
        try {
            const profile = await getReaderProfile();
            profile && dispatch({type: 'SET_READER_PROFILE', payload: profile});
        } catch(err) {
            handleError(err);
        }
    };

    const updateProfile = async(params) => {
        try {
            const updatedProfile = await updateProfile(state.tokenData.accessToken, params);
            // dispatch({type: 'SET_PROFILE', payload: profile.content})
        } catch(err) {
            handleError(err);
        }
    };

    const signIn = (token) => {
        dispatch({type: 'SET_AUTH', payload: token});
        localStorage.setItem('token', JSON.stringify({isReaderProfileToken: false, ...token}));
    };

    const readerSignIn = (readerInfo) => {
        dispatch({type: 'SET_READER_AUTH', payload: readerInfo});
        localStorage.setItem('readerInfo', JSON.stringify(readerInfo));
    };

    const authorizeManagePublicReader = (token) => {
        dispatch({type: 'SET_READER_PROFILE', payload: token});
        localStorage.setItem('token', JSON.stringify({isReaderProfileToken: true, ...token}));
    };

    const logout = async() => {
        await document.body.classList.remove(...document.body.classList);
        dispatch({type: 'LOGOUT'});
        localStorage.removeItem('token');
        localStorage.removeItem('readerInfo');
        sessionStorage.removeItem('readerInfo');
    };

    useEffect(() => {
        const handleInvalidToken = e => {
            if (e.key === 'token' && e.oldValue && !e.newValue) {
                logout();
            }
        }
        window.addEventListener('storage', handleInvalidToken)
        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken)
        }
    }, [])

    const handleError = (errObj, setError, redirectOnly) => {
        if(redirectOnly) {
            history.push('/404');
            return;
        }
        let code = errObj ? errObj.code : undefined;
        switch(code) {
            case 'INTERNAL_ERROR':
                addFlashMessage('danger', 'Unable to complete your request at this time');
                break;
            case 'LOGICAL_ERROR':
                errObj.message && addFlashMessage('danger', errObj.message);
                break;
            case 'ACCESS_DENIED':
                localStorage.removeItem('token');
                history.push('/signin');
                break;
            case 'AUTHENTICATION_FAILED':
                localStorage.removeItem('token');
                history.push('/signin');
                break;
            case 'VALIDATION_FAILED':
                setError && setError(errObj);
                break;
            case 'NOT_FOUND':
                history.push('/404');
                break;
            case 'ACCOUNT_IS_SUSPENDED':
                history.push('/suspended/request');
                break;
            case 'USERNAME_INVALID':
                addFlashMessage('danger', 'The email or password did not match our records. Please try again.');
                break;
            case 'PASSWORD_INVALID':
                addFlashMessage('danger', 'The email or password did not match our records. Please try again.');
                break;
            default:
                addFlashMessage('danger', 'Unable to complete your request at this time');
        }
    };

    return (
        <AuthContext.Provider value={{
            state,
            signIn,
            readerSignIn,
            authorizeManagePublicReader,
            logout,
            fetchProfile,
            updateProfile,
            handleError,
        }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;


