import React, {useEffect, useState, useContext} from 'react';

import {useLocation, useParams, } from 'react-router-dom';
import {isEmpty} from 'lodash';

import MobileReader from './Mobile';
import DesktopReader from './Desktop';
import {AuthContext} from 'contexts/AuthContext';

const Reader = (props) => {
    let {id} = useParams();
    let {search} = useLocation();
    const {state} = useContext(AuthContext);
    const [readerAuthChecked, setReaderAuthChecked] = useState(false);


    useEffect(() => {
        document.body.classList.contains('sidebar-minimized') &&
        document.body.classList.toggle('sidebar-minimized');
    }, []);

    useEffect(() => {
        if(!props.companyReader) {
            setReaderAuthChecked(true);
            return;
        }
        if(state && !isEmpty(state.readerInfo)) {
            let status = localStorage.getItem('readerInfo');
            if(!status || status === 'undefined') {
                return;
            }
            setReaderAuthChecked(true);
        }
    }, [state]);

    if(props.companyReader && !readerAuthChecked) return null;
    if(window.innerWidth > 1024) {
        return <DesktopReader id={id} search={search} public={props.public} companyReader={props.companyReader} readerInfo/>;
    } else {
        return <MobileReader id={id} search={search} public={props.public} companyReader={props.companyReader} readerInfo/>;
    }
};

export default Reader;
