import React, {useState, useEffect, useContext} from 'react';

import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';

import SpinnerButton from 'components/SpinnerButton';
import {assignUsersToNewsletter} from 'utils/api/newslettersAPI';
import {getUserGroups, getUsersWithoutParams} from 'utils/api/usersAPI';
import DualSelect from 'components/DualSelect';
import LoadingSpinner from 'components/LoadingSpinner';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const AssignNewsletterModal = (props) => {
    const {isOpen, toggle, refreshNewsletter, newsletter, addFlashMessage} = props;
    const [submitting, setSubmitting] = useState(false);
    const [users, setUsers] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [fetchingUsers, setFetchingUsers] = useState(true);


    useEffect(() => {
        if(newsletter) {
            setSelectedUsers(newsletter.assignedUsers);
            fetchUsers();
        }
    }, []);

    const fetchUsers = async() => {
        try {
            let userData = await getUsersWithoutParams(false, false, 1, 2000);
            let userGroupData = await getUserGroups();
            setUsers(userData.result);
            setUserGroups(userGroupData);
            setUsers(userData.result);
            setFetchingUsers(false);
        } catch(err) {
            addFlashMessage('danger', 'Unable to fetch users');
            setFetchingUsers(false);
        }
    };

    const onSubmit = async() => {
        try {
            setSubmitting(true);
            const params = {
                users: selectedUsers.map(user => user.id),
            };
            await assignUsersToNewsletter(newsletter.id, params);
            await refreshNewsletter();
            setSubmitting(false);
            addFlashMessage('success', 'Newsletter successfully updated');
            toggle();
        } catch(err) {
            setSubmitting(false);
            addFlashMessage('danger', 'Unable to update newsletter at this time');
        }
    };

    if(fetchingUsers) return (
        <Modal isOpen={isOpen} toggle={toggle} className="assign-newsletter-modal modal-lg">
            <LoadingSpinner padding text={'Fetching users'}/>
        </Modal>
    );
    return (
        <Modal isOpen={isOpen} toggle={toggle} className="assign-newsletter-modal modal-xl">
            <ModalBodyWithClose toggle={toggle}>
                <h2>Assign Newsletter</h2>
                <div>
                    <div className="assign-newsletter__info mb-3">
                        <h6 className="mt-2">{newsletter.name}</h6>
                    </div>
                    <div className="mt-5">
                        <DualSelect
                            selectedList={selectedUsers}
                            fullList={users}
                            groups={userGroups}
                            onSelect={setSelectedUsers}
                            contentType="users"
                        />
                    </div>
                </div>
            </ModalBodyWithClose>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
                <SpinnerButton
                    type="modal"
                    color="primary"
                    onClick={onSubmit}
                    submitting={submitting}
                    title="Assign"
                />
            </ModalFooter>
        </Modal>
    );
};

export default AssignNewsletterModal;
