import React, {useContext, useState, useEffect} from 'react';

import {AuthContext} from 'contexts/AuthContext';
import {FlashMessageContext} from 'contexts/FlashMessageContext';

import SingleUserBlock from 'components/SingleUserBlock';
import {getUserLabels} from 'utils/api/usersAPI';
import LabelsList from './List';
import EmptyList from './EmptyList';
import LoadingSpinner from 'components/LoadingSpinner';
import {FilterContext} from 'contexts/FilterContext';

const Labels = (props) => {
    const {state, handleError} = useContext(AuthContext);
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {userLabelFilters, setFiltersObject} = useContext(FilterContext);

    const [labels, setLabels] = useState([]);
    const [fetchingData, setFetchingData] = useState(true);

    useEffect(() => {
        !state.isSingleUser && fetchLabels();
    }, []);

    const fetchLabels = async() => {
        try {
            const labelData = await getUserLabels();
            setLabels(labelData);
            setFetchingData(false);
        } catch(err) {
            setFetchingData(false);
            handleError(err);
        }
    };


    return (
        <div>
            {state.isSingleUser ?
                <SingleUserBlock itemType="labels"/>
                :
                fetchingData ?
                    <LoadingSpinner/>
                    :
                    labels.length > 0 ?
                        <LabelsList
                            labels={labels}
                            fetchingData={fetchingData}
                            refreshLabels={fetchLabels}
                            handleError={handleError}
                            addFlashMessage={addFlashMessage}
                            setFiltersObject={setFiltersObject}
                            userLabelFilters={userLabelFilters}
                        />
                        :
                        <EmptyList
                            refreshLabels={fetchLabels}
                            handleError={handleError}
                            addFlashMessage={addFlashMessage}
                        />
            }
        </div>
    );
};

export default Labels;
