import React, { useEffect, useState, useRef } from 'react';

import { Row, Col, Button } from 'reactstrap';

import ClicksPerDay from './ClicksPerDay';
import AlertsBreakout from './AlertsBreakout';
import TopArticles from './TopArticles';
import TopPublishers from './TopPublishers';
import TopAlerts from './TopAlerts';

const AlertStatistics = ({ handleError }) => {
    const [labelContainerHeight, setLabelContainerHeight] = useState(null);
    const [articleListLength, setArticleListLength] = useState(null);
    const [publisherListLength, setPublisherListLength] = useState(null);
    const [alertListLength, setAlertListLength] = useState(null);
    const [isOpenAxiomaBot, setIsOpenAxiomaBot] = useState(false);
    const axiomaRef = useRef(null);

    useEffect(() => {
        if (Number.isInteger(articleListLength) && Number.isInteger(publisherListLength) && Number.isInteger(alertListLength)) {
            let largest = Math.max(articleListLength, publisherListLength, alertListLength);
            let containerHeight = largest > 0 ? largest * 21 + 20 : 41;
            setLabelContainerHeight(containerHeight);
        }
    }, [articleListLength, publisherListLength, alertListLength]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (axiomaRef.current && !axiomaRef.current.contains(event.target)) {
                setIsOpenAxiomaBot(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [axiomaRef]);

    return (
        <div>
            {isOpenAxiomaBot && (
                <iframe
                    ref={axiomaRef}
                    className="embed-bot"
                    src="https://axiomabot.com/embed/chat/9cd329cc-d2e5-4d3b-888d-5446d99abea9/dpb__P1Yb3VkVyjv5wxEKxcaI0Nf8JgBqPojgwAsURvWVdMdqf0DJz4GUEt4b7lP"
                    name="axiomabot"
                    scrolling="no"
                    frameBorder="1"
                    allowFullScreen>
                </iframe>
            )}

            <Button onClick={() => setIsOpenAxiomaBot(true)}>Open Axioma Bot</Button>

            <Row>
                <Col xs={12}>
                    <ClicksPerDay type="alert" />
                </Col>
            </Row>
            {/* <Row>
                <Col xs={12}>
                    <AlertsBreakout />
                </Col>
            </Row> */}
            <Row>
                <Col xl={4} lg={6} md={12} xs={12}>
                    <TopArticles labelContainerHeight={labelContainerHeight} setArticleListLength={setArticleListLength} handleError={handleError} />
                </Col>
                <Col xl={4} lg={6} md={12} xs={12}>
                    <TopPublishers
                        labelContainerHeight={labelContainerHeight}
                        setPublisherListLength={setPublisherListLength}
                        handleError={handleError}
                    />
                </Col>
                <Col xl={4} lg={6} md={12} xs={12}>
                    <TopAlerts labelContainerHeight={labelContainerHeight} setAlertListLength={setAlertListLength} handleError={handleError} />
                </Col>
            </Row>
        </div>
    );
};

export default AlertStatistics;
