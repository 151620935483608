import React, {useState, useRef} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

const SectionDropdown = (props) => {
    const {sections, setAssignmentSection, assignmentSection, articleId} = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
                caret
                color="primary"
                style={{minWidth: '120px'}}
            >
                {assignmentSection && (assignmentSection.name.length > 15 ? (assignmentSection.name.substring(0, 15) + '...') : assignmentSection.name)}
            </DropdownToggle>
            <DropdownMenu>
                {sections.map(section => {
                    return (
                        <DropdownItem
                            onClick={() => setAssignmentSection(section)}
                            // onClick={() => console.log(section)}
                            key={articleId + '-dropdown' + section.id}
                        >
                            {section.name.length > 25 ? (section.name.substring(0, 25) + '...') : section.name}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
};

export default SectionDropdown;