import React from 'react';

import {Container, Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';

import Logo from 'images/logo.png'

const Page404 = ({fromErrorBoundary}) => {
    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md="5">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Link to="/">
                                <img
                                    src={Logo}
                                    alt="Ozmosys"
                                    className="img-fluid"
                                    style={{
                                        maxWidth: 200,
                                        marginTop: -140
                                    }}
                                />
                            </Link>
                        </div>
                        <div className="clearfix">
                            <h1 className="float-left display-3 mr-4">404</h1>
                            <h4 className="pt-3">Oops! You're lost.</h4>
                            <p className="text-muted float-left">
                                The page you are looking for was not found.
                            </p>
                        </div>
                        <div
                            style={{
                                fontSize: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Link to="/">Return to homepage</Link>
                            <span style={{margin: '0 5px'}}>|</span>
                            <a
                                style={{color: '#536c79'}}
                                href="mailto:support@ozmosys.com"
                            >
                                <i
                                    style={{color: '#70818a'}}
                                    className=" mr-2 fa fa-envelope"
                                />
                                Contact Us
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Page404;
