import React from 'react';

const Alert = (props) => {
    const {alert, isSelected, handleAlertSelect} = props;
    return (
        <div
            onClick={() => handleAlertSelect(alert)}
            className={'mobile-reader-alert ' + (isSelected ? 'mobile-reader-alert--active' : '')}
        >
            <span>{alert.name}</span>
            <span className="mobile-reader-alert__cnt">{alert.countNews}</span>
        </div>
    );
};

export default Alert;
