import React from 'react';
import './style.scss';
import { convertDateString } from 'Helpers/convertDateString';
import { truncateWithoutWordBreak } from 'Helpers/truncateWithoutWordBreak';

const AlertPreviewItem = ({ item, showRank, withHour }) => {
    return (
        <div className="alert-preview-item d-flex">
            <div className="alert-preview-item__body">
                <a target="_blank" rel="noopener noreferrer" href={item.url} className="alert-preview-item__title">
                    {item.title}
                </a>
                <div className="alert-preview-item__info">
                    <span className="alert-preview-item__source" dangerouslySetInnerHTML={{ __html: item.resource }} />
                    {' · '}
                    <span className="alert-preview-item__date">
                        {convertDateString(item.created_at, withHour)}
                        {/*{showRank && item.rank && <div>Rank: {Number((1*item.rank).toFixed(3))}</div>}*/}
                    </span>
                </div>
                <div className="alert-preview-item__abstract" dangerouslySetInnerHTML={{ __html: truncateWithoutWordBreak(item.abstract, 240) }} />
            </div>
        </div>
    );
};

export default AlertPreviewItem;
