import React, {useState, useEffect, useContext} from 'react';

import {Card, CardBody, CardHeader} from 'reactstrap';

import Chart from './Chart';
import EmptyChart from './EmptyChart';
import List from 'containers/Dashboard/ComparativeAnalysis/List';
import {getComparativeSetStatistics} from 'utils/api/statisticsAPI';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {AuthContext} from 'contexts/AuthContext';
import {FilterContext} from 'contexts/FilterContext';
import './style.scss';

const ComparativeAnalysis = () => {
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError} = useContext(AuthContext);
    const {compSetFilters, setFiltersObject} = useContext(FilterContext);

    const [activeSet, setActiveSet] = useState(null);
    const [activeSetStats, setActiveSetStats] = useState(null);
    const [fetchingCompSetStatistics, setFetchingCompSetStatistics] = useState(false);

    const handleCompSetSelect = async(set) => {
        setActiveSet(set);
    };

    const fetchCompSetStatistics = async(id, fromCSV) => {
        try {
            !fromCSV && setFetchingCompSetStatistics(true);
            const compSetStats = await getComparativeSetStatistics(id, 30);
            if(fromCSV) {
                return compSetStats;
            } else {
                setActiveSetStats(compSetStats);
            }
            setFetchingCompSetStatistics(false);
        } catch(err) {
            setFetchingCompSetStatistics(false);
            handleError(err);
        }
    };

    useEffect(() => {
        activeSet && fetchCompSetStatistics(activeSet.id);
    }, [activeSet]);

    return (
        <div className="comparative-analysis">
            <Card className="chart-card">
                <CardHeader>Comparative Analysis</CardHeader>
                <CardBody>
                    {fetchingCompSetStatistics ?
                        <EmptyChart loading/>
                        :
                        activeSetStats ?
                            <Chart activeSetStats={activeSetStats} chartKey={activeSet.id} activeIndex={-1}/>
                            :
                            <EmptyChart/>
                    }
                </CardBody>
                <CardBody style={{minHeight: 0}}>
                    <List
                        handleCompSetSelect={handleCompSetSelect}
                        fetchCompSetStatistics={fetchCompSetStatistics}
                        activeSet={activeSet}
                        handleError={handleError}
                        addFlashMessage={addFlashMessage}
                        compSetFilters={compSetFilters}
                        setFiltersObject={setFiltersObject}
                    />
                </CardBody>
            </Card>
        </div>
    );
};

export default ComparativeAnalysis;
