import {useContext} from 'react';

export const handleError = (errObj, setError, addFlashMessage) => {
    let code = errObj.code;
    switch(code) {
        case 'INTERNAL_ERROR':
            // addFlashMessage('danger', 'Unable to complete your request at this time');
            // setError(errObj.errors);
            let testObj = {
                message: '',
                code: 'test',
                errors: {
                    name: [
                        {
                            message: 'This value should not be blank.',
                            code: 'IS_BLANK_ERROR',
                            payload: null,
                        },
                    ],
                },
            };
            setError(testObj);
            break;
        case 'LOGICAL_ERROR':
            addFlashMessage('danger', 'Unable to complete your request at this time');
            break;
        case 'AUTHENTICATION_FAILED':
            // GO TO SIGNIN
            addFlashMessage('danger', 'Unable to complete your request at this time');
            break;
        case 'VALIDATION_FAILED':
            setError(errObj.errors);
            break;
        case 'NOT_FOUND':
            // GO TO 404
            break;
        case 'ACCOUNT_IS_SUSPENDED':
            // GO TO SUSPENDED PAGE
            break;
        default:
            addFlashMessage('danger', 'Unable to complete your request at this time');
    }
};


// const INTERNAL_ERROR = 'INTERNAL_ERROR';
// const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
// const WSSE_ERROR = 'WSSE_ERROR';
// const ACCESS_DENIED = 'ACCESS_DENIED';
// const USERNAME_INVALID = 'USERNAME_INVALID';
// const PASSWORD_INVALID = 'PASSWORD_INVALID';
// const LOGICAL_ERROR = 'LOGICAL_ERROR';
// const NOT_FOUND = 'NOT_FOUND';
// const VALIDATION_FAILED = 'VALIDATION_FAILED';
// const CONFIRMATION_TOKEN_INVALID = 'CONFIRMATION_TOKEN_INVALID';
// const ACCOUNT_DISABLED = 'ACCOUNT_DISABLED';
// const ACCOUNT_NOT_CONFIRMED = 'ACCOUNT_NOT_CONFIRMED';
// const ACCOUNT_IS_SUSPENDED = 'ACCOUNT_IS_SUSPENDED';