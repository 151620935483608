import React, { useState } from 'react';
import { Modal, Table, UncontrolledTooltip } from 'reactstrap';
import './Info.scss';
import ModalBodyWithClose from 'components/ModalBodyWithClose';

const Info = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div onClick={toggle} className="info-line mt-0">
                <div id="alert-rules-icon">
                    <i className="fa fa-info-circle" /> Boolean Operators
                </div>
                <UncontrolledTooltip placement="right" target="alert-rules-icon" delay={{ show: 300, hide: 0 }}>
                    Advanced Operators
                </UncontrolledTooltip>
            </div>

            <Modal isOpen={isOpen} fade={false} className="search-info-modal">
                <ModalBodyWithClose toggle={toggle}>
                    <h2>Boolean Operators</h2>
                    <div className="table-container">
                        <Table className="table-responsive-md search-rules-table" size="sm">
                            <tbody>
                                <tr className="responsive-table header-row">
                                    <th>Operator</th>
                                    <th>Example</th>
                                    <th>Description</th>
                                </tr>
                                <tr>
                                    <td data-label="Operator" className="operator-td">
                                        An exact word or phrase
                                    </td>
                                    <td data-label="Example" className="example-td">
                                        "artificial intelligence"
                                    </td>
                                    <td data-label="Description">
                                        <div className="description-inner">Containing the exact phrase "artificial intelligence".</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td data-label="Operator">Multiple words</td>
                                    <td data-label="Example">artificial AND intelligence</td>
                                    <td data-label="Description">
                                        <div className="description-inner">
                                            Containing both "artificial" and "intelligence" but not necessarily as an exact phrase. The words can
                                            appear separately anywhere in the article.
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td data-label="Operator">OR</td>
                                    <td data-label="Example">love OR hate</td>
                                    <td data-label="Description">
                                        <div className="description-inner">Containing either "love" or "hate" (or both).</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td data-label="Operator">Grouping</td>
                                    <td data-label="Example">hotel AND (Boston OR "New York")</td>
                                    <td data-label="Description">
                                        <div className="description-inner">Containing both "hotel" and either "Boston" or "New York".</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td data-label="Operator">Exclude</td>
                                    <td data-label="Example">"annual report" -filing</td>
                                    <td data-label="Description">
                                        <div className="description-inner">Containing "annual report" but not "filing".</div>
                                    </td>
                                </tr>
                                {/*<tr>*/}
                                {/*    <td data-label="Operator">Exclude groups</td>*/}
                                {/*    <td data-label="Example">annual AND report -(filing OR stock)</td>*/}
                                {/*    <td data-label="Description">*/}
                                {/*        <div className="description-inner">Containing both "annual" and "report" but not "filing" or "stock".</div>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                <tr>
                                    <td data-label="Operator">Exclude phrases</td>
                                    <td data-label="Example">social -"social media"</td>
                                    <td data-label="Description">
                                        <div className="description-inner">Containing "social" anywhere in the message, but not "social media".</div>
                                    </td>
                                </tr>
                                {/*<tr>*/}
                                {/*    <td data-label="Operator">Proximity Near Operator (Nx)</td>*/}
                                {/*    <td data-label="Example">television N2 violence</td>*/}
                                {/*    <td data-label="Description">*/}
                                {/*        <div className="description-inner">*/}
                                {/*            Finds words within x number of words from each other, regardless of the order in which they occur.*/}
                                {/*        </div>*/}
                                {/*    </td>*/}
                                {/*</tr>*/}
                                {/*<tr>
                                <td data-label="Operator">Proximity Within Operator (Wx)</td>
                                <td data-label="Example">Franklin W2 Roosevelt</td>
                                <td data-label="Description">
                                    <div className="description-inner">
                                        Finds words within x number of words from each other, in the order they are entered in the search.
                                    </div>
                                </td>
                            </tr>*/}
                            </tbody>
                        </Table>
                    </div>
                </ModalBodyWithClose>
            </Modal>
        </div>
    );
};

export default Info;
