import React, {useState, useContext, useEffect} from 'react';

import {useHistory, useRouteMatch} from 'react-router-dom';

import General from './General';
import EmailSchedule from './EmailSchedule';
import AssignAlerts from './AssignAlerts';
import {Col, Row} from 'reactstrap';
import TabBar from 'components/TabBar';
import {FlashMessageContext} from 'contexts/FlashMessageContext';
import {addUser, addUserLabel, addUserSchedule, getUserLabels} from 'utils/api/usersAPI';
import SpinnerButton from 'components/SpinnerButton';
import moment from 'moment-timezone';
import {AuthContext} from 'contexts/AuthContext';
import './style.scss';
import {timezones} from 'utils/timezones';

const CreateUser = (props) => {
    let {url} = useRouteMatch();
    let history = useHistory();
    const {addFlashMessage} = useContext(FlashMessageContext);
    const {handleError, state} = useContext(AuthContext);

    const [activeView, setActiveView] = useState('general');
    const [timezone, setTimezone] = useState('');
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [sendConfirmation, setSendConfirmation] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false);
    const [selectedAlerts, setSelectedAlerts] = useState([]);
    const [selectedLabels, setSelectedLabels] = useState([]);
    const [allLabels, setAllLabels] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [creatingLabel, setCreatingLabel] = useState(false);
    const [newLabelToAdd, setNewLabelToAdd] = useState(null);
    const [useCustomSchedule, setUseCustomSchedule] = useState(false);
    const [frequency, setFrequency] = useState('once');
    const [firstAlertDays, setFirstAlertDays] = useState([]);
    const [firstAlertHour, setFirstAlertHour] = useState('9');
    const [firstAlertPeriod, setFirstAlertPeriod] = useState('AM');
    const [secondAlertDays, setSecondAlertDays] = useState([]);
    const [secondAlertHour, setSecondAlertHour] = useState('5');
    const [secondAlertPeriod, setSecondAlertPeriod] = useState('PM');
    const [fetchingScheduleData, setFetchingScheduleData] = useState(false);
    const [errors, setErrors] = useState(false);
    const [timezoneDisplay, setTimezoneDisplay] = useState(false);

    useEffect(() => {
        if(state.profile && state.profile.organization) {
            setTimezone(state.profile.organization.timezone);
        }
    }, [state]);

    useEffect(() => {
        let foundTimezone = timezones.find(obj => {
            return obj.key === timezone;
        });
        foundTimezone && setTimezoneDisplay(foundTimezone.name);
    }, [timezone]);

    useEffect(() => {
        fetchLabels();
    }, []);

    const fetchLabels = async() => {
        try {
            const labels = await getUserLabels();
            setAllLabels(labels);
        } catch(err) {
            addFlashMessage('danger', 'Unable to fetch label data');
        }
    };

    const handleActiveView = () => {
        switch(activeView) {
            case 'general':
                return (
                    <General
                        selectedTimezone={timezone}
                        setTimezone={setTimezone}
                        allLabels={allLabels}
                        selectedLabels={selectedLabels}
                        addToSelected={addToSelected}
                        removeFromSelected={removeFromSelected}
                        addLabel={addLabel}
                        creatingLabel={creatingLabel}
                        sendConfirmation={sendConfirmation}
                        setSendConfirmation={setSendConfirmation}
                        isAdmin={isAdmin}
                        setIsAdmin={setIsAdmin}
                        setFullName={setFullName}
                        fullName={fullName}
                        setEmail={setEmail}
                        email={email}
                        errors={errors}
                        isSuspended={isSuspended}
                        setIsSuspended={setIsSuspended}
                        isSingleUser={state.isSingleUser}
                    />
                );
            case 'emailSchedule':
                return (
                    <EmailSchedule
                        useCustomSchedule={useCustomSchedule}
                        frequency={frequency}
                        setFrequency={setFrequency}
                        firstAlertDays={firstAlertDays}
                        firstAlertHour={firstAlertHour}
                        firstAlertPeriod={firstAlertPeriod}
                        secondAlertDays={secondAlertDays}
                        secondAlertHour={secondAlertHour}
                        secondAlertPeriod={secondAlertPeriod}
                        fetchingScheduleData={fetchingScheduleData}
                        setFirstAlertPeriod={setFirstAlertPeriod}
                        setFirstAlertHour={setFirstAlertHour}
                        setSecondAlertPeriod={setSecondAlertPeriod}
                        setSecondAlertHour={setSecondAlertHour}
                        setFirstAlertDays={setFirstAlertDays}
                        setSecondAlertDays={setSecondAlertDays}
                        setUseCustomSchedule={setUseCustomSchedule}
                        timezone={timezone}
                        timezoneDisplay={timezoneDisplay}
                        errors={errors}
                    />
                );
            case 'assignAlerts':
                return (
                    <AssignAlerts
                        addFlashMessage={addFlashMessage}
                        selectedAlerts={selectedAlerts}
                        setSelectedAlerts={setSelectedAlerts}
                    />
                );
            default:
                return <General/>;
        }
    };

    const handleSubmit = async() => {
        if(frequency === 'twice' &&
            (firstAlertHour === secondAlertHour) &&
            (firstAlertPeriod === secondAlertPeriod)
        ) {
            let customError = {
                code: "VALIDATION_FAILED",
                errors: {sameSchedule: [{message: "Alert schedule times must be unique", code: "IS_BLANK_ERROR", payload: null}]},
                message: "Validation failed"
            }
            handleError(customError, setErrors);
            return;
        }
        try {
            setSubmitting(true);
            setErrors(null);
            const params = {
                email,
                targetUrl: window.location.protocol + '//' + window.location.host + '/invite',
                fullName,
                admin: isAdmin,
                suspend: isSuspended,
                withoutEmailSend: isAdmin ? false : !sendConfirmation,
                timezone,
                alerts: selectedAlerts.map(alert => alert.id),
                userLabels: selectedLabels.map(label => label.id),
            };

            const firstScheduleParams = {
                time: moment(firstAlertHour + firstAlertPeriod, ['h:mmA']).format('HH:mm'),
                days: firstAlertDays,
            };
            const secondScheduleParams = frequency === 'twice' ? {
                time: moment(secondAlertHour + secondAlertPeriod, ['hh:mm A']).format('HH:mm'),
                days: secondAlertDays,
            } : null;

            const user = await addUser(params);
            if(useCustomSchedule) {
                await addUserSchedule(user.content.id, firstScheduleParams);
                frequency === 'twice' && await addUserSchedule(user.content.id, secondScheduleParams);
            }
            addFlashMessage('success', 'User successfully created');
            history.push('/account/users');
        } catch(err) {
            handleError(err, setErrors);
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if(newLabelToAdd) {
            const foundLabel = allLabels.find(label => label.id === newLabelToAdd.id);
            foundLabel && addToSelected(foundLabel);
            setNewLabelToAdd(null);
        }
    }, [allLabels]);

    const addLabel = async(name) => {
        setCreatingLabel(true);
        try {
            const newLabel = await addUserLabel({name});
            setNewLabelToAdd(newLabel.content);
            await fetchLabels();
            setCreatingLabel(false);
        } catch(res) {
            if(res.errors.name[0].message) {
                addFlashMessage('danger', res.errors.name[0].message);
            }
            setCreatingLabel(false);
        }
    };

    const addToSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if(newSelectedLabels.indexOf(label) === -1) {
            newSelectedLabels.push(label);
            setSelectedLabels(newSelectedLabels);
        }
    };

    const removeFromSelected = (label) => {
        let newSelectedLabels = [...selectedLabels];
        if(newSelectedLabels.indexOf(label) > -1) {
            newSelectedLabels.splice(newSelectedLabels.indexOf(label), 1);
            setSelectedLabels(newSelectedLabels);
        }
    };

    return (
        <div className="animated fadeIn view create-edit-user">
            <Row className="mt-3">
                <Col xs="12" className="col-no-padding-mobile">
                    <div className="add-user">
                        <h1>Create User</h1>
                    </div>
                    <TabBar
                        activeItem={activeView}
                        setActive={setActiveView}
                        errorObj={errors}
                        shrink
                        items={
                            [
                                {data: 'general', name: 'General'},
                                {data: 'assignAlerts', name: 'Assign Content'},
                                {data: 'emailSchedule', name: 'Schedule'},
                            ]
                        }
                    />
                    {handleActiveView()}
                </Col>
                <Col className={'col-no-padding-mobile mb-4 ' + (window.innerWidth < 450 ? 'mt-2' : 'mt-4')}>
                    <div className="d-flex justify-content-end">
                        <SpinnerButton
                            submitting={submitting}
                            onClick={handleSubmit}
                            color="primary"
                            title="Create User"
                            block={window.innerWidth < 450}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CreateUser;
