import React, {useEffect, useState} from 'react';
import TagCategory from 'containers/Reader/Desktop/AlertList/TagCategory';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinnerSmall';

const Alert = (props) => {
    const {alert, fetchingAlertContent, selectedAlert, tagData, handleTagSelect, selectedTag} = props;

    const [tagListOpen, setTagListOpen] = useState(false);
    const isActiveAlert = selectedAlert && selectedAlert.id === alert.id ? 'reader-alert__title-container--active' : '';
    const rotateIcon = tagListOpen && isActiveAlert ? 'rotate-90' : '';

    const handleAlertSelect = (alert) => {
        if(selectedAlert && selectedAlert.id === alert.id) {
            toggleTagListOpen();
        } else {
            props.handleAlertSelect(alert);
        }
    }

    const toggleTagListOpen = () => {
        setTagListOpen(!tagListOpen);
    }

    useEffect(() => {
        if(selectedAlert && selectedAlert.id === alert.id) {
            !fetchingAlertContent && toggleTagListOpen()
        } else {
            setTagListOpen(false);
        }
    }, [selectedAlert])

    return (
        <div className="reader-alert">
            <div
                className={'reader-alert__title-container ' + isActiveAlert}
                onClick={() => handleAlertSelect(alert)}
            >
                <div className="reader-alert__title">{alert.name}</div>
                <div className="reader-alert__arrow">
                    {fetchingAlertContent && selectedAlert.id === alert.id ?
                        <LoadingSpinner/>
                        : <i className={'fa fa-angle-left ' + rotateIcon}/>
                    }
                </div>
            </div>
            {tagListOpen && !fetchingAlertContent &&
                <div className="reader-alert__tag-list">
                    {tagData.length < 1 ?
                        <div className="reader-alert__empty-tag">No tags found</div>
                        :
                        tagData.map(category => {
                            return (
                                <TagCategory
                                    key={category.type}
                                    category={category}
                                    selectedTag={selectedTag}
                                    handleTagSelect={handleTagSelect}
                                />
                            )
                        })
                    }
                </div>
            }
        </div>
    );
};

export default Alert;
