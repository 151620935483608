import axios from 'axios';
import {postDecorator, patchDecorator, getDecorator} from './decorators';

export const login = (username, password) => {
    const params = {
        _username: username,
        _password: password,
    };
    return axios('/api/v2/security/get_token', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params,
    })
        .then(res => res.data)
        .catch(err => {
            throw err.response.data.content;
        });
};


export const signup = (email, targetUrl) => {
    const params = {
        email,
        targetUrl,
    };
    return axios('/api/v2/security/registration', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'same-origin',
        data: params
    })
        .then(res => res.data)
        .catch(err => {
            throw err.response.data.content;
        });

};


export const checkRegistrationToken = (token) => {
    const params = {
        token,
    };
    return axios('/api/v2/security/registration/confirm', {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params,
    })
        .then(res => res.data)
        .catch(err => {
            throw err.response.data.content;
        });
};

export const finishRegistration = (params) => {
    return axios('/api/v2/security/registration/finish', {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params,
    })
        .then(res => res.data)
        .catch(err => {
            throw err.response.data.content;
        });
};

export const resetPasswordRequest = (params) => {
    return axios('/api/v2/security/reset_password/request', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params,
    })
        .then(res => res.data)
        .catch(err => {
            throw err.response.data.content;
        });
};

export const checkEmailToken = (token) => {
    const params = {
        token,
    };
    return axios('/api/v2/security/confirm_new_email', {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params,
    })
        .then(res => res.data)
        .catch(err => {
            throw err.response.data.content;
        });
};

export const resetPassword = (params) => {
    return axios('/api/v2/security/reset_password', {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params,
    })
        .then(res => res.data)
        .catch(err => {
            throw err.response.data.content;
        });
};

export const getProfile = () => {
    const url = '/api/v2/profile/';
    return getDecorator(url)
}

export const updateProfile = (params) => {
    const url = '/api/v2/profile/';
    return patchDecorator(url, params)
}

export const switchToOrganization = (params) => {
    const url = '/api/v2/profile/switch_to_organization';
    return patchDecorator(url, params)
}


export const loginToReader = (username, password) => {
    const params = {
        email: username,
        password: password,
    };
    return axios('/api/v2/security/reader', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params,
    })
        .then(res => res.data)
        .catch(err => {
            throw err.response.data.content;
        });
};
