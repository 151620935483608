export const truncateLongWords = (str, num) => {
    if (str) {
        let strArr = str.split(' ');
        for (let i = 0; i < strArr.length; i++) {
            if (strArr[i].length > num) {
                strArr[i] = strArr[i].substring(0, num);
            }
        }
        return strArr.join(' ');
    }
};