import React, { useState } from 'react';

import LabelListItemDropdown from './LabelsListItemDropdown';

const LabelsBlock = (props) => {
    const { allLabels, labels, refreshAlerts, refreshLabels, fetchingLabels, alert, disabled } = props;

    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        if (disabled) return;
        setExpanded(!expanded);
    };

    return (
        <div className="labels-block">
            <LabelListItemDropdown
                allLabels={allLabels}
                selectedLabels={labels}
                fetchingLabels={fetchingLabels}
                refreshList={refreshAlerts}
                refreshLabels={refreshLabels}
                alert={alert}
                disabled={disabled}
            />
            {labels.length > 3 && !expanded
                ? labels.slice(0, 3).map((label) => (
                      <div key={label.id} className="label">
                          {label.name}
                      </div>
                  ))
                : labels.map((label) => (
                      <div key={label.id} className="label">
                          {label.name}
                      </div>
                  ))}
            {labels.length > 3 && (
                <div className="labels-block-expand" onClick={toggleExpanded}>
                    {!expanded ? '+' + labels.slice(3, labels.length).length + ' More' : 'Hide'}
                </div>
            )}
        </div>
    );
};

export default LabelsBlock;
