import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import EmailToRss from './EmailToRss';
import GoogleAlerts from './GoogleAlerts';
import RSS from './RSS';
import BillsSearch from './BillsSearch';
import InternalAlerts from './InternalAlerts';
import { AuthContext } from 'contexts/AuthContext';
import { getAlertLabels, getInternalSearchCategories } from 'utils/api/alertsAPI';
import './style.scss';
import { handleError } from 'Helpers/handleError';

const AlertForm = (props) => {
    const { state } = useContext(AuthContext);
    const [activeAlertType, setActiveAlertType] = useState('internal');
    const [labels, setLabels] = useState([]);
    const [categories, setCategories] = useState(null);
    const [fetchingCategories, setFetchingCategories] = useState(true);

    useEffect(() => {
        fetchLabels();
        fetchCategories();
    }, []);

    const showAlertForm = () => {
        switch (activeAlertType) {
            case 'internal':
                return (
                    <InternalAlerts labels={labels} r refreshLabels={refreshLabels} categories={categories} categoriesLoading={fetchingCategories} />
                );
            case 'rss':
                return <RSS labels={labels} refreshLabels={refreshLabels} />;
            case 'bills':
                return <BillsSearch labels={labels} refreshLabels={refreshLabels} />;
            case 'internal_group':
                return <RSS labels={labels} internalGroup refreshLabels={refreshLabels} />;
            case 'google':
                return <GoogleAlerts labels={labels} refreshLabels={refreshLabels} />;
            case 'email_to_rss':
                return <EmailToRss />;
            default:
                return <InternalAlerts labels={labels} refreshLabels={refreshLabels} />;
        }
    };

    const fetchLabels = async () => {
        try {
            const foundLabels = await getAlertLabels();
            setLabels(foundLabels);
        } catch (err) {
            console.log(err);
        }
    };

    const refreshLabels = async () => {
        const refreshedLabels = await getAlertLabels();
        setLabels(refreshedLabels);
    };

    const fetchCategories = async () => {
        try {
            setFetchingCategories(true);
            let categoriesData = await getInternalSearchCategories();
            setCategories(categoriesData);
            setFetchingCategories(false);
        } catch (err) {
            handleError(err);
            setFetchingCategories(false);
        }
    };

    const billsSearchIsActive = activeAlertType === 'bills' ? 'active' : '';
    const internalIsActive = activeAlertType === 'internal' ? 'active' : '';

    return (
        <div className="animated fadeIn view">
            <Row className="mt-3">
                <Col xs="12">
                    <div className="alert-container">
                        <h1>Create Search</h1>
                        <div className="alert-type-button-container justify-content-between">
                            <div className="d-flex alert-container-block-1">
                                <div className={'alert-type-button ' + internalIsActive} onClick={() => setActiveAlertType('internal')}>
                                    <i className="fa fa-search" />
                                    <span className="i-space">&nbsp;</span> Search
                                </div>
                                {state.profile.organization && state.profile.organization.isLegiScanBillAvailable ? (
                                    <div className={'alert-type-button ' + billsSearchIsActive} onClick={() => setActiveAlertType('bills')}>
                                        <i className="fa fa-rss" />
                                        <span className="i-space">&nbsp;</span> State Bills
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        {showAlertForm()}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default AlertForm;
