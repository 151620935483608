import React, {useState} from 'react';
import {Row, Col, Card, CardHeader, CardBody} from 'reactstrap';
import {useParams} from 'react-router-dom';

import TabBar from 'components/TabBar';
import AlertsList, { CATEGORY_TYPE, INDUSTRIES_TYPE, TOPICS_TYPE, PRACTICES_TYPE } from '../AlertsList';
import { SEARCH_TYPE_INDUSTRY, SEARCH_TYPE_PRACTICE, SEARCH_TYPE_TOPIC } from 'utils/api/alertsAPI';

const ManageCategoryBased = () => {
    const {searchType} = useParams();
    const [activeView, setActiveView] = useState('alerts');

    const handleActiveView = () => {
        switch(activeView) {
            case 'alerts':
                return <AlertsList alertType={CATEGORY_TYPE} searchTypeId={getSearchTypeId()} searchType={searchType} />;
            default:
                return <AlertsList alertType={CATEGORY_TYPE} searchTypeId={getSearchTypeId()} searchType={searchType} />;
        }
    };

    const getHeader = () => {
        switch(searchType) {
            case PRACTICES_TYPE:
                return 'Manage Practices';
            case INDUSTRIES_TYPE:
                return 'Manage Industries';
            case TOPICS_TYPE:
                return 'Manage Topics';
        }
    };

    const getSearchTypeId = () => {
        switch(searchType) {
            case PRACTICES_TYPE:
                return SEARCH_TYPE_PRACTICE;
            case INDUSTRIES_TYPE:
                return SEARCH_TYPE_INDUSTRY;
            case TOPICS_TYPE:
                return SEARCH_TYPE_TOPIC;
            default: return 0
        }
    };

    const getTabName = () => {
        return searchType.charAt(0).toUpperCase() + searchType.slice(1);
    };

    return (
        <div className="view view--mobile-full alerts ">
            <div className="animated fadeIn">
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardHeader>
                                <div className="flex-space-between">
                                    <h1>{getHeader()}</h1>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <TabBar
                                    activeItem={activeView}
                                    setActive={setActiveView}
                                    items={
                                        [
                                            {data: 'alerts', name: getTabName()},
                                        ]
                                    }
                                />
                                {handleActiveView()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ManageCategoryBased;
